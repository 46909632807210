import { Injectable, Type } from '@angular/core';
import { XrtInMemoryService, XrtInMemorySourceWithConfig } from '@xpo/ngx-xrt';
import { ModuleLibraryVersion } from '../models/module-library-version.model';
import { ModuleExecutorService } from './module-executor.service';

@Injectable()
export class ModuleLibraryInMemoryService extends XrtInMemoryService {
  constructor(protected moduleExecutorService: ModuleExecutorService) {
    super(
      new Map<Type<any>, XrtInMemorySourceWithConfig>([
        [
          ModuleLibraryVersion,
          {
            source: moduleExecutorService.listModules.bind(moduleExecutorService),
            config: { quickSearchColumn: ['name'] },
          },
        ],
      ])
    );
  }
}
