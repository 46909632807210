import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { XpoBoardConsumer, XpoBoardState } from '../../models/index';

@Component({
  selector: 'xpo-board-grid-toolbar',
  templateUrl: 'board-grid-toolbar.component.html',
  styleUrls: ['board-grid-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-GridToolbar' },
})
export class XpoBoardGridToolbar extends XpoBoardConsumer {
  currentState: XpoBoardState;

  constructor() {
    super(null, null);
  }

  protected onStateChange(state: XpoBoardState): void {
    this.currentState = state;
  }
}
