import {StopDetailCd} from '../';
import {EnumHelper} from './enum-helper';

export class StopDetailCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in StopDetailCd ) {
      this._values.push(StopDetailCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): StopDetailCd {

    for ( const obj in StopDetailCd ) {
      if (StopDetailCd[obj] === value){
        return StopDetailCd[obj] as StopDetailCd;
      }
    }
  }
}

const StopDetailCdHelper = new StopDetailCdHelperEnumHelperClass();
export default StopDetailCdHelper;
