import { Injectable } from '@angular/core';
import { NotificationTypeEnum } from './notification-type-enum';
import { NotificationMessage } from './notification-message';
import { SnackbarConfig } from './snackbar-config';
import { Subject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationService {

  private subject = new Subject<NotificationMessage>();
  private showHideCountTracker = 0;
  private showOverlay = false;
  private message: string;
  private referenceCount = 0;

  constructor() {
    this.message = 'Loading...';
  }

  public getSubscriber(): Observable<NotificationMessage> {
    return this.subject.asObservable();
  }

  /**
   * Sets the message that will be displayed, by default 'Loading...' will be used
   * @param message
   */
  public setOverlayMessage(message) {
    this.message = message;
  }

  /**
   * Shows the loading overlay
   */
  public showOverlayMessage(message: string = 'Loading...') {
    this.referenceCount++;
    this.message = message;
    this.sendMessageToSubscribers(new NotificationMessage(NotificationTypeEnum.ShowOverlay, this.message, null));
    this.showOverlay = true;
  }

  /**
   * Hides the loading overlay
   */
  public hideOverlayMessage() {
    this.referenceCount--;
    if ( this.referenceCount <= 0 ) {
      this.referenceCount = 0;
      this.sendMessageToSubscribers(new NotificationMessage(NotificationTypeEnum.HideOverlay, null, null));
      this.message = '';
    }
  }

  /**
   * Forces hiding of the overlay
   */
  public clearOverlayMessage() {
    this.referenceCount = 0;
    this.hideOverlayMessage();
  }

  public showSnackBarMessage(message: string, snackbarConfig: SnackbarConfig = new SnackbarConfig()) {
    this.sendMessageToSubscribers(new NotificationMessage(NotificationTypeEnum.ShowSnackbar, message, snackbarConfig));
  }

  private sendMessageToSubscribers(message: NotificationMessage) {
    this.subject.next(message);
  }

  private clearSubscriberMessage() {
    this.subject.next();
  }


}
