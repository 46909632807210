
export enum ClaimEmailTemplateTypeCd {
	APPROVAL = 'Approval',
	BLANK_TEMPLATE = 'BlankTemplate',
	CLAIM_ACKNOWLEDGEMENT = 'ClaimAcknowledgement',
	CLEAR_DR_DECLINATION = 'ClearDrDeclination',
	ESCALATION_APPROVAL = 'EscalationApproval',
	INVOICE_REQUEST = 'InvoiceRequest',
	NINE_MONTH_LIMIT_DECLINATION = 'NineMonthLimitDeclination',
	POLICY_PAY = 'PolicyPay',
	REBUTTAL_ACKNOWLEDGEMENT = 'RebuttalAcknowledgement',
	REPAIR_INVOICE_REQUEST = 'RepairInvoiceRequest',
	SHORTAGE_REQUEST_FOR_INFO = 'ShortageRequestForInfo',
	SHRINK_WRAPPED_PALLET_DECLINATION = 'ShrinkWrappedPalletDeclination',
	SLC_DECLINATION = 'SlcDeclination',
	SUBRO_CLAIM_SUBMISSION = 'SubroClaimSubmission'}

