import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import {
  XpoAutofocusModule,
  XpoButtonModule,
  XpoCardModule,
  XpoIconModule,
  XpoPopoverModule,
  XpoResponsiveLoadingSpinnerModule,
} from '@xpo-ltl/ngx-ltl-core';
import { XpoAdvancedSelectModule } from '../advanced-select/index';
import { XpoCheckboxFilterComponent } from './checkbox-filter/checkbox-filter.component';
import { XpoConditionalFilterComponent } from './conditional-filter/conditional-filter.component';
import { XpoConditionComponentDirective } from './conditional-filter/conditions/condition.directive';
import { XpoConditionsComponent } from './conditional-filter/conditions/conditions.component';
import { XpoConditionBooleanFalseComponent } from './conditional-filter/conditions/validators/boolean/false/false.component';
import { XpoConditionBooleanTrueComponent } from './conditional-filter/conditions/validators/boolean/true/true.component';
import { XpoConditionEmptyComponent } from './conditional-filter/conditions/validators/common/is-empty/is-empty.component';
import { XpoConditionIsNotEmptyComponent } from './conditional-filter/conditions/validators/common/is-not-empty/is-not-empty.component';
import { XpoConditionIsOneOfComponent } from './conditional-filter/conditions/validators/common/is-one-of/is-one-of.component';
import { XpoConditionRangeEmptyComponent } from './conditional-filter/conditions/validators/common/range-is-empty/range-is-empty.component';
import { XpoConditionRangeNotEmptyComponent } from './conditional-filter/conditions/validators/common/range-is-not-empty/range-is-not-empty.component';
import { XpoCalendarComponent } from './conditional-filter/conditions/validators/custom-components/calendar/calendar.component';
import { XpoConditionDateTimeAfterSameComponent } from './conditional-filter/conditions/validators/date-time/after-same/after-same.component';
import { XpoConditionDateTimeAfterComponent } from './conditional-filter/conditions/validators/date-time/after/after.component';
import { XpoConditionDateTimeBeforeSameComponent } from './conditional-filter/conditions/validators/date-time/before-same/before-same.component';
import { XpoConditionDateTimeBeforeComponent } from './conditional-filter/conditions/validators/date-time/before/before.component';
import { XpoConditionDateTimeBetweenComponent } from './conditional-filter/conditions/validators/date-time/between/between.component';
import { XpoConditionDateTimeNotBetweenComponent } from './conditional-filter/conditions/validators/date-time/not-between/not-between.component';
import { XpoConditionDateAfterSameComponent } from './conditional-filter/conditions/validators/date/after-same/after-same.component';
import { XpoConditionDateAfterComponent } from './conditional-filter/conditions/validators/date/after/after.component';
import { XpoConditionDateBeforeSameComponent } from './conditional-filter/conditions/validators/date/before-same/before-same.component';
import { XpoConditionDateBeforeComponent } from './conditional-filter/conditions/validators/date/before/before.component';
import { XpoConditionDateBetweenComponent } from './conditional-filter/conditions/validators/date/between/between.component';
import { XpoConditionDateEqualsComponent } from './conditional-filter/conditions/validators/date/equals/equals.component';
import { XpoConditionDateIsComponent } from './conditional-filter/conditions/validators/date/is/is.component';
import { XpoConditionDateNotBetweenComponent } from './conditional-filter/conditions/validators/date/not-between/not-between.component';
import { XpoConditionDynamicEnumEqualsComponent } from './conditional-filter/conditions/validators/dynamic-enum/equals/equals.component';
import { XpoConditionEnumEqualsComponent } from './conditional-filter/conditions/validators/enum/equals/equals.component';
import { XpoConditionNumberEqualsComponent } from './conditional-filter/conditions/validators/number/equals/equals.component';
import { XpoConditionNumberGreaterEqualThanComponent } from './conditional-filter/conditions/validators/number/greater-equal-than/greater-equal-than.component';
import { XpoConditionNumberGreaterThanComponent } from './conditional-filter/conditions/validators/number/greater-than/greater-than.component';
import { XpoConditionNumberIsBetweenComponent } from './conditional-filter/conditions/validators/number/is-between/is-between.component';
import { XpoConditionNumberIsNotBetweenComponent } from './conditional-filter/conditions/validators/number/is-not-between/is-not-between.component';
import { XpoConditionNumberLessEqualThanComponent } from './conditional-filter/conditions/validators/number/less-equal-than/less-equal-than.component';
import { XpoConditionNumberLessThanComponent } from './conditional-filter/conditions/validators/number/less-than/less-than.component';
import { XpoConditionNumberNotEqualsComponent } from './conditional-filter/conditions/validators/number/not-equals/not-equals.component';
import { XpoConditionStringContainsComponent } from './conditional-filter/conditions/validators/string/contains/contains.component';
import { XpoConditionStringDoesNotContainComponent } from './conditional-filter/conditions/validators/string/does-not-contain/does-not-contain.component';
import { XpoConditionStringEndsWithComponent } from './conditional-filter/conditions/validators/string/ends-with/ends-with.component';
import { XpoConditionStringEqualsComponent } from './conditional-filter/conditions/validators/string/equals/equals.component';
import { XpoConditionStringIsBetweenComponent } from './conditional-filter/conditions/validators/string/is-between/is-between.component';
import { XpoConditionStringIsNotBetweenComponent } from './conditional-filter/conditions/validators/string/is-not-between/is-not-between.component';
import { XpoConditionStringIsNotEqualComponent } from './conditional-filter/conditions/validators/string/is-not-equal/is-not-equal.component';
import { XpoConditionStringStartsWithComponent } from './conditional-filter/conditions/validators/string/starts-with/starts-with.component';
import { XpoConditionTimeRangeAfterComponent } from './conditional-filter/conditions/validators/time-range/after/after.component';
import { XpoConditionTimeRangeBeforeComponent } from './conditional-filter/conditions/validators/time-range/before/before.component';
import { XpoConditionTimeRangeBetweenComponent } from './conditional-filter/conditions/validators/time-range/between/between.component';
import { XpoConditionTimeRangeNotBetweenComponent } from './conditional-filter/conditions/validators/time-range/not-between/not-between.component';
import { XpoConditionTimeAfterSameComponent } from './conditional-filter/conditions/validators/time/after-same/after-same.component';
import { XpoConditionTimeAfterComponent } from './conditional-filter/conditions/validators/time/after/after.component';
import { XpoConditionTimeBeforeSameComponent } from './conditional-filter/conditions/validators/time/before-same/before-same.component';
import { XpoConditionTimeBeforeComponent } from './conditional-filter/conditions/validators/time/before/before.component';
import { XpoConditionTimeBetweenComponent } from './conditional-filter/conditions/validators/time/between/between.component';
import { XpoConditionTimeEqualsComponent } from './conditional-filter/conditions/validators/time/equals/equals.component';
import { XpoConditionTimeIsComponent } from './conditional-filter/conditions/validators/time/is/is.component';
import { XpoConditionTimeNotBetweenComponent } from './conditional-filter/conditions/validators/time/not-between/not-between.component';
import { ExactTimeInputComponent } from './conditional-filter/conditions/validators/time/shared/exact-time-input/exact-time-input.component';
import {
  XpoBooleanFilterComponent,
  XpoDateOnlyFilterComponent,
  XpoDateTimeFilterComponent,
  XpoDynamicEnumFilterComponent,
  XpoEnumFilterComponent,
  XpoNumberFilterComponent,
  XpoStringFilterComponent,
  XpoTimeFilterComponent,
  XpoTimeRangeFilterComponent,
} from './conditional-filter/index';
import { XpoDateFilterComponent } from './date-filter/date-filter.component';
import { XpoDateRangeFilterGroupByComponent } from './date-range-filter/date-range-filter-group-by/date-range-filter-group-by.component';
import { XpoDateRangeFilterComponent } from './date-range-filter/date-range-filter.component';
import { XpoFilterBar, XpoFilterHost } from './filter-bar/filter-bar.component';
import { XpoGlobalFilterBar } from './filter-bar/global-filter-bar/global-filter-bar.component';
import { XpoFilterChip } from './filter-chip/filter-chip.component';
import { XpoFilterContainer } from './filter-container/filter-container.component';
import { XpoFilterDateSelectorComponent } from './filter-date-selector/filter-date-selector.component';
import { XpoFilterForm, XpoFilterFormHost } from './filter-form/filter-form.component';
import { XpoInlineMultiSelectFilterComponent } from './inline-multi-select-filter/inline-multi-select-filter.component';
import {
  XpoMultiSelectAutocompleteFilterComponent,
  XpoMultiSelectAutoCompleteFilterSelectedOptionsComponent,
} from './multi-select-autocomplete-filter/index';
import { XpoQuickSearchFilterComponent } from './quick-search-filter/quick-search-filter.component';
import { XpoSelectFilterComponent } from './select-filter/index';
import { XpoTextInputFilterComponent } from './text-input-filter/text-input-filter.component';
import { XpoYesNoFilterComponent } from './yes-no-filter/yes-no-filter.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatBadgeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatNativeDateModule,
    // MatMomentDateModule, // TODO: maybe moment is the right way to go for internationalization?
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatTabsModule,
    OverlayModule,
    ReactiveFormsModule,
    XpoAutofocusModule,
    XpoButtonModule,
    XpoCardModule,
    XpoResponsiveLoadingSpinnerModule,
    XpoIconModule,
    XpoPopoverModule,
    XpoAdvancedSelectModule,
    MatSelectModule,
  ],
  declarations: [
    XpoBooleanFilterComponent,
    XpoCalendarComponent,
    XpoCheckboxFilterComponent,
    XpoConditionEnumEqualsComponent,
    XpoConditionDynamicEnumEqualsComponent,
    XpoConditionBooleanFalseComponent,
    XpoConditionBooleanTrueComponent,
    XpoConditionComponentDirective,
    XpoConditionDateEqualsComponent,
    XpoConditionDateTimeBetweenComponent,
    XpoConditionEmptyComponent,
    XpoConditionIsNotEmptyComponent,
    XpoConditionNumberEqualsComponent,
    XpoConditionNumberGreaterEqualThanComponent,
    XpoConditionNumberGreaterThanComponent,
    XpoConditionNumberIsBetweenComponent,
    XpoConditionNumberIsNotBetweenComponent,
    XpoConditionNumberLessEqualThanComponent,
    XpoConditionNumberLessThanComponent,
    XpoConditionNumberNotEqualsComponent,
    XpoConditionStringContainsComponent,
    XpoConditionStringDoesNotContainComponent,
    XpoConditionStringEndsWithComponent,
    XpoConditionStringEqualsComponent,
    XpoConditionStringIsBetweenComponent,
    XpoConditionStringIsNotBetweenComponent,
    XpoConditionStringIsNotEqualComponent,
    XpoConditionIsOneOfComponent,
    XpoConditionStringStartsWithComponent,
    XpoConditionalFilterComponent,
    XpoConditionsComponent,
    XpoEnumFilterComponent,
    XpoDynamicEnumFilterComponent,
    XpoDateFilterComponent,
    XpoDateOnlyFilterComponent,
    XpoDateRangeFilterComponent,
    XpoDateRangeFilterGroupByComponent,
    XpoDateTimeFilterComponent,
    XpoFilterBar,
    XpoFilterChip,
    XpoFilterContainer,
    XpoFilterDateSelectorComponent,
    XpoFilterForm,
    XpoFilterFormHost,
    XpoFilterHost,
    XpoGlobalFilterBar,
    XpoInlineMultiSelectFilterComponent,
    XpoMultiSelectAutoCompleteFilterSelectedOptionsComponent,
    XpoMultiSelectAutocompleteFilterComponent,
    XpoNumberFilterComponent,
    XpoQuickSearchFilterComponent,
    XpoSelectFilterComponent,
    XpoStringFilterComponent,
    XpoTextInputFilterComponent,
    XpoTimeFilterComponent,
    XpoTimeRangeFilterComponent,
    XpoYesNoFilterComponent,
    XpoConditionTimeBeforeSameComponent,
    XpoConditionTimeNotBetweenComponent,
    XpoConditionTimeIsComponent,
    XpoConditionTimeEqualsComponent,
    XpoConditionTimeBetweenComponent,
    XpoConditionTimeRangeBetweenComponent,
    XpoConditionTimeRangeNotBetweenComponent,
    XpoConditionTimeRangeBeforeComponent,
    XpoConditionTimeRangeAfterComponent,
    XpoConditionRangeEmptyComponent,
    XpoConditionRangeNotEmptyComponent,
    XpoConditionTimeBeforeComponent,
    XpoConditionTimeAfterComponent,
    XpoConditionTimeAfterSameComponent,
    XpoConditionDateNotBetweenComponent,
    XpoConditionDateBetweenComponent,
    XpoConditionDateBeforeComponent,
    XpoConditionDateBeforeSameComponent,
    XpoConditionDateAfterComponent,
    XpoConditionDateAfterSameComponent,
    XpoConditionDateTimeNotBetweenComponent,
    XpoConditionDateTimeBeforeComponent,
    XpoConditionDateTimeAfterComponent,
    XpoConditionDateTimeBeforeSameComponent,
    XpoConditionDateIsComponent,
    XpoConditionDateTimeAfterSameComponent,
    ExactTimeInputComponent,
  ],
  exports: [
    XpoBooleanFilterComponent,
    XpoConditionDateTimeBetweenComponent,
    XpoDateFilterComponent,
    XpoDateOnlyFilterComponent,
    XpoDateRangeFilterComponent,
    XpoDateRangeFilterGroupByComponent,
    XpoDateTimeFilterComponent,
    XpoFilterBar,
    XpoFilterChip,
    XpoFilterContainer,
    XpoFilterDateSelectorComponent,
    XpoFilterForm,
    XpoFilterFormHost,
    XpoFilterHost,
    XpoGlobalFilterBar,
    XpoInlineMultiSelectFilterComponent,
    XpoMultiSelectAutoCompleteFilterSelectedOptionsComponent,
    XpoMultiSelectAutocompleteFilterComponent,
    XpoNumberFilterComponent,
    XpoQuickSearchFilterComponent,
    XpoSelectFilterComponent,
    XpoStringFilterComponent,
    XpoTextInputFilterComponent,
    XpoEnumFilterComponent,
    XpoDynamicEnumFilterComponent,
    XpoTimeFilterComponent,
    XpoTimeRangeFilterComponent,
    XpoYesNoFilterComponent,
  ],
  entryComponents: [
    XpoBooleanFilterComponent,
    XpoCheckboxFilterComponent,
    XpoConditionBooleanFalseComponent,
    XpoConditionBooleanTrueComponent,
    XpoConditionDateEqualsComponent,
    XpoConditionDateEqualsComponent,
    XpoConditionDateTimeBetweenComponent,
    XpoConditionDateTimeBetweenComponent,
    XpoConditionEmptyComponent,
    XpoConditionIsNotEmptyComponent,
    XpoConditionNumberEqualsComponent,
    XpoConditionNumberGreaterEqualThanComponent,
    XpoConditionNumberGreaterThanComponent,
    XpoConditionNumberIsBetweenComponent,
    XpoConditionNumberIsNotBetweenComponent,
    XpoConditionNumberLessEqualThanComponent,
    XpoConditionNumberLessThanComponent,
    XpoConditionNumberNotEqualsComponent,
    XpoConditionStringContainsComponent,
    XpoConditionStringDoesNotContainComponent,
    XpoConditionStringEndsWithComponent,
    XpoConditionStringEqualsComponent,
    XpoConditionStringIsBetweenComponent,
    XpoConditionStringIsNotBetweenComponent,
    XpoConditionStringIsNotEqualComponent,
    XpoConditionIsOneOfComponent,
    XpoConditionStringStartsWithComponent,
    XpoConditionalFilterComponent,
    XpoDateFilterComponent,
    XpoDateOnlyFilterComponent,
    XpoDateRangeFilterComponent,
    XpoDateRangeFilterGroupByComponent,
    XpoDateTimeFilterComponent,
    XpoFilterChip,
    XpoFilterContainer,
    XpoFilterDateSelectorComponent,
    XpoInlineMultiSelectFilterComponent,
    XpoMultiSelectAutoCompleteFilterSelectedOptionsComponent,
    XpoMultiSelectAutocompleteFilterComponent,
    XpoNumberFilterComponent,
    XpoQuickSearchFilterComponent,
    XpoSelectFilterComponent,
    XpoStringFilterComponent,
    XpoTextInputFilterComponent,
    XpoTimeFilterComponent,
    XpoTimeRangeFilterComponent,
    XpoEnumFilterComponent,
    XpoDynamicEnumFilterComponent,
    XpoConditionEnumEqualsComponent,
    XpoConditionDynamicEnumEqualsComponent,
    XpoYesNoFilterComponent,
    XpoConditionTimeBeforeSameComponent,
    XpoConditionTimeNotBetweenComponent,
    XpoConditionTimeIsComponent,
    XpoConditionTimeEqualsComponent,
    XpoConditionTimeBetweenComponent,
    XpoConditionTimeRangeBetweenComponent,
    XpoConditionTimeRangeNotBetweenComponent,
    XpoConditionTimeRangeBeforeComponent,
    XpoConditionTimeRangeAfterComponent,
    XpoConditionRangeEmptyComponent,
    XpoConditionRangeNotEmptyComponent,
    XpoConditionTimeBeforeComponent,
    XpoConditionTimeAfterComponent,
    XpoConditionTimeAfterSameComponent,
    XpoConditionDateNotBetweenComponent,
    XpoConditionDateBetweenComponent,
    XpoConditionDateBeforeComponent,
    XpoConditionDateBeforeSameComponent,
    XpoConditionDateAfterComponent,
    XpoConditionDateAfterSameComponent,
    XpoConditionDateTimeNotBetweenComponent,
    XpoConditionDateTimeBeforeComponent,
    XpoConditionDateTimeAfterComponent,
    XpoConditionDateTimeBeforeSameComponent,
    XpoConditionDateIsComponent,
    XpoConditionDateTimeAfterSameComponent,
  ],
})
export class XpoFiltersModule {}
