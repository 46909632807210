import {CustomerTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerTypeCd ) {
      this._values.push(CustomerTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerTypeCd {

    for ( const obj in CustomerTypeCd ) {
      if (CustomerTypeCd[obj] === value){
        return CustomerTypeCd[obj] as CustomerTypeCd;
      }
    }
  }
}

const CustomerTypeCdHelper = new CustomerTypeCdHelperEnumHelperClass();
export default CustomerTypeCdHelper;
