import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XpoMaxNumberLengthDirective } from './max-number-length.directive';

const directives = [XpoMaxNumberLengthDirective];

@NgModule({
  imports: [CommonModule],
  declarations: directives,
  exports: directives,
})
export class XpoMaxNumberLengthModule {}
