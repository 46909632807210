import {ShortageTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShortageTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShortageTypeCd ) {
      this._values.push(ShortageTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShortageTypeCd {

    for ( const obj in ShortageTypeCd ) {
      if (ShortageTypeCd[obj] === value){
        return ShortageTypeCd[obj] as ShortageTypeCd;
      }
    }
  }
}

const ShortageTypeCdHelper = new ShortageTypeCdHelperEnumHelperClass();
export default ShortageTypeCdHelper;
