import {RateRequestTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class RateRequestTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RateRequestTypeCd ) {
      this._values.push(RateRequestTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RateRequestTypeCd {

    for ( const obj in RateRequestTypeCd ) {
      if (RateRequestTypeCd[obj] === value){
        return RateRequestTypeCd[obj] as RateRequestTypeCd;
      }
    }
  }
}

const RateRequestTypeCdHelper = new RateRequestTypeCdHelperEnumHelperClass();
export default RateRequestTypeCdHelper;
