import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_EXPANSION_PANEL_DEFAULT_OPTIONS, MatExpansionModule } from '@angular/material/expansion';
import { XpoExpansionPanelDark } from './expansion-panel-dark/expansion-panel-dark.directive';

@NgModule({
  declarations: [XpoExpansionPanelDark],
  imports: [CommonModule, MatExpansionModule],
  exports: [MatExpansionModule, XpoExpansionPanelDark],
  providers: [
    {
      provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
      useValue: {
        hideToggle: true,
        expandedHeight: '40px',
        collapsedHeight: '40px',
      },
    },
  ],
})
export class XpoExpansionPanelModule {}
