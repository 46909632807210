import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { GetOdsShipmentResp } from '@xpo-ltl/sdk-shipmentods';
import { get as _get } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '../../../classes/unsubscriber';
import { ShipmentReferenceService } from '../../shipment-reference.service';

@Component({
  selector: 'xpo-ltl-shipment-details-remarks',
  templateUrl: './shipment-details-remarks.component.html',
  styleUrls: ['./shipment-details-remarks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentDetailsRemarksComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();

  private remarksSubject = new BehaviorSubject<string>('None');
  readonly remarks$ = this.remarksSubject.asObservable();

  constructor(private shipmentRefService: ShipmentReferenceService) {}

  ngOnInit() {
    this.shipmentRefService.shipment$
      .pipe(takeUntil(this.unsubscriber.done$), distinctUntilChanged())
      .subscribe((shipment) => {
        this.updateFormFromShipment(shipment);
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private updateFormFromShipment(shipment: GetOdsShipmentResp) {
    if (!shipment) {
      return;
    }

    this.remarksSubject.next(_get(shipment, 'remark[0].remark', 'None'));
  }
}
