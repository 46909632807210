import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { XpoLtlPopoverModule } from '../xpo-ltl-popover/index';
import { XpoAppSwitcherPopover } from './app-switcher-popover.component';

@NgModule({
  imports: [CommonModule, XpoLtlPopoverModule, MatButtonModule, MatIconModule],
  declarations: [XpoAppSwitcherPopover],
  exports: [XpoAppSwitcherPopover],
})
export class XpoAppSwitcherPopoverModule {}
