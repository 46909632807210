/**
 * Helper for enums
 */
export abstract class EnumHelper {
  protected _values:string[] = [];
  protected _keys:string[] = [];

  public values(): string[] {
    return this._values;
  }

  public keys(): string[]{
    return this._keys;
  }
}