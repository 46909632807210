import { DocType } from './doc-type.enum';

export class DocTypeHelperClass {

  protected _values: string[] = [];
  protected _keys: string[] = [];

  public values(): string[] {
    return this._values;
  }

  public keys(): string[]{
    return this._keys;
  }

  public constructor() {
    // noinspection TsLint
    for ( const obj in DocType ) {
      this._values.push(DocType[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DocType {

    for ( const obj in DocType ) {
      if (DocType[obj] === value) {
        return DocType[obj] as DocType;
      }
    }
  }
}

const DocTypeHelper = new DocTypeHelperClass();
export default DocTypeHelper;
