
export enum DwellSourceCd {
	APP_LAUNCH = 'AppLaunch',
	COMPLETE_STOP = 'CompleteStop',
	ENTER_GATE = 'EnterGate',
	INSIDE_GATE = 'InsideGate',
	KRONOS_PUNCH = 'KronosPunch',
	LEAVE_GATE = 'LeaveGate',
	OUTSIDE_GATE = 'OutsideGate'}

