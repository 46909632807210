import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { HorizontalConnectionPos, VerticalConnectionPos } from '@angular/cdk/overlay';
import { XpoPopover } from '@xpo-ltl/ngx-ltl-core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'xpo-ltl-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
})
export class XpoLtlDateSelectorComponent implements OnInit {
  @Input()
  position: TooltipPosition = 'below';

  @Input()
  color: ThemePalette = 'primary';

  @Input()
  caretPosition: HorizontalConnectionPos | VerticalConnectionPos = 'start';

  @Input()
  minDate: Date = undefined;

  @Input()
  maxDate: Date = undefined;

  @Input()
  selectedDate: Date;

  @Output()
  selectedDateChange = new EventEmitter<Date>();

  @ViewChild('popover')
  popover: XpoPopover;

  constructor() {}

  ngOnInit(): void {}

  onCalendarChanged(value: Date): void {
    if (value) {
      value.setSeconds(0, 0);
    }

    if (this.selectedDate !== value) {
      this.selectedDate = value;
      this.selectedDateChange.emit(value);
      this.popover.handleKeydown({ ...new KeyboardEvent(''), code: 'Escape', keyCode: 27 /* Escape */ });
    }
  }
}
