import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, merge, Observable, Subject, timer } from 'rxjs';
import { filter, takeUntil, throttleTime } from 'rxjs/operators';
import { Unsubscriber } from '../../classes/unsubscriber';
import { XpoLtlWindowService } from '../window/window.service';

export interface XpoLtlIdleTimeoutConfig {
  timeout?: number;
}

/**
 * Fire an event when the user has been idle for a specified amount of time
 */
@Injectable({
  providedIn: 'root',
})
export class XpoLtlIdleTimeoutService implements OnDestroy {
  private unsubscriber = new Unsubscriber();

  // events that constitute user interaction with the app
  private activityEvents$: Observable<Event>;

  private defaultConfig: XpoLtlIdleTimeoutConfig = {
    timeout: 30 * 60, // default timeout after 1/2 hour of inactivity
  };

  /**
   * current timer configuration
   */
  get config() {
    return this._config;
  }
  private _config: XpoLtlIdleTimeoutConfig;

  /**
   * True if the timer is currently stopped
   */
  get timerStopped() {
    return this._timerStopped;
  }
  private _timerStopped: boolean = true;

  /**
   * number of seconds has user been idle
   */
  get idleTime() {
    return this._idleTime;
  }
  private _idleTime = 0;

  /**
   * Observable that is trigged when the timer runs out
   */
  idleTimeout$ = new Subject();

  constructor(private windowService: XpoLtlWindowService) {
    this._config = {
      ...this.defaultConfig,
    };

    // events that define user interaction with the app
    this.activityEvents$ = merge(
      fromEvent(this.windowService.nativeWindow, 'mousemove'),
      fromEvent(this.windowService.nativeWindow, 'resize'),
      fromEvent(this.windowService.nativeDocument, 'keydown')
    );

    this.activityEvents$.pipe(throttleTime(1000), takeUntil(this.unsubscriber.done$)).subscribe(() => {
      // reset how long we have been idle every time a qualifying event happens
      this._idleTime = 0;
    });

    // start timer
    timer(0, 1000)
      .pipe(
        filter(() => !this.timerStopped),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe(() => {
        this.tick();
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
    this.idleTimeout$.unsubscribe();
  }

  /**
   * Start the idle timer
   */
  start(config: XpoLtlIdleTimeoutConfig = {}) {
    this._config = {
      ...this.defaultConfig,
      ...config,
    };

    this.reset();
  }

  /**
   * stop the idle timer
   */
  stop() {
    this._timerStopped = true;
  }

  /**
   * Reset the idle timer
   */
  reset() {
    this._idleTime = 0;
    this._timerStopped = false;
  }

  private tick() {
    if (!this.timerStopped) {
      this._idleTime += 1; // add 1 second to idle timer

      if (this.idleTime >= this.config.timeout) {
        // user has been idle for longer than our timeout.
        this._timerStopped = true;
        this.idleTimeout$.next();
      }
    }
  }
}
