import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { XpoFiltersService } from '../filters.service';

@Component({
  selector: 'xpo-filter-container',
  templateUrl: './filter-container.component.html',
  styleUrls: ['./filter-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-FilterContainer' },
})
export class XpoFilterContainer {
  @Input()
  showApplyButton: boolean = false;

  @Output()
  apply = new EventEmitter<void>();

  @Output()
  clear = new EventEmitter<void>();

  @Output()
  close = new EventEmitter<void>();

  constructor(filterService: XpoFiltersService) {
    this.showApplyButton = filterService.applyFilterStrategy === 'container';
  }
}
