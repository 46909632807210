
export enum AttemptReasonCd {
	CASH_NOT_AVAILABLE = 'CashNotAvailable',
	CLOSED_HOLIDAY = 'ClosedHoliday',
	CLOSED_INVENTORY = 'ClosedInventory',
	COD_REQUIRED = 'CodRequired',
	CONSIGNEE_RELATED = 'ConsigneeRelated',
	CREDIT_HOLD = 'CreditHold',
	CUSTOMER_REQUEST_FUTURE_DELIVERY = 'CustomerRequestFutureDelivery',
	CUSTOMER_SHUTDOWN = 'CustomerShutdown',
	CUSTOMER_STRIKE = 'CustomerStrike',
	IMPROPER_UNLOAD_EQUIPMENT = 'ImproperUnloadEquipment',
	INSUFFICIENT_DELIVERY_TIME = 'InsufficientDeliveryTime',
	OTHER = 'Other',
	RECIPIENT_UNAVAILABLE = 'RecipientUnavailable',
	RESIDENTIAL_NOT_HOME = 'ResidentialNotHome',
	SHIPPER_RELATED = 'ShipperRelated'}

