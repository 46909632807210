
export enum RuleExpressionValueTypeCd {
	BETWEEN = 'Between',
	BOOLEAN = 'Boolean',
	DATE_TIME = 'DateTime',
	LIST = 'List',
	NUMBER = 'Number',
	NUMBER_LIST = 'NumberList',
	TEXT = 'Text'}

