
export enum InvoiceTypeCd {
	BAL_DUE = 'BalDue',
	CORRECTED = 'Corrected',
	ORIGINAL = 'Original',
	PAST_DUE_BILL = 'PastDueBill',
	PAST_DUE_STMT = 'PastDueStmt',
	THIRD_PARTY_BAL_DUE = 'ThirdPartyBalDue',
	THIRD_PARTY_CORRECTED = 'ThirdPartyCorrected',
	THIRD_PARTY_ORIGINAL = 'ThirdPartyOriginal',
	THIRD_PARTY_PAST_DUE_BILL = 'ThirdPartyPastDueBill',
	THIRD_PARTY_PAST_DUE_STMT = 'ThirdPartyPastDueStmt'}

