import { BehaviorSubject, Observable } from 'rxjs';
import { XpoFilter } from '../models/filter';
import { XpoMultiSelectAutocompleteFilterComponent } from './multi-select-autocomplete-filter.component';

export class XpoMultiSelectAutocompleteFilter<T = any> extends XpoFilter {
  /**
   * Controls what field on the options array is used for the display. Defaults to title
   */
  labelField = 'title';

  /**
   * Controls what field on the options array is used for filter value. Defaults to code
   */
  valueField = 'code';

  /**
   * Controls the max number of characters to have in the displayValue. Defaults to 30
   */
  maxDisplayValueCharLength = 30;

  /**
   * Controls the options displayed in the filter based on the search input entered.
   */
  optionsResolver$: (input: string) => Observable<T[]>;

  private selectedOptionsSource$ = new BehaviorSubject<T[]>([]);

  constructor(
    field: string,
    label: string,
    optionsResolver$: (input: string) => Observable<T[]>,
    _disabled$?: BehaviorSubject<boolean>
  ) {
    super(field, label, XpoMultiSelectAutocompleteFilterComponent);

    this.optionsResolver$ = optionsResolver$;
    this.disabled$ = _disabled$;
  }

  get selectedOptions(): T[] {
    return this.selectedOptionsSource$.value;
  }

  get selectedOptions$(): Observable<T[]> {
    return this.selectedOptionsSource$.asObservable();
  }

  setSelectedOptions(v: T[]): void {
    this.selectedOptionsSource$.next(v);
  }

  getDisplayValue(val: any): string {
    if (!val || !val.length) {
      return 'Any';
    }

    const displayValue = this.selectedOptions
      .filter((opt) => val.indexOf(opt[this.valueField]) !== -1)
      .map((opt) => opt[this.labelField])
      .join(', ');

    return displayValue.length > this.maxDisplayValueCharLength
      ? displayValue.substr(0, this.maxDisplayValueCharLength - 1) + '...'
      : displayValue;
  }
}
