import { XpoQuickSearchFilter } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { ModuleExecutorBoardTemplates } from './enums/module-executor-board-templates.enum';
import { MODULE_LIBRARY_COLUMNS } from './module-library-columns';

export const MODULE_EXECUTOR_BOARD_TEMPLATES = [
  new XpoAgGridBoardViewTemplate({
    id: ModuleExecutorBoardTemplates.MODULE_LIBRARY,
    name: 'Module Library',
    allowAdditional: false,
    availableFilters: [new XpoQuickSearchFilter('q')],
    availableColumns: MODULE_LIBRARY_COLUMNS,
  }),
];
