import { Component, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';

@Component({
  selector: 'xpo-calendar-component',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class XpoCalendarComponent {
  selectedDate: string;
  @Output() dateChange = new EventEmitter<boolean>();

  @ViewChild('calendar', { static: true }) calendar: MatCalendar<any>;

  dateChanged(e): void {
    this.dateChange.emit(e);
  }
}
