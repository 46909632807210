/**
 * ModuleExecutor API
 * Version: 1.0
 * Build: 1.0.0.19
 */

import {
	ListInfo,
	AuditInfo,
	ActionCd,
	HttpMethod,
	JobStateTypeCd,
	ModuleExecutorDataExtractTypeCd,
	ModuleExecutorDatasetTypeCd,
	ModuleExecutorParmsArgTypeCd,
	ModuleExecutorSuccessfulCd
} from '@xpo-ltl/sdk-common';

/**
 * Path parameter used for deleting a DataExtract.
 */
export class DeleteDataExtractPath {
	/**
	 * The unique identifier for the dataExtract.
	 * Required: true
	 * Array: false
	 */
	dataExtractInstId:number = undefined;
}
/**
 * Path parameter used for deleting a Dataset.
 */
export class DeleteDatasetPath {
	/**
	 * The unique identifier for the dataset.
	 * Required: true
	 * Array: false
	 */
	datasetInstId:number = undefined;
	/**
	 * The unique identifier for the dataExtract.
	 * Required: true
	 * Array: false
	 */
	dataExtractInstId:number = undefined;
}
/**
 * Path parameter used for deleting a Module.
 */
export class DeleteModulePath {
	/**
	 * The unique identifier for the module.
	 * Required: true
	 * Array: false
	 */
	moduleInstId:number = undefined;
}
/**
 * Path parameter used for deleting a ModuleGroup.
 */
export class DeleteModuleGroupPath {
	/**
	 * The unique identifier for the module group.
	 * Required: true
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
}
/**
 * Path parameters used for deleting a ModuleVersion.
 */
export class DeleteModuleVersionPath {
	/**
	 * The unique identifier for the module version.
	 * Required: true
	 * Array: false
	 */
	moduleVersionSeqNbr:number = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	moduleInstId:number = undefined;
}
/**
 * Request parameter used for getting the Module Group to duplicate.
 */
export class DuplicateModuleGroupRqst {
	/**
	 * The unique identifier for the module group.
	 * Required: true
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
}
/**
 * Response to duplicate a module group by identifier.
 */
export class DuplicateModuleGroupResp {
	/**
	 * The duplicated module group for the instance ID provided.
	 * Required: false
	 * Array: false
	 */
	moduleGroup:ModuleGroup = undefined;
}
/**
 * Path parameter used for getting dataExtract.
 */
export class GetDataExtractPath {
	/**
	 * The unique identifier for the dataExtract.
	 * Required: true
	 * Array: false
	 */
	dataExtractName:string = undefined;
}
/**
 * Data extract response
 */
export class GetDataExtractResp {
	/**
	 * The DataExtract that matches the name provided.
	 * Required: false
	 * Array: false
	 */
	dataExtract:DataExtract = undefined;
}
/**
 * Path parameter used for getting last run status for a module group.
 */
export class GetLastRunStatusByModuleGroupPath {
	/**
	 * The unique identifier for the moduleGroup.
	 * Required: true
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
}
/**
 * Get last module group run status response
 */
export class GetLastRunStatusByModuleGroupResp {
	/**
	 * Run status of a Module Group.
	 * Required: false
	 * Array: false
	 */
	runStatus:ModuleGroupRunStatus = undefined;
}
/**
 * Path parameter used for getting the Module Group.
 */
export class GetModuleGroupPath {
	/**
	 * The unique identifier for the module group.
	 * Required: true
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
}
/**
 * Response to get a module group by identifier.
 */
export class GetModuleGroupResp {
	/**
	 * The data of the Module Group.
	 * Required: false
	 * Array: false
	 */
	moduleGroup:ModuleGroup = undefined;
}
/**
 * Path parameter used for getting a dataset.
 * 			
 */
export class GetMostRecentDatasetPath {
	/**
	 * Period Code for dataset lookup
	 * Required: true
	 * Array: false
	 */
	periodCd:string = undefined;
	/**
	 * Date extract argument Id for dataset lookup
	 * Required: true
	 * Array: false
	 */
	argumentId:string = undefined;
}
/**
 * Get most recent dataset response
 */
export class GetMostRecentDatasetResp {
	/**
	 * Dataset being returned.
	 * Required: false
	 * Array: false
	 */
	dataset:Dataset = undefined;
}
/**
 * Request used to launch the Module Group.
 */
export class LaunchModuleGroupRqst {
	/**
	 * The unique identifier for the module group to be launched.
	 * Required: true
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
}
/**
 * This contains the result of launching a module group.
 */
export class LaunchModuleGroupResp {
	/**
	 * The unique identifier for the module group to be launched.
	 * Required: true
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
	/**
	 * The DAG name for the module group.
	 * Required: false
	 * Array: false
	 */
	dagName:string = undefined;
	/**
	 * The string representation of the date and time the module group executed using ISO date-time format with an offset, such as '2011-12-03T10:15:30+01:00'.
	 * Required: false
	 * Array: false
	 */
	executionDateTimeString:string = undefined;
	/**
	 * The date and time the module group executed.
	 * Required: false
	 * Array: false
	 */
	executionDateTime:Date = undefined;
}
/**
 * Path parameter used for deleting a DataExtract.
 */
export class ListDataExtractsQuery {
	/**
	 * Period Code of dataset for which to find the data extracts.
	 * Required: false
	 * Array: false
	 */
	periodCd:string = undefined;
	/**
	 * The type of data extract that defines how this entry might be used.
	 * Required: false
	 * Array: false
	 */
	dataExtractTypeCd:ModuleExecutorDataExtractTypeCd = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of data extracts request
 */
export class ListDataExtractsResp {
	/**
	 * All data extracts are returned.
	 * Required: false
	 * Array: true
	 */
	dataExtract:DataExtract[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request for listing dataset period codes
 */
export class ListDatasetPeriodCdsQuery {
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of dataset period codes request
 */
export class ListDatasetPeriodCdsResp {
	/**
	 * All period codes are returned.
	 * Required: false
	 * Array: true
	 */
	periodCd:string[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request for listing of dataExtracts requests.
 */
export class ListDatasetsQuery {
	/**
	 * The unique identifier for the dataExtract.
	 * Required: false
	 * Array: false
	 */
	dataExtractInstId:number = undefined;
	/**
	 * Period Code for dataset.
	 * Required: false
	 * Array: false
	 */
	periodCd:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of datasets request
 */
export class ListDatasetsResp {
	/**
	 * All datasets are returned.
	 * Required: false
	 * Array: true
	 */
	datasets:Dataset[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request for listing of moduleGroup run requests.
 */
export class ListModuleGroupRunRequestsQuery {
	/**
	 * Required: false
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of moduleGroup run request request.
 */
export class ListModuleGroupRunRequestsResp {
	/**
	 * All moduleVersions are returned.
	 * Required: false
	 * Array: true
	 */
	moduleGroupRunRequests:ModuleGroupRunRequest[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Path parameter used for listing run statuses for a module group.
 */
export class ListModuleGroupRunStatusesPath {
	/**
	 * The unique identifier for the moduleGroup.
	 * Required: true
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
}
/**
 * List module group run statuses response
 */
export class ListModuleGroupRunStatusesResp {
	/**
	 * Run status of a Module Group.
	 * Required: false
	 * Array: true
	 */
	runStatus:ModuleGroupRunStatus[] = undefined;
}
/**
 * Query parameters used to list all module groups that are available.
 */
export class ListModuleGroupsQuery {
	/**
	 * The unique identifier for the module version.
	 * Required: false
	 * Array: false
	 */
	moduleVersionSeqNbr:number = undefined;
	/**
	 * The unique identifier for the module.
	 * Required: false
	 * Array: false
	 */
	moduleInstId:number = undefined;
	/**
	 * Determines when a dynamic dag is required (Y).  Otherwise this field defaults to N.
	 * Required: false
	 * Array: false
	 */
	dynamicDagInd:boolean = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list all module groups that are availble.
 */
export class ListModuleGroupsResp {
	/**
	 * All moduleGroups are returned.
	 * Required: false
	 * Array: true
	 */
	moduleGroups:ModuleGroup[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Path parameters used for listing of moduleGrpMVersionRunResult.
 */
export class ListModuleGrpMVersionRunResultsPath {
	/**
	 * Required: false
	 * Array: false
	 */
	runInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	moduleVersionSeqNbr:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	moduleInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	runResultSequenceNbr:number = undefined;
}
/**
 * Query parameter used for listing of moduleGrpMVersionRunResult.
 */
export class ListModuleGrpMVersionRunResultsQuery {
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of dataExtract run request request.
 */
export class ListModuleGrpMVersionRunResultsResp {
	/**
	 * All moduleGrpMVersionRunResult are returned.
	 * Required: false
	 * Array: true
	 */
	ModuleGrpMVersionRunResults:MgrpMverRunResult[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request for listing modules
 */
export class ListModulesQuery {
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of modules request
 */
export class ListModulesResp {
	/**
	 * All modules are returned.
	 * Required: false
	 * Array: true
	 */
	modules:Module[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Parameters for listing notifications.
 */
export class ListNotificationsQuery {
	/**
	 * The number of minutes of the notification window.
	 * Required: true
	 * Array: false
	 */
	notificationWindowMinutes:number = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response for listing of notifications request
 */
export class ListNotificationsResp {
	/**
	 * All data extracts are returned.
	 * Required: false
	 * Array: true
	 */
	notification:Notification[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Path parameter used for scheduling a ModuleGroup.
 * 			
 */
export class ScheduleModuleGroupExecutionRqst {
	/**
	 * The unique identifier for the module group.
	 * Required: true
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
	/**
	 * The string that acts as a cron expression to schedule the execution
	 * Required: false
	 * Array: false
	 */
	scheduleCronExpression:string = undefined;
}
/**
 * Path parameter used for unscheduling a module group execution.
 */
export class UnscheduleModuleGroupExecutionPath {
	/**
	 * The unique identifier for the module group.
	 * Required: true
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
}
/**
 * Request for updating or inserting a DataExtract.
 */
export class UpsertDataExtractRqst {
	/**
	 * DataExtract being updated or inserted.
	 * Required: true
	 * Array: false
	 */
	dataExtract:DataExtract = undefined;
}
/**
 * Response to the request for updating or inserting a DataExtract.
 */
export class UpsertDataExtractResp {
	/**
	 * The PK of the table that was inserted.
	 * Required: false
	 * Array: false
	 */
	dataExtractInstId:number = undefined;
}
/**
 * Request for updating or inserting a Dataset.
 */
export class UpsertDatasetRqst {
	/**
	 * Dataset being updated or inserted.
	 * Required: true
	 * Array: false
	 */
	dataset:Dataset = undefined;
}
/**
 * Response to the request for updating or inserting a Dataset.
 */
export class UpsertDatasetResp {
	/**
	 * The dataset Inst Id upserted.
	 * Required: false
	 * Array: false
	 */
	datasetInstId:number = undefined;
	/**
	 * The dataExtract Inst Id upserted.
	 * Required: false
	 * Array: false
	 */
	dataExtractInstId:number = undefined;
}
/**
 * Request for inserting or updating a Module and ModuleVersion.
 */
export class UpsertModuleAndModuleVersionByNameRqst {
	/**
	 * Name of the module.
	 * Required: true
	 * Array: false
	 */
	moduleName:string = undefined;
	/**
	 * Description of the module.
	 * Required: false
	 * Array: false
	 */
	moduleDescription:string = undefined;
	/**
	 * Source code project name
	 * Required: true
	 * Array: false
	 */
	sourceCodeProjectName:string = undefined;
	/**
	 * Version identifier associated with the module.
	 * Required: true
	 * Array: false
	 */
	version:string = undefined;
	/**
	 * The maven repo location for this module version.
	 * Required: true
	 * Array: false
	 */
	mavenRepoLocation:string = undefined;
	/**
	 * The image name in the container registry that will be used for executing this module version.
	 * Required: true
	 * Array: false
	 */
	containerRegistryImageName:string = undefined;
	/**
	 * The user who executed this service.
	 * Required: true
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * The config.yaml for the kubernetes engine job	configuration.
	 * Required: true
	 * Array: false
	 */
	baseJobConfiguration:string = undefined;
}
/**
 * Response for upsert of module
 */
export class UpsertModuleAndModuleVersionByNameResp {
	/**
	 * moduleInstId
	 * Required: true
	 * Array: false
	 */
	moduleInstId:number = undefined;
	/**
	 * moduleVersionSequenceNbr
	 * Required: true
	 * Array: false
	 */
	moduleVersionSequenceNbr:number = undefined;
}
/**
 * Request for updating or inserting a ModuleGroup.
 */
export class UpsertModuleGroupRqst {
	/**
	 * ModuleGroup being updated or inserted.
	 * Required: true
	 * Array: false
	 */
	moduleGroup:ModuleGroup = undefined;
}
/**
 * Response for upsert of moduleGroups
 */
export class UpsertModuleGroupResp {
	/**
	 * moduleGroupInstId
	 * Required: false
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
}
/**
 * Request for updating or inserting a ModuleGroupRunRequest.
 */
export class UpsertModuleGroupRunRequestRqst {
	/**
	 * ModuleGroupRunRequest being updated or inserted.
	 * Required: true
	 * Array: false
	 */
	moduleGroupRunRequest:ModuleGroupRunRequest = undefined;
}
/**
 * Response for upsert of UpsertModuleGroupRunRequest
 */
export class UpsertModuleGroupRunRequestResp {
	/**
	 * moduleGroupInstId
	 * Required: true
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
	/**
	 * runInstId
	 * Required: true
	 * Array: false
	 */
	runInstId:number = undefined;
}
/**
 * Request for updating or inserting a ModuleGrpMVersionRunResult.
 */
export class UpsertModuleGrpMVersionRunResultRqst {
	/**
	 * ModuleGrpMVersionRunResult being updated or	inserted.
	 * Required: true
	 * Array: false
	 */
	moduleGrpMVersionRunResult:MgrpMverRunResult = undefined;
}
/**
 * Response for upsert of UpsertModuleGrpMVersionRunResult
 */
export class UpsertModuleGrpMVersionRunResultResp {
	/**
	 * moduleInstId
	 * Required: false
	 * Array: false
	 */
	moduleInstId:number = undefined;
	/**
	 * moduleGroupInstId
	 * Required: false
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
	/**
	 * moduleVersionSequenceNbr
	 * Required: false
	 * Array: false
	 */
	moduleVersionSequenceNbr:number = undefined;
	/**
	 * moduleGroupModuleVersionSequenceNbr
	 * Required: false
	 * Array: false
	 */
	moduleGroupModuleVersionSequenceNbr:number = undefined;
	/**
	 * runInstId
	 * Required: false
	 * Array: false
	 */
	runInstId:number = undefined;
	/**
	 * Unique identifier for the module version run results for a module group.
	 * Required: false
	 * Array: false
	 */
	runResultSequenceNbr:number = undefined;
	/**
	 * Date and time the module group executed.
	 * Required: false
	 * Array: false
	 */
	executionDateTime:Date = undefined;
}
/**
 * This contains the run status of a module group.
 */
export class ModuleGroupRunStatus {
	/**
	 * Date and time the module group executed.
	 * Required: false
	 * Array: false
	 */
	executionDateTime:Date = undefined;
	/**
	 * The run status of the module group execution.
	 * Required: true
	 * Array: false
	 */
	runStatus:string = undefined;
	/**
	 * The Airflow Graph View Hyperlink.
	 * Required: false
	 * Array: false
	 */
	graphViewHyperlink:string = undefined;
}
/**
 * This contain a message that is notified to the application.
 */
export class Notification {
	/**
	 * Type of the notification.
	 * Required: false
	 * Array: false
	 */
	notificationType:string = undefined;
	/**
	 * The message of the notification.
	 * Required: false
	 * Array: false
	 */
	notificationMessage:string = undefined;
	/**
	 * The date and time of the change that is to be notified on.
	 * Required: false
	 * Array: false
	 */
	notificationDateTime:Date = undefined;
}
/**
 * 
 */
export class Dataset {
	/**
	 * Unique identifier for the dataset.
	 * Required: false
	 * Array: false
	 */
	datasetInstId:number = undefined;
	/**
	 * Unique identifier for the data extraction.
	 * Required: false
	 * Array: false
	 */
	dataExtractInstId:number = undefined;
	/**
	 * Identifies the type of the dataset. 
	 * 
	 * Possible values: 
	 * DATAEXTRACTORIGINAL
	 * DATAEXTRACTMODIFIED
	 * CONFIG
	 * Required: false
	 * Array: false
	 */
	datasetTypeCd:ModuleExecutorDatasetTypeCd = undefined;
	/**
	 * Physical location of the dataset.
	 * Required: false
	 * Array: false
	 */
	datasetLocation:string = undefined;
	/**
	 * Month and year for the data extracted.  Added 10/09/2019
	 * Required: false
	 * Array: false
	 */
	periodCd:string = undefined;
	/**
	 * Timestamp the data extract executed.  Added 10/09/2019
	 * Required: false
	 * Array: false
	 */
	dataExtractDateTime:Date = undefined;
	/**
	 * Same value for CORRELATION id 
	 * 
	 * is assigned to various rows from different tables that form a single business 
	 * 
	 * transactions. 
	 * In other words, this groups multipple rows across various tables for a 
	 * 
	 * single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class DataExtract {
	/**
	 * Unique identifier for the data extraction.
	 * Required: false
	 * Array: false
	 */
	dataExtractInstId:number = undefined;
	/**
	 * Name of the data extraction.
	 * Required: false
	 * Array: false
	 */
	dataExtractName:string = undefined;
	/**
	 * This is the unique identifier for the data quality rule for this data extraction.  Added 10/09/2019
	 * Required: false
	 * Array: false
	 */
	dataQualityRuleId:number = undefined;
	/**
	 * Same value for CORRELATION id 
	 * 
	 * is assigned to various rows from different tables that form a single business 
	 * 
	 * transactions. 
	 * In other words, this groups multipple rows across various tables for a 
	 * 
	 * single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Identifier of the argument that the Component requires for operation. An example might be Shp for output for Shipment data extract.
	 * Required: false
	 * Array: false
	 */
	argumentId:string = undefined;
	/**
	 * The type of data extract that defines how this entry might be used.    Added 06/11/2020
	 * 
	 * Possible values
	 * PERIODCODE 
	 * CONSTANT
	 * Required: false
	 * Array: false
	 */
	dataExtractTypeCd:ModuleExecutorDataExtractTypeCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class MgrpMverRunResult {
	/**
	 * Unique identifier for the module group run request.
	 * Required: false
	 * Array: false
	 */
	runInstId:number = undefined;
	/**
	 * Unique identifier for the module group/module/module version.  This will restart at 1 for the other fields of the primary key.(changed length from 15 to 4)
	 * Required: false
	 * Array: false
	 */
	moduleGroupModuleVersionSequenceNbr:string = undefined;
	/**
	 * Unique identifier for the modules version.
	 * Required: false
	 * Array: false
	 */
	moduleVersionSequenceNbr:number = undefined;
	/**
	 * Unique identifier for the module group.
	 * Required: false
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
	/**
	 * Unique identifier for the module.
	 * Required: false
	 * Array: false
	 */
	moduleInstId:number = undefined;
	/**
	 * Unique identifier for the module version run results for a module group.
	 * Required: false
	 * Array: false
	 */
	runResultSequenceNbr:number = undefined;
	/**
	 * Date and time the module group executed.  Added 10/09/2019
	 * Required: false
	 * Array: false
	 */
	executionDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	successfulInd:ModuleExecutorSuccessfulCd = undefined;
	/**
	 * Same value for CORRELATION id 
	 * 
	 * is assigned to various rows from different tables that form a single business 
	 * 
	 * transactions. 
	 * In other words, this groups multipple rows across various tables for a 
	 * 
	 * single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Status message for the successful_ind. This may contain an error message or whatever the module would need to report out.  Added 12/17/2019
	 * Required: false
	 * Array: false
	 */
	statusMessage:string = undefined;
}
/**
 * 
 */
export class Module {
	/**
	 * Unique identifier for the module.
	 * Required: false
	 * Array: false
	 */
	moduleInstId:number = undefined;
	/**
	 * Name of the module.
	 * Required: false
	 * Array: false
	 */
	moduleName:string = undefined;
	/**
	 * Description of the module.
	 * Required: false
	 * Array: false
	 */
	moduleDescription:string = undefined;
	/**
	 * Source code project name
	 * Required: false
	 * Array: false
	 */
	sourceCodeProjectName:string = undefined;
	/**
	 * Same value for CORRELATION id 
	 * 
	 * is assigned to various rows from different tables that form a single business 
	 * 
	 * transactions. 
	 * In other words, this groups multipple rows across various tables for a 
	 * 
	 * single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	moduleVersion:ModuleVersion[] = undefined;
}
/**
 * 
 */
export class ModuleGroup {
	/**
	 * Unique identifier for the module group.
	 * Required: false
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
	/**
	 * Name of the module group.  05/27/2020 Increased length from 50 to 75
	 * Required: false
	 * Array: false
	 */
	moduleGroupName:string = undefined;
	/**
	 * Description of the module group.
	 * Required: false
	 * Array: false
	 */
	moduleGroupDescription:string = undefined;
	/**
	 * Same value for CORRELATION id 
	 * 
	 * is assigned to various rows from different tables that form a single business 
	 * 
	 * transactions. 
	 * In other words, this groups multipple rows across various tables for a 
	 * 
	 * single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * The cron schedule string created when the schedule is created in the UI, and sent to Airflow in the DAG.  Added 12/13/2019
	 * Required: false
	 * Array: false
	 */
	schedule:string = undefined;
	/**
	 * When a dynamic dag is required, this would be Y otherwise this field defaults to N.  Added 05/28/2020
	 * Required: false
	 * Array: false
	 */
	dynamicDagInd:boolean = undefined;
	/**
	 * Contains the code necessary that would be added to the DAG built by XME to provide the dynamic execution that would be required.  Added 05/28/2020 Flipped from BLOB to VARCHAR 06/05/2020
	 * Required: false
	 * Array: false
	 */
	dynamicDag:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Run Status of the Module Group.
	 * Required: false
	 * Array: false
	 */
	runStatus:string = undefined;
	/**
	 * Execution date and time for last run Status of the Module Group.
	 * Required: false
	 * Array: false
	 */
	executionTmst:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	moduleGroupModuleVersion:ModuleGroupModuleVersion[] = undefined;
}
/**
 * 
 */
export class ModuleGroupExecParm {
	/**
	 * Unique identifier for the module group module execution parameter.
	 * Required: false
	 * Array: false
	 */
	moduleGroupExecParmId:number = undefined;
	/**
	 * Unique identifier for the modules version.
	 * Required: false
	 * Array: false
	 */
	moduleVersionSequenceNbr:number = undefined;
	/**
	 * Unique identifier for the module group.
	 * Required: false
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
	/**
	 * Unique identifier for the module.
	 * Required: false
	 * Array: false
	 */
	moduleInstId:number = undefined;
	/**
	 * Unique identifier for the module group/module/module version.  This will restart at 1 for the other fields of the primary key.(changed length from 15 to 4)
	 * Required: false
	 * Array: false
	 */
	moduleGroupModuleVersionSequenceNbr:string = undefined;
	/**
	 * Identifies the sequence the parameters should be passed to the module.
	 * Required: false
	 * Array: false
	 */
	argumentSequenceNbr:string = undefined;
	/**
	 * The type of argument used.
	 * 
	 * VALD VALUES
	 * SPECIFICDATASET
	 * CONFIG
	 * MAINCLASS
	 * CURRENTDATASET
	 * LASTMONTHDATASET
	 * Required: false
	 * Array: false
	 */
	argumentTypeCd:ModuleExecutorParmsArgTypeCd = undefined;
	/**
	 * Identifier of the argument that the Component requires for operation.  An example might be -o for output, or -i for input.
	 * Required: false
	 * Array: false
	 */
	argumentId:string = undefined;
	/**
	 * For arguments that are not DATASET, this would be the actual value of the argument.
	 * Required: false
	 * Array: false
	 */
	argument:string = undefined;
	/**
	 * Same value for CORRELATION id 
	 * 
	 * is assigned to various rows from different tables that form a single business 
	 * 
	 * transactions. 
	 * In other words, this groups multipple rows across various tables for a 
	 * 
	 * single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Unique identifier for the dataset.
	 * Required: false
	 * Array: false
	 */
	parentDatasetInstId:number = undefined;
	/**
	 * Unique identifier for the data extraction.
	 * Required: false
	 * Array: false
	 */
	parentDataExtractInstId:number = undefined;
}
/**
 * 
 */
export class ModuleGroupModuleVersion {
	/**
	 * Unique identifier for the modules version.
	 * Required: false
	 * Array: false
	 */
	moduleVersionSequenceNbr:number = undefined;
	/**
	 * Unique identifier for the module group.
	 * Required: false
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
	/**
	 * Unique identifier for the module.
	 * Required: false
	 * Array: false
	 */
	moduleInstId:number = undefined;
	/**
	 * Unique identifier for the module group/module/module version.  This will restart at 1 for the other fields of the primary key.(changed length from 15 to 4)
	 * Required: false
	 * Array: false
	 */
	moduleGroupModuleVersionSequenceNbr:string = undefined;
	/**
	 * Identifies the sequence the module should execute.  It is ok to have the same sequence number for the same MODULE_GRP_INST_ID as this will designate parallel processing.
	 * Required: false
	 * Array: false
	 */
	executionOrderNbr:string = undefined;
	/**
	 * The config.yaml for the kubernetes engine job configuration.
	 * Required: false
	 * Array: false
	 */
	jobConfiguration:string = undefined;
	/**
	 * Same value for CORRELATION id 
	 * 
	 * is assigned to various rows from different tables that form a single business 
	 * 
	 * transactions. 
	 * In other words, this groups multipple rows across various tables for a 
	 * 
	 * single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Designates whether or not the EXECUTION_ORDER_NBR requires looping or not. Y indicates looping is required, otherwise default to N.  Added 05/28/2020
	 * Required: false
	 * Array: false
	 */
	execOrderLoopInd:boolean = undefined;
	/**
	 * Contains the sequence a module version exists in the execution order assigned. When the module group has a dynamic DAG and the step is considered a loop, this will indicate the order for which the module versions should be executed in sequence.  Added 06/18/2020
	 * Required: false
	 * Array: false
	 */
	execOrderSequenceNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Name of the module.
	 * Required: false
	 * Array: false
	 */
	parentModuleName:string = undefined;
	/**
	 * Version identifier associated with the module.
	 * Required: false
	 * Array: false
	 */
	parentVersion:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	moduleGroupExecParm:ModuleGroupExecParm[] = undefined;
}
/**
 * 
 */
export class ModuleGroupRunRequest {
	/**
	 * Unique identifier for the module group.
	 * Required: false
	 * Array: false
	 */
	moduleGroupInstId:number = undefined;
	/**
	 * Unique identifier for the module group run request.
	 * Required: false
	 * Array: false
	 */
	runInstId:number = undefined;
	/**
	 * Date and time the module group executed.  Added 10/09/2019
	 * Required: false
	 * Array: false
	 */
	executionDateTime:Date = undefined;
	/**
	 * Same value for CORRELATION id 
	 * 
	 * is assigned to various rows from different tables that form a single business 
	 * 
	 * transactions. 
	 * In other words, this groups multipple rows across various tables for a 
	 * 
	 * single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ModuleVersion {
	/**
	 * Unique identifier for the module.
	 * Required: false
	 * Array: false
	 */
	moduleInstId:number = undefined;
	/**
	 * Unique identifier for the modules version.
	 * Required: false
	 * Array: false
	 */
	moduleVersionSequenceNbr:number = undefined;
	/**
	 * Version identifier associated with the module.
	 * Required: false
	 * Array: false
	 */
	version:string = undefined;
	/**
	 * The maven repo location for this module version.
	 * Required: false
	 * Array: false
	 */
	mavenRepoLocation:string = undefined;
	/**
	 * The image name in the container registry that will be used for executing this module version.
	 * Required: false
	 * Array: false
	 */
	containerRegistryImageName:string = undefined;
	/**
	 * The config.yaml for the kubernetes engine job configuration.
	 * Required: false
	 * Array: false
	 */
	baseJobConfiguration:string = undefined;
	/**
	 * Same value for CORRELATION id 
	 * 
	 * is assigned to various rows from different tables that form a single business 
	 * 
	 * transactions. 
	 * In other words, this groups multipple rows across various tables for a 
	 * 
	 * single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}


