import { ChangeDetectorRef, Directive, OnDestroy, OnInit, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { XpoBoardDataSourceResolver } from './board-data-source-resolver.service';
import { XpoBoardDataSource } from './board-data-source.model';
import { XpoBoardData } from './board-data.model';
import { XpoBoardStateViewer } from './board-state-viewer.model';
import { XpoBoardState } from './board-state.model';

// TODO: Document
@Directive()
export abstract class XpoBoardConsumer implements XpoBoardStateViewer, OnInit, OnDestroy {
  readonly stateChange$ = new Subject<XpoBoardState>();

  protected componentDestroyed$ = new Subject<void>();

  private dataSourceValue: XpoBoardDataSource<XpoBoardData> = null;

  constructor(
    @Optional() protected boardDataSourceResolver: XpoBoardDataSourceResolver,
    protected cd: ChangeDetectorRef
  ) {}

  get dataSource(): XpoBoardDataSource<XpoBoardData> {
    return this.dataSourceValue;
  }

  ngOnInit(): void {
    if (this.boardDataSourceResolver) {
      // this maybe a little too purist and will not work with
      // ag-grid because we might need some-kind of wrapper around it?
      // but maybe not since the ag-grid only has to worry about not double publishing the view change
      this.boardDataSourceResolver.dataSource$
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((ds) => this.onDataSourceChanged(ds));
    }
  }

  ngOnDestroy(): void {
    if (this.dataSourceValue) {
      this.dataSourceValue.disconnect(this);
    }

    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  // TODO: this should not be a 100% abstract and put logic in here about figuring out whether the view changed?
  protected abstract onStateChange(state: XpoBoardState): void;

  /**
   * Method that will be called every time the dataSource on the board changes
   */
  protected onDataSourceChanged(ds: XpoBoardDataSource): void {
    if (this.dataSourceValue) {
      this.dataSourceValue.disconnect(this);
    }

    this.dataSourceValue = ds;

    if (ds) {
      const connections = ds.connect(this);

      // TODO: should we be cleaning these up on data-source change?
      connections.pipe(takeUntil(this.componentDestroyed$)).subscribe((data) => {
        if (data.stateVersion >= this.dataSource.stateVersion) {
          this.onStateChange(data);
          this.cd.markForCheck();
        }
      });
    }
  }
}
