import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'module-group-mgmt-name-cell-renderer',
  templateUrl: './name-cell-renderer.component.html',
  styleUrls: ['./name-cell-renderer.component.scss'],
  host: {
    class: 'ModuleGroupMgmtNameCellRenderer',
  },
})
export class ModuleGroupMgmtNameCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  nameText: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.nameText = this.params.value;
  }

  refresh(): boolean {
    return false;
  }
}
