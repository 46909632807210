import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { XpoBrowserClassification, XpoBrowserInfo, xpoBrowsersInfo } from '../models/index';

@Component({
  templateUrl: './browser-guard-dialog.component.html',
  styleUrls: ['browser-guard-dialog.component.scss'],
  host: { class: 'xpo-XpoBrowserGuardDialog' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class XpoBrowserGuardDialog {
  readonly xpoBrowsersInfo = xpoBrowsersInfo;
  suggestedBrowsersInfo: XpoBrowserInfo;

  constructor(@Inject(MAT_DIALOG_DATA) data: any) {
    if (data && data.suggestions && data.suggestions.length > 0) {
      this.suggestedBrowsersInfo = data.suggestions.map(
        (x: XpoBrowserClassification) => xpoBrowsersInfo[x.browser] || xpoBrowsersInfo.Unknown
      );
    }
  }
}
