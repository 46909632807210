import {DoorPlanProfileTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DoorPlanProfileTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DoorPlanProfileTypeCd ) {
      this._values.push(DoorPlanProfileTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DoorPlanProfileTypeCd {

    for ( const obj in DoorPlanProfileTypeCd ) {
      if (DoorPlanProfileTypeCd[obj] === value){
        return DoorPlanProfileTypeCd[obj] as DoorPlanProfileTypeCd;
      }
    }
  }
}

const DoorPlanProfileTypeCdHelper = new DoorPlanProfileTypeCdHelperEnumHelperClass();
export default DoorPlanProfileTypeCdHelper;
