import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModuleGroupRunStatus } from '@xpo-ltl/sdk-moduleexecutor';

@Component({
  selector: 'module-groups-previous-runs',
  templateUrl: './module-groups-previous-runs.component.html',
  styleUrls: ['./module-groups-previous-runs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleGroupsPreviousRunsComponent {
  @Input()
  runStatuses: ModuleGroupRunStatus[];

  constructor() {}
}
