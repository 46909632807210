import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Material */
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

/* XPO */
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import {
  XpoCardModule,
  XpoCheckboxModule,
  XpoCommonModule,
  XpoDialogModule,
  XpoIconModule,
  XpoSelectModule,
} from '@xpo-ltl/ngx-ltl-core';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ltl-grid';
import { SdkModuleExecutorModule } from '@xpo-ltl/sdk-moduleexecutor';
import { AgGridModule } from 'ag-grid-angular';

/* Components */
import {
  ModuleGroupMgmtActionsCellRendererComponent,
  ModuleGroupMgmtNameCellRendererComponent,
} from './cell-renderers/index';
import { ModuleGroupMgmtArgumentsDetailCellRendererComponent } from './detail-cell-renderers/index';
import { ModuleGroupMgmtExecutionStepComponent } from './module-group-mgmt-execution-step.component';

/* Pipes */
import { PipesModule } from '../../../../shared/pipes/pipes.module';

@NgModule({
  declarations: [
    ModuleGroupMgmtExecutionStepComponent,
    ModuleGroupMgmtActionsCellRendererComponent,
    ModuleGroupMgmtNameCellRendererComponent,
    ModuleGroupMgmtArgumentsDetailCellRendererComponent,
  ],
  imports: [
    CommonModule,
    XpoCommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    XpoIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    XpoCardModule,
    XpoDialogModule,
    XpoAgGridBoardModule,
    XpoCheckboxModule,
    XpoSelectModule,
    AgGridModule.withComponents([
      ModuleGroupMgmtActionsCellRendererComponent,
      ModuleGroupMgmtNameCellRendererComponent,
      ModuleGroupMgmtArgumentsDetailCellRendererComponent,
    ]),
    SdkModuleExecutorModule,
    XpoAgGridModule,
    FlexLayoutModule,
    PipesModule,
  ],
  exports: [ModuleGroupMgmtExecutionStepComponent],
})
export class ModuleGroupMgmtExecutionStepModule {}
