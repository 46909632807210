import {AddressTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class AddressTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in AddressTypeCd ) {
      this._values.push(AddressTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): AddressTypeCd {

    for ( const obj in AddressTypeCd ) {
      if (AddressTypeCd[obj] === value){
        return AddressTypeCd[obj] as AddressTypeCd;
      }
    }
  }
}

const AddressTypeCdHelper = new AddressTypeCdHelperEnumHelperClass();
export default AddressTypeCdHelper;
