import {HttpMethod} from '../';
import {EnumHelper} from './enum-helper';

export class HttpMethodHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in HttpMethod ) {
      this._values.push(HttpMethod[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): HttpMethod {

    for ( const obj in HttpMethod ) {
      if (HttpMethod[obj] === value){
        return HttpMethod[obj] as HttpMethod;
      }
    }
  }
}

const HttpMethodHelper = new HttpMethodHelperEnumHelperClass();
export default HttpMethodHelper;
