import {PaymentStatusInternalCd} from '../';
import {EnumHelper} from './enum-helper';

export class PaymentStatusInternalCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PaymentStatusInternalCd ) {
      this._values.push(PaymentStatusInternalCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PaymentStatusInternalCd {

    for ( const obj in PaymentStatusInternalCd ) {
      if (PaymentStatusInternalCd[obj] === value){
        return PaymentStatusInternalCd[obj] as PaymentStatusInternalCd;
      }
    }
  }
}

const PaymentStatusInternalCdHelper = new PaymentStatusInternalCdHelperEnumHelperClass();
export default PaymentStatusInternalCdHelper;
