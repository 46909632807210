import {CustomerRequestPriorityCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerRequestPriorityCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerRequestPriorityCd ) {
      this._values.push(CustomerRequestPriorityCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerRequestPriorityCd {

    for ( const obj in CustomerRequestPriorityCd ) {
      if (CustomerRequestPriorityCd[obj] === value){
        return CustomerRequestPriorityCd[obj] as CustomerRequestPriorityCd;
      }
    }
  }
}

const CustomerRequestPriorityCdHelper = new CustomerRequestPriorityCdHelperEnumHelperClass();
export default CustomerRequestPriorityCdHelper;
