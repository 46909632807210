import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';

import { XpoBoardApiAction, XpoBoardApiDispatcherService } from '../board/board-api/index';
import { XpoBoardConsumer, XpoBoardDataFetchState, XpoBoardDataSourceResolver, XpoBoardState } from '../models/index';
import { XpoDataDrawerDefaultDataSource } from './data-drawer-default-data-source.service';
import { XpoDataDrawerDataSource } from './models/data-drawer-data-source.model';
import { XpoDataDrawerState } from './models/data-drawer-state.model';

// future proofing for when we have multiple data-drawer sizes
export enum XpoDataDrawerVisibility {
  Closed = 'closed',
  Full = 'full',
  Minimized = 'minimized',
  Partial = 'partial',
}

@Directive({
  selector: 'xpo-data-drawer-header',
  host: { class: 'xpo-DataDrawerHeader' },
})
export class XpoDataDrawerHeader {}

export function DEFAULT_DATA_DRAWER_VISIBILITY_STRATEGY(focusedRecord: any, selection?: any[]): boolean {
  return !!focusedRecord;
}

@Component({
  selector: 'xpo-data-drawer',
  templateUrl: './data-drawer.component.html',
  styleUrls: ['./data-drawer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-DataDrawer' },
  providers: [XpoDataDrawerDefaultDataSource],
  exportAs: 'xpoDataDrawer',
})
export class XpoDataDrawer extends XpoBoardConsumer implements OnInit, OnDestroy {
  // allow the enum to be used in the html
  XpoBoardDataFetchState = XpoBoardDataFetchState;

  dataDrawerVisibility: XpoDataDrawerVisibility = XpoDataDrawerVisibility.Closed;
  dataFetchState$: Observable<XpoBoardDataFetchState>;

  private previousDrawerAppearance: XpoDataDrawerVisibility = XpoDataDrawerVisibility.Full;
  private dataDrawerDataSourceValue: XpoDataDrawerDataSource;
  private selectionValue: any[];
  private focusedRecordValue: any;

  @Input('dataSource')
  get dataDrawerDataSource(): XpoDataDrawerDataSource {
    return this.dataDrawerDataSourceValue;
  }
  set dataDrawerDataSource(v: XpoDataDrawerDataSource) {
    this.dataDrawerDataSourceValue = v;
    this.initDataDrawerDataSource();
  }

  @Input()
  get selection(): any[] {
    return this.selectionValue;
  }
  set selection(value: any[]) {
    this.selectionValue = value;
    this.updateDrawerStateWithInputValues();
  }

  @Input()
  get focusedRecord(): any {
    return this.focusedRecordValue;
  }
  set focusedRecord(value: any) {
    this.focusedRecordValue = value;
    this.updateDrawerStateWithInputValues();
  }

  @Input()
  drawerVisibilityStrategy: (
    focusedRecord: any,
    selection?: any[]
  ) => boolean = DEFAULT_DATA_DRAWER_VISIBILITY_STRATEGY;

  @Output()
  visibilityChange = new EventEmitter<boolean>();

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private defaultDataSource: XpoDataDrawerDefaultDataSource,
    @Optional() private boardApiDispatcherService: XpoBoardApiDispatcherService,
    @Optional() boardDataSourceResolver: XpoBoardDataSourceResolver
  ) {
    super(boardDataSourceResolver, changeDetectorRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // If no dataDrawerDataSource, run init and default it to the default data source,
    // if there is a dataDrawerDataSource it will call this function in the set method and skip
    // defaulting the value.
    if (!this.dataDrawerDataSource) {
      this.initDataDrawerDataSource();
    }

    if (this.boardApiDispatcherService) {
      this.boardApiDispatcherService.registerHandler(
        XpoBoardApiAction.ToggleDataDrawer,
        (focusedRecord: any, selection?: any[]) => {
          const dataDrawerState: XpoDataDrawerState<any> = { focusedRecord, selection: selection || [] };
          this.updateDataDrawerState(dataDrawerState);
        }
      );
    }
  }

  closedClicked(): void {
    this.dataDrawerVisibility = XpoDataDrawerVisibility.Closed;
    this.visibilityChange.emit(false);
  }

  retry(): void {
    this.dataDrawerDataSource.retrieveRecord();
  }

  protected onStateChange(state: XpoBoardState): void {
    if (!state.changes.includes('focusedRecord') && !state.changes.includes('selection')) {
      return;
    }

    // If state is repeated, don't continue
    const dataDrawerState = { focusedRecord: state.focusedRecord, selection: state.selection };

    this.updateDataDrawerState(dataDrawerState);
  }

  private setDrawerVisibility(visible: boolean): void {
    // if the strategy dictates we should be closed, then go ahead and set the closed state
    if (!visible) {
      this.dataDrawerVisibility = XpoDataDrawerVisibility.Closed;
      this.visibilityChange.emit(false);
      return;
    }

    // if the data drawer is closed, restore it to the previous state
    if (this.dataDrawerVisibility === XpoDataDrawerVisibility.Closed) {
      this.dataDrawerVisibility = this.previousDrawerAppearance;
      this.visibilityChange.emit(true);
    }
  }

  private initDataDrawerDataSource(): void {
    if (!this.dataDrawerDataSourceValue) {
      this.dataDrawerDataSourceValue = this.defaultDataSource;
    }

    this.dataFetchState$ = this.dataDrawerDataSourceValue.dataFetchState$;
  }

  /**
   * Updates the drawer state and drawer visibility
   * @param state
   */
  private updateDataDrawerState(state: XpoDataDrawerState<any>): void {
    const drawerVisible = this.drawerVisibilityStrategy(state.focusedRecord, state.selection);
    // Trigger the data source update only if we decided the drawer is visible
    if (drawerVisible) {
      this.dataDrawerDataSource.setDataDrawerState(state);
    }
    this.setDrawerVisibility(drawerVisible);
  }

  /**
   * Updates the drawer state with the component's inputted values values
   */
  private updateDrawerStateWithInputValues(): void {
    this.updateDataDrawerState({ focusedRecord: this.focusedRecord, selection: this.selection });
  }
}
