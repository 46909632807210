import {ExemptReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class ExemptReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ExemptReasonCd ) {
      this._values.push(ExemptReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ExemptReasonCd {

    for ( const obj in ExemptReasonCd ) {
      if (ExemptReasonCd[obj] === value){
        return ExemptReasonCd[obj] as ExemptReasonCd;
      }
    }
  }
}

const ExemptReasonCdHelper = new ExemptReasonCdHelperEnumHelperClass();
export default ExemptReasonCdHelper;
