import { Pipe, PipeTransform } from '@angular/core';
import { padStart as _padStart } from 'lodash';

@Pipe({ name: 'equipment' })
export class EquipmentPipe implements PipeTransform {
  transform(pfx: string, sfx: number): string {
    if (!pfx) {
      return '';
    }

    if (pfx.startsWith('0')) {
      pfx = pfx.substring(1, pfx.length);
    }
    const sfxStr = _padStart(`${sfx}`, 4, '0');
    return `${pfx}-${sfxStr}`;
  }
}
