import {DebtorCd} from '../';
import {EnumHelper} from './enum-helper';

export class DebtorCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DebtorCd ) {
      this._values.push(DebtorCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DebtorCd {

    for ( const obj in DebtorCd ) {
      if (DebtorCd[obj] === value){
        return DebtorCd[obj] as DebtorCd;
      }
    }
  }
}

const DebtorCdHelper = new DebtorCdHelperEnumHelperClass();
export default DebtorCdHelper;
