import { ChangeDetectorRef, Component } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { XpoCalendarHeaderService } from './calendar-header.service';

@Component({
  selector: 'xpo-date-range-calendar-header',
  exportAs: 'xpoDateRangeCalendarHeader',
  styleUrls: ['calendar-header.component.scss'],
  templateUrl: 'calendar-header.component.html',
  host: {
    class: 'xpo-DateRangeCalendarHeader',
  },
})
/**
 * Header component to be used as custom header inside each mat-calendar
 */
export class XpoDateRangeCalendarHeader<D> {
  constructor(
    private headerService: XpoCalendarHeaderService<D>,
    private calendar: MatCalendar<D>,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.calendar.stateChanges.subscribe(() => this.changeDetectorRef.detectChanges());
  }

  /**
   * Returns the text to be shown as the period title
   */
  get periodLabel(): string {
    return this.headerService.getPeriodLabel(this.calendar.activeDate).toLocaleUpperCase();
  }

  /**
   * Returns the aria-label for the previous button
   */
  get prevButtonAriaLabel(): string {
    return this.headerService.getPreviousAriaLabel();
  }

  /**
   * Returns the aria-label for the next button
   */
  get nextButtonAriaLabel(): string {
    return this.headerService.getNextAriaLabel();
  }

  /**
   * Returns if the previous button should be enabled
   */
  previousEnabled(): boolean {
    return this.headerService.previousEnabled(this.calendar.minDate, this.calendar.activeDate);
  }

  /**
   * Returns if the next button should be enabled
   */
  nextEnabled(): boolean {
    return this.headerService.nextEnabled(this.calendar.maxDate, this.calendar.activeDate);
  }

  /**
   * Handles the previous button click
   */
  onPreviousClicked(): void {
    // notify to show the previous period
    this.headerService.onPrevious.next();
  }

  /**
   * Handles the next button click
   */
  onNextClicked(): void {
    // notify to show the next period
    this.headerService.onNext.next();
  }
}
