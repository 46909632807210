import {MetricStageCd} from '../';
import {EnumHelper} from './enum-helper';

export class MetricStageCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MetricStageCd ) {
      this._values.push(MetricStageCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MetricStageCd {

    for ( const obj in MetricStageCd ) {
      if (MetricStageCd[obj] === value){
        return MetricStageCd[obj] as MetricStageCd;
      }
    }
  }
}

const MetricStageCdHelper = new MetricStageCdHelperEnumHelperClass();
export default MetricStageCdHelper;
