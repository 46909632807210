/**
 * (Nahuel Fava):
 *
 * Created this keyCode file based on Angular Material's CDK
 * https://github.com/angular/material2/blob/master/src/cdk/keycodes/keycodes.ts
 *
 */

export const ESCAPE = 'Escape';
export const LEFT_ARROW = 'ArrowLeft';
export const RIGHT_ARROW = 'ArrowRight';
