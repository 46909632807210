import { ColDef, ColGroupDef, Column, ColumnEvent, ColumnMovedEvent, ColumnState } from 'ag-grid-community';
import { XpoAgGridBoardColumn } from '../../models/ag-grid-board-column';

export class XpoColumnsHelper {
  /**
   * General logic to find and update the column in the original column definition
   * to keep the necessary properties updated when some action is performed on the grid
   * @param event General column event received from ag-grid event
   * @param action Action to perform regarding the necessary update of column def
   * @param columns The set of columns to be updated
   * @returns The updated set of columns after perform the action received over each affected column
   */
  static onGeneralColumnEvent(
    event: ColumnEvent,
    action: (colDef: ColDef, column: Column) => void,
    columns: XpoAgGridBoardColumn[]
  ): XpoAgGridBoardColumn[] {
    // Flag to avoid emitting unnecessary changes
    let isColDefChanged = false;
    // Iterate each received column in the event. Column with changes according ag-grid
    event.columns.forEach((updatedColumn) => {
      // Get the reference of it in our columnDefinition with its ID
      const colDef: ColDef = XpoColumnsHelper.getColRefById(updatedColumn.getColId(), columns);
      if (colDef) {
        // Perform the action received sending the colDef and the current column updated
        action(colDef, updatedColumn);
        isColDefChanged = true;
      }
    });
    // If no changes were done return null
    return isColDefChanged ? columns : null;
  }

  /**
   * This method uses a diff logic than the onGeneralColumnEvent
   * because we need to map and re-build the columnDef defined by the user
   * keeping it as a XpoAgGridBoardColumn type to be saved in the new view.
   * Here we map the columnState obtained from ag-grid with the original
   * columnDef set on the implementation.
   * @param event ColumnMovedEvent returned by ag-grid
   * @param columns The set of columns to be updated
   * @returns The new reordered set of columns
   */
  static onColumnMovedEvent(event: ColumnMovedEvent, columns: XpoAgGridBoardColumn[]): XpoAgGridBoardColumn[] {
    // Get the ag-grid columnState with the current state
    const columnsState: ColumnState[] = event.columnApi.getColumnState();
    // Create the new XpoAgGridBoardColumn container
    const newColumns: XpoAgGridBoardColumn[] = [];

    // Iterate each ag-grid column state
    columnsState.forEach((colState: ColumnState) => {
      // Get the original colDef matching the colId
      const colInDef: ColDef = XpoColumnsHelper.getColRefWithUniqueChildren(colState.colId, columns);
      if (colInDef) {
        // Push to the new container in the current order
        newColumns.push(colInDef);
      }
    });

    // As ag-grid returns the children separated we have to merge them
    // to match our XpoAgGridBoardColumn expected structure
    return XpoColumnsHelper.mergeConsecutiveParents(newColumns);
  }

  /**
   * Returns a column obtained in a set of them but removing its
   * children leaving just the one we are looking for in this case.
   * This is necessary due the structure of data that ag-grid gave
   * to us where al the children and parents are in the same level.
   * TODO: Recursive children - NGXLTL-XXX
   * @param searchedId The id we need to find in the dataset
   * @param origin The original dataset where we are searching
   * @returns Returns the a new colDef obtained from the origin but edited
   */
  static getColRefWithUniqueChildren(searchedId: string, origin: XpoAgGridBoardColumn[]): ColDef {
    // Iterate first level searching among parent columns
    let searchedColumn: XpoAgGridBoardColumn = XpoColumnsHelper.getColByColIdOneLevel(searchedId, origin);
    // If not found search in children columns
    if (!searchedColumn) {
      // Iterate each parent accessing their children
      searchedColumn = origin.find(({ children }: ColGroupDef) => {
        // Iterate each child searching the ID. If found return the parent
        return XpoColumnsHelper.getColByColIdOneLevel(searchedId, children);
      });
      // If parent was found. Remove children but the current child.
      // We're going to have this parent for each children so then
      // We merge (consecutive) parents into one avoiding having repeated children
      if (searchedColumn && !!(<ColGroupDef>searchedColumn).children) {
        // Cloning the col def because we are going to remove some columns
        // And we don't want to alter the original reference
        const clonedColumnDef: ColGroupDef = { ...(searchedColumn as ColGroupDef) };
        clonedColumnDef.children = clonedColumnDef.children.filter((child: ColDef) => child.colId === searchedId);
        searchedColumn = clonedColumnDef;
      }
    }
    return searchedColumn;
  }

  /**
   * Merge consecutive parents with the same colId
   * @param columns Set of columns
   * @returns A new set of columns where consecutive parents were merged into one
   */
  static mergeConsecutiveParents(columns: any[]): XpoAgGridBoardColumn[] {
    for (let i = 0; i < columns.length - 1; i++) {
      for (let j = i + 1; j < columns.length; j++) {
        // If the next column also has children and the same groupId
        if (
          columns[i].children &&
          columns[j].children &&
          columns[i].groupId &&
          columns[j].groupId &&
          columns[i].groupId === columns[j].groupId
        ) {
          // Take the second column child and push into current column, delete the second column
          columns[i].children.push(columns[j].children[0]);
          columns.splice(j--, 1);
        } else {
          break;
        }
      }
    }
    return columns;
  }

  /**
   * Looks for a col reference in the first level of the dataset
   * and through its children just one level.
   * TODO: Recursive children - NGXLTL-XXX
   * @param searchedId The searched col id
   * @param origin Dataset where look for
   * @returns Returns the col reference or undefined
   */
  static getColRefById(searchedId: string, origin: ColDef[]): ColDef {
    // Iterate first level searching among parent columns
    let searchedColumn: ColDef = XpoColumnsHelper.getColByColIdOneLevel(searchedId, origin);
    // If not found search in children columns
    if (!searchedColumn) {
      // Iterate each parent accessing their children. If some child if found, stop iteration
      origin.some(({ children }: ColGroupDef) => {
        // Iterate each child searching the ID
        return (searchedColumn = XpoColumnsHelper.getColByColIdOneLevel(searchedId, children));
      });
    }
    // If no column was found in parents and children (first level) return undefined
    return searchedColumn;
  }

  /**
   * Looks for a col reference in the first level of a dataset.
   * This column was abstracted to allow many methods to use it.
   * @param searchedId The searched col id
   * @param origin Dataset where look for
   * @returns Returns the col reference or undefined
   */
  static getColByColIdOneLevel(searchedId: string, origin: ColDef[]): ColDef {
    // If origin is defined, search inside
    return origin && searchedId && origin.find((col: ColDef) => col.colId && col.colId === searchedId);
  }

  static isColGroupDef(column: XpoAgGridBoardColumn): column is ColGroupDef {
    return !!(<ColGroupDef>column).children?.length;
  }

  /**
   * Iterates each column on a given dataset and if col id is not defined assigns
   * some custom value
   * @param columns Dataset of columns to check
   * @param parentId (Optional) Given parent id
   * @returns Return a dataset of XpoAgGridBoardColumn with col id on each column
   */
  static addIdsToColumns(columns: XpoAgGridBoardColumn[], parentId?: string): XpoAgGridBoardColumn[] {
    columns.forEach((col, index) => {
      if (XpoColumnsHelper.isColGroupDef(col)) {
        if (!col.groupId) {
          col.groupId = `group_${index}`;
        }
        col.children = XpoColumnsHelper.addIdsToColumns(col.children, col.groupId);
      } else {
        if (!col.colId) {
          col.colId = parentId ? `${parentId}_custom_${index}` : `id_${index}`;
        }
      }
    });
    return columns;
  }
}
