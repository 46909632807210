import { asyncScheduler, Observable, of as observableOf } from 'rxjs';

import { XpoBoardViewConfig } from './board-view-config.model';
import { XpoBoardViewDataStoreBase } from './board-view-data-store-base';

const XPO_LOCAL_STORAGE_FULL_ERROR_CODE = 22;
export class XpoLocalStorageBoardViewDataStore extends XpoBoardViewDataStoreBase {
  constructor(private localStorageKey: string, private initialViews: XpoBoardViewConfig[] = null) {
    super();
  }

  getAll(): Observable<XpoBoardViewConfig[]> {
    let views = [...(this.initialViews || [])];

    if (localStorage.getItem(this.localStorageKey) !== null) {
      // retrieve the views from local storage and add any views that are not already loaded to the view collection
      const storedViews: XpoBoardViewConfig[] = JSON.parse(localStorage.getItem(this.localStorageKey)) || [];
      views.push(...storedViews.filter((sv) => !views.some((iv) => iv.id === sv.id)));

      // copy any properties persisted on the template views
      views = views.map((v) => {
        const savedView = storedViews.find((sv) => sv.id === v.id);

        return savedView ? { ...v, ...savedView } : v;
      });
    }

    return observableOf(views, asyncScheduler);
  }

  updateDataStore(configs: XpoBoardViewConfig[]): Observable<XpoBoardViewConfig[]> {
    // TODO: This is just to prevent the application to fail and also a console error when storage is full,
    // we should find some way to notify the user but can't handle this refactor now and also projects
    // don't use local storage, they save for real in user preferences.
    try {
      localStorage.setItem(this.localStorageKey, JSON.stringify(configs));
    } catch (e) {
      // Local storage is full
      if (e.code === XPO_LOCAL_STORAGE_FULL_ERROR_CODE) {
        console.warn(`[XPO-LTL-BOARD] Storage is full, can't save this item.`);
      }
    }
    return observableOf(configs, asyncScheduler);
  }
}
