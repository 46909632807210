import { Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { XpoTileModel } from './models/tile.model';

const XPO_SELECTED_TILE_CLASS: string = 'xpo-Tile--selected';

@Component({
  selector: 'xpo-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  host: { class: 'xpo-Tile' },
})
export class XpoTileComponent {
  @Input() tile: XpoTileModel;

  @Output() tileClick: EventEmitter<XpoTileModel> = new EventEmitter<XpoTileModel>();

  @Output() linkClick: EventEmitter<XpoTileModel> = new EventEmitter<XpoTileModel>();

  private clickable: boolean = false;

  constructor(private renderer: Renderer2, private host: ElementRef) {}

  onClick(): void {
    if (this.clickable) {
      this.tileClick.emit(this.tile);
    }
  }

  onLinkClick(event: MouseEvent): void {
    event.cancelBubble = true;
    this.linkClick.emit(this.tile);
  }

  setClickable(): void {
    this.clickable = true;
  }

  setSelected(selected: boolean): void {
    if (selected) {
      this.renderer.addClass(this.host.nativeElement, XPO_SELECTED_TILE_CLASS);
    } else {
      this.renderer.removeClass(this.host.nativeElement, XPO_SELECTED_TILE_CLASS);
    }
  }
}
