import {PickupRequesterRoleCd} from '../';
import {EnumHelper} from './enum-helper';

export class PickupRequesterRoleCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PickupRequesterRoleCd ) {
      this._values.push(PickupRequesterRoleCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PickupRequesterRoleCd {

    for ( const obj in PickupRequesterRoleCd ) {
      if (PickupRequesterRoleCd[obj] === value){
        return PickupRequesterRoleCd[obj] as PickupRequesterRoleCd;
      }
    }
  }
}

const PickupRequesterRoleCdHelper = new PickupRequesterRoleCdHelperEnumHelperClass();
export default PickupRequesterRoleCdHelper;
