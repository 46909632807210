import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterContentInit, Directive, ElementRef, Input, NgZone } from '@angular/core';

import { take } from 'rxjs/operators';

@Directive({
  selector: '[xpoAutofocus]',
})
export class XpoAutofocusDirective implements AfterContentInit {
  private applyValue: boolean = true;
  private initialized: boolean = false;

  constructor(private el: ElementRef, private zone: NgZone) {}

  @Input('xpoAutofocus')
  get apply(): boolean {
    return this.applyValue;
  }

  set apply(value: boolean) {
    this.applyValue = coerceBooleanProperty(value);

    if (this.initialized) {
      this.focusElement();
    }
  }

  ngAfterContentInit(): void {
    this.initialized = true;
    if (!this.apply) {
      return;
    }

    this.focusElement();
  }

  private focusElement(): void {
    this.zone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.el.nativeElement.focus();
      });
  }
}
