import {AvailableStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class AvailableStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in AvailableStatusCd ) {
      this._values.push(AvailableStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): AvailableStatusCd {

    for ( const obj in AvailableStatusCd ) {
      if (AvailableStatusCd[obj] === value){
        return AvailableStatusCd[obj] as AvailableStatusCd;
      }
    }
  }
}

const AvailableStatusCdHelper = new AvailableStatusCdHelperEnumHelperClass();
export default AvailableStatusCdHelper;
