import {WhatIfDownloadFileTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class WhatIfDownloadFileTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in WhatIfDownloadFileTypeCd ) {
      this._values.push(WhatIfDownloadFileTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): WhatIfDownloadFileTypeCd {

    for ( const obj in WhatIfDownloadFileTypeCd ) {
      if (WhatIfDownloadFileTypeCd[obj] === value){
        return WhatIfDownloadFileTypeCd[obj] as WhatIfDownloadFileTypeCd;
      }
    }
  }
}

const WhatIfDownloadFileTypeCdHelper = new WhatIfDownloadFileTypeCdHelperEnumHelperClass();
export default WhatIfDownloadFileTypeCdHelper;
