import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/* Models */
import { ModuleLibraryVersion } from '../../models/module-library-version.model';

@Component({
  selector: 'delete-module-version-unable-dialog',
  templateUrl: 'delete-module-version-unable-dialog.component.html',
  styleUrls: ['delete-module-version-unable-dialog.component.scss'],
})
export class ModuleLibraryDeleteModuleVersionUnableDialogComponent {
  moduleLibraryVersionData: ModuleLibraryVersion;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ModuleLibraryVersion,
    private dialogRef: MatDialogRef<ModuleLibraryDeleteModuleVersionUnableDialogComponent>
  ) {
    this.moduleLibraryVersionData = data;
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }
}
