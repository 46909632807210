import {MessageLogStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class MessageLogStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MessageLogStatusCd ) {
      this._values.push(MessageLogStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MessageLogStatusCd {

    for ( const obj in MessageLogStatusCd ) {
      if (MessageLogStatusCd[obj] === value){
        return MessageLogStatusCd[obj] as MessageLogStatusCd;
      }
    }
  }
}

const MessageLogStatusCdHelper = new MessageLogStatusCdHelperEnumHelperClass();
export default MessageLogStatusCdHelper;
