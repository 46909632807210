import { Injectable } from '@angular/core';

/* XPO */
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';

/* Models */
import { ModuleLibraryVersion } from '../models/module-library-version.model';

/* Rxjs */
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/* Services */
import { ModuleLibraryService } from './module-library.service';

@Injectable()
export class ModuleLibraryBoardDataSource extends XpoBoardDataSource {
  constructor(protected moduleLibraryService: ModuleLibraryService) {
    super();
    this.pageSize = 100000;
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    return this.moduleLibraryService.listModuleVersions().pipe(
      map((rows) => {
        return new XpoBoardData(state, rows, rows.length, this.pageSize);
      })
    );
  }

  deleteRows(rowsToDelete: Array<ModuleLibraryVersion>): void {
    const rows: Array<ModuleLibraryVersion> = this.stateCache.data.consumerData;

    rowsToDelete.forEach((row) => {
      rows.splice(
        rows.findIndex((r) => r.moduleInstId === row.moduleInstId),
        1
      );
    });

    this.setState({
      data: new XpoBoardData(this.stateCache, rows, rows.length, this.pageSize),
      source: 'MANUAL-ROW-REMOVAL',
    });
  }

  deleteRow(rowToDelete: ModuleLibraryVersion): void {
    const rows: Array<ModuleLibraryVersion> = this.stateCache.data.consumerData;

    rows.splice(
      rows.findIndex(
        (r) =>
          r.moduleInstId === rowToDelete.moduleInstId &&
          r.moduleVersionSequenceNbr === rowToDelete.moduleVersionSequenceNbr
      ),
      1
    );

    this.setState({
      data: new XpoBoardData(this.stateCache, rows, rows.length, this.pageSize),
      source: 'MANUAL-ROW-REMOVAL',
    });
  }

  addNewRows(): void {
    this.moduleLibraryService.getNewModules().subscribe((newRows) => {
      if (newRows.length > 0) {
        const rows = newRows.concat(this.stateCache.data.consumerData);

        this.setState({
          data: new XpoBoardData(this.stateCache, rows, rows.length, this.pageSize),
          source: 'MANUAL-ROWS-ADD',
        });
      }
    });
  }
}
