import {DriverMessageOperationCd} from '../';
import {EnumHelper} from './enum-helper';

export class DriverMessageOperationCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DriverMessageOperationCd ) {
      this._values.push(DriverMessageOperationCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DriverMessageOperationCd {

    for ( const obj in DriverMessageOperationCd ) {
      if (DriverMessageOperationCd[obj] === value){
        return DriverMessageOperationCd[obj] as DriverMessageOperationCd;
      }
    }
  }
}

const DriverMessageOperationCdHelper = new DriverMessageOperationCdHelperEnumHelperClass();
export default DriverMessageOperationCdHelper;
