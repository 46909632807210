import {ConversationSourceCd} from '../';
import {EnumHelper} from './enum-helper';

export class ConversationSourceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ConversationSourceCd ) {
      this._values.push(ConversationSourceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ConversationSourceCd {

    for ( const obj in ConversationSourceCd ) {
      if (ConversationSourceCd[obj] === value){
        return ConversationSourceCd[obj] as ConversationSourceCd;
      }
    }
  }
}

const ConversationSourceCdHelper = new ConversationSourceCdHelperEnumHelperClass();
export default ConversationSourceCdHelper;
