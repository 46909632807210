import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { XpoGridSettingsColumnOption } from '../../../board-grid-toolbar/board-grid-toolbar-settings/board-settings-default-buttons/grid-settings-default-buttons';

@Component({
  selector: 'xpo-grid-column-selection',
  templateUrl: './grid-column-selection.component.html',
  styleUrls: ['./grid-column-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-GridColumnSelection' },
})
export class XpoGridColumnSelectionComponent {
  isSelectAllChecked: boolean = true;
  isSelectAllIndeterminate: boolean = false;

  @Input()
  get columnOptions(): XpoGridSettingsColumnOption[] {
    return this.columnOptionsValue;
  }
  set columnOptions(v: XpoGridSettingsColumnOption[]) {
    this.columnOptionsValue = v;
    this.updateSelectAllState();
  }
  private columnOptionsValue: XpoGridSettingsColumnOption[];

  @Output()
  columnOptionsChange = new EventEmitter<XpoGridSettingsColumnOption[]>();

  handleSelect(column: XpoGridSettingsColumnOption): void {
    column.isVisible = !column.isVisible;
    this.updateSelectAllState();

    this.columnOptionsChange.next(this.columnOptions);
  }

  selectAll(): void {
    this.isSelectAllChecked = !this.isSelectAllChecked;

    this.columnOptions.forEach((column) => {
      if (!column.lockVisible) {
        column.isVisible = this.isSelectAllChecked;
      }
    });

    this.columnOptionsChange.next(this.columnOptions);
  }

  private updateSelectAllState(): void {
    this.isSelectAllChecked = (this.columnOptions || []).every((column) => column.isVisible);
    this.isSelectAllIndeterminate =
      !this.isSelectAllChecked && (this.columnOptions || []).some((column) => column.isVisible);
  }
}
