import { get as _get } from 'lodash';
import { FloatLabelType } from '@angular/material/form-field';

export interface ISicSwitcherLabelOptions {
  title?: string;
  floatLabel?: FloatLabelType;
}

export class XpoLtlSicSwitcherLabelOptions implements ISicSwitcherLabelOptions {
  static readonly defaultTitle = 'Select SIC';
  static readonly defaultFloatLabel = 'never';
  title: string;
  floatLabel: FloatLabelType;

  constructor(props?: ISicSwitcherLabelOptions) {
    this.title = _get(props, 'title', XpoLtlSicSwitcherLabelOptions.defaultTitle);
    this.floatLabel = _get(props, 'floatLabel', XpoLtlSicSwitcherLabelOptions.defaultFloatLabel);
  }
}
