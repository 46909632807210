import { every } from 'lodash';
import { DateTimeModel } from '../conditional-filter/conditions/validators/date-time/between/between.component';
import { IsBetweenModel } from '../conditional-filter/conditions/validators/model.interface';
import { FilterFormats } from './filter-format.interface';

export const filterFormats: FilterFormats = {
  default: {
    match(model: any): boolean {
      return true;
    },
  },
  number: {
    match(model: any): boolean | RegExpMatchArray {
      return matchNumber(model);
    },
  },
  time: {
    match(model: any): boolean | RegExpMatchArray {
      return model.split(':').every((val) => matchNumber(val));
    },
  },
  range: {
    match(model: IsBetweenModel): boolean {
      return every([model.from, model.to], (num) => matchNumber(num));
    },
    range: { from: (model) => matchNumber(model.from), to: (model) => matchNumber(model.to) },
  },
  stringRange: {
    match(model: IsBetweenModel): boolean {
      return every([model.from, model.to], () => true);
    },
    range: { from: () => true, to: () => true },
  },
  dateTimeRange: {
    match(model: DateTimeModel): boolean {
      return every([model.dateFrom, model.dateTo], () => true);
    },
    dateRange: { from: () => true, to: () => true },
  },
};

function matchNumber(number: string | number): boolean | RegExpMatchArray {
  return (
    number === '' ||
    (number && number.toString().match(/^-{0,1}\d+$/)) ||
    (number && number.toString().match(/^-{0,1}\d+(\.\d{0,5})?$/))
  );
}
