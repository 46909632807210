import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'date-time-cell',
  templateUrl: './date-time-cell-renderer.component.html',
  styleUrls: ['./date-time-cell-renderer.component.scss'],
})
export class ModuleExecutorDateTimeRenderComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  getDateTime(value: Date): String {
    return formatDate(value, 'MM/dd/yyyy \' - \' HH:mm:ss', 'en-US');
  }
}
