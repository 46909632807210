import { Injectable } from '@angular/core';
import { ListNotificationsQuery, ListNotificationsResp, ModuleExecutorApiService } from '@xpo-ltl/sdk-moduleexecutor';
import { Observable } from 'rxjs';
import { NotificationLocalStorageKeys } from '../enums/notification-local-storage-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private apiService: ModuleExecutorApiService) {}

  getNotifications(notificationWindowMinutes): Observable<ListNotificationsResp> {
    const queryParams: ListNotificationsQuery = { notificationWindowMinutes, listInfo: null };
    return this.apiService.listNotifications(queryParams);
  }

  setLastReadNotification(timestamp): void {
    localStorage.setItem(NotificationLocalStorageKeys.lastCreatedVersion, timestamp.toString());
  }

  getLastReadNotification(): number {
    return Number(localStorage.getItem(NotificationLocalStorageKeys.lastCreatedVersion) || 0);
  }
}
