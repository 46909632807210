import {UploadDynamicPricingFileOperationCd} from '../';
import {EnumHelper} from './enum-helper';

export class UploadDynamicPricingFileOperationCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in UploadDynamicPricingFileOperationCd ) {
      this._values.push(UploadDynamicPricingFileOperationCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): UploadDynamicPricingFileOperationCd {

    for ( const obj in UploadDynamicPricingFileOperationCd ) {
      if (UploadDynamicPricingFileOperationCd[obj] === value){
        return UploadDynamicPricingFileOperationCd[obj] as UploadDynamicPricingFileOperationCd;
      }
    }
  }
}

const UploadDynamicPricingFileOperationCdHelper = new UploadDynamicPricingFileOperationCdHelperEnumHelperClass();
export default UploadDynamicPricingFileOperationCdHelper;
