import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { some } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

type DropDownIconArrow = 'arrow_drop_down' | 'arrow_drop_up';

@Component({
  selector: 'xpo-header-navigation-dyn-sub-menu',
  templateUrl: 'header-navigation-sub-menu.component.html',
  styleUrls: ['header-navigation-sub-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpoHeaderNavigationDynSubMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly ARROW_DROP_DOWN: DropDownIconArrow = 'arrow_drop_down';
  readonly ARROW_DROP_UP: DropDownIconArrow = 'arrow_drop_up';
  // Projected buttons coming from implementation
  private projectedButtons: ElementRef[] = [];
  menuIcon: DropDownIconArrow = this.ARROW_DROP_DOWN;
  isMenuOpened = false;
  underlineButton = false;
  // If showMenu=false hide the dropdown button
  showMenu = false;
  // Dynamic buttons coming from the lib when window is resized and buttons should be hidden
  dynButtons: ElementRef[] = [];
  updateButton$: Subject<any> = new Subject<any>();
  updateButtonSubscription: Subscription;
  @Input() menuTitle: string = 'Sub Menu';
  // Used to close the matMenu from here when user resizes the window
  @ViewChild('menuTrigger', { static: false }) menuTrigger: MatMenuTrigger;
  // Necessary to group and get the projectedButtons from <ng-content></ng-content>
  @ViewChild('projectedWrapper') projectedWrapper: ElementRef;

  constructor(private cd: ChangeDetectorRef, private router: Router) {}

  ngOnInit(): void {
    // Creating the update button as an observable improving performance
    // with a debounce avoiding multiple and unnecessary calls to the update process
    this.updateButtonSubscription = this.updateButton$
      .pipe(debounceTime(100))
      .subscribe((dynButtons: ElementRef[]) => this.updateButton(dynButtons));
  }

  ngAfterViewInit(): void {
    // We can't use a @ContentChild to get the buttons in a proper way because they are
    // projected and encapsulated in a <ng-content></ng-content>
    // Getting projected buttons from the wrapper. If no one is projected return empty array
    this.projectedButtons = this.projectedWrapper.nativeElement.getElementsByTagName('button') || [];
    this.updateButton$.next();
  }

  // Public method called from parent when the navbar is resized
  tryToCloseMenu(): void {
    if (this.menuTrigger) {
      this.menuTrigger.closeMenu();
    }
  }

  // Public method called from html
  // We can't use the material open() method due a limitation of them.
  // Our button that triggers the open/close sometimes isn't rendered.
  // They didn't provide it in the same way than the onMenuClose so
  // he have to connect with the menuTrigger API and it has a delay with the
  // ngAfterViewInit cycle.
  // Summary: This toggleMenu is called from a (click) in the button
  toggleMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;
    if (this.isMenuOpened) {
      this.menuIcon = this.ARROW_DROP_UP;
    }
    this.updateButton$.next();
  }

  // Public method called from html
  onMenuClose(): void {
    this.menuIcon = this.ARROW_DROP_DOWN;
    this.isMenuOpened = false;
    this.updateButton$.next();
  }

  // We update the buttons showed in the list and underline it if is active
  private updateButton(incomingDynButtons: ElementRef[]): void {
    // If no dynButtons are coming in parameters, left the current this.dynButtons
    this.dynButtons = incomingDynButtons || this.dynButtons;
    this.showMenu = this.projectedButtons.length > 0 || this.dynButtons.length > 0;
    this.underlineButton = this.isMenuOpened || this.isSomeChildButtonActive();
    this.cd.detectChanges();
  }

  private isSomeChildButtonActive(): boolean {
    // We iterate each projected and dynamic button comparing its routerLink with the
    // current url to know if it's the routerActiveLink and we should underline the
    // external button
    return some([...this.projectedButtons, ...this.dynButtons], ({ attributes }: HTMLElement) => {
      // If routerLink attr doesn't exists the getNamedItem(..).value returns undefined so nothing is going to be broken
      const routerLink = attributes.getNamedItem('routerLink')?.value;
      return this.router.url.includes(routerLink);
    });
  }

  ngOnDestroy(): void {
    if (this.updateButtonSubscription) {
      this.updateButtonSubscription.unsubscribe();
    }
  }
}
