import {DmsDocTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DmsDocTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DmsDocTypeCd ) {
      this._values.push(DmsDocTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DmsDocTypeCd {

    for ( const obj in DmsDocTypeCd ) {
      if (DmsDocTypeCd[obj] === value){
        return DmsDocTypeCd[obj] as DmsDocTypeCd;
      }
    }
  }
}

const DmsDocTypeCdHelper = new DmsDocTypeCdHelperEnumHelperClass();
export default DmsDocTypeCdHelper;
