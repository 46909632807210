import {DwellPeriodTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DwellPeriodTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DwellPeriodTypeCd ) {
      this._values.push(DwellPeriodTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DwellPeriodTypeCd {

    for ( const obj in DwellPeriodTypeCd ) {
      if (DwellPeriodTypeCd[obj] === value){
        return DwellPeriodTypeCd[obj] as DwellPeriodTypeCd;
      }
    }
  }
}

const DwellPeriodTypeCdHelper = new DwellPeriodTypeCdHelperEnumHelperClass();
export default DwellPeriodTypeCdHelper;
