import {RequestedChangeCd} from '../';
import {EnumHelper} from './enum-helper';

export class RequestedChangeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RequestedChangeCd ) {
      this._values.push(RequestedChangeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RequestedChangeCd {

    for ( const obj in RequestedChangeCd ) {
      if (RequestedChangeCd[obj] === value){
        return RequestedChangeCd[obj] as RequestedChangeCd;
      }
    }
  }
}

const RequestedChangeCdHelper = new RequestedChangeCdHelperEnumHelperClass();
export default RequestedChangeCdHelper;
