import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ApiRequest, NotificationService } from '@xpo-ltl/data-api';
import { AdvanceBeyondTypeCd, FileContents, MatchedPartyTypeCd, MonetaryAmount } from '@xpo-ltl/sdk-common';
import {
  GetPricingAgreementDocumentPath,
  GetPricingAgreementDocumentQuery,
  ListPricingAgreementDocHdrsQuery,
  ListPricingAgreementDocResp,
  PricingApiService,
} from '@xpo-ltl/sdk-pricing';
import { AsMatchedParty, GetOdsShipmentResp } from '@xpo-ltl/sdk-shipmentods';
import { find as _find, get as _get, size as _size, slice as _slice } from 'lodash';
import { ClipboardService } from 'ngx-clipboard';
import { BehaviorSubject } from 'rxjs';
import { finalize, map, take, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '../../../classes/unsubscriber';
import AgreementTypeCdHelper from '../../../enums/agreement-type-cd-helper';
import { AgreementTypeCd } from '../../../enums/agreement-type-cd.enum';
import { XpoLtlConditioningService } from '../../../services/conditioning-service/conditioning.service';
import { XpoLtlWindowService } from '../../../services/window/window.service';
import { ShipmentReferenceService } from '../../shipment-reference.service';

interface OverviewData {
  pro: string;
  invoice: string;
  origin: string;
  destination: string;
  parentProNbr: string;
  pickupDate: Date;
  deliveryDate: Date;
  appliedRuleset: string;
  priceAgreementId: number;
  custPriceAgreement: string;
  billClass: string;
  xpoRev: MonetaryAmount;
  advRev: MonetaryAmount;
  bydRev: MonetaryAmount;
  spotQuote: number;
  shipperLoadAndCount: boolean;
  deliveryQualifierCd: string;

  shipperPONumber: { shipperNumber: string; poNumber: string };
  shipperAddress: AsMatchedParty;
  consigneeAddress: AsMatchedParty;
  billToAddress: AsMatchedParty;
}

interface PricingAgreementDocument {
  agreementDocHdrId: number;
  agreementDocTypeId: number;
  docFile: string;
  agreementTypeCd: AgreementTypeCd;
}

@Component({
  selector: 'xpo-ltl-shipment-details-overview',
  templateUrl: './shipment-details-overview.component.html',
  styleUrls: ['./shipment-details-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentDetailsOverviewComponent implements OnInit, OnDestroy {
  @Output() toggleSRNs = new EventEmitter<boolean>();

  private pricingAgreementDocumentsSubject: BehaviorSubject<Array<PricingAgreementDocument>> = new BehaviorSubject<
    Array<PricingAgreementDocument>
  >(undefined);
  pricingAgreementDocuments$ = this.pricingAgreementDocumentsSubject.asObservable();

  private unsubscriber = new Unsubscriber();
  private showSpinnerSubject = new BehaviorSubject<boolean>(false);
  showSpinner$ = this.showSpinnerSubject.asObservable();

  selectedPricingAgreement;

  private mimeMap = new Map([
    ['.docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    ['.doc', 'application/msword'],
    ['.pdf', 'application/pdf'],
  ]);

  private overviewSubject = new BehaviorSubject<OverviewData>({
    pro: undefined,
    invoice: undefined,
    origin: undefined,
    destination: undefined,
    parentProNbr: undefined,
    pickupDate: undefined,
    deliveryDate: undefined,
    appliedRuleset: undefined,
    priceAgreementId: undefined,
    custPriceAgreement: undefined,
    billClass: undefined,
    xpoRev: undefined,
    advRev: undefined,
    bydRev: undefined,
    spotQuote: undefined,
    shipperLoadAndCount: false,
    deliveryQualifierCd: undefined,

    shipperPONumber: undefined,
    shipperAddress: undefined,
    consigneeAddress: undefined,
    billToAddress: undefined,
  });
  readonly overview$ = this.overviewSubject.asObservable();

  showSRNs = false;
  isReadOnly = true;

  constructor(
    private shipmentRefService: ShipmentReferenceService,
    private conditioningService: XpoLtlConditioningService,
    private pricingApiService: PricingApiService,
    private windowService: XpoLtlWindowService,
    private clipboardService: ClipboardService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.shipmentRefService.shipment$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((shipment) => {
      this.updateFormFromShipment(shipment);
    });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  handleToggleSRNs() {
    this.showSRNs = !this.showSRNs;
    this.toggleSRNs.emit(this.showSRNs);
  }

  showAppliedRuleset(pricingAgreementId: string) {
    this.showSpinnerSubject.next(true);

    const query = new ListPricingAgreementDocHdrsQuery();
    query.pricingAgreementId = pricingAgreementId;
    query.levelOfDetail = 'Pro';

    this.pricingApiService
      .listPricingAgreementDocHdrs(query)
      .pipe(
        finalize(() => this.showSpinnerSubject.next(false)),
        take(1),
        map((response: ListPricingAgreementDocResp) => {
          const pricingAgreementDocuments = new Array<PricingAgreementDocument>();
          response.pricingAgreementDocHdrDetail.forEach((header) => {
            if (header.agreementDocType && header.agreementDocType.length > 0) {
              header.agreementDocType.forEach((document) => {
                pricingAgreementDocuments.push({
                  agreementDocHdrId: header.agreementDocHdrId,
                  agreementDocTypeId: document.agreementDocTypeId,
                  docFile: document.docFile,
                  agreementTypeCd: AgreementTypeCdHelper.toEnum(header.agreementTypeCd),
                });
              });
            }
          });
          return pricingAgreementDocuments;
        })
      )
      .subscribe((documents: Array<PricingAgreementDocument>) => {
        if (documents) {
          if (documents.length === 1) {
            this.pricingAgreementSelected(documents[0]);
          } else {
            this.pricingAgreementDocumentsSubject.next(documents);
          }
        }
      });
  }

  pricingAgreementSelected(pricingAgreementDocument: PricingAgreementDocument) {
    if (pricingAgreementDocument.agreementTypeCd === AgreementTypeCd.PlDoc) {
      this.getDocumentFromPlDoc(pricingAgreementDocument);
    } else {
      this.getDocumentFromInternal(pricingAgreementDocument);
    }
  }

  private getDocumentFromPlDoc(pricingAgreementDocument: PricingAgreementDocument) {
    const query = new GetPricingAgreementDocumentQuery();
    query.docTypeId = pricingAgreementDocument.agreementDocTypeId;

    const pathParams = new GetPricingAgreementDocumentPath();
    pathParams.agreementDocHdrId = pricingAgreementDocument.agreementDocHdrId;

    this.pricingApiService
      .getPricingAgreementDocument(pathParams, query, ApiRequest.defaultDataOptions, { responseType: 'arraybuffer' })
      .pipe(take(1))
      .subscribe((response: FileContents) => {
        const type = this.findMimeType(pricingAgreementDocument.docFile);
        // TODO: Update return object when api changes are made
        this.windowService.generateDownloadFile(type, response as any, pricingAgreementDocument.docFile);
      });
  }

  private findMimeType(fileName: string) {
    const extension = fileName.substring(fileName.lastIndexOf('.'));
    return this.mimeMap.has(extension) ? this.mimeMap.get(extension) : 'application/octet-stream';
  }

  private getDocumentFromInternal(pricingAgreementDocument: PricingAgreementDocument) {
    const query = new GetPricingAgreementDocumentQuery();
    query.oracleDocId = pricingAgreementDocument.agreementDocHdrId;

    const pathParams = new GetPricingAgreementDocumentPath();
    pathParams.agreementDocHdrId = 1;

    this.pricingApiService
      .getPricingAgreementDocument(pathParams, query, ApiRequest.defaultDataOptions, { responseType: 'arraybuffer' })
      .pipe(take(1))
      .subscribe((response: FileContents) => {
        const type = this.findMimeType(pricingAgreementDocument.docFile);
        // TODO: Update return object when api changes are made
        this.windowService.generateDownloadFile(type, response as any, pricingAgreementDocument.docFile);
      });
  }

  handleCopyPricingAgreement(): void {
    if (this.clipboardService.copyFromContent(this.overviewSubject.value.custPriceAgreement)) {
      this.notificationService.showSnackBarMessage('Applied Pricing Agreement # copied to clipboard.', {
        durationInMillis: 3000,
        status: 'SUCCESS',
      });
    }
  }

  private updateFormFromShipment(shipment: GetOdsShipmentResp) {
    if (!shipment) {
      return;
    }

    const getParty = (matchedParties, typeCd, typeCd2?) => {
      return _find(matchedParties, (party) => !!party.typeCd && (party.typeCd === typeCd || party.typeCd === typeCd2));
    };

    // Some pricing agreements are being returned with 4 digits in the last section. Need to convert to
    // 3 digits if this occurs
    const transformCustPricingAgreementId = (id) => {
      if (!id) {
        return;
      }

      const idArray = id.split('-');
      idArray[2] = idArray[2].length === 4 ? _slice(idArray[2], 1).join('') : idArray[2];

      return idArray.join('-');
    };

    const getRev = (advanceBeyondCarrier, typeCd) => {
      if (_size(advanceBeyondCarrier) > 0) {
        return _find(advanceBeyondCarrier, (li) => li.typeCd === typeCd)
          .chain()
          .thru((val) =>
            val
              ? {
                  amt: val.chargeAmount,
                  currencyCd: 'USD', // always in US$
                }
              : undefined
          )
          ._value();
      } else {
        return 0;
      }
    };

    // extract the payment summary data from shipment and populate the this.summary with it
    const overview = {
      pro: this.conditioningService.conditionProNumber(shipment.shipment.proNbr, 11),
      invoice: this.conditioningService.conditionProNumber(shipment.shipment.proNbr, 10),
      origin: shipment.shipment.originTerminalSicCd,
      destination: shipment.shipment.destinationTerminalSicCd,
      parentProNbr: _get(shipment.parentShipmentId, 'proNbr'),
      pickupDate: new Date(shipment.shipment.pickupDate),
      deliveryDate: new Date(shipment.shipment.estimatedDeliveryDate),
      appliedRuleset: shipment.shipment.priceRulesetNbr,
      priceAgreementId: shipment.shipment.priceAgreementId,
      custPriceAgreement: transformCustPricingAgreementId(shipment.custPricingAgreementId),
      billClass: shipment.shipment.billClassCd,
      xpoRev: { amt: shipment.xpoRevenueAmt, currencyCd: 'USD' },
      advRev: getRev(shipment.advanceBeyondCarrier, AdvanceBeyondTypeCd.ADV_CARR),
      bydRev: getRev(shipment.advanceBeyondCarrier, AdvanceBeyondTypeCd.BYD_CARR),
      spotQuote: shipment.shipment.spotQuoteId,
      shipperLoadAndCount: shipment.shipperLoadAndCountInd,
      shipperPONumber: {
        shipperNumber: _get(
          _find(shipment.suppRefNbr, (srn) => srn.typeCd === 'SN#'),
          'refNbr'
        ),
        poNumber: _get(
          _find(shipment.suppRefNbr, (srn) => srn.typeCd === 'PO#'),
          'refNbr'
        ),
      },
      deliveryQualifierCd: shipment.shipment.deliveryQualifierCd,
      shipperAddress: getParty(shipment.asMatchedParty, MatchedPartyTypeCd.SHPR),
      consigneeAddress: getParty(shipment.asMatchedParty, MatchedPartyTypeCd.CONS),
      billToAddress: getParty(shipment.asMatchedParty, MatchedPartyTypeCd.BILL_TO_OTB, MatchedPartyTypeCd.BILL_TO_INB),
    };
    this.overviewSubject.next(overview);
  }

  combinePostalCode(postal: string, postalExt: string): string {
    return postalExt ? `${postal}-${postalExt}` : postal;
  }

  combinePhoneNumber(areaCd: string, phoneNbr: string): string {
    if (phoneNbr) {
      const ac = areaCd ? `${areaCd}-` : '';
      const pn1 = phoneNbr.substring(0, 3);
      const pn2 = phoneNbr.substring(3);
      return `${ac}${pn1}-${pn2}`;
    } else {
      return '';
    }
  }
}
