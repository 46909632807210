import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { XpoErrorPage } from './error-page';
import { XpoErrorPageModule } from './error-page.module';

const routes: Routes = [{ path: '**', component: XpoErrorPage }];

@NgModule({
  imports: [CommonModule, XpoErrorPageModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class XpoPageNotFoundRoutingModule {}
