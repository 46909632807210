import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';

import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, ColGroupDef, Column, GridApi, GridReadyEvent } from 'ag-grid-community';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

function insensitiveComparator(a, b): number {
  if (typeof a === 'string') {
    return (<string>a).localeCompare(b);
  }

  return a > b ? 1 : a < b ? -1 : 0;
}

export const XPO_AG_GRID_DEFAULT_ROW_HEIGHT = 30;
export const XPO_AG_GRID_DEFAULT_CELL_MIN_WIDTH = 32;

@Component({
  selector: 'xpo-ag-grid',
  templateUrl: './ag-grid.component.html',
  styleUrls: ['./ag-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-AgGrid ag-theme-material' },
})
export class XpoAgGridComponent implements AfterContentInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private existingGridReadyListener: (event: GridReadyEvent) => void;
  private forceRowHeightsConfig: boolean;
  private gridApi: GridApi;

  @ContentChild(AgGridAngular)
  private grid: AgGridAngular;

  @Input()
  get rowHeight(): number {
    return this.rowHeightValue;
  }
  set rowHeight(v: number) {
    if (v) {
      this.rowHeightValue = v;

      if (this.grid) {
        this.configureRowHeights(true);
      } else {
        this.forceRowHeightsConfig = true;
      }
    }
  }
  private rowHeightValue: number = XPO_AG_GRID_DEFAULT_ROW_HEIGHT;

  /*
   * Whether or not the columns are automatically auto-sized every-time data changes
   */
  @Input()
  get autoSizeColumns(): boolean {
    return this.autoSizeColumnsValue;
  }
  set autoSizeColumns(value: boolean) {
    this.autoSizeColumnsValue = coerceBooleanProperty(value);
  }
  private autoSizeColumnsValue = false;

  /*
   * Whether or not the columns are automatically sizedToFit every-time data changes or the screen is resized
   */
  @Input()
  get sizeColumnsToFit(): boolean {
    return this.sizeColumnsToFitValue;
  }
  set sizeColumnsToFit(value: boolean) {
    this.sizeColumnsToFitValue = coerceBooleanProperty(value);
  }
  private sizeColumnsToFitValue: boolean = false;

  /** Sets the selection mode, options are 'row' selection  and 'cell' selection. Defaults to 'row'  */
  @Input()
  get selectionMode(): string {
    return this.selectionModeValue;
  }
  set selectionMode(value: string) {
    this.selectionModeValue = value;
  }
  private selectionModeValue: string = 'row';

  @HostBinding('class.xpo-AgGrid--enableCellContentCopy')
  @Input()
  get enableContentCopy(): boolean {
    return this.copyCellContent;
  }
  set enableContentCopy(value: boolean) {
    this.copyCellContent = coerceBooleanProperty(value);
  }
  private copyCellContent = false;

  /** Add  cell-selection-mode css class to host if selection mode is cell  */
  @HostBinding('class.xpo-AgGrid--cellSelectionMode')
  get cellSelectionModeClass(): boolean {
    return this.selectionModeValue === 'cell';
  }

  ngAfterContentInit(): void {
    this.initializeGrid();
  }

  ngOnDestroy(): void {
    this.gridApi.removeEventListener('rowDataChanged', (e) => {
      this.autoSizeAndAdjustToFitIfEnabled();
    });
    this.gridApi.removeEventListener('paginationChanged', (e) => {
      this.autoSizeAndAdjustToFitIfEnabled();
    });
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private autoSizeAndAdjustToFitIfEnabled(): void {
    if (this.autoSizeColumns) {
      this.grid.columnApi.autoSizeColumns(this.getColumnsToBeAutoSized());
    }

    // allow the auto-size to happen and calculate the minimum required width for the grid
    // the min-grid width will be used to determine if the sizeColumnsToFit function should
    // be called
    setTimeout(() => {
      this.sizeColumnsToFitIfEnabled();
    });
  }

  private bindToGridDataUpdates(): void {
    // only do an auto-size when new data is set (in client-side mode)
    // or when the page was changed (in infinite-scroll mode)
    // TODO: additional adjustments might need to be made for sorting
    this.gridApi.addEventListener('rowDataChanged', (e) => {
      this.autoSizeAndAdjustToFitIfEnabled();
    });

    this.gridApi.addEventListener('paginationChanged', (e) => {
      this.autoSizeAndAdjustToFitIfEnabled();
    });
  }

  private bindWindowResize(): void {
    // would be preferable to use the `gridSizeChanged` event from ag-grid
    // but that only gets fired when the container gets resized
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(500),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((e) => {
        setTimeout(() => {
          if (this.sizeColumnsToFit) {
            this.gridApi.sizeColumnsToFit();
          }
        });
      });
  }

  private getColumnsToBeAutoSized(): Column[] {
    const cols = (this.grid.columnApi.getAllColumns() || [])
      .filter((column: Column) => {
        return typeof column.getColDef().width === 'undefined';
      })
      .map((column: Column) => {
        return column['colId'];
      });

    return cols;
  }

  /**
   * Overrides the setColumDefs call from the grid api. We want to intercept everytime
   * this gets called so we can apply our classes to them.
   */
  private overrideSetColumnDefs(): void {
    const setColDef = this.gridApi.setColumnDefs;

    this.gridApi.setColumnDefs = (...args: any[]) => {
      if (this.sizeColumnsToFit) {
        this.gridApi.sizeColumnsToFit();
      }

      setColDef.apply(this.gridApi, args);
    };
  }

  private initializeGrid(): void {
    if (this.grid) {
      if (!this.grid.gridOptions) {
        this.grid.gridOptions = {};
      }

      if (!this.grid.gridOptions.defaultColDef) {
        this.grid.gridOptions.defaultColDef = {};
      }

      this.configureRowHeights(this.forceRowHeightsConfig);

      this.configureColumnWidth();

      // If the end developer put in their on grid ready event, store it and call it after we
      // run our grid ready function
      this.existingGridReadyListener = this.grid.gridOptions.onGridReady;
      this.grid.gridOptions.onGridReady = (event: GridReadyEvent) => this.onGridReady(event);

      this.grid.gridOptions.defaultColDef = {
        // make our grids case-insensitive: https://github.com/ag-grid/ag-grid/issues/1795
        comparator: insensitiveComparator,
        unSortIcon: true, // Always show unsort icon
        ...this.grid.gridOptions.defaultColDef,
      };
    }
  }

  private onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.autoSizeAndAdjustToFitIfEnabled();
    this.bindToGridDataUpdates();
    this.bindWindowResize();
    this.overrideSetColumnDefs();

    if (this.existingGridReadyListener) {
      this.existingGridReadyListener(event);
    }
  }

  private sizeColumnsToFitIfEnabled(): void {
    if (this.sizeColumnsToFit) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  private configureRowHeights(force = false): void {
    let updateRowHeight: boolean;

    if (!this.grid.gridOptions.rowHeight || force) {
      this.grid.gridOptions.rowHeight = this.rowHeight;
      updateRowHeight = true;
    }

    if (!this.grid.gridOptions.headerHeight || force) {
      this.grid.gridOptions.headerHeight = this.rowHeight + this.rowHeight * 0.1;
      updateRowHeight = true;
    }

    if (!this.grid.gridOptions.floatingFiltersHeight || force) {
      this.grid.gridOptions.floatingFiltersHeight = this.rowHeight;
      updateRowHeight = true;
    }

    if (this.gridApi && updateRowHeight) {
      this.gridApi.resetRowHeights();
    }

    this.forceRowHeightsConfig = false;
  }

  private configureColumnWidth(): void {
    this.grid.gridOptions.minColWidth = this.grid.gridOptions.minColWidth || XPO_AG_GRID_DEFAULT_CELL_MIN_WIDTH;
  }

  /**
   * Applies a class to editable cells since ag-grid doesn't provide one
   */
  private applyEditableCellClass(colDefs: ColDef[] = []): (ColDef | ColGroupDef)[] {
    const editableCellClass = 'xpo-AgGrid-editableCell';

    return colDefs.map((c) => {
      if (c.editable) {
        if (c.cellClass && c.cellClass instanceof Array) {
          c.cellClass.push(editableCellClass);
        } else {
          c.cellClass = c.cellClass ? `${c.cellClass} ${editableCellClass}` : editableCellClass;
        }
      }

      return c;
    });
  }
}
