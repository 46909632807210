import {StopWindowCd} from '../';
import {EnumHelper} from './enum-helper';

export class StopWindowCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in StopWindowCd ) {
      this._values.push(StopWindowCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): StopWindowCd {

    for ( const obj in StopWindowCd ) {
      if (StopWindowCd[obj] === value){
        return StopWindowCd[obj] as StopWindowCd;
      }
    }
  }
}

const StopWindowCdHelper = new StopWindowCdHelperEnumHelperClass();
export default StopWindowCdHelper;
