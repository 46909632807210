import {MinuetExecutionStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class MinuetExecutionStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MinuetExecutionStatusCd ) {
      this._values.push(MinuetExecutionStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MinuetExecutionStatusCd {

    for ( const obj in MinuetExecutionStatusCd ) {
      if (MinuetExecutionStatusCd[obj] === value){
        return MinuetExecutionStatusCd[obj] as MinuetExecutionStatusCd;
      }
    }
  }
}

const MinuetExecutionStatusCdHelper = new MinuetExecutionStatusCdHelperEnumHelperClass();
export default MinuetExecutionStatusCdHelper;
