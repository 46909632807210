import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'module-group-mgmt-actions-cell-renderer',
  templateUrl: './actions-cell-renderer.component.html',
  styleUrls: ['./actions-cell-renderer.component.scss'],
  host: {
    class: 'ModuleGroupMgmtActionsCellRenderer',
  },
})
export class ModuleGroupMgmtActionsCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  edit() {
    this.params.node.setExpanded(!this.params.node.expanded);
  }

  delete() {
    if (this.params) {
      this.params.context.deleteModule(this.params.node);
    }
  }
}
