import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggingApiService } from './logging-api.service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { inspect } from 'util';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class GlobalErrorHandlingLogger extends ErrorHandler {

  private static logUnhandledErrorsKey = 'logUnhandledErrors';
  private logErrors = false;
  private unsubscribe$ = new Subject<void>();

  constructor(private injector: Injector, private loggingApiService: LoggingApiService, private configManager: ConfigManagerService) {
    super();
    this.configManager.configured$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((settings) => {
        if (settings) {
          this.logErrors = settings[GlobalErrorHandlingLogger.logUnhandledErrorsKey] || false;
          this.unsubscribe$.next();
          this.unsubscribe$.complete();
          this.unsubscribe$ = null;
        }
      });
  }

  handleError(error) {
    let logMessage: string;
    if (this.logErrors && this.loggingApiService) {
      try {
        logMessage = inspect(error);
      } catch (err) {
        if (error['rejection'] && error['rejection']['stack']) {
          logMessage = error.rejection.stack;
        } else {
          logMessage = 'Unknown unhandled error occurred!';
        }
      }
      this.loggingApiService.fatal(logMessage);
      super.handleError(error);
    } else {
        console.log(error);
    }
  }
}
