import {InvoiceTransmitStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoiceTransmitStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoiceTransmitStatusCd ) {
      this._values.push(InvoiceTransmitStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoiceTransmitStatusCd {

    for ( const obj in InvoiceTransmitStatusCd ) {
      if (InvoiceTransmitStatusCd[obj] === value){
        return InvoiceTransmitStatusCd[obj] as InvoiceTransmitStatusCd;
      }
    }
  }
}

const InvoiceTransmitStatusCdHelper = new InvoiceTransmitStatusCdHelperEnumHelperClass();
export default InvoiceTransmitStatusCdHelper;
