import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { XpoResponsiveLoadingSpinner } from './responsive-loading-spinner.component';

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule],
  declarations: [XpoResponsiveLoadingSpinner],
  exports: [XpoResponsiveLoadingSpinner],
})
export class XpoResponsiveLoadingSpinnerModule {}
