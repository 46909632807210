import {TripNodeStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class TripNodeStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TripNodeStatusCd ) {
      this._values.push(TripNodeStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TripNodeStatusCd {

    for ( const obj in TripNodeStatusCd ) {
      if (TripNodeStatusCd[obj] === value){
        return TripNodeStatusCd[obj] as TripNodeStatusCd;
      }
    }
  }
}

const TripNodeStatusCdHelper = new TripNodeStatusCdHelperEnumHelperClass();
export default TripNodeStatusCdHelper;
