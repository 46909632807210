import { Injectable } from '@angular/core';

/* XPO */
import { ListModulesQuery, ListModulesResp, ModuleExecutorApiService } from '@xpo-ltl/sdk-moduleexecutor';

/* Rxjs */
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/* Services */
import { ListModulesStoreService } from './list-modules.store.service';

@Injectable()
export class ListModulesService {
  constructor(private apiService: ModuleExecutorApiService, private listModulesStoreService: ListModulesStoreService) {}

  requestListModules(listModulesQuery: ListModulesQuery): Observable<ListModulesResp> {
    return this.apiService.listModules(listModulesQuery).pipe(
      switchMap((response: ListModulesResp) => {
        this.listModulesStoreService.populateListModules(response);

        return this.listModulesStoreService.listModules$;
      })
    );
  }
}
