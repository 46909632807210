import { DateUtils } from '@xpo-ltl/ngx-ltl-core';
import { Observable } from 'rxjs';

import { XpoDateFilterBase } from '../../models/date-filter-base';
import { XpoDateFilterConfig } from '../../models/date-filter-config';
import { XpoDateRangeFilterCriteria } from '../date-range-model/date-range-filter-criteria';
import { XpoDateRangeFilterHelper } from '../date-range-model/date-range-filter-helper';
import { XpoQuickDate } from '../date-range-model/date-range-quick-date.enum';
import { XpoDateRangeFilterGroupByComponent } from './date-range-filter-group-by.component';

export interface XpoDateRangeGroupByFilterDisplayOption {
  shownIntervals: string[];
}

export class XpoDateRangeGroupByFilter extends XpoDateFilterBase {
  displayOptions?: XpoDateRangeGroupByFilterDisplayOption;
  dateFilterConfig?: XpoDateFilterConfig | Observable<XpoDateFilterConfig>;

  constructor(
    field: string,
    label: string,
    displayOptions?: XpoDateRangeGroupByFilterDisplayOption,
    dateFilterConfig?: XpoDateFilterConfig | Observable<XpoDateFilterConfig>
  ) {
    super(field, label, XpoDateRangeFilterGroupByComponent, dateFilterConfig);
    if (displayOptions) {
      this.displayOptions = displayOptions;
    }
  }

  private static formatDate(date: string | Date): string {
    // Ensuring that the string dates are converted to date objects
    return DateUtils.formatDate(date instanceof Date ? date : new Date(date));
  }

  getDisplayValue(val: any): string {
    const filterModel: XpoDateRangeFilterCriteria = val || {};

    if (filterModel.selected === XpoQuickDate.Today) {
      return 'Today';
    } else if (filterModel.selected === XpoQuickDate.Tomorrow) {
      return 'Tomorrow';
    } else if (filterModel.selected === XpoQuickDate.Yesterday) {
      return 'Yesterday';
    } else if (filterModel.selected === XpoQuickDate.TodayPlusDays) {
      // - 1 to exclude the last day
      return 'Today - ' + XpoDateRangeGroupByFilter.formatDate(DateUtils.fromToday(filterModel.offset * 1 - 1));
    } else if (filterModel.selected === XpoQuickDate.TodayMinusDays) {
      // + 1 to exclude the last day
      return XpoDateRangeGroupByFilter.formatDate(DateUtils.fromToday(filterModel.offset * -1 + 1)) + '- Today';
    } else if (filterModel.min && !filterModel.max) {
      return 'After ' + XpoDateRangeGroupByFilter.formatDate(filterModel.min);
    } else if (!filterModel.min && filterModel.max) {
      return 'Before ' + XpoDateRangeGroupByFilter.formatDate(filterModel.max);
    } else if (filterModel.min && filterModel.max) {
      return (
        XpoDateRangeGroupByFilter.formatDate(filterModel.min) +
        ' - ' +
        XpoDateRangeGroupByFilter.formatDate(filterModel.max)
      );
    }

    return 'Any';
  }

  getInitialFilterValue(val: any): any {
    val = super.getInitialFilterValue(val) || {};

    // if they are just using a simple date range return
    if (val['selected'] === 'date' || !val['selected']) {
      return val;
    }
    // we need to initialize the filter, because we need to translate, today, yesterday, etc..
    // into the proper representation of the dates
    return XpoDateRangeFilterHelper.constructFilter(val['selected'], val['offset'] || null);
  }
}
