/*
  TODO: This whole component needs refactoring. valueMap, RelativeTime and comparisonMap are way too complex to maintain
  and should be re-designed. This whole class is temporary and will be re-worked ASAP. Please avoid the use of this
  class anytime you can.
*/

import { Component, EventEmitter, Output } from '@angular/core';
import _invert from 'lodash-es/invert';
import moment from 'moment';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { filterFormats, Operators, OperatorText, XpoFilterConditionCriteria } from './../../../../../models';

enum RelativeTime {
  today = 'Today',
  yesterday = 'Yesterday',
  tomorrow = 'Tomorrow',
  thisWeek = 'This Week',
  lastWeek = 'Last Week',
  nextWeek = 'Next Week',
}

@Component({
  templateUrl: './is.component.html',
  styleUrls: ['../../validator.scss'],
})
export class XpoConditionDateIsComponent extends XpoConditionBase implements XpoFilterCondition {
  constructor() {
    super(filterFormats.default);
  }
  static text: string = OperatorText.Is;
  static operator: Operators = Operators.Relative;

  // TODO: This needs refactor
  readonly valueMap = {
    [RelativeTime.today]: '0d',
    [RelativeTime.yesterday]: '-1d',
    [RelativeTime.tomorrow]: '+1d',
    [RelativeTime.thisWeek]: '0w',
    [RelativeTime.lastWeek]: '-1w',
    [RelativeTime.nextWeek]: '+1w',
  };

  isNegative = false;
  model: string = this.valueMap[RelativeTime.today];
  readonly RelativeTime = RelativeTime; // For Select

  // Don't think; Only do
  readonly comparisonMap = {
    [RelativeTime.today]: (v): boolean =>
      moment(v)
        .startOf('day')
        .isSame(moment().startOf('day')),
    [RelativeTime.yesterday]: (v): boolean =>
      moment(v)
        .startOf('day')
        .isSame(
          moment()
            .subtract(1, 'day')
            .startOf('day')
        ),
    [RelativeTime.tomorrow]: (v): boolean =>
      moment(v)
        .startOf('day')
        .isSame(
          moment()
            .add(1, 'day')
            .startOf('day')
        ),
    [RelativeTime.thisWeek]: (v): boolean => moment(v).isBetween(moment().startOf('week'), moment().endOf('week')),
    [RelativeTime.lastWeek]: (v): boolean =>
      moment(v).isBetween(
        moment()
          .subtract(1, 'week')
          .startOf('week'),
        moment()
          .subtract(1, 'week')
          .endOf('week')
      ),
    [RelativeTime.nextWeek]: (v): boolean =>
      moment(v).isBetween(
        moment()
          .add(1, 'week')
          .startOf('week'),
        moment()
          .add(1, 'week')
          .endOf('week')
      ),
  };

  @Output() inputChanged = new EventEmitter();

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    if (!cellValue) {
      return false;
    }
    const relativeTimeKey = _invert(this.valueMap)[this.model];
    return this.comparisonMap[relativeTimeKey](cellValue);
  }
  getCriteria(): XpoFilterConditionCriteria {
    /* 
      TODO: The use of _invert here is totally temporary. It's a fix for model implementation here. 
      This will be updated on the refactor of this whole thing
    */
    return {
      operator: Operators.Relative,
      value: this.model,
      display: _invert(this.valueMap)[this.model],
    };
  }
  onInputChange(): any {
    this.validateFormat();
    this.inputChanged.emit(this.model);
  }
}
