import { XpoBoardData, XpoBoardView } from '@xpo-ltl/ngx-ltl-board';

export interface CallbackData {
  params: any;
  view: XpoBoardView;
}

// assists with replaying requests to avoid multiple requests to the server for the same data
export class GridDataReplayCache {
  private cachedData: XpoBoardData = null;
  private rangeHash: string = null;
  private sortHash: string = null;
  private wasLastRequestForSortingValue: boolean = false;

  private static getGridCallbackRangeHash(data: CallbackData): string {
    // generate a hash representing the request
    return JSON.stringify({
      endRow: data.params['endRow'],
      startRow: data.params['startRow'],
    });
  }

  // generates a hash key of the grid sort parameters
  private static getGridCallbackSortHash(data: CallbackData): string {
    // generate a hash representing the request
    return JSON.stringify({
      sortModel: data.params['sortModel'],
    });
  }

  get wasLastRequestForSorting(): boolean {
    return this.wasLastRequestForSortingValue;
  }

  clear(): void {
    this.cachedData = null;
    this.rangeHash = null;
    this.sortHash = null;
    this.wasLastRequestForSortingValue = false;
  }

  get(callbackData: CallbackData): XpoBoardData {
    let result: XpoBoardData = null;

    if (
      this.cachedData &&
      this.cachedData.state.viewId === callbackData.view.id &&
      this.sortHash === GridDataReplayCache.getGridCallbackSortHash(callbackData) &&
      this.rangeHash === GridDataReplayCache.getGridCallbackRangeHash(callbackData)
    ) {
      result = this.cachedData;
    }

    return result;
  }

  set(callbackData: CallbackData, data: XpoBoardData): void {
    const hashKey: string = GridDataReplayCache.getGridCallbackSortHash(callbackData);

    this.wasLastRequestForSortingValue =
      this.cachedData !== null && this.cachedData.state.viewId === data.state.viewId && this.sortHash !== hashKey;
    this.cachedData = data;
    this.rangeHash = GridDataReplayCache.getGridCallbackRangeHash(callbackData);
    this.sortHash = hashKey;
  }
}
