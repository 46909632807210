import {JSPShiftTimeTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class JSPShiftTimeTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in JSPShiftTimeTypeCd ) {
      this._values.push(JSPShiftTimeTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): JSPShiftTimeTypeCd {

    for ( const obj in JSPShiftTimeTypeCd ) {
      if (JSPShiftTimeTypeCd[obj] === value){
        return JSPShiftTimeTypeCd[obj] as JSPShiftTimeTypeCd;
      }
    }
  }
}

const JSPShiftTimeTypeCdHelper = new JSPShiftTimeTypeCdHelperEnumHelperClass();
export default JSPShiftTimeTypeCdHelper;
