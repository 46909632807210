import {EcmSummaryCountTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class EcmSummaryCountTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EcmSummaryCountTypeCd ) {
      this._values.push(EcmSummaryCountTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EcmSummaryCountTypeCd {

    for ( const obj in EcmSummaryCountTypeCd ) {
      if (EcmSummaryCountTypeCd[obj] === value){
        return EcmSummaryCountTypeCd[obj] as EcmSummaryCountTypeCd;
      }
    }
  }
}

const EcmSummaryCountTypeCdHelper = new EcmSummaryCountTypeCdHelperEnumHelperClass();
export default EcmSummaryCountTypeCdHelper;
