import {NextBusinessDayOperationCd} from '../';
import {EnumHelper} from './enum-helper';

export class NextBusinessDayOperationCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in NextBusinessDayOperationCd ) {
      this._values.push(NextBusinessDayOperationCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): NextBusinessDayOperationCd {

    for ( const obj in NextBusinessDayOperationCd ) {
      if (NextBusinessDayOperationCd[obj] === value){
        return NextBusinessDayOperationCd[obj] as NextBusinessDayOperationCd;
      }
    }
  }
}

const NextBusinessDayOperationCdHelper = new NextBusinessDayOperationCdHelperEnumHelperClass();
export default NextBusinessDayOperationCdHelper;
