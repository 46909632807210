import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

/* Components */
import { ModuleGroupMgmtExecutionStepsComponent } from './module-group-mgmt-execution-steps.component';

/* Modules */
import { ModuleGroupMgmtExecutionStepModule } from './step/index';

@NgModule({
  declarations: [ModuleGroupMgmtExecutionStepsComponent],
  imports: [CommonModule, FlexLayoutModule, ModuleGroupMgmtExecutionStepModule],
  exports: [ModuleGroupMgmtExecutionStepsComponent],
})
export class ModuleGroupMgmtExecutionStepsModule {}
