import { Component, EventEmitter, Output } from '@angular/core';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import {
  CustomFilterParams,
  filterFormats,
  Operators,
  OperatorText,
  XpoFilterConditionCriteria,
} from './../../../../../models/index';

@Component({
  templateUrl: './contains.component.html',
  styleUrls: ['../../validator.scss'],
})
export class XpoConditionStringContainsComponent extends XpoConditionBase implements XpoFilterCondition {
  static text = OperatorText.Contains;
  static operator = Operators.Contains;
  isNegative = false;
  model: string = '';
  private isCaseSensitive = false;

  set customFilterParams(params: CustomFilterParams) {
    this.isCaseSensitive = params.isCaseSensitive || false;
  }

  @Output() inputChanged = new EventEmitter();

  constructor() {
    super(filterFormats.default);
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    if (!cellValue) {
      return false;
    }

    if (this.isCaseSensitive) {
      return cellValue.toString().indexOf(this.model) >= 0;
    } else {
      return (
        cellValue
          .toString()
          .toLowerCase()
          .indexOf(this.model.toLowerCase()) >= 0
      );
    }
  }

  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.Contains,
      value: this.model,
    };
  }

  onInputChange(): any {
    this.validateFormat();
    this.inputChanged.emit(this.model);
  }
}
