import { ChangeDetectorRef, Component } from '@angular/core';
import { XpoConditionalFilterComponent } from '../conditional-filter.component';
import {
  XpoConditionEmptyComponent,
  XpoConditionIsNotEmptyComponent,
  XpoConditionIsOneOfComponent,
  XpoConditionNumberEqualsComponent,
  XpoConditionNumberGreaterEqualThanComponent,
  XpoConditionNumberGreaterThanComponent,
  XpoConditionNumberIsBetweenComponent,
  XpoConditionNumberLessEqualThanComponent,
  XpoConditionNumberLessThanComponent,
  XpoConditionNumberNotEqualsComponent,
} from '../conditions';
import { XpoConditionNumberIsNotBetweenComponent } from './../conditions/validators/number/is-not-between/is-not-between.component';

@Component({
  templateUrl: '../conditional-filter.component.html',
  styleUrls: ['../conditional-filter.component.scss'],
})
export class XpoNumberFilterComponent extends XpoConditionalFilterComponent {
  defaultConditions = [
    XpoConditionNumberEqualsComponent,
    XpoConditionNumberNotEqualsComponent,
    XpoConditionNumberGreaterThanComponent,
    XpoConditionNumberGreaterEqualThanComponent,
    XpoConditionNumberLessThanComponent,
    XpoConditionNumberLessEqualThanComponent,
    XpoConditionNumberIsBetweenComponent,
    XpoConditionNumberIsNotBetweenComponent,
    XpoConditionEmptyComponent,
    XpoConditionIsNotEmptyComponent,
    XpoConditionIsOneOfComponent,
  ];
}
