import { Component } from '@angular/core';
import { XpoConditionalFilterComponent } from '../conditional-filter.component';
import { XpoConditionDynamicEnumEqualsComponent } from '../conditions';

@Component({
  templateUrl: '../conditional-filter.component.html',
  styleUrls: ['../conditional-filter.component.scss'],
})
export class XpoDynamicEnumFilterComponent extends XpoConditionalFilterComponent {
  allowMultipleFilterRows = false;
  defaultConditions = [XpoConditionDynamicEnumEqualsComponent];
  // TODO: row models must be standardized in all board. We'll do in separate ticket.
  readonly clientSideRowModel = 'clientSide';

  agInit(params: any): void {
    super.agInit(params);
    this.checkRowModelType(params);
  }
  checkRowModelType(params): void {
    if (params.rowModel.getType() !== this.clientSideRowModel) {
      // TODO: errors and warnings should be handled by a service or a more global way. We'll do in separate ticket.
      console.warn('XPO: XpoDynamicEnumFilterComponent only works with client-side row models');
    }
  }
}
