import { XpoFacetFilterTimeValueModel } from './range-time.interface';

export interface XpoFacetFilterModelCriteria {
  [key: string]: {
    filter: {
      typeFilter: XpoFacetFilterTypeOperator;
      conditions: XpoFacetFilterConditions[];
      // combiner?: XpoFacetFilterCombiner | null;  TODO: verify if we can omit definitively this property https://jira.xpo.com/browse/NGXLTL-1284
    };
  };
}

export interface XpoFacetFilterConditions {
  operator: XpoFacetFilterStringOperators | XpoFacetFilterNumberOperators | XpoFacetFilterDateOperators | XpoFacetFilterTypeOperator;
  value: string | number | Date | XpoFacetFilterTimeValueModel;
  valueTo?: string | number | Date | XpoFacetFilterTimeValueModel;
}

export enum XpoFacetFilterStringOperators {
  'EQUAL' = 'EQUAL',
  'CONTAINS' = 'CONTAINS',
}

export enum XpoFacetFilterNumberOperators {
  'EQUAL' = 'EQUAL',
  'BETWEEN' = 'BETWEEN',
  'LESSERTHAN' = 'LESSER_THAN',
  'GREATERTHAN' = 'GREATER_THAN',
}

export enum XpoFacetFilterDateOperators {
  'EQUAL' = 'EQUAL',
  'BETWEEN' = 'BETWEEN',
  'LESSERTHAN' = 'LESSER_THAN',
  'GREATERTHAN' = 'GREATER_THAN',
}

export enum XpoFacetFilterCombiner {
  'AND' = 'AND',
  'OR' = 'OR',
}

export enum XpoFacetFilterTypeOperator {
  Number,
  String,
  Date,
  XpoFacetFilterTimeValueOperator,
}
