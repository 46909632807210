
export enum PickupSourceCd {
	DAILY = 'Daily',
	EDI = 'EDI',
	INTERNAL = 'Internal',
	MANUAL = 'Manual',
	MIXED = 'Mixed',
	OPERATIONAL = 'Operational',
	WEB = 'Web'}

