import {TrapReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class TrapReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TrapReasonCd ) {
      this._values.push(TrapReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TrapReasonCd {

    for ( const obj in TrapReasonCd ) {
      if (TrapReasonCd[obj] === value){
        return TrapReasonCd[obj] as TrapReasonCd;
      }
    }
  }
}

const TrapReasonCdHelper = new TrapReasonCdHelperEnumHelperClass();
export default TrapReasonCdHelper;
