/**
 * Routes for the components in this library
 *
 * These routes are also declared in the XpoAuth platform so these cannot be changed
 * unless the backend changes it as well
 */
export enum XpoAuthRoutePaths {
  Authentication = 'auth',
  PostSignOut = 'postsignout',
  AuthSilent = 'authsilent',
}
