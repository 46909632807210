import {InvoiceAddressCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoiceAddressCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoiceAddressCd ) {
      this._values.push(InvoiceAddressCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoiceAddressCd {

    for ( const obj in InvoiceAddressCd ) {
      if (InvoiceAddressCd[obj] === value){
        return InvoiceAddressCd[obj] as InvoiceAddressCd;
      }
    }
  }
}

const InvoiceAddressCdHelper = new InvoiceAddressCdHelperEnumHelperClass();
export default InvoiceAddressCdHelper;
