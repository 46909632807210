import { BehaviorSubject } from 'rxjs';

export interface XpoUploadListDeleteEvent {
  file: File;
  fileIndex: number;
  progress: number;
}

export class XpoUploadListItem {
  progress$: BehaviorSubject<number>;
  uploaded$: BehaviorSubject<number>;

  constructor(public file: File, public progress: number = 0, public uploaded: number = 0) {
    this.progress$ = new BehaviorSubject<number>(progress);
    this.uploaded$ = new BehaviorSubject<number>(uploaded);
  }

  setProgress(p: number): void {
    this.progress = p;
    this.progress$.next(p);
  }

  setUpload(u: number): void {
    this.uploaded = u;
    this.uploaded$.next(u);
  }
}
