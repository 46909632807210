import {ClaimAdditionalPaymentReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimAdditionalPaymentReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimAdditionalPaymentReasonCd ) {
      this._values.push(ClaimAdditionalPaymentReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimAdditionalPaymentReasonCd {

    for ( const obj in ClaimAdditionalPaymentReasonCd ) {
      if (ClaimAdditionalPaymentReasonCd[obj] === value){
        return ClaimAdditionalPaymentReasonCd[obj] as ClaimAdditionalPaymentReasonCd;
      }
    }
  }
}

const ClaimAdditionalPaymentReasonCdHelper = new ClaimAdditionalPaymentReasonCdHelperEnumHelperClass();
export default ClaimAdditionalPaymentReasonCdHelper;
