import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserManager } from 'oidc-client';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { XpoAuthEventHandler } from '../event-handler/event-handler.model';
import { XpoAuthUser } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class XpoAuthenticationService {
  private static readonly AuthRedirectSessionKey = 'xpo-auth-redirect-key';
  private userSource$ = new BehaviorSubject<XpoAuthUser>(null);

  constructor(private manager: UserManager, private router: Router, private authEventListener: XpoAuthEventHandler) {
    this.manager.events.addAccessTokenExpired((event) => {
      // TODO: will this be handled with the 403 error interceptor?
    });

    // Listening in to when the user is added to store the value
    this.manager.events.addUserLoaded((user) => {
      // Add Hook here
      this.setUser(user);
    });

    this.manager.events.addSilentRenewError(() => {
      // TODO: forcefully sign out user? or a snackbar?
    });

    this.manager.events.addUserSessionChanged(() => {
      // TODO
    });
  }

  /**
   * Return current user.
   */
  getUser(): XpoAuthUser {
    return this.userSource$.value;
  }

  /**
   * Change stream of current user.
   */
  getUser$(): Observable<XpoAuthUser> {
    return this.userSource$.asObservable();
  }

  /**
   * Sets value of current user.
   */
  setUser(u: XpoAuthUser): void {
    this.userSource$.next(u);
  }

  /**
   * Whether or not the user is logged in.
   */
  isLoggedIn(): boolean {
    const user = this.getUser();

    return user != null && !user.expired;
  }

  /**
   * Change stream of whether or no the user is logged in.
   */
  isLoggedIn$(): Observable<boolean> {
    return this.getUser$().pipe(map(() => this.isLoggedIn()));
  }

  /**
   * Returns authorization header that includes the user's access token.
   */
  getAuthorizationHeaderValue(): string {
    const user = this.getUser();

    return `${user.token_type} ${user.access_token}`;
  }

  /**
   * Triggers the process of signing in the user and redirects to the identity provider.
   * When redirected back to the app, completeSignIn$() should be called
   */
  startSignIn$(redirectUrl?: string): Observable<void> {
    this.redirectUrl = redirectUrl || '/';

    return this.authEventListener.preSignIn().pipe(switchMap(() => from(this.manager.signinRedirect())));
  }

  /**
   * Completes the process of signing in and redirects the user to the url that was stored when
   * startSignIn$() was triggered.
   */
  completeSignIn$(): Observable<XpoAuthUser> {
    return from(this.manager.signinRedirectCallback()).pipe(
      switchMap((user) => this.authEventListener.postSignIn(user)),
      tap(() => {
        this.router.navigateByUrl(this.redirectUrl || '/');
      }),
      catchError((val) => {
        console.error(`Error authenticating, Redirecting to '${this.redirectUrl || '/'}'`, val);
        this.router.navigateByUrl(this.redirectUrl || '/');
        return of(val);
      })
    );
  }

  /**
   * Signs out user and redirects them to a signout page.
   */
  signOut$(): Observable<void> {
    return this.authEventListener
      .preSignOut(this.getUser())
      .pipe(switchMap(() => from(this.manager.signoutRedirect())));
  }

  /**
   * Completes silent refresh of user's credentials
   */
  completeSilentRefresh$(): Observable<void> {
    return from(this.manager.signinSilentCallback());
  }

  /**
   * Starts silent refresh of user's credentials
   */
  startSilentRefresh$(): Observable<XpoAuthUser> {
    return this.authEventListener.preRefresh(this.getUser()).pipe(
      switchMap(() => from(this.manager.signinSilent())),
      finalize(() => this.authEventListener.postRefresh(this.getUser()))
    );
  }

  private get redirectUrl(): string {
    return sessionStorage.getItem(XpoAuthenticationService.AuthRedirectSessionKey);
  }
  private set redirectUrl(v: string) {
    sessionStorage.setItem(XpoAuthenticationService.AuthRedirectSessionKey, v);
  }
}
