import {ApplicationCd} from '../';
import {EnumHelper} from './enum-helper';

export class ApplicationCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ApplicationCd ) {
      this._values.push(ApplicationCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ApplicationCd {

    for ( const obj in ApplicationCd ) {
      if (ApplicationCd[obj] === value){
        return ApplicationCd[obj] as ApplicationCd;
      }
    }
  }
}

const ApplicationCdHelper = new ApplicationCdHelperEnumHelperClass();
export default ApplicationCdHelper;
