import {BeaconOwnerTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class BeaconOwnerTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BeaconOwnerTypeCd ) {
      this._values.push(BeaconOwnerTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BeaconOwnerTypeCd {

    for ( const obj in BeaconOwnerTypeCd ) {
      if (BeaconOwnerTypeCd[obj] === value){
        return BeaconOwnerTypeCd[obj] as BeaconOwnerTypeCd;
      }
    }
  }
}

const BeaconOwnerTypeCdHelper = new BeaconOwnerTypeCdHelperEnumHelperClass();
export default BeaconOwnerTypeCdHelper;
