import { Injectable } from '@angular/core';
import { ListModuleGroupsResp } from '@xpo-ltl/sdk-moduleexecutor';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class ListModuleGroupsStoreService {
  private listModuleGroupsSubject: BehaviorSubject<ListModuleGroupsResp>;
  private _listModuleGroups$: Observable<ListModuleGroupsResp>;

  constructor() {
    this.initialize();
  }

  get listModuleGroups$(): Observable<ListModuleGroupsResp> {
    return this._listModuleGroups$;
  }

  initialize() {
    this.listModuleGroupsSubject = new BehaviorSubject<ListModuleGroupsResp>(null);
    this._listModuleGroups$ = this.listModuleGroupsSubject.asObservable().pipe(filter((val) => !!val));
  }

  populateListModuleGroups(listModuleGroups: ListModuleGroupsResp): void {
    this.listModuleGroupsSubject.next(listModuleGroups);
  }
}
