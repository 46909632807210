import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';

import { XpoAdvancedSelectTreeNodeComponent } from './advanced-select-tree-node/index';
import { XpoAdvancedSelectComponentOption, XpoAdvancedSelectOption } from './models/index';

@Component({
  selector: 'xpo-advanced-select-panel',
  templateUrl: './advanced-select-panel.component.html',
  styleUrls: ['./advanced-select-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-AdvancedSelectPanel' },
})
export class XpoAdvancedSelectPanelComponent implements AfterViewInit {
  filteredOptions: XpoAdvancedSelectComponentOption[];
  isSelectAllSelected: boolean = false;
  hasSearchBar: boolean;
  selectedValue: XpoAdvancedSelectComponentOption;

  /** Whether or not only a single option can be selected */
  @Input()
  get isSingleSelect(): boolean {
    return this.isSingleSelectValue;
  }
  set isSingleSelect(v: boolean) {
    this.isSingleSelectValue = coerceBooleanProperty(v);
  }
  private isSingleSelectValue: boolean = false;

  /** Whether or not the select all checkbox is shown */
  @Input()
  get showSelectAll(): boolean {
    return this.showSelectAllValue;
  }
  set showSelectAll(v: boolean) {
    this.showSelectAllValue = coerceBooleanProperty(v);
  }
  private showSelectAllValue: boolean = false;

  /** Options of the select filter */
  @Input()
  get options(): XpoAdvancedSelectComponentOption[] {
    return this.optionsValue;
  }
  set options(v: XpoAdvancedSelectComponentOption[]) {
    this.optionsValue = this.toComponentOptions(v);

    // Only show search-bar if there is a scroll bar in the container of the options.
    this.hasSearchBar = this.optionsValue.length > 5;
    this.filteredOptions = this.optionsValue;
  }
  private optionsValue: XpoAdvancedSelectComponentOption[] = [];

  @Input()
  get selection(): string | string[] {
    return this.selectionValue;
  }
  set selection(value: string | string[]) {
    if (this.selectionValue !== value) {
      this.selectionValue = value;
      this.updateSelectedOptions(value);
    }
  }
  private selectionValue: string | string[];

  @Output()
  selectionChange = new EventEmitter<string | string[]>();

  @ViewChildren(XpoAdvancedSelectTreeNodeComponent) childTreeNodes: QueryList<XpoAdvancedSelectTreeNodeComponent>;

  @ViewChild('searchBox')
  searchBox: ElementRef;

  /**
   * Code inspired by https://github.com/anas-aljabri/angular-tree-search/blob/master/projects/tree/src/lib/globals.ts
   */
  private static filterTree(
    tree: XpoAdvancedSelectComponentOption[],
    keyword: string
  ): XpoAdvancedSelectComponentOption[] {
    //  If the entry is empty string return the full tree without filtering and update the matches
    if (!keyword.length || !keyword.trim().length) {
      return tree;
    }

    return tree.map(function filterCallback(node: XpoAdvancedSelectComponentOption): XpoAdvancedSelectComponentOption {
      node.hidden = true;

      if (node.label.toLocaleLowerCase().indexOf(keyword) > -1) {
        node.hidden = false;
      } else if (node.children && node.children.length) {
        const mappedChildren = node.children.map(filterCallback);
        if (mappedChildren.some((x) => !x.hidden)) {
          node.hidden = false;
        }
      }

      return node;
    });
  }

  ngAfterViewInit(): void {
    if (this.searchBox) {
      this.searchBox.nativeElement.focus();
    }
  }

  filterOptions(value: string): void {
    this.filteredOptions = XpoAdvancedSelectPanelComponent.filterTree(this.getOptionsClone(), value);
  }

  handleSelectAllClicked(isChecked: boolean): void {
    this.isSelectAllSelected = !!isChecked;

    // Set view checkboxes
    if (this.childTreeNodes && this.childTreeNodes.length) {
      this.childTreeNodes.forEach((v) => v.setSelected(isChecked));
    }

    // Set options 'selected' value
    const selectOption = (opt: XpoAdvancedSelectComponentOption, value: boolean) => {
      opt.selected = value;
      if (opt.children && opt.children.length) {
        opt.children.forEach((ch) => selectOption(ch, value));
      }
    };
    this.options.forEach((x) => selectOption(x, this.isSelectAllSelected));

    const values = this.getMultiSelectCheckedValues();
    this.selectionChange.emit(values);
  }

  /** Updates the selected criterion of filter */
  onOptionSelected(selectedValue: XpoAdvancedSelectComponentOption): void {
    this.updateValueInOptions(selectedValue);

    let value: any;

    if (this.isSingleSelect) {
      this.selectedValue = selectedValue;
      value = selectedValue.value;
    } else {
      value = this.getMultiSelectCheckedValues();
    }

    this.selectionChange.emit(value);
  }

  private toComponentOptions(options: XpoAdvancedSelectOption[]): XpoAdvancedSelectComponentOption[] {
    const toComponentFilterOption = (option: XpoAdvancedSelectOption): XpoAdvancedSelectComponentOption => {
      const children = option.children && option.children.length ? option.children.map(toComponentFilterOption) : [];

      return { ...option, indeterminate: false, selected: false, children };
    };

    return (options || []).map(toComponentFilterOption);
  }

  private updateSelectedOptions(fieldValue: string | string[]): void {
    if (this.isSingleSelect) {
      this.selectedValue = this.options.find((v) => v.value === <string>fieldValue) || undefined;
      return;
    }

    const val = <string[]>fieldValue || [];
    const loadCheckboxesState = (criteriaValues: string[], opt: XpoAdvancedSelectComponentOption) => {
      const matchedOption = criteriaValues.find((x) => opt.value === x);

      opt.selected = !!matchedOption;
      if (opt.children && opt.children.length > 0) {
        opt.children.forEach((ch) => loadCheckboxesState(criteriaValues, ch));
      }
      opt.indeterminate =
        opt.children &&
        opt.children.length > 0 &&
        !opt.children.every((ch) => ch.selected) &&
        opt.children.some((ch) => ch.selected);
    };

    const updatedOptions = this.getOptionsClone();

    updatedOptions.forEach((opt) => {
      loadCheckboxesState(val, opt);
    });

    this.optionsValue = updatedOptions;
    // TODO: we may need to account for the current input of this filter.
    // Updating the filtered options reference so that the selection appears in the view.
    this.filteredOptions = this.optionsValue;

    this.isSelectAllSelected = updatedOptions.every((o) => o.selected === true);
  }

  private getMultiSelectCheckedValues(): string[] {
    const mapOptionsToValue = (options: XpoAdvancedSelectComponentOption[]) => {
      return options
        .filter((opt) => opt.selected || opt.indeterminate)
        .map((opt) => {
          if (!opt.children || opt.children.length === 0) {
            return opt.value;
          } else if (opt.children.every((ch) => ch.selected)) {
            return [opt.value, ...mapOptionsToValue(opt.children)];
          }
          return mapOptionsToValue(opt.children);
        });
    };

    return mapOptionsToValue(this.options).flat();
  }

  private updateValueInOptions(updatedValue: XpoAdvancedSelectComponentOption): void {
    const optionsClone: XpoAdvancedSelectComponentOption[] = this.getOptionsClone();
    const selectedValueIndex = optionsClone.findIndex((v) => v.value === updatedValue.value);

    // visibility flags should not get passed to the original options observable
    // these flags should be present only on filteredOptions$
    // So here we are just clearing out whatever flags are set
    this.setNodeVisibility(updatedValue, false);
    optionsClone[selectedValueIndex] = updatedValue;

    this.optionsValue = optionsClone;
  }

  private getOptionsClone(): XpoAdvancedSelectComponentOption[] {
    // Cloning it this way since Object.assign only clones shallow.
    return JSON.parse(JSON.stringify(this.options));
  }

  private setNodeVisibility(option: XpoAdvancedSelectComponentOption, hidden: boolean): void {
    option.hidden = hidden;
    option.children.forEach((x) => this.setNodeVisibility(x, hidden));
  }
}
