import {ShipmentSourceCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentSourceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentSourceCd ) {
      this._values.push(ShipmentSourceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentSourceCd {

    for ( const obj in ShipmentSourceCd ) {
      if (ShipmentSourceCd[obj] === value){
        return ShipmentSourceCd[obj] as ShipmentSourceCd;
      }
    }
  }
}

const ShipmentSourceCdHelper = new ShipmentSourceCdHelperEnumHelperClass();
export default ShipmentSourceCdHelper;
