import {ProPrintedLabelStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ProPrintedLabelStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ProPrintedLabelStatusCd ) {
      this._values.push(ProPrintedLabelStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ProPrintedLabelStatusCd {

    for ( const obj in ProPrintedLabelStatusCd ) {
      if (ProPrintedLabelStatusCd[obj] === value){
        return ProPrintedLabelStatusCd[obj] as ProPrintedLabelStatusCd;
      }
    }
  }
}

const ProPrintedLabelStatusCdHelper = new ProPrintedLabelStatusCdHelperEnumHelperClass();
export default ProPrintedLabelStatusCdHelper;
