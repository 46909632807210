import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	CreateRoleRqst,
	CreateRoleResp,
	ListRolesResp,
	ListRolesQuery,
	UpdateRoleRqst,
	UpdateRoleResp,
	ListRolesForEmployeeResp,
	ListRolesForEmployeePath,
	UpdateRolesForEmployeeRqst,
	ProcessEmployeeRolesEffectiveTodayResp,
	ProcessEmployeeRolesExpiringTodayResp,
	GetEmployeeCountByRoleResp,
	GetEmployeeCountByRolePath,
	ListSecondaryRolesByJobCodeResp,
	ListSecondaryRolesByJobCodeQuery,
	ListSecondaryRolesByJobCodePath,
	UpdateSecondaryRolesByJobCodeRqst,
	ListEmployeesByRoleIdResp,
	ListEmployeesByRoleIdPath,
	GetFullEmployeeDetailsByEmpIdResp,
	GetFullEmployeeDetailsByEmpIdQuery,
	GetFullEmployeeDetailsByEmpIdPath,
	ListEmployeesByNameResp,
	ListEmployeesByNameQuery,
	ListLatestRoleTypeEventLogsResp,
	DetermineContractorEmployeeConversionResp,
	DetermineContractorEmployeeConversionPath,
	ListEmployeesByEmpIdRqst,
	ListEmployeesByEmpIdResp,
	SearchEmployeeDetailsBySicResp,
	SearchEmployeeDetailsBySicPath,
	GetDomicileSicForEmployeeResp,
	GetDomicileSicForEmployeeQuery,
	GetDomicileSicForEmployeePath,
	ListEmployeesBySicResp,
	ListEmployeesBySicQuery,
	ListEmployeesBySicPath,
	GetEmployeeDetailsByEmpIdResp,
	GetEmployeeDetailsByEmpIdQuery,
	GetEmployeeDetailsByEmpIdPath,
	GetHrsEmployeeDetailsByEmpIdResp,
	GetHrsEmployeeDetailsByEmpIdQuery,
	GetHrsEmployeeDetailsByEmpIdPath,
	GetEmployeeJobInfoForDriverResp,
	GetEmployeeJobInfoForDriverQuery,
	GetEmployeeJobInfoForDriverPath,
	ListDsrEmployeesResp,
	ListDsrEmployeesQuery,
	ListDirectReportEmployeesResp,
	ListDirectReportEmployeesQuery,
	ListDirectReportEmployeesPath,
	ListEmailAddressesForEmployeeResp,
	ListEmailAddressesForEmployeeQuery,
	ListEmailAddressesForEmployeePath,
	ListSecurityQuestionsForEmployeeResp,
	ListSecurityQuestionsForEmployeePath,
	UpdateSecurityQuestionsForEmployeeRqst,
	IsEmployeeADriverResp,
	IsEmployeeADriverPath,
	GetSystemAccessForEmployeeResp,
	GetSystemAccessForEmployeePath,
	UpdateSystemAccessForEmployeeRqst,
	GetSystemIdDetailsForEmployeeResp,
	GetSystemIdDetailsForEmployeePath,
	UpdateSystemIdDetailsForEmployeeRqst,
	GetSystemIdDetailsByDomainAndUserIdResp,
	GetSystemIdDetailsByDomainAndUserIdQuery,
	GetLatestActivityForEmployeeResp,
	GetLatestActivityForEmployeePath,
	CreateEmployeePunchActivityRecordRqst,
	CreateEmployeePunchActivityRecordResp,
	CreateEmployeePunchActivityRecordsRqst,
	CreateEmployeePunchActivityRecordsResp,
	CreateEmployeeLunchRecordRqst,
	CreateEmployeeLunchRecordResp,
	CreateEmployeeDsrTripRecordRqst,
	CreateEmployeeDsrTripRecordResp,
	ListLunchPunchesSinceDateTimeResp,
	ListLunchPunchesSinceDateTimeQuery,
	ListEmployeePunchesSinceDateTimeResp,
	ListEmployeePunchesSinceDateTimeQuery,
	ListDsrTripsSinceDateTimeResp,
	ListDsrTripsSinceDateTimeQuery,
	SetSentToEobrIndRqst,
	ListJobCodeRoleAssignmentsResp,
	ListJobCodeRoleAssignmentsQuery,
	ListJobCodesWithDefaultPrimaryRoleIdResp,
	UpdateJobCodeRoleAssignmentsRqst,
	ListEmployeesByJobCodeResp,
	ListEmployeesByJobCodeQuery,
	GetEmployeeCountByJobCodeResp,
	GetEmployeeCountByJobCodePath,
	ListElogEmployeesByJobCodeResp,
	ListElogEmployeesByJobCodeQuery,
	DetermineLtlEmployeeIdRqst,
	DetermineLtlEmployeeIdResp,
	GetEmployeeIdXrefResp,
	GetEmployeeIdXrefQuery
} from './api-humanresource';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class HumanResourceApiService extends BaseService {
	private static HumanResourceApiEndpoint = 'humanresourceApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Create a job role that can later be assigned to an employee.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input fields must be valid
	* <br/>
	* <br/>Post-condition:
	* <br/>Role will be created. Role ID will be returned in addition to all other fields (status will be set to Active)
	* <br/>
	*/
	public createRole(request: CreateRoleRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateRoleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/roles'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search job roles using input filter criteria describing the desired role results
	* <br/>
	* <br/>Pre-conditions:
	* <br/>At least one input field must contain data for the search. Multiple fields can be used.  
	* <br/>
	* <br/>Post-condition:
	* <br/>Role table will be searched. A list of all roles satisfying the search criteria will be returned. 
	* <br/>
	*/
	public listRoles(
							   queryParams: ListRolesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListRolesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/roles'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Update details about a job role
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input fields must be valid
	* <br/>
	* <br/>Post-condition:
	* <br/>Role table will be updated.  
	* <br/>
	*/
	public updateRole(request: UpdateRoleRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateRoleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/roles/{roleId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* List the roles for an employee
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input fields must be valid. The employeeId will be the one for whom we want the roles.
	* <br/>
	* <br/>Post-condition:
	* <br/>A list of roles for the employee will be returned.
	* <br/>
	*/
	public listRolesForEmployee(
							   pathParams: ListRolesForEmployeePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListRolesForEmployeeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employee-roles/{employeeId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Update the role assignments for an employee
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input fields must be valid. Employee id must be supplied.
	* <br/>
	* <br/>Post-condition:
	* <br/>The role assigments for the employee will be updated.
	* <br/>
	*/
	public updateRolesForEmployee(request: UpdateRolesForEmployeeRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employee-roles/{employeeId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Processes employee roles that are becoming effective today. Creates event logs if required.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>None. 
	* <br/>
	* <br/>Post-condition:
	* <br/>1. Return a boolean which determines whether the process failed or was successful.
	*/
	public processEmployeeRolesEffectiveToday(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ProcessEmployeeRolesEffectiveTodayResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employee-roles/effective-today'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Processes employee roles that are expiring today. Creates event logs if required.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>None. 
	* <br/>
	* <br/>Post-condition:
	* <br/>1. Return a boolean which determines whether the process failed or was successful.
	* <br/>
	*/
	public processEmployeeRolesExpiringToday(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ProcessEmployeeRolesExpiringTodayResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employee-roles/expiring-today'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation retrieves the count of active employees from IDM_EMPL_ROLE that are attached to a specific role.
	* <br/>
	* <br/>Mandatory Criteria:
	* <br/>- Role
	*/
	public getEmployeeCountByRole(
							   pathParams: GetEmployeeCountByRolePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetEmployeeCountByRoleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employee-roles/count/{role}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns a list of the secondary roles associated with a job code.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The input must be valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the job code is valid, a list of secondary roles associated with it will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned. 
	*/
	public listSecondaryRolesByJobCode(
							   pathParams: ListSecondaryRolesByJobCodePath,
							   queryParams: ListSecondaryRolesByJobCodeQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSecondaryRolesByJobCodeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/secondary-roles/{jobCode}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation updates (saves or removes) secondary roles that are assigned to a job code.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The input must be valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the job code is valid, then the secondary roles that were associated with it will be updated.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public updateSecondaryRolesByJobCode(request: UpdateSecondaryRolesByJobCodeRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/secondary-roles/{jobCode}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Returns a list of employees that have the input Role Id assigned.
	* <br/>		
	* <br/>Pre-conditions:
	* <br/>Role Id must be input and valid.
	* <br/>
	* <br/>Post-condition:
	* <br/>A list of employees associated with the Role Id will be returned.
	*/
	public listEmployeesByRoleId(
							   pathParams: ListEmployeesByRoleIdPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListEmployeesByRoleIdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/roles/{roleId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves full details about a given Employee based on input Employee Id. This API will be limited to the RAM system and it's downstream consumers.
	* <br/>Mandatory Criteria:
	* <br/>- Employee Id
	* <br/>Optional Criteria:
	* <br/>- Effective Date (of employee record)
	* <br/>           
	*/
	public getFullEmployeeDetailsByEmpId(
							   pathParams: GetFullEmployeeDetailsByEmpIdPath,
							   queryParams: GetFullEmployeeDetailsByEmpIdQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetFullEmployeeDetailsByEmpIdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/{employeeId}/full-details'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Searches employees using input filter criteria (required last name and optional first name, SIC, role code(s)) are used to return a list of employees.  The user can then use this list to select an employee to populate the first and last name fields of a conversation.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>At least one input field must contain data for the search. Multiple fields can be used.
	* <br/>
	* <br/>Post-condition:
	* <br/>A list of employees satisfying the search criteria will be returned.
	*/
	public listEmployeesByName(
							   queryParams: ListEmployeesByNameQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListEmployeesByNameResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Display all IDM event logs created in the last 6 hour time window.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>None. 
	* <br/>
	* <br/>Post-condition:
	* <br/>1. Return all event log details that meet the time window criteria.
	*/
	public listLatestRoleTypeEventLogs(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListLatestRoleTypeEventLogsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/idm-event-logs/latest-role-type'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation determines if the employee is a contractor-to-employee conversion. 
	* <br/>
	* <br/>Mandatory Criteria:
	* <br/>- Employee's Success Factors 8-digit employee ID
	*/
	public determineContractorEmployeeConversion(
							   pathParams: DetermineContractorEmployeeConversionPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DetermineContractorEmployeeConversionResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/cte/{sfEmplId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists employees based on the employee IDs provided.
	* <br/>
	* <br/>Pre-condition(s):
	* <br/>1. A valid 5 character employee ID is required.
	* <br/>
	* <br/>Post-condition(s):
	* <br/>1. Name information will be returned if the employee IDs are valid.
	* <br/>2. If not valid, an appropriate error message will be returned. 
	* <br/>
	*/
	public listEmployeesByEmpId(request: ListEmployeesByEmpIdRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListEmployeesByEmpIdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation is used to search for employee details (employee names and employee IDs) of the the DSRs based on the SIC, search string, and zones indicated.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The SIC location of the employee, search string, and zone indicator are included and valid.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. If the data provided was valid, a list of employee names and employee IDs of the DSRs found are returned.
	* <br/>2. If not, an appropriate error message is returned.
	*/
	public searchEmployeeDetailsBySic(
							   pathParams: SearchEmployeeDetailsBySicPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchEmployeeDetailsBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/{locationSic}/{searchString}/{includeZones}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves the domicile SIC for an employee. It also identifies whether or not the employee is a driver.
	* <br/>
	* <br/>The effective date is required, but will be passed as a query parameter.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. All input is provided and valid.
	* <br/>
	* <br/>Post-condtion:
	* <br/>1. If the data provided is valid, then the domicile SIC and flag indicating whether the employee is a driver will be returned.
	* <br/>2. Otherwise an appropriate error message is returned.          
	*/
	public getDomicileSicForEmployee(
							   pathParams: GetDomicileSicForEmployeePath,
							   queryParams: GetDomicileSicForEmployeeQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetDomicileSicForEmployeeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employee-domicile/{employeeId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Searches employees using input filter criteria to return a list of employees.
	* <br/>
	* <br/>Required criterion:
	* <br/>- SIC
	* <br/>
	* <br/>Optional criterion:
	* <br/>- role code(s)
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. At least one input field must contain data for the search. 
	* <br/>2.Multiple fields can be used.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. A list of employees satisfying the search criteria will be returned.
	*/
	public listEmployeesBySic(
							   pathParams: ListEmployeesBySicPath,
							   queryParams: ListEmployeesBySicQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListEmployeesBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/employees'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves details about a given Employee based on input Employee Id.
	* <br/>Mandatory Criteria:
	* <br/>- Employee Id
	* <br/>Optional Criteria:
	* <br/>requestType: Valid value = Basic. If requestType=Basic then only the basic employee details are provided. If requestType not= Basic then error is returned and if requestType is not provided then full employee details are provided.  Within the EmployeeBasic class, userType will only be populated if the requestType parameter is set to ï¿½userï¿½.
	* <br/>Optional Criteria:
	* <br/>- Effective Date (of employee record)
	* <br/> 
	*/
	public getEmployeeDetailsByEmpId(
							   pathParams: GetEmployeeDetailsByEmpIdPath,
							   queryParams: GetEmployeeDetailsByEmpIdQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetEmployeeDetailsByEmpIdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/{employeeId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves details about a given Employee based on input Employee Id.  It will call the HS14 proxy within the HRS component.
	* <br/>
	* <br/>Mandatory Criteria:
	* <br/>- Employee Id
	*/
	public getHrsEmployeeDetailsByEmpId(
							   pathParams: GetHrsEmployeeDetailsByEmpIdPath,
							   queryParams: GetHrsEmployeeDetailsByEmpIdQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHrsEmployeeDetailsByEmpIdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/{employeeId}/hrs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves details about an Employee Job Position for the input employee id.  Data will be returned only if the input employee is identified as a Driver, otherwise a Not Found condition will be returned. If the employee is a driver, the operation will return the company code, domicile SIC, and pay group for the employee's assigned job position. This operation will accept old or new employee ID and will perform a translation from old to new if necessary in order to always return new employee ID.
	* <br/>
	* <br/>Mandatory Criteria:
	* <br/>- Employee Id
	* <br/>
	* <br/>Pre-condition: Input Employee Id is not a driver.
	* <br/>Post-condition: Not Found returned.
	* <br/>
	* <br/>Pre-condition: Input Employee Id for a driver is the old value.
	* <br/>Post-condition: New value Employee Id is returned.
	* <br/>
	*/
	public getEmployeeJobInfoForDriver(
							   pathParams: GetEmployeeJobInfoForDriverPath,
							   queryParams: GetEmployeeJobInfoForDriverQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetEmployeeJobInfoForDriverResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employee-job-positions/{employeeId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get a list of all drivers that match the provided input of either SIC and/or driver last name. 
	* <br/>Pre-condition - provided SIC and/or driver starting last name.
	* <br/>Post-condition - a list of DSR found for the provided request.
	*/
	public listDsrEmployees(
							   queryParams: ListDsrEmployeesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListDsrEmployeesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/dsrEmployees'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List a manager's direct report employees based on the employee id of the manager.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input fields must be valid. The employeeId will be the manager who is signed on.
	* <br/>
	* <br/>Post-condition:
	* <br/>A list of active employees who directly report to the manager will be returned.
	* <br/>
	*/
	public listDirectReportEmployees(
							   pathParams: ListDirectReportEmployeesPath,
							   queryParams: ListDirectReportEmployeesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListDirectReportEmployeesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/{employeeId}/direct-reports'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will list all email addresses for an employee.  If the preferredEmailAddressInd is set to true, it will return only the preferred (primary) email address for the employee
	* <br/>Pre-conditions:
	* <br/>Input employeeId must be supplied and valid. The preferredEmailAddressInd may be set to true to return only the preferred (primary) email address for the employee
	* <br/>
	* <br/>Post-condition:
	* <br/>Return all email addresses for the input employeeId if preferredEmailAddressInd is false; return only the preferred (primary) email address if preferredEmailAddressInd is true.
	* <br/>
	*/
	public listEmailAddressesForEmployee(
							   pathParams: ListEmailAddressesForEmployeePath,
							   queryParams: ListEmailAddressesForEmployeeQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListEmailAddressesForEmployeeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/{employeeId}/email-addresses'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will list security questions and answers for an employee. This information is used whenever identity verification of the employee is required by System Admin.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input employeeId must be supplied and valid. 
	* <br/>
	* <br/>Post-condition:
	* <br/>Return all security questions and answers for the input employee.
	* <br/>
	*/
	public listSecurityQuestionsForEmployee(
							   pathParams: ListSecurityQuestionsForEmployeePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSecurityQuestionsForEmployeeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/{employeeId}/security-questions'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service updates the question selected and the answer provided by an employee. This information is used whenever identity verification of the employee is required by System Admin.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input fields must be valid
	* <br/>
	* <br/>Post-condition:
	* <br/>Security Question table will be updated.  
	* <br/>
	*/
	public updateSecurityQuestionsForEmployee(request: UpdateSecurityQuestionsForEmployeeRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/{employeeId}/security-questions'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Determines if the provided employee ID belong to a driver.  If true this result will then determine what actions to take.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid employee ID is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. A true value is returned if the provided employee ID belongs to a driver.
	*/
	public isEmployeeADriver(
							   pathParams: IsEmployeeADriverPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<IsEmployeeADriverResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/drivers/{employeeId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Retrieve system access indicators for an employee based on employee id.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input Employee ID must be supplied and valid. 
	* <br/>
	* <br/>Post-condition:
	* <br/>Return system access indicators for the input employee id.
	* <br/>
	*/
	public getSystemAccessForEmployee(
							   pathParams: GetSystemAccessForEmployeePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSystemAccessForEmployeeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/{employeeId}/systemsaccess'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Update system access details for an employee
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input fields must be valid
	* <br/>
	* <br/>Post-condition:
	* <br/>System Access table will be updated.  
	* <br/>
	*/
	public updateSystemAccessForEmployee(request: UpdateSystemAccessForEmployeeRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/{employeeId}/systemsaccess'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Retrieve System Security IDs for an employee based on employee id.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input Employee ID must be supplied and valid. 
	* <br/>
	* <br/>Post-condition:
	* <br/>Return system security ID details for the input employee id.
	* <br/>
	*/
	public getSystemIdDetailsForEmployee(
							   pathParams: GetSystemIdDetailsForEmployeePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSystemIdDetailsForEmployeeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/{employeeId}/systemId'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Update system security IDs for an employee
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input fields must be valid
	* <br/>
	* <br/>Post-condition:
	* <br/>System Security ID table will be updated.  
	* <br/>
	*/
	public updateSystemIdDetailsForEmployee(request: UpdateSystemIdDetailsForEmployeeRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/{employeeId}/systemId'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Display text information about the different system access a given employee has 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input Domain must be valid. 
	* <br/>Input UserId must be valid.
	* <br/>
	* <br/>Post-condition:
	* <br/>Return all system access ID details for given Employee.
	* <br/>
	*/
	public getSystemIdDetailsByDomainAndUserId(
							   queryParams: GetSystemIdDetailsByDomainAndUserIdQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSystemIdDetailsByDomainAndUserIdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/systemId'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get the latest punch activity for the employee
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input employeeId must be supplied and valid. 
	* <br/>
	* <br/>Post-condition:
	* <br/>Returns first and last name of employee, time and date of the latest punch activity as well as whether it was a clock in or clock out.
	* <br/>
	*/
	public getLatestActivityForEmployee(
							   pathParams: GetLatestActivityForEmployeePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLatestActivityForEmployeeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/{employeeId}/punch-activities'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Insert a new record for an employee punch activity.
	* <br/>Pre-condition:
	* <br/>employeeId, lastName, firstName, punchDate, punchTime, and other timeclock and payroll related fields are specified as input.
	* <br/>Post-Condition:
	* <br/>a punch activity record will be created and the activity Id is assigned and sent back in the response.
	*/
	public createEmployeePunchActivityRecord(request: CreateEmployeePunchActivityRecordRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateEmployeePunchActivityRecordResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/{employeeId}/punch-activities'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Insert the list of new raw employee punch records for an employee. WFM will apply logic to interpret the activities into meaningful LTL Time Card events. Not every raw event will have an LTL Time Card event. At times more than one event is used to create one LTL time card event.
	* <br/>Pre-condition:
	* <br/>employeeId, lastName, firstName, punchDate, punchTime, and other timeclock and payroll related fields are specified as input.
	* <br/>Post-Condition: The entire list of punch activities will be returned after successful insert in the WFM database. Those activities that qualify will have an ltlTimeCardEventTypeCd assigned.
	*/
	public createEmployeePunchActivityRecords(request: CreateEmployeePunchActivityRecordsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateEmployeePunchActivityRecordsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/punch-activities'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Insert a new record for an employee lunch punch.
	* <br/>Pre-condition:
	* <br/>EmployeeId, the punch date, punch time and the punch type code which could be StartLunch or EndLunch are specified as input.
	* <br/>Post-Condition:
	* <br/>a lunch record is inserted and a composite key consisting of the employeeId, punchDate and punchTime is returned.
	*/
	public createEmployeeLunchRecord(request: CreateEmployeeLunchRecordRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateEmployeeLunchRecordResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/{employeeId}/lunch-punch-activities'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Insert a new record for a DSR trip
	* <br/>Pre-condition:
	* <br/>EmployeeId, tripId, platformId, and dispatchDateTime are specified as input.
	* <br/>Post-Condition:
	* <br/>a trip record is inserted and an Id is assigned and sent back in the response.
	*/
	public createEmployeeDsrTripRecord(request: CreateEmployeeDsrTripRecordRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateEmployeeDsrTripRecordResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/{employeeId}/dsrtrips'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* List of all lunch punch activities for the given date and time.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input fields must be valid. lunchDateTime will be used to search for all activities equal to or later than the provided date/time. The list will optionally be filtered by the presence of the boolean sentToEobrInd. If false, only the punch activities since the provided date and time that have not been successfully sent to the eobr vendor will be returned.
	* <br/>
	* <br/>Post-condition:
	* <br/>A list of all employee lunch punches that meets the date and time criteria.
	* <br/>
	*/
	public listLunchPunchesSinceDateTime(
							   queryParams: ListLunchPunchesSinceDateTimeQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListLunchPunchesSinceDateTimeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/lunch-punch-activities'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of the punch activities for employees since a given date and time.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input fields must be valid. requestDate and requestTime will be used to search for all activities with that date/time and after. The list will optionally be filtered by the presence of the boolean sentToEobrInd. If false, only the punch activities since the provided date and time that have not been successfully sent to the eobr vendor will be returned.
	* <br/>
	* <br/>
	* <br/>Post-condition:
	* <br/>A list of employee Kronos' activites based on the date/time parameters.
	* <br/>
	*/
	public listEmployeePunchesSinceDateTime(
							   queryParams: ListEmployeePunchesSinceDateTimeQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListEmployeePunchesSinceDateTimeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/punch-activities'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of all DSR trip activities for the given date and time.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input fields must be valid. dispatchDateTime will be used to search for all activities equal to or later than the provided date/time. The list will optionally be filtered by the presence of the boolean sentToEobrInd. If false, only the trips since the provided date and time that have not been successfully sent to the eobr vendor will be returned.
	* <br/>
	* <br/>Post-condition:
	* <br/>A list of all employee DSR trip that meets the date and time criteria.
	* <br/>
	*/
	public listDsrTripsSinceDateTime(
							   queryParams: ListDsrTripsSinceDateTimeQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListDsrTripsSinceDateTimeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/dsrtrips'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* For each punchActivityKey in the list, set the sentToEobrInd to true.
	* <br/>Pre-condition: Existing punch activity keys are provided
	* <br/>Post-condition: The sentToEobrInd will be set to true for those records.
	*/
	public setSentToEobrInd(request: SetSentToEobrIndRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/punch-activities/senttoeobrind'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Search and list jobcodes using input filter criteria describing the desired list results
	* <br/>
	* <br/>Pre-conditions:
	* <br/>One input field must contain data for the search. Only one criteria can be used for the search.  
	* <br/>
	* <br/>Post-condition:
	* <br/>Jobcode table will be searched. A list of all Jobcodes and assigned role details satisfying the search criteria will be returned. 
	* <br/>
	*/
	public listJobCodeRoleAssignments(
							   queryParams: ListJobCodeRoleAssignmentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListJobCodeRoleAssignmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/jobcodes'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List jobcodes that have the default primary role id of 277.  
	* <br/>
	* <br/>
	*/
	public listJobCodesWithDefaultPrimaryRoleId(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListJobCodesWithDefaultPrimaryRoleIdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/default-primary-roleid-jobcodes'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service updates the role assigned and defined as the primary role for a Job Code.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Input fields must be valid
	* <br/>
	* <br/>Post-condition:
	* <br/>Job Code table will be updated with new Role ID.  
	* <br/>
	*/
	public updateJobCodeRoleAssignments(request: UpdateJobCodeRoleAssignmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/jobcodes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Returns a list of employees using input filter criteria (Job Codes) are used to return a list of employees along with their Job and Role information.
	* <br/>		
	* <br/>Pre-conditions:
	* <br/>At least one Job Code must be input and valid.
	* <br/>
	* <br/>Post-condition:
	* <br/>A list of employees associated with the Job Code will be returned.
	*/
	public listEmployeesByJobCode(
							   queryParams: ListEmployeesByJobCodeQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListEmployeesByJobCodeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employees/jobcode'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves the count of active employees from HSF_JOB attached to a specific JobCode.
	* <br/>
	* <br/>Mandatory Criteria:
	* <br/>- JobCode
	*/
	public getEmployeeCountByJobCode(
							   pathParams: GetEmployeeCountByJobCodePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetEmployeeCountByJobCodeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employee-count/{jobCode}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves a list of employees by jobcode for elogging.
	* <br/>
	* <br/>Mandatory Criteria:
	* <br/>- jobCode      
	*/
	public listElogEmployeesByJobCode(
							   queryParams: ListElogEmployeesByJobCodeQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListElogEmployeesByJobCodeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elogs/employees/jobcodes'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation accepts an 8 character Success Factors (SF) employee ID and returns the corresponding 5 character People Soft (PS) employee ID.  If the SF employee ID isn't found in the cross reference table and the indicator says to create, then this operation will find the next available 5 character PS employee ID and create a translation row for the 8 character SF employee ID.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid SF employee ID, which has an existing PS employee ID, is provided.
	* <br/>2. A valid SF employee ID, which doesn't have an existing PS employee ID, is provided.
	* <br/>3. An invalid SF employee ID is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. The corresponding PS employee ID is returned along with the SF employee ID and the PS employee source code.
	* <br/>2. A cross reference is created and returns the corresponding PS employee ID along with the SF employee ID and the PS employee source code. 
	* <br/>3. An appropriate error code and message are returned.
	*/
	public determineLtlEmployeeId(request: DetermineLtlEmployeeIdRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DetermineLtlEmployeeIdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employeeId-xref'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation will accept either a People Soft (1) employee ID or a Success Factors (2) employee ID.
	* <br/>---------------------------------------------
	* <br/>(1) This operation accepts an a 5 character People Soft (PS) employee ID and returns the 8 character Success Factors (SF) employee ID.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid PS employee ID, which has an existing SF employee ID, is provided.
	* <br/>2. A valid PS employee ID, which doesn't have an existing SF employee ID, is provided or an invalid PS employee ID is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. The corresponding SF employee ID is returned.
	* <br/>2. An appropriate error code and message are returned.
	* <br/>-----------------------------------------
	* <br/>(2) This operation accepts an 8 character Success Factors (SF) employee ID and returns the corresponding 5 character People Soft (PS) employee ID. ï¿½If the SF employee ID isn't found in the cross reference table, then an appropriate error message will be returned.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid SF employee ID, which has an existing PS employee ID, is provided.
	* <br/>2. A valid SF employee ID, which doesn&apos;t have an existing PS employee ID, is provided.
	* <br/>3. An invalid SF employee ID is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. The corresponding PS employee ID is returned along with the SF employee ID and the PS employee source code.
	* <br/>2. An appropriate error message is returned that states that there isn&apos;t a PS employee ID associated with the SF employee ID. 
	* <br/>3. An appropriate error code and message are returned that state that the SF employee ID is invalid.
	*/
	public getEmployeeIdXref(
							   queryParams: GetEmployeeIdXrefQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetEmployeeIdXrefResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/employeeId-xref'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(HumanResourceApiService.HumanResourceApiEndpoint);
	}
}
