import {ShipmentSpecialServiceCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentSpecialServiceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentSpecialServiceCd ) {
      this._values.push(ShipmentSpecialServiceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentSpecialServiceCd {

    for ( const obj in ShipmentSpecialServiceCd ) {
      if (ShipmentSpecialServiceCd[obj] === value){
        return ShipmentSpecialServiceCd[obj] as ShipmentSpecialServiceCd;
      }
    }
  }
}

const ShipmentSpecialServiceCdHelper = new ShipmentSpecialServiceCdHelperEnumHelperClass();
export default ShipmentSpecialServiceCdHelper;
