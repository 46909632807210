
export enum ExceptionTypeCd {
	ADD_LOAD_REQUEST = 'AddLoadRequest',
	CUT_LOAD_REQUEST = 'CutLoadRequest',
	GMISLOAD = 'GMisload',
	HSS = 'HSS',
	LOAD_REQUEST = 'LoadRequest',
	PARTIAL_BYPASS = 'PartialBypass',
	PROGRESSIVE_LOAD = 'ProgressiveLoad'}

