import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import {
  XpoButtonModule,
  XpoCheckboxModule,
  XpoDialogModule,
  XpoIconModule,
  XpoLtlPopoverModule,
  XpoSnackBarModule,
  XpoTabsModule,
} from '@xpo-ltl/ngx-ltl-core';
import { XpoViewMgtFullWidthDirective } from './directives/full-width.directive';
import { XpoViewListComponent } from './view-list/view-list.component';
import { XpoViewManagementComponent } from './view-management.component';
import {
  XpoViewMgtModal,
  XpoViewMgtModalDeleteView,
  XpoViewMgtModalRenameView,
  XpoViewMgtModalSaveViewAs,
} from './view-modal/index';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    XpoDialogModule,
    MatProgressSpinnerModule,
    XpoTabsModule,
    XpoButtonModule,
    XpoCheckboxModule,
    XpoLtlPopoverModule,
    XpoIconModule,
    XpoSnackBarModule,
  ],
  declarations: [
    XpoViewManagementComponent,
    XpoViewListComponent,
    XpoViewMgtModal,
    XpoViewMgtModalSaveViewAs,
    XpoViewMgtModalRenameView,
    XpoViewMgtModalDeleteView,
    XpoViewMgtFullWidthDirective,
  ],
  exports: [
    XpoViewManagementComponent,
    XpoViewListComponent,
    XpoViewMgtModal,
    XpoViewMgtModalSaveViewAs,
    XpoViewMgtModalRenameView,
    XpoViewMgtModalDeleteView,
    XpoViewMgtFullWidthDirective,
  ],
  entryComponents: [XpoViewMgtModal, XpoViewMgtModalSaveViewAs, XpoViewMgtModalRenameView, XpoViewMgtModalDeleteView],
})
export class XpoViewManagementModule {}
