import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, ViewEncapsulation } from '@angular/core';
import { GridApi } from 'ag-grid-community';

export const XPO_PAGINATION_DEFAULT_PAGE_SIZES = [10, 25, 50, 100];
@Component({
  selector: 'xpo-board-pagination',
  templateUrl: 'board-pagination.component.html',
  styleUrls: ['board-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-BoardPagination' },
})
export class XpoBoardPagination {
  private _pageSizes: number[] = XPO_PAGINATION_DEFAULT_PAGE_SIZES;
  private _currentPageSize: number = XPO_PAGINATION_DEFAULT_PAGE_SIZES[0];

  agGridApi: GridApi;
  currentPage: number = 1;
  totalPages: number = 1;
  pageToGo: number = 1;
  rowCount: number = 0;
  currentRowTo: number = 0;
  displayPagination: boolean = true;

  pageSizeChange = new EventEmitter<number>();

  readonly SKIP_PREVIOUS: string = 'skip_previous';
  readonly NAVIGATE_BEFORE: string = 'navigate_before';
  readonly SKIP_NEXT: string = 'skip_next';
  readonly NAVIGATE_NEXT: string = 'navigate_next';
  readonly KEY_ENTER_NAME = 'Enter';

  set pageSizes(value: number[]) {
    this._pageSizes = value;
  }
  get pageSizes(): number[] {
    return this._pageSizes;
  }

  set currentPageSize(value: number) {
    this._currentPageSize = value;
  }
  get currentPageSize(): number {
    return this._currentPageSize;
  }

  constructor(private cd: ChangeDetectorRef) {}

  /**
   * Page information to display in the component
   */
  private setPageInfo(): void {
    if (!this.agGridApi) {
      return;
    }
    this.totalPages = this.agGridApi.paginationGetTotalPages();
    this.rowCount = this.agGridApi.paginationGetRowCount();
    this.cd.detectChanges();
  }

  /**
   * Refresh pagination when xpo-ag-grid-board emits event on state change
   */
  notifyPagination(): void {
    if (!this.agGridApi) {
      return;
    }
    this.totalPages = 0;
    this.setPageInfo();
    this.currentRowTo = (this.agGridApi.paginationGetCurrentPage() + 1) * this.agGridApi.paginationGetPageSize();
    this.currentPage = this.agGridApi.paginationGetCurrentPage() + 1;
    this.pageToGo = this.currentPage;
    // Set minimun value or custom
    this.displayPagination = Math.min(...this.pageSizes) <= this.rowCount;
    this.cd.detectChanges();
  }

  /**
   * Receives the agGridApi and persit it locally
   * @param event
   */
  initialize(agGridApi: any): void {
    /* TODO: this event type should be XpoAgGridBoardReadyEvent but it's from xpo-ltl-board-grid lib which is not
      being allowed to be imported here from @xpo-ltl/ngx-ltl-board-grid neither from /lib.
    */
    this.agGridApi = agGridApi;
    this.agGridApi.paginationSetPageSize(this.currentPageSize);
    this.setPageInfo();
  }

  /**
   * Page sizes configuration based on user settings or default
   * @param pageSizes
   * @param pageStart
   */
  configurePageSettings(pageSizes?: number[], pageStart?: number): void {
    if (pageSizes) {
      this.pageSizes = pageSizes;
    }
    this.currentPageSize = this.pageSizes.find((size) => size === pageStart) || Math.min(...this.pageSizes);
  }

  /**
   *  Go to selected page
   */
  goTo(): void {
    this.agGridApi.paginationGoToPage(this.pageToGo - 1);
    this.notifyPagination();
  }
  /**
   *  Set page size on dropdown change
   * @param pageSize Selected page size
   */
  changePageSize(pageSize): void {
    this.agGridApi.paginationSetPageSize(pageSize);
    this.agGridApi.paginationGoToFirstPage();
    this.notifyPagination();

    this.pageSizeChange.emit(pageSize);
  }
  /**
   *  Arrow buttons actions
   */
  triggerArrowSetting(settingAction): void {
    switch (settingAction) {
      case this.SKIP_PREVIOUS:
        this.agGridApi.paginationGoToFirstPage();
        break;
      case this.NAVIGATE_BEFORE:
        this.agGridApi.paginationGoToPreviousPage();
        break;
      case this.SKIP_NEXT:
        this.agGridApi.paginationGoToLastPage();
        break;
      case this.NAVIGATE_NEXT:
        this.agGridApi.paginationGoToNextPage();
        break;
      default:
        break;
    }
    this.notifyPagination();
  }
  /**
   *  Allow only numbers on keypress
   */
  goPress(event: KeyboardEvent): boolean {
    // Enter key press fires pagiantion goto
    if (event.key === this.KEY_ENTER_NAME) {
      this.goTo();
    }
    return !isNaN(Number(event.key)) && event.key !== null && event.key !== ' ';
  }
  /**
   * Check if option should be desabled in case the size in the option is higher than avialable rows
   * @param size
   */
  disableOption(size: number): boolean {
    const currentIndex = this.pageSizes.indexOf(size);
    return this.rowCount < size && this.rowCount <= this.pageSizes[currentIndex - 1];
  }
}
