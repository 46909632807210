import { ChangeDetectorRef, Component } from '@angular/core';
import { XpoConditionalFilterComponent } from '../conditional-filter.component';
import {
  XpoConditionBooleanFalseComponent,
  XpoConditionBooleanTrueComponent,
  XpoConditionEmptyComponent,
  XpoConditionIsNotEmptyComponent,
  XpoFilterCondition,
} from '../conditions';

@Component({
  templateUrl: '../conditional-filter.component.html',
  styleUrls: ['../conditional-filter.component.scss'],
})
export class XpoBooleanFilterComponent extends XpoConditionalFilterComponent {
  defaultConditions = [
    XpoConditionBooleanTrueComponent,
    XpoConditionBooleanFalseComponent,
    XpoConditionEmptyComponent,
    XpoConditionIsNotEmptyComponent,
  ];
}
