import {CustomerNoteForCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerNoteForCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerNoteForCd ) {
      this._values.push(CustomerNoteForCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerNoteForCd {

    for ( const obj in CustomerNoteForCd ) {
      if (CustomerNoteForCd[obj] === value){
        return CustomerNoteForCd[obj] as CustomerNoteForCd;
      }
    }
  }
}

const CustomerNoteForCdHelper = new CustomerNoteForCdHelperEnumHelperClass();
export default CustomerNoteForCdHelper;
