import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';

import { XpoDateFilterComponentBase } from '../models/date-filter-component-base';
import { XpoFilterCriteria } from '../models/filter-criteria-type';

@Component({
  selector: 'xpo-date-filter',
  templateUrl: 'date-filter.component.html',
  styleUrls: ['date-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-DateFilter', '[attr.id]': 'id' },
})
export class XpoDateFilterComponent extends XpoDateFilterComponentBase {
  selectedDate: Date;

  constructor(protected cd: ChangeDetectorRef) {
    super(cd);
    // Since the user is only selecting one date, default this filter to close the filter conainer on selection
    this.closeOnApply = true;
  }

  onCalendarChanged(value: Date): void {
    this.selectedDate = value;

    this.storeCriteria(value);
  }

  protected onCriteriaModified(fieldValue: object, criteria: XpoFilterCriteria): void {
    this.selectedDate = <Date>fieldValue || null;

    this.cd.markForCheck();
  }
}
