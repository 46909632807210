import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './services/login.service';

@Component({
  selector: 'xpo-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent {
  userForm: FormGroup;
  errorMessage = null;

  constructor(
    private loginService: LoginService,
    private dialogRef: MatDialogRef<LoginComponent>,
    private fb: FormBuilder,
  ) {
    this.createForm();
  }

  /** closes the dialog */
  close() {
    this.dialogRef.close('canceled');
  }

  onSubmit() {
    this.errorMessage = null;
    this.loginService
      .loadTokensFromUser(this.userForm.get('username').value, this.userForm.get('password').value)
      .subscribe(
        data => {
          this.dialogRef.close(data);
        },
        error => {
          if (error.error.error_description && error.error.error) {
            this.errorMessage = `${error.error.error_description}`;
            this.errorMessage = this.errorMessage.replace('@carbon.super', '');
          } else {
            this.errorMessage = error.error;
          }
        },
      );
  }

  private createForm() {
    this.userForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
}
