import { Directive } from '@angular/core';

// Search bar container
@Directive({
  selector: 'xpo-header-actions-search, xpoHeaderActionsSearch ',
  host: {
    class: 'xpo-Header-actions-search',
  },
})
export class XpoHeaderActionsSearch {}
