import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Optional,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  XpoBoardConsumer,
  XpoBoardDataSourceResolver,
  XpoBoardStateSources,
  XpoGridBoardState,
  XPO_GRID_BOARD_DEFAULT_ROW_HEIGHT,
} from '../../../../../models/index';
import { XpoGridDisplayDensityOptions, XpoGridRowHeightOptions } from '../grid-settings-default-buttons';

@Component({
  selector: 'xpo-settings-button-row-height',
  templateUrl: './row-height-settings-default-buttons.component.html',
  styleUrls: ['./row-height-settings-default-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-GridToolbar-settings-button-row-height',
    'data-cy': 'settings-buttons-row-height',
  },
})
export class XpoSettingsRowHeightComponent extends XpoBoardConsumer {
  rowHeightOptions: XpoGridRowHeightOptions[] = [];
  selectedRowHeight: number;
  defaultRowHeight: number;
  isDefault = true;

  @Output()
  onRowHeightChange = new EventEmitter<number>();

  constructor(
    boardDataSourceResolver: XpoBoardDataSourceResolver,
    cd: ChangeDetectorRef,
    @Inject(XPO_GRID_BOARD_DEFAULT_ROW_HEIGHT) @Optional() defaultRowHeight: number
  ) {
    super(boardDataSourceResolver, cd);
    this.defaultRowHeight = defaultRowHeight || 40;
    this.selectedRowHeight = this.defaultRowHeight;
    this.rowHeightOptions = [
      { name: XpoGridDisplayDensityOptions.Default, value: this.defaultRowHeight },
      { name: XpoGridDisplayDensityOptions.Compact, value: this.defaultRowHeight - 8 },
      { name: XpoGridDisplayDensityOptions.Comfortable, value: this.defaultRowHeight + 8 },
    ];
    this.changeRowHeight(this.selectedRowHeight);
  }

  updateSelectedRowHeight(value: number): void {
    this.selectedRowHeight = value;
    const newState: XpoGridBoardState = {
      source: XpoBoardStateSources.GridSettingsUpdate,
      rowHeight: this.selectedRowHeight,
    };
    this.stateChange$.next(newState);
  }

  protected onStateChange(state: XpoGridBoardState): void {
    if (state.changes.includes('rowHeight') && state.rowHeight) {
      this.changeRowHeight(state.rowHeight);
    }
  }

  private changeRowHeight(newHeight: number): void {
    this.selectedRowHeight = newHeight;
    this.onRowHeightChange.emit(this.selectedRowHeight);
  }
}
