import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { GetOdsShipmentResp } from '@xpo-ltl/sdk-shipmentods';
import { get as _get, nth as _nth } from 'lodash';
import { Observable } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '../../classes';
import { XpoLtlShipmentDetailsTabs } from '../shipment-details-tabs.enum';
import { ShipmentReferenceService } from '../shipment-reference.service';

export interface XpoLtlShipmentDetailsTabConfig {
  id: XpoLtlShipmentDetailsTabs;
  label?: string;
  title?: string;
}

export interface XpoLtlShipmentDetailsConfig {
  tabs?: XpoLtlShipmentDetailsTabConfig[];
  selectedTab?: XpoLtlShipmentDetailsTabs; // selected tab on initialization
  headerComponent?: any; // displayed above tabs if provided
  tabInfoComponent?: any; // displayed right-aligned on the tab row
  hideCharges?: boolean; // true to hide charges and Payment Summary
  showShipmentSelector?: boolean; // true to show shipment selector control (for multiple shipments only!)
  searchable?: boolean; // true to allow searching for a PRO and adding it to the selection list
  moveable?: boolean; // true to make the dialog non-modal and moveable
}

const defaultShipmentDetailsConfig: XpoLtlShipmentDetailsConfig = {
  tabs: [
    { id: XpoLtlShipmentDetailsTabs.Details, label: 'Details', title: 'Shipment Details' },
    { id: XpoLtlShipmentDetailsTabs.History, label: 'History', title: 'Shipment History' },
    { id: XpoLtlShipmentDetailsTabs.Charges, label: 'Charges', title: 'Freight Charges' },
  ],
};

@Component({
  selector: 'xpo-ltl-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ShipmentReferenceService],
})
export class XpoLtlShipmentDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  set shipment(value: GetOdsShipmentResp) {
    this.shipmentReferenceService.shipment = value;
  }

  get shipment$(): Observable<GetOdsShipmentResp> {
    return this.shipmentReferenceService.shipment$;
  }

  private _config: XpoLtlShipmentDetailsConfig = defaultShipmentDetailsConfig;
  @ViewChild('headerContainer', { read: ViewContainerRef }) headerContainerRef: ViewContainerRef;
  @ViewChild('tabGroup', { read: ViewContainerRef }) tabInfoContainerRef: ViewContainerRef;

  @Input()
  get config(): XpoLtlShipmentDetailsConfig {
    return this._config;
  }
  set config(value: XpoLtlShipmentDetailsConfig) {
    this._config = {
      ...defaultShipmentDetailsConfig,
      ...value,
    };

    // go through the tabs and, if any label/title is missing, add it
    this._config.tabs.forEach((tab: XpoLtlShipmentDetailsTabConfig) => {
      if (!tab.label) {
        tab.label = _get(
          defaultShipmentDetailsConfig.tabs.find((t) => t.id === tab.id),
          'label',
          tab.id
        );
      }

      if (!tab.title) {
        tab.title = _get(
          defaultShipmentDetailsConfig.tabs.find((t) => t.id === tab.id),
          'title',
          tab.id
        );
      }
    });

    this._hasFreightChargesTab = !!this._config.tabs.find((t) => t.id === XpoLtlShipmentDetailsTabs.Charges);
  }

  @Output() tabChange = new EventEmitter<XpoLtlShipmentDetailsTabConfig>();

  private _selectedTab = 0;
  get selectedTab() {
    return this._selectedTab;
  }
  set selectedTab(value: number) {
    this._selectedTab = value;
    this.tabChange.emit(_nth(this.config.tabs, this.selectedTab));
  }

  private _hasFreightChargesTab: boolean = true;
  get hasFreightChargesTab() {
    return this._hasFreightChargesTab;
  }

  get hideCharges() {
    return this.config.hideCharges;
  }

  private unsubscriber = new Unsubscriber();

  readonly XpoLtlShipmentDetailsTabs = XpoLtlShipmentDetailsTabs;

  constructor(
    private shipmentReferenceService: ShipmentReferenceService,
    private resolver: ComponentFactoryResolver,
    private renderer: Renderer2
  ) {
    console.warn(
      'Deprecation Warning: XpoLtlShipmentDetailsComponent has been deprecated. Please use @xpo-ltl/ngx-ltl-shipment-details library'
    );
  }

  ngOnInit() {
    this.selectedTab = this.config.tabs.findIndex((tab) => tab.id === this.config.selectedTab) || 0;
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  ngAfterViewInit() {
    // TODO - this feels like a hack.  Should be a better way to refactor so we don't need this dance
    this.shipment$
      .pipe(
        delay(1), // delay here to allow UI time to render the tabGroup
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((shipment) => {
        if (shipment) {
          this.injectComponents();
          this.unsubscriber.complete();
        }
      });
  }

  private injectComponents() {
    if (this.config.headerComponent && this.headerContainerRef) {
      this.headerContainerRef.clear();
      const factory = this.resolver.resolveComponentFactory(this.config.headerComponent);
      this.headerContainerRef.createComponent(factory);
    }

    if (this.config.tabInfoComponent && this.tabInfoContainerRef) {
      const factory = this.resolver.resolveComponentFactory(this.config.tabInfoComponent);
      const compRef = this.tabInfoContainerRef.createComponent(factory);

      const matHeaderElem = this.tabInfoContainerRef.element.nativeElement.querySelector('.mat-tab-header');
      const compEl = compRef.location.nativeElement;
      this.renderer.appendChild(matHeaderElem, compEl);
    }
  }

  openTab(tabId: XpoLtlShipmentDetailsTabs) {
    const tabIndex = this.config.tabs.findIndex((tab) => tab.id === tabId);
    if (tabIndex >= 0) {
      this.selectedTab = tabIndex;
    }
  }
}
