import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper } from '@angular/cdk/stepper';
import { AfterContentChecked, ChangeDetectorRef, Component } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { XpoStepComponent } from './step/step.component';

@Component({
  selector: 'xpo-horizontal-stepper',
  templateUrl: 'horizontal-stepper.component.html',
  styleUrls: ['horizontal-stepper.component.scss'],
  host: {
    class: 'xpo-HorizontalStepper',
  },
  providers: [
    { provide: MatStepper, useExisting: XpoHorizontalStepperComponent },
    { provide: CdkStepper, useExisting: XpoHorizontalStepperComponent },
  ],
})
export class XpoHorizontalStepperComponent extends MatStepper implements AfterContentChecked {
  constructor(private dir: Directionality, private cd: ChangeDetectorRef) {
    super(dir, cd);
  }

  private hasInvalidStep: boolean = false;

  ngAfterContentChecked(): void {
    this.setHasInvalidStep();
  }

  getHasInvalidStep(): boolean {
    return this.hasInvalidStep;
  }

  private setHasInvalidStep(): void {
    // check if steps are already initialized
    if (!this.steps) {
      return;
    }
    this.hasInvalidStep = this.steps.find((step: XpoStepComponent) => !step.isValid()) ? true : false;
  }
}
