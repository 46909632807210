import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { XpoPopoverModule } from '../popover/index';
import { XpoMessagingPopover } from './messaging-popover';

@NgModule({
  imports: [CommonModule, XpoPopoverModule, MatButtonModule, MatIconModule],
  declarations: [XpoMessagingPopover],
  exports: [XpoMessagingPopover],
})
export class XpoMessagingPopoverModule {
  constructor() {}
}
