import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';

import { XpoAdvancedSelectPanelComponent } from './advanced-select-panel.component';
import { XpoAdvancedSelectTreeNodeComponent } from './advanced-select-tree-node/index';

@NgModule({
  declarations: [XpoAdvancedSelectPanelComponent, XpoAdvancedSelectTreeNodeComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatRadioModule,
  ],
  exports: [XpoAdvancedSelectPanelComponent, XpoAdvancedSelectTreeNodeComponent],
})
export class XpoAdvancedSelectModule {}
