import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { configAppSettings, ConfigManagerService } from './config-manager.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {provide: APP_INITIALIZER, useFactory: configAppSettings, deps: [ConfigManagerService], multi: true},
  ],
  exports: [],
  declarations: []
})
export class ConfigManagerModule {
}
