import { Pipe, PipeTransform } from '@angular/core';
import { MovementExceptionTypeCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'movementExceptionType',
})
export class MovementExceptionTypePipe implements PipeTransform {
  transform(value: MovementExceptionTypeCd): string {
    switch (value) {
      case MovementExceptionTypeCd.AD_HOC:
        return 'Other';
      case MovementExceptionTypeCd.DAMAGED:
        return 'Damaged';
      case MovementExceptionTypeCd.DAMAGED_BUT_ACCEPTED:
        return 'Damaged But Accepted';
      case MovementExceptionTypeCd.EXCP_NOTED:
        return 'Exception Noted';
      case MovementExceptionTypeCd.OVER:
        return 'Over';
      case MovementExceptionTypeCd.OVER_BUT_ACCEPTED:
        return 'Over But Accepted';
      case MovementExceptionTypeCd.REFUSED:
        return 'Refused';
      case MovementExceptionTypeCd.SHORT:
        return 'Short';
      case MovementExceptionTypeCd.UNDELIVERABLE:
        return 'Undeliverable';
      default:
        return value;
    }
  }
}
