
export enum BeaconStatusCd {
	ACKNOWLEDGED = 'Acknowledged',
	APPROVED = 'Approved',
	CANCELLED = 'Cancelled',
	CLOSED = 'Closed',
	DECLINED = 'Declined',
	EXPIRED = 'Expired',
	NEW = 'New',
	RESOLVED = 'Resolved',
	SCHEDULED = 'Scheduled',
	SENT = 'Sent',
	UNDER_REVIEW = 'UnderReview'}

