import { XpoDateRangeFilterCriteria } from './date-range-filter-criteria';
import { XpoDateRangeFilterHelper } from './date-range-filter-helper';
import { XpoDateRangeFilterTab } from './date-range-filter-tab';
import { XpoDateRangeGroupByIntervalType } from './date-range-interval';
import { XpoDateRangeIntervalBase } from './date-range-interval-base';
import { XpoQuickDate } from './date-range-quick-date.enum';
import { XpoQuickFilter } from './date-range-quick-filter';

export class XpoDateRangeQuarterly extends XpoDateRangeIntervalBase {
  get groupByInterval(): XpoDateRangeGroupByIntervalType {
    return 'quarter';
  }

  get label(): string {
    return 'Quarterly';
  }

  getQuickFilterOptions(): XpoQuickFilter[] {
    return [
      { code: XpoQuickDate.LastYear, display: 'Last Year' },
      { code: XpoQuickDate.ThisYear, display: 'This Year' },
      { code: XpoQuickDate.NextYear, display: 'Next Year' },
    ];
  }

  getTabModel(): XpoDateRangeFilterTab {
    return {
      pos: this.pos,
      quickFilterOptions: this.getQuickFilterOptions(),
      groupByInterval: this.groupByInterval,
    };
  }

  setNext(nextInput: number): XpoDateRangeFilterCriteria {
    return XpoDateRangeFilterHelper.constructFilter(XpoQuickDate.TodayPlusQuarters, nextInput * 1);
  }

  setPrev(prevInput: number): XpoDateRangeFilterCriteria {
    return XpoDateRangeFilterHelper.constructFilter(XpoQuickDate.TodayMinusQuarters, prevInput * 1);
  }
}
