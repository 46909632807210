import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, OnInit, AfterContentInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { XpoFacetFilterService } from '../facet-filter.service';
import { XpoFacetFilterSection } from '../filter-section/filter-section.component';
import { XpoFacetFilterControlChangeEvent, XpoFacetFilterCriteriaChangeEvent } from '../share/model/control-events.interface';
import { XpoFacetFilterCombiner, XpoFacetFilterModelCriteria } from '../share/model/filter-criteria.interface';
import { getUniqueFilterId } from '../share/utils/general';

@Component({
  selector: 'xpo-facet-filter',
  templateUrl: './filter-base.component.html',
  styleUrls: ['./filter-base.component.scss'],
})
export class XpoFacetFilterComponent implements OnInit, OnDestroy, AfterContentInit {
  @ContentChildren(XpoFacetFilterSection) filterSections: QueryList<XpoFacetFilterSection>;

  @Output()
  toggleMenu: EventEmitter<{ open: boolean }> = new EventEmitter<{ open: boolean }>();

  @Output()
  valueChanged: EventEmitter<XpoFacetFilterCriteriaChangeEvent> = new EventEmitter<XpoFacetFilterCriteriaChangeEvent>();

  @Input()
  facetContracted: boolean = true;

  @Input()
  facetId: string;

  @Input()
  combiner: XpoFacetFilterCombiner = XpoFacetFilterCombiner.AND;

  private controlSubscriptions: Subscription[] = [];

  constructor(private facetService: XpoFacetFilterService) {}

  ngOnInit(): void {
    if (!this.facetId) {
      this.facetId = getUniqueFilterId();
    }
  }

  get filterCriteria(): XpoFacetFilterModelCriteria[] {
    return this.facetService.getFilterCriteria();
  }

  ngAfterContentInit(): void {
    this.filterSections.forEach((section) => {
      const subscription = section.valueChanged.subscribe((event: XpoFacetFilterControlChangeEvent) => {
        const param = event.controlCriteria;
        this.facetService.updateFilterCriteria(param);

        this.valueChanged.emit({
          combiner: this.combiner,
          filterCriteria: this.facetService.getFilterCriteria(),
        });
      });
      this.controlSubscriptions.push(subscription);
      section.configComponent();
    });
  }

  ngOnDestroy(): void {
    if (this.controlSubscriptions.length) {
      this.controlSubscriptions.forEach((item) => item.unsubscribe());
    }
  }

  toggleMenuFacet(event, onlyExpand: boolean = false): void {
    if (onlyExpand && !this.facetContracted) {
      return;
    }
    this.facetContracted = !this.facetContracted;
    this.toggleMenu.emit({ open: !this.facetContracted });
    event.stopPropagation();
  }
}
