import { Component, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';

/* Services */
import { ModuleGroupManagementService } from '../../services/module-group-management.service';

/* Models */
import { StepGridItem } from '../../models/step-grid-item.model';

/* Components */
import { ModuleGroupMgmtExecutionStepComponent } from './step/module-group-mgmt-execution-step.component';

/* Rxjs */
import { ModuleGroupModuleVersion } from '@xpo-ltl/sdk-moduleexecutor';
import { Subscription } from 'rxjs';

@Component({
  selector: 'module-group-mgmt-execution-steps',
  templateUrl: './module-group-mgmt-execution-steps.component.html',
  styleUrls: ['./module-group-mgmt-execution-steps.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ModuleGroupMgmtExecutionSteps',
  },
})
export class ModuleGroupMgmtExecutionStepsComponent implements OnInit, OnDestroy {
  @ViewChildren(ModuleGroupMgmtExecutionStepComponent) stepComponents: QueryList<any>;

  steps: StepGridItem[];

  /* Subscriptions */
  subscriptions: Subscription;

  constructor(private moduleGroupManagementService: ModuleGroupManagementService) {
    this.steps = this.moduleGroupManagementService.getSteps();
    this.subscriptions = new Subscription();
  }

  ngOnInit() {
    const onStepsChangeSubscription = this.moduleGroupManagementService.onStepsChange().subscribe((steps) => {
      this.steps = steps;
    });
    this.subscriptions.add(onStepsChangeSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  addRowsByStepId(stepId: number, rows: ModuleGroupModuleVersion[]): void {
    const step: ModuleGroupMgmtExecutionStepComponent = this.stepComponents.find(
      (stepComponent) => stepComponent.id === stepId
    );
    if (step) {
      step.addRows(rows, false);
    }
  }
}
