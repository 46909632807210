import {PickupShipmentStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class PickupShipmentStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PickupShipmentStatusCd ) {
      this._values.push(PickupShipmentStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PickupShipmentStatusCd {

    for ( const obj in PickupShipmentStatusCd ) {
      if (PickupShipmentStatusCd[obj] === value){
        return PickupShipmentStatusCd[obj] as PickupShipmentStatusCd;
      }
    }
  }
}

const PickupShipmentStatusCdHelper = new PickupShipmentStatusCdHelperEnumHelperClass();
export default PickupShipmentStatusCdHelper;
