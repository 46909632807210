import { DateUtils } from '@xpo-ltl/ngx-ltl-core';

import { XpoDateRangeFilterCriteria } from './date-range-filter-criteria';
import { XpoQuickDate } from './date-range-quick-date.enum';

export class XpoDateRangeFilterHelper {
  static constructFilter(filterKey: XpoQuickDate, offset: number = null): XpoDateRangeFilterCriteria {
    let filterValue: XpoDateRangeFilterCriteria;

    switch (filterKey) {
      case XpoQuickDate.TodayMinusMinutes:
        filterValue = {
          offset: offset,
          min: this.minutes(offset * -1),
          max: DateUtils.today(),
          selected: XpoQuickDate.TodayMinusMinutes,
          intervalType: 'minute',
        };
        break;
      case XpoQuickDate.TodayPlusMinutes:
        filterValue = {
          offset: offset,
          min: DateUtils.today(),
          max: this.minutes(offset),
          selected: XpoQuickDate.TodayPlusMinutes,
          intervalType: 'minute',
        };
        break;
      case XpoQuickDate.TodayMinusHours:
        filterValue = {
          offset: offset,
          min: DateUtils.fromNow(offset * -1),
          max: DateUtils.today(),
          selected: XpoQuickDate.TodayMinusHours,
          intervalType: 'hour',
        };
        break;
      case XpoQuickDate.TodayPlusHours:
        filterValue = {
          offset: offset,
          min: DateUtils.today(),
          max: DateUtils.fromNow(offset),
          selected: XpoQuickDate.TodayPlusHours,
          intervalType: 'hour',
        };
        break;
      case XpoQuickDate.Today:
        filterValue = {
          min: DateUtils.today(),
          max: DateUtils.today(),
          selected: XpoQuickDate.Today,
          intervalType: 'hour',
        };
        break;
      case XpoQuickDate.Tomorrow:
        filterValue = {
          min: DateUtils.tomorrow(),
          max: DateUtils.tomorrow(),
          selected: XpoQuickDate.Tomorrow,
          intervalType: 'hour',
        };
        break;
      case XpoQuickDate.Yesterday:
        filterValue = {
          min: DateUtils.yesterday(),
          max: DateUtils.yesterday(),
          selected: XpoQuickDate.Yesterday,
          intervalType: 'hour',
        };

        break;
      case XpoQuickDate.TodayMinusDays:
        filterValue = {
          offset: offset,
          min: DateUtils.fromToday(offset * -1 + 1), // + 1 to exclude the last day
          max: DateUtils.today(),
          selected: XpoQuickDate.TodayMinusDays,
          intervalType: 'day',
        };
        break;
      case XpoQuickDate.TodayPlusDays:
        filterValue = {
          offset: offset,
          min: DateUtils.today(),
          max: DateUtils.fromToday(offset - 1), // - 1 to exclude the last day
          selected: XpoQuickDate.TodayPlusDays,
          intervalType: 'day',
        };
        break;
      case XpoQuickDate.NextWeek:
        filterValue = {
          min: DateUtils.firstDayOfWeek(1),
          max: DateUtils.lastDayOfWeek(1),
          selected: XpoQuickDate.NextWeek,
          intervalType: 'day',
        };
        break;
      case XpoQuickDate.ThisWeek:
        filterValue = {
          min: DateUtils.firstDayOfWeek(),
          max: DateUtils.lastDayOfWeek(),
          selected: XpoQuickDate.ThisWeek,
          intervalType: 'day',
        };
        break;
      case XpoQuickDate.LastWeek:
        filterValue = {
          min: DateUtils.firstDayOfWeek(-1),
          max: DateUtils.lastDayOfWeek(-1),
          selected: XpoQuickDate.LastWeek,
          intervalType: 'day',
        };
        break;

      case XpoQuickDate.TodayPlusWeeks:
        filterValue = {
          offset: offset,
          min: DateUtils.firstDayOfWeek(1),
          max: DateUtils.lastDayOfWeek(offset),
          selected: XpoQuickDate.TodayPlusWeeks,
          intervalType: 'week',
        };
        break;
      case XpoQuickDate.TodayMinusWeeks:
        filterValue = {
          offset: offset,
          min: DateUtils.firstDayOfWeek(-offset),
          max: DateUtils.lastDayOfWeek(-1),
          selected: XpoQuickDate.TodayMinusWeeks,
          intervalType: 'week',
        };
        break;
      case XpoQuickDate.NextMonth:
        filterValue = {
          min: DateUtils.firstDayOfMonth(1),
          max: DateUtils.lastDayOfMonth(1),
          selected: XpoQuickDate.NextMonth,
          intervalType: 'week',
        };
        break;
      case XpoQuickDate.ThisMonth:
        filterValue = {
          min: DateUtils.firstDayOfMonth(),
          max: DateUtils.lastDayOfMonth(),
          selected: XpoQuickDate.ThisMonth,
          intervalType: 'week',
        };
        break;
      case XpoQuickDate.LastMonth:
        filterValue = {
          min: DateUtils.firstDayOfMonth(-1),
          max: DateUtils.lastDayOfMonth(-1),
          selected: XpoQuickDate.LastMonth,
          intervalType: 'week',
        };
        break;

      case XpoQuickDate.TodayPlusMonths:
        filterValue = {
          offset: offset,
          min: DateUtils.firstDayOfMonth(1),
          max: DateUtils.lastDayOfMonth(offset),
          selected: XpoQuickDate.TodayPlusMonths,
          intervalType: 'month',
        };
        break;
      case XpoQuickDate.TodayMinusMonths:
        filterValue = {
          offset: offset,
          min: DateUtils.firstDayOfMonth(-offset),
          max: DateUtils.lastDayOfMonth(-1),
          selected: XpoQuickDate.TodayMinusMonths,
          intervalType: 'month',
        };
        break;
      case XpoQuickDate.NextQuarter:
        filterValue = {
          min: DateUtils.firstDayOfQuarter(1),
          max: DateUtils.lastDayOfQuarter(1),
          selected: XpoQuickDate.NextQuarter,
          intervalType: 'month',
        };
        break;
      case XpoQuickDate.ThisQuarter:
        filterValue = {
          min: DateUtils.firstDayOfQuarter(),
          max: DateUtils.lastDayOfQuarter(),
          selected: XpoQuickDate.ThisQuarter,
          intervalType: 'month',
        };
        break;
      case XpoQuickDate.LastQuarter:
        filterValue = {
          min: DateUtils.firstDayOfQuarter(-1),
          max: DateUtils.lastDayOfQuarter(-1),
          selected: XpoQuickDate.LastQuarter,
          intervalType: 'month',
        };
        break;

      case XpoQuickDate.TodayPlusQuarters:
        filterValue = {
          offset: offset,
          min: DateUtils.firstDayOfQuarter(1),
          max: DateUtils.lastDayOfQuarter(offset),
          selected: XpoQuickDate.TodayPlusQuarters,
          intervalType: 'quarter',
        };
        break;
      case XpoQuickDate.TodayMinusQuarters:
        filterValue = {
          offset: offset,
          min: DateUtils.firstDayOfQuarter(-offset),
          max: DateUtils.lastDayOfQuarter(-1),
          selected: XpoQuickDate.TodayMinusQuarters,
          intervalType: 'quarter',
        };
        break;
      case XpoQuickDate.NextYear:
        filterValue = {
          min: DateUtils.firstDayOfYear(1),
          max: DateUtils.lastDayOfYear(1),
          selected: XpoQuickDate.NextYear,
          intervalType: 'quarter',
        };
        break;
      case XpoQuickDate.ThisYear:
        filterValue = {
          min: DateUtils.firstDayOfYear(),
          max: DateUtils.lastDayOfYear(),
          selected: XpoQuickDate.ThisYear,
          intervalType: 'quarter',
        };
        break;
      case XpoQuickDate.LastYear:
        filterValue = {
          min: DateUtils.firstDayOfYear(-1),
          max: DateUtils.lastDayOfYear(-1),
          selected: XpoQuickDate.LastYear,
          intervalType: 'quarter',
        };
        break;

      case XpoQuickDate.TodayPlusYears:
        filterValue = {
          offset: offset,
          min: DateUtils.firstDayOfYear(1),
          max: DateUtils.lastDayOfYear(offset),
          selected: XpoQuickDate.TodayPlusYears,
          intervalType: 'year',
        };
        break;
      case XpoQuickDate.TodayMinusYears:
        filterValue = {
          offset: offset,
          min: DateUtils.firstDayOfYear(-offset),
          max: DateUtils.lastDayOfYear(-1),
          selected: XpoQuickDate.TodayMinusYears,
          intervalType: 'year',
        };
        break;

      default:
        filterValue = { selected: filterKey, offset: offset };
        break;
    }

    // Zeroing out mins and seconds so comparing dates is better
    if (filterValue.min) {
      filterValue.min.setSeconds(0, 0);
    }

    if (filterValue.max) {
      filterValue.max.setSeconds(0, 0);
    }

    return filterValue;
  }

  private static minutes(offset: number): Date {
    const date = DateUtils.today();
    date.setMinutes(date.getMinutes() + offset);
    return date;
  }
}
