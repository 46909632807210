import { InjectionToken } from '@angular/core';

import { XpoBrowserRestrictions, XpoBrowserType } from './models/index';

export const xpoDefaultBrowserRestrictions: XpoBrowserRestrictions = {
  blocked: [{ browser: XpoBrowserType.InternetExplorer }],
  suggestions: [{ browser: XpoBrowserType.Chrome }],
};

export const XPO_BROWSER_RESTRICTIONS = new InjectionToken<XpoBrowserRestrictions>('xpo-browser-restrictions');
