import { Component, Input, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { DocumentCdt, GetDocumentResp } from '@xpo-ltl/sdk-documentmanagement';
import { endsWith as _endsWith, size as _size } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { decode } from 'typescript-base64-arraybuffer';
import { ConfigManagerProperties } from '../../config-manager-properties.enum';
import { XpoLtlWindowService } from '../../services/window/window.service';
import { XpoLtlDocumentService } from '../document.service';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '../../classes';
import { DocumentDetails } from '../models/document-details.class';
import { DisplayState } from '../enums/display-state.enum';

/**
 * Display a single DMS Document
 */
@Component({
  selector: 'xpo-ltl-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class XpoLtlDocumentViewerComponent implements OnInit, OnDestroy {
  @Input()
  set document(document: DocumentCdt) {
    this.documentSubject.next(document);
  }
  get document(): DocumentCdt {
    return this.documentSubject.value;
  }

  @Input() showPrint: boolean = true;

  private documentSubject = new BehaviorSubject<DocumentCdt>(undefined);
  readonly document$ = this.documentSubject.asObservable();

  readonly DisplayState = DisplayState;

  private stateSubject = new BehaviorSubject<DisplayState>(DisplayState.loading);
  readonly state$ = this.stateSubject.asObservable();
  private set state(value: DisplayState) {
    this.stateSubject.next(value);
  }

  documentDetails: DocumentDetails;
  errorMessage: string = undefined;

  private unsubscriber: Unsubscriber = new Unsubscriber();

  constructor(
    private documentService: XpoLtlDocumentService,
    private windowService: XpoLtlWindowService,
    private configManagerService: ConfigManagerService
  ) {}

  ngOnInit() {
    this.initWatchers();
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private initWatchers(): void {
    this.document$.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
      this.loadDocument();
    });
  }

  private decodeData(data: string): Uint8Array {
    return decode(data);
  }

  loadDocument() {
    this.state = DisplayState.loading;
    this.documentDetails = new DocumentDetails();

    // use the provided corpCode, or if none, use the one from the config
    let corpCode = this.document.corpCode;
    if (_size(corpCode) === 0) {
      corpCode = this.configManagerService.getSetting<string>(ConfigManagerProperties.imageCorpCode);
    }

    this.documentService.getDocument(this.document.timestamp, this.document.docClass, corpCode).subscribe(
      (result: GetDocumentResp) => {
        try {
          this.documentDetails.document = this.decodeData(result.documentData);
          this.documentDetails.filename = result.fileName;
          this.documentDetails.contentType = result.contentType;

          if (!this.documentDetails.document) {
            // failed to decode document, so can't show anything
            throw new Error();
          } else if (!_endsWith(this.documentDetails.contentType, 'pdf')) {
            this.state = DisplayState.unsupportedFormat;
          } else {
            this.state = DisplayState.showPdf;
          }
        } catch {
          this.state = DisplayState.error;
          this.errorMessage = 'There Was A Problem Loading This Document';
        }
      },
      (error) => {
        this.state = DisplayState.error;
        this.errorMessage = 'Document Not Found';
      }
    );
  }

  handleDownloadClicked() {
    this.windowService.generateDownloadFile(
      this.document.docClass,
      this.documentDetails.document,
      this.documentDetails.filename
    );
  }
}
