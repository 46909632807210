import {WeekDayCd} from '../';
import {EnumHelper} from './enum-helper';

export class WeekDayCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in WeekDayCd ) {
      this._values.push(WeekDayCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): WeekDayCd {

    for ( const obj in WeekDayCd ) {
      if (WeekDayCd[obj] === value){
        return WeekDayCd[obj] as WeekDayCd;
      }
    }
  }
}

const WeekDayCdHelper = new WeekDayCdHelperEnumHelperClass();
export default WeekDayCdHelper;
