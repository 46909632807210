import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XpoFooterComponent } from './footer.component';
import { XpoFooterStickyDirective } from './footer.directive';

@NgModule({
  declarations: [XpoFooterComponent, XpoFooterStickyDirective],
  imports: [CommonModule],
  exports: [XpoFooterComponent, XpoFooterStickyDirective],
})
export class XpoFooterModule {}
