import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FeedbackConfigInterface } from './feedback-config';
import { FeedbackFormFields } from './feedback-form-fields.enum';

@Component({
  selector: 'xpo-utils-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class XpoFeedback implements OnInit {
  feedbackFormFields = FeedbackFormFields;
  userFeedbackFormGroup: FormGroup;
  sendingEmail = false;

  private feedbackConfig: FeedbackConfigInterface;

  constructor(
  @Inject(MAT_DIALOG_DATA) config: FeedbackConfigInterface,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<XpoFeedback>
  ) {
    this.feedbackConfig = this.mapConfig(config);
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  hasError(error: string, field: FeedbackFormFields): boolean {
    return this.userFeedbackFormGroup.get(field).hasError(error) && !this.userFeedbackFormGroup.get(field).untouched;
  }

  cancelClicked(): void {
    this.dialogRef.close(false);
  }

  sendClicked(): void {
    const formValue = this.userFeedbackFormGroup.getRawValue();
    const message = formValue[FeedbackFormFields.MESSAGE];

    if (this.feedbackConfig.onSendCallback) {
      this.sendingEmail = true;
      this.feedbackConfig.onSendCallback(message).subscribe((result) => {
        if (result) {
          this.dialogRef.close();
        }
        this.sendingEmail = false;
      });
    } else {
      this.dialogRef.close(message);
    }
  }

  private mapConfig(config: FeedbackConfigInterface): FeedbackConfigInterface {
    return {
      disableFromNameInput: config.disableFromNameInput || false,
      disableSubjectInput: config.disableSubjectInput || false,
      fromEmail: config.fromEmail || '',
      fromName: config.fromName || config.fromEmail || '',
      invalidCharacterPattern: config.invalidCharacterPattern || '',
      message: config.message || '',
      onSendCallback: config.onSendCallback,
      subject: config.subject || '',
      title: config.title || 'Feedback',
    };
  }

  private initializeForm(): void {
    this.userFeedbackFormGroup = this.formBuilder.group({
      [FeedbackFormFields.USER]: new FormControl(
        {
          value: this.feedbackConfig.fromName,
          disabled: this.feedbackConfig.disableFromNameInput,
        },
        [Validators.required]
      ),
      [FeedbackFormFields.SUBJECT]: new FormControl(
        {
          value: this.feedbackConfig.subject,
          disabled: this.feedbackConfig.disableSubjectInput,
        },
        [Validators.required, Validators.pattern(this.feedbackConfig.invalidCharacterPattern)]
      ),
      [FeedbackFormFields.MESSAGE]: new FormControl(
        {
          value: this.feedbackConfig.message,
          disabled: false,
        },
        [Validators.required, Validators.pattern(this.feedbackConfig.invalidCharacterPattern)]
      ),
    });
  }
}
