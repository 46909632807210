import moment from 'moment';

/**
 * Takes a parsable time and creates a moment object with today's date at that time
 *
 * @param time A date time (typically HH:mm) with a parsable timestamp by moment.
 * Also supports seconds, milliseconds, and 12hr time with meridiem present
 */
export function getTodayAt(time: string): moment.Moment {
  let format: string;
  if (!!time) {
    const separatorCount = [...time].filter((char: string) => char === ':' || char === '.').length;
    const meridiemPresent = time.match('([AaPp].?[Mm].?)');

    // initialize to empty string
    format = '';

    // check if meridiem is present for hour format
    format += meridiemPresent ? 'hh' : 'HH';

    // add minutes
    if (separatorCount >= 1) {
      format += ':mm';
    }

    // if seconds present, add formatter
    if (separatorCount >= 2) {
      format += ':ss';
    }

    // if seconds milliseconds present, add formatter
    if (separatorCount >= 3) {
      format += '.SSS';
    }

    // if meridiem present, add meridiem formatter
    if (!!meridiemPresent) {
      format += ' A';
    }
  }

  return moment(time, format);
}

/**
 * Takes two military time strings and figures out which one comes first
 *
 * @param a string with the following moment equiv. format HH:mm
 * @param b string with the following moment equiv. format HH:mm
 *
 * @return Object keyed from, to with the smaller time in from and larger in to
 */
export function ordersMinMaxTime(a: string, b: string): { from: string; to: string } {
  if (
    Number.parseInt(a.substr(0, 2), 10) * 60 + Number.parseInt(a.substr(3, 2), 10) >=
    Number.parseInt(b.substr(0, 2), 10) * 60 + Number.parseInt(b.substr(3, 2), 10)
  ) {
    return { from: b, to: a };
  } else {
    return { from: a, to: b };
  }
}
