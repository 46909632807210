import {ShiftCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShiftCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShiftCd ) {
      this._values.push(ShiftCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShiftCd {

    for ( const obj in ShiftCd ) {
      if (ShiftCd[obj] === value){
        return ShiftCd[obj] as ShiftCd;
      }
    }
  }
}

const ShiftCdHelper = new ShiftCdHelperEnumHelperClass();
export default ShiftCdHelper;
