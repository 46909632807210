import {ClaimDecisionCategoryCd} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimDecisionCategoryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimDecisionCategoryCd ) {
      this._values.push(ClaimDecisionCategoryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimDecisionCategoryCd {

    for ( const obj in ClaimDecisionCategoryCd ) {
      if (ClaimDecisionCategoryCd[obj] === value){
        return ClaimDecisionCategoryCd[obj] as ClaimDecisionCategoryCd;
      }
    }
  }
}

const ClaimDecisionCategoryCdHelper = new ClaimDecisionCategoryCdHelperEnumHelperClass();
export default ClaimDecisionCategoryCdHelper;
