import {RebuttalExternalStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class RebuttalExternalStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RebuttalExternalStatusCd ) {
      this._values.push(RebuttalExternalStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RebuttalExternalStatusCd {

    for ( const obj in RebuttalExternalStatusCd ) {
      if (RebuttalExternalStatusCd[obj] === value){
        return RebuttalExternalStatusCd[obj] as RebuttalExternalStatusCd;
      }
    }
  }
}

const RebuttalExternalStatusCdHelper = new RebuttalExternalStatusCdHelperEnumHelperClass();
export default RebuttalExternalStatusCdHelper;
