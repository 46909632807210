import {RuleLogicalOperatorCd} from '../';
import {EnumHelper} from './enum-helper';

export class RuleLogicalOperatorCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RuleLogicalOperatorCd ) {
      this._values.push(RuleLogicalOperatorCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RuleLogicalOperatorCd {

    for ( const obj in RuleLogicalOperatorCd ) {
      if (RuleLogicalOperatorCd[obj] === value){
        return RuleLogicalOperatorCd[obj] as RuleLogicalOperatorCd;
      }
    }
  }
}

const RuleLogicalOperatorCdHelper = new RuleLogicalOperatorCdHelperEnumHelperClass();
export default RuleLogicalOperatorCdHelper;
