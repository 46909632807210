import { Directive } from '@angular/core';

/**
 * @deprecated
 */
@Directive({
  selector: '[xpo-no-borders-tabs]',
  host: {
    class: 'xpo-no-borders-tabs',
  },
})
export class XpoNoBorderTabs {
  constructor() {
    console.warn('[xpo-no-borders-tabs] is deprecated, please remove it from your board element');
  }
}
