import { XpoAgGridBoardColumn } from '@xpo-ltl/ngx-ltl-board-grid';

export const MODULE_LIBRARY_COLUMNS: XpoAgGridBoardColumn[] = [
  {
    colId: 'actions',
    sortable: false,
    minWidth: 50,
    maxWidth: 50,
    cellRenderer: 'xmeActionsCellRenderer',
    pinned: 'left',
    floatingFilter: false,
  },
  {
    field: 'moduleName',
    hide: true,
    rowGroup: true,
  },
  {
    headerName: 'Version',
    field: 'version',
    minWidth: 200,
  },
  {
    headerName: 'Created (PST)',
    field: 'auditInfo.createdTimestamp',
    cellRenderer: 'xmeDateTimeCellRenderer',
    minWidth: 200,
  },
  {
    headerName: 'Last Modified (PST)',
    field: 'auditInfo.updatedTimestamp',
    cellRenderer: 'xmeDateTimeCellRenderer',
    minWidth: 200,
  },
];
