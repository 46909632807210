import { ChangeDetectorRef, Component } from '@angular/core';
import { XpoConditionalFilterComponent } from '../conditional-filter.component';
import {
  XpoConditionDateAfterComponent,
  XpoConditionDateAfterSameComponent,
  XpoConditionDateBeforeComponent,
  XpoConditionDateBeforeSameComponent,
  XpoConditionDateBetweenComponent,
  XpoConditionDateEqualsComponent,
  XpoConditionDateIsComponent,
  XpoConditionDateNotBetweenComponent,
  XpoConditionEmptyComponent,
  XpoConditionIsNotEmptyComponent,
} from '../conditions';

@Component({
  templateUrl: '../conditional-filter.component.html',
  styleUrls: ['../conditional-filter.component.scss'],
})
export class XpoDateOnlyFilterComponent extends XpoConditionalFilterComponent {
  defaultConditions = [
    XpoConditionDateIsComponent,
    XpoConditionDateEqualsComponent,
    XpoConditionDateAfterComponent,
    XpoConditionDateAfterSameComponent,
    XpoConditionDateBeforeComponent,
    XpoConditionDateBeforeSameComponent,
    XpoConditionDateBetweenComponent,
    XpoConditionDateNotBetweenComponent,
    XpoConditionEmptyComponent,
    XpoConditionIsNotEmptyComponent,
  ];
}
