import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ConfigManagerProperties } from '../../../shared/enums/config-manager-properties.enum';
import { StatusEnum } from '../status-cell-renderer/status-cell-renderer.enum';

@Component({
  selector: 'module-groups-actions-cell-renderer',
  templateUrl: './actions-cell-renderer.component.html',
  styleUrls: ['./actions-cell-renderer.component.scss'],
  host: {
    class: 'ModuleGroupsActionsCellRenderer',
  },
})
export class ModuleGroupsActionsCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  isDisableLaunchButton: boolean;
  isLaunching: boolean;

  constructor(private router: Router, private configManagerService: ConfigManagerService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.isDisableLaunchButton = this.isRunningStatus(this.params);
    this.isLaunching = false;
  }

  refresh(): boolean {
    return false;
  }

  edit(): void {
    const routePath =
      this.configManagerService.getSetting(ConfigManagerProperties.moduleGroupManagementRoute) ||
      './module-group-management';
    this.router.navigate([routePath, this.params.data.moduleGroupInstId]);
  }

  copy(): void {
    if (this.params) {
      this.params.context.copyModule(this.params.node);
    }
  }

  delete(): void {
    if (this.params) {
      this.params.context.deleteModule(this.params.node);
    }
  }

  launch(): void {
    if (this.params) {
      this.params.context.launchModule(this.params.node);
      this.isLaunching = true;
    }
  }

  private isRunningStatus(params): boolean {
    if (params && params.data && params.data.runStatus) {
      const running = params.data.runStatus.toLowerCase() === StatusEnum.RUNNING;
      if (running) {
        this.isLaunching = false;
      }
      return running;
    }
    return false;
  }
}
