import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { XpoBoardViewConfig, XpoBoardViewDataStore } from '../../../board-views/models/index';
import { XpoBoardConsumer, XpoBoardDataSourceResolver, XpoBoardState, XpoBoardViewUtil } from '../../../models/index';
import { XpoBoardActionsVisibilityService, XpoBoardActionType } from '../board-actions-visibility.service';
import { XpoSaveViewDialog, XpoSaveViewDialogData, XpoSaveViewDialogPurpose } from '../save-view-dialog/index';

@Component({
  selector: 'xpo-save-view',
  templateUrl: './save-view.component.html',
  styleUrls: ['./save-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-SaveView' },
})
export class XpoSaveViewComponent extends XpoBoardConsumer {
  private currentState: XpoBoardState = { source: 'SAVE-VIEW-INIT' };

  isMenuDisabled: boolean = false;
  isSaveDisabled: boolean = false;
  isSaveAsDisabled: boolean = false;

  @Input()
  viewDataStore: XpoBoardViewDataStore;

  constructor(
    private dialog: MatDialog,
    private actionsVisibilityService: XpoBoardActionsVisibilityService,
    boardDataSourceResolver: XpoBoardDataSourceResolver,
    cd: ChangeDetectorRef
  ) {
    super(boardDataSourceResolver, cd);
  }

  saveCurrentView(): void {
    if (!this.currentState.viewLastSaved) {
      this.showDialog();
      return;
    }

    const currentView = XpoBoardViewUtil.findActiveView(this.currentState);

    this.saveView(currentView.toViewConfig(this.currentState), false)
      .pipe(take(1))
      .subscribe((newState) => {
        this.stateChange$.next(newState);
      });
  }

  showDialog(): void {
    const currentView = XpoBoardViewUtil.findActiveView(this.currentState);

    this.dialog
      .open<XpoSaveViewDialog, XpoSaveViewDialogData, XpoBoardState | null>(XpoSaveViewDialog, {
        minWidth: '500px',
        data: {
          state: this.currentState,
          purpose: XpoSaveViewDialogPurpose.Save,
          saveView: this.saveView.bind(this),
          view: currentView,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((newState) => {
        if (newState) {
          this.stateChange$.next(newState);
        }
      });
  }

  onSaveClick(): void {
    this.isMenuDisabled = true;
  }

  onMenuClosed(): void {
    this.isMenuDisabled = false;
  }

  protected onStateChange(state: XpoBoardState): void {
    // TODO: only update the disabled states when view change/view template change
    this.currentState = state;

    this.isSaveDisabled = this.actionsVisibilityService.isDisabled(XpoBoardActionType.Save, this.currentState);
    this.isSaveAsDisabled = this.actionsVisibilityService.isDisabled(XpoBoardActionType.SaveAs, this.currentState);
    this.isMenuDisabled = this.isSaveDisabled && this.isSaveAsDisabled;
  }

  private saveView(viewConfig: XpoBoardViewConfig, saveAs: boolean = false): Observable<XpoBoardState> {
    return this.viewDataStore.save(viewConfig).pipe(
      map((savedView) => {
        const newView = this.currentState.template.createView(savedView);
        const nextState = saveAs
          ? XpoBoardViewUtil.addView(newView, this.currentState, 'SAVE-VIEW-AS')
          : XpoBoardViewUtil.updateView(newView, this.currentState, 'SAVE-VIEW');
        return nextState;
      })
    );
  }
}
