import {ShipperPhoneCountryCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipperPhoneCountryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipperPhoneCountryCd ) {
      this._values.push(ShipperPhoneCountryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipperPhoneCountryCd {

    for ( const obj in ShipperPhoneCountryCd ) {
      if (ShipperPhoneCountryCd[obj] === value){
        return ShipperPhoneCountryCd[obj] as ShipperPhoneCountryCd;
      }
    }
  }
}

const ShipperPhoneCountryCdHelper = new ShipperPhoneCountryCdHelperEnumHelperClass();
export default ShipperPhoneCountryCdHelper;
