import {BatchFrameworkArgumentTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class BatchFrameworkArgumentTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BatchFrameworkArgumentTypeCd ) {
      this._values.push(BatchFrameworkArgumentTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BatchFrameworkArgumentTypeCd {

    for ( const obj in BatchFrameworkArgumentTypeCd ) {
      if (BatchFrameworkArgumentTypeCd[obj] === value){
        return BatchFrameworkArgumentTypeCd[obj] as BatchFrameworkArgumentTypeCd;
      }
    }
  }
}

const BatchFrameworkArgumentTypeCdHelper = new BatchFrameworkArgumentTypeCdHelperEnumHelperClass();
export default BatchFrameworkArgumentTypeCdHelper;
