import {GoalLevelCd} from '../';
import {EnumHelper} from './enum-helper';

export class GoalLevelCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in GoalLevelCd ) {
      this._values.push(GoalLevelCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): GoalLevelCd {

    for ( const obj in GoalLevelCd ) {
      if (GoalLevelCd[obj] === value){
        return GoalLevelCd[obj] as GoalLevelCd;
      }
    }
  }
}

const GoalLevelCdHelper = new GoalLevelCdHelperEnumHelperClass();
export default GoalLevelCdHelper;
