import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { XpoDynamicMenuBlockDirective } from './dynamic-menu-directive/dynamic-menu-block-btn.directive';
import { XpoDynamicMenuDirective } from './dynamic-menu-directive/dynamic-menu.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [XpoDynamicMenuDirective, XpoDynamicMenuBlockDirective],
  exports: [XpoDynamicMenuDirective, XpoDynamicMenuBlockDirective],
})
export class XpoUtilsModule {}
