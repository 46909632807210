import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: './dynamic-dag-script-dialog',
  templateUrl: './dynamic-dag-script-dialog-error.component.html',
  styleUrls: ['./dynamic-dag-script-dialog-error.component.scss'],
})
export class DynamicDAGScriptDialogErrorComponent {
  validateDynamicDagCodeList: any[];

  constructor(
    public dialog: MatDialogRef<DynamicDAGScriptDialogErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[]
  ) {
    this.validateDynamicDagCodeList = data || [];
  }

  close(): void {
    this.dialog.close();
  }
}
