import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ApiRequest, DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { ConditioningService } from '@xpo-ltl/common-services';
import { DmsDocumentRqst, DocType} from './classes';
import * as _ from 'lodash';
import { switchMap, take, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';


enum ConfigManagerProperties {
  Region = 'region',
  DmsApiEndpoint = 'dmsApiEndpoint',
  ImagingApiEndpoint = 'imagingApiEndpoint',
  ImageCorpCode = 'imageCorpCode',
  ApiUrl = 'apiUrl'
}

class DmsAccessToken {
  access_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
  expiresAt?: Date;
}

@Injectable({ providedIn: 'root' })
export class DmsService {

  private readonly dmsApiEndpoint: string;
  private readonly imagingApiEndpoint: string;
  private readonly apiUrl: string;
  private readonly corpCode: string;
  private dmsAccessToken: DmsAccessToken;

  constructor(private http: HttpClient,
              private dataManager: DataApiService,
              private config: ConfigManagerService,
              private conditioningService: ConditioningService,
              private notificationService: NotificationService) {
    this.dmsApiEndpoint = config.getSetting(ConfigManagerProperties.DmsApiEndpoint);
    this.imagingApiEndpoint = config.getSetting(ConfigManagerProperties.ImagingApiEndpoint);
    this.corpCode = config.getSetting<string>(ConfigManagerProperties.ImageCorpCode);
    this.apiUrl = config.getSetting(ConfigManagerProperties.ApiUrl);
  }
  private generateFullyQualifiedUrl(url: string = '') {
    return this.appendUrlParts(this.apiUrl, url);
  }
  private appendUrlParts(part1: string, part2: string): string {
    const conditionedPart1 = `${part1}${part1.endsWith('/') ? '' : '/'}`;
    const conditionedPart2 = `${part2.startsWith('/') ? part2.substring(1) : part2}`;
    return `${conditionedPart1}${conditionedPart2}`;
  }

  private authenticateDms(): Observable<DmsAccessToken> {
    const requestUri = `${this.dmsApiEndpoint}/oauth/dmstoken`;
    const request = new ApiRequest(requestUri);
    if (this.dmsAccessToken && !this.isAuthTokenExpired()) {
      return of(this.dmsAccessToken);
    }

    return this.dataManager.post(request).pipe(tap(response => {
      this.dmsAccessToken = response as DmsAccessToken;
      this.dmsAccessToken.expiresAt = new Date(Date.now() + (this.dmsAccessToken.expires_in * 1000));
    }));
  }
  private isAuthTokenExpired() {
    return new Date() > this.dmsAccessToken.expiresAt;
  }

  private getDmsDocument2(documentRequest: DmsDocumentRqst): Observable<ArrayBuffer> {
    return this.authenticateDms()
      .pipe(
        take(1),
        switchMap((token) => {
          const requestUri = this.generateFullyQualifiedUrl(this.appendUrlParts(this.dmsApiEndpoint, `document/${this.corpCode}/${documentRequest.docType}/${documentRequest.referenceNbr}`));
          return this.http.get(requestUri, {responseType: 'arraybuffer', headers: {DMSAuth: token.access_token}});
        }));
  }

  public getDmsDocument(documentRequest: DmsDocumentRqst): Observable<ArrayBuffer> {
    const mySubject = new Subject<ArrayBuffer>();

    if (documentRequest.docType === DocType.InvoicePdf) {
      this.getDmsDocument2(documentRequest).pipe(take(1)).subscribe(
        success => {
          mySubject.next(success);
        },
        error => {
          if (_.result(error, 'error.errorCode', undefined)) {
            mySubject.error(`${_.result(error, 'error.message', '')}`);
          } else {
            mySubject.error('No data found');
          }
        }
      );
    } else {
      let httpHeaders: HttpHeaders = new HttpHeaders();
      httpHeaders = httpHeaders.append('Content-Type', 'multipart/form-data');
      const url = this.appendUrlParts(
          this.imagingApiEndpoint,
          `shipments/${this.conditioningService.conditionProNumber(documentRequest.proNbr, 11)}/imaged-docs?imageFormat=${documentRequest.imageFormat}&imageType=${documentRequest.docType}&multiPartResp=true`);
      const apiRequest = new ApiRequest(url);
      // apiRequest.httpOptions = {observe: 'response', responseType: 'arraybuffer', headers: httpHeaders};
      apiRequest.httpOptions = { responseType: 'arraybuffer', headers: httpHeaders };
      apiRequest.dataOptions = ApiRequest.concealedCall;
      this.dataManager.get(apiRequest).subscribe(
        (response) => {
          const body = response.body as ArrayBuffer;
          const responseContentType = response.headers.get('Content-Type');
          const boundary = responseContentType.split(';')[1].split('=')[1] as string;
          const bodyString = String.fromCharCode.apply( null, new Uint8Array(body));
          mySubject.next(body.slice(bodyString.indexOf('%PDF'), bodyString.lastIndexOf(boundary)));
          mySubject.complete();
        },
        error => {
          mySubject.error(`${_.result(error, 'error.message', '')}`);
        });
    }
    return mySubject.asObservable();
  }
}
