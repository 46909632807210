import {ProBolUsageStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ProBolUsageStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ProBolUsageStatusCd ) {
      this._values.push(ProBolUsageStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ProBolUsageStatusCd {

    for ( const obj in ProBolUsageStatusCd ) {
      if (ProBolUsageStatusCd[obj] === value){
        return ProBolUsageStatusCd[obj] as ProBolUsageStatusCd;
      }
    }
  }
}

const ProBolUsageStatusCdHelper = new ProBolUsageStatusCdHelperEnumHelperClass();
export default ProBolUsageStatusCdHelper;
