import {ClaimExternalStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimExternalStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimExternalStatusCd ) {
      this._values.push(ClaimExternalStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimExternalStatusCd {

    for ( const obj in ClaimExternalStatusCd ) {
      if (ClaimExternalStatusCd[obj] === value){
        return ClaimExternalStatusCd[obj] as ClaimExternalStatusCd;
      }
    }
  }
}

const ClaimExternalStatusCdHelper = new ClaimExternalStatusCdHelperEnumHelperClass();
export default ClaimExternalStatusCdHelper;
