import {NonWorkDayTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class NonWorkDayTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in NonWorkDayTypeCd ) {
      this._values.push(NonWorkDayTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): NonWorkDayTypeCd {

    for ( const obj in NonWorkDayTypeCd ) {
      if (NonWorkDayTypeCd[obj] === value){
        return NonWorkDayTypeCd[obj] as NonWorkDayTypeCd;
      }
    }
  }
}

const NonWorkDayTypeCdHelper = new NonWorkDayTypeCdHelperEnumHelperClass();
export default NonWorkDayTypeCdHelper;
