import { XpoFilter } from '../models/filter';
import { XpoYesNoFilterComponent } from './yes-no-filter.component';

export class XpoYesNoFilter extends XpoFilter {
  constructor(public field: string, public label: string) {
    super(field, label, XpoYesNoFilterComponent);
  }

  getDisplayValue(val: any): string {
    if (val == null || val.length === 0 || val.length === 2) {
      return 'All';
    }

    return val.indexOf('1') !== -1 ? 'Yes' : 'No';
  }
}
