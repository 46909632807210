import { AfterViewInit, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { StatusColors } from './statusColor';

@Component({
  selector: 'xpo-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-StatusIndicator',
  },
})
export class XpoStatusIndicator implements AfterViewInit {
  @Input() isLabel: boolean;

  @Input() statusText: string;

  private statusColorValue: string;
  @Input()
  get statusColor(): string {
    return this.statusColorValue;
  }

  ngAfterViewInit(): void {
    if (this.isLabel) {
      this.elementRef.nativeElement.style.backgroundColor = this.statusColorValue;
      // TODO: Move this hardcoded values out of here NGXLTL-931
      this.elementRef.nativeElement.style.color = this.isColorDark(this.statusColorValue) ? '#FFFFFF' : '#333333';
    }
  }

  set statusColor(value: string) {
    this.statusColorValue = this.isColorPredefined(value) ? StatusColors.colors[value] : value;
  }

  constructor(private elementRef: ElementRef) {}

  private isColorPredefined(color: string): boolean {
    return !!StatusColors.colors[color];
  }

  private isColorDark(hexColor: string): boolean {
    const colorVal = hexColor.substring(1); // strip #
    const rgb = parseInt(colorVal, 16); // convert rrggbb to decimal

    /* eslint-disable */
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue
    /* eslint-enable */

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    return luma < 160; // this value should be verified with UX
  }
}
