import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { XpoFilterComponentBase, XpoFilterCriteria } from '../models/index';

@Component({
  selector: 'xpo-yes-no-filter',
  templateUrl: 'yes-no-filter.component.html',
  styleUrls: ['yes-no-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-YesNoFilter', '[attr.id]': 'id' },
})
export class XpoYesNoFilterComponent extends XpoFilterComponentBase {
  yesValue: boolean;
  noValue: boolean;

  constructor() {
    super();
  }

  /** updates the selected criterion of filter */
  onCheckboxClicked(): void {
    const value: string[] = [];

    if (this.yesValue) {
      value.push('1');
    }

    if (this.noValue) {
      value.push('0');
    }

    this.storeCriteria(value);
  }

  protected initialize(): void {
    // Nothing to initialize
  }

  protected onCriteriaModified(fieldValue: string[], criteria: XpoFilterCriteria): void {
    const val: string[] = fieldValue || [];

    this.yesValue = val.indexOf('1') !== -1;
    this.noValue = val.indexOf('0') !== -1;
  }
}
