import { AfterViewInit, Directive, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { XpoFilterContainer } from '../filter-container/filter-container.component';
import { XpoFiltersService } from '../filters.service';
import { DEFAULT_FILER_STRATEGY, XpoApplyFilterStrategy } from './apply-filter-strategy';
import { XpoFilter } from './filter';
import { XpoFilterComponent } from './filter-component';
import { XpoFilterCriteria } from './filter-criteria-type';

/**
 * Interface to be implemented by the component that renders the filter
 */
@Directive()
export abstract class XpoFilterComponentBase<T extends XpoFilter = XpoFilter>
  implements OnInit, AfterViewInit, OnDestroy, XpoFilterComponent {
  /** Whether or not the component renders inline on the filter-bar (like quicksearch) */
  inline = false;

  /** If the popup should close immediately when an option is clicked (like singleSelect) */
  closeOnApply = false;

  /** Id of filter */
  id: string;

  /** Configuration */
  @Input()
  configuration: T;

  /** Input data, name of the field that the filter is for */
  @Input()
  field: string;

  /** The query service */
  @Input()
  filtersService: XpoFiltersService;

  @Input()
  applyFilterStrategy: XpoApplyFilterStrategy = DEFAULT_FILER_STRATEGY;

  /** Callback to close popup if filter is in a popup */
  @Output()
  closePopup = new EventEmitter<void>();

  /** Filter Container element to hook into the apply and clear buttons */
  @ViewChild(XpoFilterContainer)
  filterContainer: XpoFilterContainer;

  /** Filter's current criteria */
  protected currentCriteria: any;

  protected componentDestroyed: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    this.initialize();

    this.filtersService.criteria$.pipe(takeUntil(this.componentDestroyed)).subscribe((c) => {
      const criteriaValue = c ? c[this.field] || null : null;
      this.currentCriteria = criteriaValue;
      this.onCriteriaModified(criteriaValue, c);
    });

    this.id = `${this.filtersService.id}-filter-${this.field}`;
  }

  ngAfterViewInit(): void {
    if (!this.filterContainer) {
      return;
    }

    if (this.configuration.onOpen) {
      this.configuration.onOpen();
    }

    // On Apply Clicked
    this.filterContainer.apply.pipe(takeUntil(this.componentDestroyed)).subscribe(() => {
      // We close the popup here instead of calling `applyCriteria` when its `container` since both clicking
      // outside of the popover and clicking `Apply` will apply the criteria, so this avoids a double call.
      this.filtersService.applyFilterStrategy === 'container' ? this.closePopup.emit() : this.applyCriteria(false);
    });

    // On Clear Clicked
    this.filterContainer.clear.pipe(takeUntil(this.componentDestroyed)).subscribe(() => {
      this.currentCriteria = null;
      this.applyCriteria(false);
    });

    // On Close Clicked
    this.filterContainer.close.pipe(takeUntil(this.componentDestroyed)).subscribe(() => {
      this.closePopup.emit();
    });
  }

  ngOnDestroy(): void {
    if (this.filterContainer && this.configuration.onClose) {
      this.configuration.onClose();
    }

    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  /**
   * Stores the current criteria, if the filter has no filter container (apply/clear buttons) it will apply
   * the criteria
   *
   * @param criteriaValue
   */
  storeCriteria(criteriaValue: any): void {
    this.currentCriteria = criteriaValue;

    // Set pending criteria that may not have been applied yet
    // This may only be applicable for applyFilterStrategy="global"
    this.filtersService.setPendingCriteria(this.field, criteriaValue);

    // If filter has no filter container, or doesn't have the apply button then apply criteria on change,
    if (!this.filterContainer || this.filtersService.applyFilterStrategy !== 'container' || this.closeOnApply) {
      // close the popup  only on Single select filter
      this.applyCriteria(true);
    }
  }

  /** Applies filter's criteria value to view */
  applyCriteria(applyCloseStrategy: boolean): void {
    this.filtersService.setFieldCriteria(this.field, this.currentCriteria);

    const closePopup = applyCloseStrategy ? this.closeOnApply : true;
    if (closePopup) {
      this.closePopup.emit();
    }
  }

  protected abstract initialize(): void;

  protected abstract onCriteriaModified(fieldValue: any, criteria: XpoFilterCriteria): void;
}
