import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { XpoViewMgtModalData } from './view-modal-data.interface';

@Component({
  selector: 'xpo-view-modal',
  templateUrl: './view-modal.component.html',
  styleUrls: ['./view-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
/**
 * This is the bas modal component used to handle all modals inside View Management
 */
export class XpoViewMgtModal {
  isSubmitting: boolean = false;
  @Input() modalData: XpoViewMgtModalData;
  @Output() onSubmit = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();

  constructor(public dialogRef: MatDialogRef<XpoViewMgtModal>) {}

  /**
   * Submit action
   */
  submit(): void {
    this.isSubmitting = true;
    this.onSubmit.emit();
  }

  /**
   * Cancel action
   */
  cancel(): void {
    this.onCancel.emit();
    this.close();
  }

  /**
   * Close modal
   */
  close(): void {
    this.dialogRef.close();
  }
}
