import { XrtSortExpression } from './sort-expression.model';

export class XrtSearchRequest {
  type?: string;
  pageNumber?: number;
  pageSize?: number;
  sortExpressions?: XrtSortExpression[];
  fields?: string[];
  filter?: { [key: string]: any };
  aggregations?: { [key: string]: any };
}
