import {LegalTermsTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class LegalTermsTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LegalTermsTypeCd ) {
      this._values.push(LegalTermsTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LegalTermsTypeCd {

    for ( const obj in LegalTermsTypeCd ) {
      if (LegalTermsTypeCd[obj] === value){
        return LegalTermsTypeCd[obj] as LegalTermsTypeCd;
      }
    }
  }
}

const LegalTermsTypeCdHelper = new LegalTermsTypeCdHelperEnumHelperClass();
export default LegalTermsTypeCdHelper;
