import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  XpoBadgeDotImportant,
  XpoBadgeDotInfo,
  XpoBadgeDotSuccess,
  XpoBadgeDotWarning,
  XpoBadgeImportantNotification,
} from './directives/badge.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    XpoBadgeDotWarning,
    XpoBadgeDotImportant,
    XpoBadgeDotInfo,
    XpoBadgeDotSuccess,
    XpoBadgeImportantNotification,
  ],
  exports: [
    XpoBadgeDotWarning,
    XpoBadgeDotImportant,
    XpoBadgeDotInfo,
    XpoBadgeDotSuccess,
    XpoBadgeImportantNotification,
  ],
})
export class XpoBadgeModule {}
