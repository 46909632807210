import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { XpoPopoverModule } from '@xpo-ltl/ngx-ltl-core';
import { NotificationsComponent } from './notifications.component';

@NgModule({
  declarations: [NotificationsComponent],
  imports: [CommonModule, MatBadgeModule, MatButtonModule, MatIconModule, XpoPopoverModule],
  exports: [NotificationsComponent],
})
export class NotificationsModule {}
