import { HorizontalConnectionPos, VerticalConnectionPos } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

export interface XpoAppSwitcherApplication {
  name: string;
  url: string;
}

/**
 * @deprecated
 */
@Component({
  selector: 'xpo-app-switcher-popover',
  templateUrl: './app-switcher-popover.component.html',
  styleUrls: ['./app-switcher-popover.component.scss'],
  host: {
    class: 'xpo-AppSwitcherPopover',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class XpoAppSwitcherPopover {
  @Input()
  applications: XpoAppSwitcherApplication[];

  @Input()
  caretPosition: HorizontalConnectionPos | VerticalConnectionPos;

  @Input()
  position: TooltipPosition;
}
