import {InvoiceRequirementTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoiceRequirementTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoiceRequirementTypeCd ) {
      this._values.push(InvoiceRequirementTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoiceRequirementTypeCd {

    for ( const obj in InvoiceRequirementTypeCd ) {
      if (InvoiceRequirementTypeCd[obj] === value){
        return InvoiceRequirementTypeCd[obj] as InvoiceRequirementTypeCd;
      }
    }
  }
}

const InvoiceRequirementTypeCdHelper = new InvoiceRequirementTypeCdHelperEnumHelperClass();
export default InvoiceRequirementTypeCdHelper;
