import { ChangeDetectionStrategy, Component, Directive, OnInit, ViewEncapsulation } from '@angular/core';
import { XpoAuthenticationService, XpoAuthSwitchApiService } from '@xpo/ngx-auth';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { XpoLtlAuthenticationService } from '../authentication/authentication.service';

export interface XpoAuthUserProfileData {
  name: string;
  imageUri: string;
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'xpo-auth-user-profile-links',
  host: { class: 'xpo-AuthUserProfileLinks' },
})
export class XpoAuthUserProfileLinksDirective {}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'xpo-auth-user-profile-actions',
  host: { class: 'xpo-AuthUserProfileActions' },
})
export class XpoAuthUserProfileActionsDirective {}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'xpo-auth-user-profile',
  templateUrl: './auth-user-profile.component.html',
  styleUrls: ['./auth-user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-AuthUserProfile' },
})
export class AuthUserProfileComponent implements OnInit {
  profile$: Observable<XpoAuthUserProfileData>;
  profileImageLoaded = false;

  constructor(
    private authService: XpoAuthenticationService,
    private switchApiService: XpoAuthSwitchApiService,
    private ltlAuthService: XpoLtlAuthenticationService
  ) {}

  ngOnInit(): void {
    this.profile$ = this.authService.getUser$().pipe(
      filter((v) => !!v && !!v.profile),
      map((user) => ({
        name: user.profile.name,
        imageUri: this.switchApiService.getUserImageSrc(user),
      }))
    );
  }

  signOut(): void {
    this.ltlAuthService.logout();
    this.authService.signOut$().subscribe();
  }
}
