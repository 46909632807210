import {MiscLineItemCd} from '../';
import {EnumHelper} from './enum-helper';

export class MiscLineItemCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MiscLineItemCd ) {
      this._values.push(MiscLineItemCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MiscLineItemCd {

    for ( const obj in MiscLineItemCd ) {
      if (MiscLineItemCd[obj] === value){
        return MiscLineItemCd[obj] as MiscLineItemCd;
      }
    }
  }
}

const MiscLineItemCdHelper = new MiscLineItemCdHelperEnumHelperClass();
export default MiscLineItemCdHelper;
