import {EnsembleExecutionStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class EnsembleExecutionStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EnsembleExecutionStatusCd ) {
      this._values.push(EnsembleExecutionStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EnsembleExecutionStatusCd {

    for ( const obj in EnsembleExecutionStatusCd ) {
      if (EnsembleExecutionStatusCd[obj] === value){
        return EnsembleExecutionStatusCd[obj] as EnsembleExecutionStatusCd;
      }
    }
  }
}

const EnsembleExecutionStatusCdHelper = new EnsembleExecutionStatusCdHelperEnumHelperClass();
export default EnsembleExecutionStatusCdHelper;
