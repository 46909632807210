import { CanDeactivate } from '@angular/router';

import { ModuleGroupManagementComponent } from '../module-group-management.component';

/* Rxjs */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class ConfirmDeactivateGuard implements CanDeactivate<ModuleGroupManagementComponent> {
  canDeactivate(target: ModuleGroupManagementComponent): Observable<boolean> | boolean {
    if (target.partialUpdated) {
      return target.openDialogConfirmCancel().pipe(
        switchMap((resp) => {
          if (resp) {
            if (target.needToUnschedule) {
              return target.unscheduleModuleGroupExecution();
            } else {
              return of(true);
            }
          } else {
            return of(false);
          }
        })
      );
    } else {
      return true;
    }
  }
}
