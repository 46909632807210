import { Pipe, PipeTransform } from '@angular/core';
import { MovementStatusCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'movementStatus',
})
export class MovementStatusPipe implements PipeTransform {
  transform(value: MovementStatusCd): string {
    switch (value) {
      case MovementStatusCd.CANCELLED:
        return 'Cancelled';
      case MovementStatusCd.FINAL_DLVD:
        return 'Final Delivered';
      case MovementStatusCd.INTERIM_DLVRY:
        return 'Interim Delivery';
      case MovementStatusCd.NO_MVMT_ALLOWED:
        return 'No Movement Allowed';
      case MovementStatusCd.ON_DOCK:
        return 'On Dock';
      case MovementStatusCd.NOT_APPLICABLE:
        return 'Not Applicable';
      case MovementStatusCd.ON_TRAILER:
        return 'On Trailer';
      case MovementStatusCd.OUT_FOR_DLVRY:
        return 'Out For Delivery';
      default:
        return value;
    }
  }
}
