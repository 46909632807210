import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { XpoLtlPopoverContent } from './xpo-ltl-popover-content.directive';
import { XpoLtlPopoverFooter } from './xpo-ltl-popover-footer.directive';
import { XpoLtlPopoverHeader } from './xpo-ltl-popover-header.directive';
import { XpoLtlPopoverStatusHeader } from './xpo-ltl-popover-status-header.directive';
import {
  XpoLtlPopoverTrigger,
  XPO_LTL_POPOVER_SCROLL_STRATEGY_FACTORY_PROVIDER,
} from './xpo-ltl-popover-trigger.directive';
import { XpoLtlPopover } from './xpo-ltl-popover.component';

@NgModule({
  declarations: [
    XpoLtlPopover,
    XpoLtlPopoverTrigger,
    XpoLtlPopoverHeader,
    XpoLtlPopoverContent,
    XpoLtlPopoverFooter,
    XpoLtlPopoverStatusHeader,
  ],
  imports: [CommonModule, MatMenuModule, MatIconModule, MatButtonModule],
  exports: [
    XpoLtlPopover,
    XpoLtlPopoverTrigger,
    XpoLtlPopoverHeader,
    XpoLtlPopoverContent,
    XpoLtlPopoverFooter,
    XpoLtlPopoverStatusHeader,
  ],
  providers: [XPO_LTL_POPOVER_SCROLL_STRATEGY_FACTORY_PROVIDER],
})
export class XpoLtlPopoverModule {}
