import {DeliveryQualifierReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class DeliveryQualifierReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DeliveryQualifierReasonCd ) {
      this._values.push(DeliveryQualifierReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DeliveryQualifierReasonCd {

    for ( const obj in DeliveryQualifierReasonCd ) {
      if (DeliveryQualifierReasonCd[obj] === value){
        return DeliveryQualifierReasonCd[obj] as DeliveryQualifierReasonCd;
      }
    }
  }
}

const DeliveryQualifierReasonCdHelper = new DeliveryQualifierReasonCdHelperEnumHelperClass();
export default DeliveryQualifierReasonCdHelper;
