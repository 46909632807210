import {XdockExceptionTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class XdockExceptionTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in XdockExceptionTypeCd ) {
      this._values.push(XdockExceptionTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): XdockExceptionTypeCd {

    for ( const obj in XdockExceptionTypeCd ) {
      if (XdockExceptionTypeCd[obj] === value){
        return XdockExceptionTypeCd[obj] as XdockExceptionTypeCd;
      }
    }
  }
}

const XdockExceptionTypeCdHelper = new XdockExceptionTypeCdHelperEnumHelperClass();
export default XdockExceptionTypeCdHelper;
