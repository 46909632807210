import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ModuleLibraryActionsCellRendererParams } from './models/actions-cell-renderer-params';

@Component({
  selector: 'actions-cell-renderer',
  templateUrl: './actions-cell-renderer.component.html',
  styleUrls: ['./actions-cell-renderer.component.scss'],
})
export class ModuleLibraryActionsCellRendererComponent implements ICellRendererAngularComp {
  params: ModuleLibraryActionsCellRendererParams;

  agInit(params: ModuleLibraryActionsCellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  onDelete(): void {
    if (this.params.node.group) {
      this.params.onDeleteModule({ rowNode: this.params.node });
    } else {
      this.params.onDeleteModuleVersion({ rowNode: this.params.node });
    }
  }
}
