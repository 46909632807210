import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[xpoChipColor]',
})
export class XpoChipColor {
  private xpoChipColorValue: string;

  constructor(private elementRef: ElementRef) {}

  @Input('xpoChipColor')
  get xpoChipColor(): string {
    return this.xpoChipColorValue;
  }
  set xpoChipColor(value: string) {
    this.xpoChipColorValue = value;

    if (this.isHexColor(this.xpoChipColor)) {
      this.elementRef.nativeElement.style.backgroundColor = this.xpoChipColor;
      this.elementRef.nativeElement.style.color = this.isColorDark(this.xpoChipColor) ? '#FFFFFF' : '#171717';
    }
  }

  private isHexColor(hexColor: string): boolean {
    const regexp = /^[0-9a-fA-F]+$/;
    return hexColor.startsWith('#') && regexp.test(hexColor.substring(1));
  }

  private isColorDark(hexColor: string): boolean {
    const colorVal = hexColor.substring(1); // strip #
    const rgb = parseInt(colorVal, 16); // convert rrggbb to decimal

    /* eslint-disable */
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue
    /* eslint-enable */

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    return luma < 160; // this value should be verified with UX
  }
}
