import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { XpoButtonModule } from '../button/button.module';
import { XpoDialogModule } from '../dialog/dialog.module';
import { XpoFeedback } from './feedback.component';

@NgModule({
  declarations: [XpoFeedback],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    XpoButtonModule,
    XpoDialogModule,
  ],
  exports: [XpoFeedback],
  entryComponents: [XpoFeedback],
})
export class XpoFeedbackModule {}
