import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, share } from 'rxjs/operators';

export interface XpoDeleteViewConfirmData {
  deleteView: () => Observable<boolean>;
}

@Component({
  selector: 'xpo-delete-confirm-dialog',
  templateUrl: './delete-confirm-dialog.component.html',
  styleUrls: ['./delete-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-DeleteViewDialog' },
})
export class XpoDeleteViewConfirmDialog {
  deleting$: Observable<boolean>;

  private deletingSource$ = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<XpoDeleteViewConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) private data: XpoDeleteViewConfirmData
  ) {
    this.deleting$ = this.deletingSource$.asObservable().pipe(share());
  }

  confirmDelete(): void {
    this.deletingSource$.next(true);

    this.data
      .deleteView()
      .pipe(
        finalize(() => {
          this.deletingSource$.next(false);
        })
      )
      .subscribe((newState) => {
        this.dialogRef.close(newState);
      });
  }
}
