import { Component, Input } from '@angular/core';
import { XpoDateRangePicker } from '../../date-range-picker.component';

@Component({
  selector: 'xpo-date-range-picker-toggle',
  templateUrl: 'date-range-picker-toggle.component.html',
  styleUrls: ['date-range-picker-toggle.component.scss'],
  host: {
    class: 'xpo-DateRangePickerToggle',
  },
  exportAs: 'xpoDateRangePickerToggle',
})
export class XpoDateRangePickerToggle<D> {
  /** Date Range Picker instance that the button will toggle. */
  @Input('for') dateRangePicker: XpoDateRangePicker<D>;

  open(event: Event): void {
    if (this.dateRangePicker) {
      this.dateRangePicker.open();
      event.stopPropagation();
    }
  }
}
