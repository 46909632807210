import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { find as _find, forEach as _forEach, remove as _remove, size as _size } from 'lodash';

interface DialogInfo {
  dialog: MatDialogRef<any>;
  container: Element;
}

@Injectable({ providedIn: 'root' })
export class XpoLtlDialogTitleService {
  constructor(private matDialog: MatDialog, private overlayContainer: OverlayContainer) {
    this.matDialog.afterOpened.subscribe((newDialog: MatDialogRef<any>) => {
      newDialog.afterOpened().subscribe(() => {
        // register this dialog with the stack after it is created
        this.addDialog(newDialog);
      });
    });
  }

  private dialogStack: Array<DialogInfo> = [];

  /**
   * Returns the overlay Element that contains the item with the passed targetId
   */
  private findContainerWithId(targetId: string): Element {
    if (targetId) {
      const overlays = this.overlayContainer.getContainerElement().querySelectorAll('.cdk-global-overlay-wrapper');
      const targetOverlay: Element = _find(overlays, (overlay) => !!overlay.querySelector(`#${targetId}`));
      return targetOverlay;
    } else {
      return undefined;
    }
  }

  /**
   * Add the dialog to the stack of focusable dialogs
   * @param dialog dialog to add
   */
  addDialog(dialog: MatDialogRef<any>): void {
    if (dialog) {
      const container = this.findContainerWithId(dialog.id);
      const dialogInfo: DialogInfo = { dialog, container };
      this.dialogStack.push(dialogInfo);
      this.updateLayers();

      dialog.beforeClosed().subscribe(() => {
        // remove the dialog from the stack when it is closed
        this.removeDialog(dialog.id);
      });

      dialog.keydownEvents().subscribe((keyEvent: KeyboardEvent) => {
        if (keyEvent.code === 'Escape') {
          // close the currently focused dialog
          const dialogToClose = this.dialogStack.pop();
          dialogToClose.dialog.close();
        }
      });
    }
  }

  /**
   * Remove the specified dialog from the stack and rebuild layers
   * @param dialogId id of the dialog to remove
   */
  removeDialog(dialogId: string): void {
    _remove(this.dialogStack, (dialogInfo) => dialogInfo.dialog.id === dialogId);
    this.updateLayers();
  }

  /**
   * Set the specified dialog as focused and bring to the top of the stack
   * @param dialogId id of dialog to set as focused
   */
  focusDialog(dialogId: string): void {
    if (_size(dialogId) > 0) {
      const focused = _remove(this.dialogStack, (dialogInfo) => !!dialogInfo.container.querySelector(`#${dialogId}`));
      if (_size(focused)) {
        this.dialogStack.push(focused[0]);
        this.updateLayers();
      }
    }
  }

  /**
   * Update the layers so that the focused dialog is on top of all others
   */
  private updateLayers(): void {
    const baseZ = 2000;
    _forEach(this.dialogStack, (di: DialogInfo, index: number) => {
      di.container.classList.remove('xpo-ltlDialogFocused');
      di.container['style'].zIndex = `${baseZ + index} !important`;
      if (index === _size(this.dialogStack) - 1) {
        di.container.classList.add('xpo-ltlDialogFocused');
      }
    });
  }
}
