import { Injectable } from '@angular/core';

import { ListInfo } from '@xpo-ltl/sdk-common';
import { ListModulesQuery, ListModulesResp } from '@xpo-ltl/sdk-moduleexecutor';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/* Models */
import { AssociatedModuleVersion } from '../components/associated-modules/models/module-group-mgmt-associated-modules-grid.model';
import { ListModulesService } from './list-modules.service';

@Injectable({
  providedIn: 'root',
})
export class ModuleGroupMgmtAssociatedModulesService {
  constructor(private listModulesService: ListModulesService) {}

  listModuleGroupMgmtAssociatedModules(): Observable<Array<AssociatedModuleVersion>> {
    const query = new ListModulesQuery();
    query.listInfo = new ListInfo();

    return this.listModulesService.requestListModules(query).pipe(
      map((response: ListModulesResp) => {
        let associatedModuleVersions = new Array<AssociatedModuleVersion>();

        response.modules.map(
          (module) =>
            (associatedModuleVersions = associatedModuleVersions.concat(
              module.moduleVersion.map((version) => ({
                ...version,
                ...{
                  moduleName: module.moduleName,
                },
              }))
            ))
        );

        return associatedModuleVersions;
      })
    );
  }
}
