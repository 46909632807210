import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { XpoLtlShipmentDetailsTabs } from '../shipment-details-tabs.enum';

@Component({
  selector: 'xpo-ltl-shipment-details-details',
  templateUrl: './shipment-details-details.component.html',
  styleUrls: ['./shipment-details-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ShipmentDetailsDetailsComponent implements OnInit {
  @Input() hasFreightChargesTab: boolean = false;
  @Input() hidePaymentSummary: boolean = false;
  @Input() hideCharges: boolean = false;

  @Output()
  openTab = new EventEmitter<XpoLtlShipmentDetailsTabs>();

  private showSRNsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private showDimensionsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showSRNs$ = this.showSRNsSubject.asObservable();
  showDimensions$ = this.showDimensionsSubject.asObservable();

  constructor() {}

  set showSRNs(value: boolean) {
    this.showSRNsSubject.next(value);
  }

  set showDimensions(value: boolean) {
    this.showDimensionsSubject.next(value);
  }

  ngOnInit() {}

  openFreightCharges() {
    this.openTab.emit(XpoLtlShipmentDetailsTabs.Charges);
  }

  toggleSRNs(show: boolean) {
    this.showSRNs = show;
  }

  toggleDimensions(show: boolean) {
    this.showDimensions = show;
  }
}
