import { Injectable } from '@angular/core';

/* Xpo */
import { ListInfo } from '@xpo-ltl/sdk-common';
import {
  DeleteModulePath,
  DeleteModuleVersionPath,
  ListModulesQuery,
  ListModulesResp,
  Module,
  ModuleExecutorApiService,
  ModuleVersion,
} from '@xpo-ltl/sdk-moduleexecutor';

/* Rxjs */
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/* Enums */
import { ModuleLibraryLocalStorageKeys } from '../enums/local-storage-keys.enum';

/* Models */
import { ModuleLibraryVersion } from '../models';

@Injectable()
export class ModuleLibraryService {
  constructor(private apiService: ModuleExecutorApiService) {}

  listModuleVersions(): Observable<ModuleLibraryVersion[]> {
    const query = new ListModulesQuery();
    query.listInfo = new ListInfo();

    return this.apiService.listModules(query, { loadingOverlayEnabled: false }).pipe(
      map((response: ListModulesResp) => {
        const moduleLibraryVersions: ModuleLibraryVersion[] = response.modules.flatMap((module: Module) => {
          return module.moduleVersion.map((moduleVersion: ModuleVersion) => {
            return {
              ...module,
              ...moduleVersion,
              ...{
                // Add Custom Information
                isNew: this.isNewVersion(moduleVersion),
              },
            };
          });
        });
        this.setLastVersionCreated(moduleLibraryVersions);
        return moduleLibraryVersions;
      })
    );
  }

  getNewModules(): Observable<Array<ModuleLibraryVersion>> {
    return this.listModuleVersions().pipe(map((modules) => modules.filter((module) => module.isNew)));
  }

  deleteModule(moduleInstId: number): Observable<void> {
    const pathParams: DeleteModulePath = {
      moduleInstId: moduleInstId,
    };
    return this.apiService.deleteModule(pathParams);
  }

  deleteModuleVersion(moduleInstId: number, moduleVersionSeqNbr: number): Observable<void> {
    const pathParams: DeleteModuleVersionPath = {
      moduleInstId: moduleInstId,
      moduleVersionSeqNbr: moduleVersionSeqNbr,
    };
    return this.apiService.deleteModuleVersion(pathParams);
  }

  private isNewVersion(version: ModuleVersion): boolean {
    const lastCreated = localStorage.getItem(ModuleLibraryLocalStorageKeys.lastCreatedVersion);

    return lastCreated && version.auditInfo.createdTimestamp > new Date(Number(lastCreated));
  }

  private setLastVersionCreated(moduleLibraryVersions: ModuleLibraryVersion[]): void {
    const lastCreated = localStorage.getItem(ModuleLibraryLocalStorageKeys.lastCreatedVersion);

    const maxCreated = Math.max.apply(
      null,
      moduleLibraryVersions.map(
        (moduleLibraryVersion: ModuleLibraryVersion) => moduleLibraryVersion.auditInfo.createdTimestamp
      )
    );

    if (!lastCreated || maxCreated > new Date(Number(lastCreated))) {
      localStorage.setItem(ModuleLibraryLocalStorageKeys.lastCreatedVersion, maxCreated.toString());
    }
  }
}
