import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { XpoAdvancedSelectComponentOption } from '../models/index';

@Component({
  selector: 'xpo-advanced-select-tree-node',
  templateUrl: './advanced-select-tree-node.component.html',
  styleUrls: ['./advanced-select-tree-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-AdvancedSelectTreeNode',
    // Used for filtering. Instead of destroying the component, we hide it, that way the `indeterminate`
    // logic for the parent node still works.
    '[class.xpo-AdvancedSelectTreeNode--hidden]': 'option.hidden',
  },
})
export class XpoAdvancedSelectTreeNodeComponent {
  @Input()
  option: XpoAdvancedSelectComponentOption;

  @Output()
  optionSelected = new EventEmitter<XpoAdvancedSelectComponentOption>();

  @ViewChildren(XpoAdvancedSelectTreeNodeComponent) childTreeNodes: QueryList<XpoAdvancedSelectTreeNodeComponent>;

  constructor(private cd: ChangeDetectorRef) {}

  onCheckboxClicked(event: MatCheckboxChange): void {
    this.setSelected(event.checked);

    this.emitUpdate();
  }

  setSelected(checked: boolean): void {
    this.option.selected = checked;
    this.option.indeterminate = false;
    this.cd.markForCheck();

    if (this.childTreeNodes && this.childTreeNodes.length) {
      this.childTreeNodes.forEach((v) => v.setSelected(checked));
    }
  }

  onChildCheckboxClicked(childOption: XpoAdvancedSelectComponentOption): void {
    if (this.option.children && this.option.children.length) {
      this.option.indeterminate =
        this.option.children.some((ch) => ch.selected) && !this.option.children.every((ch) => ch.selected);
      this.option.selected = this.option.children.every((ch) => ch.selected);
    }

    this.emitUpdate();
  }

  private emitUpdate(): void {
    this.optionSelected.emit(this.option);
  }
}
