export interface XrtChangedDocuments {
  filterHash: string;
  documents: XrtChangedDocument[];
}
export interface XrtChangedDocument {
  documentKey: number;
}

export interface XrtFirebaseMessageData {
  filterHash: string;
  changedDocumentsJson: any;
}

export interface XrtFirebaseMessage {
  data: XrtFirebaseMessageData;
}
