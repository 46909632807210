import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { XpoBoardModule, XpoFiltersModule, XPO_GRID_BOARD_DEFAULT_ROW_HEIGHT } from '@xpo-ltl/ngx-ltl-board';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoAgGridModule, XPO_AG_GRID_DEFAULT_ROW_HEIGHT } from '@xpo-ltl/ngx-ltl-grid';
import { AgGridModule } from 'ag-grid-angular';

import { FormsModule } from '@angular/forms';
import { XpoAgGridFilterChipComponent } from '../ag-grid-filter-chip/index';
import { XpoAgGridSelectAllCheckbox } from '../ag-grid-select-all/ag-grid-select-all.component';
import { XpoAgGridBoard } from './ag-grid-board.component';

@NgModule({
  declarations: [XpoAgGridBoard, XpoAgGridFilterChipComponent, XpoAgGridSelectAllCheckbox],
  imports: [
    AgGridModule.withComponents([XpoAgGridFilterChipComponent, XpoAgGridSelectAllCheckbox]),
    CommonModule,
    MatButtonModule,
    XpoButtonModule,
    MatProgressSpinnerModule,
    XpoAgGridModule,
    XpoFiltersModule,
    MatCheckboxModule,
    FormsModule,
  ],
  exports: [XpoAgGridBoard, XpoBoardModule],
  // TODO: use factory to check if XPO_GRID_BOARD_DEFAULT_ROW_HEIGHT already is provided, if not, use this default
  // To enable end dev to default their own row height
  providers: [{ provide: XPO_GRID_BOARD_DEFAULT_ROW_HEIGHT, useValue: XPO_AG_GRID_DEFAULT_ROW_HEIGHT }],
})
export class XpoAgGridBoardModule {}
