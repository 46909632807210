import { Component, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import { xpoDateFormatter } from '../../../../../../formatters';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { filterFormats, Operators, OperatorText, XpoFilterConditionCriteria } from './../../../../../models/index';

@Component({
  templateUrl: './equals.component.html',
  styleUrls: ['../../validator.scss'],
})
export class XpoConditionDateEqualsComponent extends XpoConditionBase implements XpoFilterCondition {
  static text: string = OperatorText.Exactly;
  static operator = Operators.Equals;

  isNegative = false;
  model: Date;

  @Output() inputChanged = new EventEmitter();

  constructor() {
    super(filterFormats.default);
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    if (!cellValue) {
      return false;
    }

    // remove time part and compare
    return moment(this.model)
      .startOf('day')
      .isSame(moment(cellValue).startOf('day'));
  }

  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.Equals,
      value: this.model,
      display: xpoDateFormatter({ value: this.model }),
    };
  }
  onInputChange(): any {
    this.validateFormat();
    this.inputChanged.emit(this.model);
  }
}
