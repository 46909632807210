import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

/* Material */
import { MatMenuModule } from '@angular/material/menu';

/* Xpo Modules */
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoButtonModule, XpoIconModule, XpoTooltipModule } from '@xpo-ltl/ngx-ltl-core';
import { SdkModuleExecutorModule } from '@xpo-ltl/sdk-moduleexecutor';
import { AgGridModule } from 'ag-grid-angular';

/* Components */
import { ModuleGroupMgmtActionsCellRendererComponent } from './cell-renderers/actions-cell-renderer/actions-cell-renderer.component';
import { ModuleGroupMgmtNameCellRendererComponent } from './cell-renderers/name-cell-renderer/name-cell-renderer.component';
import { ModuleGroupMgmtAssociatedModulesComponent } from './module-group-mgmt-associated-modules.component';

/* Services */

@NgModule({
  declarations: [
    ModuleGroupMgmtAssociatedModulesComponent,
    ModuleGroupMgmtActionsCellRendererComponent,
    ModuleGroupMgmtNameCellRendererComponent,
  ],
  imports: [
    CommonModule,
    XpoIconModule,
    XpoButtonModule,
    XpoTooltipModule,
    MatMenuModule,
    XpoAgGridBoardModule,
    AgGridModule.withComponents([
      ModuleGroupMgmtActionsCellRendererComponent,
      ModuleGroupMgmtNameCellRendererComponent,
    ]),
    SdkModuleExecutorModule,
  ],
  exports: [ModuleGroupMgmtAssociatedModulesComponent],
})
export class ModuleGroupMgmtAssociatedModulesModule {}
