export class XpoAuthConfig implements Required<XpoAuthConfigData> {
  static readonly BaseScopes = ['openid', 'email', 'name', 'xpo-auth-api'];
  static readonly ResponseTypes = ['id_token', 'token'];

  appCode: string;
  scopes: string[];
  isProd: boolean;
  authorityUri: string;
  authApiUri: string;
  applicationRootUri: string;
  unauthorizedPath: string;
  switchApiUri: string;

  constructor(config: XpoAuthConfigData) {
    if (!config) {
      throw new Error('Must Provide XpoAuthConfigData');
    }

    if (!config.appCode) {
      throw new Error('Must Provide appCode in XpoAuthConfigData');
    }

    if (!config.scopes) {
      throw new Error('Must Provide scopes in XpoAuthConfigData or default it to [] explicitly');
    }

    this.appCode = config.appCode;

    this.isProd = !!config.isProd;

    // Appending base scopes with entered ones, and ensuring they are distinct
    this.scopes = Array.from(new Set([...config.scopes, ...XpoAuthConfig.BaseScopes]));

    // If no applicationRootUri, default it to the origin of the app.
    this.applicationRootUri = config.applicationRootUri || window.location.origin;

    // Add the trailing slash if not already there
    this.applicationRootUri = this.applicationRootUri.endsWith('/')
      ? this.applicationRootUri
      : `${this.applicationRootUri}/`;

    // If no authApiUri provided, default it to the authXPO's sandbox or prod environment based the isProd flag
    this.authApiUri =
      config.authApiUri || (this.isProd ? 'https://api.authxpo.com' : 'https://api-sandbox.authxpo.com');

    // If no authorityUri,default it to the authXPO's sandbox or prod environment based the isProd flag
    this.authorityUri =
      config.authorityUri || (this.isProd ? 'https://login.authxpo.com' : 'https://login-sandbox.authxpo.com');

    this.switchApiUri =
      config.switchApiUri ||
      (this.isProd ? 'https://switch.xpoapi.com' : 'https://switch-sandbox.xpoapi.com');

    this.unauthorizedPath = config.unauthorizedPath || '/unauthorized';
  }
}

export interface XpoAuthConfigData {
  appCode: string;
  scopes: string[];
  isProd: boolean;
  applicationRootUri?: string;
  authApiUri?: string;
  authorityUri?: string;
  unauthorizedPath?: string;
  switchApiUri?: string;
}
