import {JSPShiftTimeNameTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class JSPShiftTimeNameTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in JSPShiftTimeNameTypeCd ) {
      this._values.push(JSPShiftTimeNameTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): JSPShiftTimeNameTypeCd {

    for ( const obj in JSPShiftTimeNameTypeCd ) {
      if (JSPShiftTimeNameTypeCd[obj] === value){
        return JSPShiftTimeNameTypeCd[obj] as JSPShiftTimeNameTypeCd;
      }
    }
  }
}

const JSPShiftTimeNameTypeCdHelper = new JSPShiftTimeNameTypeCdHelperEnumHelperClass();
export default JSPShiftTimeNameTypeCdHelper;
