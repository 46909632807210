import { XpoAgGridBoardColumn } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoAgGridColumns } from '@xpo-ltl/ngx-ltl-grid';

export const MODULE_GROUP_MGMT_ASSOCIATED_MODULES_COLUMNS: XpoAgGridBoardColumn[] = [
  { ...XpoAgGridColumns.SelectionWithSelectAll, ...{ minWidth: 35, maxWidth: 35, pinned: 'left' } },
  {
    headerName: 'Module Name',
    field: 'moduleName',
    cellRenderer: 'NameCellRenderer',
    rowGroup: true,
    hide: true,
  },
  {
    headerName: 'Version',
    field: 'version',
  },
];
