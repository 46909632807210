import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentCdt } from '@xpo-ltl/sdk-documentmanagement';
import { first as _first, get as _get, size as _size, uniqBy as _uniqBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { XpoLtlDocType } from '../doc-type.enum';
import { Unsubscriber } from '../../classes';
import { XpoLtlDocTypePipe } from '../doc-type.pipe';
import { XpoLtlDocumentViewerComponent } from '../document-viewer/document-viewer.component';
import { XpoLtlDocumentViewerDialogConfig } from './document-viewer-dialog-config';

interface DialogData {
  shipmentDescriptors: DocumentCdt[];
  config?: XpoLtlDocumentViewerDialogConfig;
}

@Component({
  selector: 'xpo-ltl-document-viewer-dialog',
  templateUrl: './document-viewer-dialog.component.html',
  styleUrls: ['./document-viewer-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpoLtlDocumentViewerDialogComponent implements AfterViewInit, OnDestroy {
  private unsubscriber = new Unsubscriber();
  private dialogRef: MatDialogRef<XpoLtlDocumentViewerComponent>;

  opened = false;

  private dialogData: DialogData;
  private documentDescs: DocumentCdt[];

  get documentDescriptors(): DocumentCdt[] {
    return this.documentDescs;
  }

  get moveable(): boolean {
    return !!_get(this.dialogData, 'config.moveable');
  }

  get searchable(): boolean {
    return !!_get(this.dialogData, 'config.searchable');
  }

  get showDocumentsListPanel(): boolean {
    return _size(this.documentDescriptors) > 1 || this.searchable;
  }

  private selectedDocumentSubject = new BehaviorSubject<DocumentCdt>(undefined);
  readonly selectedDocument$ = this.selectedDocumentSubject.asObservable();
  get selectedDocument(): DocumentCdt {
    return this.selectedDocumentSubject.value;
  }

  private dialogTitleSubject = new BehaviorSubject<string>('Shipment');
  readonly dialogTitle$ = this.dialogTitleSubject.asObservable();

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private docTypePipe: XpoLtlDocTypePipe) {
    this.dialogData = data;
    this.documentDescs = _uniqBy(this.dialogData.shipmentDescriptors, 'proNbr');

    this.opened = _get(this.dialogData, 'config.showShipmentSelector', false);

    this.dialogTitle$ = this.selectedDocument$.pipe(
      filter((selectedDocument) => !!selectedDocument),
      map((selectedDocument: DocumentCdt) => {
        const title = this.docTypePipe.transform(selectedDocument.docClass as XpoLtlDocType);
        return title;
      })
    );
  }

  ngAfterViewInit() {
    if (_size(this.documentDescriptors) === 1) {
      this.selectedDocumentSubject.next(_first(this.documentDescriptors));
    }
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.closeClicked();
  }

  closeClicked() {
    if (!!this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
