
export enum WeekDayCd {
	FRIDAY = 'Friday',
	MONDAY = 'Monday',
	SATURDAY = 'Saturday',
	SUNDAY = 'Sunday',
	THURSDAY = 'Thursday',
	TUESDAY = 'Tuesday',
	WEDNESDAY = 'Wednesday'}

