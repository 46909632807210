import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

/**
 * Force input to be Uppercase
 */
@Directive({
  selector: '[xpo-uppercase]',
})
export class XpoUppercaseDirective implements OnInit {
  constructor(public ref: ElementRef) {}

  ngOnInit(): void {
    this.ref.nativeElement.value = this.ref.nativeElement.value ? this.ref.nativeElement.value.toUpperCase() : '';
  }

  @HostListener('input', ['$event']) onInput(event) {
    this.ref.nativeElement.value = event.target.value.toUpperCase();
  }
}
