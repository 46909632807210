import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { XpoCard, XpoCardActions, XpoCardContent } from './card.component';
import { XpoCardFooter, XpoCardHeader, XpoCardHeaderShadow, XpoCardTitle } from './components/index';

@NgModule({
  imports: [CommonModule, MatTooltipModule],
  declarations: [
    XpoCard,
    XpoCardActions,
    XpoCardContent,
    XpoCardFooter,
    XpoCardHeader,
    XpoCardTitle,
    XpoCardHeaderShadow,
  ],
  exports: [XpoCard, XpoCardActions, XpoCardContent, XpoCardFooter, XpoCardHeader, XpoCardTitle, XpoCardHeaderShadow],
})
export class XpoCardModule {}
