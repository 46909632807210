import { Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';
import { Subject } from 'rxjs';

/**
 * Manages calendar header events, dates format and others headers utilities.
 * Allows to communicate each calendar header component with its parent calendar component
 * to keep both calendar header synchronized.
 */
@Injectable()
export class XpoCalendarHeaderService<D> {
  /** Subject to notify when to move to the next period */
  onNext: Subject<void> = new Subject<void>();

  /** Subject to notify when to move to the previous period */
  onPrevious: Subject<void> = new Subject<void>();

  constructor(
    protected intl: MatDatepickerIntl,
    protected dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) protected dateFormats: MatDateFormats
  ) {}

  /**
   * Returns if header previous button should be enabled,
   * based on the calendar min date
   * @param minDate
   * @param activeDate
   */
  previousEnabled(minDate: D, activeDate: D): boolean {
    if (!minDate) {
      return true;
    }

    // if not same active and min year and month
    return !(
      this.dateAdapter.getYear(activeDate) === this.dateAdapter.getYear(minDate) &&
      this.dateAdapter.getMonth(activeDate) === this.dateAdapter.getMonth(minDate)
    );
  }

  /**
   * Returns if header next button should be enabled,
   * based on the calendar max date
   * @param maxDate
   * @param activeDate
   */
  nextEnabled(maxDate: D, activeDate: D): boolean {
    if (!maxDate) {
      return true;
    }

    // if not same active and max year and month
    return !(
      this.dateAdapter.getYear(activeDate) === this.dateAdapter.getYear(maxDate) &&
      this.dateAdapter.getMonth(activeDate) === this.dateAdapter.getMonth(maxDate)
    );
  }

  /**
   * Returns the aria-label for the previous button
   */
  getPreviousAriaLabel(): string {
    return this.intl.prevMonthLabel;
  }

  /**
   * Returns the aria-label for the next button
   */
  getNextAriaLabel(): string {
    return this.intl.nextMonthLabel;
  }

  /**
   * Returns the formatted date to show as the calendar period title
   * @param date
   */
  getPeriodLabel(date: D): string {
    return this.dateAdapter.format(date, this.dateFormats.display.monthYearLabel);
  }
}
