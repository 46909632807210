import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { XpoTileRibbonClickableDirective } from './directives/tile-ribbon-clickable.directive';
import { XpoTileRibbonCompactDirective } from './directives/tile-ribbon-compact.directive';
import { XpoTileRibbonMiniDirective } from './directives/tile-ribbon-mini.directive';
import { XpoTileRibbonHeaderComponent } from './header/tile-ribbon-header.component';
import { XpoScrollableDirective } from './scrollable/directives/scrollable.directive';
import { XpoScrollableComponent } from './scrollable/scrollable.component';
import { XpoTileRibbonComponent } from './tile-ribbon.component';
import { XpoTileComponent } from './tile/tile.component';

@NgModule({
  declarations: [
    XpoScrollableDirective,
    XpoScrollableComponent,
    XpoTileComponent,
    XpoTileRibbonHeaderComponent,
    XpoTileRibbonComponent,
    XpoTileRibbonCompactDirective,
    XpoTileRibbonClickableDirective,
    XpoTileRibbonMiniDirective,
  ],
  imports: [CommonModule, ScrollingModule, MatIconModule],
  exports: [
    XpoTileRibbonComponent,
    XpoTileComponent,
    XpoTileRibbonCompactDirective,
    XpoTileRibbonClickableDirective,
    XpoTileRibbonMiniDirective,
  ],
})
export class XpoTileRibbonModule {}
