import {BeaconTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class BeaconTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BeaconTypeCd ) {
      this._values.push(BeaconTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BeaconTypeCd {

    for ( const obj in BeaconTypeCd ) {
      if (BeaconTypeCd[obj] === value){
        return BeaconTypeCd[obj] as BeaconTypeCd;
      }
    }
  }
}

const BeaconTypeCdHelper = new BeaconTypeCdHelperEnumHelperClass();
export default BeaconTypeCdHelper;
