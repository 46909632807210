import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryQualifierCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'deliveryQualifierCd',
})
export class DeliveryQualifierCdPipe implements PipeTransform {
  transform(value: DeliveryQualifierCd, useAbbrv: boolean = false): string {
    const deliveryQualifierCdMap: Map<DeliveryQualifierCd, { full: string; abbrv: string }> = new Map([
      [DeliveryQualifierCd.ALL_SHORT, { full: 'All Short', abbrv: 'SHRT' }],
      [DeliveryQualifierCd.APPT, { full: 'Hold For Appointment', abbrv: 'APPT' }],
      [DeliveryQualifierCd.ATTEMPTED, { full: 'Attempted', abbrv: 'ATMP' }],
      [DeliveryQualifierCd.CARTAGE, { full: 'Cartage', abbrv: 'CRTG' }],
      [DeliveryQualifierCd.DAMAGED, { full: 'Damaged Shipment', abbrv: 'RDMG' }],
      [DeliveryQualifierCd.FINAL, { full: 'Final Delivery', abbrv: 'FINL' }],
      [DeliveryQualifierCd.HOLD_FOR_CUSTOMS, { full: 'Hold For Customs', abbrv: 'CSTM' }],
      [DeliveryQualifierCd.OVER_SHPMT, { full: 'Over Shipment', abbrv: 'OVER' }],
      [DeliveryQualifierCd.PARTIAL_SHORT, { full: 'Partial Short', abbrv: 'PSRT' }],
      [DeliveryQualifierCd.PRG_BLOCKED, { full: 'Purge Blocked', abbrv: 'PBLK' }],
      [DeliveryQualifierCd.REFUSED, { full: 'Refused', abbrv: 'RFSD' }],
      [DeliveryQualifierCd.NOT_APPLICABLE, { full: 'Not Applicable', abbrv: 'N/A' }],
      [DeliveryQualifierCd.RETURNED, { full: 'Return Status', abbrv: 'RTRN' }],
      [DeliveryQualifierCd.SPOTTED, { full: 'On Spotted Trailer', abbrv: 'SPOT' }],
      [DeliveryQualifierCd.SUSPENSE, { full: 'Suspense', abbrv: 'SUSP' }],
      [DeliveryQualifierCd.TRANSFER, { full: 'Transfer', abbrv: 'TSFR' }],
      [DeliveryQualifierCd.TRAPPED, { full: 'Trapped', abbrv: 'TRAP' }],
      [DeliveryQualifierCd.UNDELIVERED, { full: 'Undelivered', abbrv: 'UNDL' }],
      [DeliveryQualifierCd.WAIT, { full: 'Wait', abbrv: 'WAIT' }],
    ]);
    let deliveryQualifierCdMappedValue: string = value;
    const qualifierCdMapValue = deliveryQualifierCdMap.get(value);

    if (!qualifierCdMapValue) {
      deliveryQualifierCdMappedValue = value;
    } else if (useAbbrv) {
      deliveryQualifierCdMappedValue = qualifierCdMapValue.abbrv;
    } else {
      deliveryQualifierCdMappedValue = qualifierCdMapValue.full;
    }

    return deliveryQualifierCdMappedValue;
  }
}
