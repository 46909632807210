import { AfterContentInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment } from 'moment';
import { XpoBaseDatePickerDirective } from './datepicker-base.directive';

const XPO_YEAR_PICKER_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Directive({
  selector: '[xpoYearPicker]',
  host: {
    class: 'xpo-DatePicker',
  },
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: XPO_YEAR_PICKER_FORMATS },
  ],
})
export class XpoYearPickerDirective extends XpoBaseDatePickerDirective implements AfterContentInit {
  // TODO: Remove and only keep constructor in parent class using empty @Directive decorator (NGXLTL-732)
  constructor(protected element: ElementRef, protected renderer: Renderer2) {
    super(element, renderer);
  }

  ngAfterContentInit(): void {
    super.ngAfterContentInit();

    this.datePickerMatInput.placeholder = XPO_YEAR_PICKER_FORMATS.display.dateInput;

    this.datePicker.startView = 'multi-year';
    this.datePicker.yearSelected.subscribe((selectedYear: Moment) => this.onYearSelected(selectedYear));
  }

  private onYearSelected(selectedMonth: Moment): void {
    const yearValue = this.datePickerInput.value;
    yearValue.year(selectedMonth.year());
    this.datePicker.select(yearValue);
    this.datePicker.close();
  }
}
