import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class XpoLtlAuthConfigLoaderService {
  appCode: string = '';
  scopes: string[] = [];
  isProd: boolean = false;
  apiUrl: string = '';
  consumerKey: string;
  consumerSecret: string;
  grantType: string = '';
  appName: string = '';
  region: string = '';
  appRootUri: string = '';
  scopeOptions: string[] = [];

  initSettings(filePath: string): void {
    const json = JSON.parse(this.loadConfigFile(filePath, 'application/json'));
    this.appCode = json['authAppCode'];
    this.scopes = json['authScopes'];
    this.isProd = json['authIsProd'];
    this.apiUrl = json['apiUrl'];
    this.grantType = json['authGrantType'];
    this.appName = json['appName'];
    this.region = json['region'];
    this.appRootUri = json['authAppRootUri'];
    this.scopeOptions = json['scopeOptions'];
    if (this.scopeOptions && !Array.isArray(this.scopeOptions)) {
      this.scopeOptions = [this.scopeOptions];
    }
  }

  /**
   * @deprecated since v8.0.0, please use initSettings instead.
   * @param filePath
   */
  getSettings(filePath): void {
    const json = JSON.parse(this.loadConfigFile(filePath, 'application/json'));
    this.appCode = json['authAppCode'];
    this.scopes = json['authScopes'];
    this.isProd = json['authIsProd'];
    this.apiUrl = json['apiUrl'];
    this.grantType = json['authGrantType'];
    this.appName = json['appName'];
    this.region = json['region'];
    this.appRootUri = json['authAppRootUri'];
    this.scopeOptions = json['scopeOptions'];
    if (this.scopeOptions && !Array.isArray(this.scopeOptions)) {
      this.scopeOptions = [this.scopeOptions];
    }
  }

  loadConfigFile(filePath, mimeType): string {
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open('GET', filePath, false);
    if (mimeType != null) {
      if (xmlhttp.overrideMimeType) {
        xmlhttp.overrideMimeType(mimeType);
      }
    }
    xmlhttp.send();
    if (xmlhttp.status === 200) {
      return xmlhttp.responseText;
    } else {
      return null;
    }
  }
}
