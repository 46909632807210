import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class XpoLtlBrowserCacheService {
  constructor() {}

  /**
   * Uses the browser's "localStorage" to cache a value as a JSON string.
   * When "isSingleSession" is true, then "sessionStorage" will be used instead.
   * (see: https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage |
   * https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage)
   */
  setCacheValue(key: string, value: any, isSingleSession: boolean = false): void {
    if (key.trim().length > 0 && value !== null && value !== undefined) {
      if (isSingleSession) {
        window.sessionStorage.setItem(key, JSON.stringify(value));
      } else {
        window.localStorage.setItem(key, JSON.stringify(value));
      }
    }
  }

  /**
   * Uses the browser's "localStorage" to retrieve a value from the cache and returns the parsed JSON object
   * If "isSingleSession" is true, then the value is retrieved from "sessionStorage".
   * (see: https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage |
   * https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage)
   */
  getCacheValue(key: string, isSingleSession: boolean = false): any {
    if (key.trim().length > 0) {
      if (isSingleSession) {
        return JSON.parse(window.sessionStorage.getItem(key));
      } else {
        return JSON.parse(window.localStorage.getItem(key));
      }
    }
  }

  /**
   * Removes the value from cache based on the key provided. If "isSingleSession" param is true,
   * then "sessionStorage" will be used. Otherwise
   * "localStorage" is used.
   * (see: https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage |
   * https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage)
   */
  purgeCacheValue(key: string, isSingleSession: boolean = false): any {
    if (key.trim().length > 0) {
      if (isSingleSession) {
        window.sessionStorage.removeItem(key);
      } else {
        window.localStorage.removeItem(key);
      }
    }
  }
}
