import { Component } from '@angular/core';
import { StatusColors } from '@xpo-ltl/ngx-ltl-core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { StatusEnum } from './status-cell-renderer.enum';

@Component({
  selector: 'module-groups-status-cell-renderer',
  templateUrl: './status-cell-renderer.component.html',
  styleUrls: ['./status-cell-renderer.component.scss'],
  host: {
    class: 'ModuleGroupsStatusCellRenderer',
  },
})
export class ModuleGroupsStatusCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  statusText: string;
  statusColor: string;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.statusText = this.params.value;
    this.statusColor = this.getStatusColor(this.statusText);
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  getStatusColor(statusText: string): string {
    const status = statusText ? statusText.toLowerCase() : '';
    switch (status) {
      case StatusEnum.RUNNING:
        return StatusColors.colors.blue;
      case StatusEnum.SUCCEED:
        return StatusColors.colors.green;
      case StatusEnum.FAILED:
        return StatusColors.colors.red;
      case StatusEnum.SCHEDULED:
        return StatusColors.colors.purple;
      default:
        return StatusColors.colors.grey;
    }
  }
}
