import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'xpo-dialog-close-button',
  templateUrl: './dialog-close-button.component.html',
  styleUrls: ['./dialog-close-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-DialogCloseButton' },
})
export class XpoDialogCloseButtonComponent {
  constructor(private dialogRef: MatDialogRef<any>) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
