export class DocumentDetails {
  get showNavigation() {
    return this.totalPages > 1;
  }
  document: Uint8Array;
  filename: string;
  contentType: string;

  currentPage = 1;
  totalPages = 1;
  zoomLevel = 1;
  pageRotation = 0;
}
