import { Directive } from '@angular/core';

/**
 * @deprecated use xpoAlternateTabs
 */
@Directive({
  selector: '[xpo-primary-tabs]',
  host: { class: 'xpo-PrimaryTabs' },
})
export class XpoPrimaryTabs {
  constructor() {
    console.warn('WARN: xpo-primary-tabs has been deprecated, please use xpoAlternativeTabs');
  }
}
