import { Component, EventEmitter, Output } from '@angular/core';

/**
 * Application Switcher toggle button
 */
@Component({
  selector: 'xpo-application-switcher-toggle',
  templateUrl: './application-switcher-toggle.component.html',
  styleUrls: ['./application-switcher-toggle.component.scss'],
  host: {
    class: 'xpo-ApplicationSwitcherToggle'
  }
})
export class ApplicationSwitcherToggleComponent {

  /** Emit when user clicks on toggle button */
  @Output() click: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Handles when user clicks on toggle button
   */
  onClick(): void {
    this.click.emit();
  }
}
