import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { XpoDashbarMetricPopover } from './dashbar-metric/index';
import { DashbarService } from './dashbar.service';

@Component({
  selector: 'xpo-dashbar',
  templateUrl: 'dashbar.component.html',
  styleUrls: ['dashbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-Dashbar' },
  providers: [DashbarService],
})
export class XpoDashbar {
  constructor(private dashbarService: DashbarService) {}

  get metricPopover$(): Observable<XpoDashbarMetricPopover> {
    return this.dashbarService.openedPopover$;
  }
}
