import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { XpoHorizontalStepperComponent } from './horizontal-stepper.component';
import { XpoHorizontalStepsHeaderComponent } from './horizontal-steps-header/horizontal-steps-header.component';
import { XpoStepLeftButtonsDirective } from './step-buttons/directives/left-buttons.directive';
import { XpoStepperNextDirective } from './step-buttons/directives/next-button.directive';
import { XpoStepperPreviousDirective } from './step-buttons/directives/previous.button.directive';
import { XpoStepRightButtonsDirective } from './step-buttons/directives/right-buttons.directive';
import { XpoStepButtonsComponent } from './step-buttons/step-buttons.component';
import { XpoStepHeaderComponent } from './step-header/step-header.component';
import { XpoStepComponent } from './step/step.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatStepperModule, ScrollingModule],
  declarations: [
    XpoStepHeaderComponent,
    XpoHorizontalStepsHeaderComponent,
    XpoStepComponent,
    XpoStepButtonsComponent,
    XpoHorizontalStepperComponent,
    XpoStepperNextDirective,
    XpoStepperPreviousDirective,
    XpoStepLeftButtonsDirective,
    XpoStepRightButtonsDirective,
  ],
  exports: [
    XpoStepComponent,
    XpoStepButtonsComponent,
    XpoHorizontalStepperComponent,
    XpoStepperNextDirective,
    XpoStepperPreviousDirective,
    XpoStepLeftButtonsDirective,
    XpoStepRightButtonsDirective,
  ],
})
export class XpoStepperModule {}
