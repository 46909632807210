import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';

import { XpoDateFilterComponentBase, XpoFilterCriteria } from '../models/index';
import { XpoDateRangeFilterCriteria, XpoQuickDate, XpoQuickFilter } from './date-range-model/index';

@Component({
  selector: 'xpo-date-range-filter',
  templateUrl: 'date-range-filter.component.html',
  styleUrls: ['date-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-DateRangeFilter', '[attr.id]': 'id' },
})
export class XpoDateRangeFilterComponent extends XpoDateFilterComponentBase {
  readonly quickFilterOptions: XpoQuickFilter[] = [
    { code: XpoQuickDate.Yesterday, display: 'Yesterday' },
    { code: XpoQuickDate.Today, display: 'Today' },
    { code: XpoQuickDate.ThisWeek, display: 'This Week' },
    { code: XpoQuickDate.NextWeek, display: 'Next Week' },
  ];

  /** The minimum date to search for */
  fromDateValue: Date;
  /** The max date to search for */
  toDateValue: Date;

  constructor(protected cd: ChangeDetectorRef) {
    super(cd);
  }

  onToCalendarChanged(value: Date): void {
    this.toDateValue = value;

    // make sure the min-date is not greater than the max data
    const minDate = this.fromDateValue && this.fromDateValue > this.toDateValue ? this.toDateValue : this.fromDateValue;
    this.onCalendarChanged(minDate, this.toDateValue);
  }

  onFromCalendarChanged(value: Date): void {
    this.fromDateValue = value;

    // make sure the min-date is not greater than the max data
    const maxDate = this.toDateValue && this.fromDateValue > this.toDateValue ? this.fromDateValue : this.toDateValue;
    this.onCalendarChanged(this.fromDateValue, maxDate);
  }

  protected onCriteriaModified(fieldValue: object, criteria: XpoFilterCriteria): void {
    const val = <XpoDateRangeFilterCriteria>fieldValue || <XpoDateRangeFilterCriteria>{};

    this.toDateValue = val.max;
    this.fromDateValue = val.min;

    this.cd.markForCheck();
  }

  protected onCalendarChanged(min: Date, max: Date): void {
    this.storeCriteria({
      max: max,
      min: min,
      selected: 'date',
    });
  }

  protected updateCalendar(): void {
    /**
     * we need to update with minDate and maxDate the values in order to show up if it's a past DATE
     */
    this.minDate = this.currentCriteria.min;
    this.maxDate = this.currentCriteria.max;

    this.fromDateValue = this.currentCriteria.min;
    this.toDateValue = this.currentCriteria.max;

    this.cd.markForCheck();
  }
}
