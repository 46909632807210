import { HorizontalConnectionPos, VerticalConnectionPos } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ICellRendererParams } from 'ag-grid-community';
import { XpoGridColumnsUtil } from '../../util/column-util';

interface RowData {
  colName: string;
  value: string;
}
@Component({
  selector: 'xpo-transpose-popover-cell-renderer',
  templateUrl: './transpose-popover-cell-renderer.component.html',
  styleUrls: ['./transpose-popover-cell-renderer.component.scss'],
})
export class TransposePopoverCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  position: TooltipPosition = 'after';
  caretPosition: HorizontalConnectionPos | VerticalConnectionPos = 'center';
  color: ThemePalette = 'primary';
  rowData: RowData[];
  rowIndex: number;

  agInit(params: ICellRendererParams): void {
    const mappedColumns = [];

    params.columnApi.getAllDisplayedColumns().forEach((col) => {
      const colDef: ColDef = col.getColDef();
      const colRenderedValue = XpoGridColumnsUtil.getColumnRenderedValue(colDef, params);

      if (colRenderedValue) {
        mappedColumns.push({ colName: colDef.headerName, value: colRenderedValue });
      }
    });
    this.rowData = mappedColumns;
    this.rowIndex = params.rowIndex;
  }

  refresh(): boolean {
    return false;
  }

  mapRowValues(rowData: any): RowData[] {
    const mappedValues = [];
    Object.keys(rowData).forEach((key) => {
      mappedValues.push({ colName: key, value: rowData[key] });
    });
    return mappedValues;
  }
}
