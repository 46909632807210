import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { cloneDeep} from 'lodash';
import { XpoFacetFilterTypeControls } from '../../share/enums/type-list-control';
import { XpoFacetFilterCriteria } from '../../share/model/facet-model-criteria.interface';
import { XpoFacetFilterNumberOperators, XpoFacetFilterTypeOperator } from '../../share/model/filter-criteria.interface';
import { XpoFacetFilterRangeTimeModel } from '../../share/model/range-time.interface';
import { XpoFilterControlBaseComponent } from '../filter-control-base.component';

@Component({
  selector: 'xpo-time-range-facet-filter',
  templateUrl: './filter-control-time-range.component.html',
  styleUrls: ['./filter-control-time-range.component.scss'],
  providers: [{ provide: XpoFilterControlBaseComponent, useExisting: forwardRef(() => XpoFacetFilterRangeTimeControlComponent) }],
})
export class XpoFacetFilterRangeTimeControlComponent
  extends XpoFilterControlBaseComponent<XpoFacetFilterRangeTimeModel> implements OnInit {

  originalFilterModel: XpoFacetFilterRangeTimeModel;
  typeControl: XpoFacetFilterTypeControls = XpoFacetFilterTypeControls.RangeMinMax;

  @Input()
  filterModel: XpoFacetFilterRangeTimeModel = {
    minValue: { hour: undefined, minutes: undefined },
    maxValue: { hour: undefined, minutes: undefined },
  } as XpoFacetFilterRangeTimeModel;

  get timeFrom(): string {
    return `${this.filterModel?.minValue?.hour}:${this.filterModel?.minValue?.minutes}`;
  }
  get timeTo(): string {
    return `${this.filterModel?.maxValue?.hour}:${this.filterModel?.maxValue?.minutes}`;
  }
  set timeFrom(value: string) {
    this.filterModel.minValue.hour =  +`${value.substring(0, 2)}`;
    this.filterModel.minValue.minutes =  +`${value.substring(3, 5)}`;
  }
  set timeTo(value: string) {
    this.filterModel.maxValue.hour =  +`${value.substring(0, 2)}`;
    this.filterModel.maxValue.minutes =  +`${value.substring(3, 5)}`;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.checkInit();
    this.originalFilterModel = cloneDeep(this.filterModel);
  }

  setModel(model: XpoFacetFilterRangeTimeModel): void {
    this.filterModel = model;
    this.originalFilterModel = cloneDeep(this.filterModel);
  }

  applyFilter(): void {
    this.onValueChanged(this.filterModel, this.mapFacetFilterControlToCriteria(cloneDeep(this.filterModel)));
  }

  validateKey($event: KeyboardEvent): void {
    if ($event.code === 'Enter') {
      this.applyFilter();
    }
  }

  onReset(): void {
    super.onReset();
    this.filterModel = cloneDeep(this.originalFilterModel);
    this.applyFilter();
  }

  mapFacetFilterControlToCriteria(val: XpoFacetFilterRangeTimeModel): XpoFacetFilterCriteria {
    const operator: XpoFacetFilterNumberOperators = XpoFacetFilterNumberOperators.BETWEEN;
    return {
      filterId: this.filterId,
      type: XpoFacetFilterTypeOperator.XpoFacetFilterTimeValueOperator,
      conditions: [{ operator, value: val.minValue, valueTo: val.maxValue }],
      invalid: !this.isValidCondition(),
    };
  }

  isValidCondition(): boolean {
    return (this.filterModel.maxValue?.hour !== undefined && this.filterModel.minValue?.hour !== undefined
      && this.filterModel.maxValue?.minutes !== undefined && this.filterModel.minValue?.minutes !== undefined);
  }
}
