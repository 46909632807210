import { CriteriaFunction } from './criteria-function.model';

export class PrimitiveCriteriaFunction implements CriteriaFunction {
  supportsValue(value: any): boolean {
    if (typeof value === 'object') {
      return value === null;
    }
    return typeof value !== 'function';
  }

  getFilter<T>(key: string, value: any): (t: T) => boolean {
    return (t: T) => t[key] === value;
  }
}
