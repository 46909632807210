import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { DEFAULT_FILER_STRATEGY, XpoApplyFilterStrategy } from './models/apply-filter-strategy';
import { XpoFilter } from './models/filter';
import { XpoFilterCriteria } from './models/filter-criteria-type';

let nextUniqueId = 0;

export interface XpoCriteriaStore {
  readonly criteria$: Observable<XpoFilterCriteria>;
  id: string;
  applyFilterStrategy: XpoApplyFilterStrategy;
  setFieldCriteria(field: string, val: XpoFilterCriteria): void;
  clearFieldCriteria(field: string): void;
}

@Injectable()
export class XpoFiltersService implements XpoCriteriaStore {
  private uid = `xpo-Filters-${nextUniqueId++}`;
  private idValue: string;
  private readonly criteriaSource$: BehaviorSubject<XpoFilterCriteria>;
  private readonly pendingCriteriaSource$: BehaviorSubject<XpoFilterCriteria>;
  private applyFilterStrategyValue: XpoApplyFilterStrategy = DEFAULT_FILER_STRATEGY;

  constructor() {
    this.criteriaSource$ = new BehaviorSubject(null);
    this.pendingCriteriaSource$ = new BehaviorSubject(null);
  }

  get criteria(): XpoFilterCriteria {
    return this.criteriaSource$.getValue();
  }

  get criteria$(): Observable<XpoFilterCriteria> {
    return this.criteriaSource$.asObservable();
  }

  get pendingCriteria(): XpoFilterCriteria {
    return this.pendingCriteriaSource$.getValue();
  }

  get pendingCriteria$(): Observable<XpoFilterCriteria> {
    return this.pendingCriteriaSource$.asObservable();
  }

  /** Id used to uniquely identify components inside this instance of the filter bar */
  get id(): string {
    return this.idValue;
  }
  set id(value: string) {
    this.idValue = value || this.uid;
  }

  get applyFilterStrategy(): XpoApplyFilterStrategy {
    return this.applyFilterStrategyValue;
  }
  set applyFilterStrategy(applyStrategy: XpoApplyFilterStrategy) {
    this.applyFilterStrategyValue = applyStrategy || DEFAULT_FILER_STRATEGY;
  }

  apply(): any {
    this.setCriteria(this.criteriaSource$.getValue());
  }

  setCriteria(criteria: XpoFilterCriteria, filters: XpoFilter[] = null): void {
    const c = criteria || {};
    this.initializeWithFilterDefaults(c, filters || []);
    this.criteriaSource$.next(c);
  }

  /** Sets current criteria that may have not been applied yet based on the applyFilterStrategy */
  setPendingCriteria(field: string, val: XpoFilterCriteria): void {
    const obj = Object.assign({}, this.pendingCriteria);
    obj[field] = val;
    this.pendingCriteriaSource$.next(obj);
  }

  setFieldCriteria(field: string, val: any): void {
    const obj = Object.assign({}, this.criteriaSource$.getValue());
    obj[field] = val;
    this.criteriaSource$.next(obj);
  }

  clearFieldCriteria(field: string): void {
    const currentCriteria = Object.assign({}, this.criteriaSource$.getValue());
    delete currentCriteria[field];
    this.criteriaSource$.next(currentCriteria);
  }

  reset(initialCriteria: XpoFilterCriteria): void {
    this.criteriaSource$.next(initialCriteria || {});
  }

  /** Initialize the criteria object for the default value for each filter */
  private initializeWithFilterDefaults(criteria: XpoFilterCriteria, filters: XpoFilter[]): void {
    filters.forEach((f) => {
      const filterValue = f.getInitialFilterValue(criteria[f.field] || null);
      if (filterValue != null) {
        criteria[f.field] = filterValue;
      }
    });
  }
}
