import {XFlowFrequencyCd} from '../';
import {EnumHelper} from './enum-helper';

export class XFlowFrequencyCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in XFlowFrequencyCd ) {
      this._values.push(XFlowFrequencyCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): XFlowFrequencyCd {

    for ( const obj in XFlowFrequencyCd ) {
      if (XFlowFrequencyCd[obj] === value){
        return XFlowFrequencyCd[obj] as XFlowFrequencyCd;
      }
    }
  }
}

const XFlowFrequencyCdHelper = new XFlowFrequencyCdHelperEnumHelperClass();
export default XFlowFrequencyCdHelper;
