import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { GetOdsShipmentResp, SuppRefNbr } from '@xpo-ltl/sdk-shipmentods';
import { filter as _filter, find as _find } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '../../../classes/unsubscriber';
import { ShipmentReferenceService } from '../../shipment-reference.service';

@Component({
  selector: 'xpo-ltl-shipment-details-srns',
  templateUrl: './shipment-details-srns.component.html',
  styleUrls: ['./shipment-details-srns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentDetailsSrnsComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();

  private srnsSubject = new BehaviorSubject<SuppRefNbr[]>([]);
  readonly srns$ = this.srnsSubject.asObservable();

  constructor(private shipmentRefService: ShipmentReferenceService) {}

  ngOnInit() {
    this.shipmentRefService.shipment$
      .pipe(takeUntil(this.unsubscriber.done$), distinctUntilChanged())
      .subscribe((shipment) => {
        this.updateFormFromShipment(shipment);
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private updateFormFromShipment(shipment: GetOdsShipmentResp) {
    if (!shipment) {
      return;
    }

    const firstPOSrn = _find(shipment.suppRefNbr, (srn) => srn.typeCd === 'PO#');
    this.srnsSubject.next(_filter(shipment.suppRefNbr, (srn) => srn.typeCd !== 'SN#' && srn !== firstPOSrn));
  }
}
