import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';

export interface BrowserGuardDialogData {
  appName: string;
  compatibleBrowsers: string;
  appRedirectUrl: string;
}

@Component({
  selector: 'xpo-browser-guard-dialog',
  templateUrl: './browser-guard-dialog.component.html',
  styleUrls: ['./browser-guard-dialog.component.scss'],
})
export class BrowserGuardDialogComponent {
  appUrl = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BrowserGuardDialogData,
    private clipboardService: ClipboardService
  ) {
    this.appUrl = data.appRedirectUrl ? data.appRedirectUrl : window.location.href;
  }

  getHref(browser: string): string {
    if (browser === 'CHROME') {
      return 'http://www.google.com/chrome';
    } else if (browser === 'IE' || browser === 'EDGE') {
      return 'https://www.microsoft.com/en-us/windows/microsoft-edge';
    } else {
      return 'https://www.google.com/search?q=browser';
    }
  }

  saveClicked(): void {
    this.clipboardService.copyFromContent(this.appUrl);
  }
}
