import {XFlowNotificationTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class XFlowNotificationTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in XFlowNotificationTypeCd ) {
      this._values.push(XFlowNotificationTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): XFlowNotificationTypeCd {

    for ( const obj in XFlowNotificationTypeCd ) {
      if (XFlowNotificationTypeCd[obj] === value){
        return XFlowNotificationTypeCd[obj] as XFlowNotificationTypeCd;
      }
    }
  }
}

const XFlowNotificationTypeCdHelper = new XFlowNotificationTypeCdHelperEnumHelperClass();
export default XFlowNotificationTypeCdHelper;
