import { Pipe, PipeTransform } from '@angular/core';
import { ExemptReasonCd } from '@xpo-ltl/sdk-common';

@Pipe({ name: 'exemptReasonCd' })
export class ExemptReasonCdPipe implements PipeTransform {
  transform(value: ExemptReasonCd): any {
    let displayValue = '';
    switch (value) {
      case ExemptReasonCd.ACCIDENT:
        displayValue = 'Accident';
        break;
      case ExemptReasonCd.CUSTOMS:
        displayValue = 'Customs';
        break;
      case ExemptReasonCd.DEFERRED:
        displayValue = 'Deferred';
        break;
      case ExemptReasonCd.NOT_EXEMPT:
        displayValue = 'Not Exempt';
        break;
      case ExemptReasonCd.OTHER:
        displayValue = 'Other';
        break;
      case ExemptReasonCd.PREFER_VOL_SHPMT:
        displayValue = 'Preferred Volume Shipment';
        break;
      case ExemptReasonCd.ROAD_CLOSURE:
        displayValue = 'Road Closure';
        break;
      case ExemptReasonCd.TORNADO:
        displayValue = 'Tornado';
        break;
      case ExemptReasonCd.WEATHER:
        displayValue = 'Weather';
        break;
      default:
        displayValue = value;
    }
    return displayValue;
  }
}
