import {PhotoTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class PhotoTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PhotoTypeCd ) {
      this._values.push(PhotoTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PhotoTypeCd {

    for ( const obj in PhotoTypeCd ) {
      if (PhotoTypeCd[obj] === value){
        return PhotoTypeCd[obj] as PhotoTypeCd;
      }
    }
  }
}

const PhotoTypeCdHelper = new PhotoTypeCdHelperEnumHelperClass();
export default PhotoTypeCdHelper;
