import {DisputeAreaCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeAreaCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeAreaCd ) {
      this._values.push(DisputeAreaCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeAreaCd {

    for ( const obj in DisputeAreaCd ) {
      if (DisputeAreaCd[obj] === value){
        return DisputeAreaCd[obj] as DisputeAreaCd;
      }
    }
  }
}

const DisputeAreaCdHelper = new DisputeAreaCdHelperEnumHelperClass();
export default DisputeAreaCdHelper;
