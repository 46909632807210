import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { MatIconModule } from '@angular/material/icon';
import { XpoButtonModule, XpoPopoverModule } from '@xpo-ltl/ngx-ltl-core';
import { DefaultAuthEventHandlerProvider, XpoAuthModule } from '@xpo/ngx-auth';
import { AUTH_SERVICE, AuthModule, PROTECTED_FALLBACK_PAGE_URI, PUBLIC_FALLBACK_PAGE_URI } from 'ngx-auth';
import { AuthAppSwitcherComponent } from './auth-app-switcher/auth-app-switcher.component';
import {
  AuthUserProfileComponent,
  XpoAuthUserProfileActionsDirective,
  XpoAuthUserProfileLinksDirective,
} from './auth-user-profile/auth-user-profile.component';
import { XpoLtlAuthenticationService } from './authentication/authentication.service';

@NgModule({
  declarations: [
    AuthAppSwitcherComponent,
    AuthUserProfileComponent,
    XpoAuthUserProfileLinksDirective,
    XpoAuthUserProfileActionsDirective,
  ],
  imports: [
    AngularFirestoreModule,
    CommonModule,
    XpoAuthModule,
    AuthModule,
    MatIconModule,
    XpoPopoverModule,
    XpoButtonModule,
  ],
  providers: [
    { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/' },
    { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/login' },
    { provide: AUTH_SERVICE, useClass: XpoLtlAuthenticationService },
  ],
  exports: [
    XpoAuthModule,
    AuthAppSwitcherComponent,
    AuthUserProfileComponent,
    XpoAuthUserProfileLinksDirective,
    XpoAuthUserProfileActionsDirective,
  ],
})
export class XpoLtlAuthModule {
  static forRoot(xpoAuthConfigProvider: Provider): ModuleWithProviders {
    return {
      ngModule: XpoLtlAuthModule,
      providers: [xpoAuthConfigProvider, DefaultAuthEventHandlerProvider],
    };
  }
}
