import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Directive,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import _isEqual from 'lodash-es/isEqual';
import { filter, takeUntil } from 'rxjs/operators';
import { XpoBoardApiAction, XpoBoardApiDispatcherService } from '../../board/board-api/index';
import { XpoBoardConsumer, XpoBoardDataSourceResolver, XpoBoardState, XpoBoardViewUtil } from '../../models/index';
import { XpoFilterChip } from '../filter-chip/filter-chip.component';
import { XpoFiltersService } from '../filters.service';
import { XpoApplyFilterStrategy, XpoFilter, XpoFilterCriteria, XpoInlineFilter } from '../models/index';

@Directive({
  selector: '[xpo-filter-host]',
})
export class XpoFilterHost {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

export function filtersServiceFactory(parentFiltersService: XpoFiltersService): XpoFiltersService {
  return parentFiltersService ?? new XpoFiltersService();
}

@Component({
  selector: 'xpo-filter-bar',
  templateUrl: 'filter-bar.component.html',
  styleUrls: ['filter-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: XpoFiltersService,
      useFactory: filtersServiceFactory,
      deps: [[new SkipSelf(), new Optional(), XpoFiltersService]],
    },
  ],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-FilterBar', '[attr.id]': 'id' },
})
export class XpoFilterBar extends XpoBoardConsumer implements OnInit, OnDestroy {
  /** Input filter object that will be dynamically generated */
  private previousCriteria: XpoFilterCriteria = null;
  private currentViewId: string;
  private initialCriteriaList: { [viewId: string]: XpoFilterCriteria } = {};

  /** Child filter components container */
  @ViewChild(XpoFilterHost, { static: true })
  host: XpoFilterHost;

  /** Filters that will be generated */
  @Input()
  get filters(): XpoFilter[] {
    return this.filtersValue;
  }
  set filters(v: XpoFilter[]) {
    this.filtersValue = v;
    this.createFilterChips();
  }
  private filtersValue: XpoFilter[];

  /** Filter Criteria Value */
  @Input()
  get criteria(): XpoFilterCriteria {
    return this.criteriaValue;
  }
  set criteria(v: XpoFilterCriteria) {
    if (!_isEqual(this.criteriaValue, v)) {
      // no need to publish the change event because the filterService.criteria change
      // subscribe from the init method will handle this scenario
      this.filtersService.setCriteria((this.criteriaValue = v));
    }
  }
  private criteriaValue: XpoFilterCriteria = {};

  @Output() criteriaChange = new EventEmitter<XpoFilterCriteria>();

  @Input()
  get applyFilterStrategy(): XpoApplyFilterStrategy {
    return this.filtersService.applyFilterStrategy;
  }
  set applyFilterStrategy(config: XpoApplyFilterStrategy) {
    this.filtersService.applyFilterStrategy = config;
  }

  @Input()
  get id(): string {
    return this.filtersService.id;
  }
  set id(value: string) {
    this.filtersService.id = value;
  }

  /**
   *  The reset button should restore all filter values back to the previous saved values in the view.
   */
  @Input()
  get enableFilterReset(): boolean {
    return this.enableFilterResetValue;
  }
  set enableFilterReset(value: boolean) {
    this.enableFilterResetValue = coerceBooleanProperty(value);
  }
  private enableFilterResetValue = false;

  constructor(
    public filtersService: XpoFiltersService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    // Optional in for the case that the filter bar is not used outside the context of the board
    @Optional()
    boardDataSourceResolver: XpoBoardDataSourceResolver,
    @Optional()
    private boardApiDispatcherService: XpoBoardApiDispatcherService
  ) {
    super(boardDataSourceResolver, changeDetectorRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.filtersService.criteria$
      .pipe(
        filter((cr) => !_isEqual(this.previousCriteria, cr)), // make sure we don't re-echo view changes
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((c) => {
        // global filters should be applied only on click not on each change
        if (this.applyFilterStrategy !== 'global') {
          this.applyCriteriaChange(c);
        }
      });

    if (this.boardApiDispatcherService) {
      this.boardApiDispatcherService.registerHandler(XpoBoardApiAction.ApplyFiltersCriteria, (criteria) => {
        this.criteria = criteria;
      });

      this.boardApiDispatcherService.registerHandler(XpoBoardApiAction.ApplyFilterCriterion, (field, criterion) => {
        this.filtersService.setFieldCriteria(field, criterion);
      });
    }
  }

  onApplyClicked(): void {
    this.filtersService.apply();
    this.applyCriteriaChange(this.filtersService.criteria);
  }

  /**
   * Triggered when reset button is clicked
   */
  onResetClicked(): void {
    const initialCriteria = this.initialCriteriaList[this.currentViewId];
    this.filtersService.reset(initialCriteria);
    // Make sure we reset on click even for global which doesn't get applied from the change subscription
    if (this.applyFilterStrategy === 'global') {
      this.applyCriteriaChange(initialCriteria);
    }
  }

  protected onStateChange(state: XpoBoardState): void {
    // Update filer list when view changes
    if (state.changes.includes('availableViews') || state.changes.includes('viewId')) {
      const activeView = XpoBoardViewUtil.findActiveView(state);
      this.filters = activeView.visibleFilters;
    }

    if (!_isEqual(this.previousCriteria, state.criteria)) {
      this.previousCriteria = state.criteria;
      this.filtersService.setCriteria(state.criteria, this.filters);
    }

    // save the initial criteria if view changes
    if (this.currentViewId !== state.viewId) {
      this.currentViewId = state.viewId;

      // Save the initial criteria only the first time we activate the view
      if (!this.initialCriteriaList.hasOwnProperty(this.currentViewId)) {
        this.initialCriteriaList[this.currentViewId] = state.criteria;
      }
    }
  }

  /** Apply the filter criteria on the state*/
  private applyCriteriaChange(criteria: XpoFilterCriteria): void {
    this.stateChange$.next({
      criteria: criteria,
      pageNumber: 1,
      source: 'FILTER-CHANGE',
    });

    this.criteriaValue = criteria;
    this.criteriaChange.next(criteria);
  }

  /** Generates the child filter components. */
  private createFilterChips(): void {
    // Remove anything thats in the filter container
    this.host.viewContainerRef.clear();

    if (!this.filters) {
      return;
    }

    this.filters.filter(Boolean).forEach((filter_) => {
      // check whether or no the filter renders inline
      if (filter_ instanceof XpoInlineFilter) {
        this.createInlineFilter(filter_);
      } else {
        this.createFilter(filter_);
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  /**
   * Create Inline filter
   */
  private createInlineFilter(filterModel: XpoInlineFilter): void {
    // create the filter component
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(filterModel.filterComponentType);
    // Attach it to the container
    const componentRef = this.host.viewContainerRef.createComponent(componentFactory);
    // Get the component instance
    const component = componentRef.instance;
    // Assign data to the component
    component.configuration = filterModel;
    component.field = filterModel.field;
    component.filtersService = this.filtersService;
  }

  /**
   * Creates filter with filter chip
   */
  private createFilter(filterModel: XpoFilter): void {
    // Create Filter Chip
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(XpoFilterChip);
    // Attach it to the container
    const componentRef = this.host.viewContainerRef.createComponent(componentFactory);
    // Get the component instance of the newc
    const component = componentRef.instance;
    // Assign filter to filter chip
    component.filter = filterModel;
    component.criteriaStore = this.filtersService;
  }
}
