import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

import { XpoButtonModule, XpoDownloadButtonModule, XpoPopoverModule, XpoUtilsModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoBoardGridToolbarActions } from './board-grid-toolbar-actions/board-grid-toolbar-actions.component';
import {
  XpoBoardGridToolbarActionsMore,
  XpoBoardGridToolbarDynamicMore,
} from './board-grid-toolbar-actions/board-grid-toolbar-dynamic-more/board-grid-toolbar-dynamic-more.component';
import { XpoBoardGridToolbarSettings } from './board-grid-toolbar-settings/board-grid-toolbar-settings.component';
import { XpoSettingsFloatingFiltersComponent } from './board-grid-toolbar-settings/board-settings-default-buttons/floating-settings-default-buttons/floating-settings-default-buttons.component';
import { XpoGridSettingsColumnSelectionComponent } from './board-grid-toolbar-settings/board-settings-default-buttons/grid-settings-default-buttons/grid-settings-column-selection/grid-settings-column-selection.component';
import { XpoSettingsGridOptionsComponent } from './board-grid-toolbar-settings/board-settings-default-buttons/grid-settings-default-buttons/grid-settings-default-buttons.component';
import { XpoSettingsRowHeightComponent } from './board-grid-toolbar-settings/board-settings-default-buttons/row-height-settings-default-buttons/row-height-settings-default-buttons.component';
import { XpoBoardGridToolbar } from './board-grid-toolbar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    XpoUtilsModule,
    XpoDownloadButtonModule,
    XpoPopoverModule,
    XpoButtonModule,
  ],
  declarations: [
    XpoBoardGridToolbar,
    XpoBoardGridToolbarActions,
    XpoBoardGridToolbarActionsMore,
    XpoBoardGridToolbarDynamicMore,
    XpoBoardGridToolbarSettings,
    XpoSettingsGridOptionsComponent,
    XpoSettingsFloatingFiltersComponent,
    XpoGridSettingsColumnSelectionComponent,
    XpoSettingsRowHeightComponent,
  ],
  exports: [
    XpoBoardGridToolbar,
    XpoBoardGridToolbarActions,
    XpoBoardGridToolbarActionsMore,
    XpoBoardGridToolbarDynamicMore,
    XpoBoardGridToolbarSettings,
    XpoSettingsGridOptionsComponent,
    XpoSettingsFloatingFiltersComponent,
    XpoGridSettingsColumnSelectionComponent,
    XpoSettingsRowHeightComponent,
  ],
  entryComponents: [],
})
export class XpoGridToolbarModule {}
