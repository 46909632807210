import { AbstractControl } from '@angular/forms';

export function validateSteps(control: AbstractControl): { [key: string]: boolean } | null {
  let result = null;
  if (this.steps && this.steps.length > 0 && this.steps[0].rows.length === 0 && control.value !== 'Inactive') {
    result = {
      validateSteps: true,
    };
  }
  return result;
}
