import { FilterFormat } from '../../models/filter-format.interface';
import { FormatValidationsErrorMessages } from '../../models/format-validation-messages.enum';
import { filterFormats } from '../../models/format-validators';

export abstract class XpoConditionBase {
  isRequiredField = true;
  errors = {
    format: false,
    required: false,
    to: { format: false, required: false },
    from: { format: false, required: false },
  };
  errorMessage: string;
  private isEmpty = true;
  private format: FilterFormat;
  private isDateTime = false;
  abstract model: any;

  constructor(format: FilterFormat = filterFormats.default, isRequired: boolean = true, isDateTime: boolean = false) {
    this.format = format;
    this.isRequiredField = isRequired;
    this.isDateTime = isDateTime;
  }

  isValid(): boolean {
    this.validateFormat();
    this.validateRequired();
    return !this.errors.format && !this.errors.required;
  }

  validateFormat(): void {
    this.resetRequiredFlag();
    this.format.range ? this.validateRangeFormat() : this.validateSingleFormat();
  }

  changeFormat(format: FilterFormat): void {
    this.format = format;
  }

  private validateSingleFormat(): void {
    const isMatchingFormat = this.format.match(this.model);
    this.errors.format = !isMatchingFormat;
    this.errorMessageHandler(this.errors.format, FormatValidationsErrorMessages.InvalidFormat);
  }

  private validateRangeFormat(): void {
    this.validateSingleFormat();
    this.errors.from.format = !this.format.range.from(this.model);
    this.errors.to.format = !this.format.range.to(this.model);
  }

  private validateRequired(): void {
    this.errors.required = this.isEmptyRequired();
    this.errorMessageHandler(this.errors.required, FormatValidationsErrorMessages.FieldRequired);
  }

  private isEmptyRequired(): boolean {
    return this.isRequiredField && this.validateEmpty();
  }

  private validateEmpty(): boolean {
    if (this.format.range) {
      this.errors.from.required = this.isFieldEmpty(this.model.from);
      this.errors.to.required = this.isFieldEmpty(this.model.to);
      this.isEmpty = this.errors.to.required || this.errors.from.required;
    } else if (this.format.dateRange) {
      this.errors.from.required = this.isFieldEmpty(this.model.dateFrom);
      this.errors.to.required = this.isFieldEmpty(this.model.dateTo);
      this.isEmpty = this.errors.to.required || this.errors.from.required;
    } else {
      this.isEmpty = this.isFieldEmpty(this.model);
    }
    return this.isEmpty;
  }

  private isFieldEmpty(model): boolean {
    return (
      model === '' ||
      model === undefined ||
      model === null ||
      (!this.isDateTime && !model.length) ||
      ((this.isDateTime && !model.date) || model.time === '')
    );
  }

  private errorMessageHandler(error: boolean, errorMessage: string): void {
    if (error) {
      this.errorMessage = errorMessage;
    }
  }

  private resetRequiredFlag(): void {
    this.errors.required = false;
  }
}
