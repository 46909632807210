import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { INVALID_24HOUR_FORMAT_MSG, INVALID_AM_PM_FORMAT_MSG } from './exact-time-input-constants';
import { HourFormatErrorEvent } from './hour-format-error-event';

@Component({
  selector: 'xpo-exact-time-input',
  templateUrl: './exact-time-input.component.html',
  styleUrls: ['./exact-time-input.component.scss'],
})
export class ExactTimeInputComponent implements OnInit, OnDestroy {
  @Input() is24Hour: boolean;
  @Input() validationError: boolean;
  @Output() formatError = new EventEmitter();
  @Output() inputValue = new EventEmitter();
  @Input() model: string = '';

  formatErrorEvent: HourFormatErrorEvent;
  hour24 = new FormControl('', Validators.pattern('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$'));
  hour12 = new FormControl('', Validators.pattern('((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))'));
  private valueSubscription: Subscription;

  constructor() {}

  ngOnInit(): void {
    if (this.is24Hour) {
      this.valueSubscription = this.hour24.valueChanges.subscribe((value: string) => {
        if (!this.hour24.dirty) {
          return;
        }
        if (this.hour24.valid) {
          this.formatErrorEvent = {
            isErrorFormat: false,
            errorMsg: '',
          };
          this.formatError.emit(this.formatErrorEvent);
        } else {
          this.formatErrorEvent = {
            isErrorFormat: true,
            errorMsg: INVALID_24HOUR_FORMAT_MSG,
          };
          this.formatError.emit(this.formatErrorEvent);
        }

        if (this.hour24.value.length > 5) {
          this.hour24.setValue(this.hour24.value.slice(0, 5));
        }
        if (this.hour24.value.length > 2 && this.hour24.value.charAt(2) !== ':') {
          this.hour24.setValue(this.hour24.value.slice(0, 2) + ':' + this.hour24.value.slice(2));
        }
        this.inputValue.emit(this.hour24.value);
      });
    } else {
      this.valueSubscription = this.hour12.valueChanges.subscribe((value: string) => {
        if (!this.hour12.dirty) {
          return;
        }
        let eventValue: string;

        if (value) {
          if (this.hour12.valid) {
            this.formatErrorEvent = {
              isErrorFormat: false,
              errorMsg: '',
            };
            this.formatError.emit(this.formatErrorEvent);
          } else {
            this.formatErrorEvent = {
              isErrorFormat: true,
              errorMsg: INVALID_AM_PM_FORMAT_MSG,
            };
            this.formatError.emit(this.formatErrorEvent);
          }

          if (this.hour12.value.length > 8) {
            this.hour12.setValue(this.hour12.value.slice(0, 8));
          }
          if (this.hour12.value.length > 2 && this.hour12.value.charAt(2) !== ':') {
            this.hour12.setValue(this.hour12.value.slice(0, 2) + ':' + this.hour12.value.slice(2));
          }
          if (this.hour12.value.length > 5 && this.hour12.value.charAt(5) !== ' ') {
            this.hour12.setValue(this.hour12.value.slice(0, 5) + ' ' + this.hour12.value.slice(5));
          }

          eventValue = this.hour12.value;
        }

        this.inputValue.emit(eventValue);
      });
    }
  }

  ngOnDestroy(): any {
    this.valueSubscription.unsubscribe();
  }
}
