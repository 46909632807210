import {ARDebtorCd} from '../';
import {EnumHelper} from './enum-helper';

export class ARDebtorCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ARDebtorCd ) {
      this._values.push(ARDebtorCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ARDebtorCd {

    for ( const obj in ARDebtorCd ) {
      if (ARDebtorCd[obj] === value){
        return ARDebtorCd[obj] as ARDebtorCd;
      }
    }
  }
}

const ARDebtorCdHelper = new ARDebtorCdHelperEnumHelperClass();
export default ARDebtorCdHelper;
