import {DockManifestReportTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DockManifestReportTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DockManifestReportTypeCd ) {
      this._values.push(DockManifestReportTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DockManifestReportTypeCd {

    for ( const obj in DockManifestReportTypeCd ) {
      if (DockManifestReportTypeCd[obj] === value){
        return DockManifestReportTypeCd[obj] as DockManifestReportTypeCd;
      }
    }
  }
}

const DockManifestReportTypeCdHelper = new DockManifestReportTypeCdHelperEnumHelperClass();
export default DockManifestReportTypeCdHelper;
