import {AdvanceBeyondTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class AdvanceBeyondTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in AdvanceBeyondTypeCd ) {
      this._values.push(AdvanceBeyondTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): AdvanceBeyondTypeCd {

    for ( const obj in AdvanceBeyondTypeCd ) {
      if (AdvanceBeyondTypeCd[obj] === value){
        return AdvanceBeyondTypeCd[obj] as AdvanceBeyondTypeCd;
      }
    }
  }
}

const AdvanceBeyondTypeCdHelper = new AdvanceBeyondTypeCdHelperEnumHelperClass();
export default AdvanceBeyondTypeCdHelper;
