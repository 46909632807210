export enum XpoBrowserType {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Edge = 'Edge',
  InternetExplorer = 'InternetExplorer',
  Safari = 'Safari',
  Opera = 'Opera',
  Unknown = 'Unknown',
}

export enum XpoBrowserOs {
  Windows = 'Windows',
  Mac = 'Mac',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  iPhone = 'iPhone',
  Linux = 'Linux',
  Unknown = 'Unknown',
}
