import {WhatIfShipmentSelectionTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class WhatIfShipmentSelectionTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in WhatIfShipmentSelectionTypeCd ) {
      this._values.push(WhatIfShipmentSelectionTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): WhatIfShipmentSelectionTypeCd {

    for ( const obj in WhatIfShipmentSelectionTypeCd ) {
      if (WhatIfShipmentSelectionTypeCd[obj] === value){
        return WhatIfShipmentSelectionTypeCd[obj] as WhatIfShipmentSelectionTypeCd;
      }
    }
  }
}

const WhatIfShipmentSelectionTypeCdHelper = new WhatIfShipmentSelectionTypeCdHelperEnumHelperClass();
export default WhatIfShipmentSelectionTypeCdHelper;
