import { InjectionToken } from '@angular/core';

export interface ILoginConfig {
  appName: string;
  apiUrl: string;
  secretToken: string;
  scopeOptions: string;
}

export const LOGIN_CONFIG = new InjectionToken<ILoginConfig>( 'login.config' );
