import { Observable } from 'rxjs';

import { XpoSelectFilter } from './select-filter';

export class XpoSingleSelectFilter extends XpoSelectFilter {
  constructor(field: string, label: string, options: any[] | Observable<any[]> | Promise<any[]>) {
    super({ field, label, options, isSingleSelect: true });

    console.warn('WARN: `XpoSingleSelectFilter` is deprecated, use `XpoSelectFilter` instead');
  }
}
