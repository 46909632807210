export class ErrorMessageParser {
  public static parseMessage(err: any): string {
    let reason = '';
    try {
      let error = err['error'];
      if (error) {
        error = error['error'];
        if (error) {
          if (error.moreInfo && error.moreInfo.some(item => item.errorCode)) {
            const moreInfoItem = error.moreInfo.filter(info => info.errorCode)[0];
            reason = `${moreInfoItem.errorCode} ${moreInfoItem.message} ${moreInfoItem.location}`;
          } else if (error.errorCode && error.message) {
            reason = `${error.errorCode} ${error.message}`;
          } else {
            reason = 'Unknown error while validating PRO number';
          }
        }
      }
    } catch (err) {
      reason = 'Unable to parse error message';
    }
    return reason;
  }
}
