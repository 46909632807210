import { Injectable, OnDestroy } from '@angular/core';
import { XpoBoardApiActionsHandler } from './board-api-actions.model';

@Injectable()
export class XpoBoardApiDispatcherBase<T> implements OnDestroy {
  private registeredHandlers = new Map<T, XpoBoardApiActionsHandler>();

  registerHandler(action: T, handler: XpoBoardApiActionsHandler): void {
    this.registeredHandlers.set(action, handler);
  }

  executeAction(action: T, ...args: any[]): any {
    const handler: XpoBoardApiActionsHandler = this.registeredHandlers.get(action);
    if (!handler) {
      throw new Error(`No handler was registered for ${action} board action`);
    }

    return handler(...args);
  }

  ngOnDestroy(): void {
    this.registeredHandlers.clear();
  }
}
