import { AfterContentInit, Component, ContentChildren, Input, QueryList, ViewEncapsulation } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'xpo-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-CheckboxGroupHost' },
})
export class XpoCheckboxGroupComponent implements AfterContentInit {
  @ContentChildren(MatCheckbox) childrenCheckboxes: QueryList<MatCheckbox>;
  @Input() selectAllLabel = '';

  isChecked = false;
  isIndeterminate = false;

  onSelectAllChange(event: MatCheckboxChange): void {
    this.isChecked = event.checked;
    this.isIndeterminate = false;
    this.childrenCheckboxes.forEach((child) => {
      child.checked = event.checked;
    });
  }

  ngAfterContentInit(): void {
    // initialize state for main checkbox group
    this.isIndeterminate = this.isSelectAllIndeterminate();
    this.isChecked = this.isSelectAllChecked();

    // subscribe state change for each checkbox in the group
    this.childrenCheckboxes.forEach((checkbox) => {
      checkbox.change.subscribe(() => {
        // set main checkbox group state
        this.isIndeterminate = this.isSelectAllIndeterminate();
        this.isChecked = this.isSelectAllChecked();
      });
    });
  }

  private isSelectAllIndeterminate(): boolean {
    return (
      this.childrenCheckboxes.some((child) => child.checked) && this.childrenCheckboxes.some((child) => !child.checked)
    );
  }

  private isSelectAllChecked(): boolean {
    return this.childrenCheckboxes.toArray().every((child) => child.checked);
  }
}
