import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { XpoDashbarMetricPopover } from './dashbar-metric/dashbar-metric-popover.directive';

@Injectable()
export class DashbarService {
  private openedPopoverSubject$ = new ReplaySubject<XpoDashbarMetricPopover>(1);
  private openedPopoverValue: XpoDashbarMetricPopover;

  constructor() {}

  get openedPopover$(): Observable<XpoDashbarMetricPopover> {
    return this.openedPopoverSubject$.asObservable();
  }

  get openedPopover(): XpoDashbarMetricPopover {
    return this.openedPopoverValue;
  }

  set openedPopover(value: XpoDashbarMetricPopover) {
    this.openedPopoverSubject$.next((this.openedPopoverValue = value));
  }

  togglePopover(popover: XpoDashbarMetricPopover): void {
    if (this.openedPopover !== popover) {
      this.openedPopover = popover;
    } else {
      this.openedPopover = null;
    }
  }
}
