import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

/* Material */
import { MatSidenavModule } from '@angular/material/sidenav';

/* Xpo Modules */
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import {
  XpoButtonModule,
  XpoCardModule,
  XpoChipsModule,
  XpoConfirmDialogModule,
  XpoIconModule,
  XpoSnackBarModule,
  XpoStatusIndicatorModule,
  XpoTabsModule,
  XpoTooltipModule,
} from '@xpo-ltl/ngx-ltl-core';
import { SdkModuleExecutorModule } from '@xpo-ltl/sdk-moduleexecutor';

/* Ag-grid */
import { AgGridModule } from 'ag-grid-angular';

/* Components */
import { ModuleGroupsActionsCellRendererComponent } from './cell-renderers/actions-cell-renderer/actions-cell-renderer.component';
import { ModuleGroupsNameCellRendererComponent } from './cell-renderers/name-cell-renderer/name-cell-renderer.component';
import { ModuleGroupsStatusCellRendererComponent } from './cell-renderers/status-cell-renderer/status-cell-renderer.component';
import { ModuleGroupsBoardComponent } from './module-groups-board.component';
import { ListModuleGroupsService } from './services/list-module-groups.service';
import { ListModuleGroupsStoreService } from './services/list-module-groups.store.service';

/* Modules */
import { DateTimeCellRendererModule } from '../shared/cell-renderers/date-time-cell-renderer/date-time-cell-renderer.module';

/* Services */
import { ModuleGroupsPreviousRunsComponent } from './components/module-groups-previous-runs/module-groups-previous-runs.component';
import { ModuleGroupsTrailPanelComponent } from './components/trail-panel/trail-panel.component';
import { ModuleGroupsService } from './services/module-groups.service';

@NgModule({
  declarations: [
    ModuleGroupsBoardComponent,
    ModuleGroupsActionsCellRendererComponent,
    ModuleGroupsNameCellRendererComponent,
    ModuleGroupsStatusCellRendererComponent,
    ModuleGroupsPreviousRunsComponent,
    ModuleGroupsTrailPanelComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    XpoCardModule,
    XpoAgGridBoardModule,
    XpoChipsModule,
    XpoConfirmDialogModule,
    XpoSnackBarModule,
    XpoStatusIndicatorModule,
    XpoIconModule,
    XpoButtonModule,
    XpoTooltipModule,
    XpoTabsModule,
    SdkModuleExecutorModule,
    AgGridModule.withComponents([
      ModuleGroupsActionsCellRendererComponent,
      ModuleGroupsNameCellRendererComponent,
      ModuleGroupsStatusCellRendererComponent,
    ]),
    DateTimeCellRendererModule,
  ],
  exports: [ModuleGroupsBoardComponent],
  providers: [ListModuleGroupsService, ListModuleGroupsStoreService, ModuleGroupsService],
})
export class ModuleGroupsBoardModule {}
