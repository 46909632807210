import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	GetUserPreferenceResp,
	GetUserPreferenceQuery,
	UpsertUserPreferenceRqst,
	UpsertUserPreferenceQuery
} from './api-userpreference';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class UserPreferenceApiService extends BaseService {
	private static UserPreferenceApiEndpoint = 'userpreferenceApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* IGet user preferences. The user id and application name are derived from the API subscription 
	* <br/>        and passed in the transaction context.
	* <br/>        Optional Parameters:
	* <br/>        	- uiComponentName. The UI component for which to retrieve the preferences
	* <br/>        	- uiComponentDefault. A boolean indicating whether to retrieve the default preferences for the UI component.
	* <br/>        	- appDefault. A boolean indicating whether to retrieve the default preferences for the application. Cannot be combined
	* <br/>        	with the UI Component options
	* <br/>        	- globalDefault. A boolean indicating whether to retrieve the global preferences. Cannot be combined
	* <br/>        	with either app default or UI Component options
	* <br/>        
	*/
	public getUserPreference(
							   queryParams: GetUserPreferenceQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetUserPreferenceResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/users/preferences'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Inserts or Updates user preferences. The user id and application name are derived from the API subscription 
	* <br/>        and passed in the transaction context.
	* <br/>        Optional Parameters:
	* <br/>        	- uiComponentName. The UI component for which to store the preferences
	* <br/>        	- uiComponentDefault. A boolean indicating whether to upsert the default preferences for the UI component.
	* <br/>        	- appDefault. A boolean indicating whether to upsert the default preferences for the application. Cannot be combined
	* <br/>        	with the UI Component options
	* <br/>        	- globalDefault. A boolean indicating whether to upsert the global preferences. Cannot be combined
	* <br/>        	with either app default or UI Component options
	* <br/>        
	*/
	public upsertUserPreference(request: UpsertUserPreferenceRqst,
							   queryParams: UpsertUserPreferenceQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/users/preferences'),
			{
				queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(UserPreferenceApiService.UserPreferenceApiEndpoint);
	}
}
