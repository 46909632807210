import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { MatIconModule } from '@angular/material/icon';
import { XpoButtonModule } from '../button/button.module';
import { XpoCardModule } from '../card/index';
import { XpoConfirmDialog, XpoConfirmDialogWindow } from './confirm-dialog';

@NgModule({
  declarations: [XpoConfirmDialog, XpoConfirmDialogWindow],
  imports: [CommonModule, MatDialogModule, XpoCardModule, XpoButtonModule, MatIconModule],
  exports: [XpoConfirmDialogWindow],
  providers: [XpoConfirmDialog],
  entryComponents: [XpoConfirmDialogWindow],
})
export class XpoConfirmDialogModule {}
