
export enum CarrierTenderGroupStatusCd {
	ACCEPTED = 'Accepted',
	ACCEPTED_WITH_EXCEPTIONS = 'AcceptedWithExceptions',
	PARTIALLY_ACCEPTED = 'PartiallyAccepted',
	PARTIALLY_ACCEPTED_WITH_EXCEPTIONS = 'PartiallyAcceptedWithExceptions',
	PARTIALLY_ROUTED = 'PartiallyRouted',
	PARTIALLY_ROUTED_WITH_EXCEPTIONS = 'PartiallyRoutedWithExceptions',
	ROUTED = 'Routed',
	ROUTED_WITH_EXCEPTIONS = 'RoutedWithExceptions',
	TENDERED = 'Tendered',
	TENDERED_WITH_EXCEPTIONS = 'TenderedWithExceptions',
	WITHDRAWN = 'Withdrawn'}
