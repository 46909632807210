import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { finalize, map, take, takeUntil } from 'rxjs/operators';

import { XpoFilterComponentBase } from '../models/filter-component-base';
import { XpoMultiSelectAutocompleteFilter } from './multi-select-autocomplete-filter';

@Component({
  selector: 'xpo-multi-select-autocomplete-filter',
  templateUrl: 'multi-select-autocomplete-filter.component.html',
  styleUrls: ['multi-select-autocomplete-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-MultiSelectAutocompleteFilter', '[attr.id]': 'id' },
})
export class XpoMultiSelectAutocompleteFilterComponent extends XpoFilterComponentBase {
  config: XpoMultiSelectAutocompleteFilter;
  loadingData = true;
  options: any[] = [];
  search: string;
  originalOptions: any[] = [];
  pendingOptions: any[] = [];
  initializing = true;

  @ViewChild('searchBox', { static: true })
  private searchBox: ElementRef;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  /** Updates the selected criterion of filter */
  onCheckboxClicked(el: any, selectedValue: string): void {
    const selectedOptions = [...this.config.selectedOptions];

    const selectedItemIndex = selectedOptions.findIndex((i) => i[this.config.valueField] === selectedValue);
    const itemIndex = this.options.findIndex((i) => i[this.config.valueField] === selectedValue);

    this.options[itemIndex].checked = el.checked;

    if (el.checked) {
      selectedOptions.push(this.options[itemIndex]);
    } else if (selectedItemIndex !== -1) {
      selectedOptions.splice(selectedItemIndex, 1);
    }

    this.config.setSelectedOptions(selectedOptions);

    // publish the values for any selected options
    this.updateFieldCriteria();
  }

  onInputChange(value: string): void {
    this.loadingData = true;
    this.searchOptions(value);
  }

  onOptionRemove(removedValue: any): void {
    const selectedOptions = [...this.config.selectedOptions];
    const selectedItemIndex = selectedOptions.findIndex((i) => i[this.config.valueField] === removedValue);
    if (this.options[selectedItemIndex]) {
      this.options[selectedItemIndex].checked = false;
    }

    selectedOptions.splice(selectedItemIndex, 1);

    this.config.setSelectedOptions(selectedOptions);

    // publish the values for any selected options
    this.updateFieldCriteria();
  }

  protected initialize(): void {
    this.config = <XpoMultiSelectAutocompleteFilter>this.configuration;
    this.options = [];

    this.searchOptions('');
  }

  protected onCriteriaModified(fieldValue: object, criteria: { [key: string]: any }): void {
    const val = <string[]>fieldValue || new Array<string>();
    // check off the appropriate checkboxes
    const selectedOptions = [];

    this.originalOptions.forEach((opt) => {
      opt.checked = val.indexOf(opt[this.config.valueField]) !== -1;
      if (opt.checked) {
        selectedOptions.push(opt);
      }
    });
    this.config.setSelectedOptions(selectedOptions);

    /**
     * store fieldValue if there are no option list yet (when onCriteriaModified called by the
     * board before searchOptions is fired)
     */
    if (!this.originalOptions.length) {
      this.pendingOptions = this.pendingOptions.concat(fieldValue);
    }
  }

  private searchOptions(searchValue: string): void {
    this.config
      .optionsResolver$(searchValue)
      .pipe(
        map((options: any) => {
          const values = options.map((o: any) => ({
            checked: this.isOptionInSelectedOptions(o) || this.isOptionInPendingOptions(o),
            [this.config.labelField]: o[this.config.labelField],
            [this.config.valueField]: o[this.config.valueField],
          }));
          return values;
        }),
        finalize(() => {
          this.resetFocusAndLoading();
        }),
        take(1)
      )
      .subscribe((options) => {
        if (this.initializing) {
          this.originalOptions = options;
        }
        this.options = options || [];
        this.initializing = false;

        if (this.pendingOptions.length) {
          this.pendingOptions = [];
          this.config.setSelectedOptions(this.options.filter((o) => o.checked));
        }
      });
  }

  private isOptionInPendingOptions(option: any): boolean {
    return this.pendingOptions.some((po) => po === option[this.config.valueField]);
  }
  private isOptionInSelectedOptions(option: any): boolean {
    return this.config.selectedOptions.some((s) => s[this.config.valueField] === option[this.config.valueField]);
  }

  private resetFocusAndLoading(): void {
    this.loadingData = false;
    this.searchBox.nativeElement.focus();
    this.changeDetectorRef.markForCheck();
  }

  private updateFieldCriteria(): void {
    // publish the values for any selected options
    const value = this.config.selectedOptions.map((opt) => opt[this.config.valueField]);

    this.filtersService.setFieldCriteria(this.field, value);
  }
}
