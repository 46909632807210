import { Injectable } from '@angular/core';
import { XpoDataDrawerDataSource } from '@xpo-ltl/ngx-ltl-board';
import { Observable, of } from 'rxjs';
import { ModuleLibraryVersion } from '../models/module-library-version.model';

@Injectable()
export class ModuleLibraryDataDrawerDataSource extends XpoDataDrawerDataSource<ModuleLibraryVersion> {
  fetchEntity(selectedRecord: ModuleLibraryVersion): Observable<ModuleLibraryVersion> {
    return of(selectedRecord);
  }
}
