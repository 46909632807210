import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'name-cell-renderer',
  templateUrl: './name-cell-renderer.component.html',
  styleUrls: ['./name-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ModuleLibraryNameCellRenderer',
  },
})
export class ModuleLibraryNameCellRenderComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  isNew: boolean;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.isNew = this.params.node.group
      ? this.params.node.allLeafChildren.find((row) => row.data.isNew) !== undefined
      : this.params.node.data.isNew;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  onClick(): void {
    const module = this.params.node.group ? this.params.node.allLeafChildren[0].data : this.params.node.data;

    this.params.context.setSelectedModule(module);
  }
}
