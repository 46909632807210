import {AccessorialCheckCd} from '../';
import {EnumHelper} from './enum-helper';

export class AccessorialCheckCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in AccessorialCheckCd ) {
      this._values.push(AccessorialCheckCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): AccessorialCheckCd {

    for ( const obj in AccessorialCheckCd ) {
      if (AccessorialCheckCd[obj] === value){
        return AccessorialCheckCd[obj] as AccessorialCheckCd;
      }
    }
  }
}

const AccessorialCheckCdHelper = new AccessorialCheckCdHelperEnumHelperClass();
export default AccessorialCheckCdHelper;
