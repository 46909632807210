import { Directive, DoCheck, ElementRef, Inject, InjectFlags, Injector, Input, OnInit, Optional } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  NgControl,
  NgForm,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DateAdapter, ErrorStateMatcher, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateRange } from '@angular/material/datepicker';
import { XpoDateRangeSelectionChangeEvent } from '../../../models/date-range-selection-change.event';
import { XpoDateRangeSelectionModel } from '../../../models/date-range-selection.model';
import { xpoDateRangeInvalidValidator } from '../validators/date-range-invalid-validor-fn';
import { xpoDateRangeParseValidator } from '../validators/date-range-parse-validor-fn';
import { xpoDateRangeUncompletedValidator } from '../validators/date-range-uncompleted-validor-fn';
import { XpoDateRangeInputSupressPlaceholder } from './date-range-input-supress-placeholder.directive';

/**
 * Base class for each child input control in the date range input form field
 */
@Directive()
export abstract class XpoDateRangeBaseInput<D> implements ControlValueAccessor, Validator, OnInit, DoCheck {
  /**
   * The value of the input.
   */
  @Input()
  get value(): D | null {
    // check in case the model is not registered, return the pending value if exists or null
    return this.selectionModel ? this.getValueFromRange(this.selectionModel.selection) : this.pendingValue;
  }
  set value(value: D | null) {
    // deserealize the value to a valid date object
    let date = this.dateAdapter.deserialize(value);

    // check if the date is valid for validation purposes
    this.hasParseError = !this.isValidDate(date);

    // check if the date is a valid, if not return null
    date = this.dateAdapter.getValidDateOrNull(date);

    // change de current selection in the model
    this.changeSelection(date);

    // update the element value with the formatted date
    this.setInputFormattedValue(date);
  }

  /**
   * Whether the control is required.
   */
  get required(): boolean {
    return this.elementRef.nativeElement.required;
  }

  /**
   * Whether the input has focus
   */
  focused: boolean = false;

  constructor(
    protected elementRef: ElementRef<HTMLInputElement>,
    @Optional() protected parentForm: NgForm,
    @Optional() protected parentFormGroup: FormGroupDirective,
    protected dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) protected dateFormats: MatDateFormats,
    protected injector: Injector,
    protected errorStateMatcher: ErrorStateMatcher,
    @Optional() protected supressPlaceholder: XpoDateRangeInputSupressPlaceholder
  ) {}

  /** The control reference itself as part as being a ControlValueAccesor  */
  ngControl: NgControl;

  /** Whether the input is in an error state. */
  errorState: boolean = false;

  /**
   * Combined form control validator for this input.
   * Used as part of the implementation of validate() for Validator.
   * */
  protected validator: ValidatorFn | null;

  /**
   * The selection model associated with the date picker,
   * the parent form field and the date inputs controls
   * */
  protected selectionModel: XpoDateRangeSelectionModel<D>;

  /**
   * Since the value is kept on the model which is assigned in an Input,
   * we might get a value before we have a model. This property keeps track
   * of the value until we have somewhere to assign it.
   */
  protected pendingValue: D;

  /** Whether the last value set on the input is a valid date. */
  protected hasParseError: boolean = false;

  /**
   * Callback function that is called by the forms API on initialization to update the form model on blur.
   * Used as part of the implementation of registerOnTouched() for ControlValueAccessor.
   */
  protected onTouched: () => void = () => {};

  /** Callback function that is called when the control's value changes in the UI
   * Used as part of the implementation of registerOnChange() for ControlValueAccessor.
   */
  protected cvaOnChange: (value: D) => void = () => {};

  /**
   * Callback function to call when the validator inputs change
   * Used as part of the implementation of registerOnValidatorChange() for Validator.
   * */
  protected validatorOnChange = () => {};

  ngOnInit(): void {
    // (from material): We need the date input to provide itself as a `ControlValueAccessor` and a `Validator`, while
    // injecting its `NgControl` so that the error state is handled correctly. This introduces a
    // circular dependency, because both `ControlValueAccessor` and `Validator` depend on the input
    // itself. Usually we can work around it for the CVA, but there's no API to do it for the
    // validator. We work around it here by injecting the `NgControl` in `ngOnInit`, after
    // everything has been resolved.
    const ngControl = this.injector.get(NgControl, null, InjectFlags.Self);

    if (ngControl) {
      this.ngControl = ngControl;
    }

    // set the default placeholder for the input
    this.setDefaultPlaceholder();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      // (from material): We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }
  }

  /**
   * Sets a new value to the input
   * Implemented as part of ControlValueAccessor
   * @param value
   */
  writeValue(value: D): void {
    // asign the given value to the selection model using value setter
    this.value = value;
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * Implemented as part of ControlValueAccessor.
   * @param fn
   */
  registerOnChange(fn: (value: any) => void): void {
    this.cvaOnChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API
   * on initialization to update the form model when the input changes (generally on blur)
   * Implemented as part of ControlValueAccessor.
   * @param fn
   */
  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  /**
   * Function that is called by the forms API
   * when the control status changes to or from 'DISABLED'.
   * Depending on the status, it enables or disables the appropriate DOM element.
   * Implemented as part of ControlValueAccessor.
   * @param _isDisabled
   */
  setDisabledState(_isDisabled: boolean): void {
    // this.disabled = _isDisabled;
  }

  /**
   * Performs synchronous validation against the provided control.
   * Implemented as part of Validator
   * @param control
   */
  validate(control: AbstractControl): ValidationErrors {
    // compose all validators with the updated values they need
    const validator = Validators.compose(this.getValidators());

    return validator(control);
  }

  /**
   * Registers a callback function to call when the validator inputs change.
   * Implemented as part of Validator
   * @param fn
   */
  registerOnValidatorChange(fn: () => void): void {
    this.validatorOnChange = fn;
  }

  /**
   * Focuses the input.
   **/
  focus(): void {
    this.elementRef.nativeElement.focus();
  }

  /**
   * Gets whether the input is empty.
   **/
  isEmpty(): boolean {
    return this.elementRef.nativeElement.value.length === 0;
  }

  /**
   * Gets the placeholder of the input.
   **/
  getPlaceholder(): string {
    return this.elementRef.nativeElement.placeholder;
  }

  /**
   * Associate the date picker selection model to the input control
   *
   * @param selectionModel
   */
  registerSelectionModel(selectionModel: XpoDateRangeSelectionModel<D>): void {
    // keep the selection model instance
    this.selectionModel = selectionModel;

    // check if there is a pending value assigned before the model is registered
    if (this.pendingValue) {
      this.changeSelection(this.pendingValue);
      this.pendingValue = null;
    }

    // handle selection changes
    this.selectionModel.selectionChanged.subscribe((event: XpoDateRangeSelectionChangeEvent<D>) => {
      // check if the change is made outside input controls
      if (event.source !== 'input') {
        this.handleSelectionChanged(event.value);
      } else {
        // Whenever the value changes inside one input we need to revalidate, because
        // the validation state of each of the inputs depends on the other one.
        this.validatorOnChange();
      }
    });
  }

  /**
   * Based on the date formats config, set the default placeholder
   */
  protected setDefaultPlaceholder(): void {
    // if there is not a placeholder already defined
    if (this.getPlaceholder().length === 0 && !this.supressPlaceholder) {
      // use the display format from date formas config as placeholder
      this.elementRef.nativeElement.placeholder = this.dateFormats.display.dateInput;
    }
  }

  /**
   * Handles the input event inside the control
   * @param value
   */
  protected onInput(value: string): void {
    // parse the given value into date instance
    let date = this.dateAdapter.parse(value, this.dateFormats.parse.dateInput);

    // check if the date is valid for validation purposes
    this.hasParseError = !this.isValidDate(date);

    // check if parsed value is valid date or assign null
    date = this.dateAdapter.getValidDateOrNull(date);

    // change the current selection
    this.changeSelection(date);
  }

  /**
   * Handles blur events on the input.
   * */
  protected onBlur(): void {
    // Reformat the input only if it has a valid value.
    if (this.value) {
      this.setInputFormattedValue(this.value);
    }

    // change the focused state
    this.focused = false;

    // (paula.zabalegui): I think we don't need to call this.onTouched() here
    // since the logic behind we'll set the input as touched when its value changes
    // by input setter/typed or calendar selection
  }

  /**
   * Handles focus event on the input
   */
  protected onFocus(): void {
    this.focused = true;
  }

  /**
   * Handles selection changes from outside the input control
   */
  protected handleSelectionChanged(range: DateRange<D>): void {
    // the change comes from the calendar, where the selected dates are valid
    // so reset the parse error flag
    this.hasParseError = false;

    // get the corresponding value for each input
    const date = this.getValueFromRange(range);

    // update the input control value
    this.setInputFormattedValue(date);

    // call the ControlValueAccessor onChange callback function
    // to allow the forms API to update itself
    this.cvaOnChange(date);

    // call the ControlValueAccessor onTouched callback function
    // to allow the forms API to update itself
    this.onTouched();
  }

  /**
   * Returns the corresponding input value (start or end)
   * @param range
   */
  protected abstract getValueFromRange(range: DateRange<D>): D;

  /**
   * Based on the given date, updates the input control value
   * @param date
   */
  protected setInputFormattedValue(date: D): void {
    this.elementRef.nativeElement.value = date ? this.dateAdapter.format(date, this.dateFormats.display.dateInput) : '';
  }

  /**
   * Sets the given date to the current selection range
   * @param date
   */
  protected changeSelection(date: D): void {
    // check if the selection model is already registered
    // We may get some incoming values before the model was
    // assigned. Save the value so that we can assign it later.
    if (!this.selectionModel) {
      this.pendingValue = date;
      return;
    }

    // create a new range based on the given date
    const range = this.getNewSelection(date);

    // update the selection inside the model
    this.selectionModel.updateSelection(range, 'input');

    // call the ControlValueAccessor onChange function
    // to alllow the forms API to update itself
    this.cvaOnChange(date);

    // call the ControlValueAccessor onTouched callback function
    // to allow the forms API to update itself
    this.onTouched();
  }

  /**
   * Returns a new range selection based on the given date
   * @param date
   */
  protected abstract getNewSelection(date: D): DateRange<D>;

  /**
   * Gets internal validator functions for date range inputs
   */
  protected getValidators(): ValidatorFn[] {
    return [
      // input date is valid
      xpoDateRangeParseValidator(this.hasParseError),
      // date range has just one null date
      xpoDateRangeUncompletedValidator(this.selectionModel),
      // start date earlier than end date
      xpoDateRangeInvalidValidator(this.selectionModel, this.dateAdapter),
    ];
  }

  /**
   * Whether a date is considered valid.
   * Used to check after parse or deserialize a value
   */
  protected isValidDate(date: D | null): boolean {
    return !date || this.dateAdapter.isValid(date);
  }

  /**
   * Sets the error state value for the input
   */
  protected updateErrorState(): void {
    // if the input has a parent form
    const form = this.parentFormGroup || this.parentForm;

    // if the input acts as a ControlValueAccesor
    const control = this.ngControl ? (this.ngControl.control as FormControl) : null;

    // check the error state
    this.errorState = this.errorStateMatcher.isErrorState(control, form);
  }

  /**
   * Returns the maxlength for the input control
   * based on the date format in the global date formats configuration object
   */
  protected getMaxLength(): number {
    return this.dateFormats.display.dateInput.length;
  }
}
