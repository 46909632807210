import {CheckpointEventStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class CheckpointEventStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CheckpointEventStatusCd ) {
      this._values.push(CheckpointEventStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CheckpointEventStatusCd {

    for ( const obj in CheckpointEventStatusCd ) {
      if (CheckpointEventStatusCd[obj] === value){
        return CheckpointEventStatusCd[obj] as CheckpointEventStatusCd;
      }
    }
  }
}

const CheckpointEventStatusCdHelper = new CheckpointEventStatusCdHelperEnumHelperClass();
export default CheckpointEventStatusCdHelper;
