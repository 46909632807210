import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  Optional,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { XpoPopover } from '@xpo-ltl/ngx-ltl-core';
import { XpoBoardStateSources } from '../../../models/board-state-sources.enum';

import {
  XpoBoardConsumer,
  XpoBoardDataSourceResolver,
  XpoGridBoardColumn,
  XpoGridBoardState,
  XpoGridBoardViewTemplate,
  XPO_GRID_BOARD_DEFAULT_ROW_HEIGHT,
} from '../../../models/index';
import { XpoGridSettingsColumnOption } from '../../board-grid-toolbar/board-grid-toolbar-settings/board-settings-default-buttons/grid-settings-default-buttons';

@Component({
  selector: 'xpo-grid-settings',
  templateUrl: './grid-settings.component.html',
  styleUrls: ['./grid-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: ' xpo-GridSettings' },
})
export class XpoGridSettingsComponent extends XpoBoardConsumer {
  /** Column Options that will be mutated from the grid column selection component via 2 way binding */
  columnOptions: XpoGridSettingsColumnOption[] = [];
  /** All columns from the current template */
  availableColumns: XpoGridBoardColumn[] = [];

  selectedRowHeight: number;

  /** Popover container for the settings */
  @ViewChild('popover')
  popover: XpoPopover;

  /** List of columns with no header names */
  private columnsWithNoHeaderName: XpoGridBoardColumn[] = [];
  private cachedState: XpoGridBoardState;

  @Input()
  suppressGridDensity: boolean = false;

  constructor(
    boardDataSourceResolver: XpoBoardDataSourceResolver,
    cd: ChangeDetectorRef,
    @Inject(XPO_GRID_BOARD_DEFAULT_ROW_HEIGHT) @Optional() private defaultRowHeight: number
  ) {
    super(boardDataSourceResolver, cd);
  }

  handlePopoverClose(closeData: any): void {
    // If user doesn't hit apply, reset to previous state
    if (!closeData) {
      this.handleStateUpdate(this.cachedState);
    }
  }

  applyChangesClick($event): void {
    this.applySettingsUpdates();
    this.popover.closed.emit();
  }

  applySettingsUpdates(): void {
    const selectedColumnsToSubmit: XpoGridSettingsColumnOption[] = this.availableColumns.filter((column) => {
      const sel = this.columnOptions.find((col) => col.headerName === column.headerName);

      if (sel && sel.isVisible) {
        return column;
      }
    });

    const newState: XpoGridBoardState = {
      visibleColumns: [...this.columnsWithNoHeaderName, ...selectedColumnsToSubmit],
      source: XpoBoardStateSources.GridSettingsUpdate,
    };

    if (this.selectedRowHeight !== this.cachedState.rowHeight) {
      newState.rowHeight = this.selectedRowHeight;
      newState.changes = ['rowHeight'];
    }

    this.stateChange$.next(newState);
    this.popover.closed.emit();
  }

  protected onStateChange(state: XpoGridBoardState): void {
    if (!this.cachedState || this.shouldUpdateState(state)) {
      this.cachedState = state;
      this.handleStateUpdate(state);
    }

    // Update selected row height in modal
    if (state.rowHeight !== this.selectedRowHeight) {
      this.selectedRowHeight = state.rowHeight;
    }
  }

  private handleStateUpdate(state: XpoGridBoardState): void {
    this.updateColumnSelectionWithState(state);
  }

  private updateColumnSelectionWithState(state: XpoGridBoardState): void {
    const template = state.template as XpoGridBoardViewTemplate;
    const visibleColumns = state.visibleColumns || [];

    this.availableColumns = (template && template.availableColumns) || [];
    // Storing the column headers with no header names so they can be appended to the selected columns
    this.columnsWithNoHeaderName = this.availableColumns.filter((c) => !c.headerName);
    // mapping columns to view model columns
    this.columnOptions = this.availableColumns
      .filter((c) => !!c.headerName)
      .map((column) => {
        return {
          headerName: column.headerName,
          isVisible: !visibleColumns.length || visibleColumns.some((c) => c.headerName === column.headerName),
          lockVisible: column.lockVisible,
        };
      });

    this.selectedRowHeight = state.rowHeight || this.defaultRowHeight;
    this.cachedState.rowHeight = this.selectedRowHeight;
  }

  private shouldUpdateState(state: XpoGridBoardState): boolean {
    const changeKeys: Array<keyof XpoGridBoardState> = ['visibleColumns', 'template', 'rowHeight'];

    return changeKeys.some((v) => state.changes.includes(v));
  }
}
