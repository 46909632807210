import {RuleShmEntityTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class RuleShmEntityTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RuleShmEntityTypeCd ) {
      this._values.push(RuleShmEntityTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RuleShmEntityTypeCd {

    for ( const obj in RuleShmEntityTypeCd ) {
      if (RuleShmEntityTypeCd[obj] === value){
        return RuleShmEntityTypeCd[obj] as RuleShmEntityTypeCd;
      }
    }
  }
}

const RuleShmEntityTypeCdHelper = new RuleShmEntityTypeCdHelperEnumHelperClass();
export default RuleShmEntityTypeCdHelper;
