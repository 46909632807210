
export enum CustomsReasonCd {
	BROKER_CLOSED = 'BrokerClosed',
	CLIENT_CLEAR = 'ClientClear',
	CLIENT_CREDIT = 'ClientCredit',
	CUSTOMS_IMPORT_EXPORT = 'CustomsImportExport',
	FAILED_PARS = 'FailedPars',
	GOVERNMENT_DOCS_REQUIRED = 'GovernmentDocsRequired',
	INCORRECT_BROKER = 'IncorrectBroker',
	INFO_MISSING = 'InfoMissing',
	INT_NON_CARRIER_DELIVERY = 'IntNonCarrierDelivery',
	NO_BROKER = 'NoBroker',
	OTHER = 'Other',
	PAPERWORK_ISSUE = 'PaperworkIssue',
	WAITING_INSPECTION = 'WaitingInspection',
	WRONG_PRO = 'WrongPro'}

