import {PartyShipmentRoleCd} from '../';
import {EnumHelper} from './enum-helper';

export class PartyShipmentRoleCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PartyShipmentRoleCd ) {
      this._values.push(PartyShipmentRoleCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PartyShipmentRoleCd {

    for ( const obj in PartyShipmentRoleCd ) {
      if (PartyShipmentRoleCd[obj] === value){
        return PartyShipmentRoleCd[obj] as PartyShipmentRoleCd;
      }
    }
  }
}

const PartyShipmentRoleCdHelper = new PartyShipmentRoleCdHelperEnumHelperClass();
export default PartyShipmentRoleCdHelper;
