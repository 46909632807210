import { XpoDateRangeFilterCriteria } from './date-range-filter-criteria';
import { XpoDateRangeFilterHelper } from './date-range-filter-helper';
import { XpoDateRangeFilterTab } from './date-range-filter-tab';
import { XpoDateRangeGroupByIntervalType } from './date-range-interval';
import { XpoDateRangeIntervalBase } from './date-range-interval-base';
import { XpoQuickDate } from './date-range-quick-date.enum';
import { XpoQuickFilter } from './date-range-quick-filter';

export class XpoDateRangeMonthly extends XpoDateRangeIntervalBase {
  get groupByInterval(): XpoDateRangeGroupByIntervalType {
    return 'month';
  }

  get label(): string {
    return 'Monthly';
  }

  getQuickFilterOptions(): XpoQuickFilter[] {
    return [
      { code: XpoQuickDate.LastQuarter, display: 'Last Quarter' },
      { code: XpoQuickDate.ThisQuarter, display: 'This Quarter' },
      { code: XpoQuickDate.NextQuarter, display: 'Next Quarter' },
    ];
  }

  getTabModel(): XpoDateRangeFilterTab {
    return {
      pos: this.pos,
      quickFilterOptions: this.getQuickFilterOptions(),
      groupByInterval: this.groupByInterval,
    };
  }

  setNext(nextInput: number): XpoDateRangeFilterCriteria {
    return XpoDateRangeFilterHelper.constructFilter(XpoQuickDate.TodayPlusMonths, nextInput * 1);
  }

  setPrev(prevInput: number): XpoDateRangeFilterCriteria {
    return XpoDateRangeFilterHelper.constructFilter(XpoQuickDate.TodayMinusMonths, prevInput * 1);
  }
}
