import { Injectable } from '@angular/core';
import {
  ListLocationsQuery,
  ListLocationsResp,
  ListLocationsRqst,
  LocationApiService,
  LocationSic,
  ServiceCenter,
} from '@xpo-ltl-2.0/sdk-location';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import {
  defaultTo as _defaultTo,
  forEach as _forEach,
  isDate as _isDate,
  isInteger as _isInteger,
  isString as _isString,
  isUndefined as _isUndefined,
  toUpper as _toUpper,
} from 'lodash';
import * as moment_ from 'moment-timezone';
import { AsyncSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, delay, filter, mapTo, retryWhen, take } from 'rxjs/operators';

import { XpoLtlServiceCentersService } from '../service-centers/service-centers.service';

const moment = moment_;

/**
 * Handle converting time for various timezones and sics.
 */
@Injectable({
  providedIn: 'root',
})
export class XpoLtlTimeService {
  private sicTimezones = new Map<string, string>();
  private timezonesLoadedSubject = new AsyncSubject<void>();

  /**
   * Resolves after the service center timezone data has been loaded
   * @deprecated use timeDataLoaded$ instead
   */
  readonly timezonesLoaded$ = this.timezonesLoadedSubject.asObservable();

  private locationSics = new Map<string, LocationSic>();
  private readonly locationSicsLoadedSubject = new AsyncSubject<void>();
  /**
   * Resolves after the sic locations data has been loaded
   */
  private readonly locationSicsLoaded$ = this.locationSicsLoadedSubject.asObservable();

  /**
   * Resolves after all data has been loaded in timezone service
   */
  readonly timeDataLoaded$: Observable<void> = forkJoin([this.timezonesLoaded$, this.locationSicsLoaded$]).pipe(
    mapTo(undefined)
  );

  constructor(
    private serviceCentersService: XpoLtlServiceCentersService,
    private locationService: LocationApiService,
    private configService: ConfigManagerService
  ) {
    this.configService.configured$
      .pipe(
        filter((config) => config),
        take(1)
      )
      .subscribe((config) => {
        this.loadServiceCenters();
        this.loadLocations();
      });
  }

  private loadServiceCenters() {
    this.serviceCentersService.serviceCenters$.pipe(take(1)).subscribe((centers: ServiceCenter[]) => {
      _forEach(centers, (ss) => {
        this.sicTimezones.set(_toUpper(ss.sicCd), ss.reference.timezoneName);
      });
      this.timezonesLoadedSubject.next();
      this.timezonesLoadedSubject.complete();
    });
  }

  private loadLocations() {
    const request: ListLocationsRqst = { ...new ListLocationsRqst() };
    const query: ListLocationsQuery = {
      ...new ListLocationsQuery(),
      activeInd: true,
      meetAndTurnDesiredInd: true,
    };
    this.locationService
      .listLocations(request, query)
      .pipe(
        retryWhen((errors) =>
          // retry getting list if we get an error. Might be waiting for authentication to complete
          errors.pipe(delay(1000), take(5))
        ),
        catchError((err) => {
          console.error('Unable To Load Sic Location Information: ', err);

          return of({ locationSic: [] });
        })
      )
      .subscribe((resp: ListLocationsResp) => {
        _forEach(resp.locationSic, (loc) => {
          this.locationSics.set(_toUpper(loc.sicCd), loc);
        });

        this.locationSicsLoadedSubject.next();
        this.locationSicsLoadedSubject.complete();
      });
  }

  /**
   * Return an Observable that resolves to the Timezone for the specified SIC, or undefined if not found.
   *
   * NOTE: Each call with the same sicCd returns a NEW Observable! Do NOT call this using the
   * Angular `async` pipe.  Instead, save the returned Observable and async with that.
   *
   * @param sicCd - SIC to get timezone for. If not found, defaults to America/Los_Angeles
   */
  timezoneForSicCd$(sicCd: string): Observable<string> {
    return new Observable<string>((observer) => {
      this.timezonesLoadedSubject.pipe(take(1)).subscribe(() => {
        const tzone = this.timezoneForSicCd(sicCd);
        observer.next(tzone);
        observer.complete();
      });
    });
  }

  /**
   * Return the Timezone for the specified SIC, or undefined if not found
   * @param sicCd - SIC to get timezone for. If not found, defaults to America/Los_Angeles
   * @deprecated use timezoneForSicCd$ instead
   */
  timezoneForSicCd(sicCd: string): string {
    // NOTE: We are rolling the dice here that sicTimezones has been populated. There is still a chance
    // that if we call this method on app startup that the sics haven't finished loading
    const timezone = _defaultTo(this.sicTimezones.get(_toUpper(sicCd)), 'America/Los_Angeles');
    return timezone;
  }

  /**
   * Convert the passed date/time into a Date object
   * @param date 	The date to convert, as a Date, or a number (milliseconds since UTC epoch) or an ISO date-time string.
   */
  private toDate(value: Date | number | string): Date {
    if (_isInteger(value)) {
      // date encoded as ms
      return new Date(value);
    } else if (_isString(value)) {
      // date encoded in a string
      return new Date(value);
    } else if (_isDate(value)) {
      // already a date
      return new Date(value);
    } else {
      // don't know how to make this a Date
      return undefined;
    }
  }

  /**
   * return Date as a string in the form 'HH:mm' (24 hour time)
   * @param date the date to display in 24-hour format
   */
  to24Time(value: Date | number | string, sicCd?: string): string {
    return this.formatDate(this.toDate(value), 'HH:mm', sicCd);
  }

  /**
   * Format the passed DateTime using the passed format string and sic for timezone.
   * @param value date value
   * @param format (optional) the format string to use - moment format
   * @param sicCd (optional) the sicCd for the service center to base timezone on
   */
  formatDate(value: Date | number | string, format?: string, sicCd?: string): string {
    const date = this.toDate(value);
    if (_isUndefined(date)) {
      return undefined;
    }
    format = _defaultTo(format, 'llll');

    // serviceCenterOffset is in relative to Portland time, so everything in PST will be 0
    const utcOffset = Number(this.locationSics.get(sicCd)?.serviceCenterOffset ?? 0) - 60 * 7;

    const result = moment(value)
      .utcOffset(utcOffset)
      .format(format);

    return result;
  }
}
