import {DisputeShipmentPaymentStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeShipmentPaymentStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeShipmentPaymentStatusCd ) {
      this._values.push(DisputeShipmentPaymentStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeShipmentPaymentStatusCd {

    for ( const obj in DisputeShipmentPaymentStatusCd ) {
      if (DisputeShipmentPaymentStatusCd[obj] === value){
        return DisputeShipmentPaymentStatusCd[obj] as DisputeShipmentPaymentStatusCd;
      }
    }
  }
}

const DisputeShipmentPaymentStatusCdHelper = new DisputeShipmentPaymentStatusCdHelperEnumHelperClass();
export default DisputeShipmentPaymentStatusCdHelper;
