import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'xpo-ltl-confirm-environment',
  templateUrl: './confirm-environment.component.html',
  styleUrls: ['./confirm-environment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class XpoLtlConfirmEnvironmentComponent implements OnInit {
  environment: string;
  environmentFriendlyName: string;
  inputValue: string;
  enableConfirmButton: boolean;

  constructor(
    public dialogRef: MatDialogRef<XpoLtlConfirmEnvironmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    const { environment } = this.data;
    this.environment = environment;
    this.environmentFriendlyName = this.environment.toUpperCase() === 'PROD' ? 'Production' : 'Test';
  }

  checkInputValue(event: KeyboardEvent) {
    this.enableConfirmButton = this.inputValue.toUpperCase() === this.environment;
    if (event.key === 'Enter' && this.enableConfirmButton) {
      this.dialogRef.close(true);
    }
  }
}
