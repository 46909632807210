import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { cloneDeep, includes, some } from 'lodash-es';
import {
  CustomFilterParams,
  filterFormats,
  Operators,
  OperatorText,
  OptionListValue,
  XpoFilterConditionCriteria,
} from '../../../../../models/index';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { EnumValue } from '../../common/enum/enum.interface';

@Component({
  templateUrl: './equals.component.html',
  styleUrls: ['./equals.component.scss'],
})
export class XpoConditionDynamicEnumEqualsComponent extends XpoConditionBase
  implements XpoFilterCondition, AfterViewInit {
  static text: string = OperatorText.None; // value used in dropdown
  static operator: Operators = Operators.Equals;
  readonly ALL_DISPLAY_VALUE = 'all';
  defValuesChecked: boolean = false;
  isNegative: boolean = false;
  model: any = [];
  values: OptionListValue;
  allOptionsSelected: boolean = false;

  @Output() inputChanged = new EventEmitter();

  set customFilterParams(params: CustomFilterParams) {
    this.values = this.mapValues(params);
  }

  constructor() {
    super(filterFormats.default);
  }

  ngAfterViewInit(): void {
    this.model.forEach((item) => {
      this.values[item].checked = true;
    });
    this.checkAllSelection();
  }

  mapValues(params: any): OptionListValue {
    let values: OptionListValue = {};
    let emptyValues: boolean = false;

    params.api.forEachNode((node) => {
      if (!params.colDef || !params.colDef.field) {
        return;
      }
      const nodeValues = this.getNodeValues(node, params.colDef.field);

      nodeValues.forEach((value) => {
        // only add it if not already in
        if (!(node.data[params.colDef.field] in values)) {
          // just add the new value to the object
          values = { ...values, ...{ [value]: { text: value, checked: this.defValuesChecked } } };
        }
      });

      if (!nodeValues.length) {
        emptyValues = true;
      }
    });
    if (emptyValues) {
      values = Object.assign(this.getEmptyValue(), values);
    }
    return values;
  }

  getEmptyValue(): EnumValue {
    return {
      [Operators.Empty]: {
        text: OperatorText.Empty,
        checked: this.defValuesChecked,
      },
    };
  }

  getNodeValues(node, col): string[] {
    if (!node.data || !node.data[col] || !node.data[col].length) {
      return [];
    }
    // Support for array of values in cell. Cell value can be string, number or array
    return Array.isArray(node.data[col]) ? node.data[col] : [node.data[col]];
  }

  validate(cellValue: string & string[]): boolean {
    return Array.isArray(cellValue) ? this.arrayMatchChecker(cellValue) : this.matchAnyChecked(cellValue);
  }

  private arrayMatchChecker(cellValue: string[]): any {
    return cellValue.length === 0 ? this.matchAnyChecked('') : some(cellValue, (value) => this.matchAnyChecked(value));
  }

  private matchAnyChecked(cellValue: string): boolean {
    return !this.model || some(this.model, (item) => this.hasCoincidence(item, cellValue));
  }

  private hasCoincidence(item: string, value: string): boolean {
    return (item === Operators.Empty && !value.toString().trim()) || item === value.toString().trim();
  }

  sortValues(): number {
    // preserve map order
    return 1;
  }

  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.Equals,
      value: this.model,
      display: this.getModelDisplayValues(),
    };
  }

  selectAll(e: MatCheckboxChange): void {
    this.model = [];
    Object.keys(this.values).forEach((val) => {
      this.values[val].checked = e.checked;
      this.model.push(val);
    });
    this.allOptionsSelected = e.checked;
  }

  checkAllSelection(): void {
    this.allOptionsSelected = Object.keys(this.values).length === this.model.length;
  }

  onInputChange(e: MatCheckboxChange, value: string): void {
    const updatedModel = cloneDeep(this.model);
    if (e.checked) {
      updatedModel.push(value);
    } else {
      // this.allOptionsSelected = false;
      const index = updatedModel.indexOf(value);
      if (index > -1) {
        updatedModel.splice(index, 1);
      }
    }
    this.model = updatedModel;
    this.inputChanged.emit(updatedModel.join(','));
    this.checkAllSelection();
    this.validateFormat();
  }
  private getModelDisplayValues(): string {
    if (this.allOptionsSelected) {
      return this.ALL_DISPLAY_VALUE;
    }
    const mappedValues: string[] = [];
    this.model.forEach((element) => {
      mappedValues.push(this.values[element].text);
    });
    // if empty was selected, switch it to last for displaying purposes
    if (mappedValues[0] === OperatorText.Empty) {
      mappedValues.push(mappedValues.shift());
    }
    return mappedValues.join(', ');
  }
}
