import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { DataApiService } from './data-api.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CorrelationIdHttpInterceptor implements HttpInterceptor {

  private dataApi: DataApiService = undefined;
  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.dataApi) {
      this.dataApi = this.injector.get<DataApiService>(DataApiService);
    }
    if (this.dataApi) {
        return next.handle(req.clone({headers: req.headers.set('x-correlation-id', this.dataApi.getCorrelationId())}));
    }
    return next.handle(req);
  }
}
