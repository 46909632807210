import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { XpoErrorPageData, xpoErrorPageStatusCodeMap } from './status-codes';

@Component({
  selector: 'xpo-error-page',
  templateUrl: './error-page.html',
  styleUrls: ['./error-page.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-ErrorPage' },
})
export class XpoErrorPage {
  errorPageData: XpoErrorPageData = xpoErrorPageStatusCodeMap.get(404);

  constructor(private route: ActivatedRoute) {
    const statusCode = this.route.snapshot.data['statusCode'];

    if (statusCode) {
      this.errorPageData = xpoErrorPageStatusCodeMap.get(statusCode) || { status: statusCode, summary: '', detail: '' };
    }
  }
}
