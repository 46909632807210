import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { User } from '@xpo-ltl/sdk-common';
import { map, tap, pluck, share } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConfigManagerProperties } from '../../config-manager-properties.enum';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

/**
 * Provides access to the currently logged-in User
 */
@Injectable({
  providedIn: 'root',
})
export class XpoLtlLoggedInUserService {
  private _cachedUser: User;
  private _runningGetLoggedInUser$: Observable<User>;

  private readonly userLoggedInSubject = new Subject<void>();
  /**
   * emits when the user is logged in
   */
  readonly userLoggedIn$ = this.userLoggedInSubject.asObservable();

  constructor(private http: HttpClient, private configManagerService: ConfigManagerService) {}

  /**
   * Get the logged in user detail
   * @param loggedInUserEndpoint End point i.e. 'shipment/1.0/appusers/logged-in-user'
   */
  getLoggedInUser(loggedInUserEndpoint: string): Observable<User> {
    if (this._cachedUser) {
      return of(this._cachedUser);
    }

    if (!this._runningGetLoggedInUser$) {
      this._runningGetLoggedInUser$ = this.http.get(`${this.getUrl(loggedInUserEndpoint)}`).pipe(
        share(),
        pluck('data'),
        tap((user: User) => {
          this._cachedUser = user;
          this._runningGetLoggedInUser$ = undefined;
          this.userLoggedInSubject.next();
        })
      );
    }
    return this._runningGetLoggedInUser$;
  }

  /**
   * Clears the previously cached User
   */
  clear() {
    this._cachedUser = undefined;
  }

  private appendUrlParts(part1: string, part2: string): string {
    const conditionedPart1 = `${part1}${part1.endsWith('/') ? '' : '/'}`;
    const conditionedPart2 = `${part2.startsWith('/') ? part2.substring(1) : part2}`;
    return `${conditionedPart1}${conditionedPart2}`;
  }

  private getUrl(uri) {
    const url = this.configManagerService.getSetting<string>(ConfigManagerProperties.apiUrl);
    return this.appendUrlParts(url, uri);
  }
}
