import { CriteriaFunction } from './criteria-function.model';

export class NotCriteriaFunction implements CriteriaFunction {
  supportsValue(value: any): boolean {
    return value && !!value.not;
  }

  getFilter<T>(key: string, value: any): (t: T) => boolean {
    return (t: T) => {
      const compareValue: string = t[key];
      const criteria = value.not;

      if (criteria) {
        const c: any[] = Array.isArray(criteria) ? criteria : [criteria];

        return !c.some((val) => compareValue === val);
      }

      return true;
    };
  }
}
