import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ListModuleGroupRunStatusesResp } from '@xpo-ltl/sdk-moduleexecutor';
import { ModuleGroupGridModel } from '../../models/module-group-grid.model';

@Component({
  selector: 'module-group-trail-panel',
  templateUrl: './trail-panel.component.html',
  styleUrls: ['./trail-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleGroupsTrailPanelComponent {
  @Output()
  close: EventEmitter<any>;

  @Input()
  moduleGroupsData: ModuleGroupGridModel;

  @Input()
  previousRunsData: ListModuleGroupRunStatusesResp;

  constructor() {
    this.close = new EventEmitter<any>();
  }

  onClose(): void {
    this.close.emit();
  }
}
