import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map, take } from 'rxjs/operators';

import { XpoBoardViewConfig, XpoBoardViewDataStore } from '../../../board-views/index';
import {
  XpoBoardConsumer,
  XpoBoardDataSourceResolver,
  XpoBoardState,
  XpoBoardView,
  XpoBoardViewUtil,
  XpoVisibleBoardViewDatum,
} from '../../../models/index';

import { Observable } from 'rxjs';
import { XpoDeleteViewConfirmData, XpoDeleteViewConfirmDialog } from '../delete-confirm-dialog';
import { XpoSaveViewDialog, XpoSaveViewDialogData, XpoSaveViewDialogPurpose } from '../save-view-dialog';

// TODO: Maybe we shouldnt use this model and use the XpoBoardView model
// directly in the template?

export class XpoAvailableViewItem {
  readonly deleteable: boolean;
  readonly id: string;
  readonly name: string;
  readonly systemDefined: boolean;
  readonly visible: boolean;
  readonly view: XpoBoardView;

  constructor(view: XpoBoardView, visible: boolean) {
    this.deleteable = view.closeable;
    this.id = view.id;
    this.name = view.name;
    this.systemDefined = view.systemDefined;
    this.visible = visible;
    this.view = view;
  }
}

@Component({
  selector: 'xpo-available-views',
  templateUrl: './available-views.component.html',
  styleUrls: ['./available-views.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-AvailableViews' },
})
export class XpoAvailableViews extends XpoBoardConsumer {
  query: string;
  systemViews: XpoAvailableViewItem[] = [];
  customViews: XpoAvailableViewItem[] = [];

  private availableViews: XpoBoardView[] = [];
  private visibleViewData: XpoVisibleBoardViewDatum[] = [];
  private state: XpoBoardState = { source: 'AVAILABLE-VIEWS' };

  @Input()
  viewDataStore: XpoBoardViewDataStore;

  constructor(boardDataSourceResolver: XpoBoardDataSourceResolver, cd: ChangeDetectorRef, private dialog: MatDialog) {
    super(boardDataSourceResolver, cd);
  }

  get visibleCustomViews(): XpoAvailableViewItem[] {
    return this.customViews.filter((v) => v.visible);
  }

  get hiddenCustomViews(): XpoAvailableViewItem[] {
    return this.customViews.filter((v) => !v.visible);
  }

  toggleViewDisplay(toggleView: XpoAvailableViewItem): void {
    // getting updated state with hidden/shown view applied
    const updatedState = toggleView.visible
      ? XpoBoardViewUtil.hideView(toggleView.id, this.state, 'AVAILABLE-VIEWS-HIDE')
      : XpoBoardViewUtil.showView(toggleView.id, this.state, 'AVAILABLE-VIEWS-SHOW');
    this.stateChange$.next(updatedState);

    // Sending it up to the viewDataStore to persist these changes
    this.viewDataStore
      .updateViewVisibility(toggleView.id, !toggleView.visible)
      .pipe(take(1))
      .subscribe();
  }

  openEditViewDialog(view: XpoAvailableViewItem): void {
    this.dialog
      .open<XpoSaveViewDialog, XpoSaveViewDialogData, XpoBoardState | null>(XpoSaveViewDialog, {
        minWidth: '500px',
        data: {
          state: this.state,
          purpose: XpoSaveViewDialogPurpose.Edit,
          saveView: this.saveView.bind(this, view.view),
          view: view.view,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((newState) => {
        if (newState) {
          this.stateChange$.next(newState);
        }
      });
  }

  openDeleteConfirmDialog(view: XpoAvailableViewItem): void {
    this.dialog
      .open<XpoDeleteViewConfirmDialog, XpoDeleteViewConfirmData, null>(XpoDeleteViewConfirmDialog, {
        minWidth: '500px',
        data: { deleteView: this.deleteView.bind(this, view.view) },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((newState) => {
        if (newState) {
          this.stateChange$.next(newState);
        }
      });
  }

  protected onStateChange(state: XpoBoardState): void {
    this.state = state;

    if (this.shouldUpdateData(state)) {
      this.availableViews = state.availableViews;
      this.visibleViewData = state.visibleViewData;
      this.systemViews = state.availableViews
        .filter((v) => v.systemDefined)
        .map(
          (v) =>
            new XpoAvailableViewItem(
              v,
              state.visibleViewData.some((vv) => vv.viewId === v.id)
            )
        );

      // show user views that have been persisted
      this.customViews = state.availableViews
        .filter((v) => !v.systemDefined && v.lastSaved)
        .map(
          (v) =>
            new XpoAvailableViewItem(
              v,
              state.visibleViewData.some((vv) => vv.viewId === v.id)
            )
        );
    }
  }

  private saveView(
    view: XpoBoardView,
    viewConfig: XpoBoardViewConfig,
    saveAs: boolean = false
  ): Observable<XpoBoardState> {
    return this.viewDataStore.save(viewConfig).pipe(
      map((savedView) => {
        const newView = view.template.createView(viewConfig);

        return XpoBoardViewUtil.updateView(newView, this.state, 'AVAILABLE-VIEWS-SAVE-VIEW');
      })
    );
  }

  private deleteView(view: XpoBoardView): Observable<XpoBoardState> {
    return this.viewDataStore.delete(view.id).pipe(
      map((res) => {
        if (res) {
          return XpoBoardViewUtil.deleteView(view.id, this.state, 'AVAILABLE-VIEWS-DELETE-VIEW');
        }
      })
    );
  }

  private shouldUpdateData(state: XpoBoardState): boolean {
    return (
      (this.state.changes.includes('availableViews') && this.availableViews !== state.availableViews) ||
      (this.state.changes.includes('visibleViewData') && this.visibleViewData !== state.visibleViewData)
    );
  }
}
