import { combineLatest, from, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { XpoAdvancedSelectOption } from '../../advanced-select/index';
import { XpoFilter } from '../models/filter';
import { OptionsResolver, XpoFilterParams } from '../models/index';
import { XpoSelectFilterComponent } from './select-filter.component';

export interface XpoSelectFilterParams extends XpoFilterParams {
  /** Options of the select filter */
  options: any[] | Observable<any[]> | Promise<any[]> | OptionsResolver;
  /** Whether or not only a single option can be selected */
  isSingleSelect?: boolean;
  /** Whether or not the select all checkbox is shown */
  enableSelectAll?: boolean;
}

export class XpoSelectFilter extends XpoFilter {
  /**
   * Controls what field on the options array is used for the display. Defaults to title
   */
  labelField = 'title';

  /**
   * Controls the max number of number of characters to have in the displayValue. Defaults to 30
   */
  maxDisplayValueCharLength = 30;

  /**
   * Readonly array of objects containing the field values
   */
  options$: Observable<XpoAdvancedSelectOption[]>;

  /**
   * Readonly flattened array of objects containing the field values
   */
  flatOptions$: Observable<XpoAdvancedSelectOption[]>;

  /**
   * Controls what field on the options array is used for filter value. Defaults to code
   */
  valueField = 'code';

  /**
   * Controls what field on the options array is used for children value. Defaults to children
   */
  childrenField = 'children';

  /** Whether or not only a single option can be selected */
  isSingleSelect: boolean = false;

  /** Whether or not the select all checkbox is shown */
  enableSelectAll: boolean = false;

  private static flattenOptions(options: XpoAdvancedSelectOption[]): XpoAdvancedSelectOption[] {
    const flatResult = options.reduce((acc, option) => {
      let concatVal: any = option;

      if (option.children) {
        concatVal = XpoSelectFilter.flattenOptions(option.children);
      }

      return acc.concat(concatVal);
    }, []);

    return flatResult;
  }

  constructor(params: XpoSelectFilterParams) {
    super(params.field, params.label, XpoSelectFilterComponent);

    this.disabled$ = params.disabled$;
    this.isSingleSelect = !!params.isSingleSelect;
    this.enableSelectAll = !!params.enableSelectAll;

    const options = params.options;

    let mappedOptions$: Observable<XpoAdvancedSelectOption[]>;

    if (options instanceof Array) {
      mappedOptions$ = of(this.normalizeOptions(options || []));
    } else if (options instanceof Observable) {
      mappedOptions$ = options.pipe(map((opt: any[]) => this.normalizeOptions(opt || [])));
    } else if (options instanceof Promise) {
      mappedOptions$ = from(options).pipe(map((opt: any[]) => this.normalizeOptions(opt || [])));
    } else {
      mappedOptions$ = of(this.normalizeOptions(options()));
    }

    this.options$ = mappedOptions$.pipe(shareReplay());
    this.flatOptions$ = this.options$.pipe(
      map(XpoSelectFilter.flattenOptions),
      shareReplay()
    );
  }

  getDisplayValue(val: any | any[]): Observable<string> {
    return combineLatest([this.options$, this.flatOptions$]).pipe(
      map(([options, flatOptions]) => {
        if (!options || !val || (!this.isSingleSelect && (!val.length || val.length === flatOptions.length))) {
          return 'Any';
        }

        let displayValue = '';

        if (this.isSingleSelect) {
          const targetOption = options.find((opt) => opt.value === val);
          displayValue = targetOption ? targetOption.label : 'Any';
        } else {
          const checkedOptions = flatOptions.filter((opt) => val.some((v) => v === opt.value));
          displayValue = checkedOptions.map((opt) => opt.label).join(', ');
        }

        return displayValue.length > this.maxDisplayValueCharLength
          ? displayValue.substr(0, this.maxDisplayValueCharLength - 1) + '...'
          : displayValue;
      })
    );
  }

  private normalizeOptions(options: any[]): XpoAdvancedSelectOption[] {
    return options.map((o: any) => {
      return {
        label: o[this.labelField],
        value: o[this.valueField],
        children:
          o[this.childrenField] && o[this.childrenField].length ? this.normalizeOptions(o[this.childrenField]) : null,
      };
    });
  }
}
