
export enum ApiTypeAttributeDataTypeCd {
	ANY = 'any',
	ANY_URI = 'anyURI',
	BOOLEAN = 'boolean',
	BYTE = 'byte',
	DATE = 'date',
	DATE_TIME = 'dateTime',
	DECIMAL = 'decimal',
	DOUBLE = 'double',
	INT = 'int',
	INTEGER = 'integer',
	LONG = 'long',
	OBJECT = 'object',
	SHORT = 'short',
	STRING = 'string',
	TIME = 'time'}

