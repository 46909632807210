import {DeviceApplicationCd} from '../';
import {EnumHelper} from './enum-helper';

export class DeviceApplicationCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DeviceApplicationCd ) {
      this._values.push(DeviceApplicationCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DeviceApplicationCd {

    for ( const obj in DeviceApplicationCd ) {
      if (DeviceApplicationCd[obj] === value){
        return DeviceApplicationCd[obj] as DeviceApplicationCd;
      }
    }
  }
}

const DeviceApplicationCdHelper = new DeviceApplicationCdHelperEnumHelperClass();
export default DeviceApplicationCdHelper;
