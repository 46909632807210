import {ClaimChargeabilityReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimChargeabilityReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimChargeabilityReasonCd ) {
      this._values.push(ClaimChargeabilityReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimChargeabilityReasonCd {

    for ( const obj in ClaimChargeabilityReasonCd ) {
      if (ClaimChargeabilityReasonCd[obj] === value){
        return ClaimChargeabilityReasonCd[obj] as ClaimChargeabilityReasonCd;
      }
    }
  }
}

const ClaimChargeabilityReasonCdHelper = new ClaimChargeabilityReasonCdHelperEnumHelperClass();
export default ClaimChargeabilityReasonCdHelper;
