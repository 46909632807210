import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'xpo-download-button-icon',
  templateUrl: './download-button-icon.component.html',
  styleUrls: ['./download-button-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-DownloadButtonIcon' },
})
export class XpoDownloadButtonIcon {
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  update(): void {
    this.changeDetectorRef.markForCheck();
  }
}
