import { AfterViewInit, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import moment from 'moment';

@Component({
  selector: 'xpo-datepicker-cell-editor',
  templateUrl: './datepicker-editor.component.html',
  styleUrls: ['./datepicker-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-AgGrid-cellEditor--datepicker',
  },
})
export class DatepickerEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  params: any;
  value: string;
  dateControl = new FormControl();

  @ViewChild('picker') selectRef: MatDatepicker<any>;

  ngAfterViewInit(): void {
    this.selectRef.open();
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.dateControl.setValue(this.params.value);
  }

  onFocusOut(e): void {
    if (!e.relatedTarget) {
      this.params.api.stopEditing();
    }
  }

  onOptionSelected(): void {
    this.params.api.stopEditing();
  }

  getValue(): any {
    return moment(this.dateControl.value || this.params.value).format('YYYY-MM-DD');
  }
}
