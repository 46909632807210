import { ChangeDetectorRef, Component } from '@angular/core';
import { XpoConditionalFilterComponent } from '../conditional-filter.component';
import {
  XpoConditionEmptyComponent,
  XpoConditionIsNotEmptyComponent,
  XpoConditionTimeAfterComponent,
  XpoConditionTimeAfterSameComponent,
  XpoConditionTimeBeforeComponent,
  XpoConditionTimeBeforeSameComponent,
  XpoConditionTimeBetweenComponent,
  XpoConditionTimeEqualsComponent,
  XpoConditionTimeIsComponent,
  XpoConditionTimeNotBetweenComponent,
  XpoFilterCondition,
} from '../conditions';

@Component({
  templateUrl: '../conditional-filter.component.html',
  styleUrls: ['../conditional-filter.component.scss'],
})
export class XpoTimeFilterComponent extends XpoConditionalFilterComponent {
  defaultConditions = [
    XpoConditionTimeIsComponent,
    XpoConditionTimeEqualsComponent,
    XpoConditionTimeAfterComponent,
    XpoConditionTimeAfterSameComponent,
    XpoConditionTimeBeforeComponent,
    XpoConditionTimeBeforeSameComponent,
    XpoConditionTimeBetweenComponent,
    XpoConditionTimeNotBetweenComponent,
    XpoConditionEmptyComponent,
    XpoConditionIsNotEmptyComponent,
  ];
}
