import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { XpoAppSwitcherApplication } from './auth-app-switcher-application.model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'xpo-auth-app-switcher',
  templateUrl: './auth-app-switcher.component.html',
  styleUrls: ['./auth-app-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-AuthAppSwitcher' },
})
export class AuthAppSwitcherComponent {
  @Input() applications: XpoAppSwitcherApplication[];

  @Input() allApplicationsURL: string;
}
