import { CdkDrag, Point } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { get as _get } from 'lodash';
import { XpoLtlDialogTitleService } from './dialog-title.service';

export interface XpoLtlDialogTitleComponentOptions {
  closeButton?: boolean; // true (default) to display close button in top-right
  dragRootElement?: string; // css of root element for the cdkDragRootElement (default: '.cdk-overlay-pane')
  dragBoundaryElement?: string; // css of boundary element to contain dragger. empty for no bounds (default: '.cdk-overlay-container')
  raiseOnClick?: boolean; // true (default) to bring this dialog to top of view stack
  moveable?: boolean; // true (default) to allow user to move dialog using the header bar
  headerClass?: string; // class for h1 element
}

/**
 * Implement Dialog title that allows dragging the dialog within the container, and
 * bringing the selected dialog to the front of other dialogs.
 */
@Component({
  selector: 'xpo-ltl-dialog-title',
  templateUrl: './dialog-title.component.html',
  styleUrls: ['./dialog-title.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpoLtlDialogTitleComponent implements OnInit {
  @ViewChild(CdkDrag) cdkDrag: CdkDrag;

  constructor(private xpoLtlDialogTitleService: XpoLtlDialogTitleService) {}
  @Input()
  options: XpoLtlDialogTitleComponentOptions;

  private defaultOptions: XpoLtlDialogTitleComponentOptions = {
    closeButton: true,
    dragRootElement: '.cdk-overlay-pane',
    dragBoundaryElement: '.cdk-overlay-container',
    headerClass: '',
    raiseOnClick: true,
    moveable: true,
  };

  @HostListener('mousedown', ['$event'])
  handleMouseDown(event: Event) {
    if (this.options.raiseOnClick) {
      this.xpoLtlDialogTitleService.focusDialog(_get(event.target, 'id'));
    }
  }

  ngOnInit() {
    this.options = {
      ...this.defaultOptions,
      ...this.options,
    };
  }

  setFreeDragPosition(freeDragPosition: Point) {
    this.cdkDrag?._dragRef?.setFreeDragPosition(freeDragPosition);
  }

  getFreeDragPosition(): Point {
    return this.cdkDrag?._dragRef?.getFreeDragPosition();
  }

  dragReset(): void {
    this.cdkDrag?._dragRef?.reset();
  }
}
