import {BillToRelationCd} from '../';
import {EnumHelper} from './enum-helper';

export class BillToRelationCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BillToRelationCd ) {
      this._values.push(BillToRelationCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BillToRelationCd {

    for ( const obj in BillToRelationCd ) {
      if (BillToRelationCd[obj] === value){
        return BillToRelationCd[obj] as BillToRelationCd;
      }
    }
  }
}

const BillToRelationCdHelper = new BillToRelationCdHelperEnumHelperClass();
export default BillToRelationCdHelper;
