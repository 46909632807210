import { ReplaySubject } from 'rxjs';
import { XpoFilterCriteria } from '../../../filters/index';
import { XpoBoardState, XpoBoardStateViewer } from '../../../models/index';
import { XpoBoardApiDispatcherService } from '../board-api-dispatcher.service';
import { XpoBoardApiAction } from './board-api-actions.model';

export class XpoBoardApi implements XpoBoardStateViewer {
  stateChange$ = new ReplaySubject<XpoBoardState>(1);

  constructor(private boardApiDispatcher: XpoBoardApiDispatcherService) {}

  activateView(viewId: string): void {
    this.boardApiDispatcher.executeAction(XpoBoardApiAction.ActivateView, viewId);
  }

  hideView(viewId: string): void {
    this.boardApiDispatcher.executeAction(XpoBoardApiAction.HideView, viewId);
  }

  selectRecords(records: any[]): void {
    this.stateChange$.next({ selection: records, source: 'BOARD-API-RECORDS-SELECTION' });
  }

  toggleDataDrawer(selection: any[]): void {
    this.boardApiDispatcher.executeAction(XpoBoardApiAction.ToggleDataDrawer, selection);
  }

  applyFiltersCriteria(criteria: XpoFilterCriteria): void {
    this.boardApiDispatcher.executeAction(XpoBoardApiAction.ApplyFiltersCriteria, criteria);
  }

  applyFilterCriterion(field: string, criterion: any): void {
    this.boardApiDispatcher.executeAction(XpoBoardApiAction.ApplyFilterCriterion, field, criterion);
  }
}
