
export enum LocationOperationTypeCd {
	BUSINESS_COMMERCIAL = 'BusinessCommercial',
	CONSTR_SITE = 'ConstrSite',
	CONSTR_UTILITY_SITE = 'ConstrUtilitySite',
	DISTRIBUTION_CENTER = 'DistributionCenter',
	EXHIBITION_CONV_CTR = 'ExhibitionConvCtr',
	GOVT_AIRPORT = 'GovtAirport',
	GOVT_GAS_OIL_FIELD = 'GovtGasOilField',
	GOVT_MILITARY = 'GovtMilitary',
	GOVT_MINE = 'GovtMine',
	GOVT_OTHER = 'GovtOther',
	GOVT_POST_OFFICE = 'GovtPostOffice',
	GOVT_PRISON_CORRECTION = 'GovtPrisonCorrection',
	GROCERY_WAREHOUSE = 'GroceryWarehouse',
	PIER_CFS = 'PierCFS',
	PIER_WHARF = 'PierWharf',
	RESIDENTIAL_BUSINESS = 'ResidentialBusiness',
	RES_APARTMENT = 'ResApartment',
	RES_CAMP = 'ResCamp',
	RES_FARM = 'ResFarm',
	RES_HOME = 'ResHome',
	RES_PLACE_OF_WORSHIP = 'ResPlaceOfWorship',
	RES_PUBLIC_STORAGE = 'ResPublicStorage',
	RES_SCHOOL = 'ResSchool',
	SHOPPING_MALL = 'ShoppingMall',
	UNVERSITY_COLLEGE = 'UnversityCollege'}

