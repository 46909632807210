import {GoalTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class GoalTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in GoalTypeCd ) {
      this._values.push(GoalTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): GoalTypeCd {

    for ( const obj in GoalTypeCd ) {
      if (GoalTypeCd[obj] === value){
        return GoalTypeCd[obj] as GoalTypeCd;
      }
    }
  }
}

const GoalTypeCdHelper = new GoalTypeCdHelperEnumHelperClass();
export default GoalTypeCdHelper;
