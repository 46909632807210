import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { XpoAuthenticationService } from '@xpo/ngx-auth';
import { AuthService } from 'ngx-auth';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { XpoLtlAuthConfigLoaderService } from './auth-config-loader.service';
import { RegionInfo } from './model/region-info.model';

const OVERRIDE_KEY = '.OverrideToken';
const ACCESS_KEY = '.AccessToken';
const ACCESS_TOKEN_EXPIRATION_KEY = '.AccessTokenExpiration';
const REFRESH_KEY = '.RefreshToken';
const REFRESH_TOKEN_EXPIRATION_KEY = '.RefreshTokenExpiration';

/**
 * This service is responsible for authenticating with WSO2 after SSO authentication
 *
 * @version 9.1.2
 */
@Injectable({ providedIn: 'root' })
export class XpoLtlAuthenticationService implements AuthService {
  private interruptedUrl: string;
  private readonly appName: string;
  private readonly appDocRef: AngularFirestoreDocument;
  private svcAcct: string;
  private svcAcctKey: string;

  constructor(
    private firestore: AngularFirestore,
    private xpoAuthenticationService: XpoAuthenticationService,
    private authConfigService: XpoLtlAuthConfigLoaderService
  ) {
    // Loaded from config.json
    const appName = authConfigService.appName;
    if (!appName || appName === '') {
      throw new Error('Missing appName Configuration.');
    }
    this.appName = appName.toLowerCase();
    this.appDocRef = this.firestore.collection('appKeyConfigs').doc(this.appName);
  }

  getSupportedRegions$(): Observable<string[]> {
    return this.appDocRef
      .collection('regions')
      .get()
      .pipe(
        map((snapshot) => {
          const regions = new Array<string>();
          snapshot.forEach((region) => regions.push(region.id));
          return regions;
        })
      );
  }

  initAuthSetup$(region: string): Observable<RegionInfo> {
    if (!region || region.length === 0) {
      throw new Error('Missing Region Configuration.');
    }
    const regionUpper = region.toUpperCase();
    return this.appDocRef
      .collection('regions')
      .doc<RegionInfo>(regionUpper)
      .get()
      .pipe(
        map((doc) => {
          if (!doc.exists) {
            throw new Error(`RegionInfo is not setup properly for App: ${this.appName} of Region: ${regionUpper}.`);
          }
          return new RegionInfo(doc.data());
        }),
        tap((info) => {
          this.authConfigService.consumerKey = info.consumerKey;
          this.authConfigService.consumerSecret = info.consumerSecret;
          if (info.svcAcct && info.svcAcctKey) {
            this.svcAcct = info.svcAcct;
            this.svcAcctKey = info.svcAcctKey;
          }
        })
      );
  }

  getGoogleApiLicenseKey$(): Observable<string> {
    return this.appDocRef.get().pipe(map((doc) => doc.data().googleApiLicenseKey));
  }

  logout(): void {
    sessionStorage.removeItem(`${this.appName}${ACCESS_KEY}`);
    sessionStorage.removeItem(`${this.appName}${REFRESH_KEY}`);
    sessionStorage.removeItem(`${this.appName}${ACCESS_TOKEN_EXPIRATION_KEY}`);
    sessionStorage.removeItem(`${this.appName}${REFRESH_TOKEN_EXPIRATION_KEY}`);
  }

  // #region AuthService implementation
  isAuthorized(): Observable<boolean> {
    const isAuthorized = Date.now() < this.getAccessTokenExpiration();

    const accessKey = `${this.appName}${ACCESS_KEY}`;
    if (!isAuthorized && !!sessionStorage.getItem(accessKey)) {
      sessionStorage.removeItem(accessKey);
    }

    return of(isAuthorized);
  }

  getAccessToken(): Observable<string> {
    const overrideKey = `${this.appName}${OVERRIDE_KEY}`;
    const overrideToken = sessionStorage.getItem(overrideKey);
    if (overrideToken && overrideToken !== '') {
      return of(overrideToken);
    } else {
      const accessKey = `${this.appName}${ACCESS_KEY}`;
      const accessTokenExpiration = this.getAccessTokenExpiration();

      // remove the access token if it has expired
      if (Date.now() >= accessTokenExpiration) {
        sessionStorage.removeItem(accessKey);
      }

      const accessToken = sessionStorage.getItem(accessKey);
      if (accessToken) {
        // we have a valid token
        return of(accessToken);
      } else {
        // need to load a token
        return this.loadTokens();
      }
    }
  }

  refreshToken(): Observable<any> {
    const refreshKey = `${this.appName}${REFRESH_KEY}`;
    const refreshTokenExpiration = this.getRefreshTokenExpiration();

    // remove the refresh token if it has expired
    if (Date.now() >= refreshTokenExpiration) {
      sessionStorage.removeItem(refreshKey);
    }

    // return the refresh token, if it exists
    const refreshToken = sessionStorage.getItem(refreshKey);
    if (refreshToken) {
      sessionStorage.removeItem(refreshKey); // can only be used once
      // we have a valid refresh token, so try to refresh the access token with it
      const body = new URLSearchParams();
      body.append('grant_type', 'refresh_token');
      body.append('refresh_token', refreshToken);
      return this.fetchToken(body).pipe(
        catchError((err) => {
          return this.loadTokens();
        })
      );
    } else {
      // no refresh token, so get all new tokens
      return this.loadTokens();
    }
  }

  refreshShouldHappen(response: HttpErrorResponse): boolean {
    return response.status === 401;
  }

  verifyTokenRequest(url: string): boolean {
    return url.endsWith('refresh-token');
  }

  getInterruptedUrl(): string {
    return this.interruptedUrl;
  }

  setInterruptedUrl(url: string): void {
    this.interruptedUrl = url;
  }

  fetchTokenByPwd(): Observable<string> {
    if (!this.svcAcct || !this.svcAcctKey || this.svcAcct === '' || this.svcAcctKey === '') {
      throw new Error(`initAuthSetup$ needs to be finished before fetching token by pwd | App: ${this.appName}`);
    }
    const body = new URLSearchParams();
    const scopeOptions = this.authConfigService.scopeOptions;

    body.append('grant_type', 'password');
    body.append('username', this.svcAcct);
    body.append('password', this.svcAcctKey);
    if (scopeOptions && scopeOptions.length > 0) {
      body.append('scope', this.authConfigService.scopeOptions.join(' '));
    }
    return this.request('POST', body).pipe(
      catchError((val) => {
        return throwError(val);
      }),
      map((xhr) => {
        const responseJson = JSON.parse(xhr.responseText);
        return responseJson.access_token;
      })
    );
  }

  /**
   * Fetch new WSO2 tokens
   */
  private loadTokens(): Observable<string> {
    return this.xpoAuthenticationService.getUser$().pipe(
      filter((user) => !!user),
      switchMap((user) => {
        const body = new URLSearchParams();
        const scopeOptions = this.authConfigService.scopeOptions;

        body.append('grant_type', this.authConfigService.grantType);
        body.append('assertion', user.access_token);
        if (scopeOptions && scopeOptions.length > 0) {
          body.append('scope', this.authConfigService.scopeOptions.join(' '));
        }
        return this.fetchToken(body);
      })
    );
  }
  /**
   * Return the token string from the API, re-authenticating if needed
   */
  private fetchToken(urlSearchParams: URLSearchParams): Observable<string> {
    return this.request('POST', urlSearchParams).pipe(
      catchError((val) => {
        return throwError(val);
      }),
      tap((xhr) => {
        // Save token information into storage
        const responseJson = JSON.parse(xhr.responseText);
        sessionStorage.setItem(`${this.appName}${ACCESS_KEY}`, responseJson.access_token);
        const accessTokenExpiration: number = Date.now() + (responseJson.expires_in - 240) * 1000;
        sessionStorage.setItem(`${this.appName}${ACCESS_TOKEN_EXPIRATION_KEY}`, `${accessTokenExpiration}`);

        sessionStorage.setItem(`${this.appName}${REFRESH_KEY}`, responseJson.refresh_token);
        const refreshTokenExpiration: number = Date.now() + (responseJson.expires_in - 240) * 2000;
        sessionStorage.setItem(`${this.appName}${REFRESH_TOKEN_EXPIRATION_KEY}`, `${refreshTokenExpiration}`);
      }),
      map((xhr) => {
        const responseJson = JSON.parse(xhr.responseText);
        return responseJson.access_token;
      })
    );
  }

  /**
   * Make a HTTP request
   */
  private request(method: string, urlSearchParams: URLSearchParams): Observable<XMLHttpRequest> {
    const tokenUrl = `${this.authConfigService.apiUrl}/token`;
    const consumerKey = this.authConfigService.consumerKey;
    const consumerSecret = this.authConfigService.consumerSecret;

    return new Observable<XMLHttpRequest>((observer) => {
      const xhr = new XMLHttpRequest();
      xhr.open(method, tokenUrl);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.setRequestHeader(
        'Authorization',
        `Basic ${btoa(unescape(encodeURIComponent(`${consumerKey}:${consumerSecret}`)))}`
      );
      xhr.setRequestHeader('Accept', 'application/json');

      xhr.addEventListener('error', (event) => {
        observer.error(event);
      });

      xhr.addEventListener('abort', (event) => {
        observer.error(event);
      });

      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            observer.next(xhr);
            observer.complete();
            // tslint:disable-next-line:no-bitwise
          } else if ((xhr.status & 400) === 400) {
            // Getting an error here probably means we need to redo our SSO login
            if (xhr.status === 400 && (xhr.response as string).includes('"error":"invalid_grant"')) {
              // WSO2 returns a 400 when the JWT token is not valid. It needs to be 401 so that ngx-auth
              // weill refresh the token and we can try this all again.
              const modifiedXhr = {
                status: 401,
                statusText: 'Unauthorized',
                responseURL: xhr.responseURL,
              };
              observer.error(modifiedXhr);
            } else {
              observer.error(xhr);
            }
          } else {
            observer.error(xhr);
          }
        }
      });

      xhr.send(urlSearchParams);
    });
  }

  getAccessTokenExpiration(): number {
    const timeMilliSecString = sessionStorage.getItem(`${this.appName}${ACCESS_TOKEN_EXPIRATION_KEY}`);
    return parseInt(timeMilliSecString, 10);
  }

  getRefreshTokenExpiration(): number {
    const timeMilliSecString = sessionStorage.getItem(`${this.appName}${REFRESH_TOKEN_EXPIRATION_KEY}`);
    return parseInt(timeMilliSecString, 10);
  }
}
