import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { XpoCommonModule, XpoIconModule, XpoLtlPopoverModule, XpoSelectModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoAgGridComponent } from './ag-grid.component';
import { DatepickerEditorComponent } from './cell-editors/datepicker-editor/datepicker-editor.component';
import { NumericEditorComponent } from './cell-editors/numeric-editor/numeric-editor.component';
import { SelectEditorComponent } from './cell-editors/select-editor/select-editor.component';
import { CellEditionErrorCellRendererComponent, TransposePopoverCellRendererComponent } from './cell-renderers/index';
@NgModule({
  imports: [
    CommonModule,
    AgGridModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    XpoIconModule,
    XpoLtlPopoverModule,
    XpoSelectModule,
    XpoCommonModule,
    MatTooltipModule,
  ],
  declarations: [
    XpoAgGridComponent,
    DatepickerEditorComponent,
    NumericEditorComponent,
    SelectEditorComponent,
    TransposePopoverCellRendererComponent,
    CellEditionErrorCellRendererComponent,
  ],
  exports: [
    XpoAgGridComponent,
    DatepickerEditorComponent,
    NumericEditorComponent,
    SelectEditorComponent,
    CellEditionErrorCellRendererComponent,
  ],
  entryComponents: [TransposePopoverCellRendererComponent],
})
export class XpoAgGridModule {}
