import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormatValidationService } from '@xpo-ltl/common-services';
import {
  ListOdsShipmentsResp,
  ListOdsShipmentsRqst,
  OdsShipment,
  ShipmentOdsApiService,
} from '@xpo-ltl/sdk-shipmentods';
import {
  concat as _concat,
  defaultTo as _defaultTo,
  find as _find,
  first as _first,
  isEqual as _isEqual,
  map as _map,
  sortBy as _sortBy,
} from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProFormatterPipe } from '../../pipes/pro-formatter/pro-formatter.pipe';
import { XpoLtlShipmentDescriptor } from '../shipment-descriptor';

@Component({
  selector: 'xpo-ltl-shipment-selector',
  templateUrl: './shipment-selector.component.html',
  styleUrls: ['./shipment-selector.component.scss'],
  encapsulation: ViewEncapsulation.Emulated, // allow use of :host
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpoLtlShipmentSelectorComponent implements AfterContentInit {
  @Input() shipments: XpoLtlShipmentDescriptor[];
  @Input() searchable: boolean = false;
  @Output() selectionChange = new EventEmitter<XpoLtlShipmentDescriptor>();

  selectedShipment: XpoLtlShipmentDescriptor;

  private invalidSubject = new BehaviorSubject<boolean>(false);
  readonly invalid$ = this.invalidSubject.asObservable();

  proSearchValue: string;

  private shipmentListSubject = new BehaviorSubject<XpoLtlShipmentDescriptor[]>([]);
  readonly shipmentList$ = this.shipmentListSubject.asObservable();

  constructor(
    private proFormatterPipe: ProFormatterPipe,
    private formValidationService: FormatValidationService,
    private shipmentOdsApiService: ShipmentOdsApiService
  ) {}

  ngAfterContentInit() {
    // make sure all ProNbrs are in 10-digit format
    const shipmentList = _defaultTo(
      _sortBy(
        _map(this.shipments, (s) => {
          return { ...s, proNbr: this.proFormatterPipe.transform(s.proNbr, 10) };
        }),
        ['proNbr']
      ),
      []
    );

    this.shipmentListSubject.next(shipmentList);
    this.selectShipment(_first(shipmentList));
    this.clearSearch();
  }

  selectShipment(shipment: XpoLtlShipmentDescriptor) {
    if (!_isEqual(shipment, this.selectedShipment)) {
      this.selectedShipment = shipment;
      this.selectionChange.emit(this.selectedShipment);
    }
  }

  isSearchValidPro(): boolean {
    return true;
  }

  clearSearch() {
    this.proSearchValue = '';
    this.invalidSubject.next(false);
  }

  handleSearchForShipments() {
    const searchForPro = this.proFormatterPipe.transform(this.proSearchValue, 10);
    this.proSearchValue = searchForPro;

    this.invalidSubject.next(!this.formValidationService.isValidProNumber(searchForPro));

    if (
      !this.invalidSubject.value &&
      !_find(this.shipmentListSubject.value, (shipment) => shipment.proNbr === this.proSearchValue)
    ) {
      // search for PRO shipments that have the requested PRO and add those to the list.
      const request = new ListOdsShipmentsRqst();
      request.proNbrs = [this.proSearchValue];
      this.shipmentOdsApiService
        .listOdsShipments(request)
        .pipe(
          map((resp: ListOdsShipmentsResp) => {
            return _map(resp.odsShipments, (shipment: OdsShipment) => {
              return {
                proNbr: this.proFormatterPipe.transform(shipment.shipment.proNbr, 10),
                pickupDate: `${shipment.shipment.pickupDate}`,
                shipmentInstId: shipment.shipment.shipmentInstId,
              } as XpoLtlShipmentDescriptor;
            });
          })
        )
        .subscribe((newShipments: XpoLtlShipmentDescriptor[]) => {
          const newList = _concat(this.shipmentListSubject.value, newShipments);
          const shipmentToSelect = _first(newShipments);
          const shipmentList = _sortBy(newList, ['proNbr']);
          this.shipmentListSubject.next(shipmentList);
          this.clearSearch();
          this.selectShipment(shipmentToSelect);
        });
    }
  }
}
