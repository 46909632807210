import {CurrencyCd} from '../';
import {EnumHelper} from './enum-helper';

export class CurrencyCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CurrencyCd ) {
      this._values.push(CurrencyCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CurrencyCd {

    for ( const obj in CurrencyCd ) {
      if (CurrencyCd[obj] === value){
        return CurrencyCd[obj] as CurrencyCd;
      }
    }
  }
}

const CurrencyCdHelper = new CurrencyCdHelperEnumHelperClass();
export default CurrencyCdHelper;
