
export enum CustomerContactRoleCd {
	ACCOUNTS_PAYABLE = 'AccountsPayable',
	BALANCE_DUE = 'BalanceDue',
	CLAIMS = 'Claims',
	DELIVERY = 'Delivery',
	EDI = 'EDI',
	INVOICING = 'Invoicing',
	NOA = 'NOA',
	OPERATIONS = 'Operations',
	OSD = 'OSD',
	PAYMENT_PLAN = 'PaymentPlan',
	PICKUP = 'Pickup',
	PURCHASING = 'Purchasing',
	SALES = 'Sales'}
