import {ApiContractTypeCategoryCd} from '../';
import {EnumHelper} from './enum-helper';

export class ApiContractTypeCategoryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ApiContractTypeCategoryCd ) {
      this._values.push(ApiContractTypeCategoryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ApiContractTypeCategoryCd {

    for ( const obj in ApiContractTypeCategoryCd ) {
      if (ApiContractTypeCategoryCd[obj] === value){
        return ApiContractTypeCategoryCd[obj] as ApiContractTypeCategoryCd;
      }
    }
  }
}

const ApiContractTypeCategoryCdHelper = new ApiContractTypeCategoryCdHelperEnumHelperClass();
export default ApiContractTypeCategoryCdHelper;
