import {CustomerContactRoleCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerContactRoleCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerContactRoleCd ) {
      this._values.push(CustomerContactRoleCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerContactRoleCd {

    for ( const obj in CustomerContactRoleCd ) {
      if (CustomerContactRoleCd[obj] === value){
        return CustomerContactRoleCd[obj] as CustomerContactRoleCd;
      }
    }
  }
}

const CustomerContactRoleCdHelper = new CustomerContactRoleCdHelperEnumHelperClass();
export default CustomerContactRoleCdHelper;
