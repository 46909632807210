import {UndeliveredReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class UndeliveredReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in UndeliveredReasonCd ) {
      this._values.push(UndeliveredReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): UndeliveredReasonCd {

    for ( const obj in UndeliveredReasonCd ) {
      if (UndeliveredReasonCd[obj] === value){
        return UndeliveredReasonCd[obj] as UndeliveredReasonCd;
      }
    }
  }
}

const UndeliveredReasonCdHelper = new UndeliveredReasonCdHelperEnumHelperClass();
export default UndeliveredReasonCdHelper;
