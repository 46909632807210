/**
 * (Ancel):
 *
 * Created this component by taking pieces of Material's Menu component(https://material.angular.io/components/menu) and
 * Tooltip component (https://material.angular.io/components/tooltip/)
 *
 * For the XpoPopover I utilized the functionality of MatMenu but removed the MenuItem and ParentMenu logic.
 *
 */
import { AnimationEvent } from '@angular/animations';
import { Direction } from '@angular/cdk/bidi';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HorizontalConnectionPos, VerticalConnectionPos } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { matMenuAnimations, MatMenuContent } from '@angular/material/menu';
import { TooltipPosition } from '@angular/material/tooltip';
import { Subject } from 'rxjs';
import { ESCAPE } from '../keycodes/keycodes';
import { ClosedEmitterTypes } from './enums/closed-emitter-types.enum';
import { TriggerType } from './enums/trigger-type.enum';
import { XpoPopoverTitle } from './popover-title.directive';

/** Default `mat-menu` options that can be overridden. */
export interface XpoPopoverDefaultOptions {
  /** The x-axis position of the menu. */
  position: TooltipPosition;

  /** The x-axis or y-axis position of the caret. */
  caretPosition: HorizontalConnectionPos | VerticalConnectionPos;

  /** Class to be applied to the menu's backdrop. */
  backdropClass: string;

  /** Whether the menu has a backdrop. */
  hasBackdrop?: boolean;
}

/** Injection token to be used to override the default options for `mat-menu`. */
export const XPO_POPOVER_DEFAULT_OPTIONS = new InjectionToken<XpoPopoverDefaultOptions>('xpo-popover-default-options', {
  providedIn: 'root',
  factory: XPO_POPOVER_DEFAULT_OPTIONS_FACTORY,
});

export const OVERLAY_TRANSPARENT_BACKDROP_CLASS: string = 'cdk-overlay-transparent-backdrop';
/** @docs-private */
export function XPO_POPOVER_DEFAULT_OPTIONS_FACTORY(): XpoPopoverDefaultOptions {
  return {
    position: 'below',
    caretPosition: 'center',
    hasBackdrop: true,
    backdropClass: OVERLAY_TRANSPARENT_BACKDROP_CLASS,
  };
}
/**
 * Start elevation for the menu panel.
 * @docs-private
 */
const XPO_POPOVER_BASE_ELEVATION = 2;

// Directive for the Content tag

@Directive({
  selector: 'xpo-popover-content',
  host: { class: 'xpo-Popover-content' },
})
export class XpoPopoverContent {}

@Component({
  selector: 'xpo-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-Popover',
  },
  exportAs: 'xpoPopover',
  animations: [matMenuAnimations.transformMenu, matMenuAnimations.fadeInItems],
})
export class XpoPopover implements OnInit, OnDestroy {
  private positionValue: TooltipPosition = this.defaultOptions.position;
  private caretPositionValue: HorizontalConnectionPos | VerticalConnectionPos = this.defaultOptions.caretPosition;
  private previousElevationValue: string;
  private colorValue: ThemePalette = 'primary';
  private hideCloseButtonValue: boolean = false;
  triggerType: string = TriggerType.Click;

  /** Config object to be passed into the menu's ngClass */
  classList: { [key: string]: boolean } = {};

  /** Current state of the panel animation. */
  panelAnimationState: 'void' | 'enter' = 'void';

  /** Emits whenever an animation on the menu completes. */
  animationDone = new Subject<AnimationEvent>();

  /** Whether the menu is animating. */
  isAnimating: boolean;

  /** Layout direction of the menu. */
  direction: Direction;

  /** Tracks the original intended position  */
  private originalPosition: TooltipPosition;

  /** Closing disabled on popover */
  closeDisabled = false;

  /** Class to be added to the backdrop element. */
  @Input() backdropClass: string = this.defaultOptions.backdropClass;

  @Input()
  get hideCloseButton(): boolean {
    return this.hideCloseButtonValue;
  }
  set hideCloseButton(value: boolean) {
    this.hideCloseButtonValue = coerceBooleanProperty(value);
  }

  /** Position of the menu in the X axis. */
  @Input()
  get position(): TooltipPosition {
    return this.positionValue;
  }
  set position(value: TooltipPosition) {
    if (this.positionValue !== value) {
      this.positionValue = value;
      this.setPopoverClasses();
    }
  }

  /** The x-axis or y-axis position of the caret. */
  @Input()
  get caretPosition(): HorizontalConnectionPos | VerticalConnectionPos {
    return this.caretPositionValue;
  }
  set caretPosition(value: HorizontalConnectionPos | VerticalConnectionPos) {
    if (this.caretPositionValue !== value) {
      this.caretPositionValue = value;
      this.setPopoverClasses();
    }
  }

  @Input()
  get color(): ThemePalette {
    return this.colorValue;
  }
  set color(value: ThemePalette) {
    if (this.colorValue !== value) {
      this.colorValue = value;
      this.setPopoverClasses();
    }
  }

  /** @docs-private */
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

  /**
   * Menu content that will be rendered lazily.
   * @docs-private
   */
  @ContentChild(MatMenuContent) lazyContent: MatMenuContent;

  @ContentChild(XpoPopoverTitle) title: XpoPopoverTitle;

  /** Whether the menu has a backdrop. */
  @Input()
  get hasBackdrop(): boolean | undefined {
    return this.hasBackdropValue;
  }
  set hasBackdrop(value: boolean | undefined) {
    this.hasBackdropValue = coerceBooleanProperty(value);
  }
  private hasBackdropValue: boolean | undefined = this.defaultOptions.hasBackdrop;

  /**
   * This method takes classes set on the host xpo-popover element and applies them on the
   * menu template that displays in the overlay container.  Otherwise, it's difficult
   * to style the containing menu from outside the component.
   * @param classes list of class names
   */
  @Input('class')
  set panelClass(classes: string) {
    if (classes && classes.length) {
      this.classList = classes.split(' ').reduce((obj: any, className: string) => {
        obj[className] = true;
        return obj;
      }, {});

      this.elementRef.nativeElement.className = '';
    }
  }

  /** Sets the trigger action type */
  @Input('triggerOn')
  set triggerMode(action: string) {
    this.triggerType = action;
    if (this.triggerType === TriggerType.Hover) {
      this.hideCloseButton = true;
    }
  }

  // enum CloseEmitterTypes {}

  /** Event emitted when the menu is closed. */
  @Output() readonly closed: EventEmitter<void | ClosedEmitterTypes.Click | ClosedEmitterTypes.Keydown | ClosedEmitterTypes.Tab> = new EventEmitter<
  void | ClosedEmitterTypes.Click | ClosedEmitterTypes.Keydown | ClosedEmitterTypes.Tab
  >();

  /** Event emitted when mouse leaves the popover component if triggertype is set to hover */
  @Output() mouseLeaveEmitter = new EventEmitter<void>();

  constructor(private elementRef: ElementRef, @Inject(XPO_POPOVER_DEFAULT_OPTIONS) private defaultOptions: XpoPopoverDefaultOptions) {}

  ngOnInit(): void {
    this.originalPosition = this.position;
    this.setPopoverClasses();
  }

  ngOnDestroy(): void {
    this.closed.complete();
    this.mouseLeaveEmitter.complete();
  }

  /** Handle a keyboard event from the menu, delegating to the appropriate action. */
  handleKeydown(event: KeyboardEvent): void {
    const keyCode = event.code;
    switch (keyCode) {
      case ESCAPE:
        this.closed.emit(ClosedEmitterTypes.Keydown);
        event.stopPropagation();
        break;
      default:
    }
  }

  /**
   * Sets the menu panel elevation.
   * @param depth Number of parent menus that come before the menu.
   */
  setElevation(depth: number): void {
    // The elevation starts at the base and increases by one for each level.
    const newElevation = `mat-elevation-z${XPO_POPOVER_BASE_ELEVATION + depth}`;
    const customElevation = Object.keys(this.classList).find((c) => c.startsWith('mat-elevation-z'));

    if (!customElevation || customElevation === this.previousElevationValue) {
      if (this.previousElevationValue) {
        this.classList[this.previousElevationValue] = false;
      }

      this.classList[newElevation] = true;
      this.previousElevationValue = newElevation;
    }
  }

  /**
   * Adds classes to the popover panel based on its position. Can be used by
   * consumers to add specific styling based on the position.
   * @docs-private
   */
  setPopoverClasses(): void {
    const classes = this.classList;
    classes['xpo-Popover--before'] = this.positionValue === 'before';
    classes['xpo-Popover--after'] = this.positionValue === 'after';
    classes['xpo-Popover--above'] = this.positionValue === 'above';
    classes['xpo-Popover--below'] = this.positionValue === 'below';

    classes['xpo-Popover--primary'] = this.colorValue === 'primary';
    classes['xpo-Popover--accent'] = this.colorValue === 'accent';

    classes['xpo-Popover-caret--top'] =
      (this.caretPositionValue === 'top' || this.caretPositionValue === 'start') && (this.positionValue === 'before' || this.positionValue === 'after');
    classes['xpo-Popover-caret--bottom'] =
      (this.caretPositionValue === 'bottom' || this.caretPositionValue === 'end') && (this.positionValue === 'before' || this.positionValue === 'after');
    classes['xpo-Popover-caret--verticalCenter'] = this.caretPositionValue === 'center' && (this.positionValue === 'before' || this.positionValue === 'after');

    classes['xpo-Popover-caret--start'] =
      (this.caretPositionValue === 'top' || this.caretPositionValue === 'start') && (this.positionValue === 'above' || this.positionValue === 'below');
    classes['xpo-Popover-caret--end'] =
      (this.caretPositionValue === 'bottom' || this.caretPositionValue === 'end') && (this.positionValue === 'above' || this.positionValue === 'below');
    classes['xpo-Popover-caret--horizontalCenter'] = this.caretPositionValue === 'center' && (this.positionValue === 'above' || this.positionValue === 'below');
    classes['xpo-Popover--hasTitle'] = !!this.title;
  }

  /** Starts the enter animation. */
  startAnimation(): void {
    // @deletion-target 7.0.0 Combine with resetAnimation.
    this.panelAnimationState = 'enter';
  }

  /** Resets the panel animation to its initial state. */
  resetAnimation(): void {
    // @deletion-target 7.0.0 Combine with startAnimation.
    this.panelAnimationState = 'void';
  }

  /** Callback that is invoked when the panel animation completes. */
  onAnimationDone(event: AnimationEvent): void {
    this.animationDone.next(event);
    this.isAnimating = false;
  }

  resetPosition(): void {
    this.position = this.originalPosition;
  }

  /** Enables close of popover when mouse leaving popover element */
  onMouseLeave(): void {
    if (this.triggerType === TriggerType.Hover) {
      this.closeDisabled = false;
      this.mouseLeaveEmitter.emit();
    }
  }
  /** Disables close of popover when leaving trigger element and mouse over the popover */
  onMouseOver(): void {
    if (this.triggerType === TriggerType.Hover) {
      this.closeDisabled = true;
    }
  }
}
