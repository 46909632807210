import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject, Observable } from 'rxjs';

import { XpoFilterComponentBase, XpoFilterCriteria } from '../models/index';

@Component({
  selector: 'xpo-checkbox-filter',
  templateUrl: 'checkbox-filter.component.html',
  styleUrls: ['checkbox-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-CheckboxFilter', '[attr.id]': 'id' },
})
export class XpoCheckboxFilterComponent extends XpoFilterComponentBase {
  private checkedSource$ = new BehaviorSubject<boolean>(false);

  constructor() {
    super();
    this.inline = true;
  }

  get checked(): boolean {
    return this.checkedSource$.value;
  }
  set checked(value: boolean) {
    this.checkedSource$.next(value);
  }

  get checked$(): Observable<boolean> {
    return this.checkedSource$.asObservable();
  }

  /**
   * Emits Checkbox value change
   */
  onCheckboxClicked(event: MatCheckboxChange): void {
    this.checked = event.checked;
    this.storeCriteria(this.checked);
  }

  protected initialize(): void {}

  protected onCriteriaModified(fieldValue: boolean, criteria: XpoFilterCriteria): void {
    this.checked = !!fieldValue;
  }
}
