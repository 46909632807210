import { ChangeDetectionStrategy, Component, Directive, ViewEncapsulation } from '@angular/core';

@Directive({
  selector: 'xpo-board-header-title',
  host: { class: 'xpo-BoardHeader-title' },
})
export class XpoBoardHeaderTitle {}

@Component({
  selector: 'xpo-board-header',
  templateUrl: 'board-header.component.html',
  styleUrls: ['board-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-BoardHeader xpo-GridBar' },
})
export class XpoBoardHeader {}
