import { ChangeDetectorRef, Component } from '@angular/core';
import { XpoConditionalFilterComponent } from '../conditional-filter.component';
import {
  XpoConditionDateEqualsComponent,
  XpoConditionDateIsComponent,
  XpoConditionDateTimeAfterComponent,
  XpoConditionDateTimeAfterSameComponent,
  XpoConditionDateTimeBeforeComponent,
  XpoConditionDateTimeBeforeSameComponent,
  XpoConditionDateTimeBetweenComponent,
  XpoConditionDateTimeNotBetweenComponent,
  XpoConditionEmptyComponent,
  XpoConditionIsNotEmptyComponent,
} from '../conditions';

@Component({
  templateUrl: '../conditional-filter.component.html',
  styleUrls: ['../conditional-filter.component.scss'],
})
export class XpoDateTimeFilterComponent extends XpoConditionalFilterComponent {
  defaultConditions = [
    XpoConditionDateIsComponent,
    XpoConditionDateEqualsComponent,
    XpoConditionDateTimeAfterComponent,
    XpoConditionDateTimeAfterSameComponent,
    XpoConditionDateTimeBeforeComponent,
    XpoConditionDateTimeBeforeSameComponent,
    XpoConditionDateTimeBetweenComponent,
    XpoConditionDateTimeNotBetweenComponent,
    XpoConditionEmptyComponent,
    XpoConditionIsNotEmptyComponent,
  ];
}
