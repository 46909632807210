import {PrintFBDSDocumentsTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class PrintFBDSDocumentsTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PrintFBDSDocumentsTypeCd ) {
      this._values.push(PrintFBDSDocumentsTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PrintFBDSDocumentsTypeCd {

    for ( const obj in PrintFBDSDocumentsTypeCd ) {
      if (PrintFBDSDocumentsTypeCd[obj] === value){
        return PrintFBDSDocumentsTypeCd[obj] as PrintFBDSDocumentsTypeCd;
      }
    }
  }
}

const PrintFBDSDocumentsTypeCdHelper = new PrintFBDSDocumentsTypeCdHelperEnumHelperClass();
export default PrintFBDSDocumentsTypeCdHelper;
