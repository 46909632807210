import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { XpoMessagingPopoverMessage } from '@xpo-ltl/ngx-ltl-core';
import { ListNotificationsResp, Notification } from '@xpo-ltl/sdk-moduleexecutor';
import { Observable, of, Subject, Subscription, timer } from 'rxjs';
import { concatMap, map, repeatWhen, takeUntil, tap } from 'rxjs/operators';
import { NotificationStatus, NotificationTime } from './enums/notification.enum';
import { NotificationsService } from './services/notifications.service';

@Component({
  selector: 'ltl-xpo-module-notifications',
  templateUrl: './module-notifications.component.html',
  styleUrls: ['./module-notifications.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModuleNotificationsComponent implements OnInit {
  lstMessages$: Observable<any>;
  lastReadNotifications: number;

  unreadMsgs = 0;

  private readonly _stop = new Subject<void>();
  private readonly _start = new Subject<void>();

  @Input() messageData: Observable<Notification[]> = of([]);

  constructor(private notificationsService: NotificationsService) {}

  ngOnInit(): void {
    this.lastReadNotifications = this.notificationsService.getLastReadNotification();

    this.lstMessages$ = timer(0, NotificationTime.FIFTEEN_SECONDS_MS).pipe(
      concatMap((_) => this.sourceCall()),
      concatMap((data) => this.sortSource(data)),
      concatMap((notifications) => this.parseDataToMsg(notifications)),
      takeUntil(this._stop),
      repeatWhen(() => this._start)
    );
  }

  parseDataToMsg(lstNotification: Notification[]): Observable<XpoMessagingPopoverMessage[]> {
    const messages = lstNotification.map((notification: Notification) => {
      const { notificationType, notificationMessage, notificationDateTime } = notification;
      return {
        type:
          notificationDateTime > new Date(this.lastReadNotifications)
            ? NotificationStatus.UNREAD
            : NotificationStatus.READ,
        received: notificationDateTime,
        title: notificationType,
        body: notificationMessage,
        app: null,
        link: null,
      };
    });
    this.unreadMsgs = messages.filter((msg) => msg.type === NotificationStatus.UNREAD).length;
    return of(messages);
  }

  sortSource(lstXmeNotifications): Observable<Notification[]> {
    const source =
      lstXmeNotifications && lstXmeNotifications.notification
        ? lstXmeNotifications.notification.concat(this.messageData)
        : this.messageData;
    return of(source.sort((a, b) => (a.notificationDateTime < b.notificationDateTime ? 1 : -1)));
  }

  sourceCall(): Observable<ListNotificationsResp> {
    /* 
      notificationsSince:
        if never opened a notifications popup then return latest 7 days notifications
        if was opened at least once then return max(three hours, last opened)
      return: unix timestamp (in seconds)
    */
    const notificationsSince =
      this.lastReadNotifications > 0
        ? Math.max(NotificationTime.THREE_HOURS_S, Math.round((Date.now() - this.lastReadNotifications) / 1000))
        : NotificationTime.SEVEN_DAYS_S;
    return this.notificationsService.getNotifications(notificationsSince);
  }

  onClose(): void {
    this._start.next();
  }

  onReadMessages(): void {
    const now = Date.now();
    this.notificationsService.setLastReadNotification(now);
    this.lastReadNotifications = now;
    this.unreadMsgs = 0;
    this._stop.next();
  }
}
