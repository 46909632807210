import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  AuthModule,
  AUTH_SERVICE,
  PUBLIC_FALLBACK_PAGE_URI,
  PROTECTED_FALLBACK_PAGE_URI
} from 'ngx-auth';

import { LoginService } from './services/login.service';
import { AuthenticationService } from './services/authentication.service';
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatDialogModule } from '@angular/material/dialog'
import { MatInputModule } from '@angular/material/input'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatIconModule } from '@angular/material/icon'
import { LOGIN_CONFIG } from './services/login-config';
import { LoginComponent } from './login.component';
import { ConfigManagerModule } from '@xpo-ltl/config-manager';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatSnackBarModule,
    ConfigManagerModule
  ],
  entryComponents: [
    LoginComponent
  ],
  providers: [
    LoginService,
    AuthenticationService,
    { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/' },
    { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/' },
    { provide: AUTH_SERVICE, useClass: AuthenticationService },
  ],
  declarations: [LoginComponent]
})

export class LoginModule {
}

export { LOGIN_CONFIG, ILoginConfig } from './services/login-config';

export { User, XpoLoginUser } from './model/user';

