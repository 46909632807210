import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  XpoButtonModule,
  XpoChipsModule,
  XpoConfirmDialogModule,
  XpoDialogModule,
  XpoDownloadButtonModule,
  XpoIconModule,
  XpoPopoverModule,
  XpoSelectModule,
  XpoUtilsModule,
} from '@xpo-ltl/ngx-ltl-core';
import { XpoAppliedFilters } from '../applied-filters/applied-filters.component';
import { XpoBoardViewsModule } from '../board-views/board-views.module';
import { XpoCellRenderersModule } from '../cell-renderers/index';
import { XpoFiltersModule } from '../filters/filters.module';
import { XpoGridBarModule } from '../grid-bar/grid-bar.module';
import {
  XpoAvailableViews,
  XpoAvailableViewsFilter,
  XpoColumnLevelFilteringToggleComponent,
  XpoDeleteViewConfirmDialog,
  XpoGridColumnSelectionComponent,
  XpoGridRowHeightSelectionComponent,
  XpoGridSettingsComponent,
  XpoSaveViewComponent,
  XpoSaveViewDialog,
} from './board-actions/index';
import {
  XpoBoardDataFetchDisplay,
  XpoBoardNoResultsDisplay,
} from './board-data-fetch-display/board-data-fetch-display.component';
import { XpoGridToolbarModule } from './board-grid-toolbar/board-grid-toolbar.module';
import { XpoBoardHeader, XpoBoardHeaderTitle } from './board-header/board-header.component';
import { XpoBoardPagination } from './board-pagination/board-pagination.component';
import { XpoBoardTotalRecords } from './board-total-records/board-total-records.component';
import { XpoThousandsSeparator } from './board-total-records/thousands-separator.pipe';

import { XpoBoard, XpoBoardActions } from './board.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatTooltipModule,
    XpoBoardViewsModule,
    XpoButtonModule,
    XpoChipsModule,
    XpoConfirmDialogModule,
    XpoDialogModule,
    XpoDownloadButtonModule,
    XpoFiltersModule,
    XpoGridBarModule,
    XpoIconModule,
    XpoPopoverModule,
    XpoUtilsModule,
    XpoGridToolbarModule,
    MatSelectModule,
    XpoSelectModule,
  ],
  declarations: [
    XpoAvailableViews,
    XpoAvailableViewsFilter,
    XpoThousandsSeparator,
    XpoBoard,
    XpoBoardActions,
    XpoBoardDataFetchDisplay,
    XpoBoardHeader,
    XpoBoardHeaderTitle,
    XpoBoardNoResultsDisplay,
    XpoBoardTotalRecords,
    XpoColumnLevelFilteringToggleComponent,
    XpoDeleteViewConfirmDialog,
    XpoGridColumnSelectionComponent,
    XpoGridRowHeightSelectionComponent,
    XpoGridSettingsComponent,
    XpoSaveViewComponent,
    XpoSaveViewDialog,
    XpoAppliedFilters,
    XpoBoardPagination,
  ],
  exports: [
    XpoAvailableViewsFilter,
    XpoThousandsSeparator,
    XpoBoard,
    XpoBoardViewsModule,
    XpoGridBarModule,
    XpoBoardHeader,
    XpoBoardTotalRecords,
    XpoBoardHeaderTitle,
    XpoBoardActions,
    XpoBoardNoResultsDisplay,
    XpoBoardDataFetchDisplay,
    XpoAppliedFilters,
    XpoBoardPagination,
    XpoCellRenderersModule,
    XpoGridToolbarModule,
  ],
  entryComponents: [XpoSaveViewDialog, XpoBoardDataFetchDisplay, XpoDeleteViewConfirmDialog],
})
export class XpoBoardModule {}
