import {ServiceCd} from '../';
import {EnumHelper} from './enum-helper';

export class ServiceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ServiceCd ) {
      this._values.push(ServiceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ServiceCd {

    for ( const obj in ServiceCd ) {
      if (ServiceCd[obj] === value){
        return ServiceCd[obj] as ServiceCd;
      }
    }
  }
}

const ServiceCdHelper = new ServiceCdHelperEnumHelperClass();
export default ServiceCdHelper;
