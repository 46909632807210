import { ChangeDetectionStrategy, Component, Directive, ViewEncapsulation } from '@angular/core';

@Directive({
  selector: '[xpo-card-header-shadow], [xpoCardHeaderShadow]',
  host: {
    class: 'xpo-Card--headerShadow',
  },
})
export class XpoCardHeaderShadow {}

@Directive({
  selector: 'xpo-card-title',
  host: { class: 'xpo-Card-title' },
})
export class XpoCardTitle {}

@Component({
  selector: 'xpo-card-header',
  templateUrl: './card-header.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-Card-header' },
})
export class XpoCardHeader {}
