import { Type } from '@angular/core';

import { XpoFilter } from './filter';
import { XpoFilterComponent } from './filter-component';

/** Filter class to inherit when you want your filter to render inline on the box */
export abstract class XpoInlineFilter extends XpoFilter {
  readonly configuration: any;

  constructor(field: string, label: string, filterComponentType: Type<XpoFilterComponent>) {
    super(field, label, filterComponentType);
  }
}
