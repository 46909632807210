import { CriteriaFunction } from './criteria-function.model';

export class RangeCriteriaFunction implements CriteriaFunction {
  supportsValue(value: any): boolean {
    return value && (!!value.min || !!value.max);
  }

  getFilter<T>(key: string, value: any): (t: T) => boolean {
    return (t: T) => {
      if (!t[key]) {
        return false;
      }

      const normalizeType = (incomingValue: any) => {
        // If the value of the field is a number, return the number,
        // the reason we do this before Date.parse is because numbers are valid dates.
        if (!isNaN(incomingValue)) {
          return incomingValue;
        }

        // If the value is a date, transform it to a date object
        if (!!Date.parse(incomingValue)) {
          return new Date(incomingValue);
        }

        // At this point we assume the value is a string, but most likely if you are doing a range filter properly, you will not hit this.
        return incomingValue;
      };

      const normalizedValue = normalizeType(t[key]);
      const minDate = value.min ? normalizeType(value.min) : null;
      const maxDate = value.max ? normalizeType(value.max) : null;

      let returnValue: boolean;

      if (minDate && maxDate) {
        returnValue = normalizedValue >= minDate && normalizedValue <= maxDate;
      } else {
        returnValue = (minDate ? normalizedValue >= minDate : false) || (maxDate ? normalizedValue <= maxDate : false);
      }

      return returnValue;
    };
  }
}
