import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'xpo-cell-edition-error-cell-renderer',
  templateUrl: './cell-edition-error-cell-renderer.component.html',
  styleUrls: ['./cell-edition-error-cell-renderer.component.scss'],
})
export class CellEditionErrorCellRendererComponent implements ICellRendererAngularComp {
  value: any;
  error: boolean;

  agInit(params): void {
    this.value = params.value;
    this.error = params.error;
  }

  refresh(): boolean {
    return false;
  }
}
