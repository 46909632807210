import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { XpoDialogCloseButtonComponent } from './dialog-close-button/dialog-close-button.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule],
  declarations: [XpoDialogCloseButtonComponent],
  exports: [XpoDialogCloseButtonComponent, MatDialogModule],
})
export class XpoDialogModule {}
