import { Pipe, PipeTransform } from '@angular/core';
import { ChargeToCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'chargeToCd',
})
export class ChargeToCdPipe implements PipeTransform {
  transform(value: ChargeToCd): any {
    switch (value) {
      case ChargeToCd.BOTH:
        return 'Both';
      case ChargeToCd.COLL:
        return 'Collect';
      case ChargeToCd.PPD:
        return 'Prepaid';
      case ChargeToCd.VALID_ONLY_FOR_NON_REV_BILLS:
        return 'Valid only for Non-Revenue bills';
      default:
        return value;
    }
  }
}
