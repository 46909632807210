import {BeaconStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class BeaconStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BeaconStatusCd ) {
      this._values.push(BeaconStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BeaconStatusCd {

    for ( const obj in BeaconStatusCd ) {
      if (BeaconStatusCd[obj] === value){
        return BeaconStatusCd[obj] as BeaconStatusCd;
      }
    }
  }
}

const BeaconStatusCdHelper = new BeaconStatusCdHelperEnumHelperClass();
export default BeaconStatusCdHelper;
