import { Injectable } from '@angular/core';
import { ListInfo } from '@xpo-ltl/sdk-common';
import { ListModulesQuery, Module, ModuleExecutorApiService } from '@xpo-ltl/sdk-moduleexecutor';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModuleLibraryVersion } from '../models/module-library-version.model';

@Injectable()
export class ModuleExecutorService {
  constructor(private apiService: ModuleExecutorApiService) {}

  listModules(): Observable<Array<ModuleLibraryVersion>> {
    const query = new ListModulesQuery();
    query.listInfo = new ListInfo();

    return this.apiService.listModules(query).pipe(
      map((response) => {
        let versionList = new Array<ModuleLibraryVersion>();

        response.modules.map(
          (module) =>
            (versionList = versionList.concat(
              module.moduleVersion.map(
                (version) =>
                  new ModuleLibraryVersion(
                    module.moduleName,
                    module.moduleDescription,
                    version.version,
                    version.auditInfo.createdTimestamp,
                    version.auditInfo.updatedTimestamp
                  )
              )
            ))
        );

        return versionList;
      })
    );
  }
}
