import { Component, EventEmitter, Output } from '@angular/core';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { IsBetweenModel } from '../../model.interface';
import { filterFormats, Operators, OperatorText, XpoFilterConditionCriteria } from './../../../../../models/index';

@Component({
  templateUrl: './is-between.component.html',
  styleUrls: ['./../../validator.scss'],
})
export class XpoConditionNumberIsBetweenComponent extends XpoConditionBase implements XpoFilterCondition {
  static text: string = OperatorText.Between;
  static operator = Operators.Between;

  isNegative = false;
  model: IsBetweenModel = {
    from: '',
    to: '',
  };

  @Output() inputChanged = new EventEmitter();

  constructor() {
    super(filterFormats.range);
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model.from || !this.model.to) {
      return true;
    }

    return parseFloat(cellValue) >= parseFloat(this.model.from) && parseFloat(cellValue) <= parseFloat(this.model.to);
  }
  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.Between,
      value: this.model.from,
      valueTo: this.model.to,
    };
  }
  onInputChange(): any {
    this.validateFormat();
    this.inputChanged.emit(this.model);
  }
}
