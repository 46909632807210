import { Pipe, PipeTransform } from '@angular/core';
import { get as _get, has as _has, size as _size } from 'lodash';

/**
 * Return a full name built from the passed data with either fullName or firstName and lastName properties
 */
@Pipe({
  name: 'employeeName',
})
export class EmployeeNamePipe implements PipeTransform {
  /**
   * Transforms an employee object to a string
   * @param value employee to transform
   * @param ignoreFullName transforms using firstName and lastName properties
   */
  transform(value: any, ignoreFullName = false): string {
    if (value) {
      if (_has(value, 'fullName') && !ignoreFullName) {
        return _get(value, 'fullName');
      } else {
        const fullName = [_get(value, 'firstName') || _get(value, 'givenName'), _get(value, 'lastName')]
          .join(' ')
          .trim();
        return _size(fullName) > 0 ? fullName : null;
      }
    }
    return null;
  }
}
