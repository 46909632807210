import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { XpoFilterComponentBase, XpoFilterCriteria } from '../models/index';
import { XpoTextInputFilter } from './text-input-filter';

@Component({
  selector: 'xpo-text-input-filter',
  templateUrl: 'text-input-filter.component.html',
  styleUrls: ['text-input-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-TextInputFilter xpo-FilterChip', '[attr.id]': 'id' },
})
export class XpoTextInputFilterComponent extends XpoFilterComponentBase<XpoTextInputFilter> implements AfterViewInit {
  @ViewChild('textInput', { static: true }) textInput: ElementRef;
  searchFormControl: FormControl = new FormControl();

  constructor(private cdr: ChangeDetectorRef) {
    super();
    this.inline = true;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.textInput.nativeElement.focus();
    this.cdr.detectChanges();
  }

  storeCriteriaIfValid(value: string): void {
    if (this.searchFormControl.valid) {
      this.storeCriteria(value);
    }
  }

  /**
   * Clears inputs and sets focus back to input
   */
  clearInput(): void {
    this.searchFormControl.setValue('');
  }

  protected initialize(): void {
    this.initValidators();

    this.searchFormControl.valueChanges
      .pipe(
        debounceTime(this.configuration.inputDebounceTime),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((v) => {
        this.storeCriteriaIfValid(v);
      });
  }

  /**
   * Initializes validators
   */
  protected initValidators(): void {
    const validators = this.configuration.validators;

    if (validators && validators.length) {
      this.searchFormControl.setValidators(validators);
      this.searchFormControl.updateValueAndValidity({ emitEvent: false });
    }
  }

  protected onCriteriaModified(fieldValue: object, criteria: XpoFilterCriteria): void {
    this.searchFormControl.setValue(fieldValue ? fieldValue.toString() : '', { emitEvent: false });
  }
}
