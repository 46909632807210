import {RequestedChangeTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class RequestedChangeTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RequestedChangeTypeCd ) {
      this._values.push(RequestedChangeTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RequestedChangeTypeCd {

    for ( const obj in RequestedChangeTypeCd ) {
      if (RequestedChangeTypeCd[obj] === value){
        return RequestedChangeTypeCd[obj] as RequestedChangeTypeCd;
      }
    }
  }
}

const RequestedChangeTypeCdHelper = new RequestedChangeTypeCdHelperEnumHelperClass();
export default RequestedChangeTypeCdHelper;
