
export enum DmsDocTypeCd {
	ACTL = 'ACTL',
	ARIN = 'ARIN',
	BL = 'BL',
	BLAT = 'BLAT',
	CHKS = 'CHKS',
	CLAT = 'CLAT',
	CLM = 'CLM',
	CLMH = 'CLMH',
	CLMN = 'CLMN',
	CUPD = 'CUPD',
	CUST = 'CUST',
	DR = 'DR',
	DRAT = 'DRAT',
	DRRR = 'DRRR',
	DXFO = 'DXFO',
	FLYS = 'FLYS',
	IR = 'IR',
	IRFO = 'IRFO',
	LOA = 'LOA',
	MMAK = 'MMAK',
	NCIC = 'NCIC',
	OSDF = 'OSDF',
	PXFC = 'PXFC',
	WI = 'WI',
	WRFO = 'WRFO'}

