import { Injectable } from '@angular/core';
import { XpoBoardViewDataStore } from '../../board-views/index';
import { XpoBoardState, XpoBoardView, XpoBoardViewTemplate } from '../../models/index';

export enum XpoBoardActionType {
  Save = 'Save',
  SaveAs = 'SaveAs',
  Edit = 'Edit',
  Delete = 'Delete',
  AvailableViews = 'AvailableViews',
}

@Injectable()
export class XpoBoardActionsVisibilityService {
  views: XpoBoardView[] = null;
  viewTemplates: XpoBoardViewTemplate[] = null;
  viewDataStore: XpoBoardViewDataStore = null;

  isVisible(actionType: XpoBoardActionType): boolean {
    switch (actionType) {
      case XpoBoardActionType.Save:
      case XpoBoardActionType.SaveAs:
        return this.isVisibleFromDataStore();
      case XpoBoardActionType.AvailableViews:
      case XpoBoardActionType.Edit:
      case XpoBoardActionType.Delete:
        return this.isVisibleFromDataStore() && this.isVisibleFromViewsOrTemplates();
    }
  }

  isDisabled(actionType: XpoBoardActionType, state: XpoBoardState): boolean {
    if (!state) {
      return true;
    }

    switch (actionType) {
      case XpoBoardActionType.Save:
        return !state.viewId || state.isViewSystemDefined;
      case XpoBoardActionType.SaveAs:
        return !state.viewId;
      case XpoBoardActionType.Delete:
        return state.isViewSystemDefined;
      case XpoBoardActionType.Edit:
        return state.isViewSystemDefined || !state.viewLastSaved;
    }
  }

  private isVisibleFromDataStore(): boolean {
    return !!this.viewDataStore;
  }

  private isVisibleFromViewsOrTemplates(): boolean {
    return (
      (this.views && this.views.length > 0 && this.views.some((v) => v.closeable)) ||
      (this.viewTemplates && this.viewTemplates.length > 0 && this.viewTemplates.some((t) => t.allowAdditional))
    );
  }
}
