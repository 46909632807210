import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest, merge, Observable, of as observableOf } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { XpoBoardApiAction, XpoBoardApiDispatcherService } from '../board/board-api/index';
import {
  XpoBoardConsumer,
  XpoBoardDataFetchState,
  XpoBoardDataSourceResolver,
  XpoBoardState,
  XpoBoardView,
  XpoBoardViewTemplate,
  XpoBoardViewUtil,
  XpoVisibleBoardViewDatum,
} from '../models/index';
import { BOARD_ACTIVE_VIEW_QUERY_PARAM, XpoBoardViewDataStore } from './models/index';

let nextUniqueId = 0;

/** Display model for the repeater in the UI */
export class VisibleBoardViewModel implements XpoVisibleBoardViewDatum {
  readonly active: boolean;
  readonly closeable: boolean;
  readonly name: string;
  readonly recordCount?: number;
  readonly viewId: string;
  readonly view: XpoBoardView;
  readonly recordChangeCount?: number;

  constructor(datum: XpoVisibleBoardViewDatum, active: boolean, view: XpoBoardView) {
    if (view) {
      this.active = active;
      this.closeable = view.closeable;
      this.name = view ? view.name : 'UNKNOWN';
      this.recordCount = VisibleBoardViewModel.hasRecordCount(datum.recordCount) ? datum.recordCount : view.recordCount;
      this.viewId = datum.viewId;
      this.view = view;
      this.recordChangeCount = datum.recordChangeCount;
    }
  }

  static hasRecordCount(recordCount: number): boolean {
    return !!recordCount || recordCount === 0;
  }
}

@Component({
  selector: 'xpo-board-views',
  templateUrl: 'board-views.component.html',
  styleUrls: ['board-views.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-BoardViews', '[attr.id]': 'id' },
})
export class XpoBoardViews extends XpoBoardConsumer implements OnInit, OnDestroy {
  static readonly XpoBoardViewsSources = {
    Init: 'BOARD-VIEWS-INIT',
    CloseView: 'BOARD-VIEWS-CLOSE-VIEW',
    AddView: 'ADD-NEW-VIEW',
    ActiveViewChange: 'ACTIVE-VIEW-CHANGE',
    PreloadData: 'BOARD-VIEW-PRELOAD-DATA',
  };

  allowAdditionalViews: boolean;
  visibleViews$: Observable<VisibleBoardViewModel[]>;
  viewTemplates: XpoBoardViewTemplate[] = [];

  private uid = `xpo-BoardViews-${nextUniqueId++}`;
  private idValue: string;
  private currentState: XpoBoardState = { source: XpoBoardViews.XpoBoardViewsSources.Init };
  private readonly maxNumberOfRecords: number = 10000;
  private suppressRecordCountsValue = false;
  private preloadViewDataValue = false;
  private enableQueryParamStatePersistanceValue = false;
  private persistFiltersBetweenViewsValue = false;

  @ViewChild(MatMenuTrigger)
  trigger: MatMenuTrigger;

  @Input()
  addNewViewButtonLabel: string;

  @Input()
  viewDataStore: XpoBoardViewDataStore;

  @Input()
  get persistFiltersBetweenViews(): boolean {
    return this.persistFiltersBetweenViewsValue;
  }
  set persistFiltersBetweenViews(value: boolean) {
    this.persistFiltersBetweenViewsValue = coerceBooleanProperty(value);
  }

  @Input()
  get enableQueryParamStatePersistance(): boolean {
    return this.enableQueryParamStatePersistanceValue;
  }
  set enableQueryParamStatePersistance(value: boolean) {
    this.enableQueryParamStatePersistanceValue = coerceBooleanProperty(value);
  }

  @Input()
  get suppressRecordCounts(): boolean {
    return this.suppressRecordCountsValue;
  }
  set suppressRecordCounts(value: boolean) {
    this.suppressRecordCountsValue = coerceBooleanProperty(value);
  }

  @Input()
  get preloadViewData(): boolean {
    return this.preloadViewDataValue;
  }
  set preloadViewData(v: boolean) {
    this.preloadViewDataValue = coerceBooleanProperty(v);
  }

  @Input()
  get id(): string {
    return this.idValue;
  }
  set id(value: string) {
    this.idValue = value || this.uid;
  }

  get viewsWithAllowAdditional(): XpoBoardViewTemplate[] {
    return this.viewTemplates.filter((x) => x.allowAdditional === true);
  }

  constructor(
    boardDataSourceResolver: XpoBoardDataSourceResolver,
    cd: ChangeDetectorRef,
    private boardApiDispatcherService: XpoBoardApiDispatcherService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super(boardDataSourceResolver, cd);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.registerBoardApiHandlers();
  }

  addNewView(viewTemplateId: string): void {
    const template = this.viewTemplates.find((x) => x.id === viewTemplateId);

    const view = template.createView({
      closeable: true,
      name: XpoBoardViewUtil.getNextViewName(template),
      visible: true,
    });

    this.stateChange$.next(
      XpoBoardViewUtil.addView(
        view,
        this.currentState,
        XpoBoardViews.XpoBoardViewsSources.AddView,
        this.persistFiltersBetweenViews
      )
    );
  }

  closeView(view: XpoBoardView, clickEvent: MouseEvent = null): void {
    // Avoid calling multiple click handlers - like the other one for activating view
    if (clickEvent) {
      clickEvent.stopPropagation();
    }

    // Not passing in the current state since the user might have changed the
    // criteria of the state, but not saved it, so we dont
    // want to persist that when the user closes the view.
    this.stateChange$.next(
      XpoBoardViewUtil.hideView(view.id, this.currentState, XpoBoardViews.XpoBoardViewsSources.CloseView)
    );

    // If view data store is inputted or if the view is created and not saved, then just remove the view.
    // TODO: maybe we should normalize the view data store to always exist?
    if (this.viewDataStore || !view.lastSaved) {
      // TODO: handle error
      this.viewDataStore.updateViewVisibility(view.id, false).pipe(take(1)).subscribe();
    }
  }

  checkViewTemplateCount(): void {
    if (this.viewsWithAllowAdditional.length > 1) {
      this.trigger.openMenu();
    } else {
      // if theres only one viewTemplate, create a new view with it
      this.addNewView(this.viewsWithAllowAdditional[0].id);
    }
  }

  setActiveView(view: XpoBoardView): void {
    if (this.enableQueryParamStatePersistance) {
      const queryParams: Params = { [BOARD_ACTIVE_VIEW_QUERY_PARAM]: view.id };
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
    }

    this.stateChange$.next(
      XpoBoardViewUtil.activateView(
        view,
        this.currentState,
        XpoBoardViews.XpoBoardViewsSources.ActiveViewChange,
        this.persistFiltersBetweenViews
      )
    );
  }

  getRecordCountDisplay(recordCount: number): string | null {
    if (!VisibleBoardViewModel.hasRecordCount(recordCount)) {
      return null;
    }

    return recordCount > this.maxNumberOfRecords ? this.maxNumberOfRecords / 1000 + 'K+' : `${recordCount}`;
  }

  protected onStateChange(state: XpoBoardState): void {
    if (state.viewTemplates !== this.currentState.viewTemplates) {
      this.viewTemplates = state.viewTemplates || [];
      this.allowAdditionalViews = this.viewTemplates.some((t) => t.allowAdditional);
    }

    // Map the visible views, if they changed or an error occurred, while retrieving data
    if (
      this.currentState.visibleViewData !== state.visibleViewData ||
      state.dataFetchState === XpoBoardDataFetchState.Error
    ) {
      // Flag to cause an update in state when a view has to preload data.
      // This is so other state observers can get the update in the view record count.
      let updateStateWithNewVisibleViewData = false;

      this.visibleViews$ = combineLatest(
        (state.visibleViewData || []).map((visibleView) => {
          const isActive = visibleView.viewId === state.viewId;
          const view = state.availableViews.find((x) => x.id === visibleView.viewId);

          const visibleView$ = observableOf(new VisibleBoardViewModel(visibleView, isActive, view));
          // If the board is configured to preload all view data & the view does
          // not have a record count & the view is not the active
          // view (since we are already fetching the data for that in another call)
          // then fetch the data of the view so we have the
          // record count pre-loaded, else, return the view with no record count loaded
          if (
            this.preloadViewData &&
            !VisibleBoardViewModel.hasRecordCount(visibleView.recordCount) &&
            !isActive &&
            view
          ) {
            // Setting this to true so that the state will update with the new record counts for views.
            updateStateWithNewVisibleViewData = true;

            // Return the merge result between the view with no recordCount
            // (a completed observable) and the request for fetching data.
            // This way we immediately display the views, and later update the count too.
            return merge(
              visibleView$,
              this.dataSource.fetchData(view.getState(view.criteria)).pipe(
                take(1),
                map(
                  (result) =>
                    new VisibleBoardViewModel({ recordCount: result.recordCount, viewId: view.id }, isActive, view)
                )
              )
            );
          }

          return visibleView$;
        })
      ).pipe(
        tap((v) => {
          // If we pre-loaded the view data, update the state of the board so other observers can get this update
          if (updateStateWithNewVisibleViewData) {
            // Update the local current state value with the newly
            // updated visible view data so that when this state change is emitted
            // and comes back to this function
            // this.currentState.visibleViewData !== state.visibleViewData can be true and not
            // cause another fetch
            this.currentState.visibleViewData = v;
            this.stateChange$.next({ visibleViewData: v, source: XpoBoardViews.XpoBoardViewsSources.PreloadData });
          }
        })
      );
    }

    this.currentState = state;
  }

  private registerBoardApiHandlers(): void {
    this.boardApiDispatcherService.registerHandler(XpoBoardApiAction.HideView, (viewId) => {
      const view = this.currentState.availableViews.find((x) => x.id === viewId);

      if (view) {
        this.closeView(view);
      } else {
        console.error(`XpoBoardViews: ${viewId} was trying to be hidden but it was not found`);
      }
    });

    this.boardApiDispatcherService.registerHandler(XpoBoardApiAction.ActivateView, (viewId) => {
      const view = this.currentState.availableViews.find((x) => x.id === viewId);

      if (view) {
        this.setActiveView(view);
      } else {
        console.error(`XpoBoardViews: ${viewId} was trying to be activated but it was not found`);
      }
    });
  }
}
