import {DynamicPriceLocationTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DynamicPriceLocationTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DynamicPriceLocationTypeCd ) {
      this._values.push(DynamicPriceLocationTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DynamicPriceLocationTypeCd {

    for ( const obj in DynamicPriceLocationTypeCd ) {
      if (DynamicPriceLocationTypeCd[obj] === value){
        return DynamicPriceLocationTypeCd[obj] as DynamicPriceLocationTypeCd;
      }
    }
  }
}

const DynamicPriceLocationTypeCdHelper = new DynamicPriceLocationTypeCdHelperEnumHelperClass();
export default DynamicPriceLocationTypeCdHelper;
