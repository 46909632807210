import { XpoBrowserOs, XpoBrowserType } from './browser-type.enums';

export class XpoBrowserClassification {
  os?: XpoBrowserOs;

  constructor(public browser: XpoBrowserType, os: XpoBrowserOs = null) {
    this.os = os;
  }

  static areMatching(a: XpoBrowserClassification, b: XpoBrowserClassification): boolean {
    if (!a.os || !b.os) {
      return a.browser === b.browser;
    }

    return a.os === b.os && a.browser === b.browser;
  }
}
