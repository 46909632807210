import {RatingPartyLocationTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class RatingPartyLocationTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RatingPartyLocationTypeCd ) {
      this._values.push(RatingPartyLocationTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RatingPartyLocationTypeCd {

    for ( const obj in RatingPartyLocationTypeCd ) {
      if (RatingPartyLocationTypeCd[obj] === value){
        return RatingPartyLocationTypeCd[obj] as RatingPartyLocationTypeCd;
      }
    }
  }
}

const RatingPartyLocationTypeCdHelper = new RatingPartyLocationTypeCdHelperEnumHelperClass();
export default RatingPartyLocationTypeCdHelper;
