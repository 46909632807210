import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { GetLocReferenceDetailsBySicResp } from '@xpo-ltl-2.0/sdk-location';
import { EmployeeBasic, GetEmployeeDetailsByEmpIdResp } from '@xpo-ltl/sdk-humanresource';
import {
  GetOdsShipmentResp,
  ListOdsShipmentHistTraceEventsResp,
  ListOdsShipmentHistTraceEventsRqst,
  ShipmentHistTraceEvent,
  ShipmentOdsApiService,
} from '@xpo-ltl/sdk-shipmentods';
import { get as _get, map as _map, size as _size } from 'lodash';
import * as moment_ from 'moment-timezone';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '../../classes/unsubscriber';
import { SicDatePipe } from '../../pipes/sic-date/sic-date.pipe';
import { XpoLtlEmployeeDetailsCacheService } from '../../services/employee-details-cache/employee-details-cache.service';
import { XpoLtlLocationDetailsCacheService } from '../../services/location-details-cache/location-details-cache.service';
import { ShipmentReferenceService } from '../shipment-reference.service';

const moment = moment_;

interface EventData {
  occurredTime: string;
  elapsedTime: string;
  sicCd: string;
  description: string;
  originDest: string;
  loadRelease: string;
  loadDestination: string;
  trailer: string;
  weightCubeBls: string;
  updatedBy: string;
}

@Component({
  selector: 'xpo-ltl-shipment-details-events',
  templateUrl: './shipment-details-events.component.html',
  styleUrls: ['./shipment-details-events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ShipmentDetailsEventsComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();
  columns = [
    {
      id: 'occurredTmst',
      label: 'Date/Time (local)',
      rendererAsync: (row) => this.displayTime(row),
    },
    {
      id: 'elapsedTm',
      label: 'Elapsed Time',
      renderer: (row) => (row.elapsedTm ? moment(row.elapsedTm).format('HH:mm') : undefined),
    },
    { id: 'occurredSic', label: 'SIC' },
    { id: 'description', label: 'Description' },
    {
      id: 'originDest',
      label: 'Origin - Destination',
      renderer: (row) => this.appendValues(row.originSic, row.destinationSic),
    },
    { id: 'loadReleaseNbr', label: 'Load Release' },
    { id: 'loadDestinationSic', label: 'Load Destination' },
    { id: 'trailerNbr', label: 'Trailer' },
    {
      id: 'weightCubeBls',
      label: 'Weight/Cube/Bls',
      renderer: (row) => this.appendValues(row.trailerWeightLbs, row.trailerCubePct, row.shipmentsOnTrailerCnt),
    },
    { id: 'updtBy', label: 'Updated By', rendererAsync: (row) => this.userNameFromEmplId(row) },
  ];
  columnsToDisplay = _map(this.columns, (c) => c.id);

  readonly dataSource = new MatTableDataSource<ShipmentHistTraceEvent>(undefined);

  constructor(
    private shipmentRefService: ShipmentReferenceService,
    private shipmentOdsApiService: ShipmentOdsApiService,
    private sicDatePipe: SicDatePipe,
    private locationDetailsService: XpoLtlLocationDetailsCacheService,
    private employeeDetailsCacheService: XpoLtlEmployeeDetailsCacheService
  ) {}

  ngOnInit() {
    this.shipmentRefService.shipment$
      .pipe(takeUntil(this.unsubscriber.done$), distinctUntilChanged())
      .subscribe((shipment) => {
        this.updateFormFromShipment(shipment);
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private appendValues(...args: Array<any>): string {
    if (!!args && args.some((arg) => !!arg)) {
      return args.join('/');
    }
    return '';
  }
  private updateFormFromShipment(shipment: GetOdsShipmentResp) {
    if (!shipment) {
      return;
    }

    const request = new ListOdsShipmentHistTraceEventsRqst();
    request.odsShipmentId = {
      shipmentInstId: shipment.shipment.shipmentInstId,
      proNbr: shipment.shipment.proNbr,
      pickupDate: `${shipment.shipment.pickupDate.valueOf()}`,
    };

    this.shipmentOdsApiService
      .listOdsShipmentHistTraceEvents(request)
      .subscribe((result: ListOdsShipmentHistTraceEventsResp) => {
        this.dataSource.data = result.shipmentTraceHistoryEvents;
      });
  }

  private displayTime(event: ShipmentHistTraceEvent): Observable<string> {
    if (!event.occurredTmst) {
      return of('-');
    }
    const fallback = moment(event.occurredTmst).format('MM/DD/YYYY HH:mm');
    if (!!event.occurredSic) {
      return this.locationDetailsService.request({ sicCd: event.occurredSic }).pipe(
        take(1),
        switchMap(
          (response: GetLocReferenceDetailsBySicResp): Observable<string> => {
            if (!!_get(response, 'locReference.timezoneName')) {
              return of(
                moment(event.occurredTmst)
                  .tz(response.locReference.timezoneName)
                  .format('MM/DD/YYYY HH:mm')
              );
            }
            return of(fallback);
          }
        )
      );
    }
    return of(fallback);
  }

  private userNameFromEmplId(event: ShipmentHistTraceEvent): Observable<string> {
    const params = { employeeId: _get(event, 'updtBy') };
    if (params.employeeId) {
      return this.employeeDetailsCacheService.request(params).pipe(
        map((employeeDetails: GetEmployeeDetailsByEmpIdResp) => {
          const info: EmployeeBasic = _get(employeeDetails, 'employee.basicInfo');
          if (info) {
            return `${info.deptSic}/${info.lastName}, ${
              _size(info.preferredFirstName) > 0 ? info.preferredFirstName : info.firstName
            }`;
          } else {
            return event.updtBy;
          }
        })
      );
    } else {
      return of('');
    }
  }
}
