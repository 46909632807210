import {ClaimEventTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimEventTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimEventTypeCd ) {
      this._values.push(ClaimEventTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimEventTypeCd {

    for ( const obj in ClaimEventTypeCd ) {
      if (ClaimEventTypeCd[obj] === value){
        return ClaimEventTypeCd[obj] as ClaimEventTypeCd;
      }
    }
  }
}

const ClaimEventTypeCdHelper = new ClaimEventTypeCdHelperEnumHelperClass();
export default ClaimEventTypeCdHelper;
