import { get as _get } from 'lodash';
import { Observable } from 'rxjs';
import { delay, finalize, retryWhen, scan, shareReplay } from 'rxjs/operators';

interface CacheData<CType> {
  timestamp: number;
  data$: Observable<CType>;
}

/**
 * Abstract class for managing a Cache fo data.  Automatically fetches data from
 * the backing data sourcee if the item is not in the cache, or if it has been in
 * the cache longer than the max age.
 */
export abstract class XpoLtlBaseCacheService<PType, CType> {
  static readonly MAX_RETRIES = 5; // how many time to retry fetching data before giving up
  static readonly RETRY_DELAY = 500; // how long (in ms) between retries when fetching data
  static readonly MAX_AGE = 0; // how long (in ms) to keep data before forcing a refresh. 0 means forever

  private cache: Map<string, CacheData<CType>> = new Map<string, CacheData<CType>>();

  constructor(
    protected maxRetries = XpoLtlBaseCacheService.MAX_RETRIES,
    protected retryDelay = XpoLtlBaseCacheService.RETRY_DELAY,
    protected maxAge = XpoLtlBaseCacheService.MAX_AGE
  ) {}

  /**
   * Return the unique cache key generated from the passed params
   * @param params object containing data used to create the unique caching key
   */
  protected abstract getKeyFromParams(params: PType): string;

  /**
   * Get data from the backing data source using the passed params
   *
   * @param params object containing data used to create the unique caching key
   */
  protected abstract requestData(params: PType): Observable<CType>;

  /**
   * The keys for all items in the cache
   */
  get keys(): string[] {
    return Array.from(this.cache.keys());
  }

  /**
   * Return the number of items currently in the cache
   */
  get size(): number {
    return this.cache.size;
  }

  /**
   * Request data from the cache, fetching it from data source if not found or if it is stale
   * @param params used to request data
   * @returns Observable that resolves with the requested data
   */
  request(params: PType): Observable<CType> {
    const key = this.getKeyFromParams(params);
    const cachedData = this.cache.get(key);
    if (!cachedData || (this.maxAge > 0 && Date.now() - cachedData.timestamp > this.maxAge)) {
      const result$ = new Observable<CType>((observer) => {
        this.requestData(params)
          .pipe(
            retryWhen((errors) =>
              errors.pipe(
                delay(this.retryDelay),
                scan((tries, err) => {
                  if (tries >= this.maxRetries) {
                    throw err;
                  }
                  return tries + 1;
                }, 0)
              )
            ),
            finalize(() => {
              observer.complete();
            })
          )
          .subscribe(
            (data) => {
              observer.next(data);
            },
            (err) => {
              const errorCode = _get(err, 'error.errorCode');
              const errorMessage = _get(err, 'error.message');
              console.error(`Cache failed to get ${JSON.stringify(params)} with error: ${errorCode}: ${errorMessage}`);
              observer.next(undefined);
            }
          );
      }).pipe(shareReplay(1));

      this.cache.set(key, { timestamp: Date.now(), data$: result$ });
    }

    return this.cache.get(key).data$;
  }

  /**
   * Clear everything from cache
   */
  clear(): void {
    this.cache.clear();
  }

  /**
   * Delete the specified items from the cache
   * @param key key or list of keys that should be deleted from cache
   */
  delete(key: string | string[]): void {
    let keysToDelete: string[];

    if (typeof key === 'string') {
      keysToDelete = [key];
    } else {
      keysToDelete = key;
    }

    for (const keyToDelete of keysToDelete) {
      if (keyToDelete) {
        this.cache.delete(keyToDelete);
      }
    }
  }
}
