import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Header for Application Switcher dialog
 */
@Component({
  selector: 'xpo-application-switcher-header',
  templateUrl: './application-switcher-header.component.html',
  styleUrls: ['./application-switcher-header.component.scss'],
  host: {
    class : 'xpo-ApplicationSwitcherHeader'
  }
})
export class ApplicationSwitcherHeaderComponent {

  /** The application name */
  @Input() appName: string;

  /** The application sub-name */
  @Input() appSubName: string;

  /** Whether to check the favorite toggle */
  @Input() showFavorite: boolean = false;

  /** Whether to disable the favorite toggle */
  @Input() disableFavorite: boolean = false;

  /** Notify when user wants to close the dialog */
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  /** Notify when user changes show favorite applications value */
  @Output() showFavoriteChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** Handles when user clicks on toggle or close buttons */
  onClose(): void {
    this.close.emit();
  }

  /** Handles when users click on favorite toggle */
  onFavoriteToggle(): void {
    // change value
    this.showFavorite = !this.showFavorite;

    // notify the change
    this.showFavoriteChange.emit(this.showFavorite);
  }
}
