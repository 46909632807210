import {HazmatBulkQuantityCd} from '../';
import {EnumHelper} from './enum-helper';

export class HazmatBulkQuantityCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in HazmatBulkQuantityCd ) {
      this._values.push(HazmatBulkQuantityCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): HazmatBulkQuantityCd {

    for ( const obj in HazmatBulkQuantityCd ) {
      if (HazmatBulkQuantityCd[obj] === value){
        return HazmatBulkQuantityCd[obj] as HazmatBulkQuantityCd;
      }
    }
  }
}

const HazmatBulkQuantityCdHelper = new HazmatBulkQuantityCdHelperEnumHelperClass();
export default HazmatBulkQuantityCdHelper;
