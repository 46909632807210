import {AppointmentNotificationStatCd} from '../';
import {EnumHelper} from './enum-helper';

export class AppointmentNotificationStatCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in AppointmentNotificationStatCd ) {
      this._values.push(AppointmentNotificationStatCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): AppointmentNotificationStatCd {

    for ( const obj in AppointmentNotificationStatCd ) {
      if (AppointmentNotificationStatCd[obj] === value){
        return AppointmentNotificationStatCd[obj] as AppointmentNotificationStatCd;
      }
    }
  }
}

const AppointmentNotificationStatCdHelper = new AppointmentNotificationStatCdHelperEnumHelperClass();
export default AppointmentNotificationStatCdHelper;
