import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[xpoOptionHideDivider]',
  host: {
    '[class.xpo-Option-hideDivider]': 'xpoOptionHideDivider',
  },
})
export class XpoOptionHideDividerDirective {
  @Input('xpoOptionHideDivider') xpoOptionHideDivider: boolean = true;
}
