import {ReweighStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ReweighStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ReweighStatusCd ) {
      this._values.push(ReweighStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ReweighStatusCd {

    for ( const obj in ReweighStatusCd ) {
      if (ReweighStatusCd[obj] === value){
        return ReweighStatusCd[obj] as ReweighStatusCd;
      }
    }
  }
}

const ReweighStatusCdHelper = new ReweighStatusCdHelperEnumHelperClass();
export default ReweighStatusCdHelper;
