import {InvoicePresentationFormatCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoicePresentationFormatCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoicePresentationFormatCd ) {
      this._values.push(InvoicePresentationFormatCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoicePresentationFormatCd {

    for ( const obj in InvoicePresentationFormatCd ) {
      if (InvoicePresentationFormatCd[obj] === value){
        return InvoicePresentationFormatCd[obj] as InvoicePresentationFormatCd;
      }
    }
  }
}

const InvoicePresentationFormatCdHelper = new InvoicePresentationFormatCdHelperEnumHelperClass();
export default InvoicePresentationFormatCdHelper;
