/* eslint-disable @typescript-eslint/naming-convention */
import { XpoBrowserClassification } from './browser-classification.model';
import { XpoBrowserInfo } from './browser-info.model';
import { XpoBrowserType } from './browser-type.enums';

export const xpoBrowsersInfo: { [key in keyof typeof XpoBrowserType]: XpoBrowserInfo } = {
  Chrome: {
    name: 'Google Chrome',
    installationUrl: 'https://www.google.com/chrome/',
    iconName: 'chrome',
    classification: new XpoBrowserClassification(XpoBrowserType.Chrome),
  },
  Firefox: {
    name: 'Firefox',
    installationUrl: 'https://www.mozilla.org/en-US/firefox/new/',
    iconName: 'firefox',
    classification: new XpoBrowserClassification(XpoBrowserType.Firefox),
  },
  InternetExplorer: {
    name: 'Internet Explorer',

    installationUrl: 'https://www.microsoft.com/en-us/download/internet-explorer.aspx',
    classification: new XpoBrowserClassification(XpoBrowserType.InternetExplorer),
  },
  Edge: {
    name: 'Edge',
    installationUrl: 'https://www.microsoft.com/en-us/windows/microsoft-edge',
    classification: new XpoBrowserClassification(XpoBrowserType.Edge),
  },
  Safari: {
    name: 'Safari',
    installationUrl: 'https://www.apple.com/safari/',
    classification: new XpoBrowserClassification(XpoBrowserType.Safari),
  },
  Opera: {
    name: 'Opera',
    installationUrl: 'https://www.opera.com/',
    classification: new XpoBrowserClassification(XpoBrowserType.Opera),
  },
  Unknown: {
    name: 'Unknown',
    installationUrl: '',
  },
};
