import { Directive, Input } from '@angular/core';
import { MatStepperPrevious } from '@angular/material/stepper';

@Directive({
  selector: 'button[xpoStepperPrevious]',
  host: {
    '[type]': 'type',
  },
})
export class XpoStepperPreviousDirective extends MatStepperPrevious {
  @Input() type: string;
}
