import {InvoiceHoldReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoiceHoldReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoiceHoldReasonCd ) {
      this._values.push(InvoiceHoldReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoiceHoldReasonCd {

    for ( const obj in InvoiceHoldReasonCd ) {
      if (InvoiceHoldReasonCd[obj] === value){
        return InvoiceHoldReasonCd[obj] as InvoiceHoldReasonCd;
      }
    }
  }
}

const InvoiceHoldReasonCdHelper = new InvoiceHoldReasonCdHelperEnumHelperClass();
export default InvoiceHoldReasonCdHelper;
