export enum DocType {
  BillOfLading = 'BL',
  DeliveryReceipt = 'DR',
  DeliveryReturnReceipt = 'DRRR',
  CustomsDocuments = 'CUST',
  InspectionReport = 'IR',
  LetterOfAuthority = 'LOA',
  NmfcClassInspectionCertificate = 'NCIC',
  WeightInspectionCertificate = 'WI',
  WeightAndResearchPhotos = 'WRFO',
  InvoicePdf = 'ARIN',
  ClaimsAttachment = 'CLAT',
  CustomerUpload = 'CUPD',
}
