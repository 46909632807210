import {BillingRemarkTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class BillingRemarkTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BillingRemarkTypeCd ) {
      this._values.push(BillingRemarkTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BillingRemarkTypeCd {

    for ( const obj in BillingRemarkTypeCd ) {
      if (BillingRemarkTypeCd[obj] === value){
        return BillingRemarkTypeCd[obj] as BillingRemarkTypeCd;
      }
    }
  }
}

const BillingRemarkTypeCdHelper = new BillingRemarkTypeCdHelperEnumHelperClass();
export default BillingRemarkTypeCdHelper;
