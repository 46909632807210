import { Injectable } from '@angular/core';
import { GetOdsShipmentResp } from '@xpo-ltl/sdk-shipmentods';
import { BehaviorSubject } from 'rxjs';

/**
 * Service to share Shipment data within component
 */
@Injectable()
export class ShipmentReferenceService {
  private shipmentBehaviorSubject = new BehaviorSubject<GetOdsShipmentResp>(undefined);
  readonly shipment$ = this.shipmentBehaviorSubject.asObservable();
  set shipment(value: GetOdsShipmentResp) {
    this.shipmentBehaviorSubject.next(value);
  }
}
