import {ShipmentMovementTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentMovementTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentMovementTypeCd ) {
      this._values.push(ShipmentMovementTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentMovementTypeCd {

    for ( const obj in ShipmentMovementTypeCd ) {
      if (ShipmentMovementTypeCd[obj] === value){
        return ShipmentMovementTypeCd[obj] as ShipmentMovementTypeCd;
      }
    }
  }
}

const ShipmentMovementTypeCdHelper = new ShipmentMovementTypeCdHelperEnumHelperClass();
export default ShipmentMovementTypeCdHelper;
