import {ConsigneeUnloadedTrailerCd} from '../';
import {EnumHelper} from './enum-helper';

export class ConsigneeUnloadedTrailerCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ConsigneeUnloadedTrailerCd ) {
      this._values.push(ConsigneeUnloadedTrailerCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ConsigneeUnloadedTrailerCd {

    for ( const obj in ConsigneeUnloadedTrailerCd ) {
      if (ConsigneeUnloadedTrailerCd[obj] === value){
        return ConsigneeUnloadedTrailerCd[obj] as ConsigneeUnloadedTrailerCd;
      }
    }
  }
}

const ConsigneeUnloadedTrailerCdHelper = new ConsigneeUnloadedTrailerCdHelperEnumHelperClass();
export default ConsigneeUnloadedTrailerCdHelper;
