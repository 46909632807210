import {AccessorialUnitOfMeasureCd} from '../';
import {EnumHelper} from './enum-helper';

export class AccessorialUnitOfMeasureCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in AccessorialUnitOfMeasureCd ) {
      this._values.push(AccessorialUnitOfMeasureCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): AccessorialUnitOfMeasureCd {

    for ( const obj in AccessorialUnitOfMeasureCd ) {
      if (AccessorialUnitOfMeasureCd[obj] === value){
        return AccessorialUnitOfMeasureCd[obj] as AccessorialUnitOfMeasureCd;
      }
    }
  }
}

const AccessorialUnitOfMeasureCdHelper = new AccessorialUnitOfMeasureCdHelperEnumHelperClass();
export default AccessorialUnitOfMeasureCdHelper;
