export enum XpoBoardStateSources {
  ExternalDataRefresh = 'EXTERNAL-DATA-REFRESH',
  BoardActivatingView = 'BOARD-ACTIVATING-VIEW',
  ActiveViewChange = 'ACTIVE-VIEW-CHANGE',
  AddNewView = 'ADD-NEW-VIEW',
  SaveView = 'SAVE-VIEW',
  SaveViewAs = 'SAVE-VIEW-AS',
  RefreshView = 'REFRESH-VIEW',
  DeleteView = 'AVAILABLE-VIEWS-DELETE-VIEW',
  RenameView = 'RENAME-VIEW',
  FilterChange = 'FILTER-CHANGE',
  BoardDataFetcher = 'BOARD DATA FETCHER',
  RowModelType = 'ROW-MODEL-TYPE',
  DataSourceRefresh = 'DATA SOURCE REFRESH',
  CellClicked = 'CELL-CLICKED',
  FirstDataRendered = 'FIRST-DATA-RENDERED',
  InitializeFloatingFilters = 'INITIALIZE-FLOATING-FILTERS',
  SelectionChange = 'SELECTION-CHANGE',
  GridSettingsUpdate = 'GRID-SETTINGS-UPDATE',
  ColumnLevelFilteringToggle = 'COLUMN-LEVEL-FILTERING-TOGGLE',
  PageChange = 'AG-GRID-PAGE',
  SortChange = 'AG-GRID-SORT',
}
