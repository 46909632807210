import { XpoBoardState } from './board-state.model';

/** Object to returned from the board-data-service when loading data  */
export class XpoBoardData<T = any> {
  readonly endRecord: number;
  readonly startRecord: number;

  constructor(
    readonly state: XpoBoardState,
    readonly consumerData: T,
    readonly recordCount: number,
    readonly pageSize: number
  ) {
    this.startRecord = (state.pageNumber - 1) * pageSize;

    // We check the length of the array, against the page size to see if we have reached the end
    this.endRecord = recordCount = Math.min(recordCount, this.startRecord + pageSize);
  }

  static empty<T = any>(state: XpoBoardState): XpoBoardData<T> {
    return new XpoBoardData<T>(state, null, 0, 0);
  }
}
