import {ApiContractImplProtocolCd} from '../';
import {EnumHelper} from './enum-helper';

export class ApiContractImplProtocolCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ApiContractImplProtocolCd ) {
      this._values.push(ApiContractImplProtocolCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ApiContractImplProtocolCd {

    for ( const obj in ApiContractImplProtocolCd ) {
      if (ApiContractImplProtocolCd[obj] === value){
        return ApiContractImplProtocolCd[obj] as ApiContractImplProtocolCd;
      }
    }
  }
}

const ApiContractImplProtocolCdHelper = new ApiContractImplProtocolCdHelperEnumHelperClass();
export default ApiContractImplProtocolCdHelper;
