import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	GetGeoAreasForLocationResp,
	GetGeoAreasForLocationPath,
	ListOperationalServiceCentersResp,
	ListOperationalServiceCentersQuery,
	GetLocReferenceDetailsBySicResp,
	GetLocReferenceDetailsBySicPath,
	GetServiceCenterDetailsBySicResp,
	GetServiceCenterDetailsBySicPath,
	GetSectorByDoorNumberResp,
	GetSectorByDoorNumberPath,
	ListLocGroupCodeResp,
	ListLocGroupCodeQuery,
	ListAllSicCdsByRegionResp,
	ListAllSicCdsByRegionPath
} from './api-location';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class LocationApiService extends BaseService {
	private static LocationApiEndpoint = 'locationApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns geo area information about a location. 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The SIC code and type code are required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If a geo area is found for the SIC and type provided, then information about it will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getGeoAreasForLocation(
							   pathParams: GetGeoAreasForLocationPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetGeoAreasForLocationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/areas/{polygonTypeCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists operational service centers.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>None 
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. It should return a list of all existing operational servie centers.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listOperationalServiceCenters(
							   queryParams: ListOperationalServiceCentersQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListOperationalServiceCentersResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns location reference information about a service center.
	*/
	public getLocReferenceDetailsBySic(
							   pathParams: GetLocReferenceDetailsBySicPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLocReferenceDetailsBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/loc-reference-sics/{sicCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns location information about a service center. This information shall include the service center's physical address as well as geocoordinates for use in GIS applications.
	*/
	public getServiceCenterDetailsBySic(
							   pathParams: GetServiceCenterDetailsBySicPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetServiceCenterDetailsBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns the sector number that is associated with the door number provided.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The SIC and door number are required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the input provided is valid, then a sector number will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getSectorByDoorNumber(
							   pathParams: GetSectorByDoorNumberPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSectorByDoorNumberResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/doors/{doorNumber}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns a list of all location group codes.
	* <br/>â
	* <br/>Pre-conditions:
	* <br/>1. Group category code may be provided (LHO_LHAREA_ALL, LHO_MODEL_GRP) to return all location group codes for the category. 
	* <br/>2. A group category code and sic code may be provided to return specific location group code.
	* <br/>â
	* <br/>Post-conditions:
	* <br/>1. It should return a list of all location group codes.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listLocGroupCode(
							   queryParams: ListLocGroupCodeQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListLocGroupCodeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/location-group-code'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns a list of service centers
	*/
	public listAllSicCdsByRegion(
							   pathParams: ListAllSicCdsByRegionPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListAllSicCdsByRegionResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/sics-by-region/{locGroupCode}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(LocationApiService.LocationApiEndpoint);
	}
}
