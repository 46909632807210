
export enum JSPShiftTimeNameTypeCd {
	DAY_RE_HANDLE = 'Day re-handle',
	FAC_ALLOCATED = 'FAC allocated',
	FAC_RE_HANDLE = 'FAC re-handle',
	INBOUND_ALLOCATED = 'Inbound allocated',
	INBOUND_RE_HANDLE = 'Inbound re-handle',
	OUTBOUND_ALLOCATED = 'Outbound allocated',
	OUTBOUND_RE_HANDLE = 'Outbound re-handle'}

