import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SdkDmsModule } from '@xpo-ltl/sdk-dms';
import { SdkDocumentManagementModule } from '@xpo-ltl/sdk-documentmanagement';
import { XpoResponsiveLoadingSpinnerModule } from '@xpo-ltl/ngx-ltl-core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { XpoLtlDocTypePipe } from './doc-type.pipe';
import { XpoLtlDocViewPageComponent } from './doc-view-page/doc-view-page.component';
import { XpoLtlDocumentSelectorComponent } from './document-selector/document-selector.component';
import { XpoLtlDocumentViewerComponent } from './document-viewer/document-viewer.component';
import { XpoLtlPdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { XpoLtlDocumentViewerDialogComponent } from './document-viewer-dialog/document-viewer-dialog.component';
import { XpoLtlDialogTitleModule } from '../dialog-title/dialog-title.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

const components = [
  XpoLtlDocumentSelectorComponent,
  XpoLtlDocViewPageComponent,
  XpoLtlDocumentViewerComponent,
  XpoLtlPdfViewerComponent,
];
const pipes = [XpoLtlDocTypePipe];

const dialogs = [XpoLtlDocumentViewerDialogComponent];

@NgModule({
  imports: [
    CommonModule,
    SdkDocumentManagementModule,
    SdkDmsModule,
    PdfViewerModule,
    MatTooltipModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    XpoLtlDialogTitleModule,
    XpoResponsiveLoadingSpinnerModule,
  ],
  declarations: [...components, ...pipes, ...dialogs, XpoLtlDocViewPageComponent],
  exports: [...components, ...dialogs, ...pipes],
  providers: [...pipes],
  entryComponents: [...dialogs],
})
export class XpoLtlDmsUtilsModule {}
