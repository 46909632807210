import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'module-groups-name-cell-renderer',
  templateUrl: './name-cell-renderer.component.html',
  styleUrls: ['./name-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ModuleGroupsNameCellRenderer',
  },
})
export class ModuleGroupsNameCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  nameText: string;
  moduleGroupInstId: Number;
  moduleDescription: string;
  isNew: boolean;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.moduleGroupInstId = this.params.data.moduleGroupInstId;
    this.moduleDescription = this.params.data.description;
    this.nameText = this.params.value;
    this.isNew = this.params && this.params.node && this.params.node.data ? this.params.node.data.isNew : false;
  }

  refresh(): boolean {
    return false;
  }

  onClick(): void {
    this.params.context.setSelectedModule(this.params.node.data);
  }
}
