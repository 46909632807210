import { XpoInlineFilter } from '../models/inline-filter';

import { XpoQuickSearchFilterComponent } from './quick-search-filter.component';

export interface XpoQuickSearchFilterConfig {
  collapsible?: boolean;
  disableAutofocus?: boolean;
  placeholderText?: string;
}

export class XpoQuickSearchFilter extends XpoInlineFilter {
  readonly collapsible: boolean;
  readonly disableAutofocus: boolean;
  readonly placeholderText: string;

  constructor(public field: string, config: XpoQuickSearchFilterConfig = {}) {
    super(field, null, XpoQuickSearchFilterComponent);
    this.collapsible = config ? !!config.collapsible : false;
    this.disableAutofocus = config ? !!config.disableAutofocus : false;
    this.placeholderText = config && config.placeholderText ? config.placeholderText : 'Search';
  }

  getDisplayValue(val: any): any {
    return val;
  }
}
