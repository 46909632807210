import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { XpoPopoverModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoDashbarMetric, XpoDashbarMetricPopover } from './dashbar-metric/index';
import { XpoDashbar } from './dashbar.component';

@NgModule({
  imports: [CommonModule, XpoPopoverModule, MatIconModule],
  declarations: [XpoDashbar, XpoDashbarMetric, XpoDashbarMetricPopover],
  exports: [XpoDashbar, XpoDashbarMetric, XpoDashbarMetricPopover],
})
export class XpoDashbarModule {}
