import { Observable } from 'rxjs';
import { XpoUploadEvent, XpoUploadEventType } from './upload-events/index';

export interface XpoUploadConfig {
  showFileSize?: boolean;
  accept?: string;
  showUploadedFiles?: boolean;
  uploadFilesOnDrop: boolean;
  uploadCancelable?: boolean;
  maxUploadSizeMB?: number;
  uploadFileTrigger?: () => void;
  uploadFile: (file: File) => Observable<any>;
  cleanFiles?: () => void;
  clearUploaded?: () => void;
  setFiles?: (files: File[], eventType?: XpoUploadEventType) => void;
  onFilesChange?: (files: File[], event?: XpoUploadEvent) => void;
  onComplete?: () => void;
  onError?: (file: File, error: any) => void;
}
export interface XpoUploadUploadedFiles {
  customer: string;
  fileSize: number;
}

export class XpoUploadInternal {
  generalProgress: number;
  sizeUploaded: number;
  totalUploadSize: number;

  constructor() {
    this.generalProgress = 0;
    this.sizeUploaded = 0;
    this.totalUploadSize = 0;
  }
}
