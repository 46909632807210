import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XpoBusyLoader } from './busy-loader.component';

@NgModule({
  imports: [CommonModule],
  declarations: [XpoBusyLoader],
  exports: [XpoBusyLoader],
})
export class XpoBusyLoaderModule {}
