import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'xpo-icon',
  templateUrl: 'icon.component.html',
  styleUrls: ['icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-Icon' },
})
export class XpoIcon {
  static readonly registeredIcons: string[] = [];

  private iconNameValue: string;

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  @Input()
  set iconName(value: string) {
    this.iconNameValue = value;

    if (XpoIcon.registeredIcons.indexOf(value) === -1) {
      this.matIconRegistry.addSvgIcon(
        value,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`./assets/xpo-icons/${value}.svg`)
      );
      XpoIcon.registeredIcons.push(value);
    }
  }
  get iconName(): string {
    return this.iconNameValue;
  }
}
