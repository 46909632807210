import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { BOARD_ACTIVE_VIEW_QUERY_PARAM, XpoBoardViewConfig, XpoBoardViewDataStore } from '../board-views/index';
import {
  XpoBoardOptions,
  XpoBoardState,
  XpoBoardView,
  XpoBoardViewTemplate,
  XpoBoardViewUtil,
  XpoVisibleBoardViewDatum,
} from '../models/index';

/**
 * Internal service used by the board component to improve code readability
 */
@Injectable()
export class XpoBoardService implements OnDestroy {
  private serviceDestroyed$ = new Subject<void>();
  // make behavior because null is a valid value
  private readonly viewDataStoreSubject$ = new BehaviorSubject<XpoBoardViewDataStore>(null);
  private readonly viewsSubject$ = new ReplaySubject<XpoBoardView[]>(1);
  private readonly viewTemplatesSubject$ = new ReplaySubject<XpoBoardViewTemplate[]>(1);

  private viewDataStoreValue: XpoBoardViewDataStore;
  private viewsValue: XpoBoardView[] = [];
  private viewTemplatesValue: XpoBoardViewTemplate[] = [];
  private dataStoreViewSubscription: Subscription;

  stateChange$ = new ReplaySubject<XpoBoardState>(1);
  boardOptions: XpoBoardOptions;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    // listen every time the views or templates change and push out event
    // TODO: Views can be null, so this will never hit if views are empty
    combineLatest(this.views$, this.viewTemplates$)
      .pipe(takeUntil(this.serviceDestroyed$))
      .subscribe(([views, viewTemplates]) => this.initializeViews(views, viewTemplates));

    // listen every time the dataStore or templates change
    combineLatest(this.viewDataStore$, this.viewTemplates$)
      .pipe(takeUntil(this.serviceDestroyed$))
      .subscribe(([viewDataStore, viewTemplates]) => this.loadViewsFromDataStore(viewDataStore, viewTemplates));
  }

  get viewDataStore(): XpoBoardViewDataStore {
    return this.viewDataStoreValue;
  }
  set viewDataStore(value: XpoBoardViewDataStore) {
    this.viewDataStoreSubject$.next((this.viewDataStoreValue = value));
  }
  get viewDataStore$(): Observable<XpoBoardViewDataStore> {
    return this.viewDataStoreSubject$.asObservable();
  }

  get views(): XpoBoardView[] {
    return this.viewsValue;
  }
  set views(value: XpoBoardView[]) {
    this.viewsSubject$.next((this.viewsValue = value));
  }

  get views$(): Observable<XpoBoardView[]> {
    return this.viewsSubject$.asObservable();
  }

  get viewTemplates(): XpoBoardViewTemplate[] {
    return this.viewTemplatesValue;
  }
  set viewTemplates(value: XpoBoardViewTemplate[]) {
    this.viewTemplatesSubject$.next((this.viewTemplatesValue = value));
  }

  get viewTemplates$(): Observable<XpoBoardViewTemplate[]> {
    return this.viewTemplatesSubject$.asObservable();
  }

  ngOnDestroy(): void {
    this.destroyDataStoreViewSubscription();
    this.serviceDestroyed$.next();
    this.serviceDestroyed$.complete();
  }

  private activateInitialView(views: XpoBoardView[], templates: XpoBoardViewTemplate[]): void {
    const activeView = this.getDefaultActiveView(views);

    this.stateChange$.next({
      ...activeView.getState(),
      pageNumber: 1,
      source: 'BOARD-ACTIVATING-VIEW',
      availableViews: views,
      visibleViewData: views.filter((v) => v.visible).map((v) => <XpoVisibleBoardViewDatum>{ viewId: v.id }),
      viewTemplates: templates,
    });
  }

  private getDefaultActiveView(views: XpoBoardView[]): XpoBoardView {
    let activeView: XpoBoardView = null;

    const activeViewFromQueryParams = this.activatedRoute.snapshot.queryParamMap.get(BOARD_ACTIVE_VIEW_QUERY_PARAM);

    if (!!activeViewFromQueryParams) {
      const queryParamActiveView = views.find((v) => v.id === activeViewFromQueryParams);

      // If there is no view associated with the view id in the query param, remove it if the current board has
      // enableQueryParamStatePersistance. The reason we want to not remove it if the  current board doesn't have
      // enableQueryParamStatePersistance enabled is if the end application routes to a view with another board,
      // we still want the ability to route back to the board with enableQueryParamStatePersistance and set
      // the active view.
      if (!queryParamActiveView) {
        if (this.boardOptions && this.boardOptions.enableQueryParamStatePersistance) {
          // Clear out query param from route and continue
          const queryParams: Params = { [BOARD_ACTIVE_VIEW_QUERY_PARAM]: null };
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: 'merge',
          });
        }
      } else {
        activeView = queryParamActiveView;
      }
    }

    if (!activeView) {
      activeView = views.find((v) => v.isActive) || views[0];
    }

    return activeView;
  }

  private createView(viewConfig: XpoBoardViewConfig): XpoBoardView {
    // find associated template, none found use the first one
    const template = this.viewTemplates.find((t) => t.id === viewConfig.templateId) || this.viewTemplates[0];
    return template.createView(viewConfig);
  }

  private initializeViews(views: XpoBoardView[], templates: XpoBoardViewTemplate[]): void {
    // if there are no views create on and wait for this method to re-execute
    if (!views || !views.length) {
      const configValue: XpoBoardViewConfig = {
        name: XpoBoardViewUtil.getNextViewName(templates[0]),
        visible: true,
        id: templates[0].id,
      };
      const newView = templates[0].createView(configValue);
      this.views = [newView];
    }

    this.activateInitialView(views, templates);
  }

  private loadViewsFromDataStore(dataStore: XpoBoardViewDataStore, templates: XpoBoardViewTemplate[]): void {
    if (!dataStore || !templates || !templates.length) {
      return;
    }

    this.destroyDataStoreViewSubscription();

    this.dataStoreViewSubscription = dataStore
      .loadAll()
      .pipe(
        map((viewConfigs: XpoBoardViewConfig[]) => {
          return (viewConfigs || []).map((config) => this.createView(config));
        })
      )
      .subscribe((persistedViews: XpoBoardView[]) => {
        this.views = [...(persistedViews || [])];
      });
  }

  private destroyDataStoreViewSubscription(): void {
    if (this.dataStoreViewSubscription && !this.dataStoreViewSubscription.closed) {
      this.dataStoreViewSubscription.unsubscribe();
      this.dataStoreViewSubscription = undefined;
    }
  }
}
