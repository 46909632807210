import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

export interface XpoMessagingPopoverMessage {
  body: string;
  received: Date;
  title: string;
  type: string;
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  host: {
    class: 'xpo-MessagingPopover',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NotificationsComponent {
  messagesData = [];
  unreadMsgsData = 0;

  @Input('messages')
  set messages(messages: XpoMessagingPopoverMessage[]) {
    this.messagesData = messages;
  }

  @Input('unreadMsgs')
  set unreadMsgs(unreadMsgs: number) {
    this.unreadMsgsData = unreadMsgs;
  }

  @Output()
  openPopover: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  closePopover: EventEmitter<number> = new EventEmitter<number>();

  open(): void {
    this.openPopover.emit(Date.now());
  }

  close(): void {
    this.closePopover.emit(Date.now());
  }
}
