import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, Inject, Input } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';

@Component({
  selector: 'a[xpo-link], a[xpo-regular-link]',
  template: '<ng-content></ng-content>',
  host: {
    class: 'xpo-Link',
    '[class.xpo-Link--regular]': 'this.hasAttribute("xpo-regular-link")',
    '[class.xpo-Link--accent]': 'color === "accent"',
  },
})
export class XpoLink extends MatAnchor {
  @Input()
  color: ThemePalette = 'primary';

  constructor(
    focusMonitor: FocusMonitor,
    protected elementRef: ElementRef,
    @Inject(ANIMATION_MODULE_TYPE) animationMode: string
  ) {
    super(focusMonitor, elementRef, animationMode);
  }

  hasAttribute(value: string): boolean {
    return this.elementRef.nativeElement.hasAttribute(value);
  }
}
