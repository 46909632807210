import { EventEmitter, Output, Input, Directive } from '@angular/core';
import { XPO_FACET_FILTER_ERROR_CONSTANTS } from '../share/constants/error.constants';
import { XpoFacetFilterTypeControls } from '../share/enums/type-list-control';
import { XpoFacetFilterControlChangeEvent } from '../share/model/control-events.interface';
import { XpoFacetFilterCriteria } from '../share/model/facet-model-criteria.interface';

@Directive()
export abstract class XpoFilterControlBaseComponent<FilterModelParam = any> {
  @Input()
  filterId: string;

  // filterModel is required to be defined in extended class
  @Input()
  abstract filterModel: FilterModelParam;

  // To keep a copy of the original model
  abstract originalFilterModel: FilterModelParam;

  @Output()
  valueChanged: EventEmitter<XpoFacetFilterControlChangeEvent> = new EventEmitter<XpoFacetFilterControlChangeEvent>();

  @Output()
  removeFilter: EventEmitter<void> = new EventEmitter<void>();

  hideTitleSection: boolean = false;
  parentSectionName: string = '';

  protected typeControl: XpoFacetFilterTypeControls;

  get typeComponentControl(): XpoFacetFilterTypeControls {
    return this.typeControl;
  }

  constructor() {}

  checkInit(): void {
    if (!this.filterId) {
      console.error(XPO_FACET_FILTER_ERROR_CONSTANTS.missingFilterId);
    }
  }

  onValueChanged(value: FilterModelParam, controlCriteria: XpoFacetFilterCriteria): void {
    this.valueChanged.emit({ value: value, typeControl: this.typeControl, controlId: this.filterId, controlCriteria });
  }

  onReset(): void{
    this.removeFilter.emit();
  };

  abstract mapFacetFilterControlToCriteria(value): XpoFacetFilterCriteria;

  abstract setModel(newValue: FilterModelParam): void;

  applyFilter(): void{}

  abstract isValidCondition(): boolean;
}
