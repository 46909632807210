import { Injectable } from '@angular/core';

/* XPO */
import {
  DeleteModuleGroupPath,
  DuplicateModuleGroupResp,
  DuplicateModuleGroupRqst,
  LaunchModuleGroupResp,
  LaunchModuleGroupRqst,
  ListModuleGroupRunStatusesPath,
  ListModuleGroupRunStatusesResp,
  ListModuleGroupsQuery,
  ListModuleGroupsResp,
  ModuleExecutorApiService,
  ModuleGroup,
} from '@xpo-ltl/sdk-moduleexecutor';

/* Rxjs */
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/* Enums */
import { ModuleGroupLocalStorageKeys } from '../../module-library-board/enums/local-storage-keys.enum';

/* Models */
import { ModuleGroupGridModel } from '../models/module-group-grid.model';

/* Services */
import { ListModuleGroupsService } from './list-module-groups.service';

@Injectable({
  providedIn: 'root',
})
export class ModuleGroupsService {
  constructor(private listModuleGroupsService: ListModuleGroupsService, private apiService: ModuleExecutorApiService) {}

  listModuleGroups(query = new ListModuleGroupsQuery()): Observable<Array<ModuleGroupGridModel>> {
    return this.listModuleGroupsService.requestListModuleGroups(query).pipe(
      map((response: ListModuleGroupsResp) => {
        let moduleGroupList = new Array<ModuleGroupGridModel>();

        moduleGroupList = response.moduleGroups.map((module: ModuleGroup) => {
          const isNew = this.isNewVersion(module);
          return new ModuleGroupGridModel(
            module.moduleGroupInstId,
            module.moduleGroupName,
            module.moduleGroupDescription,
            module.auditInfo.createdById,
            module.auditInfo.createdTimestamp,
            module.executionTmst,
            module.auditInfo.updatedTimestamp,
            module.runStatus,
            module.schedule,
            isNew
          );
        });

        this.setLastVersionCreated(response.moduleGroups);
        return moduleGroupList;
      })
    );
  }

  deleteModuleGroup(moduleGroupInstId): Observable<void> {
    const pathParams: DeleteModuleGroupPath = { moduleGroupInstId };
    return this.apiService.deleteModuleGroup(pathParams);
  }

  copyModuleGroup(moduleGroupInstId): Observable<DuplicateModuleGroupResp> {
    const request: DuplicateModuleGroupRqst = { moduleGroupInstId };
    return this.apiService.duplicateModuleGroup(request);
  }

  private isNewVersion(version: ModuleGroup): boolean {
    const lastCreated = localStorage.getItem(ModuleGroupLocalStorageKeys.lastCreatedVersion);

    return lastCreated && version.auditInfo.createdTimestamp > new Date(Number(lastCreated));
  }

  private setLastVersionCreated(moduleGroup: ModuleGroup[]): void {
    const lastCreated = localStorage.getItem(ModuleGroupLocalStorageKeys.lastCreatedVersion);

    const maxCreated = Math.max.apply(
      null,
      moduleGroup.map((moduleGroupItem: ModuleGroup) => moduleGroupItem.auditInfo.createdTimestamp)
    );

    if (!lastCreated || maxCreated > new Date(Number(lastCreated))) {
      localStorage.setItem(ModuleGroupLocalStorageKeys.lastCreatedVersion, maxCreated.toString());
    }
  }

  launchModule(moduleGroupInstId): Observable<LaunchModuleGroupResp> {
    const request: LaunchModuleGroupRqst = { moduleGroupInstId };
    return this.apiService.launchModuleGroup(request);
  }

  listModuleGroupRunStatuses(moduleGroupInstId): Observable<ListModuleGroupRunStatusesResp> {
    const pathParams: ListModuleGroupRunStatusesPath = { moduleGroupInstId };
    return this.apiService.listModuleGroupRunStatuses(pathParams);
  }
}
