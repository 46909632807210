import { Component, ContentChildren, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList } from '@angular/core';
import { Subscription } from 'rxjs';
import { XpoFilterControlBaseComponent } from '../filter-controls/filter-control-base.component';
import { XpoFacetFilterControlChangeEvent } from '../share/model/control-events.interface';

@Component({
  selector: 'xpo-facet-filter-section',
  templateUrl: './filter-section.component.html',
  styleUrls: ['./filter-section.component.scss'],
})
export class XpoFacetFilterSection  implements OnInit, OnDestroy {
  @ContentChildren(XpoFilterControlBaseComponent) controls: QueryList<XpoFilterControlBaseComponent>;

  private controlSubscriptions: Subscription[] = [];

  @Input()
  sectionName: string;

  @Output()
  valueChanged: EventEmitter<XpoFacetFilterControlChangeEvent> = new EventEmitter<XpoFacetFilterControlChangeEvent>();

  showTitle: boolean = true;
  showReset: boolean = false;

  ngOnInit(): void {}

  constructor() {}

  ngOnDestroy(): void {
    if (this.controlSubscriptions.length) {
      this.controlSubscriptions.forEach((item) => item.unsubscribe());
    }
  }

  configComponent(): void {
    this.controls.forEach((control) => {
      const subscription = control.valueChanged.subscribe((event: XpoFacetFilterControlChangeEvent) => {
        this.valueChanged.emit(event);
        if (!event.controlCriteria.invalid){
          this.showReset = true;
        }
      });
      this.showTitle = !control.hideTitleSection;
      const subscriptionReset = control.removeFilter.subscribe(() => {
        this.showReset = false;
      });
      this.controlSubscriptions.push(subscription);
      this.controlSubscriptions.push(subscriptionReset);
      control.parentSectionName = this.sectionName;
      control.applyFilter();
    });
  }

  onReset(): void {
    this.controls.forEach((control) => control.onReset());
    this.showReset = false;
  }

  getSectionControlById(controlId: string): XpoFilterControlBaseComponent {
    return this.controls.find((control) => control.filterId === controlId);
  }
}
