/**
 * Im'm just moving OperatorsToOperatorText out of applied filters. This deserves a refactor.
 * that will require filters operators updates.
 */

import { OperatorText } from './operator-text.enum';
import { Operators } from './operators.enum';

/**
 * TODO: Refactor Operators, OperatorText and OperatorsToOperatorText.
 */
export const OperatorsToOperatorText = {
  [Operators.Equals]: OperatorText.Is,
  [Operators.NotEquals]: OperatorText.IsNot,
  [Operators.Empty]: OperatorText.Empty,
  [Operators.NotEmpty]: OperatorText.NotEmpty,
  [Operators.Greater]: OperatorText.Greater,
  [Operators.GreaterEqual]: OperatorText.GreaterEqual,
  [Operators.Less]: OperatorText.Less,
  [Operators.LessEqual]: OperatorText.LessEqual,
  [Operators.Between]: OperatorText.Between,
  [Operators.NotBetween]: OperatorText.NotBetween,
  [Operators.Contains]: OperatorText.Contains,
  [Operators.NotContains]: OperatorText.NotContains,
  [Operators.EndsWith]: OperatorText.EndsWith,
  [Operators.StartsWith]: OperatorText.StartsWith,
  [Operators.OneOf]: OperatorText.OneOf,
  [Operators.True]: OperatorText.True,
  [Operators.NotTrue]: OperatorText.False,
  [Operators.After]: OperatorText.After,
  [Operators.OnOrAfter]: OperatorText.OnOrAfter,
  [Operators.Before]: OperatorText.Before,
  [Operators.OnOrBefore]: OperatorText.OnOrBefore,
  [Operators.Relative]: OperatorText.Is,
};
