import {TripActivityTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class TripActivityTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TripActivityTypeCd ) {
      this._values.push(TripActivityTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TripActivityTypeCd {

    for ( const obj in TripActivityTypeCd ) {
      if (TripActivityTypeCd[obj] === value){
        return TripActivityTypeCd[obj] as TripActivityTypeCd;
      }
    }
  }
}

const TripActivityTypeCdHelper = new TripActivityTypeCdHelperEnumHelperClass();
export default TripActivityTypeCdHelper;
