import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XpoUppercaseDirective } from './uppercase.directive';

const directives = [XpoUppercaseDirective];

@NgModule({
  imports: [CommonModule],
  declarations: directives,
  exports: directives,
})
export class XpoUppercaseModule {}
