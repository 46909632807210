import {ModuleExecutorDatasetTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ModuleExecutorDatasetTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ModuleExecutorDatasetTypeCd ) {
      this._values.push(ModuleExecutorDatasetTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ModuleExecutorDatasetTypeCd {

    for ( const obj in ModuleExecutorDatasetTypeCd ) {
      if (ModuleExecutorDatasetTypeCd[obj] === value){
        return ModuleExecutorDatasetTypeCd[obj] as ModuleExecutorDatasetTypeCd;
      }
    }
  }
}

const ModuleExecutorDatasetTypeCdHelper = new ModuleExecutorDatasetTypeCdHelperEnumHelperClass();
export default ModuleExecutorDatasetTypeCdHelper;
