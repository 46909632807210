import { Observable } from 'rxjs';

import { XpoBoardState } from './board-state.model';

export interface XpoBoardExportableDataSource {
  export(state: XpoBoardState): Observable<File>;
  isExportDisabled?(): Observable<boolean>;
}

export function isExportableDataSource(dataSource: any): dataSource is XpoBoardExportableDataSource {
  return <XpoBoardExportableDataSource>dataSource && (<XpoBoardExportableDataSource>dataSource).export !== undefined;
}
