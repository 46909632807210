import {CustomerPaymentTermsCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerPaymentTermsCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerPaymentTermsCd ) {
      this._values.push(CustomerPaymentTermsCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerPaymentTermsCd {

    for ( const obj in CustomerPaymentTermsCd ) {
      if (CustomerPaymentTermsCd[obj] === value){
        return CustomerPaymentTermsCd[obj] as CustomerPaymentTermsCd;
      }
    }
  }
}

const CustomerPaymentTermsCdHelper = new CustomerPaymentTermsCdHelperEnumHelperClass();
export default CustomerPaymentTermsCdHelper;
