import {RouteDetailCd} from '../';
import {EnumHelper} from './enum-helper';

export class RouteDetailCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RouteDetailCd ) {
      this._values.push(RouteDetailCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RouteDetailCd {

    for ( const obj in RouteDetailCd ) {
      if (RouteDetailCd[obj] === value){
        return RouteDetailCd[obj] as RouteDetailCd;
      }
    }
  }
}

const RouteDetailCdHelper = new RouteDetailCdHelperEnumHelperClass();
export default RouteDetailCdHelper;
