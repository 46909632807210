export class ModuleGroupGridModel {
  constructor(
    public moduleGroupInstId: Number,
    public name: string,
    public description: string,
    public createdBy: string,
    public created: Date,
    public lastRun: Date,
    public lastModified: Date,
    public runStatus: string,
    public schedule: string,
    public isNew?: boolean
  ) {}
}
