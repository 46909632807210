import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { cloneDeep} from 'lodash';
import { XpoFacetFilterTypeControls } from '../../share/enums/type-list-control';
import { XpoFacetFilterCriteria } from '../../share/model/facet-model-criteria.interface';
import { XpoFacetFilterNumberOperators, XpoFacetFilterTypeOperator } from '../../share/model/filter-criteria.interface';
import { XpoFacetFilterRangeNumberModel } from '../../share/model/range-number.interface';
import { XpoFilterControlBaseComponent } from '../filter-control-base.component';
import { XpoFacetRangeFilterTexts } from './filter-control-range-enum-texts';

@Component({
  selector: 'xpo-range-facet-filter',
  templateUrl: './filter-control-range.component.html',
  styleUrls: ['./filter-control-range.component.scss'],
  providers: [{ provide: XpoFilterControlBaseComponent, useExisting: forwardRef(() => XpoFacetFilterRangeNumberControlComponent) }],
})
export class XpoFacetFilterRangeNumberControlComponent
  extends XpoFilterControlBaseComponent<XpoFacetFilterRangeNumberModel | XpoFacetFilterRangeNumberModel[]> implements OnInit {

  originalFilterModel: XpoFacetFilterRangeNumberModel;
  typeControl: XpoFacetFilterTypeControls = XpoFacetFilterTypeControls.RangeMinMax;

  @Input()
  filterModel: XpoFacetFilterRangeNumberModel | XpoFacetFilterRangeNumberModel[] = {} as XpoFacetFilterRangeNumberModel;

  rangeLabelsLinks: string[] = [];


  constructor() {
    super();
  }

  get isArrayOfValues(): boolean {
    return Array.isArray(this.filterModel);
  }

  get originalRangeValues(): XpoFacetFilterRangeNumberModel {
    return this.isArrayOfValues ? { minValue: undefined, maxValue: undefined } : <XpoFacetFilterRangeNumberModel>this.filterModel;
  }

  ngOnInit(): void {
    super.checkInit();
    this.originalFilterModel = cloneDeep(this.originalRangeValues);
    this.setRangeLabels();
  }

  setModel(model: XpoFacetFilterRangeNumberModel): void {
    this.filterModel = model;
    this.originalFilterModel = cloneDeep(this.filterModel);
  }

  applyFilter(): void {
    this.onValueChanged(this.originalFilterModel, this.mapFacetFilterControlToCriteria(cloneDeep(this.originalFilterModel)));
  }

  setFilterLink(value: XpoFacetFilterRangeNumberModel): void {
    this.originalFilterModel = cloneDeep(value);
    this.applyFilter();
  }

  validateKey($event: KeyboardEvent): void {
    if ($event.code === 'Enter') {
      this.applyFilter();
    }
  }

  onReset(): void {
    super.onReset();
    this.originalFilterModel = cloneDeep(this.originalRangeValues);
    this.applyFilter();
  }

  mapFacetFilterControlToCriteria(val: XpoFacetFilterRangeNumberModel): XpoFacetFilterCriteria {
    let operator: XpoFacetFilterNumberOperators = XpoFacetFilterNumberOperators.BETWEEN;
    if (val.minValue === undefined || val.minValue === null) {
      operator = XpoFacetFilterNumberOperators.LESSERTHAN;
      val.minValue = val.maxValue;
      val.maxValue = undefined;
    } else if (val.maxValue === undefined || val.maxValue === null) {
      operator = XpoFacetFilterNumberOperators.GREATERTHAN;
    }
    return {
      filterId: this.filterId,
      type: XpoFacetFilterTypeOperator.Number,
      conditions: [{ operator, value: val.minValue, valueTo: val.maxValue }],
      invalid: !this.isValidCondition(),
    };
  }

  isValidCondition(): boolean {
    return (this.originalFilterModel.maxValue !== undefined || this.originalFilterModel.minValue !== undefined);
  }

  private setRangeLabels(): void {
    if (this.isArrayOfValues) {
      this.rangeLabelsLinks = (<unknown>this.filterModel as []).map((item: XpoFacetFilterRangeNumberModel) => {
        let labelTextFrom: string = item.minValue === undefined ? `${XpoFacetRangeFilterTexts.Under} ` : `${item.minValue.toString()} - `;
        if (item.maxValue === undefined ) {
          labelTextFrom  =  `${XpoFacetRangeFilterTexts.Under} `;
        }
        const labelTextTo: string = item.maxValue === undefined ? item.minValue.toString() : item.maxValue.toString();
        return labelTextFrom  + labelTextTo;
      });
    }
  }
}
