import {ExceptionCategoryCd} from '../';
import {EnumHelper} from './enum-helper';

export class ExceptionCategoryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ExceptionCategoryCd ) {
      this._values.push(ExceptionCategoryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ExceptionCategoryCd {

    for ( const obj in ExceptionCategoryCd ) {
      if (ExceptionCategoryCd[obj] === value){
        return ExceptionCategoryCd[obj] as ExceptionCategoryCd;
      }
    }
  }
}

const ExceptionCategoryCdHelper = new ExceptionCategoryCdHelperEnumHelperClass();
export default ExceptionCategoryCdHelper;
