import { FlexibleConnectedPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Optional, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { XpoDateRangeCalendar } from './components/calendar/date-range-calendar.component';
import { XpoDateRangeInput } from './components/inputs/date-range-input.component';
import { XpoDateRangeSelectionModel } from './models/date-range-selection.model';

/**
 * Component responsible for managing the date range picker popup/dialog.
 */

// (from material): We use a component instead of a directive here so the user can use implicit
// template reference variables (e.g. #d vs #d="xpoDateRangePicker"). We can change this to a
// directive if angular adds support for `exportAs: '$implicit'` on directives.
@Component({
  selector: 'xpo-date-range-picker',
  template: '',
  exportAs: 'xpoDateRangePicker',
})
export class XpoDateRangePicker<D> {
  /** Emits when the datepicker has been opened. */
  @Output() opened: EventEmitter<void> = new EventEmitter<void>();

  /** Emits when the datepicker has been closed. */
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  /** The input element this datepicker is associated with. */
  protected dateRangeInput: XpoDateRangeInput<D> = null;

  /** Reference to the overlay when the calendar is opened */
  protected popupRef: OverlayRef = null;

  /** The element that was focused before the datepicker was opened. */
  protected focusedElementBeforeOpen: HTMLElement = null;

  /** Model for the selected range */
  selectionModel: XpoDateRangeSelectionModel<D> = new XpoDateRangeSelectionModel<D>(this.dateAdapter);

  constructor(
    @Optional() @Inject(DOCUMENT) protected document,
    protected overlay: Overlay,
    protected dateAdapter: DateAdapter<D>
  ) {}

  /**
   * Whether the calendar is open.
   **/
  isOpened(): boolean {
    return this.popupRef !== null;
  }

  /**
   * Register an input with this date picker
   * @param input
   */
  registerInput(input: XpoDateRangeInput<D>): void {
    this.dateRangeInput = input;
  }

  /**
   * Opens the date range picker popup
   */
  open(): void {
    // create the popup overlay with the
    // date range calendar component rendered inside it
    this.createPopup();

    // keep the las focused element before open
    // to return de focus after close
    if (this.document) {
      this.focusedElementBeforeOpen = this.document.activeElement;
    }

    // emit the open event
    this.opened.emit();
  }

  /**
   * Closes date range picker popup
   */
  close(): void {
    this.destroyPopup();

    // after the popup is closed
    // return the focus to the element that has it before open (if it can take the focus)
    if (this.focusedElementBeforeOpen && typeof this.focusedElementBeforeOpen.focus === 'function') {
      this.focusedElementBeforeOpen.focus();
      this.focusedElementBeforeOpen = null;
    }

    // emit the close event
    this.closed.emit();
  }

  /**
   * Create the popup that contains the calendar
   */
  protected createPopup(): void {
    // create the popup position strategy
    const positionStrategy: FlexibleConnectedPositionStrategy = this.createPositionStrategy();

    // create the popup configuration object
    const popupConfig = new OverlayConfig({
      positionStrategy: positionStrategy,
      hasBackdrop: true,
      backdropClass: 'mat-overlay-transparent-backdrop',
      panelClass: 'xpo-DateRangePicker-popup',
    });

    // create the popup
    this.popupRef = this.overlay.create(popupConfig);

    // create a portal to the calendar component to be attached and renderer inside the popup
    const calendarPortal = new ComponentPortal<XpoDateRangeCalendar<D>>(XpoDateRangeCalendar);

    // attach the calendar portal to the popup overlay and keep the calendar component instance
    const calendar = this.popupRef.attach(calendarPortal).instance;

    // set the reference to this date range picker inside the calendar component
    calendar.registerDateRangePicker(this);

    // close the calendar when clicking outside
    this.popupRef.backdropClick().subscribe(() => this.close());
  }

  /**
   * Destroys the popup
   */
  protected destroyPopup(): void {
    this.popupRef.dispose();
    this.popupRef = null;
  }

  /**
   * Creates the position strategy for the popup
   */
  protected createPositionStrategy(): FlexibleConnectedPositionStrategy {
    // create the position strategy
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.dateRangeInput.getConnectedOverlayOrigin())
      .withFlexibleDimensions(false)
      .withLockedPosition();

    // set the connected positions
    this.setConnectedPositions(positionStrategy);

    return positionStrategy;
  }

  /**
   * Sets the preferred positions where to show the popup
   * @param strategy
   */
  protected setConnectedPositions(strategy: FlexibleConnectedPositionStrategy): void {
    // set the positions where to open the popup
    strategy.withPositions([
      // below the input, left aligned
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      },
      // above the input, left aligned
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
      },
    ]);
  }
}
