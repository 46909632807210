import {ShipmentAgendaItemActionCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentAgendaItemActionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentAgendaItemActionCd ) {
      this._values.push(ShipmentAgendaItemActionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentAgendaItemActionCd {

    for ( const obj in ShipmentAgendaItemActionCd ) {
      if (ShipmentAgendaItemActionCd[obj] === value){
        return ShipmentAgendaItemActionCd[obj] as ShipmentAgendaItemActionCd;
      }
    }
  }
}

const ShipmentAgendaItemActionCdHelper = new ShipmentAgendaItemActionCdHelperEnumHelperClass();
export default ShipmentAgendaItemActionCdHelper;
