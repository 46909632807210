import { Component, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { HourFormatErrorEvent } from '../shared/exact-time-input/hour-format-error-event';
import {
  CustomFilterParams,
  filterFormats,
  Operators,
  OperatorText,
  XpoFilterConditionCriteria,
} from './../../../../../models/index';

@Component({
  templateUrl: './equals.component.html',
  styleUrls: ['../../validator.scss'],
})
export class XpoConditionTimeEqualsComponent extends XpoConditionBase implements XpoFilterCondition {
  static text: string = OperatorText.Exactly;
  static operator: Operators = Operators.Equals;

  isNegative = false;
  model: string = '';
  format24Error: boolean = false;

  // TODO: This is to solve the issue with moment taking full date values only and is meant to be redone
  // in a more correct manner
  fakeDate = '01/01/2020';

  is24Hour: boolean = false;
  @Output() inputChanged = new EventEmitter();

  set customFilterParams(params: CustomFilterParams) {
    this.is24Hour = params.is24HourFormat;

    // TODO: this fill be fixed when refactoring this whole thing
    if (!this.is24Hour) {
      this.changeFormat(filterFormats.default);
    }
  }

  constructor() {
    super(filterFormats.time);
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    if (!cellValue) {
      return false;
    }
    const cell = moment(`${cellValue} ${this.fakeDate}`).format('HH:mm');

    return cell === this.model;
  }

  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.Equals,
      value: this.model,
    };
  }

  onInputChange(value): void {
    this.model = value;

    // TODO: this fill be fixed when refactoring this whole thing
    if (this.is24Hour) {
      this.validateFormat();
    }

    this.inputChanged.emit(this.model);
  }
}
