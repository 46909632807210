import {ServiceLevelCd} from '../';
import {EnumHelper} from './enum-helper';

export class ServiceLevelCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ServiceLevelCd ) {
      this._values.push(ServiceLevelCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ServiceLevelCd {

    for ( const obj in ServiceLevelCd ) {
      if (ServiceLevelCd[obj] === value){
        return ServiceLevelCd[obj] as ServiceLevelCd;
      }
    }
  }
}

const ServiceLevelCdHelper = new ServiceLevelCdHelperEnumHelperClass();
export default ServiceLevelCdHelper;
