
export enum MiscLineItemCd {
	AS_WGT = 'AsWgt',
	CASH_COLLECTED_LN = 'CashCollectedLn',
	CASH_PPD_LN = 'CashPpdLn',
	COD_AMT = 'CodAmt',
	COD_PMT = 'CodPmt',
	DEFICIT_WGT = 'DeficitWgt',
	DISC_LN = 'DiscLn',
	PART_COLL_LN = 'PartCollLn',
	PART_PPD_LN = 'PartPpdLn'}

