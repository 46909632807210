/*
Tests for new view management logic will be handled in 	NGXLTL-967
*/
import { AfterViewInit, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { XpoLtlPopover, XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { map, take } from 'rxjs/operators';
import { XpoBoardViewDataStore, XpoBoardViews } from '../board-views';
import {
  XpoBoardState,
  XpoBoardStateSources,
  XpoBoardStateViewer,
  XpoBoardView,
  XpoBoardViewUtil,
  XpoGridBoardState,
} from '../models/index';

import { ReplaySubject } from 'rxjs';
import { XpoViewListItem } from './view-list';
import { XpoViewMgtMessages } from './view-management-messages';
import { XpoViewManagementOptions } from './view-management-options.interface';
import {
  XpoViewMgtModalDeleteView,
  XpoViewMgtModalRenameView,
  XpoViewMgtModalSaveViewAs,
  XpoViewMgtModalTexts,
} from './view-modal/index';

@Component({
  selector: 'xpo-view-management',
  templateUrl: 'view-management.component.html',
  styleUrls: ['view-management.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class XpoViewManagementComponent implements XpoBoardStateViewer, AfterViewInit {
  readonly CONNECTION_ERROR_MESSAGE = '[XPO-LTL-BOARD] View Management: Data source could not connect';
  private currentState: XpoBoardState;
  stateChange$ = new ReplaySubject<XpoBoardState>(1);
  viewIncrementor: number = 0;
  isMenuExpanded: boolean = false;
  viewList: XpoViewListItem[] = [];

  // State changes for which we want to react on state change
  viewStateSources: string[] = [
    XpoBoardStateSources.BoardActivatingView,
    XpoBoardStateSources.ActiveViewChange,
    XpoBoardStateSources.SaveViewAs,
    XpoBoardStateSources.DeleteView,
  ];
  selectedView: XpoViewListItem;

  @Input() viewMgtOptions?: XpoViewManagementOptions = {
    persistFiltersBetweenViews: false,
  };
  @Input() dataSource: any; // TODO: NGXLTL-1099 Define a type here
  @Input() viewDataStore: XpoBoardViewDataStore;
  @ViewChild('viewsMenuTrigger') viewsMenuTrigger: MatMenuTrigger;
  @ViewChild('viewsSaveActions') viewSaveActions: XpoLtlPopover;
  @ViewChild('viewsMoreActions') viewsMoreActions: XpoLtlPopover;

  constructor(private viewMgtDialog: MatDialog, private viewMgtSnackbar: XpoSnackBar) {}

  ngAfterViewInit() {
    if (this.dataSource?.connect) {
      this.dataSource.connect(this).subscribe((state: XpoBoardState) => {
        this.onStateChange(state);
      });
    } else {
      console.error(`${this.CONNECTION_ERROR_MESSAGE}`);
    }
  }
  /**
   * Handles state change
   * @param state
   */
  onStateChange(state: XpoGridBoardState): void {
    if (!state) {
      return;
    }
    this.currentState = state;

    // Mark the view as dirty and enable saving.
    if (state.source === XpoBoardStateSources.GridSettingsUpdate) {
      this.markViewAsDirty();
    }

    // Map available views to current view list
    if (this.shouldRefreshViewList(state)) {
      this.populateViewList(state.availableViews);
    }
  }

  /**
   * Check if should move forward handling the state change
   * @param stateSource
   */
  shouldRefreshViewList(state: XpoBoardState): boolean {
    return this.viewStateSources.some((source) => source === state.source) && state.availableViews.length > 0;
  }

  /**
   * Map viewList with current availableViews from state
   * @param availableViews
   */
  populateViewList(availableViews: XpoBoardView[]): void {
    /* We need to preserve dirty views before re-creating the list. We should not add dirty as a view property because
    we would need to update/save the view every time it gets dirty but this property should no be 'saved' as part of the view,
    it's an in memory thing. */
    const dirtyViews = this.viewList.filter((view) => view.dirty);
    this.viewList = [];

    availableViews.forEach((view) => {
      this.viewList.push({
        id: view.id,
        name: view.name || XpoViewMgtModalTexts.DefaultViewName,
        group: 'TBD',
        owner: 'TBD',
        lastUpdated: view.lastSaved,
        bookmarked: true,
        dirty: dirtyViews.some((dView) => dView.id === view.id),
      });
    });
    this.updateSelectedView(this.currentState.viewId);
  }

  /**
   * Handle click on view item
   * @param view
   */
  onItemClick(view: XpoBoardView): void {
    const selectedView = this.currentState.availableViews.find((availableView) => availableView.id === view.id);
    this.setActiveView(selectedView);
    this.viewsMenuTrigger.closeMenu();
  }

  /**
   * Switch to the given view
   * @param view
   */
  setActiveView(view: XpoBoardView): void {
    // TODO: [NGXLTL-971] Check if enableQueryParamStatePersistance should be still supported
    this.dataSource.setState(
      XpoBoardViewUtil.activateView(
        view,
        this.currentState,
        XpoBoardViews.XpoBoardViewsSources.ActiveViewChange,
        this.viewMgtOptions?.persistFiltersBetweenViews
      )
    );
    this.updateSelectedView(view.id);
  }

  /**
   * Expand / Collapse menu
   */
  onMenuTriggerClick(): void {
    this.isMenuExpanded = !this.isMenuExpanded;
  }
  // TODO:[NGXLTL-1018] Defining this for future use
  onMenuOpened(): void {}
  // TODO:[NGXLTL-1018] Defining this for future use
  onMenuClosed(): void {}

  /**
   * Returns favorite views
   */
  getFavorites(): XpoViewListItem[] {
    // TODO:[NGXLTL-1018] This will be handled in another way, this is just a mocked thing
    return this.viewList.filter((item) => item.bookmarked === true);
  }

  /**
   * Save view dialog
   * @param isSaveAs
   */
  openSaveViewAsDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px'; // [NGXLTL-1018] TODO we'll try to handle this differently
    dialogConfig.data = {
      actions: {
        onSubmit: (formData) => {
          this.saveViewAs(formData.viewName);
        },
      },
    };
    this.viewMgtDialog.open(XpoViewMgtModalSaveViewAs, dialogConfig);
    this.viewSaveActions.closed.emit();
  }

  /**
   * Rename view dialog
   */
  openRenameViewDialog(): void {
    const currentView = XpoBoardViewUtil.findActiveView(this.currentState);
    const dialogConfig = new MatDialogConfig();

    dialogConfig.width = '600px'; // [NGXLTL-1018] TODO we'll try to handle this differently
    dialogConfig.data = {
      formData: {
        viewName: currentView.name,
      },
      actions: {
        onSubmit: (formData) => {
          this.renameView(formData.viewName);
        },
      },
    };
    this.viewMgtDialog.open(XpoViewMgtModalRenameView, dialogConfig);
    this.viewsMoreActions.closed.emit();
  }

  /**
   * Delete view dialog
   */
  openDeleteViewDialog(): void {
    const currentView = XpoBoardViewUtil.findActiveView(this.currentState);
    const dialogConfig = new MatDialogConfig();

    dialogConfig.width = '600px'; // [NGXLTL-1018] TODO we'll try to handle this differently
    dialogConfig.data = {
      formData: {
        viewName: currentView.name,
      },
      actions: {
        onSubmit: () => {
          this.deleteView();
        },
      },
    };
    this.viewMgtDialog.open(XpoViewMgtModalDeleteView, dialogConfig);
    this.viewsMoreActions.closed.emit();
  }

  /**
   * Save as new view
   * @param viewName
   */
  saveViewAs(viewName: string): void {
    const saveViewId = `${viewName}-${this.viewIncrementor++}`;
    const result = this.viewDataStore
      .save({
        ...this.currentState,
        id: saveViewId,
        lastSaved: new Date(),
        name: viewName,
        systemDefined: false,
        closeable: true,
      })
      .pipe(
        map((savedView) => {
          const newView = this.currentState.template.createView(savedView);
          const nextState = XpoBoardViewUtil.addView(newView, this.currentState, XpoBoardStateSources.SaveViewAs);
          return nextState;
        })
      );
    result.pipe(take(1)).subscribe((newState) => {
      this.viewMgtDialog.closeAll();
      this.viewMgtSnackbar.success(XpoViewMgtMessages.SaveViewAsSuccess);
      this.markViewAsDirty(false);
      this.dataSource.setState(newState);
    });
  }

  /**
   * Save changes on current view
   */
  saveCurrentView(): void {
    const currentView = XpoBoardViewUtil.findActiveView(this.currentState);
    const updatedView = currentView.toViewConfig(this.currentState);
    const result = this.viewDataStore.save(updatedView).pipe(
      map((savedView) => {
        const newView = this.currentState.template.createView(savedView);
        const nextState = XpoBoardViewUtil.updateView(newView, this.currentState, XpoBoardStateSources.SaveView);
        return nextState;
      })
    );
    result.pipe(take(1)).subscribe((newState) => {
      this.viewMgtDialog.closeAll();
      this.viewMgtSnackbar.success(XpoViewMgtMessages.SaveViewSuccess);
      this.markViewAsDirty(false);
      this.dataSource.setState(newState);
    });
  }

  /**
   * Rename current view
   * @param viewName
   */
  renameView(viewName: string): void {
    const currentView = XpoBoardViewUtil.findActiveView(this.currentState);
    const viewConfig = currentView.toViewConfig(this.currentState);
    viewConfig.name = viewName;

    const result = this.viewDataStore.save(viewConfig).pipe(
      map((savedView) => {
        const newView = this.currentState.template.createView(savedView);
        const nextState = XpoBoardViewUtil.updateView(
          newView,
          this.currentState,
          XpoBoardStateSources.ActiveViewChange
        );

        return nextState;
      })
    );
    result.pipe(take(1)).subscribe((newState) => {
      this.viewMgtDialog.closeAll();
      this.viewMgtSnackbar.success(XpoViewMgtMessages.RenameViewSuccess);
      this.dataSource.setState(newState);
    });
  }
  /**
   * Refresh current view
   */
  refreshView(): void {
    this.setActiveView(XpoBoardViewUtil.findActiveView(this.currentState));
    this.markViewAsDirty(false);
  }

  /**
   * Delete current view
   */
  deleteView(): void {
    const activeView = XpoBoardViewUtil.findActiveView(this.currentState);
    const result = this.viewDataStore.delete(activeView.id).pipe(
      map((status) => {
        return XpoBoardViewUtil.deleteView(activeView.id, this.currentState, XpoBoardStateSources.DeleteView);
      })
    );
    result.pipe(take(1)).subscribe((newState) => {
      this.viewMgtDialog.closeAll();
      this.viewMgtSnackbar.success(XpoViewMgtMessages.DeleteViewSuccess);
      this.markViewAsDirty(false);
      this.dataSource.setState(newState);
    });
    this.viewsMoreActions.closed.emit();
  }

  /**
   * Set a view as dirty
   * @param dirty
   */
  markViewAsDirty(dirty: boolean = true) {
    const view = this.viewList.find((view) => view.id === this.selectedView.id);
    view.dirty = dirty;
  }

  /**
   * Update selected view from viewId
   * @param viewId
   */
  updateSelectedView(viewId: string): void {
    this.selectedView = this.viewList.find((view) => view.id === viewId);
  }
}
