import { Pipe, PipeTransform } from '@angular/core';
import { startCase } from 'lodash';


@Pipe({
  name: 'camelCaseToHyphen'
})
export class CamelCaseToHyphenPipe implements PipeTransform {

  transform(value: string): any {
    if (value) {
      return startCase(value).replace(/\s+/g, '-');
    }
    return null;
  }
}
