import { formatDate, formatNumber } from '@angular/common';

export interface XpoAgGridFormatterParams {
  value: any;
}

export class XpoAgGridFormatters {
  private static nullValueFallbackString: string = '-';

  static booleanAsYesNo(params: XpoAgGridFormatterParams): string {
    return params && params.value === true ? 'Yes' : 'No';
  }
  static zeroAsDash(params: XpoAgGridFormatterParams): string {
    return params && params.value !== 0 ? params.value : XpoAgGridFormatters.nullValueFallbackString;
  }

  static formatNumber(params: XpoAgGridFormatterParams, locale: string = 'en-US'): string {
    try {
      return isNaN(params.value) ? params.value : formatNumber(params.value, locale);
    } catch (err) {
      return params.value;
    }
  }

  // todo: need deprecate.
  static formatToMediumDate(params: XpoAgGridFormatterParams, locale: string = 'en-US'): string {
    try {
      return formatDate(params.value, 'mediumDate', locale);
    } catch (err) {
      return XpoAgGridFormatters.nullValueFallbackString;
    }
  }

  // todo: need deprecate.
  static formatToShortDate(params: XpoAgGridFormatterParams, locale: string = 'en-US'): string {
    try {
      return formatDate(params.value, 'hh:mm', locale);
    } catch (err) {
      return XpoAgGridFormatters.nullValueFallbackString;
    }
  }

  /** format options: https://angular.io/api/common/DatePipe#usage-notes in angular */
  static formatToDateTime(
    params: XpoAgGridFormatterParams,
    format: string = 'MMM dd, yyyy, hh:mm aa',
    locale: string = 'en-US'
  ): string {
    try {
      return formatDate(params.value, format, locale);
    } catch (err) {
      return XpoAgGridFormatters.nullValueFallbackString;
    }
  }
}
