import {LicenseEquipmentBodyCd} from '../';
import {EnumHelper} from './enum-helper';

export class LicenseEquipmentBodyCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LicenseEquipmentBodyCd ) {
      this._values.push(LicenseEquipmentBodyCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LicenseEquipmentBodyCd {

    for ( const obj in LicenseEquipmentBodyCd ) {
      if (LicenseEquipmentBodyCd[obj] === value){
        return LicenseEquipmentBodyCd[obj] as LicenseEquipmentBodyCd;
      }
    }
  }
}

const LicenseEquipmentBodyCdHelper = new LicenseEquipmentBodyCdHelperEnumHelperClass();
export default LicenseEquipmentBodyCdHelper;
