import {ModuleExecutorSuccessfulCd} from '../';
import {EnumHelper} from './enum-helper';

export class ModuleExecutorSuccessfulCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ModuleExecutorSuccessfulCd ) {
      this._values.push(ModuleExecutorSuccessfulCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ModuleExecutorSuccessfulCd {

    for ( const obj in ModuleExecutorSuccessfulCd ) {
      if (ModuleExecutorSuccessfulCd[obj] === value){
        return ModuleExecutorSuccessfulCd[obj] as ModuleExecutorSuccessfulCd;
      }
    }
  }
}

const ModuleExecutorSuccessfulCdHelper = new ModuleExecutorSuccessfulCdHelperEnumHelperClass();
export default ModuleExecutorSuccessfulCdHelper;
