import {ShipmentPnDStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentPnDStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentPnDStatusCd ) {
      this._values.push(ShipmentPnDStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentPnDStatusCd {

    for ( const obj in ShipmentPnDStatusCd ) {
      if (ShipmentPnDStatusCd[obj] === value){
        return ShipmentPnDStatusCd[obj] as ShipmentPnDStatusCd;
      }
    }
  }
}

const ShipmentPnDStatusCdHelper = new ShipmentPnDStatusCdHelperEnumHelperClass();
export default ShipmentPnDStatusCdHelper;
