import {CustomerIdTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerIdTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerIdTypeCd ) {
      this._values.push(CustomerIdTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerIdTypeCd {

    for ( const obj in CustomerIdTypeCd ) {
      if (CustomerIdTypeCd[obj] === value){
        return CustomerIdTypeCd[obj] as CustomerIdTypeCd;
      }
    }
  }
}

const CustomerIdTypeCdHelper = new CustomerIdTypeCdHelperEnumHelperClass();
export default CustomerIdTypeCdHelper;
