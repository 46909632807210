import {ChangeRsnCd} from '../';
import {EnumHelper} from './enum-helper';

export class ChangeRsnCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ChangeRsnCd ) {
      this._values.push(ChangeRsnCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ChangeRsnCd {

    for ( const obj in ChangeRsnCd ) {
      if (ChangeRsnCd[obj] === value){
        return ChangeRsnCd[obj] as ChangeRsnCd;
      }
    }
  }
}

const ChangeRsnCdHelper = new ChangeRsnCdHelperEnumHelperClass();
export default ChangeRsnCdHelper;
