import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'pastPresentFuture',
})
export class PastPresentFuturePipe implements PipeTransform {
  transform(value: Date, pastLabel: string, presentLabel: string, futureLabel: string): any {
    const startOfDay = moment()
      .startOf('day')
      .toDate();
    const endOfDay = moment()
      .endOf('day')
      .toDate();

    if (value < startOfDay) {
      return pastLabel;
    } else if (value > endOfDay) {
      return futureLabel;
    } else {
      return presentLabel;
    }
  }
}
