import { XpoAgGridBoardApiAction } from './ag-grid-board-api-actions.model';
import { XpoAgGridBoardApiDispatcherService } from './ag-grid-board-api-dispatcher.service';

export class XpoAgGridBoardApi {
  constructor(private agGridBoardApiDispatcher: XpoAgGridBoardApiDispatcherService) {}

  focusRow(row: any): void {
    this.agGridBoardApiDispatcher.executeAction(XpoAgGridBoardApiAction.FocusRow, row);
  }

  updateEditingState(isEditing: boolean, field?: string): void {
    this.agGridBoardApiDispatcher.executeAction(XpoAgGridBoardApiAction.FocusRow, isEditing, field);
  }
}
