import {InvoiceAccuracyConstraintCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoiceAccuracyConstraintCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoiceAccuracyConstraintCd ) {
      this._values.push(InvoiceAccuracyConstraintCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoiceAccuracyConstraintCd {

    for ( const obj in InvoiceAccuracyConstraintCd ) {
      if (InvoiceAccuracyConstraintCd[obj] === value){
        return InvoiceAccuracyConstraintCd[obj] as InvoiceAccuracyConstraintCd;
      }
    }
  }
}

const InvoiceAccuracyConstraintCdHelper = new InvoiceAccuracyConstraintCdHelperEnumHelperClass();
export default InvoiceAccuracyConstraintCdHelper;
