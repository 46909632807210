import _get from 'lodash-es/get';
import { XpoGridBoardColumn } from './grid-board-column.model';
import { XpoGridBoardState } from './grid-board-state.model';
import { XpoGridBoardViewConfig, XpoGridBoardVisibleColumn } from './grid-board-view-config.model';
import { XpoGridBoardViewTemplate } from './grid-board-view-template.model';
export class XpoGridBoardViewUtils {
  /**
   * Helper method to assist with the generation of the columns
   */
  static generateVisibleColumns(
    template: XpoGridBoardViewTemplate,
    view: XpoGridBoardViewConfig
  ): XpoGridBoardColumn[] {
    // Container of the updated columns, combining the current template and the saved column's properties in the view
    const updatedColumnsDef: XpoGridBoardColumn[] = [];

    // There are some columns in the template which doesn't contain identifier like checkboxes, indexes, actions.
    // So we first iterate the current template to push those columns in the current position in the new
    // updatedColumnsDef. We iterate the template.availableColumns until we find some column with an identifier.
    // Then we break and start to match the saved column in the view with the current template.

    // Using here .every because iterates array until false is returned
    template.availableColumns.every((column) => {
      return !XpoGridBoardViewUtils.hasSomeColumnIdentifier(column) ? updatedColumnsDef.push(column) : false;
    });

    // Map the saved view colDef updating in the template the properties we want to keep
    view.visibleColumns.forEach((viewColumnDef: XpoGridBoardVisibleColumn) => {
      // Using the saved column in the view, get the column definition from the current template definition
      const templateColumnDef: XpoGridBoardVisibleColumn = XpoGridBoardViewUtils.getColDefByPrimaryIdentifier(
        template.availableColumns,
        viewColumnDef
      );
      // Create an updated column def with the merge of the template definition and the current column state in the view
      const updatedColumn: XpoGridBoardVisibleColumn = XpoGridBoardViewUtils.getMergeColumnDef(
        templateColumnDef,
        viewColumnDef
      );
      // Push updateColumn to the array of currentColumnsDef
      if (updatedColumn) {
        updatedColumnsDef.push(updatedColumn);
      }
    });

    // Once the iteration of saved column in the view ends we iterate from the back the template looking for columns
    // without identified to add them at the end of the updatedColumnsDef.

    // Using .slice to create a shallow copy. Using .reverse to start the iteration from the back.
    // Using .every to iterate the array until a false is returned
    template.availableColumns
      .slice()
      .reverse()
      .every((column) => {
        return !XpoGridBoardViewUtils.hasSomeColumnIdentifier(column) ? updatedColumnsDef.push(column) : false;
      });

    return updatedColumnsDef;
  }

  /**
   * Helper method to assist with determining if columns were updated
   */
  static wereColumnsUpdated(state: XpoGridBoardState<XpoGridBoardColumn>, template: XpoGridBoardViewTemplate): boolean {
    if (!state || !state.visibleColumns || !template.availableColumns) {
      return false;
    }

    // Removes all other params that might be on the column object so we can just compare if the
    // field and header names have changed.
    const toNormalizedColumn = (column: XpoGridBoardColumn): XpoGridBoardColumn => ({
      headerName: column.headerName,
      field: column.field,
      width: column.width,
      colId: column.colId,
      pinned: column.pinned,
      children: column.children,
      groupId: column.groupId,
    });

    const visibleColumns = state.visibleColumns.map(toNormalizedColumn);
    const availableColumns = template.availableColumns.map(toNormalizedColumn);

    return (
      visibleColumns.length !== availableColumns.length ||
      JSON.stringify(visibleColumns) !== JSON.stringify(availableColumns)
    );
  }

  /**
   * Merge the template column definition with the saved properties of the view
   * @param templateColDef Col defined in the view
   * @param savedColDef Col saved in the view with the properties we saved
   * @returns The complete column definition from the template with the properties as saved in the view
   */
  static getMergeColumnDef(
    templateColDef: XpoGridBoardVisibleColumn,
    savedColDef: XpoGridBoardVisibleColumn
  ): XpoGridBoardVisibleColumn {
    let updatedColumn: XpoGridBoardVisibleColumn;
    if (templateColDef) {
      // Merge the template column definition with the state of properties we want to keep
      // Using _get from lodash because allows you to return the original value (third parameters) if the searched is undefined
      updatedColumn = {
        ...templateColDef,
        width: _get(savedColDef, 'width', templateColDef.width),
        pinned: _get(savedColDef, 'pinned', templateColDef.pinned),
        colId: _get(savedColDef, 'colId', templateColDef.colId),
        groupId: _get(savedColDef, 'groupId', templateColDef.groupId),
        headerName: _get(savedColDef, 'headerName', templateColDef.headerName),
        field: _get(savedColDef, 'field', templateColDef.field),
      };

      // Check if the currentColDef has children (because the original definition could has them but the current has them hidden)
      if (savedColDef && savedColDef.children) {
        updatedColumn.children = XpoGridBoardViewUtils.getMergeChildrenColDef(templateColDef, savedColDef);
      }
    }
    return updatedColumn;
  }

  /**
   * Update the children col definition of a parent column against the saved children in the view
   * @param templateColDef The col as defined in the template
   * @param parentSavedColDef The parent column saved in the view
   * @returns Returns a parent column with its children updated as they were saved in the view
   */
  static getMergeChildrenColDef(
    templateColDef: XpoGridBoardVisibleColumn,
    parentSavedColDef: XpoGridBoardVisibleColumn
  ): XpoGridBoardVisibleColumn[] {
    // Update the children def with the state saved in the view
    const updatedChildren: XpoGridBoardVisibleColumn[] = [];
    // If some one of the children hasn't an identifier, replace all the saved children for the ones on the template
    if (parentSavedColDef.children.some((childCol) => !XpoGridBoardViewUtils.hasSomeColumnIdentifier(childCol))) {
      templateColDef.children.forEach((child) => {
        updatedChildren.push(child);
      });
    } else {
      // Update the children declared on the template with the saved properties of the view
      // Iterate the currentColDef children to be sure iterate just the visible ones
      parentSavedColDef.children.forEach((viewChildColDef: XpoGridBoardVisibleColumn) => {
        const originalChildColDef = XpoGridBoardViewUtils.getColDefByPrimaryIdentifier(
          templateColDef.children,
          viewChildColDef
        );
        // Set all the original child definitions replacing the ones changed in the current state
        // This is necessary to get the col as defined in the template before the compilation. Some properties are missed during the execution.
        // For that reason we can't just let the currentChildColDef without merging the original def
        updatedChildren.push({
          ...originalChildColDef,
          ...viewChildColDef,
        });
      });
      // NGXLTL-1147: regarding an edge case where some child is the owner of a row group definition we
      // must persist it as child even if it's hide
      templateColDef.children.forEach((originalChildColDef: XpoGridBoardVisibleColumn) => {
        if (originalChildColDef.rowGroup) {
          // Check if it was already defined as an updatedChildren
          if (!XpoGridBoardViewUtils.getColDefByPrimaryIdentifier(updatedChildren, originalChildColDef)) {
            updatedChildren.push(originalChildColDef);
          }
        }
      });
    }
    return updatedChildren;
  }

  /**
   * Get a column definition from a container using from the more specific identifier to the lesser specific.
   * @param columnsContainer The container of the columns.
   * @param searchedColumn The searched column in the container
   * @returns Returns the column reference from the container. If the column isn't find it returns the current searched column
   */
  static getColDefByPrimaryIdentifier(
    columnsContainer: any,
    searchedColumn: XpoGridBoardVisibleColumn
  ): XpoGridBoardVisibleColumn {
    const columnFound = columnsContainer.find(
      (c: XpoGridBoardVisibleColumn) =>
        (c.colId && c.colId === _get(searchedColumn, 'colId', searchedColumn)) ||
        (c.headerName && c.headerName === _get(searchedColumn, 'headerName', searchedColumn)) ||
        (c.field && c.field === _get(searchedColumn, 'field', searchedColumn))
    );
    // Using this return separated since you will get a compile time error returning lambdas on static classes
    return columnFound;
  }

  /**
   * This method updates the saved columns of the view considering the keys defined in the current template
   * @param template current view template defined in the project component
   * @param currentColumns columns saved in the view
   * @returns Returns the columns saved in the view updating keys as are now in the template
   */
  static oldViewsAdapter(
    template: XpoGridBoardViewTemplate,
    currentColumns: XpoGridBoardColumn[]
  ): XpoGridBoardColumn[] {
    // Map the original colDef updating the properties we want to keep as currently are
    currentColumns.forEach((currentColumnDef: XpoGridBoardVisibleColumn) => {
      // Get the original column definition from the template definition
      const originalColumnDef: XpoGridBoardVisibleColumn = XpoGridBoardViewUtils.getColDefByPrimaryIdentifier(
        template.availableColumns,
        currentColumnDef
      );

      // Update column def in the reference with the merge of the original and the current column state
      currentColumnDef = XpoGridBoardViewUtils.getMergeColumnDef(originalColumnDef, currentColumnDef);
    });

    return currentColumns;
  }

  static hasSomeColumnIdentifier(col: XpoGridBoardVisibleColumn): boolean {
    return col.colId?.length > 0 || col.headerName?.length > 0 || col.field?.length > 0;
  }
}
