import {ShipmentItemTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentItemTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentItemTypeCd ) {
      this._values.push(ShipmentItemTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentItemTypeCd {

    for ( const obj in ShipmentItemTypeCd ) {
      if (ShipmentItemTypeCd[obj] === value){
        return ShipmentItemTypeCd[obj] as ShipmentItemTypeCd;
      }
    }
  }
}

const ShipmentItemTypeCdHelper = new ShipmentItemTypeCdHelperEnumHelperClass();
export default ShipmentItemTypeCdHelper;
