import { Directive } from '@angular/core';

// Chips
@Directive({
  selector: '[xpo-badge-chip], [xpoBadgeChip] ',
  host: {
    class: 'xpo-BadgeChip',
  },
})
export class XpoBadgeChip {}

@Directive({
  selector: '[xpo-badge-chip-negative], [xpoBadgeChipNegative] ',
  host: {
    class: 'xpo-BadgeChip xpo-BadgeChip--negative',
  },
})
export class XpoBadgeChipNegative {}

@Directive({
  selector: '[xpo-badge-chip-positive], [xpoBadgeChipPositive] ',
  host: {
    class: 'xpo-BadgeChip xpo-BadgeChip--positive',
  },
})
export class XpoBadgeChipPositive {}
