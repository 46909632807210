import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenuTrigger, _MatMenu } from '@angular/material/menu';

@Directive({
  selector: 'xpo-board-toolbar-actions-group-more',
  host: {
    'data-cy': 'action-buttons-group-more',
  },
})
export class XpoBoardGridToolbarActionsMore {}
@Component({
  selector: 'xpo-board-toolbar-dynamic-more',
  templateUrl: 'board-grid-toolbar-dynamic-more.component.html',
  styleUrls: ['board-grid-toolbar-dynamic-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class XpoBoardGridToolbarDynamicMore implements OnChanges {
  @Input() dynButtons: HTMLFieldSetElement[];
  @ViewChild('menuTrigger', { static: false }) menuTrigger: MatMenuTrigger;

  /*
   * This ngOnChanges is being dispatched by the "@Input() dynButton" when the screen is
   * resized or when the dropdown is expanded
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.menuTrigger) {
      this.menuTrigger.closeMenu();
    }
  }
}
