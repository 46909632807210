import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ContextualHeaderActions } from '../contextual-header-config';

@Component({
  selector: 'xpo-contextual-header-actions',
  templateUrl: './contextual-header-actions.component.html',
  styleUrls: ['./contextual-header-actions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-HeaderActions',
  },
})
export class ContextualHeaderActionsComponent {
  @Input() config: ContextualHeaderActions;
}
