import { Directive, ElementRef, HostListener } from '@angular/core';

import { ClosedEmitterTypes } from './enums/closed-emitter-types.enum';
import { XpoPopover } from './popover.component';

@Directive({
  selector: '[xpo-popover-item]',
  host: { class: 'xpo-Popover-item' },
})
export class XpoPopoverItem {
  constructor(private parentPopover: XpoPopover, private el: ElementRef) {}

  // close popover on select, if the popover-item is button
  @HostListener('click')
  handleElementClicked(): void {
    if (this.el.nativeElement.nodeName === 'BUTTON' && this.parentPopover) {
      this.parentPopover.closed.emit(ClosedEmitterTypes.Click);
    }
  }
}
