import { NgModule } from '@angular/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { rippleConfig } from './ripple/ripple-config';
import { tabsConfig } from './tabs/index';

@NgModule({
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: rippleConfig },
    { provide: MAT_TABS_CONFIG, useValue: tabsConfig },
  ],
})
export class XpoCommonModule {}
