import { Component, EventEmitter, Output } from '@angular/core';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import {
  CustomFilterParams,
  filterFormats,
  Operators,
  OperatorText,
  XpoFilterConditionCriteria,
} from './../../../../../models/index';

@Component({
  templateUrl: './ends-with.component.html',
  styleUrls: ['../../validator.scss'],
})
export class XpoConditionStringEndsWithComponent extends XpoConditionBase implements XpoFilterCondition {
  static text = OperatorText.EndsWith;
  static operator: Operators = Operators.EndsWith;
  isNegative = false;
  model: string = '';
  private isCaseSensitive = false;

  set customFilterParams(params: CustomFilterParams) {
    this.isCaseSensitive = params.isCaseSensitive || false;
  }

  @Output() inputChanged = new EventEmitter();

  constructor() {
    super(filterFormats.default);
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    if (!cellValue) {
      return false;
    }

    if (this.isCaseSensitive) {
      return cellValue.toString().endsWith(this.model);
    } else {
      return cellValue
        .toString()
        .toLowerCase()
        .endsWith(this.model.toLowerCase());
    }
  }

  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.EndsWith,
      value: this.model,
    };
  }
  onInputChange(): any {
    this.validateFormat();
    this.inputChanged.emit(this.model);
  }
}
