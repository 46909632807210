import {EslCd} from '../';
import {EnumHelper} from './enum-helper';

export class EslCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EslCd ) {
      this._values.push(EslCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EslCd {

    for ( const obj in EslCd ) {
      if (EslCd[obj] === value){
        return EslCd[obj] as EslCd;
      }
    }
  }
}

const EslCdHelper = new EslCdHelperEnumHelperClass();
export default EslCdHelper;
