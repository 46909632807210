import { XpoAgGridBoardColumn } from '@xpo-ltl/ngx-ltl-board-grid';

export const MODULE_GROUP_MGMT_EXECUTION_STEP_COLUMNS: XpoAgGridBoardColumn[] = [
  {
    headerName: '#',
    field: 'execOrderSequenceNbr',
    lockPosition: true,
    minWidth: 30,
    maxWidth: 50,
  },
  {
    headerName: '',
    field: 'drag',
    rowDrag: true,
    minWidth: 30,
    maxWidth: 30,
    lockPosition: true,
  },
  {
    headerName: 'Actions',
    field: 'actions',
    minWidth: 80,
    maxWidth: 80,
    cellRenderer: 'ActionsCellRenderer',
    lockPosition: true,
  },
  {
    headerName: 'Module Name',
    field: 'parentModuleName',
    flex: 1,
    showRowGroup: true,
    resizable: true,
    filter: 'agTextColumnFilter',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      suppressCount: true,
      checkbox: false,
      innerRenderer: 'NameCellRenderer',
      suppressDoubleClickExpand: true,
      suppressEnterExpand: true,
    },
  },
  {
    headerName: 'Version',
    flex: 1,
    resizable: true,
    field: 'parentVersion',
    filter: 'agTextColumnFilter',
  },
];
