import { Directive, OnInit } from '@angular/core';
import { MatSelect } from '@angular/material/select';

/**
 * Disabling material's shifting of the select option panel when selecting a value.
 */
@Directive({
  selector: 'mat-select',
})
export class XpoDisableOptionCenteringDirective implements OnInit {
  private panelClasses = ['xpo-Select-panel', 'xpo-Select-panelMultiple'];
  private existingPanelClasses = '';

  constructor(private select: MatSelect) {}

  ngOnInit(): void {
    this.select.disableOptionCentering = true;

    // Getting the existing panel class or classes and transforming
    // them into a class string. This will be added to our
    // custom panel classes
    if (this.select.panelClass) {
      this.existingPanelClasses = Array.isArray(this.select.panelClass)
        ? ` ${this.select.panelClass.join(' ')}`
        : ` ${this.select.panelClass.toString()}`;
    }

    // Setting custom classes to facilitate style overriding.
    // We are keeping all classes added by the projects
    this.select.panelClass = `${this.select.multiple ? this.panelClasses.join(' ') : this.panelClasses[0]} ${
      this.existingPanelClasses
    }`;
  }
}
