import {RuleOperatorCd} from '../';
import {EnumHelper} from './enum-helper';

export class RuleOperatorCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RuleOperatorCd ) {
      this._values.push(RuleOperatorCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RuleOperatorCd {

    for ( const obj in RuleOperatorCd ) {
      if (RuleOperatorCd[obj] === value){
        return RuleOperatorCd[obj] as RuleOperatorCd;
      }
    }
  }
}

const RuleOperatorCdHelper = new RuleOperatorCdHelperEnumHelperClass();
export default RuleOperatorCdHelper;
