import { Component, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import { xpoDateFormatter } from '../../../../../../formatters';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { IsBetweenModel } from '../../model.interface';
import { ordersMinMaxDate } from '../date.util';
import { filterFormats, Operators, OperatorText, XpoFilterConditionCriteria } from './../../../../../models';

@Component({
  templateUrl: './not-between.component.html',
  styleUrls: ['./not-between.component.scss'],
})
export class XpoConditionDateNotBetweenComponent extends XpoConditionBase implements XpoFilterCondition {
  static text: string = OperatorText.NotBetween;
  static operator = Operators.NotBetween;

  isNegative = false;
  model: IsBetweenModel = {
    from: '',
    to: '',
  };

  @Output() inputChanged = new EventEmitter();

  constructor() {
    super(filterFormats.stringRange);
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    if (!cellValue) {
      return false;
    }

    const ordered = ordersMinMaxDate(this.model.from, this.model.to);

    return !moment(cellValue).startOf('day').isBetween(
      moment(ordered.from).startOf('day'),
      moment(ordered.to).endOf('day'),
      null,
      '[]' // This signifies that the extent is exclusive
    );
  }

  getCriteria(): XpoFilterConditionCriteria {
    const ordered = ordersMinMaxDate(this.model.from, this.model.to);
    return {
      operator: Operators.NotBetween,
      value: ordered.from,
      valueTo: ordered.to,
      display: xpoDateFormatter({ value: ordered.from }),
      displayTo: xpoDateFormatter({ value: ordered.to }),
    };
  }

  onInputChange(): any {
    this.validateFormat();
    this.inputChanged.emit(this.model);
  }
}
