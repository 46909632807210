import { Pipe, PipeTransform } from '@angular/core';
import { upperFirst as _upperFirst } from 'lodash';

@Pipe({
  name: 'sentenceCase',
})
export class SentenceCasePipe implements PipeTransform {
  private sentenceCaseRegex = new RegExp('(?<=\\.|!|\\?)[\\s]+', 'gm');

  transform(value: string, args?: any): any {
    return value ? this.toSentenceCase(value) : '';
  }

  private toSentenceCase(value) {
    return value
      .toLowerCase()
      .split(this.sentenceCaseRegex)
      .map((str) => _upperFirst(str))
      .join(' ');
  }
}
