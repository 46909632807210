import {OptimizerParameterTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class OptimizerParameterTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in OptimizerParameterTypeCd ) {
      this._values.push(OptimizerParameterTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): OptimizerParameterTypeCd {

    for ( const obj in OptimizerParameterTypeCd ) {
      if (OptimizerParameterTypeCd[obj] === value){
        return OptimizerParameterTypeCd[obj] as OptimizerParameterTypeCd;
      }
    }
  }
}

const OptimizerParameterTypeCdHelper = new OptimizerParameterTypeCdHelperEnumHelperClass();
export default OptimizerParameterTypeCdHelper;
