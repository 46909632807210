import { Component } from '@angular/core';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { filterFormats, Operators, OperatorText, XpoFilterConditionCriteria } from './../../../../../models/index';

@Component({ template: ' ' })
export class XpoConditionEmptyComponent extends XpoConditionBase implements XpoFilterCondition {
  static text = OperatorText.Empty;
  static applyAlwaysEnabled: boolean = true;
  static operator = Operators.Empty;

  isNegative = false;
  model: null = null;

  constructor() {
    super(filterFormats.default, false);
  }

  validate(cellValue): boolean {
    if (cellValue !== undefined) {
      return !cellValue.toString().trim();
    }
    return true;
  }

  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.Empty,
      value: '',
    };
  }
}
