import {ConfigurationValueTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ConfigurationValueTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ConfigurationValueTypeCd ) {
      this._values.push(ConfigurationValueTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ConfigurationValueTypeCd {

    for ( const obj in ConfigurationValueTypeCd ) {
      if (ConfigurationValueTypeCd[obj] === value){
        return ConfigurationValueTypeCd[obj] as ConfigurationValueTypeCd;
      }
    }
  }
}

const ConfigurationValueTypeCdHelper = new ConfigurationValueTypeCdHelperEnumHelperClass();
export default ConfigurationValueTypeCdHelper;
