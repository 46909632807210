import { Type } from '@angular/core';
import { DateUtils } from '@xpo-ltl/ngx-ltl-core';
import { Observable, of as observableOf } from 'rxjs';

import { XpoDateFilterConfig } from './date-filter-config';
import { XpoFilter } from './filter';
import { XpoFilterComponent } from './filter-component';

export class XpoDateFilterBase extends XpoFilter {
  readonly dateFilterConfig$: Observable<XpoDateFilterConfig>;

  constructor(
    field: string,
    label: string,
    filterComponentType: Type<XpoFilterComponent>,
    dateFilterConfigValue?: XpoDateFilterConfig | Observable<XpoDateFilterConfig>
  ) {
    super(field, label, filterComponentType);

    this.dateFilterConfig$ =
      dateFilterConfigValue instanceof Observable ? dateFilterConfigValue : observableOf(dateFilterConfigValue);
  }

  getDisplayValue(val: any): string {
    if (!val) {
      return 'Any';
    }

    return DateUtils.formatDate(val instanceof Date ? val : new Date(val));
  }
}
