import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';

/* Material */
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

/* XPO */
import {
  XpoButtonModule,
  XpoCardModule,
  XpoCommonModule,
  XpoIconModule,
  XpoSelectModule,
  XpoStatusIndicatorModule,
} from '@xpo-ltl/ngx-ltl-core';

/* Components */
import { ModuleGroupMgmtInfoComponent } from './module-group-mgmt-info.component';

@NgModule({
  declarations: [ModuleGroupMgmtInfoComponent],
  imports: [
    CommonModule,
    XpoCommonModule,
    XpoCardModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    MatSelectModule,
    XpoIconModule,
    XpoSelectModule,
    XpoButtonModule,
    XpoStatusIndicatorModule,
  ],
  exports: [ModuleGroupMgmtInfoComponent],
})
export class ModuleGroupMgmtInfoModule {}
