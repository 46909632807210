import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { MatSpinner } from '@angular/material/progress-spinner';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { XpoDownloadButtonIcon } from './download-button-icon/index';

// TODO: Rename this to XpoDownloadButton when deprecated download button is removed
@Directive({
  selector: 'button[xpoDownloadButton]',
  host: { class: 'xpo-DownloadButton' },
})
export class XpoDownloadButtonDirective implements AfterViewInit {
  private progressElement: any;
  private iconElement: ComponentRef<XpoDownloadButtonIcon>;
  private displayDefaultIcon: boolean = false;
  private displayStyleBeforeHide: string;
  private replaceContentValue: boolean;

  constructor(
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2,
    private zone: NgZone
  ) {}

  @Input()
  handleDownload: () => Observable<any>;

  @Input()
  fileName: string;

  @Input()
  get replaceContentWithLoader(): boolean {
    return this.replaceContentValue;
  }
  set replaceContentWithLoader(value: boolean) {
    this.replaceContentValue = coerceBooleanProperty(value);
  }

  @HostListener('click')
  handleClick(): void {
    this.download();
  }

  ngAfterViewInit(): void {
    this.displayDefaultIcon =
      // display if we don't have a direct text descendant
      !this.el.nativeElement.textContent.length &&
      // and if our first child is without content
      this.el.nativeElement.children.length &&
      !this.el.nativeElement.children[0].innerHTML.length &&
      // and if the user hasn't explicitly informed us how to handle provided content
      !this.replaceContentWithLoader;

    // Avoid ExpressionChangedAfterItHasBeenCheckedError
    this.zone.onStable.pipe(take(1)).subscribe(() => this.showIcon());
  }

  download(): void {
    if (!this.handleDownload) {
      console.error('XpoDownloadButtonDirective: you must provide handleDownload input value in order to download.');
      return;
    }

    const fileObservable = this.handleDownload();
    if (!fileObservable) {
      console.warn(
        'XpoDownloadButtonDirective: no result was provided by handleDownload. Download will not be perfomed.'
      );
      return;
    }

    this.showLoader();
    this.hideIcon();

    fileObservable
      .pipe(
        finalize(() => {
          this.hideLoader();
          this.showIcon();
        }),
        take(1)
      )
      .subscribe(
        (data: File) => {
          saveAs(data, this.fileName || data.name);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  showLoader(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<MatSpinner>(MatSpinner);
    const matSpinner = this.viewContainerRef.createComponent(componentFactory);

    matSpinner.instance.diameter = 24;

    this.progressElement = matSpinner.injector.get(MatSpinner)._elementRef.nativeElement;
    this.renderer.appendChild(this.el.nativeElement, this.progressElement);

    if (this.replaceContentWithLoader) {
      this.displayStyleBeforeHide = this.el.nativeElement.firstChild.style.display;
      this.renderer.setStyle(this.el.nativeElement.firstChild, 'display', 'none');
    }

    this.viewContainerRef.element.nativeElement.disabled = true;
  }

  private hideLoader(): void {
    if (this.replaceContentWithLoader) {
      const displayStyleValue = this.displayStyleBeforeHide ? this.displayStyleBeforeHide : 'inline';
      this.renderer.setStyle(this.el.nativeElement.firstChild, 'display', displayStyleValue);
    }

    this.viewContainerRef.element.nativeElement.disabled = false;
    this.renderer.removeChild(this.el.nativeElement, this.progressElement);
  }

  showIcon(): void {
    if (!this.displayDefaultIcon) {
      return;
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(XpoDownloadButtonIcon);
    this.iconElement = this.viewContainerRef.createComponent<XpoDownloadButtonIcon>(componentFactory);
    this.el.nativeElement.appendChild(this.iconElement.location.nativeElement);

    this.iconElement.instance.update();
  }

  hideIcon(): void {
    if (!this.displayDefaultIcon) {
      return;
    }

    this.renderer.removeChild(this.el.nativeElement, this.iconElement.location.nativeElement);
  }
}
