import { ReplaySubject, Observable } from 'rxjs';
import { XrtChangedDocuments } from './changed-document.model';

export class XrtHubSettings {
  hubUrl: string;
  hubLogLevel: SignalRLogLevel;
  maxAttempts?: number;
  reconnectTimeout?: number;
  registerUri: string;
  requestType: string;
}

export interface XrtRegisterRequest<T = any> {
  filter: T;
}

export interface XrtRegisterResponse {
  filterHash: string;
}

export class XrtConnectResponse {
  filterHash: string;
  started: boolean;
  joined: boolean;
  dataProviderSubject$: ReplaySubject<XrtChangedDocuments>;
  get dataProvider(): Observable<XrtChangedDocuments> {
    return this.dataProviderSubject$.asObservable();
  }
}

export enum SignalRLogLevel {
  Trace = 0,
  Debug = 1,
  Information = 2,
  Warning = 3,
  Error = 4,
  Critical = 5,
  None = 6,
}
