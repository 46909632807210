import {CisRequestStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class CisRequestStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CisRequestStatusCd ) {
      this._values.push(CisRequestStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CisRequestStatusCd {

    for ( const obj in CisRequestStatusCd ) {
      if (CisRequestStatusCd[obj] === value){
        return CisRequestStatusCd[obj] as CisRequestStatusCd;
      }
    }
  }
}

const CisRequestStatusCdHelper = new CisRequestStatusCdHelperEnumHelperClass();
export default CisRequestStatusCdHelper;
