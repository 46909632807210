
export enum DisputeFilingMethodCd {
	EMAIL = 'Email',
	FAX = 'Fax',
	INTERNAL_REQUEST = 'InternalRequest',
	MAIL = 'Mail',
	NOT_APPLICABLE = 'NotApplicable',
	OTHER = 'Other',
	WEB = 'Web'}

