import { ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { XpoFilterParams, XpoInlineFilter } from '../models/index';
import { XpoTextInputFilterComponent } from './text-input-filter.component';

export interface XpoTextInputFilterParams extends XpoFilterParams {
  validators?: ValidatorFn[];
  title?: string;
  inputDebounceTime?: number;
  matcher?: ErrorStateMatcher;
}

export class XpoTextInputFilter extends XpoInlineFilter {
  static readonly DefaultInputDebounceTime = 500;

  validators: ValidatorFn[];
  title: string;
  inputDebounceTime: number;
  matcher: ErrorStateMatcher;

  // Will deprecate constructor(field, name) in angular 9, needed to make both constructors have the same amount of
  // arguments to avoid compile time errors, tried to do ...args but it did not work.
  constructor(field: string | any, name?: string);
  constructor(params: XpoTextInputFilterParams | any, noop?: string) {
    super(params.field || params, params.label || noop, XpoTextInputFilterComponent);

    if (noop) {
      /**
       * target @v9.0.0
       */
      console.warn(
        'WARN: XpoTextInputFilter(field, value) is deprecated, please use XpoTextInputFilter({field: field, value: value})'
      );
    } else {
      this.title = params.title || '';
      this.validators = params.validators || null;
      this.inputDebounceTime = params.inputDebounceTime || XpoTextInputFilter.DefaultInputDebounceTime;
      this.matcher = params.matcher || null;
    }
  }

  getDisplayValue(val: any): any {
    return val;
  }
}
