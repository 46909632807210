import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Observer } from 'rxjs';

export interface XpoConfirmDialogData {
  message: string;
  title?: string;
  confirmConfig?: XpoConfirmDialogConfig;
}

export interface XpoConfirmDialogConfig {
  confirmButtonText?: string;
  confirmButtonColor?: ThemePalette;
  rejectButtonText?: string;
  rejectButtonColor?: ThemePalette;
  icon?: string;
  showCancelButton?: boolean;
  width?: string;
}

@Component({
  selector: 'xpo-confirm-dialog-window',
  templateUrl: './confirm-dialog.html',
  styleUrls: ['./confirm-dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-ConfirmDialog',
  },
})
export class XpoConfirmDialogWindow {
  config: XpoConfirmDialogConfig = {};
  title: string;

  constructor(
    public dialogRef: MatDialogRef<XpoConfirmDialogWindow>,
    @Inject(MAT_DIALOG_DATA) public data: XpoConfirmDialogData
  ) {
    this.config.confirmButtonText = data.confirmConfig.confirmButtonText || 'Confirm';
    this.config.confirmButtonColor = data.confirmConfig.confirmButtonColor || 'primary';
    this.config.rejectButtonText = data.confirmConfig.rejectButtonText || 'Cancel';
    this.config.rejectButtonColor = data.confirmConfig.rejectButtonColor || 'accent';
    this.config.showCancelButton = false;
  }

  confirmAction(): void {
    this.dialogRef.close(true);
  }

  cancelAction(): void {
    this.dialogRef.close(false);
  }
}

@Component({
  selector: 'xpo-confirm-dialog',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class XpoConfirmDialog {
  constructor(public dialog: MatDialog) {}

  // TODO: Change the order of the parameters, decide when is the best time to do it,
  // since this will be a breaking change
  confirm(
    message: string,
    title: string = 'Confirmation',
    confirmConfig: XpoConfirmDialogConfig = {}
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(XpoConfirmDialogWindow, {
      width: confirmConfig.width || '600px', // Default width provided by UX design
      disableClose: true,
      data: { message: message, title: title, confirmConfig: confirmConfig },
    });

    return new Observable<boolean>((observer: Observer<boolean>) => {
      dialogRef.afterClosed().subscribe((result) => {
        observer.next(result);
        observer.complete();
      });
    });
  }
}
