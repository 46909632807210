import {ShipmentServiceTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentServiceTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentServiceTypeCd ) {
      this._values.push(ShipmentServiceTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentServiceTypeCd {

    for ( const obj in ShipmentServiceTypeCd ) {
      if (ShipmentServiceTypeCd[obj] === value){
        return ShipmentServiceTypeCd[obj] as ShipmentServiceTypeCd;
      }
    }
  }
}

const ShipmentServiceTypeCdHelper = new ShipmentServiceTypeCdHelperEnumHelperClass();
export default ShipmentServiceTypeCdHelper;
