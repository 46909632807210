import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { XpoVerticalTab } from './../tab/vertical-tab.component';

@Component({
  selector: 'xpo-vertical-tab-group',
  templateUrl: './vertical-tab-group.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'xpo-VerticalTabGroup',
    '[class.xpo-VerticalTabGroup--is-active]': 'childLinkActive',
  },
})
export class XpoVerticalTabGroup implements AfterContentInit, OnDestroy {
  /** Whether or not a child link is active */
  showChildLinks = false;
  /** Whether or not a child link is active */
  childLinkActive = false;

  /** The tracker for the group's subscriptions */
  private destroyed$ = new Subject<void>();

  /** The title of the "group" */
  @Input() title: string;

  /** Child Links */
  @ContentChildren(XpoVerticalTab)
  links: QueryList<XpoVerticalTab>;

  /** Show the "active" bar if a child is active while this is closed */
  get childActiveWhileGroupClosed(): boolean {
    return !this.showChildLinks && this.childLinkActive;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    // Check if any child link is active to initialize group active state
    this.childLinkActive = this.links.some((l) => l.isActive);
    if (this.childLinkActive) {
      this.openSubLinkView();
    }

    // Subscribe to changes in child links active state to update group active state
    // TODO: Maybe subscribe to the content children's changes event to resubscribe if new links appear
    combineLatest([...this.links.map((link) => link.activeChange$)])
      .pipe(
        debounceTime(100),
        takeUntil(this.destroyed$)
      )
      .subscribe((isActiveCollection) => {
        this.childLinkActive = isActiveCollection.some((v) => !!v);

        if (this.childLinkActive) {
          this.openSubLinkView();
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /** Toggles the child links */
  toggleChildLinks(): void {
    if (!this.showChildLinks) {
      this.openSubLinkView();
    } else {
      this.closeSubLinkView();
    }
  }

  /** Opens the child links */
  private openSubLinkView(): void {
    this.showChildLinks = true;
  }

  /** Closes the child links */
  private closeSubLinkView(): void {
    this.showChildLinks = false;
  }
}
