import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ErrorMessageParser } from '@xpo-ltl/data-api';
import { FeedbackConfigInterface, XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConfigManagerProperties } from '../../config-manager-properties.enum';
import { XpoLtlEmailService } from '../email-service/email.service';
import { XpoLtlLoggedInUserService } from '../logged-in-user/logged-in-user.service';

@Injectable({ providedIn: 'root' })
export class XpoLtlFeedbackService {
  private feedbackConfigBehaviorSubject = new BehaviorSubject<FeedbackConfigInterface>(undefined);
  feedbackConfig$ = this.feedbackConfigBehaviorSubject.asObservable();

  constructor(
    private loggedInUserService: XpoLtlLoggedInUserService,
    private configManagerService: ConfigManagerService,
    private emailService: XpoLtlEmailService,
    private snackBar: XpoSnackBar
  ) {
    const generateFeedbackConfig = (user): FeedbackConfigInterface => {
      return {
        disableFromNameInput: true,
        disableSubjectInput: true,
        subject: this.configManagerService.getSetting(ConfigManagerProperties.feedbackSubject),
        fromEmail: user.emailAddress,
        fromName: `${user.givenName || user.displayName} ${user.lastName}`,
        onSendCallback: (message): Observable<boolean> => {
          return this.sendEmail(message);
        },
      };
    };

    this.loggedInUserService
      .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .subscribe((user) => {
        if (user) {
          this.feedbackConfigBehaviorSubject.next(generateFeedbackConfig(user));
        }
      });
  }

  private sendEmail(message): Observable<boolean> {
    const subject = new Subject<boolean>();

    this.emailService
      .sendEmail(
        this.feedbackConfigBehaviorSubject.value.fromName,
        this.feedbackConfigBehaviorSubject.value.fromEmail,
        this.configManagerService.getSetting(ConfigManagerProperties.feedbackToAddress),
        this.configManagerService.getSetting(ConfigManagerProperties.feedbackSubject),
        `${message} - ${this.configManagerService.getSetting(ConfigManagerProperties.appName)} - ${
          this.feedbackConfigBehaviorSubject.value.fromName
        }`
      )
      .subscribe(
        () => {
          this.snackBar.success('Feedback sent.');
          subject.next(true);
          subject.complete();
        },
        (error) => {
          this.snackBar.error(`${ErrorMessageParser.parseMessage(error) || error}.`);
          subject.next(false);
          subject.complete();
        }
      );

    return subject;
  }
}
