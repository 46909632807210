
export enum InvoiceModeCd {
	EDI = 'EDI',
	EDI_LEGACY = 'EDILegacy',
	EDI_TEST = 'EDITest',
	EDI_TRANSFORMATION = 'EDITransformation',
	EMAIL = 'Email',
	FAX = 'Fax',
	FTP = 'FTP',
	PAPER = 'PAPER',
	SELF_PAID = 'SelfPaid'}

