import {LocationDetailCd} from '../';
import {EnumHelper} from './enum-helper';

export class LocationDetailCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LocationDetailCd ) {
      this._values.push(LocationDetailCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LocationDetailCd {

    for ( const obj in LocationDetailCd ) {
      if (LocationDetailCd[obj] === value){
        return LocationDetailCd[obj] as LocationDetailCd;
      }
    }
  }
}

const LocationDetailCdHelper = new LocationDetailCdHelperEnumHelperClass();
export default LocationDetailCdHelper;
