import {LogLevel} from '../';
import {EnumHelper} from './enum-helper';

export class LogLevelHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LogLevel ) {
      this._values.push(LogLevel[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LogLevel {

    for ( const obj in LogLevel ) {
      if (LogLevel[obj] === value){
        return LogLevel[obj] as LogLevel;
      }
    }
  }
}

const LogLevelHelper = new LogLevelHelperEnumHelperClass();
export default LogLevelHelper;
