import { Component } from '@angular/core';

@Component({
  selector: 'xpo-tray-panel-footer',
  templateUrl: './tray-panel-footer.component.html',
  styleUrls: ['./tray-panel-footer.component.scss'],
  host: {
    class: 'xpo-TrayPanel-footer',
  },
})
export class XpoTrayPanelFooterComponent {}
