import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber',
  pure: false
})
export class PhoneNumberPipe implements PipeTransform {
  /**
   * Formats 10 digit phone number to contain dashes
   * @param number 10 digit phone number
   */
  transform(number: string): string {
    if (!number) {
      return '';
    }
    let nakedPhoneNumber = number.replace(/\D/g, '');
    if (nakedPhoneNumber) {
      if (nakedPhoneNumber.length > 3) {
        nakedPhoneNumber = `${nakedPhoneNumber.slice(
          0,
          3
        )}-${nakedPhoneNumber.slice(3)}`;
      }
      if (nakedPhoneNumber.length > 8) {
        nakedPhoneNumber = `${nakedPhoneNumber.slice(
          0,
          7
        )}-${nakedPhoneNumber.slice(7)}`;
      }
    }
    return nakedPhoneNumber;
  }
}
