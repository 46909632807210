/**
 * Infrastructure API
 * Version: 1.0
 * Build: Manual
 */

import {
	User,
	AuditInfo,
	ElectronicMessage,
	File
} from '@xpo-ltl/sdk-common';

/**
 * Submit either a printer group code or a list of printer codes for validation. Invalid codes will be returned.
 */
export class ValidatePrinterGroupOrPrinterCodesResp {
	/**
	 * If present the printer group code is invalid
	 */
	printerGroupCd:string[] = undefined;
	/**
	 * If any printer codes are present, they are invalid
	 */
	printerCd:string[] = undefined;
}
/**
 * Submit either a printer group code or a list of printer codes for validation. Invalid codes will be returned.
 */
export class ValidatePrinterGroupOrPrinterCodesRqst {
	/**
	 * The printer group code to be validated
	 */
	printerGroupCd:string = undefined;
	/**
	 * The printer code(s) to be validated
	 */
	printerCd:string[] = undefined;
}
/**
 * Add a  printer response.
 */
export class AddPrinterResp {
	/**
	 * The database instance ID of the printer that was added.
	 */
	printerInstId:string[] = undefined;
}
/**
 * Add a printer.The operation will return the instance id when it is created.
 */
export class AddPrinterRqst {
	/**
	 * The code and location of the printer.
	 */
	printer:Printer[] = undefined;
}
/**
 * Attach a printer to a  printer group. There is no return from this operation.
 */
export class AttachPrinterToGroupRqst {
	/**
	 * The instance ID of the printer to attach to the given group.
	 */
	printerInstId:string[] = undefined;
	/**
	 * The instance ID of the group to which to attach the printer.
	 */
	printerGroupInstId:string[] = undefined;
}
/**
 * Delete a  printer group.
 */
export class DeletePrinterGroupRqst {
	/**
	 * The instance ID of the group to be deleted. There is no return for this operation.
	 */
	printerGroupInstId:string[] = undefined;
}
/**
 * Delete printer.
 */
export class DeletePrinterRqst {
	/**
	 * The instance ID of the printer to be deleted. There is no return for this operation.
	 */
	printerInstId:string[] = undefined;
}
/**
 * Returns information about the user who is logged in. It includes information about user name, address, and roles as defined in AD
 */
export class GetLoggedInUserInfoResp {
	userInfo:User = undefined;
}
/**
 * A list of printers attached to a printer group
 */
export class ListAttachedPrintersResp {
	/**
	 * A repeating list of the printers attached to the group
	 */
	printer:Printer[] = undefined;
}
/**
 * Get a list of printers attached to a printer group.
 */
export class ListAttachedPrintersRqst {
	/**
	 * The printer group instance Id.
	 */
	printerGroupInstId:string[] = undefined;
}
/**
 * A list of printer groups starting with the group code if it was provided. Otherwise, it will be all of the group codes.
 */
export class ListPrinterGroupsResp {
	/**
	 * A repeating list of the printer group with information and  without the member printers.
	 */
	printerGroup:PrinterGroup[] = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Get a list of printer groups. If a group code is provide then return a list of printer groups starting from that group code. If no group code provide then return all of the printer group. There is only a few hundred printer groups out there.
 */
export class ListPrinterGroupsRqst {
	/**
	 * The printer group code to be the start of the list if present.
	 */
	printerGroupCd:string[] = undefined;
}
/**
 * Request to return a list of printers where the printer code and SIC code are optional query params.
 */
export class ListPrintersRqst {
	/**
	 * The printer code, which will be the start of the list if present.
	 */
	printerCd:string = undefined;
	/**
	 * The SIC code that will be used to filter the list of printer codes.
	 */
	sicCd:string = undefined;
}
/**
 * A list of printers, starting with the printer code if it was provided, will be returned.  If a SIC code was provided, all printers from that location will be returned. Otherwise, all of the printers will be returned.
 */
export class ListPrintersResp {
	/**
	 * A list of printers and information about them.
	 */
	printer:Printer[] = undefined;
}
/**
 * A list of reference standard codes for a code type is returned.
 */
export class ListRefStandardCodesResp {
	/**
	 * A repeating list of the Ref Standard Codes with information
	 */
	refStandardCodes:RefStandardCode[] = undefined;
}
/**
 * Get a list of reference standard codes for a code type.
 */
export class ListRefStandardCodesRqst {
	/**
	 * A 4 character code type. CTYP is the top level code type. ï¿½Use CTYP to navigate through all other code types.
	 */
	codeType:string = undefined;
}
/**
 * Update printer group information.
 */
export class MaintainRefStandardCodesResp {
	/**
	 * A repeating list of the Ref Standard Codes to be updated, with information
	 */
	refStandardCodes:RefStandardCode[] = undefined;
}
/**
 * Update printer information.
 */
export class MaintainRefStandardCodesRqst {
	/**
	 * A repeating list of the updated Ref Standard Codes with information
	 */
	refStandardCodes:RefStandardCode[] = undefined;
}
/**
 * Remove a printer from a printer group.There is no return for this operation.
 */
export class RemovePrinterFromGroupRqst {
	/**
	 * The printer to remove from the group
	 */
	printerInstId:string[] = undefined;
	/**
	 * The instance ID of the group from which to remove the printer
	 */
	printerGroupInstId:string[] = undefined;
}
/**
 * Update printer group information.
 */
export class UpdatePrinterGroupRqst {
	/**
	 * Information to be updated on the printer group. The instance ID must be present. There is no return for this operation.
	 */
	printerGroupInfo:PrinterGroup[] = undefined;
}
/**
 * Update printer information.
 */
export class UpdatePrinterRqst {
	/**
	 * Information to be updated a printer. The instance ID must be present. There is no return for this operation.
	 */
	printerInfo:Printer[] = undefined;
}
/**
 * Add a  printer group response.
 */
export class AddPrinterGroupResp {
	/**
	 * The database instance ID of the printer group that was added.
	 */
	printerGroupInstId:string[] = undefined;
}
/**
 * Add a  printer group.
 */
export class AddPrinterGroupRqst {
	/**
	 * The group to be added. All fields should be populated except the instance ID which will be returned by this API
	 */
	printerGroup:PrinterGroup[] = undefined;
}
/**
 * An asyncronous report request
 */
export class CreateAsyncReportRqst {
	/**
	 * Unique Name for the report
	 */
	reportName:string = undefined;
	/**
	 * Parameter string to be passed to teh reprort
	 */
	reportParms:string = undefined;
	/**
	 * Person requesting the report
	 */
	requesterId:string = undefined;
}
/**
 * Response to a asynchronous Report Request
 */
export class CreateAsyncReportResp {
	/**
	 * Unique Identifier for the report request created
	 */
	instanceId:string = undefined;
}
/**
 * Request message to send the email notification
 */
export class SendEmailRqst {
	emailMessage:ElectronicMessage = undefined;
	/**
	 * Flag that indicates if the email body has html elements so it will display the message properly.
	 */
	html:boolean = undefined;
}
/**
 * The document and a list of printers on which to print them
 */
export class PrintDocumentRqst {
	document:File[] = undefined;
	documentContents:string[] = undefined;
	/**
	 * The printer code to be the start of the list if present.
	 */
	printerCd:string[] = undefined;
}
/**
 * A named group that associates a set of printers
 */
export class PrinterGroup {
	/**
	 * database key for the group
	 */
	printerGroupInstId:string = undefined;
	/**
	 * The code identifying a particular group of printers
	 */
	printerGroupCd:string = undefined;
	/**
	 * Description of the group
	 */
	printerGroupDesc:string = undefined;
	/**
	 * printers associated with this group
	 */
	printers:Printer[] = undefined;
}
/**
 * A printer code and its location
 */
export class Printer {
	/**
	 * The database key for the printer
	 */
	printerInstId:string = undefined;
	/**
	 * The code identifying a specific printer
	 */
	printerCd:string = undefined;
	/**
	 * Location of the printer
	 */
	printerLocation:string = undefined;
}
/**
 * An object to store ref standard code information
 */
export class RefStandardCode {
	/**
	 * A 4 character code for grouping
	 */
	codeType:string = undefined;
	/**
	 * A value to represent the code.
	 */
	codeValue:string = undefined;
	/**
	 * A short description of the code. This is normally used to display in the drop down or in the print report.
	 */
	shortDescription:string = undefined;
	/**
	 * a longer and more detail description of what the code is.
	 */
	longDescription:string = undefined;
	/**
	 * When the code takes effect. ï¿½Code is not deleted; the effective start date determines when the code is active.
	 */
	effectiveStartDate:Date = undefined;
	/**
	 * When the code is not effective any longer. ï¿½Code is not deleted; the effective end date determines when the code is no longer active.
	 */
	effectiveEndDate:Date = undefined;
	/**
	 * Action specific to the ref standard code maintenance activity.
	 */
	actionCode:string = undefined;
	/**
	 * Message specific to the ref standard code maintenance activity.
	 */
	errorMessage:string = undefined;
}
