import { Component, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { IsBetweenModel } from '../../model.interface';
import { XpoConditionTimeNotBetweenComponent } from '../../time/index';
import { ordersMinMaxTime } from '../../time/time.util';

import {
  CustomFilterParams,
  filterFormats,
  Operators,
  OperatorText,
  XpoFilterConditionCriteria,
} from './../../../../../models/index';

@Component({
  templateUrl: './not-between.component.html',
  styleUrls: ['../../validator.scss'],
})
export class XpoConditionTimeRangeNotBetweenComponent
  extends XpoConditionTimeNotBetweenComponent
  implements XpoFilterCondition {
  static text: string = OperatorText.NotBetween;
  static operator: Operators = Operators.NotBetween;

  constructor() {
    super();
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    if (!cellValue || !cellValue.from || !cellValue.to) {
      return false;
    }

    const cellValueFrom = moment(cellValue.from, 'h:mma');
    const cellValueTo = moment(cellValue.to, 'h:mma');

    const modelFrom = moment(this.model.from, 'h:mma');
    const modelTo = moment(this.model.to, 'h:mma');

    // Common scenario when user set FROM lesser than TO
    if (modelFrom.isSameOrBefore(modelTo)) {
      return (
        (cellValueFrom.isBefore(modelFrom) && cellValueTo.isBefore(modelTo)) ||
        (cellValueFrom.isAfter(modelFrom) && cellValueTo.isAfter(modelTo))
      );
    }
    // If FROM is greather than TO on this no-bewteen filter, then whe need to do the oposite validation
    return cellValueFrom.isSameOrAfter(modelTo) && cellValueTo.isSameOrBefore(modelFrom);
  }
  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.NotBetween,
      value: this.model.from,
      valueTo: this.model.to,
    };
  }
}
