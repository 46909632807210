export enum XpoEventKeyCode {
  Delete = 46,
  DeleteSafari = 63272,
  Backspace = 8,
  Control = 17,
  C = 67,
  S = 83,
  V = 86,
  Zero = 48,
  Nine = 57,
  Minus = 189,
  Subtract = 109,
  Enter = 13,
  Tab = 9,
  LeftArrow = 37,
  RightArrow = 39,
  Alt = 18,
}
