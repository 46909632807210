import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';

/**
 * Input element that is in the xpo-upload-button element
 */
@Directive({
  selector: 'input[type=file]',
})
export class XpoUploadInput {
  constructor(private element: ElementRef) {}

  get inputElement(): HTMLInputElement {
    return this.element.nativeElement;
  }
}

@Component({
  selector: 'xpo-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-UploadInput' },
})
export class XpoUploadButton {
  @Input() disabled: boolean;

  @ContentChild(XpoUploadInput)
  input: XpoUploadInput;

  openFileSelector(): void {
    // Open the file selector when button clicked
    this.input.inputElement.click();
  }
}
