import {BeaconSourceCd} from '../';
import {EnumHelper} from './enum-helper';

export class BeaconSourceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BeaconSourceCd ) {
      this._values.push(BeaconSourceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BeaconSourceCd {

    for ( const obj in BeaconSourceCd ) {
      if (BeaconSourceCd[obj] === value){
        return BeaconSourceCd[obj] as BeaconSourceCd;
      }
    }
  }
}

const BeaconSourceCdHelper = new BeaconSourceCdHelperEnumHelperClass();
export default BeaconSourceCdHelper;
