
export enum CustomerPaymentTermsCd {
	DELINQUENT = 'Delinquent',
	IMMEDIATE = 'Immediate',
	TERM_120_DAYS = 'Term120Days',
	TERM_15_DAYS = 'Term15Days',
	TERM_180_DAYS = 'Term180Days',
	TERM_20_DAYS = 'Term20Days',
	TERM_30_DAYS = 'Term30Days',
	TERM_35_DAYS = 'Term35Days',
	TERM_40_DAYS = 'Term40Days',
	TERM_45_DAYS = 'Term45Days',
	TERM_50_DAYS = 'Term50Days',
	TERM_55_DAYS = 'Term55Days',
	TERM_60_DAYS = 'Term60Days',
	TERM_75_DAYS = 'Term75Days',
	TERM_90_DAYS = 'Term90Days'}
