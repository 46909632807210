import {LocationOperationTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class LocationOperationTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LocationOperationTypeCd ) {
      this._values.push(LocationOperationTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LocationOperationTypeCd {

    for ( const obj in LocationOperationTypeCd ) {
      if (LocationOperationTypeCd[obj] === value){
        return LocationOperationTypeCd[obj] as LocationOperationTypeCd;
      }
    }
  }
}

const LocationOperationTypeCdHelper = new LocationOperationTypeCdHelperEnumHelperClass();
export default LocationOperationTypeCdHelper;
