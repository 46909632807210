import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { take } from 'rxjs/operators';
import { XpoAuthenticationService } from '../authentication/authentication.service';

/**
 * When navigating to this component, it will check if the user is login in,
 * if so, it will redirect the user to the root of the app, if not, then it will start the
 * login process
 */
@Component({
  selector: 'xpo-authentication',
  template: '',
})
export class XpoAuthenticationComponent implements OnInit {
  constructor(private authenticationService: XpoAuthenticationService, private router: Router) {}

  ngOnInit(): void {
    if (this.authenticationService.isLoggedIn()) {
      this.router.navigate(['/']);
    } else {
      this.authenticationService
        .completeSignIn$()
        .pipe(take(1))
        .subscribe();
    }
  }
}
