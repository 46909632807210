import { Injectable } from '@angular/core';
import {
  GetLocReferenceDetailsBySicResp,
  LocationApiService,
  GetLocReferenceDetailsBySicPath,
  GetLocReferenceDetailsBySicQuery,
} from '@xpo-ltl-2.0/sdk-location';
import { Observable } from 'rxjs';
import { XpoLtlBaseCacheService } from '../base-cache/base-cache.service';

export interface LocationDetailsCacheParams {
  sicCd: string;
}

@Injectable({ providedIn: 'root' })
export class XpoLtlLocationDetailsCacheService extends XpoLtlBaseCacheService<
  LocationDetailsCacheParams,
  GetLocReferenceDetailsBySicResp
> {
  constructor(private locationService: LocationApiService) {
    super();
  }

  protected getKeyFromParams(params: LocationDetailsCacheParams): string {
    return params.sicCd.toUpperCase();
  }

  protected requestData(params: LocationDetailsCacheParams): Observable<GetLocReferenceDetailsBySicResp> {
    const request = new GetLocReferenceDetailsBySicPath();
    request.sicCd = params.sicCd.toUpperCase();
    return this.locationService.getLocReferenceDetailsBySic(request, new GetLocReferenceDetailsBySicQuery(), {
      toastOnError: false,
    });
  }
}
