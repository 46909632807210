import {GeoLocationTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class GeoLocationTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in GeoLocationTypeCd ) {
      this._values.push(GeoLocationTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): GeoLocationTypeCd {

    for ( const obj in GeoLocationTypeCd ) {
      if (GeoLocationTypeCd[obj] === value){
        return GeoLocationTypeCd[obj] as GeoLocationTypeCd;
      }
    }
  }
}

const GeoLocationTypeCdHelper = new GeoLocationTypeCdHelperEnumHelperClass();
export default GeoLocationTypeCdHelper;
