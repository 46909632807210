
import { Inject, OnInit, Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { XpoFacetFilterListItemModel } from '../../../share/model/list-items.interface';

@Component({
  selector: 'xpo-enum-facet-filter',
  templateUrl: './show-more-modal.component.html',
  styleUrls: ['./show-more-modal.component.scss'],
})
export class XpoFacetFilterEnumShowMoreDialogComponent implements OnInit {
  listItems: XpoFacetFilterListItemModel[] = [];
  title: string;
  allSelected: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<XpoFacetFilterEnumShowMoreDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {}


  ngOnInit(): void {
    this.listItems = this.data.listItems;
    this.title = this.data.dialogTitle;
  }

  closeDialog(listItems?: XpoFacetFilterListItemModel[]): void {
    this.dialogRef.close(listItems);
  }

  listItemsTracker(item: XpoFacetFilterListItemModel): string {
    return item.value?.toString();
  }

  onChangeSelected(): void {
    this.allSelected = this.listItems.length && this.listItems.every((item) => item.selected);
  }

  someSelected(): boolean {
    return this.listItems.some((item) => item.selected) && !this.allSelected;
  }

  setAll(selected: boolean): void {
    this.allSelected = selected;
    this.listItems.forEach((item) => (item.selected = selected));
  }
}
