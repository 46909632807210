import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

import { XpoBoardDataFetchState } from '../../models/board-data-fetch-state.model';
import { XpoDataDrawerState } from './data-drawer-state.model';

/**
 * Class to extend to fetch an individual entity
 *
 * TInput selected record model
 *
 * TOutput defaults to TInput if no TOutput, we are going to assume that
 * there is no transformation from the incoming record selection
 * to the consumer of the selection change.
 */
export abstract class XpoDataDrawerDataSource<TInput = any, TOutput = TInput> {
  private transformedSelectedRecordSource$ = new Subject<TOutput>();
  private transformedSelectedRecordValue: TOutput;
  private dataDrawerStateSource$ = new BehaviorSubject<XpoDataDrawerState<TInput>>({
    focusedRecord: null,
    selection: [],
  });
  private dataFetchStateSource$ = new BehaviorSubject<XpoBoardDataFetchState>(null);

  // Transformed Selected Record
  get transformedSelectedRecord(): TOutput {
    return this.transformedSelectedRecordValue;
  }
  get transformedSelectedRecord$(): Observable<TOutput> {
    return this.transformedSelectedRecordSource$.asObservable();
  }

  // Selection
  get dataDrawerState(): XpoDataDrawerState<TInput> {
    return this.dataDrawerStateSource$.value;
  }
  get dataDrawerState$(): Observable<XpoDataDrawerState<TInput>> {
    return this.dataDrawerStateSource$.asObservable();
  }

  setDataDrawerState(v: XpoDataDrawerState<TInput>): void {
    this.dataDrawerStateSource$.next(v);
    this.retrieveRecord(); // Update transformed record on selection change
  }

  // Data fetch state
  get dataFetchState$(): Observable<XpoBoardDataFetchState> {
    return this.dataFetchStateSource$.asObservable();
  }

  retrieveRecord(): void {
    // Fetching the selected record from the observable instead of the getting the value directly since
    // I want to use a switchMap so that the previous fetch is canceled when an updated selection is made
    this.dataDrawerStateSource$
      .pipe(
        // Make sure we to the fetch when we actually have data. Maybe we ended up here due to a focus/selection reset
        filter((state) => !!state.focusedRecord || !!(state.selection && state.selection.length > 0)),
        tap(() => this.dataFetchStateSource$.next(XpoBoardDataFetchState.Loading)),
        switchMap((state) => this.fetchEntity(state.focusedRecord, state.selection)),
        take(1)
      )
      .subscribe(
        (r) => {
          this.dataFetchStateSource$.next(XpoBoardDataFetchState.ResultsReturned);
          this.transformedSelectedRecordValue = r;
          this.transformedSelectedRecordSource$.next(r);
        },
        (err) => {
          this.dataFetchStateSource$.next(XpoBoardDataFetchState.Error);
        }
      );
  }

  /**
   * Based on the selectedRecord, fetch the data associated with the selected record.
   *
   * @param focusedRecord
   * @param selection
   */
  abstract fetchEntity(focusedRecord: TInput, selection?: TInput[]): Observable<TOutput>;
}
