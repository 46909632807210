import { Component, forwardRef, ViewEncapsulation } from '@angular/core';
import { XpoFacetFilterTypeControls } from '../../share/enums/type-list-control';
import { XpoFacetFilterCriteria } from '../../share/model/facet-model-criteria.interface';
import { XpoFacetFilterStringOperators, XpoFacetFilterTypeOperator } from '../../share/model/filter-criteria.interface';
import { XpoFilterControlBaseComponent } from '../filter-control-base.component';

@Component({
  selector: 'xpo-quick-search-facet-filter',
  templateUrl: './filter-control-quick-search.component.html',
  styleUrls: ['./filter-control-quick-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: XpoFilterControlBaseComponent, useExisting: forwardRef(() => XpoFacetFilterQuickSearchControlComponent) }],
})
export class XpoFacetFilterQuickSearchControlComponent extends XpoFilterControlBaseComponent<string> {
  filterModel: string = '';
  originalFilterModel: string;
  hideTitleSection: boolean = true;

  typeControl: XpoFacetFilterTypeControls =  XpoFacetFilterTypeControls.QuickSearch;

  constructor() {
    super();
  }

  applyFilter(): void {
    this.onValueChanged(this.filterModel, this.mapFacetFilterControlToCriteria(this.filterModel));
  }

  onReset(): void {
    super.onReset();
    this.filterModel = '';
    this.applyFilter();
  }

  mapFacetFilterControlToCriteria (value: string): XpoFacetFilterCriteria {
    return {
      // here
      filterId: '', // quick search apply for all columns so no special field is tied
      type: XpoFacetFilterTypeOperator.String,
      conditions: [{ operator: XpoFacetFilterStringOperators.CONTAINS, value }],
      invalid: !this.isValidCondition()
    };
  }

  isValidCondition(): boolean {
    return this.filterModel.length > 0;
  }
  setModel(): void {};
}
