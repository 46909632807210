import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

/* Ag-grid */
import { ColumnApi, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';

/* Material */
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';

/* XPO */
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoBoardOptions } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardView } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoConfirmDialog, XpoConfirmDialogConfig, XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { ListModuleGroupsQuery } from '@xpo-ltl/sdk-moduleexecutor';

/* Rxjs */
import { forkJoin, interval, Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

/* Enums */
import { ConfigManagerProperties } from '../shared/enums/config-manager-properties.enum';

/* Models */
import {
  MODULE_EXECUTOR_BOARD_TEMPLATES,
  MODULE_LIBRARY_GRID_OPTIONS,
  ModuleLibrary,
  ModuleLibraryCellRendererParams,
  ModuleLibraryVersion,
} from './models';

/* Services */
import { ModuleGroupsService } from '../module-groups-board/services/module-groups.service';
import { ModuleLibraryBoardDataSource } from './services/module-library-board-dataSource';
import { ModuleLibraryService } from './services/module-library.service';

/* Dialogs */
import { ModuleGroupGridModel } from '../module-groups-board/models/module-group-grid.model';
import { ModuleLibraryDeleteModuleVersionUnableDialogComponent } from './dialogs/index';

@Component({
  selector: 'ltl-xpo-module-library-board',
  templateUrl: './module-library-board.component.html',
  styleUrls: ['./module-library-board.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ModuleLibraryContainer',
  },
})
export class ModuleLibraryBoardComponent implements OnInit, OnDestroy {
  @ViewChild('trailPanel', { static: true })
  private trailPanel: MatDrawer;

  private lastAccess: Date;

  private gridApi: GridApi;
  private columnApi: ColumnApi;

  views: XpoAgGridBoardView[];
  viewTemplates = MODULE_EXECUTOR_BOARD_TEMPLATES;
  boardOptions: XpoBoardOptions;
  gridOptions: GridOptions = MODULE_LIBRARY_GRID_OPTIONS;

  moduleLibraryVersion: ModuleLibraryVersion;
  moduleGroups: ModuleGroupGridModel[];

  dataRenderer$: Subscription;

  refreshBoardMinutes: number;

  constructor(
    private configManagerService: ConfigManagerService,
    private moduleLibraryService: ModuleLibraryService,
    private dialog: MatDialog,
    private confirmDialog: XpoConfirmDialog,
    private snackBar: XpoSnackBar,
    public dataSource: ModuleLibraryBoardDataSource,
    public moduleGroupService: ModuleGroupsService
  ) {
    this.refreshBoardMinutes = this.configManagerService.getSetting(ConfigManagerProperties.refreshModuleBoardMin);
    this.boardOptions = {
      suppressViewSwitcher: true,
      suppressGridSettingsPopover: false,
      suppressGridDensity: true,
      applyFilterStrategy: 'auto',
      enableFilterReset: true,
    };
  }

  ngOnInit(): void {
    this.views = this.getViews();
    this.lastAccess = new Date();

    this.gridOptions.onFirstDataRendered = () => this.onFirstDataRenderer();
    this.gridOptions.onRowDataChanged = () => this.onRowDataChanged();

    this.gridOptions.context = { setSelectedModule: this.onSelectedModule.bind(this) };

    this.moduleLibraryVersion = null;
  }

  protected getViews(): XpoAgGridBoardView[] {
    return this.viewTemplates.map((template) => {
      return template.createView({
        id: template.id,
        name: template.name,
        visible: true,
      });
    });
  }

  getLastAccess(): string {
    return formatDate(this.lastAccess, 'd MMMM y \'at\' hh:mm', 'en-US');
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;

    this.columnApi = params.columnApi;
    // TODO: use context
    this.columnApi.getColumn('actions').getColDef().cellRendererParams = {
      onDeleteModule: this.onDeleteModule.bind(this),
      onDeleteModuleVersion: this.onDeleteModuleVersion.bind(this),
    };
    // TODO: When autoGroupColumnDef is used, grid doesn't respect col order.
    this.columnApi.moveColumn('actions', 0);
  }

  private onDeleteModule(params: ModuleLibraryCellRendererParams): void {
    let module = params.rowNode.data;
    // if has children takes the first
    if (!module) {
      module = params.rowNode.allLeafChildren[0] ? params.rowNode.allLeafChildren[0].data : null;
    }
    const moduleVersions: ModuleLibraryVersion[] = params.rowNode.allLeafChildren.map<ModuleLibraryVersion>(
      (node) => node.data
    );
    this.getReferencedModuleGroups(moduleVersions)
      .pipe(take(1))
      .subscribe((referencedModules) => {
        const dialogConfig = {
          maxWidth: '400px',
          disableClose: true,
          hasBackdrop: true,
        };
        if (referencedModules && referencedModules.length > 0) {
          this.dialog
            .open(ModuleLibraryDeleteModuleVersionUnableDialogComponent, {
              ...dialogConfig,
              maxWidth: '530px',
              data: referencedModules,
            })
            .afterClosed();
        } else {
          const confirmConfig: XpoConfirmDialogConfig = {
            confirmButtonText: 'Delete',
            rejectButtonText: 'Cancel',
            icon: 'warning',
            width: '640px',
          };
          this.confirmDialog
            .confirm('This action cannot be undone.', 'Are you sure you want to delete the Module?', confirmConfig)
            .subscribe((result) => {
              if (result) {
                this.deleteModule(module, moduleVersions);
              }
            });
        }
      });
  }

  private getReferencedModuleGroups(moduleVersions: ModuleLibraryVersion[]): Observable<ModuleGroupGridModel[]> {
    const listReferenceRequest = moduleVersions.map((modVersion) => {
      const listMGQuery: ListModuleGroupsQuery = {
        moduleVersionSeqNbr: modVersion.moduleVersionSequenceNbr,
        moduleInstId: modVersion.moduleInstId,
        dynamicDagInd: null,
        listInfo: null,
      };
      return this.moduleGroupService.listModuleGroups(listMGQuery).pipe(take(1));
    });
    return forkJoin(listReferenceRequest).pipe(
      map((listReferenceResponse) => {
        // return a flatten module groups list without duplicates
        const flattenMGList = listReferenceResponse.flat();
        return flattenMGList.filter(
          (moduleGroup, index, self) => self.findIndex((t) => t.name === moduleGroup.name) === index
        );
      })
    );
  }

  private deleteModule(module: ModuleLibrary, moduleVersions: ModuleLibraryVersion[]): void {
    this.moduleLibraryService.deleteModule(module.moduleInstId).subscribe(
      () => {
        this.dataSource.deleteRows(moduleVersions);
        this.snackBar.success(`Module "${module.moduleName}" was delete successfully`);
      },
      (error) => {
        this.snackBar.error(`Module "${module.moduleName}" can't be deleted`);
      }
    );
  }

  private onDeleteModuleVersion(params: ModuleLibraryCellRendererParams): void {
    const moduleVersion: ModuleLibraryVersion = params.rowNode.data;
    const { moduleInstId, moduleVersionSequenceNbr } = moduleVersion;
    const listMGQuery: ListModuleGroupsQuery = {
      moduleVersionSeqNbr: moduleVersionSequenceNbr,
      moduleInstId,
      dynamicDagInd: null,
      listInfo: null,
    };

    const dialogConfig = {
      maxWidth: '400px',
      disableClose: true,
      hasBackdrop: true,
    };
    this.moduleGroupService
      .listModuleGroups(listMGQuery)
      .pipe(take(1))
      .subscribe((listMG) => {
        if (listMG && listMG.length > 0) {
          this.dialog
            .open(ModuleLibraryDeleteModuleVersionUnableDialogComponent, {
              ...dialogConfig,
              maxWidth: '500px',
              data: listMG,
            })
            .afterClosed();
        } else {
          const confirmConfig: XpoConfirmDialogConfig = {
            confirmButtonText: 'Yes',
            rejectButtonText: 'No',
            icon: 'warning',
            width: '640px',
          };
          this.confirmDialog
            .confirm(
              `Are you sure that you would like to delete ${moduleVersion.moduleName}?`,
              'Confirm Module Deletion',
              confirmConfig
            )
            .subscribe((result) => {
              if (result) {
                this.deleteModuleVersion(moduleVersion);
              }
            });
        }
      });
  }

  private deleteModuleVersion(moduleVersion: ModuleLibraryVersion): void {
    this.moduleLibraryService
      .deleteModuleVersion(moduleVersion.moduleInstId, moduleVersion.moduleVersionSequenceNbr)
      .subscribe(
        () => {
          this.dataSource.deleteRow(moduleVersion);
          this.snackBar.success(`Module version "${moduleVersion.version}" was delete successfully`);
        },
        (error) => {
          // this.snackBar.error(`Module version "${moduleVersion.version}" can't be deleted`);
          this.dialog
            .open(ModuleLibraryDeleteModuleVersionUnableDialogComponent, {
              maxWidth: '400px',
              disableClose: true,
              hasBackdrop: true,
              data: moduleVersion,
            })
            .afterClosed();
        }
      );
  }

  private onFirstDataRenderer(): void {
    this.dataRenderer$ = interval(this.refreshBoardMinutes * 60000).subscribe(() => this.dataSource.addNewRows());
  }

  private onRowDataChanged(): void {
    this.showNewModuleVersionNotification(this.getNewVersions());
  }

  private getNewVersions(): Array<ModuleLibraryVersion> {
    const versions = new Array<ModuleLibraryVersion>();

    this.gridApi.forEachLeafNode((node) => {
      if (!node.alreadyRendered && node.data.isNew) {
        versions.push(node.data);
      }
    });

    return versions;
  }

  private showNewModuleVersionNotification(moduleVersionList: ModuleLibraryVersion[]): void {
    if (moduleVersionList.length === 0) {
      return;
    }

    const version = moduleVersionList.shift();

    const snackBarRef = this.snackBar.open({
      message: `${version.moduleName} Module Version ${version.version} was added`,
      status: 'success',
    });

    snackBarRef.afterDismissed().subscribe(() => {
      this.showNewModuleVersionNotification(moduleVersionList);
    });
  }

  private onSelectedModule(moduleLibraryVersion: ModuleLibraryVersion): void {
    this.moduleLibraryVersion = moduleLibraryVersion;
    this.getReferencedModuleGroups([moduleLibraryVersion])
      .pipe(take(1))
      .subscribe((referencedModules: ModuleGroupGridModel[]) => {
        this.moduleGroups = referencedModules;
        this.openTrailPanel();
      });
  }

  private openTrailPanel(): void {
    this.trailPanel.open();
  }

  closeTrailPanel(): void {
    this.trailPanel.close();
  }

  ngOnDestroy(): void {
    if (this.dataRenderer$) {
      this.dataRenderer$.unsubscribe();
    }
  }
}
