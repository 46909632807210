import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { XpoLtlConfirmEnvironmentComponent } from './confirm-environment/confirm-environment.component';

@NgModule({
  declarations: [XpoLtlConfirmEnvironmentComponent],
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatInputModule, FormsModule],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
  ],
  entryComponents: [XpoLtlConfirmEnvironmentComponent],
})
export class XpoLtlEnvironmentAndRoleGuardModule {}
