
export enum ContactRoleCd {
	ACCOUNTS_PAYABLE = 'AccountsPayable',
	BALANCE_DUE = 'BalanceDue',
	CLAIMS = 'Claims',
	DELIVERY = 'Delivery',
	EDI = 'EDI',
	FREIGHT_PAYMENT_PLAN = 'FreightPaymentPlan',
	INVOICING = 'Invoicing',
	NOA = 'NOA',
	OPERATIONS = 'Operations',
	OS_AND_D = 'OSAndD',
	PAYMENT_PLAN = 'PaymentPlan',
	PICKUP = 'Pickup',
	PURCHASING = 'Purchasing',
	SALES = 'Sales'}

