import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { XpoBoardConsumer, XpoBoardStateSources, XpoGridBoardState } from '../../../models/index';
@Component({
  selector: 'xpo-column-level-filtering-toggle',
  templateUrl: './column-level-filtering-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-column-level-filtering-toggle' },
})
export class XpoColumnLevelFilteringToggleComponent extends XpoBoardConsumer {
  static readonly IsFilterVisibleDefaultValue = true;

  private isFiltersVisible: boolean = XpoColumnLevelFilteringToggleComponent.IsFilterVisibleDefaultValue;

  toggleColumnLevelFiltering(): void {
    this.isFiltersVisible = !this.isFiltersVisible;
    this.stateChange$.next(<XpoGridBoardState>{
      columnLevelFilteringVisible: this.isFiltersVisible,
      source: XpoBoardStateSources.ColumnLevelFilteringToggle,
    });
  }

  protected onStateChange(state: XpoGridBoardState): void {
    this.isFiltersVisible = state.columnLevelFilteringVisible;
  }
}
