import { ComponentRef } from '@angular/core';
import { XpoFilterCondition } from './conditions';
import { XpoConditionBase } from './validations/validations.class';

export class XpoConditionalFilter {
  componentReference: ComponentRef<XpoFilterCondition & XpoConditionBase>;
  conditions: XpoFilterCondition[];
  selectedCondition: any;

  constructor(conditions: XpoFilterCondition[], selectCondition?: string) {
    this.conditions = conditions;
    if (selectCondition) {
      this.selectedCondition = conditions[this.getIndexOfCondition(conditions, selectCondition)];
    } else {
      this.selectedCondition = conditions[0];
    }
  }

  saveComponentReference(ref): void {
    this.componentReference = ref;
  }

  /**
   * Gets you the index of the condition you're looking for.
   *
   * TODO: Catch if it doesn't find the value.
   * @param conditions The conditions for this filter
   * @param conditionValueToFind The value of the condition to find
   * @returns the index of the condition you are trying to find
   */
  getIndexOfCondition(conditions: XpoFilterCondition[], conditionValueToFind: string): number {
    let index = 0;
    for (const condition of conditions) {
      if (condition.operator === conditionValueToFind) {
        return index;
      } else {
        index++;
      }
    }
    return index;
  }

  validate(cellValue): boolean {
    return this.componentReference.instance.validate(cellValue);
  }
}
