import { Type } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  ArrayCriteriaFunction,
  CriteriaFunction,
  DefaultCriteriaFunction,
  EqualsCriteriaFunction,
  NotCriteriaFunction,
  PrimitiveCriteriaFunction,
  RangeCriteriaFunction,
  XrtSearchRequest,
  XrtSearchResponse,
  XrtSortExpression,
} from '../models/index';
import { XrtService } from './xrt.service';

/**
 * Configurations for specific a specific data source
 */
export interface XrtInMemorySourceConfig {
  /** Columns that the q filter will do a contains in filter on */
  // TODO: string | string[]
  quickSearchColumn?: string | string[];
}

/**
 * Type to represent the datasources
 */
export type XrtInMemorySource = (...args) => Observable<any[]>;

/**
 * datasource and datasource configuration
 */
export interface XrtInMemorySourceWithConfig {
  source: XrtInMemorySource;
  config: XrtInMemorySourceConfig;
}

/**
 * Drop in replacement for remote XRT, that implements basic search functionality
 */
export class XrtInMemoryService extends XrtService {
  private readonly dataSources = new Map<Type<any> | string, XrtInMemorySourceWithConfig>();
  private readonly criteriaFilterAppliers: CriteriaFunction[] = [
    new ArrayCriteriaFunction(),
    new RangeCriteriaFunction(),
    new EqualsCriteriaFunction(),
    new NotCriteriaFunction(),
    new PrimitiveCriteriaFunction(),
    new DefaultCriteriaFunction(),
  ];

  constructor(dataSourcesValue: Map<Type<any> | string, XrtInMemorySource | XrtInMemorySourceWithConfig>) {
    super(
      // dummy params to make base class happy
      {
        apiUri: 'https://DOES-NOT-EXIST.SERVICE',
      },
      undefined
    );

    // Normalizing entered datasources value so the value is always going to be a XrtInMemorySourceWithConfig
    dataSourcesValue.forEach((value, key) => {
      const normalizedValue: XrtInMemorySourceWithConfig =
        value instanceof Function ? { source: value, config: {} } : value;

      this.dataSources.set(key, normalizedValue);
    });
  }

  search<T>(resultType: Type<T>, request: XrtSearchRequest, ...apiArgs): Observable<XrtSearchResponse<T>> {
    const startIdx = Math.max(0, request.pageNumber - 1) * request.pageSize;
    const endIdx = startIdx + request.pageSize;

    if (!this.dataSources.has(resultType)) {
      throw new Error('Unknown type ' + typeof resultType);
    }

    const dataSourcesResult = this.dataSources.get(resultType);

    return dataSourcesResult.source(...apiArgs).pipe(
      take(1),
      map((data) => (data ? data : [])),
      map((data) => this.applyCriteria(data, request.filter)),
      map((data) => this.applyQFilter(data, request.filter.q, dataSourcesResult.config.quickSearchColumn)),
      // Commented out due to frameworks (like ag-grid and mat-table) already having sorting built in, so no need to duplicate work
      // But keeping just in case this is needed
      // map((data) => this.applySort(data, request.sortExpressions)),
      map((filteredData) => {
        return {
          items: filteredData.length > startIdx ? filteredData.slice(startIdx, endIdx) : [],
          pageNumber: Math.max(1, request.pageNumber),
          pageSize: request.pageSize,
          resultCount: filteredData.length,
        };
      })
    );
  }

  private applyCriteria<T>(data: T[], criteria: { [key: string]: any }): T[] {
    // q filter is applied in it's own function, so strip it from the criteria
    const { q, ...appliedCriteria } = criteria;

    const criterionFuncs: ((t: T) => boolean)[] = Object.entries(appliedCriteria)
      .filter(([k, v]) => v != null) // Ignoring criterion that have null or undefined values
      .map(([key, value]) => {
        const criteriaApplier = this.criteriaFilterAppliers.find((applier) => applier.supportsValue(value));

        return criteriaApplier.getFilter<T>(key, value);
      });

    return data.filter((t: T) => criterionFuncs.every((cf) => cf(t)));
  }

  private applyQFilter(data: any[], q: string, columnValue: string | string[]): any[] {
    const regexp = new RegExp(`.*(${q}).*`, 'i');

    if (!q || !columnValue || !columnValue.length) {
      return data;
    }

    const column: string[] = columnValue instanceof Array ? columnValue : [columnValue];

    return data.filter((d) => column.some((col) => !!regexp.test(d[col])));
  }

  private applySort<T>(data: T[], sortExpressions: XrtSortExpression[]): T[] {
    return sortExpressions && sortExpressions.length ? data.sort(this.sortComparer(sortExpressions)) : data;
  }

  private sortComparer(sortExpressions: XrtSortExpression[]) {
    let sortOrder = 1;
    const firstSortExp = sortExpressions[0];

    if (firstSortExp.direction === 'desc') {
      sortOrder = -1;
    }

    return (a, b) => {
      const result =
        a[firstSortExp.column] < b[firstSortExp.column] ? -1 : a[firstSortExp.column] > b[firstSortExp.column] ? 1 : 0;
      return result * sortOrder;
    };
  }
}
