import {PaymentTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class PaymentTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PaymentTypeCd ) {
      this._values.push(PaymentTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PaymentTypeCd {

    for ( const obj in PaymentTypeCd ) {
      if (PaymentTypeCd[obj] === value){
        return PaymentTypeCd[obj] as PaymentTypeCd;
      }
    }
  }
}

const PaymentTypeCdHelper = new PaymentTypeCdHelperEnumHelperClass();
export default PaymentTypeCdHelper;
