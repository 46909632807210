import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

export interface XpoAccountPopoverConfig {
  imageUri?: string;
  name: string;
  links: XpoAccountPopoverLink[];
  onSignOutCallback?: Function;
  email?: string;
}

export interface XpoAccountPopoverLink {
  title: string;
  url: string;
}

/**
 * Using deprecated xpo-account-popover only for examples
 */
type DropDownIconArrow = 'arrow_drop_down' | 'arrow_drop_up';
@Component({
  selector: 'xpo-account-popover',
  templateUrl: './account-popover.component.html',
  styleUrls: ['./account-popover.component.scss'],
  host: {
    class: 'xpo-AccountPopover',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class XpoAccountPopover implements OnInit {
  nameInitials: string = '';
  isPopoverOpened = false;
  popoverIcon: DropDownIconArrow = 'arrow_drop_down';

  @Input()
  config: XpoAccountPopoverConfig;

  ngOnInit(): void {
    // Get name initials
    let name = '';
    if (this.config.name) {
      const configName = this.config.name;
      if (configName.split(' ')[0]) {
        name = configName.split(' ')[0].charAt(0);
      }
      let lastName = '';
      if (configName.split(' ')[1]) {
        lastName = configName.split(' ')[1].charAt(0);
      }
      this.nameInitials = `${name}${lastName}`.toUpperCase();
    }
  }

  signOut(): void {
    if (this.config.onSignOutCallback) {
      this.config.onSignOutCallback();
    }
  }

  onPopoverClose(): void {
    this.togglePopover();
  }

  togglePopover(): void {
    this.isPopoverOpened = !this.isPopoverOpened;
    this.popoverIcon = this.isPopoverOpened ? 'arrow_drop_up' : 'arrow_drop_down';
  }
}
