export enum OperatorText {
  Is = 'is',
  IsNot = 'is not',
  Empty = 'is empty',
  NotEmpty = 'is not empty',
  Greater = 'is greater than',
  GreaterEqual = 'is greater or equal to',
  Less = 'is less than',
  LessEqual = 'is less or equal to',
  Between = 'is between',
  NotBetween = 'is not between',
  Contains = 'contains',
  NotContains = 'does not contain',
  None = '',
  EndsWith = 'ends with',
  StartsWith = 'starts with',
  Exactly = 'is exactly',
  NotExactly = 'is not exactly',
  OneOf = 'is one of',
  True = 'is true',
  False = 'is false',
  After = 'is after',
  OnOrAfter = 'is on or after',
  Before = 'is before',
  OnOrBefore = 'is on or before',
}
