import {FieldDisplayTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class FieldDisplayTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in FieldDisplayTypeCd ) {
      this._values.push(FieldDisplayTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): FieldDisplayTypeCd {

    for ( const obj in FieldDisplayTypeCd ) {
      if (FieldDisplayTypeCd[obj] === value){
        return FieldDisplayTypeCd[obj] as FieldDisplayTypeCd;
      }
    }
  }
}

const FieldDisplayTypeCdHelper = new FieldDisplayTypeCdHelperEnumHelperClass();
export default FieldDisplayTypeCdHelper;
