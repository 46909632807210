import { Observable } from 'rxjs';

import { XpoDateFilterBase } from '../models/date-filter-base';
import { XpoDateFilterConfig } from '../models/date-filter-config';
import { XpoDateFilterComponent } from './date-filter.component';

export class XpoDateFilter extends XpoDateFilterBase {
  constructor(
    field: string,
    label: string,
    dateFilterConfigValue?: XpoDateFilterConfig | Observable<XpoDateFilterConfig>
  ) {
    super(field, label, XpoDateFilterComponent, dateFilterConfigValue);
  }
}
