import { ChangeDetectorRef, Component } from '@angular/core';
import { XpoConditionalFilterComponent } from '../conditional-filter.component';
import {
  XpoConditionEnumEqualsComponent,
} from '../conditions';

@Component({
  templateUrl: '../conditional-filter.component.html',
  styleUrls: ['../conditional-filter.component.scss'],
})
export class XpoEnumFilterComponent extends XpoConditionalFilterComponent {
  allowMultipleFilterRows = false;
  defaultConditions = [
    XpoConditionEnumEqualsComponent,
  ];
}
