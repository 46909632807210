import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { interval, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { DateUtils } from '../utils';

@Component({
  selector: 'xpo-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-Clock' },
})
export class XpoClockComponent implements OnInit {
  time$: Observable<Date>;

  @Input()
  offsetInSeconds: number = new Date().getTimezoneOffset() * 60 * -1;

  @Input()
  timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor() {}

  ngOnInit(): void {
    this.time$ = interval(1000).pipe(map(() => DateUtils.getDateFromOffset(this.offsetInSeconds)));
  }
}
