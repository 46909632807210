import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';

import { ModuleExecutorDateTimeRenderComponent } from './date-time-cell-renderer.component';

@NgModule({
  declarations: [ModuleExecutorDateTimeRenderComponent],
  imports: [CommonModule, AgGridModule.withComponents([ModuleExecutorDateTimeRenderComponent])],
  exports: [ModuleExecutorDateTimeRenderComponent],
})
export class DateTimeCellRendererModule {}
