import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XpoMessagingPopoverModule } from '@xpo-ltl/ngx-ltl-core';
import { ModuleExecutorApiService } from '@xpo-ltl/sdk-moduleexecutor';
import { NotificationsModule } from './components/notifications/notifications.module';
import { ModuleNotificationsComponent } from './module-notifications.component';

@NgModule({
  declarations: [ModuleNotificationsComponent],
  imports: [CommonModule, NotificationsModule, XpoMessagingPopoverModule],
  exports: [ModuleNotificationsComponent],
  providers: [ModuleExecutorApiService],
})
export class ModuleNotificationsModule {}
