import {EnvironmentCd} from '../';
import {EnumHelper} from './enum-helper';

export class EnvironmentCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EnvironmentCd ) {
      this._values.push(EnvironmentCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EnvironmentCd {

    for ( const obj in EnvironmentCd ) {
      if (EnvironmentCd[obj] === value){
        return EnvironmentCd[obj] as EnvironmentCd;
      }
    }
  }
}

const EnvironmentCdHelper = new EnvironmentCdHelperEnumHelperClass();
export default EnvironmentCdHelper;
