import {ActionCd} from '../';
import {EnumHelper} from './enum-helper';

export class ActionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ActionCd ) {
      this._values.push(ActionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ActionCd {

    for ( const obj in ActionCd ) {
      if (ActionCd[obj] === value){
        return ActionCd[obj] as ActionCd;
      }
    }
  }
}

const ActionCdHelper = new ActionCdHelperEnumHelperClass();
export default ActionCdHelper;
