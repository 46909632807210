
export enum CustomerRequestStatusCd {
	CANCEL = 'Cancel',
	COMPLETED = 'Completed',
	EXPIRED = 'Expired',
	NEW = 'New',
	PROCESS = 'Process',
	SUBMITTED = 'Submitted',
	WAIT_APPROVER = 'WaitApprover',
	WAIT_REQUESTOR = 'WaitRequestor'}
