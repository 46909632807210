import {PaymentStatusExternalCd} from '../';
import {EnumHelper} from './enum-helper';

export class PaymentStatusExternalCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PaymentStatusExternalCd ) {
      this._values.push(PaymentStatusExternalCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PaymentStatusExternalCd {

    for ( const obj in PaymentStatusExternalCd ) {
      if (PaymentStatusExternalCd[obj] === value){
        return PaymentStatusExternalCd[obj] as PaymentStatusExternalCd;
      }
    }
  }
}

const PaymentStatusExternalCdHelper = new PaymentStatusExternalCdHelperEnumHelperClass();
export default PaymentStatusExternalCdHelper;
