import {DoorPreferenceTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DoorPreferenceTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DoorPreferenceTypeCd ) {
      this._values.push(DoorPreferenceTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DoorPreferenceTypeCd {

    for ( const obj in DoorPreferenceTypeCd ) {
      if (DoorPreferenceTypeCd[obj] === value){
        return DoorPreferenceTypeCd[obj] as DoorPreferenceTypeCd;
      }
    }
  }
}

const DoorPreferenceTypeCdHelper = new DoorPreferenceTypeCdHelperEnumHelperClass();
export default DoorPreferenceTypeCdHelper;
