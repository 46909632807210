import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { XpoButtonModule } from '../button/button.module';
import { XpoIconModule } from '../icon/icon.module';
import { XpoNotificationTemplate } from './notification-template';

@NgModule({
  declarations: [XpoNotificationTemplate],
  imports: [
    CommonModule,
    MatIconModule,
    MatSnackBarModule,
    MatButtonModule,
    XpoButtonModule,
    XpoIconModule,
    RouterModule],
  exports: [MatSnackBarModule],
  entryComponents: [XpoNotificationTemplate],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500, verticalPosition: 'top'  } }
  ]
})
export class XpoSnackBarModule {}
