import { NgModule } from '@angular/core';
import { XpoAutocompleteInternalActiveNavigation } from './directives/autocompleteKeyboardActiveNavigation.directive';
import { XpoDisableOptionCenteringDirective } from './directives/disable-option-centering.directive';
import { XpoSelectThreeColumnsContainer } from './directives/threeColumnsContainer.directive';
import { XpoSelectTwoColumnsContainer } from './directives/twoColumnsContainer.directive';
import { XpoSelectTwoLinesContainer } from './directives/twoLinesContainer.directive';
import { XpoOptionHideDividerDirective } from './directives/xpo-option-hide-divider.directive';
import { XpoOptionTwoLinesDirective } from './directives/xpo-option-twoLines.directive';
import { XpoSelectTwoLinesDirective } from './directives/xpo-select-twoLines.directive';
import { XpoSelectDirective } from './directives/xpo-select.directive';

@NgModule({
  declarations: [
    XpoDisableOptionCenteringDirective,
    XpoSelectTwoLinesContainer,
    XpoSelectTwoColumnsContainer,
    XpoAutocompleteInternalActiveNavigation,
    XpoOptionHideDividerDirective,
    XpoOptionTwoLinesDirective,
    XpoSelectDirective,
    XpoSelectTwoLinesDirective,
    XpoSelectThreeColumnsContainer,
  ],
  exports: [
    XpoDisableOptionCenteringDirective,
    XpoSelectTwoLinesContainer,
    XpoSelectTwoColumnsContainer,
    XpoAutocompleteInternalActiveNavigation,
    XpoOptionHideDividerDirective,
    XpoOptionTwoLinesDirective,
    XpoSelectDirective,
    XpoSelectTwoLinesDirective,
    XpoSelectThreeColumnsContainer,
  ],
})
export class XpoSelectModule {}
