import { Injectable } from '@angular/core';

import _ from 'lodash';

/* XPO */
import { ActionCd, ModuleExecutorParmsArgTypeCd } from '@xpo-ltl/sdk-common';
import {
  DataExtract,
  Dataset,
  GetModuleGroupPath,
  GetModuleGroupResp,
  ModuleExecutorApiService,
  ModuleGroup,
  ModuleGroupExecParm,
  ModuleGroupModuleVersion,
} from '@xpo-ltl/sdk-moduleexecutor';

/* Rxjs */
import { Observable, Subject } from 'rxjs';

/* Models */
import { StepGridItem } from '../models/step-grid-item.model';

@Injectable({
  providedIn: 'root',
})
export class ModuleGroupManagementService {
  private steps: StepGridItem[];
  private stepsCopy: StepGridItem[];

  private stepsChange: Subject<StepGridItem[]>;

  private readonly defaultStep: StepGridItem;

  private dagScript: string;
  private dagScriptCopy: string;

  private dagScriptChange: Subject<string>;

  isDagDisable: boolean;

  private selectedPeriodCd: string;
  private selectedPeriodCdChange: Subject<string>;

  datasetLists: {
    periodCds: string[];
    dataExtracts: DataExtract[][];
    datasets: Dataset[][];
  };

  constructor(private moduleExecutorApiService: ModuleExecutorApiService) {
    // Default Step
    this.defaultStep = new StepGridItem(1, 'Step 1', [], false, false);

    this.steps = [this.defaultStep];
    this.stepsCopy = [];

    this.stepsChange = new Subject();

    this.dagScript = null;
    this.dagScriptCopy = null;

    this.dagScriptChange = new Subject();

    this.selectedPeriodCd = null;
    this.selectedPeriodCdChange = new Subject();

    this.datasetLists = {
      periodCds: [],
      dataExtracts: [],
      datasets: [],
    };
  }

  /**
   * @name getSelectedPeriodCd
   * @description Get Selected Period Code
   * @returns string
   */
  getSelectedPeriodCd(): string {
    return this.selectedPeriodCd;
  }

  /**
   * @name getModuleGroup
   * @description Get Module Group
   * @param moduleGroupInstId <number>
   * @returns Observable<GetModuleGroupResp>
   */
  getModuleGroup(moduleGroupInstId: number): Observable<GetModuleGroupResp> {
    const pathParams: GetModuleGroupPath = {
      moduleGroupInstId: moduleGroupInstId,
    };
    return this.moduleExecutorApiService.getModuleGroup(pathParams);
  }

  /**
   * @name getSteps
   * @description Get Steps
   * @returns StepGridItem[]
   */
  getSteps(): StepGridItem[] {
    return this.steps;
  }

  /**
   * @name getStepsCopy
   * @description Get Steps Copy
   * @returns StepGridItem[]
   */
  getStepsCopy(): StepGridItem[] {
    return this.stepsCopy;
  }

  /**
   * @name getNextStepId
   * @description Get Next Step Id
   * @returns number
   */
  getNextStepId(): number {
    return this.steps.length + 1;
  }

  /**
   * @name getStepById
   * @description Get step by Id
   * @param stepId <number>
   * @returns StepGridItem
   */
  getStepById(stepId: number): StepGridItem {
    return this.steps.find((step: StepGridItem) => step.id === stepId);
  }

  /**
   * @name getStepRowsByStepId
   * @description Get step rows by step id
   * @param stepId <number>
   * @returns AssociatedModuleVersion[]
   */
  getStepRowsByStepId(stepId: number): ModuleGroupModuleVersion[] {
    const step = this.getStepById(stepId);
    return (
      step &&
      step.rows.sort((a, b) => {
        return a.execOrderSequenceNbr - b.execOrderSequenceNbr;
      })
    );
  }

  /**
   * @name getNumberOfDatasets
   * @description Get count of datasets in all steps
   * @returns AssociatedModuleVersion[]
   */
  getNumberOfDatasets(): number {
    const steps = this.getSteps();
    let count = 0;
    steps.forEach((step) => {
      step.rows.forEach((row) => {
        if (row.moduleGroupExecParm && row.moduleGroupExecParm.length) {
          row.moduleGroupExecParm.forEach((moduleGroupExecParmItem) => {
            if (moduleGroupExecParmItem.argumentTypeCd === ModuleExecutorParmsArgTypeCd.DATA_SET) {
              count++;
            }
          });
        }
      });
    });
    return count;
  }

  /**
   * @name setSelectedPeriodCd
   * @description Set Selected Period Code
   * @returns string
   */
  setSelectedPeriodCd(selectedPeriodCd: string): void {
    this.selectedPeriodCd = selectedPeriodCd;
    this.selectedPeriodCdChange.next(this.selectedPeriodCd);
  }

  /**
   * @name onSelectedPeriodCdChange
   * @description On Selected Period Code Change
   * @returns Subject<string>
   */
  onSelectedPeriodCdChange(): Subject<string> {
    return this.selectedPeriodCdChange;
  }

  /**
   * @name setStepRowsByStepId
   * @description Set step rows by step id
   * @param stepId <number>
   * @returns AssociatedModuleVersion[]
   */
  setStepRowsByStepId(stepId: number, rows: ModuleGroupModuleVersion[]): void {
    const stepIndex = this.getStepIndex(stepId);
    if (stepIndex !== -1) {
      this.steps[stepIndex].rows = rows;
      this.stepsChange.next(this.steps);
    }
  }

  /**
   * @name setSteps
   * @description Set Steps
   * @param steps <StepGridItem[]>
   */
  setSteps(steps: StepGridItem[]): void {
    const stepsSorted = this.getStepsSorted(steps);
    this.steps = stepsSorted;
    this.stepsChange.next(stepsSorted);
  }

  /**
   * @name setStepsCopy
   * @description Set Steps Edited
   * @param steps <StepGridItem[]>
   */
  setStepsCopy(steps: StepGridItem[]): void {
    const stepsSorted = this.getStepsSorted(steps);
    this.stepsCopy = _.cloneDeep(stepsSorted);
  }

  /**
   * @name getStepsSorted
   * @description Get Steps Sorted
   * Order steps rows by execOrderSequenceNbr
   * @param steps <StepGridItem[]>
   */
  getStepsSorted(steps: StepGridItem[]): StepGridItem[] {
    return steps.map((step) => {
      step.rows = step.rows.sort((a, b) => a.execOrderSequenceNbr - b.execOrderSequenceNbr);
      return step;
    });
  }

  /**
   * @name onStepsChange
   * @description On Steps Change
   * @returns Subject<StepGridItem[]>
   */
  onStepsChange(): Subject<StepGridItem[]> {
    return this.stepsChange;
  }

  /**
   * @name getStepsByModuleGroup
   * @description Get Steps By Module Group
   * @param moduleGroup <ModuleGroup>
   * @return StepGridItem[]
   */
  getStepsByModuleGroup(moduleGroup: ModuleGroup): StepGridItem[] {
    let steps: StepGridItem[] = [this.defaultStep];
    if (moduleGroup && moduleGroup.moduleGroupModuleVersion) {
      steps = moduleGroup.moduleGroupModuleVersion.reduce((r, a, i) => {
        const orderNbr = parseInt(a.executionOrderNbr, 10);
        const previousRows = (r[orderNbr - 1] && r[orderNbr - 1].rows) || [];
        r[orderNbr - 1] = {
          id: orderNbr,
          name: 'Step ' + orderNbr,
          rows: [...previousRows, a],
          removable: orderNbr !== 1,
          execOrderLoopInd: a.execOrderLoopInd,
        };
        return r;
      }, []);
    }
    return steps;
  }

  /**
   * @name getArgumentsByStepAndModuleVersion
   * @description Get Arguments By Step And Module Version
   * @param stepId <number>
   * @param moduleVersionSequenceNbr <number>
   * @return ModuleGroupExecParm[]
   */
  getArgumentsByStepAndModuleVersion(stepId: number, moduleVersionSequenceNbr: number): ModuleGroupExecParm[] {
    const step = this.getStepById(stepId);
    const moduleVersion = step.rows.find((row) => row.moduleVersionSequenceNbr === moduleVersionSequenceNbr);
    return moduleVersion ? moduleVersion.moduleGroupExecParm : [];
  }

  /**
   * @name setStepArguments
   * @description Set Step Arguments
   * @param stepId <number>
   * @param moduleVersionSequenceNbr <number>
   * @param moduleGroupExecParm <ModuleGroupExecParm[]>
   */
  setStepArguments(stepId: number, moduleVersionSequenceNbr: number, moduleGroupExecParm: ModuleGroupExecParm[]): void {
    const stepIndex = this.getStepIndex(stepId);
    if (stepIndex !== -1) {
      const moduleVersionIndex = this.steps[stepIndex].rows.findIndex(
        (row) => row.moduleVersionSequenceNbr === moduleVersionSequenceNbr
      );
      if (moduleVersionIndex !== -1) {
        this.steps[stepIndex].rows[moduleVersionIndex].moduleGroupExecParm = moduleGroupExecParm;
        this.stepsChange.next(this.steps);
      }
    }
  }

  /**
   * @name setStepArgumentsByIndex
   * @description Set Step Arguments By Index
   * @param stepId <number>
   * @param moduleVersionSequenceNbr <number>
   * @param argumentIndex <number>
   * @param moduleGroupExecParm <ModuleGroupExecParm>
   */
  setStepArgumentsByIndex(
    stepId: number,
    moduleVersionSequenceNbr: number,
    argumentIndex: number,
    moduleGroupExecParm: ModuleGroupExecParm
  ): void {
    const stepIndex = this.getStepIndex(stepId);
    if (stepIndex !== -1) {
      const moduleVersionIndex = this.steps[stepIndex].rows.findIndex(
        (row) => row.moduleVersionSequenceNbr === moduleVersionSequenceNbr
      );
      if (moduleVersionIndex !== -1) {
        this.steps[stepIndex].rows[moduleVersionIndex].moduleGroupExecParm[argumentIndex] = moduleGroupExecParm;
        this.stepsChange.next(this.steps);
      }
    }
  }

  /**
   * @name setStepModuleGroupModuleVersion
   * @description Set Step Module Group Module Version
   * Used to update ModuleGroupModuleVersion from a particular Step obtained from executionOrderNbr
   * @param moduleGroupModuleVersion <ModuleGroupModuleVersion>
   */
  setStepModuleGroupModuleVersion(moduleGroupModuleVersion: ModuleGroupModuleVersion): void {
    const stepId = parseInt(moduleGroupModuleVersion.executionOrderNbr, 10);
    const stepIndex = this.getStepIndex(stepId);
    if (stepIndex !== -1) {
      const moduleVersionIndex = this.steps[stepIndex].rows.findIndex(
        (row) => {
          return row.moduleVersionSequenceNbr === moduleGroupModuleVersion.moduleVersionSequenceNbr &&
          row.execOrderSequenceNbr === moduleGroupModuleVersion.execOrderSequenceNbr
        });
      if (moduleVersionIndex !== -1) {
        this.steps[stepIndex].rows[moduleVersionIndex] = moduleGroupModuleVersion;
        this.stepsChange.next(this.steps);
      }
    }
  }

  /**
   * @name setPeriodCodeOnModuleGroup
   * @description Add/Update PeriodCd (if selected) on execParms for all moduleGroupVersion
   * @param moduleGroupModuleVersion <ModuleGroupModuleVersion>
   * @return ModuleGroupModuleVersion[]
   */
  setPeriodCodeOnModuleGroup(moduleGroupVersion: ModuleGroupModuleVersion[]): ModuleGroupModuleVersion[] {
    if (this.selectedPeriodCd) {
      moduleGroupVersion.forEach((mv) => {
        if (mv.listActionCd !== ActionCd.DELETE) {
          if (!mv.moduleGroupExecParm) {
            mv.moduleGroupExecParm = [];
          }
          const pcParm = mv.moduleGroupExecParm.findIndex(
            (obj) => obj.argumentTypeCd === ModuleExecutorParmsArgTypeCd.PERIOD_CODE
          );

          if (pcParm !== -1) {
            mv.moduleGroupExecParm[pcParm] = {
              ...mv.moduleGroupExecParm[pcParm],
              argument: this.selectedPeriodCd,
              listActionCd: mv.listActionCd,
            };
          } else {
            let newExecParam = new ModuleGroupExecParm();
            newExecParam = {
              moduleGroupExecParmId: null,
              moduleVersionSequenceNbr: mv.moduleVersionSequenceNbr,
              moduleGroupInstId: mv.moduleGroupInstId,
              moduleInstId: mv.moduleInstId,
              moduleGroupModuleVersionSequenceNbr: mv.moduleGroupModuleVersionSequenceNbr,
              argumentSequenceNbr: '0',
              argumentTypeCd: ModuleExecutorParmsArgTypeCd.PERIOD_CODE,
              argumentId: 'pc',
              argument: this.selectedPeriodCd,
              correlationId: null,
              listActionCd: ActionCd.ADD,
              auditInfo: null,
              parentDatasetInstId: null,
              parentDataExtractInstId: null,
            };
            mv.moduleGroupExecParm.push(newExecParam);
          }
        }
      });
    }

    return moduleGroupVersion;
  }

  /**
   * @name fixStepsOrderId
   * @description Fix Steps OrderId
   * This is used to correct the steps order id when middle step is deleted for example
   * @param steps <StepGridItem[]>
   * @return StepGridItem[]
   */
  fixStepsOrderId(steps: StepGridItem[]): StepGridItem[] {
    const uniqueStepOrderNbrList = [...new Set(steps.map((item) => item.id))];
    return steps.map((step: StepGridItem) => {
      step.id = uniqueStepOrderNbrList.findIndex((val) => val === step.id) + 1;
      step.name = 'Step ' + step.id;
      step.removable = step.id !== 1;
      step.rows.forEach((row) => {
        row.executionOrderNbr = `${step.id}`;
      });
      return step;
    });
  }

  /**
   * @name fixStepRowsExecOrderSequenceNbr
   * @description Fix Step Rows ExecOrderSequenceNbr
   * This is used to correct the steps rows
   * execOrderSequenceNbr Order when middle row is deleted for example
   * @param stepId <number>
   */
  fixStepRowsExecOrderSequenceNbr(stepId: number): void {
    const step = this.getStepById(stepId);
    const uniqueStepOrderNbrList = [...new Set(step.rows.map((item) => item.execOrderSequenceNbr))];
    step.rows = step.rows.map((row: ModuleGroupModuleVersion) => {
      row.execOrderSequenceNbr = uniqueStepOrderNbrList.findIndex((val) => val === row.execOrderSequenceNbr) + 1;
      row.listActionCd = ActionCd.UPDATE;
      return row;
    });
  }

  /**
   * @name getModuleGroupModuleVersionCopyComparison
   * @description Get Module Group Module Versions Copy Comparison
   * @param moduleGroupInstId <number>
   * @return ModuleGroupModuleVersion[]
   */
  getModuleGroupModuleVersionCopyComparison(moduleGroupInstId: number): ModuleGroupModuleVersion[] {
    let result: ModuleGroupModuleVersion[] = [];
    if (this.steps && this.stepsCopy) {
      const stepsCopyModuleVersions: ModuleGroupModuleVersion[] = _.cloneDeep(
        this.getModuleVersionsBySteps(moduleGroupInstId, this.stepsCopy)
      );
      const stepsModuleVersions: ModuleGroupModuleVersion[] = _.cloneDeep(
        this.getModuleVersionsBySteps(moduleGroupInstId, this.steps)
      );

      // Find values that are in Steps Copy but not in Current Steps
      const uniqueStepsCopy = stepsCopyModuleVersions
        .filter(function(obj) {
          return !stepsModuleVersions.some(function(obj2) {
            return (
              obj.parentModuleName === obj2.parentModuleName &&
              obj.parentVersion === obj2.parentVersion &&
              obj.executionOrderNbr === obj2.executionOrderNbr
            );
          });
        })
        .map((obj) => {
          obj.listActionCd = ActionCd.DELETE;
          // Set listActionCd of a ALL moduleGroupExecParm to DELETE
          if (obj.moduleGroupExecParm) {
            obj.moduleGroupExecParm = obj.moduleGroupExecParm.map((obj2) => {
              obj2.listActionCd = ActionCd.DELETE;
              return obj2;
            });
          }
          return obj;
        });

      // Find values that are in Current Steps but not in Steps Copy
      const uniqueCurrentSteps = stepsModuleVersions
        .filter(function(obj) {
          return !stepsCopyModuleVersions.some(function(obj2) {
            return (
              obj.parentModuleName === obj2.parentModuleName &&
              obj.parentVersion === obj2.parentVersion &&
              obj.executionOrderNbr === obj2.executionOrderNbr
            );
          });
        })
        .map((obj) => {
          obj.listActionCd = ActionCd.ADD;
          // Set listActionCd of a ALL moduleGroupExecParm to ADD
          if (obj.moduleGroupExecParm) {
            obj.moduleGroupExecParm.map((execParm) => {
              execParm.listActionCd = ActionCd.ADD;
              return execParm;
            });
          }
          return obj;
        });

      // Combine the two previous arrays
      const uniques = [...uniqueStepsCopy, ...uniqueCurrentSteps];

      // Find values that are in Steps Copy but not in previous result combination
      const uniqueStepsCopyCombinationCopy = stepsCopyModuleVersions
        .filter(function(obj) {
          return !uniques.some(function(obj2) {
            return (
              obj.parentModuleName === obj2.parentModuleName &&
              obj.parentVersion === obj2.parentVersion &&
              obj.executionOrderNbr === obj2.executionOrderNbr
            );
          });
        })
        .map((moduleGroupModuleVersionCopy: ModuleGroupModuleVersion) => {
          moduleGroupModuleVersionCopy.listActionCd = ActionCd.UPDATE;

          let currentStepsModuleVersionsIndex;

          const stepsModuleVersion = stepsModuleVersions.find(
            (o, index) => {
              currentStepsModuleVersionsIndex = index;

              return moduleGroupModuleVersionCopy.parentModuleName === o.parentModuleName &&
              moduleGroupModuleVersionCopy.parentVersion === o.parentVersion &&
              moduleGroupModuleVersionCopy.executionOrderNbr === o.executionOrderNbr
            }
          );

          // Remove current step module in case there is another module with the same version
          if (currentStepsModuleVersionsIndex > -1) {
            stepsModuleVersions.splice(currentStepsModuleVersionsIndex, 1)
          }

          // Replace the edited execOrderSequenceNbr and the execOrderLoopInd
          moduleGroupModuleVersionCopy.execOrderSequenceNbr = stepsModuleVersion.execOrderSequenceNbr;
          moduleGroupModuleVersionCopy.execOrderLoopInd = stepsModuleVersion.execOrderLoopInd;

          moduleGroupModuleVersionCopy.moduleGroupExecParm = this.getModuleGroupModuleVersionExecParamCopyComparison(
            stepsModuleVersion.moduleGroupExecParm,
            moduleGroupModuleVersionCopy.moduleGroupExecParm
          );

          return moduleGroupModuleVersionCopy;
        });

      result = [...uniqueStepsCopyCombinationCopy, ...uniques];
    }
    return result;
  }

  /**
   * @name getModuleGroupModuleVersionExecParamCopyComparison
   * @description Get Module Group Module Versions Execution Params Copy Comparison
   * @param moduleGroupModuleVersionExecParm: ModuleGroupExecParm[],
   * @param moduleGroupModuleVersionExecParmCopy: ModuleGroupExecParm[]
   * @return ModuleGroupExecParm[]
   */
  getModuleGroupModuleVersionExecParamCopyComparison(
    moduleGroupModuleVersionExecParm: ModuleGroupExecParm[] = [],
    moduleGroupModuleVersionExecParmCopy: ModuleGroupExecParm[] = []
  ): ModuleGroupExecParm[] {
    // Find ExecParm that are in Steps Copy but not in Current Steps
    const uniqueStepsCopyModuleVersionExecParam = moduleGroupModuleVersionExecParmCopy
      .filter((copyExecParm) => {
        return !moduleGroupModuleVersionExecParm.some((currentExecParam) => {
          return (
            copyExecParm.argument === currentExecParam.argument &&
            copyExecParm.argumentId === currentExecParam.argumentId &&
            copyExecParm.argumentTypeCd === currentExecParam.argumentTypeCd
          );
        });
      })
      .map((res) => {
        res.listActionCd = ActionCd.DELETE;
        return res;
      });

    // Find ExecParm that are in Current Steps but not in Steps Copy
    const uniqueCurrentStepsModuleVersionExecParam = moduleGroupModuleVersionExecParm
      .filter((currentExecParam) => {
        return !moduleGroupModuleVersionExecParmCopy.some((copyExecParm) => {
          return (
            currentExecParam.argument === copyExecParm.argument &&
            currentExecParam.argumentId === copyExecParm.argumentId &&
            currentExecParam.argumentTypeCd === copyExecParm.argumentTypeCd
          );
        });
      })
      .map((res) => {
        res.listActionCd = ActionCd.ADD;
        return res;
      });

    // Combine the two previous arrays
    const uniquesModuleVersionExecParam = [
      ...uniqueStepsCopyModuleVersionExecParam,
      ...uniqueCurrentStepsModuleVersionExecParam,
    ];

    // Find values that are in Copy Exec Parm but not in previous result combination
    const uniqueStepsCopyExecParmCombination = moduleGroupModuleVersionExecParmCopy
      .filter((copyExecParm) => {
        return !uniquesModuleVersionExecParam.some((uniquesExecParm) => {
          return (
            copyExecParm.argument === uniquesExecParm.argument &&
            copyExecParm.argumentId === uniquesExecParm.argumentId &&
            copyExecParm.argumentTypeCd === uniquesExecParm.argumentTypeCd
          );
        });
      })
      .map((res) => {
        const newExecParam = moduleGroupModuleVersionExecParm.find((item) => res.argumentId === item.argumentId);
        res.argumentSequenceNbr = newExecParam ? newExecParam.argumentSequenceNbr : res.argumentSequenceNbr;
        res.listActionCd = ActionCd.UPDATE;
        return res;
      });

    const execParms = [...uniqueStepsCopyExecParmCombination, ...uniquesModuleVersionExecParam];

    // execParms.forEach((execParm) => {
    //   if (execParm.argumentTypeCd === ModuleExecutorParmsArgTypeCd.PERIOD_CODE) {
    //     execParm.argument = this.selectedPeriodCd;
    //   }
    // });
    return execParms;
  }

  /**
   * @name getModuleVersionsBySteps
   * @description Get Module Versions By Steps
   * @param moduleGroupInstId: number
   * @param steps: StepGridItem[]
   * @return ModuleGroupModuleVersion[]
   */
  getModuleVersionsBySteps(moduleGroupInstId: number, steps: StepGridItem[]): ModuleGroupModuleVersion[] {
    const moduleGroupModuleVersions: ModuleGroupModuleVersion[] = [];
    steps.forEach((step) => {
      step.rows.forEach((row: ModuleGroupModuleVersion) => {
        moduleGroupModuleVersions.push(row);
      });
    });
    return moduleGroupModuleVersions;
  }

  /**
   * @name addStep
   * @description Add step
   * @param step <StepGridItem>
   */
  addStep(step: StepGridItem): void {
    this.steps.push(step);
    this.stepsChange.next(this.steps);
  }

  /**
   * @name addSteps
   * @description Add steps
   * @param steps <StepGridItem[]>
   */
  addSteps(steps: StepGridItem[]): void {
    if (steps.length > 0) {
      this.steps = [...new Set([...this.steps, ...steps])]; // Set for uniques steps
      this.stepsChange.next(this.steps);
    }
  }

  /**
   * @name addStepRows
   * @description Add Step Rows
   * @param stepId <number>
   * @param newRows <ModuleGroupModuleVersion[]>
   */
  addStepRows(stepId: number, newRows: ModuleGroupModuleVersion[]): void {
    const stepIndex = this.getStepIndex(stepId);
    if (stepIndex !== -1) {
      this.steps[stepIndex].rows = [...new Set([...this.steps[stepIndex].rows, ...newRows])]; // Set for uniques rows
      this.stepsChange.next(this.steps);
    }
  }

  /**
   * @name getUniqueRowsByStepId
   * @description Get Unique Rows By StepId
   * @param stepId <number>
   * @param newRows <AssociatedModuleVersion[]>
   * @returns AssociatedModuleVersion[]
   */
  getUniqueRowsByStepId(stepId: number, newRows: ModuleGroupModuleVersion[]): ModuleGroupModuleVersion[] {
    const step = this.getStepById(stepId);
    return step
      ? newRows.filter(
          (newRow: ModuleGroupModuleVersion) =>
            !step.rows.find((row) => row.moduleVersionSequenceNbr === newRow.moduleVersionSequenceNbr)
        )
      : [];
  }

  /**
   * @name deleteStep
   * @description Delete step
   * @param stepId <number>
   */
  deleteStep(stepId: number): void {
    const stepIndex = this.getStepIndex(stepId);
    if (stepIndex !== -1) {
      this.steps.splice(stepIndex, 1);
      this.setSteps(this.fixStepsOrderId(this.steps));
    }
  }

  /**
   * @name deleteStepRows
   * @description Delete Step Rows
   * @param stepId <number>
   * @param rowsToDelete <ModuleGroupModuleVersion[]>
   */
  deleteStepRows(stepId: number, rowsToDelete: ModuleGroupModuleVersion[]): void {
    const stepIndex = this.getStepIndex(stepId);
    if (stepIndex !== -1) {
      this.steps[stepIndex].rows = this.steps[stepIndex].rows.filter(
        (stepRow: ModuleGroupModuleVersion) =>
          !rowsToDelete.find(
            (rowToDelete: ModuleGroupModuleVersion) => {
              return rowToDelete.moduleVersionSequenceNbr === stepRow.moduleVersionSequenceNbr &&
              rowToDelete.execOrderSequenceNbr === stepRow.execOrderSequenceNbr
            }
          )
      );
      this.stepsChange.next(this.steps);
    }
  }

  /**
   * @name updateStepRows
   * @description Update Step Rows
   * @param stepId <number>
   * @param rowsToUpdate <ModuleGroupModuleVersion[]>
   */
  updateStepRows(stepId: number, rowsToUpdate: ModuleGroupModuleVersion[]): void {
    const stepIndex = this.getStepIndex(stepId);
    if (stepIndex !== -1) {
      this.steps[stepIndex].rows = rowsToUpdate;
      this.stepsChange.next(this.steps);
    }
  }

  /**
   * @name setStepExecOrderLoopId
   * @description Set Step ExecOrderLoopId
   * @param stepId <number>
   * @param execOrderLoopInd <boolean>
   */
  setStepExecOrderLoopId(stepId: number, execOrderLoopInd: boolean): void {
    const stepIndex = this.getStepIndex(stepId);
    if (stepIndex !== -1) {
      this.steps[stepIndex].execOrderLoopInd = execOrderLoopInd;
      this.stepsChange.next(this.steps);
    }
  }

  /**
   * @name getStepExecOrderLoopId
   * @description Get Step ExecOrderLoopId
   * @param stepId <number>
   * @param rowsToUpdate <ModuleGroupModuleVersion[]>
   */
  getStepExecOrderLoopId(stepId: number): boolean {
    const stepIndex = this.getStepIndex(stepId);
    if (stepIndex !== -1) {
      return this.steps[stepIndex].execOrderLoopInd;
    }
  }

  /**
   * @name resetSteps
   * @description Reset Steps
   */
  resetSteps(): void {
    this.defaultStep.rows = [];
    this.steps = [this.defaultStep];
    this.stepsCopy = [this.defaultStep];
    this.dagScript = null;
  }

  /**
   * @name getStepIndex
   * @description Get Step Index
   * @param stepId <number>
   * @returns number
   */
  private getStepIndex(stepId: number): number {
    return this.steps.findIndex((step: StepGridItem) => step.id === stepId);
  }

  /**
   * @name hasSomeStepEmptyRows
   * @description Check if exists some of the steps with empty rows
   * @returns boolean
   */
  hasSomeStepEmptyRows(): boolean {
    return this.steps.some((step) => step.rows.length === 0);
  }

  /**
   * @name setDagScript
   * @description Set Dag Script
   * @param dagScript <string>
   */
  setDagScript(dagScript: string): void {
    this.dagScript = this.dagScriptCopy = dagScript;
    this.dagScriptCopy = _.cloneDeep(dagScript);
    this.dagScriptChange.next(dagScript);
  }

  /**
   * @name updateDagScript
   * @description Update Dag Script
   * @param dagScript <string>
   */
  updateDagScript(dagScript: string): void {
    this.dagScript = dagScript;
    this.dagScriptChange.next(dagScript);
  }

  /**
   * @name getDagScript
   * @description Get Dag Script
   * @return dagScript <string>
   */
  getDagScript(): string {
    return this.dagScript;
  }

  /**
   * @name getDagScriptCopy
   * @description Get Dag Script Copy
   * @return dagScript <string>
   */
  getDagScriptCopy(): string {
    return this.dagScriptCopy;
  }

  /**
   * @name onDagScriptChange
   * @description On Dag Script Change
   * @returns Subject<string>
   */
  onDagScriptChange(): Subject<string> {
    return this.dagScriptChange;
  }

  /**
   * @name setDagDisable
   * @description Set Dag value to enable/disable feature
   * @param dagDisable <boolean>
   */
  setDagDisable(dagDisable): void {
    this.isDagDisable = dagDisable;
  }

  /**
   * @name getDagDisable
   * @description Get Dag value to enable/disable feature
   * @return boolean
   */
  getDagDisable(): boolean {
    return this.isDagDisable;
  }
}
