import { XpoDateRangeFilterCriteria } from './date-range-filter-criteria';
import { XpoDateRangeFilterHelper } from './date-range-filter-helper';
import { XpoDateRangeFilterTab } from './date-range-filter-tab';
import { XpoDateRangeGroupByIntervalType } from './date-range-interval';
import { XpoDateRangeIntervalBase } from './date-range-interval-base';
import { XpoQuickDate } from './date-range-quick-date.enum';
import { XpoQuickFilter } from './date-range-quick-filter';

export class XpoDateRangeHourly extends XpoDateRangeIntervalBase {
  get groupByInterval(): XpoDateRangeGroupByIntervalType {
    return 'hour';
  }

  get label(): string {
    return 'Hourly';
  }

  getQuickFilterOptions(): XpoQuickFilter[] {
    return [
      { code: XpoQuickDate.Yesterday, display: 'Yesterday' },
      { code: XpoQuickDate.Today, display: 'Today' },
      { code: XpoQuickDate.Tomorrow, display: 'Tomorrow' },
    ];
  }

  getTabModel(): XpoDateRangeFilterTab {
    return {
      pos: this.pos,
      quickFilterOptions: this.getQuickFilterOptions(),
      groupByInterval: this.groupByInterval,
    };
  }

  setNext(nextInput: number): XpoDateRangeFilterCriteria {
    return XpoDateRangeFilterHelper.constructFilter(XpoQuickDate.TodayPlusHours, nextInput * 1);
  }

  setPrev(prevInput: number): XpoDateRangeFilterCriteria {
    return XpoDateRangeFilterHelper.constructFilter(XpoQuickDate.TodayMinusHours, prevInput * 1);
  }
}
