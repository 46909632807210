import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { XpoActionButton } from './action-button/action-button.directive';
import { XpoButtonGroup, XpoIconButtonGroup } from './button-group/button-group.component';
import { XpoLargeButton } from './large-button/large-button.directive';
import { XpoLink } from './link/link.component';
import { XpoSmallButton } from './small-button/small-button.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [XpoButtonGroup, XpoLargeButton, XpoSmallButton, XpoLink, XpoIconButtonGroup, XpoActionButton],
  exports: [
    XpoButtonGroup,
    XpoLargeButton,
    XpoSmallButton,
    MatButtonModule,
    MatButtonToggleModule,
    XpoLink,
    XpoIconButtonGroup,
    XpoActionButton,
  ],
})
export class XpoButtonModule {}
