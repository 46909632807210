import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { XpoDateRangeSelectionModel } from '../../../models/date-range-selection.model';

/**
 * Validates if the selected range is valid,
 * i.e. start date should be earlier than the end date
 * */
export function xpoDateRangeInvalidValidator<D>(
  selectionModel: XpoDateRangeSelectionModel<D>,
  dateAdapter: DateAdapter<D>
): ValidatorFn {
  return (): ValidationErrors | null => {
    // if selection model is not valid, we can't do validation
    if (!selectionModel) {
      return null;
    }

    // define the error
    const err = { xpoDateRangeInvalid: true };

    // keep start and end values from selection
    const { start, end } = selectionModel.selection;

    // evaluates if start date is later than end date
    return start && end && dateAdapter.compareDate(start, end) > 0 ? err : null;
  };
}
