import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { debounce, each, filter } from 'lodash';
import { BtnGroups } from './models/dynamic-btn-groups.interface';

/* eslint-disable */
declare var ResizeObserver: new (arg: (entries: any) => void) => any;
/* eslint-enable */

@Directive({
  selector: '[xpo-dynamic-menu], [xpoDynamicMenu]',
})
export class XpoDynamicMenuDirective implements OnInit, OnDestroy {
  readonly MORE_BUTTON_CLASS = 'xpo-DynamicMenu--block';
  readonly STYLE_DISPLAY = 'display';
  readonly STYLE_NONE = 'none';
  readonly DEBOUNCE_TIME = 500;
  private observer: { observe: (arg: any) => void; unobserve: (arg: any) => void };
  private blockedButton: HTMLElement;
  private firstLoad = true;
  private extraSpace: number;
  hiddenElements: HTMLElement[] = [];
  hiddenButtonsWidth: number[] = [];

  @Output() hiddenBtnEvent = new EventEmitter();

  constructor(private hostElement: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    /**
     * Create a resize observer over this whole component and dispatching evalButtonsDisplay
     * when width or height changes
     */
    this.observer = new ResizeObserver(debounce(this.evalButtonsDisplay, this.DEBOUNCE_TIME).bind(this));
    this.observer.observe(this.hostElement.nativeElement);
  }

  private evalButtonsDisplay(): void {
    this.hiddenElements = this.executeMeasurement();
    if (this.hiddenElements) {
      this.hiddenBtnEvent.emit(this.hiddenElements);
    }
    this.firstLoad = false;
  }

  private executeMeasurement(): HTMLElement[] {
    // Get group of hidden and showed buttons
    const btnGroups: BtnGroups = {
      visibleButtons: this.getVisibleButtons(),
      nonVisibleButtons: this.getNonVisibleButtons(),
    };
    // Get the navbar extra space after buttons
    this.extraSpace = this.getExtraSpace(btnGroups.visibleButtons);
    // If remaining space is less than the 0 we have to hide some buttons
    const haveToHideCondition = btnGroups.visibleButtons.length && this.extraSpace < 0;
    // If remaining space is more than the btnAverageWidth we can possible show hidden button
    const haveToShowCondition = btnGroups.nonVisibleButtons.length && this.extraSpace > this.hiddenButtonsWidth[this.hiddenButtonsWidth.length - 1];

    if (haveToHideCondition || haveToShowCondition) {
      this.executeHideAndShow(btnGroups, haveToHideCondition);
    }

    return this.getNonVisibleButtons();
  }

  private executeHideAndShow(btnGroups: BtnGroups, haveToHideCondition: boolean): void {
    if (haveToHideCondition) {
      this.hideButtons(btnGroups.visibleButtons);
    } else {
      this.showButtons(btnGroups.nonVisibleButtons);
    }
  }

  private hideButtons(visibleButtons: HTMLElement[]): void {
    let currentExtraSpace = this.extraSpace;
    visibleButtons.reverse().some((nextVisibleButton: HTMLElement) => {
      if (currentExtraSpace < 0) {
        currentExtraSpace += this.getElementWidth(nextVisibleButton);
        this.hiddenButtonsWidth.push(this.getElementWidth(nextVisibleButton));
        this.renderer.setStyle(nextVisibleButton, this.STYLE_DISPLAY, this.STYLE_NONE);
      } else {
        // Returning true breaks the iteration
        return true;
      }
    });
  }

  private showButtons(nonVisibleButtons: HTMLElement[]): void {
    let currentExtraSpace = this.extraSpace;
    nonVisibleButtons.some((nextNonVisibleButton: HTMLElement) => {
      if (this.hiddenButtonsWidth && currentExtraSpace > this.hiddenButtonsWidth[this.hiddenButtonsWidth.length - 1]) {
        currentExtraSpace -= this.hiddenButtonsWidth[this.hiddenButtonsWidth.length - 1];
        this.renderer.removeStyle(nextNonVisibleButton, this.STYLE_DISPLAY);
        this.hiddenButtonsWidth.pop();
      } else {
        // Returning true breaks the iteration
        return true;
      }
    });
  }

  private getExtraSpace(visibleButtons: HTMLElement[]): number {
    const containerWidth = this.getElementWidth(this.hostElement.nativeElement);
    const visibleBtnWidth = this.getVisibleBtnWidth(visibleButtons);
    const moreBtnWidth = this.getMoreBtnWidth();
    return containerWidth - (visibleBtnWidth + moreBtnWidth);
  }

  private getNonVisibleButtons(): HTMLElement[] {
    // Get elements from host that aren't block and are hide (width === 0)
    return filter(this.hostElement.nativeElement.children, (child) => !child.classList.contains(this.MORE_BUTTON_CLASS) && child.offsetWidth === 0);
  }

  private getVisibleButtons(): HTMLElement[] {
    // Get elements from host that aren't block and aren't hide (has width)
    return filter(this.hostElement.nativeElement.children, (child) => !child.classList.contains(this.MORE_BUTTON_CLASS) && child.offsetWidth);
  }

  private getVisibleBtnWidth(visibleButtons: HTMLElement[]): number {
    // Iterate every button to calculate the complete width occupied for them
    let visibleBtnWidth = 0;
    each(visibleButtons, (btn) => {
      visibleBtnWidth += this.getElementWidth(btn);
    });
    return visibleBtnWidth;
  }

  private getMoreBtnWidth(): number {
    if (this.firstLoad) {
      this.blockedButton = this.getMoreBtn();
    }
    return this.blockedButton ? this.getElementWidth(this.blockedButton) : 0;
  }

  private getMoreBtn(): HTMLElement {
    // Get the width of the block element using his special class
    return filter(this.hostElement.nativeElement.children, (child: HTMLElement) => child.classList.contains(this.MORE_BUTTON_CLASS))[0];
  }

  private getElementWidth(element: HTMLElement): number {
    const style = window.getComputedStyle(element);
    // width + margin of each element
    return element.offsetWidth + parseFloat(style.marginLeft) + parseFloat(style.marginRight);
  }

  ngOnDestroy(): void {
    this.observer?.unobserve(this.hostElement.nativeElement);
  }
}
