import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { XpoDateRangeSelectionModel } from '../../../models/date-range-selection.model';

/**
 * Validates if the selection range is uncomplete,
 * i.e. one of the date has value, and the other one hasn't
 * */
export function xpoDateRangeUncompletedValidator<D>(selectionModel: XpoDateRangeSelectionModel<D>): ValidatorFn {
  return (): ValidationErrors | null => {
    // if selection model is not valid, we can't do validation
    if (!selectionModel) {
      return null;
    }

    // define the error
    const err = { xpoDateRangeUncompleted: true };

    // keep start and end values from selection
    const { start, end } = selectionModel.selection;

    // evaluates if one of the date range is null and the other is not
    return (start && !end) || (end && !start) ? err : null;
  };
}
