export enum XpoQuickDate {
  Yesterday = 'yesterday',
  Today = 'today',
  Tomorrow = 'tomorrow',

  LastWeek = 'lastweek',
  ThisWeek = 'thisweek',
  NextWeek = 'nextweek',

  LastMonth = 'lastmonth',
  ThisMonth = 'thismonth',
  NextMonth = 'nextmonth',

  LastQuarter = 'lastquarter',
  ThisQuarter = 'thisquarter',
  NextQuarter = 'nextquarter',

  LastYear = 'lastyear',
  ThisYear = 'thisyear',
  NextYear = 'nextyear',

  TodayMinusMinutes = 'todayminusminutes',
  TodayPlusMinutes = 'todayplusminutes',
  TodayMinusHours = 'todayminushours',
  TodayPlusHours = 'todayplushours',
  TodayMinusDays = 'todayminusdays',
  TodayPlusDays = 'todayplusdays',
  TodayMinusWeeks = 'todayminusweeks',
  TodayPlusWeeks = 'todayplusweeks',
  TodayMinusMonths = 'todayminusmonths',
  TodayPlusMonths = 'todayplusmonths',
  TodayMinusQuarters = 'todayminusquarters',
  TodayPlusQuarters = 'todayplusquarters',
  TodayMinusYears = 'todayminusyears',
  TodayPlusYears = 'todayplusyears',
}
