import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentCdt } from '@xpo-ltl/sdk-documentmanagement';
import { get as _get } from 'lodash';
import { take } from 'rxjs/operators';

@Component({
  selector: 'xpo-ltl-doc-view-page',
  templateUrl: './doc-view-page.component.html',
  styleUrls: ['./doc-view-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpoLtlDocViewPageComponent {
  documentCdt: DocumentCdt;

  constructor(private route: ActivatedRoute) {
    this.route.params.pipe(take(1)).subscribe((params) => {
      this.documentCdt = {
        corpCode: '', // Not used here
        docClass: _get(params, 'doc-class'),
        timestamp: _get(params, 'timestamp'),
      };
    });
  }
}
