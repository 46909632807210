import {DockShipmentTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DockShipmentTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DockShipmentTypeCd ) {
      this._values.push(DockShipmentTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DockShipmentTypeCd {

    for ( const obj in DockShipmentTypeCd ) {
      if (DockShipmentTypeCd[obj] === value){
        return DockShipmentTypeCd[obj] as DockShipmentTypeCd;
      }
    }
  }
}

const DockShipmentTypeCdHelper = new DockShipmentTypeCdHelperEnumHelperClass();
export default DockShipmentTypeCdHelper;
