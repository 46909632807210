import {BusinessMetricCd} from '../';
import {EnumHelper} from './enum-helper';

export class BusinessMetricCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BusinessMetricCd ) {
      this._values.push(BusinessMetricCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BusinessMetricCd {

    for ( const obj in BusinessMetricCd ) {
      if (BusinessMetricCd[obj] === value){
        return BusinessMetricCd[obj] as BusinessMetricCd;
      }
    }
  }
}

const BusinessMetricCdHelper = new BusinessMetricCdHelperEnumHelperClass();
export default BusinessMetricCdHelper;
