import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { User } from 'oidc-client';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { XpoAuthenticationService } from './authentication.service';

export const XPO_AUTH_HEADER_KEY = 'Authorization';

/**
 * Intercepts http requests to attach a Authorization token to the header
 */
@Injectable()
export class XpoAuthenticationInterceptor implements HttpInterceptor {
  private authenticationService: XpoAuthenticationService;

  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // We are assuming all calls to a url ending with `.json` do not need our token
    // This was put here to solve an issue where the app-settings.json file was getting intercepted
    // before this service is created. Hence the reason we are injecting the injector instead of the
    // XpoAuthenticationService, this function is being called before that service is created.
    if (!req.url.includes('.json') && (!req.headers || !req.headers.get(XPO_AUTH_HEADER_KEY))) {
      this.authenticationService = this.injector.get(XpoAuthenticationService);

      if (this.authenticationService.isLoggedIn()) {
        // add JWT authentication token to headers
        const authRequest = this.addAuthToHeaders(req);

        return next.handle(authRequest).pipe(
          catchError((error) => {
            // the request failed.  If it is an authentication issue, then we want to try to
            // update our auth token
            if (this.isUnauthorizedResponse(error)) {
              return this.reauthenticate().pipe(
                switchMap((user) => {
                  // authenticated. try running the request again. if it fails this time, then we are done
                  const newAuthRequest = this.addAuthToHeaders(req);
                  return next.handle(newAuthRequest);
                })
              );
            } else {
              // error not due to authentication, so just return it
              return throwError(error);
            }
          })
        );
      }
    }

    // request does not need authentication token
    return next.handle(req);
  }

  private addAuthToHeaders(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      headers: req.headers.set(XPO_AUTH_HEADER_KEY, this.getAuthHeaderValue()),
    });
  }

  private getAuthHeaderValue(): string {
    return `${this.authenticationService.getAuthorizationHeaderValue()}`;
  }

  private isUnauthorizedResponse(res: HttpErrorResponse): boolean {
    return res.status === 401;
  }

  // Attempt to reauthenticate
  private reauthenticate(): Observable<User | void> {
    // First, try to silently refresh the token
    return this.authenticationService.startSilentRefresh$();
    // NOTE: If we want, we can catch errors from the silentRefresh and force the user
    // through a full signIn process.
    // .pipe(
    //   catchError((error) => {
    //     // Failed to silently refresh, so force the user to go through the entire log-in process
    //     console.error(`XpoAuthenticationInterceptor: SilentRefresh failed. Forcing full sign-in: `, error);
    //     if (!this.authenticationService) {
    //       this.authenticationService = this.injector.get(XpoAuthenticationService);
    //     }
    //     return this.authenticationService.startSignIn$();
    //   })
    // );
  }
}
