import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { cloneDeep} from 'lodash';
import { XpoFacetFilterSortOptions } from '../../share/enums/sort-options';
import { XpoFacetFilterTypeControls } from '../../share/enums/type-list-control';
import { XpoFacetFilterCriteria } from '../../share/model/facet-model-criteria.interface';
import { XpoFacetFilterStringOperators, XpoFacetFilterTypeOperator } from '../../share/model/filter-criteria.interface';
import { XpoFacetFilterListItemModel } from '../../share/model/list-items.interface';
import { sortItems } from '../../share/utils/general';
import { XpoFilterControlBaseComponent } from '../filter-control-base.component';
import { XpoFacetEnumFilterTexts } from './filter-control-enum.texts';
import { XpoFacetFilterEnumShowMoreDialogComponent } from './modal-show-more/show-more-modal.component';

const DEFAULT_MAX_ITEMS: number = 5;
const DEFAULT_WIDTH_MODAL: string = '50%';
@Component({
  selector: 'xpo-enum-facet-filter',
  templateUrl: './filter-control-enum.component.html',
  styleUrls: ['./filter-control-enum.component.scss'],
  providers: [{ provide: XpoFilterControlBaseComponent, useExisting: forwardRef(() => XpoFacetFilterEnumControlComponent) }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpoFacetFilterEnumControlComponent extends XpoFilterControlBaseComponent<XpoFacetFilterListItemModel[]> implements OnInit {

  @Input()
  numOfItemsToShow: number = DEFAULT_MAX_ITEMS;

  @Input()
  showAllText: string = XpoFacetEnumFilterTexts.ShowAll;

  @Input()
  showLessText: string = XpoFacetEnumFilterTexts.ShowLess;

  allSelected: boolean = false;
  showMoreItems: boolean = false;

  @Input()
  filterModel: XpoFacetFilterListItemModel[] = [];

  @Input()
  dialogConfig: MatDialogConfig;

  @Input()
  sort: XpoFacetFilterSortOptions | undefined = undefined;

  originalFilterModel: XpoFacetFilterListItemModel[] = [];
  typeControl: XpoFacetFilterTypeControls = XpoFacetFilterTypeControls.Enum;

  constructor(public cdr: ChangeDetectorRef, private dialog: MatDialog) {
    super();
  }

  get itemsToShow(): XpoFacetFilterListItemModel[] {
    return this.showMoreItems ? this.filterModel : this.filterModel.slice(0, this.numOfItemsToShow);
  }

  get showMoreItemsActionButton(): boolean {
    return this.filterModel.length > this.numOfItemsToShow;
  }

  ngOnInit(): void {
    this.filterModel?.sort((current, next) => sortItems(this.sort, current.label, next.label));
    this.originalFilterModel = cloneDeep(this.filterModel);
    super.checkInit();
  }

  setModel(model: XpoFacetFilterListItemModel[]): void {
    this.filterModel = model;
    this.filterModel?.sort((current, next) => sortItems(this.sort, current.label, next.label));

    this.originalFilterModel = cloneDeep(this.filterModel);
    this.cdr.markForCheck();
  }

  onChangeSelected(): void {
    this.allSelected = this.filterModel.length && this.filterModel.every((item) => item.selected);
    this.applyFilter();
  }

  someSelected(): boolean {
    return this.filterModel.some((item) => item.selected) && !this.allSelected;
  }

  setAll(selected: boolean): void {
    this.allSelected = selected;
    this.filterModel.forEach((item) => (item.selected = selected));
    this.applyFilter();
  }

  onReset(): void {
    super.onReset();
    this.filterModel = cloneDeep(this.originalFilterModel);
    this.allSelected = false;
    this.applyFilter();
    this.showMoreItems = false;
    this.cdr.markForCheck();
  }

  onShowMoreItems(): void {
    const dialogRef = this.dialog.open(XpoFacetFilterEnumShowMoreDialogComponent, {
      width: DEFAULT_WIDTH_MODAL,
      ...this.dialogConfig,
      data: { dialogTitle: this.parentSectionName, listItems: this.filterModel },
    });

    const modalSubscription = dialogRef.afterClosed().subscribe((result: XpoFacetFilterListItemModel[]) => {
      this.filterModel = result ? result : this.filterModel;
      this.onChangeSelected();
      this.cdr.markForCheck();
      modalSubscription.unsubscribe();
    });
  }

  filterModelTracker(itemModel: XpoFacetFilterListItemModel): string {
    return itemModel.value?.toString();
  }

  applyFilter(): void {
    this.onValueChanged(this.filterModel, this.mapFacetFilterControlToCriteria(this.filterModel));
  }

  mapFacetFilterControlToCriteria(val: XpoFacetFilterListItemModel[]): XpoFacetFilterCriteria {
    return {
      filterId: this.filterId,
      type: XpoFacetFilterTypeOperator.String,
      conditions: val?.filter((el) => el?.selected)?.map((values) => ({ operator: XpoFacetFilterStringOperators.EQUAL, value: values.value })),
      invalid: !this.isValidCondition(),
    };
  }

  isValidCondition(): boolean {
    return this.filterModel.some(item => (item.selected));
  }
}
