import {LateTenderCd} from '../';
import {EnumHelper} from './enum-helper';

export class LateTenderCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LateTenderCd ) {
      this._values.push(LateTenderCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LateTenderCd {

    for ( const obj in LateTenderCd ) {
      if (LateTenderCd[obj] === value){
        return LateTenderCd[obj] as LateTenderCd;
      }
    }
  }
}

const LateTenderCdHelper = new LateTenderCdHelperEnumHelperClass();
export default LateTenderCdHelper;
