import { ChangeDetectionStrategy, Component, Directive, Input, ViewEncapsulation } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { XpoCardType, XpoStatusBar } from './card.model';

@Directive({
  selector: 'xpo-card-actions',
  host: { class: 'xpo-Card-actions' },
})
export class XpoCardActions {}

@Directive({
  selector: 'xpo-card-content',
  host: { class: 'xpo-Card-content' },
})
export class XpoCardContent {}

@Component({
  selector: 'xpo-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-Card',
    '[class.xpo-Card--success]': 'status === "success"',
    '[class.xpo-Card--warn]': 'status === "warn" || color === "warn"',
    '[class.xpo-Card--error]': 'status === "error"',
    '[class.xpo-Card--noStatus]': '!status',
    '[class.xpo-Card--basic]': 'type === "basic"',
    '[class.xpo-Card--data]': 'type === "data"',
    '[class.xpo-Card--primary]': 'color === "primary"',
    '[class.xpo-Card--accent]': 'color === "accent"',
  },
})
export class XpoCard {
  @Input()
  status: XpoStatusBar = null;

  @Input()
  type: XpoCardType = 'basic';

  @Input()
  color: ThemePalette = 'primary';
}
