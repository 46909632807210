import {CustomsControlTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomsControlTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomsControlTypeCd ) {
      this._values.push(CustomsControlTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomsControlTypeCd {

    for ( const obj in CustomsControlTypeCd ) {
      if (CustomsControlTypeCd[obj] === value){
        return CustomsControlTypeCd[obj] as CustomsControlTypeCd;
      }
    }
  }
}

const CustomsControlTypeCdHelper = new CustomsControlTypeCdHelperEnumHelperClass();
export default CustomsControlTypeCdHelper;
