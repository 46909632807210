import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { XpoViewMgtModalActions } from '../view-modal-actions.interface';
import { XpoViewMgtModalData } from '../view-modal-data.interface';
import { XpoViewMgtModalTexts } from '../view-modal.texts';

@Component({
  selector: 'xpo-delete-view-modal',
  templateUrl: './delete-view.component.html',
  styleUrls: ['./delete-view.component.scss'],
})
export class XpoViewMgtModalDeleteView {
  renameViewData: XpoViewMgtModalData = {
    title: XpoViewMgtModalTexts.DeleteViewTitle,
    submitButtonText: XpoViewMgtModalTexts.DeleteViewSubmitBtn,
    cancelButtonText: XpoViewMgtModalTexts.DeleteViewCancelBtn,
  };
  viewName: string;
  modalActions: XpoViewMgtModalActions;

  @ViewChild('xpoViewModal') xpoViewModal;

  constructor(public dialogRef: MatDialogRef<XpoViewMgtModalDeleteView>, @Inject(MAT_DIALOG_DATA) data) {
    this.modalActions = data.actions;
    this.viewName = data.formData.viewName;
  }

  /**
   * On delete submit
   */
  deleteView(): void {
    // Callback for rename view
    this.xpoViewModal.isSubmitting = true;
    this.modalActions?.onSubmit();
  }

  /**
   * Cancel button action in modal
   */
  cancel(): void {
    // Callback for cancel
    this.modalActions?.onCancel();
  }
}
