import { formatDate } from '@angular/common';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

/* XPO */
import { XpoBoardOptions } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardView, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoConfirmDialog, XpoConfirmDialogConfig, XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import {
  DuplicateModuleGroupResp,
  ListModuleGroupRunStatusesResp,
  ModuleExecutorApiService,
} from '@xpo-ltl/sdk-moduleexecutor';

/* Ag-grid */
import { GridApi, GridReadyEvent } from 'ag-grid-community';

/* Material */
import { MatDrawer } from '@angular/material/sidenav';

/* Rxjs */
import { map } from 'rxjs/operators';

/* Models */
import { ModuleGroupGridModel } from './models/module-group-grid.model';
import { MODULE_EXECUTOR_BOARD_TEMPLATES } from './models/module-groups-board-templates';
import { MODULE_GROUP_GRID_OPTIONS } from './models/module-groups-grid-options';

/* Services */
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { forkJoin, Observable } from 'rxjs';
import { ConfigManagerProperties } from '../shared/enums/config-manager-properties.enum';
import { ModuleGroupsBoardDataSource } from './services/module-groups-board-dataSource';
import { ModuleGroupsService } from './services/module-groups.service';

@Component({
  selector: 'ltl-xpo-module-groups-board',
  templateUrl: './module-groups-board.component.html',
  styleUrls: ['./module-groups-board.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ModuleGroupsBoardDataSource],
  host: {
    class: 'ModuleGroups-container',
  },
})
export class ModuleGroupsBoardComponent {
  dynamicDAGDisabled: boolean;

  @ViewChild('trailPanel', { static: true })
  private trailPanel: MatDrawer;

  private gridApi: GridApi;

  previousRunsData: ListModuleGroupRunStatusesResp;
  moduleGroupsData: ModuleGroupGridModel;

  readonly views: XpoAgGridBoardView[];
  readonly viewTemplates: XpoAgGridBoardViewTemplate[];
  readonly gridOptions = {
    ...MODULE_GROUP_GRID_OPTIONS,
    ...{
      context: {
        copyModule: this.onCopyModule.bind(this),
        deleteModule: this.onDeleteModule.bind(this),
        launchModule: this.onLaunchModule.bind(this),
        setSelectedModule: this.onSelectedModule.bind(this),
      },
    },
  };

  protected lastAccess: Date;

  readonly boardOptions: XpoBoardOptions = {
    suppressViewSwitcher: true,
    suppressGridSettingsPopover: false,
    suppressGridDensity: true,
    applyFilterStrategy: 'auto',
    enableFilterReset: true,
  };

  constructor(
    public dataSource: ModuleGroupsBoardDataSource,
    public moduleGroupsService: ModuleGroupsService,
    private router: Router,
    private confirmDialog: XpoConfirmDialog,
    private moduleExecutorApiService: ModuleExecutorApiService,
    private snackbar: XpoSnackBar,
    private configManagerService: ConfigManagerService
  ) {
    this.dynamicDAGDisabled = this.configManagerService.getSetting(ConfigManagerProperties.dynamicDAGDisabled) || false;
    this.viewTemplates = MODULE_EXECUTOR_BOARD_TEMPLATES;
    this.views = this.getViews();
    this.lastAccess = new Date();
  }

  private getViews(): XpoAgGridBoardView[] {
    return this.viewTemplates.map((template) => {
      return template.createView({
        id: template.id,
        name: template.name,
        visible: true,
      });
    });
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;

    this.gridApi.setSortModel([
      {
        colId: 'name',
        sort: 'asc',
      },
    ]);
  }

  private onDeleteModule(node): void {
    const confirmConfig: XpoConfirmDialogConfig = {
      confirmButtonText: 'Delete',
      rejectButtonText: 'Cancel',
      icon: 'warning',
    };

    this.confirmDialog
      .confirm(
        'Associated modules with this module group will be disassociated, but not deleted.',
        'Are you sure you would like to delete this module group?',
        confirmConfig
      )
      .subscribe((result) => {
        if (result) {
          const { moduleGroupInstId, schedule } = node.data;
          const unscheduleModuleGroupExecution$ = this.moduleExecutorApiService.unscheduleModuleGroupExecution({
            moduleGroupInstId,
          });
          const deleteModuleGroup$ = this.moduleGroupsService.deleteModuleGroup(moduleGroupInstId);
          const source: Observable<any> = schedule
            ? forkJoin(unscheduleModuleGroupExecution$, deleteModuleGroup$)
            : deleteModuleGroup$;

          source
            .pipe(map((_) => this.dataSource.refresh()))
            .subscribe(() => this.snackbar.success('Module group successfully deleted.'));
        }
      });
  }

  private onCopyModule(node): void {
    const { moduleGroupInstId } = node.data;
    this.moduleGroupsService
      .copyModuleGroup(moduleGroupInstId)
      .pipe(
        map((duplicateModuleGroupResp: DuplicateModuleGroupResp) => {
          if (duplicateModuleGroupResp) {
            this.dataSource.refresh();
          }
          return duplicateModuleGroupResp;
        })
      )
      .subscribe((duplicateModuleGroupResp: DuplicateModuleGroupResp) => {
        this.snackbar.success(`${duplicateModuleGroupResp.moduleGroup.moduleGroupName} Module group created.`);
      });
  }

  private onLaunchModule(node): void {
    const { moduleGroupInstId, name } = node.data;
    this.moduleGroupsService
      .launchModule(moduleGroupInstId)
      .pipe(map((_) => this.dataSource.refresh()))
      .subscribe((_) => {
        this.snackbar.success(`${name} Module group launched.`);
      });
  }

  private onSelectedModule(moduleGroup: ModuleGroupGridModel): void {
    const { moduleGroupInstId } = moduleGroup;
    this.moduleGroupsService
      .listModuleGroupRunStatuses(moduleGroupInstId)
      .subscribe((previousRuns: ListModuleGroupRunStatusesResp) => {
        let { runStatus } = previousRuns;
        runStatus = runStatus.sort((a, b) => (a.executionDateTime < b.executionDateTime ? 1 : -1));
        this.moduleGroupsData = moduleGroup;
        this.previousRunsData = { runStatus };
        this.openTrailPanel();
      });
  }

  getLastAccess(): string {
    return formatDate(this.lastAccess, 'd MMMM y \'at\' hh:mm', 'en-US');
  }

  goToModuleGroupManagement() {
    const routePath =
      this.configManagerService.getSetting(ConfigManagerProperties.moduleGroupManagementRoute) ||
      './module-group-management';

    this.router.navigate([routePath]);
  }

  private openTrailPanel(): void {
    this.trailPanel.open();
  }

  closeTrailPanel(): void {
    this.trailPanel.close();
  }
}
