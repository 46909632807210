import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { XpoDatePickerModule } from '../datepicker/datepicker.module';
import { XpoChipsModule } from '../chips/chips.module';
import { XpoButtonModule } from '../button/button.module';
import { XpoDialogModule } from '../dialog/dialog.module';
import { XpoIconModule } from '../icon/icon.module';
import { XpoFacetFilterService } from './facet-filter.service';
import { XpoFacetFilterComponent } from './filter-base/filter-base.component';
import { XpoFacetFilterDateRangeControlComponent } from './filter-controls/filter-date-range/filter-control-date-range.component';
import { XpoFacetFilterEnumControlComponent } from './filter-controls/filter-list-checkbox/filter-control-enum.component';
import { XpoFacetFilterEnumShowMoreDialogComponent } from './filter-controls/filter-list-checkbox/modal-show-more/show-more-modal.component';
import { XpoFacetFilterOptionControlComponent } from './filter-controls/filter-list-radio/filter-control-option.component';
import { XpoFacetFilterRangeNumberControlComponent } from './filter-controls/filter-min-max-range-number/filter-control-range.component';
import { XpoFacetFilterQuickSearchControlComponent } from './filter-controls/filter-quick-search/filter-control-quick-search.component';
import { XpoFacetFilterSection } from './filter-section/filter-section.component';
import { XpoFacetFilterRangeTimeControlComponent } from './filter-controls/filter-time-range/filter-control-time-range.component';
@NgModule({
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    XpoIconModule,
    XpoDialogModule,
    XpoButtonModule,
    XpoDatePickerModule,
    XpoChipsModule,
  ],
  declarations: [
    XpoFacetFilterComponent,
    XpoFacetFilterSection,
    XpoFacetFilterEnumControlComponent,
    XpoFacetFilterOptionControlComponent,
    XpoFacetFilterRangeNumberControlComponent,
    XpoFacetFilterQuickSearchControlComponent,
    XpoFacetFilterEnumShowMoreDialogComponent,
    XpoFacetFilterDateRangeControlComponent,
    XpoFacetFilterRangeTimeControlComponent,
  ],
  providers: [
    XpoFacetFilterService,
  ],
  exports: [
    XpoFacetFilterComponent,
    XpoFacetFilterSection,
    XpoFacetFilterEnumControlComponent,
    XpoFacetFilterOptionControlComponent,
    XpoFacetFilterRangeNumberControlComponent,
    XpoFacetFilterQuickSearchControlComponent,
    XpoFacetFilterDateRangeControlComponent,
    XpoFacetFilterRangeTimeControlComponent,
  ],
  entryComponents: [XpoFacetFilterEnumShowMoreDialogComponent]
})
export class XpoFacetFilterModule {}
