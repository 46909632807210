import { Directive, Input } from '@angular/core';
import { MatStepperNext } from '@angular/material/stepper';

@Directive({
  selector: 'button[xpoStepperNext]',
  host: {
    '[type]': 'type',
  },
})
export class XpoStepperNextDirective extends MatStepperNext {
  @Input() type: string;
}
