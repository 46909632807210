import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	RetrieveDmsAuthTokenRqst,
	RetrieveDmsAuthTokenResp,
	GetArchivedDocumentQuery,
	GetArchivedDocumentPath,
	ArchiveDocumentRqst,
	ArchiveDocumentResp,
	CreateWorkFlowRequestRqst,
	CreateWorkFlowRequestResp,
	SearchDmsDocumentRqst,
	SearchDmsDocumentResp,
	ListActiveRequestsByQueueResp,
	ListActiveRequestsByQueuePath,
	GetShipmentImagedDocumentsResp,
	GetShipmentImagedDocumentsQuery,
	GetShipmentImagedDocumentsPath,
	ProcessShipmentCustomsDocumentsRqst,
	ProcessShipmentCustomsDocumentsResp,
	AddIndexToDocumentRqst,
	AddIndexToDocumentResp,
	GetDocumentResp,
	GetDocumentQuery,
	GetDocumentPath
} from './api-documentmanagement';

import {
	GetHealthCheckResp,
	FileContents,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class DocumentManagementApiService extends BaseService {
	private static DocumentManagementApiEndpoint = 'documentmanagementApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will request an Authentication token from DMS
	*/
	public retrieveDmsAuthToken(request: RetrieveDmsAuthTokenRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RetrieveDmsAuthTokenResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/oauth/dmstoken'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will retrieve an archived document from DMS for input corp code, doc class and doc id
	*/
	public getArchivedDocument(
							   pathParams: GetArchivedDocumentPath,
							   queryParams: GetArchivedDocumentQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<FileContents> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/document/{corpCode}/{docClass}/{docArchiveTimestamp}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will archive a document in DMS System.
	*/
	public archiveDocument(request: ArchiveDocumentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ArchiveDocumentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/document/{corpCode}/{docClass}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will create a workflow in DMS System.
	*/
	public createWorkFlowRequest(request: CreateWorkFlowRequestRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateWorkFlowRequestResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/workflow/{queueName}/{activityName}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will search for an archived document from DMS for input corp code, a tag and a value.
	*/
	public searchDmsDocument(request: SearchDmsDocumentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchDmsDocumentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/document/{corpCode}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will retrieve a list of active requests and total count based on input Queue Name, Activity Name and flowName.
	*/
	public listActiveRequestsByQueue(
							   pathParams: ListActiveRequestsByQueuePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListActiveRequestsByQueueResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/workflow/{queueName}/{activityName}/{flowName}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service retrieves imaged documents for a single Pro number from DMS.  Optionally, one or more image types can be input.  If no image type is provided then all imaged documents will be retrieved.  Supported Imaged Documents are: BL (Bill of Lading), DR (Delivery Receipt), DRRR (Delivery Return Receipt), CUST (Customs Documents), IR (Inspection Report), LOA (Letter of Authority), NCIC (NMFC Certificate), WI (Weights and Corrections), WRFO (Weights and Research photos).
	* <br/>		In addition, an image format (pdf, jpeg or tiff) can be entered. All imaged documents will be returned in this format.  If no image format is entered, all imaged documents will be returned in tiff format.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>	1. Valid input data - Pro number and image type are provided and the imaged document exists.
	* <br/>	2. Imaged document doesn't exist for input Pro number and image type.
	* <br/>Post-conditions: 
	* <br/>	1.	If a Pro number and image type are provided, and the requested imaged document exists, then the image is returned. If multiple types are provided, multiple images are returned (if they exist).
	* <br/>		a.	If an image format is provided, then any requested images are returned in the requested image format.
	* <br/>	2.	If the image doesn't exist for the requested Pro number and image type, then the ï¿½Image not foundï¿½ message is returned.
	* <br/>	
	* <br/>Note that this operation has a multi-part response.  The data is included in the first part (listed in the response elements below) and the second part includes the binary image or images.  Please see the samples in this document for more information.
	* <br/>
	*/
	public getShipmentImagedDocuments(
							   pathParams: GetShipmentImagedDocumentsPath,
							   queryParams: GetShipmentImagedDocumentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetShipmentImagedDocumentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/{proNbr}/imaged-docs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service retrieves all the documents from DMS for each document ID and then merges them into one tiff file.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. Valid input data - Pro number and at least 1 document information ID supplied.
	* <br/>	
	* <br/>Post-conditions: 
	* <br/>1. Merged Tiff file is returned that includes all the documents in the input list.
	*/
	public processShipmentCustomsDocuments(request: ProcessShipmentCustomsDocumentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ProcessShipmentCustomsDocumentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/shipments/{proNbr}/customs-documents'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will retrieve an archived document from DMS for input corp code, doc class and doc id
	*/
	public addIndexToDocument(request: AddIndexToDocumentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<AddIndexToDocumentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/index/{corpCode}/{docClass}/{docArchiveTimestamp}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will retrieve an archived document from DMS for input corp code, doc class and doc id and convert it to the format requested by the consumer.
	*/
	public getDocument(
							   pathParams: GetDocumentPath,
							   queryParams: GetDocumentQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetDocumentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/document/{corpCode}/{docClass}/{docArchiveTimestamp}/{docFormat}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(DocumentManagementApiService.DocumentManagementApiEndpoint);
	}
}
