import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceTypeCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'invoiceTypeCd',
})
export class InvoiceTypeCdPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let displayValue = '';
    switch (value) {
      case 'BOTH': // key as a string
      case InvoiceTypeCd.ORIGINAL:
        displayValue = 'Original';
        break;
      case 'COLL': // key as a string
      case InvoiceTypeCd.BAL_DUE:
        displayValue = 'Balance Due';
        break;
      case 'PPD': // key as a string
      case InvoiceTypeCd.CORRECTED:
        displayValue = 'Corrected';
        break;
      default:
        displayValue = value;
    }
    return displayValue;
  }
}
