import { ColDef } from 'ag-grid-community';
import { TransposePopoverCellRendererComponent } from './cell-renderers';

// @dynamic
export class XpoAgGridColumns {
  static readonly Selection: ColDef = {
    lockPosition: true,
    sortable: false,
    cellClass: ['xpo-AgGrid-customColum', 'xpo-AgGrid-checkboxColumn'],
    headerClass: 'xpo-AgGrid-checkboxColumn',
    width: 32,
    checkboxSelection: true,
    suppressMenu: true,
    suppressSizeToFit: true,
    filter: false,
    editable: false,
  };

  static readonly SelectionWithSelectAll: ColDef = {
    ...XpoAgGridColumns.Selection,
    headerCheckboxSelection: true,
  };

  static readonly RowIndex: ColDef = {
    lockPosition: true,
    sortable: false,
    valueGetter: 'node.childIndex + 1 || node.rowIndex + 1;', // Index of this row with respect to its parent when grouping
    cellClass: ['xpo-AgGrid-customColum', 'xpo-AgGrid-rowIndexColumn'],
    headerClass: 'xpo-AgGrid-rowIndexColumn',
    width: 50,
    suppressMenu: true,
    suppressSizeToFit: true,
    filter: false,
    editable: false,
  };

  static readonly TransposePopover: ColDef = {
    lockPosition: true,
    sortable: false,
    width: 30,
    suppressMenu: true,
    filter: false,
    editable: false,
    cellRendererFramework: TransposePopoverCellRendererComponent,
    cellClass: ['xpo-AgGrid-customColum', 'xpo-AgGrid-transposeColumn'],
    headerClass: 'xpo-AgGrid-transposeColumn',
  };
}
