import {BillClassCd} from '../';
import {EnumHelper} from './enum-helper';

export class BillClassCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BillClassCd ) {
      this._values.push(BillClassCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BillClassCd {

    for ( const obj in BillClassCd ) {
      if (BillClassCd[obj] === value){
        return BillClassCd[obj] as BillClassCd;
      }
    }
  }
}

const BillClassCdHelper = new BillClassCdHelperEnumHelperClass();
export default BillClassCdHelper;
