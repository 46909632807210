import { AfterContentInit, ContentChild, Directive, ElementRef, Input } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { XpoTrayPanelComponent } from '../panel/tray-panel.component';

const XPO_TRAY_PANEL_EXTENDED_CLASS = 'xpo-TrayDrawer--extended';

@Directive({
  selector: '[xpoTrayDrawer]',
  host: { class: 'xpo-TrayDrawer' },
})
export class XpoTrayDrawerDirective implements AfterContentInit {
  // using setter it is possible to just add the attribute "extended" in the template
  // without doing property binding
  private extended: boolean = false;
  @Input('extended') set setExtended(extended: boolean | '') {
    this.extended = extended === '' || extended;
  }

  @ContentChild(XpoTrayPanelComponent, { static: true }) panel: XpoTrayPanelComponent;

  constructor(private drawer: MatDrawer, private element: ElementRef) {}

  ngAfterContentInit(): void {
    // subscribe close event
    this.panel.close.subscribe(() => this.drawer.close());
    this.setExtendedTray();
  }

  private setExtendedTray(): void {
    if (!this.extended) {
      return;
    }

    this.element.nativeElement.classList.add(XPO_TRAY_PANEL_EXTENDED_CLASS);
  }
}
