import {ProCheckoutTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ProCheckoutTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ProCheckoutTypeCd ) {
      this._values.push(ProCheckoutTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ProCheckoutTypeCd {

    for ( const obj in ProCheckoutTypeCd ) {
      if (ProCheckoutTypeCd[obj] === value){
        return ProCheckoutTypeCd[obj] as ProCheckoutTypeCd;
      }
    }
  }
}

const ProCheckoutTypeCdHelper = new ProCheckoutTypeCdHelperEnumHelperClass();
export default ProCheckoutTypeCdHelper;
