
export enum HttpMethod {
	DELETE = 'DELETE',
	GET = 'GET',
	HEAD = 'HEAD',
	HTTP_METHOD_UNSPECIFIED = 'HTTP_METHOD_UNSPECIFIED',
	OPTIONS = 'OPTIONS',
	PATCH = 'PATCH',
	POST = 'POST',
	PUT = 'PUT'}

