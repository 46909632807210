import { Injectable } from '@angular/core';
import { XpoLtlBaseCacheService } from '../base-cache/base-cache.service';
import {
  HumanResourceApiService,
  GetEmployeeDetailsByEmpIdResp,
  GetEmployeeDetailsByEmpIdPath,
  GetEmployeeDetailsByEmpIdQuery,
} from '@xpo-ltl/sdk-humanresource';
import { Observable, throwError } from 'rxjs';
import { isNull as _isNull } from 'lodash';

export interface EmployeeDetailsCacheParams {
  employeeId: string;
  effectiveDate?: string;
  requestType?: string;
}

@Injectable({ providedIn: 'root' })
export class XpoLtlEmployeeDetailsCacheService extends XpoLtlBaseCacheService<
  EmployeeDetailsCacheParams,
  GetEmployeeDetailsByEmpIdResp
> {
  private invalidCharsRegex: RegExp = new RegExp(/[^A-Za-z0-9]+/);

  constructor(private hrService: HumanResourceApiService) {
    super();
  }

  protected getKeyFromParams(params: EmployeeDetailsCacheParams): string {
    return `${params.employeeId.toUpperCase()}:${params.effectiveDate || ''}:${params.requestType || ''}`;
  }

  protected requestData(params: EmployeeDetailsCacheParams): Observable<GetEmployeeDetailsByEmpIdResp> {
    if (this.isInvalidEmployeeId(params.employeeId)) {
      return throwError({
        error: {
          errorCode: 400,
          message: 'Invalid employeeId.',
        },
      });
    }

    const pathParams = new GetEmployeeDetailsByEmpIdPath();
    pathParams.employeeId = params.employeeId.toUpperCase();
    const queryParams = new GetEmployeeDetailsByEmpIdQuery();
    queryParams.effectiveDate = params.effectiveDate;
    queryParams.requestType = params.requestType;
    return this.hrService.getEmployeeDetailsByEmpId(pathParams, queryParams, { toastOnError: false });
  }

  private isInvalidEmployeeId(employeeId: string): boolean {
    return !employeeId || _isNull(employeeId) || this.invalidCharsRegex.test(employeeId);
  }
}
