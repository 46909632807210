import { HorizontalConnectionPos, VerticalConnectionPos } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

export interface XpoNotificationPopoverConfig {
  title: string;
  url: string;
}

@Component({
  selector: 'xpo-notification-popover',
  templateUrl: './notification-popover.html',
  styleUrls: ['./notification-popover.scss'],
  host: {
    class: 'xpo-NotificationPopover',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class XpoNotificationPopover {
  @Input()
  notifications: XpoNotificationPopoverConfig[];

  @Input()
  caretPosition: HorizontalConnectionPos | VerticalConnectionPos;

  @Input()
  position: TooltipPosition;
}
