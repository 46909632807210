import {ChargeToCd} from '../';
import {EnumHelper} from './enum-helper';

export class ChargeToCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ChargeToCd ) {
      this._values.push(ChargeToCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ChargeToCd {

    for ( const obj in ChargeToCd ) {
      if (ChargeToCd[obj] === value){
        return ChargeToCd[obj] as ChargeToCd;
      }
    }
  }
}

const ChargeToCdHelper = new ChargeToCdHelperEnumHelperClass();
export default ChargeToCdHelper;
