import { XpoBoardData } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardColumn } from './ag-grid-board-column';

export class XpoAgGridBoardDatum {
  readonly columns: XpoAgGridBoardColumn[];
  readonly rows: any[];
  readonly totals: any[] = null;

  constructor(boardData: XpoAgGridBoardDatum | any[]) {
    if (boardData instanceof Array) {
      this.rows = boardData;
    } else {
      this.columns = boardData.columns;
      this.rows = boardData.rows;
      this.totals = boardData.totals;
    }
  }
}

export class XpoAgGridBoardData extends XpoBoardData<XpoAgGridBoardDatum> {
  constructor(data: XpoBoardData) {
    super(data.state, new XpoAgGridBoardDatum(data.consumerData || []), data.recordCount, data.pageSize);
  }
}
