import { Type } from '@angular/core';
import { Observable } from 'rxjs';

import { XpoFilterComponent } from './filter-component';

export interface XpoFilter {
  onOpen?(): void;
  onClose?(): void;
}

export abstract class XpoFilter {
  disabled$: Observable<boolean>;

  constructor(
    readonly field: string,
    readonly label: string,
    readonly filterComponentType: Type<XpoFilterComponent>,
    readonly type?: string
  ) {}

  /** Gets the value to be displayed on the UI, based on the current filter value */
  abstract getDisplayValue(val: any): string | Observable<string>;

  /** Gets the initial value of the filter, based on the current filter value */
  getInitialFilterValue(val: any): any {
    return val;
  }
}
