import {ShipmentVolumeMethodCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentVolumeMethodCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentVolumeMethodCd ) {
      this._values.push(ShipmentVolumeMethodCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentVolumeMethodCd {

    for ( const obj in ShipmentVolumeMethodCd ) {
      if (ShipmentVolumeMethodCd[obj] === value){
        return ShipmentVolumeMethodCd[obj] as ShipmentVolumeMethodCd;
      }
    }
  }
}

const ShipmentVolumeMethodCdHelper = new ShipmentVolumeMethodCdHelperEnumHelperClass();
export default ShipmentVolumeMethodCdHelper;
