import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { XpoApplicationCategory } from '../../../models/application-category.model';
import { APPLICATION_CATEGORY_ICONS } from './icons/application-category-icons.const';

/**
 * The component for the Application Category data
 */
@Component({
  selector: 'xpo-application-category',
  templateUrl: './application-category.component.html',
  styleUrls: ['./application-category.component.scss'],
  host: {
    class: 'xpo-ApplicationCategory',
  },
})
export class XpoApplicationCategoryComponent {
  /**
   * The icons registered for each of the categories
   * Used to avoid registering the same icon twice
   * */
  protected static readonly registeredICons: string[] = [];

  /** The data for the Application Category */
  @Input()
  set applicationCategory(category: XpoApplicationCategory) {
    // keep the instance
    this.appCategoryValue = category;

    // if the category has a defined icon
    if (this.appCategoryValue.icon) {
      // register the category icon
      this.registerIcon(this.appCategoryValue.icon);
    }

    // sort application list
    this.appCategoryValue.applications = this.appCategoryValue.applications.sort((prevApp, currApp) =>
      prevApp.name > currApp.name ? 1 : -1
    );
  }
  get applicationCategory(): XpoApplicationCategory {
    return this.appCategoryValue;
  }

  /** The instance of the Application Category */
  protected appCategoryValue: XpoApplicationCategory;

  constructor(protected matIconRegistry: MatIconRegistry, protected domSanitizer: DomSanitizer) {}

  showIcon(): boolean {
    return (
      this.applicationCategory.icon &&
      XpoApplicationCategoryComponent.registeredICons.indexOf(this.applicationCategory.icon) !== -1
    );
  }

  /**
   * Registers the icon for the category
   * @param name
   */
  protected registerIcon(name: string): void {
    // get the icon svg source
    const svg = this.getIconSvg(name);

    // check if an icon with the same name was already registered
    if (svg && XpoApplicationCategoryComponent.registeredICons.indexOf(name) === -1) {
      this.matIconRegistry.addSvgIconLiteral(name, this.domSanitizer.bypassSecurityTrustHtml(svg));

      // add the icon to the registered ones
      XpoApplicationCategoryComponent.registeredICons.push(name);
    }
  }

  protected getIconSvg(name: string): string {
    return APPLICATION_CATEGORY_ICONS.find((icon) => icon.name === name)?.svg;
  }
}
