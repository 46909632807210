import { Observable } from 'rxjs';

import { OptionsResolver } from '../models/options-resolver.model';
import { XpoSelectFilter } from './select-filter';

export class XpoMultiSelectFilter extends XpoSelectFilter {
  constructor(field: string, label: string, options: any[] | OptionsResolver | Observable<any[]> | Promise<any[]>) {
    super({ field, label, options });

    console.warn('WARN: `XpoMultiSelectFilter` is deprecated, use `XpoSelectFilter` instead');
  }
}
