/**
 * Takes two date time formatted strings and figures out which one comes first
 *
 * @param a string formatted such that they can be string compared ie YYYY-MM-DD
 * @param b string formatted such that they can be string compared ie YYYY-MM-DD
 *
 * @return Object keyed from, to with the smaller date time in from and larger in to
 */
export function ordersMinMaxDate(a: string, b: string): { from: string; to: string } {
  if (a >= b) {
    return { from: b, to: a };
  }
  return { from: a, to: b };
}
