import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { take } from 'rxjs/operators';

import { XpoAuthenticationService } from './authentication.service';

/**
 * Guard that prevents user from access route if they are not authenticated.
 * If not authenticated, will start the authentication process and login the user.
 */
@Injectable()
export class XpoAuthenticationGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(private authenticationService: XpoAuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isAuthenticated(state.url);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isAuthenticated(state.url);
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.isAuthenticated(route.path); // TODO: maybe it should be path from root?
  }

  private isAuthenticated(returnRoute: string): boolean {
    if (this.authenticationService.isLoggedIn()) {
      return true;
    }

    this.authenticationService
      .startSignIn$(returnRoute)
      .pipe(take(1))
      .subscribe();

    return false;
  }
}
