import {ProRequestDetailStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ProRequestDetailStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ProRequestDetailStatusCd ) {
      this._values.push(ProRequestDetailStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ProRequestDetailStatusCd {

    for ( const obj in ProRequestDetailStatusCd ) {
      if (ProRequestDetailStatusCd[obj] === value){
        return ProRequestDetailStatusCd[obj] as ProRequestDetailStatusCd;
      }
    }
  }
}

const ProRequestDetailStatusCdHelper = new ProRequestDetailStatusCdHelperEnumHelperClass();
export default ProRequestDetailStatusCdHelper;
