import {RebuttalInternalStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class RebuttalInternalStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RebuttalInternalStatusCd ) {
      this._values.push(RebuttalInternalStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RebuttalInternalStatusCd {

    for ( const obj in RebuttalInternalStatusCd ) {
      if (RebuttalInternalStatusCd[obj] === value){
        return RebuttalInternalStatusCd[obj] as RebuttalInternalStatusCd;
      }
    }
  }
}

const RebuttalInternalStatusCdHelper = new RebuttalInternalStatusCdHelperEnumHelperClass();
export default RebuttalInternalStatusCdHelper;
