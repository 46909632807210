import {CustomerMatchRequestTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerMatchRequestTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerMatchRequestTypeCd ) {
      this._values.push(CustomerMatchRequestTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerMatchRequestTypeCd {

    for ( const obj in CustomerMatchRequestTypeCd ) {
      if (CustomerMatchRequestTypeCd[obj] === value){
        return CustomerMatchRequestTypeCd[obj] as CustomerMatchRequestTypeCd;
      }
    }
  }
}

const CustomerMatchRequestTypeCdHelper = new CustomerMatchRequestTypeCdHelperEnumHelperClass();
export default CustomerMatchRequestTypeCdHelper;
