import {MessagePriorityCd} from '../';
import {EnumHelper} from './enum-helper';

export class MessagePriorityCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MessagePriorityCd ) {
      this._values.push(MessagePriorityCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MessagePriorityCd {

    for ( const obj in MessagePriorityCd ) {
      if (MessagePriorityCd[obj] === value){
        return MessagePriorityCd[obj] as MessagePriorityCd;
      }
    }
  }
}

const MessagePriorityCdHelper = new MessagePriorityCdHelperEnumHelperClass();
export default MessagePriorityCdHelper;
