import { Component, Input } from '@angular/core';
import { XpoApplicationCategory } from '../../models/application-category.model';

/**
 * The component that holds the application categories
 */
@Component({
  selector: 'xpo-application-switcher-content',
  templateUrl: './application-switcher-content.component.html',
  styleUrls: ['./application-switcher-content.component.scss'],
  host: {
    class: 'xpo-ApplicationSwitcherContent',
  },
})
export class XpoApplicationSwitcherContentComponent {
  /** The list of categories  */
  @Input() applicationCategories: XpoApplicationCategory[];
}
