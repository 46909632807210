import { Observable, ReplaySubject, Subscription } from 'rxjs';

import { XpoBoardDataFetchState } from './board-data-fetch-state.model';
import { XpoBoardDataFetcher } from './board-data-fetcher.model';
import { XpoBoardData } from './board-data.model';
import { XpoBoardStateViewer } from './board-state-viewer.model';
import { XpoBoardState } from './board-state.model';

export abstract class XpoBoardDataSource<T extends XpoBoardData = XpoBoardData> {
  // Keys XpoBoardState that are send but not cached on the stateCache
  private static NonCachedKeys: Array<keyof XpoBoardState> = ['context'];

  pageSize: number = 50;

  // caches properties between requests so they can be replied on view change events
  protected stateCache: XpoBoardState = { source: 'UNKNOWN' };

  /**
   * Stores reference to any subscriptions, that were connected to via the connect method.
   * Any subscriptions in here will be disconnected when `disconnect` is called
   */
  protected subscriptions = new Map<XpoBoardStateViewer, Subscription>();
  stateVersion = 0;

  private stateSource$ = new ReplaySubject<XpoBoardState>(1);

  constructor() {
    this.connect(new XpoBoardDataFetcher<T>(this.stateSource$, this.fetchData.bind(this)));
  }

  /**
   * Clears values from the state cache that we dont want to be repeated back on next fetch
   */
  private static clearNonCacheValuesFromState(newState: XpoBoardState): XpoBoardState {
    XpoBoardDataSource.NonCachedKeys.filter((key) => !newState.changes.includes(key)).forEach(
      (key) => delete newState[key]
    );
    return newState;
  }

  /**
   * Connects a collection viewer (such as a data-table) to this data source. Note that
   * the stream provided will be accessed during change detection and should not directly change
   * values that are bound in template views.
   *
   * @param boardDataViewer The component that exposes a view over the data provided by this
   *     data source.
   * @returns XpoBoardDataSourceConnections object that provides access too the various data-sources
   */
  connect(stateViewer: XpoBoardStateViewer): Observable<XpoBoardState> {
    const subscription = this.attachToViewChange(<XpoBoardStateViewer>stateViewer);

    this.subscriptions.set(stateViewer, subscription);

    return this.stateSource$;
  }

  /**
   * Disconnects a collection viewer (such as a data-table) from this data source. Can be used
   * to perform any clean-up or tear-down operations when a view is being destroyed.
   *
   * @param boardDataViewer The component that exposes a view over the data provided by this
   *     data source.
   */
  disconnect(boardDataViewer: XpoBoardStateViewer): void {
    const sub = this.subscriptions.get(boardDataViewer);

    if (!sub) {
      sub.unsubscribe();
      this.subscriptions.delete(boardDataViewer);
    }
  }

  /**
   * Refreshes the data in the grid.  The only mutation that will occur is the grid will go to page one.
   * If there is not a currently active view, the refresh will not occur
   */
  refresh(): void {
    // don't allow a refresh without an active view, this might occur if an outside event
    // causes a refresh of the board before it has finished displaying its first view
    if (this.stateCache && this.stateCache.viewId) {
      this.setState({ pageNumber: 1, source: 'DATA SOURCE REFRESH' });
    }
  }

  /**
   * Issues a request for the data, used for background loading of data and loading data for the current view.
   */
  abstract fetchData(state: XpoBoardState): Observable<T>;

  protected get state$(): Observable<XpoBoardState> {
    return this.stateSource$.asObservable();
  }

  protected attachToViewChange(viewer: XpoBoardStateViewer): Subscription {
    // only process the last request from a viewChange
    // TODO: do we need aggregate the data from all XpoBoardStateViewers into a single stream and only collect the last
    // ajax request and cancel the last one?
    return viewer.stateChange$.subscribe(
      (incomingState) => {
        this.setState(incomingState);
      },
      (err) => {
        this.stateSource$.error(err);
        console.error(err.error);
      }
    );
  }

  /**
   * Broadcasted out state changes, the incoming state change will be combined
   * with the previous state change and broadcast out
   */
  protected setState(incomingState: XpoBoardState): void {
    this.stateCache = this.rebuildState(incomingState);
    this.stateVersion++;
    this.stateCache.stateVersion = this.stateVersion;
    this.stateSource$.next(this.stateCache);
  }

  private rebuildState(state: XpoBoardState): XpoBoardState {
    const newState = XpoBoardDataSource.clearNonCacheValuesFromState({
      ...this.stateCache,
      ...state,
      changes: Object.keys(state),
    });

    return this.evalFetchStatus(newState);
  }

  private evalFetchStatus(newState: XpoBoardState): XpoBoardState {
    if (
      XpoBoardDataFetcher.isFetchRequired(newState, this.stateCache) &&
      newState.dataFetchState !== XpoBoardDataFetchState.Loading
    ) {
      newState.dataFetchState = XpoBoardDataFetchState.Loading;
      newState.changes.push('dataFetchState');
    } else if (newState.changes.includes('visibleRows')) {
      if (newState.visibleRows === 0) {
        newState.dataFetchState = XpoBoardDataFetchState.NoResults;
      } else {
        newState.dataFetchState = XpoBoardDataFetchState.ResultsReturned;
      }
      newState.changes.push('dataFetchState');
    }

    return newState;
  }
}
