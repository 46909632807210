import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, share, take } from 'rxjs/operators';

import { XpoBoardViewConfig } from '../../../board-views/index';
import { XpoBoardState, XpoBoardView } from '../../../models/index';

export enum XpoSaveViewDialogPurpose {
  Save = 'Save',
  Edit = 'Edit',
}

export interface XpoSaveViewDialogData {
  state: XpoBoardState;
  purpose: XpoSaveViewDialogPurpose;
  saveView: (viewConfig: XpoBoardViewConfig, saveAs: boolean) => Observable<XpoBoardState>;
  view: XpoBoardView;
}

@Component({
  selector: 'xpo-save-view-dialog',
  templateUrl: './save-view-dialog.component.html',
  styleUrls: ['./save-view-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-SaveViewDialog' },
})
export class XpoSaveViewDialog implements OnInit {
  viewName: string;
  purpose: XpoSaveViewDialogPurpose;
  dialogTitle: string = '';
  submitting$: Observable<boolean>;

  private submittingSource$ = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<XpoSaveViewDialog>,
    @Inject(MAT_DIALOG_DATA) private data: XpoSaveViewDialogData
  ) {
    this.submitting$ = this.submittingSource$.asObservable().pipe(share());
  }

  ngOnInit(): void {
    this.viewName = this.data.view.name;
    this.purpose = this.data.purpose || this.getDialogPurpose();
    this.dialogTitle = this.purpose === XpoSaveViewDialogPurpose.Edit ? 'Edit View' : 'Save View';
  }

  submitForm(): void {
    this.submittingSource$.next(true);

    const state = this.data.state;
    const viewToSave = this.data.view;
    // previously saved views or system defined views should save as a new view
    const isSaveAs =
      this.purpose === XpoSaveViewDialogPurpose.Save ? this.hasViewBeenSaved() || viewToSave.systemDefined : false;
    // if saveAs generate a new view
    const viewId = isSaveAs ? new Date().getTime().toString() : viewToSave.id;
    this.data
      .saveView(
        {
          ...viewToSave.toViewConfig(state),
          id: viewId,
          lastSaved: new Date(),
          name: this.viewName,
          systemDefined: false, // if this view is coming from a system defined view, ensure that this value is false
          closeable: true,
        },
        isSaveAs
      )
      .pipe(
        take(1),
        finalize(() => this.submittingSource$.next(false))
      )
      .subscribe((newState) => {
        this.dialogRef.close(newState);
      });
  }

  private getDialogPurpose(): XpoSaveViewDialogPurpose {
    return this.hasViewBeenSaved() ? XpoSaveViewDialogPurpose.Edit : XpoSaveViewDialogPurpose.Save;
  }

  /**
   * Checks if the entered view was saved before, if there is no entered view,
   * it checks if the active view was saved before
   */
  private hasViewBeenSaved(): boolean {
    return !!this.data.view.lastSaved;
  }
}
