import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { XpoCheckboxGroupHorizontal } from './checkbox-group/checkbox-group-horizontal.directive';
import { XpoCheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
@NgModule({
  declarations: [XpoCheckboxGroupComponent, XpoCheckboxGroupHorizontal],
  imports: [CommonModule, MatCheckboxModule, MatFormFieldModule],
  exports: [XpoCheckboxGroupComponent, XpoCheckboxGroupHorizontal],
})
export class XpoCheckboxModule {}
