import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';


import { UUID } from 'angular2-uuid';

import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { LoggingConstants } from './logging-constants';
import { IHttpLoggingConfig } from './logging-config';
import { Subject } from 'rxjs';
import { Header, InsertLogRqst, Log, Body } from './api-logging';
import { LogLevel } from '@xpo-ltl/sdk-common';


@Injectable({ providedIn: 'root' })
export class LoggingApiService {

  private insertLogRqst: InsertLogRqst;
  public readonly subject = new Subject<InsertLogRqst>();
  private logMap: Object = {};
  private configured = false;
  private unsubscribe = new Subject<void>();
  private buildVersion: string;

  constructor(private configManager: ConfigManagerService) {
    this.insertLogRqst = new InsertLogRqst();
    this.insertLogRqst.Log = new Log();
    this.configManager.configured$.pipe(takeUntil(this.unsubscribe)).subscribe(
      settings => {
        if (settings) {
          this.initHeader (settings);
          this.buildVersion = settings.buildVersion;
        }
      }
    );

    this.initBody();

    this.logMap[LogLevel.DEBUG] = 1;
    this.logMap[LogLevel.INFO] = 2;
    this.logMap[LogLevel.WARN] = 3;
    this.logMap[LogLevel.ERROR] = 4;
    this.logMap[LogLevel.FATAL] = 5;

  }
  public setContext(contextName: string, value: string) {
    if (!this.insertLogRqst.Log.hdr.domainAttrbs) {
       this.insertLogRqst.Log.hdr.domainAttrbs = {data: {}};
    }
    this.insertLogRqst.Log.hdr.domainAttrbs.data[contextName] = value;
  }
  public clearContext(contextName: string) {
    if (this.insertLogRqst.Log.hdr.domainAttrbs) {
      delete this.insertLogRqst.Log.hdr.domainAttrbs.data[contextName];
    }
  }

  public setUserContext(userName: string, browser: string) {
    if (!this.insertLogRqst.Log.hdr.domainAttrbs) {
      this.insertLogRqst.Log.hdr.domainAttrbs = {data: {}};
    }
    this.insertLogRqst.Log.hdr.domainAttrbs.data['userId'] = userName;
    this.insertLogRqst.Log.hdr.domainAttrbs.data['appVersion'] = this.buildVersion;
    this.insertLogRqst.Log.hdr.domainAttrbs.data['browserInfo'] = browser;
  }

  private initHeader(settings: IHttpLoggingConfig) {
    if (settings) {
      this.insertLogRqst.Log.hdr = new Header();
      this.insertLogRqst.Log.hdr.domainAttrbs = {data: {}};
      this.insertLogRqst.Log.hdr.org = settings.logOrganization;
      this.insertLogRqst.Log.hdr.layer = settings.logLayer;
      this.insertLogRqst.Log.hdr.appName = settings.logAppName;
      this.insertLogRqst.Log.hdr.projectName = settings.logProjectName;
      this.insertLogRqst.Log.hdr.appInstanceName = UUID.UUID();
      this.configured = true;
      this.unsubscribe.next();
      this.unsubscribe.complete();
      this.unsubscribe = null;
    }
  }

  private initBody() {
    this.insertLogRqst.Log.bdy = new Body();
  }

  public logHttpStats(activityName: string, transactionType: string, httpResponse: number, requestPath: string,
                      httpMethod: string, elapsedTimeInMilliseconds: number, correlationId?: string, message?: string) {
    if (!this.configured) {
      // TODO: WE COULD QUEUE.  FOR NOW JUST CONSOLE LOG
      console.log('Logging not yet configured.  Skipping log statement');
      return;
    }
    if (this.configManager.getSetting<boolean>('logServicePerformance')) {
      const logRequest = _.cloneDeep(this.insertLogRqst);
      logRequest.Log.hdr.env = this.configManager.getSetting<string>('region');
      logRequest.Log.hdr.returnCode = `${httpResponse}`;
      logRequest.Log.hdr.eventType = httpMethod;
      logRequest.Log.hdr.tranType = transactionType;
      logRequest.Log.hdr.activityName = activityName;
      logRequest.Log.hdr.shortContext = requestPath;
      logRequest.Log.bdy.processInfo = {data: {elapsedTime: elapsedTimeInMilliseconds, messageType: LoggingConstants.MESSAGE_TYPE}};

      if (correlationId) {
        logRequest.Log.hdr.correlationId = correlationId;
      }
      if (message) {
        logRequest.Log.bdy.detailedContext = message;
      }
      logRequest.Log.hdr.logLevel = LogLevel.INFO;
      logRequest.Log.hdr.logTimestamp = new Date();
      this.subject.next(logRequest);
    }
  }

  public logHttpRequestBody(body: any, action: string, activityName: string, transactionType: string,
                                    requestPath: string, httpMethod: string, logLevel: LogLevel, correlationId?: string) {
    if (!this.configured) {
      // TODO: WE COULD QUEUE.  FOR NOW JUST CONSOLE LOG
      console.log('Logging not yet configured.  Skipping log statement');
      return;
    }

    const logRequest = _.cloneDeep(this.insertLogRqst);
    logRequest.Log.hdr.env = this.configManager.getSetting<string>('region');
    logRequest.Log.hdr.tranType = transactionType;
    logRequest.Log.hdr.eventType = httpMethod;
    logRequest.Log.hdr.activityName = activityName;
    logRequest.Log.hdr.shortContext = requestPath;
    logRequest.Log.hdr.action = action;
    if (correlationId) {
      logRequest.Log.hdr.correlationId = correlationId;
    }
    logRequest.Log.bdy.detailedContext = JSON.stringify(body);
    logRequest.Log.bdy.processInfo = {data: {messageType: LoggingConstants.MESSAGE_TYPE}};
    logRequest.Log.hdr.logLevel = logLevel;
    logRequest.Log.hdr.logTimestamp = new Date();
    this.subject.next(logRequest);
  }

  public logHttpResponseBody(body: any, action: string, activityName: string, transactionType: string,
                             requestPath: string, httpMethod: string, httpResponse: number,
                             elapsedTimeInMilliseconds: number, logLevel: LogLevel, correlationId?: string) {
    if (!this.configured) {
      // TODO: WE COULD QUEUE.  FOR NOW JUST CONSOLE LOG
      console.log('Logging not yet configured.  Skipping log statement');
      return;
    }

    const logRequest = _.cloneDeep(this.insertLogRqst);
    logRequest.Log.hdr.env = this.configManager.getSetting<string>('region');
    logRequest.Log.hdr.returnCode = `${httpResponse}`;
    logRequest.Log.hdr.tranType = transactionType;
    logRequest.Log.hdr.eventType = httpMethod;
    logRequest.Log.hdr.activityName = activityName;
    logRequest.Log.hdr.shortContext = requestPath;
    logRequest.Log.hdr.action = action;
    if (correlationId) {
      logRequest.Log.hdr.correlationId = correlationId;
    }
    logRequest.Log.bdy.detailedContext = JSON.stringify(body);
    logRequest.Log.bdy.processInfo = {
      data: {
        elapsedTime: elapsedTimeInMilliseconds,
        messageType: LoggingConstants.MESSAGE_TYPE
      }
    };
    logRequest.Log.hdr.logLevel = logLevel;
    logRequest.Log.hdr.logTimestamp = new Date();
    this.subject.next(logRequest);
  }

  public logAppNavigation(url: string, elapsedTimeInMilliseconds: number, activityName: string) {
    if (!this.configured) {
      // TODO: WE COULD QUEUE.  FOR NOW JUST CONSOLE LOG
      console.log('Logging not yet configured.  Skipping log statement');
      return;
    }

    if (this.configManager.getSetting<boolean>('logServicePerformance')) {
      const logRequest = _.cloneDeep(this.insertLogRqst);
      logRequest.Log.hdr.env = this.configManager.getSetting<string>('region');
      logRequest.Log.hdr.tranType = url;
      logRequest.Log.hdr.activityName = activityName;
      logRequest.Log.hdr.shortContext = url;
      logRequest.Log.bdy.processInfo = {data: {elapsedTime: elapsedTimeInMilliseconds}};
      logRequest.Log.hdr.logLevel = LogLevel.INFO;
      logRequest.Log.hdr.logTimestamp = new Date();
      logRequest.Log.hdr.projectName = this.configManager.getSetting<string>('logProjectName');
      this.subject.next(logRequest);
    }
  }

  /**
   * Queues up a log request with the message details
   */
  public log(level: LogLevel, message: string, correlationId?: string, action?: string, activityName?: string, transactionType?: string) {
    if (!this.configured) {
      // TODO: WE COULD QUEUE.  FOR NOW JUST CONSOLE LOG
      console.log('Logging not yet configured.  Skipping log statement');
      return;
    }
    if (this.isLoggingEnabledFor(level)) {
      this.insertLogRqst.Log.hdr.env = this.configManager.getSetting<string>('region');
      const logRequest = _.cloneDeep(this.insertLogRqst);
      logRequest.Log.hdr.logLevel = level;
      logRequest.Log.hdr.logTimestamp = new Date();
      if (activityName) {
        logRequest.Log.hdr.activityName = activityName;
      }
      if (action) {
        logRequest.Log.hdr.action = action;
      }
      if (transactionType) {
        logRequest.Log.hdr.tranType = transactionType;
      }
      if (correlationId) {
        logRequest.Log.hdr.correlationId = correlationId;
      }
      logRequest.Log.bdy.detailedContext = message;
      this.subject.next(logRequest);
    }
  }

  public debug(message: string, correlationId?: string, action?: string, activityName?: string, transactionType?: string) {
    this.log(LogLevel.DEBUG, message, correlationId, action, activityName, transactionType);
  }

  public info(message: string, correlationId?: string, action?: string, activityName?: string, transactionType?: string) {
    this.log(LogLevel.INFO, message, correlationId, action, activityName, transactionType);
  }

  public warn(message: string, correlationId?: string, action?: string, activityName?: string, transactionType?: string) {
    this.log(LogLevel.WARN, message, correlationId, action, activityName, transactionType);
  }

  public error(message: string, correlationId?: string, action?: string, activityName?: string, transactionType?: string) {
    this.log(LogLevel.ERROR, message, correlationId, action, activityName, transactionType);
  }

  public fatal(message: string, correlationId?: string, action?: string, activityName?: string, transactionType?: string) {
    this.log(LogLevel.FATAL, message, correlationId, action, activityName, transactionType);
  }

  public isLoggingEnabledFor(logLevel: LogLevel): boolean {
    return (<number>this.logMap[logLevel]) >= (<number>this.logMap[this.configManager.getSetting<string>('esLogLevel')]);
  }


}
