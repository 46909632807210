// Parts taken from https://github.com/georgipeltekov/ngx-file-drop/blob/master/src/lib/ngx-drop/file-drop.component.ts

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

import { Subscription, timer } from 'rxjs';

import { FileSystemDirectoryEntry, FileSystemEntry, FileSystemFileEntry } from './dom.types';
import { UploadFile } from './upload-file';

export class UploadEvent {
  constructor(public files: UploadFile[]) {}
}

@Component({
  selector: 'xpo-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-DragAndDrop' },
})
export class XpoDragAndDrop implements OnDestroy {
  stack = [];
  files: UploadFile[] = [];
  fileUploadCheckTimerSubscription: Subscription;
  dragoverflag: boolean = false;

  globalDisable: boolean = false;
  globalStart: Function;
  globalEnd: Function;

  numOfActiveReadEntries = 0;

  @Input()
  disabled: boolean = false;

  @Input()
  errorFlag: boolean = false;

  @Output()
  fileDrop = new EventEmitter<UploadEvent>();
  @Output()
  fileOver = new EventEmitter<any>();
  @Output()
  fileLeave = new EventEmitter<any>();

  constructor(private renderer: Renderer2) {
    this.globalStart = this.renderer.listen('document', 'dragstart', () => {
      this.globalDisable = true;
    });
    this.globalEnd = this.renderer.listen('document', 'dragend', () => {
      this.globalDisable = false;
    });
  }

  ngOnDestroy(): void {
    this.clearFileUploadCheckTimerSubscription();
    this.globalStart();
    this.globalEnd();
  }

  onDragOver(event: Event): void {
    if (!this.globalDisable && !this.disabled) {
      if (!this.dragoverflag) {
        this.dragoverflag = true;
        this.fileOver.emit(event);
      }
    }

    this.preventAndStop(event);
  }

  onDragLeave(event: Event): void {
    if (!this.globalDisable && !this.disabled) {
      if (this.dragoverflag) {
        this.dragoverflag = false;
        this.fileLeave.emit(event);
      }
      this.preventAndStop(event);
    }
  }

  dropFiles(event: any): void {
    this.preventAndStop(event);

    if (!this.globalDisable && !this.disabled) {
      this.dragoverflag = false;
      event.dataTransfer.dropEffect = 'copy';
      let length;

      if (event.dataTransfer.items) {
        length = event.dataTransfer.items.length;
      } else {
        length = event.dataTransfer.files.length;
      }

      for (let i = 0; i < length; i++) {
        let entry: FileSystemEntry;

        if (event.dataTransfer.files) {
          if (event.dataTransfer.files[i].webkitGetAsEntry) {
            entry = event.dataTransfer.files[i].webkitGetAsEntry();
          }
        } else {
          if (event.dataTransfer.items[i].webkitGetAsEntry) {
            entry = event.dataTransfer.files[i].webkitGetAsEntry();
          }
        }

        if (!entry) {
          const file: File = event.dataTransfer.files[i];

          if (file) {
            const fakeFileEntry: FileSystemFileEntry = {
              name: file.name,
              isDirectory: false,
              isFile: true,
              file: file,
            };
            const toUploadFake: UploadFile = new UploadFile(fakeFileEntry.name, fakeFileEntry);
            this.addToQueue(toUploadFake);
          }
        } else {
          if (entry.isFile) {
            const toUpload: UploadFile = new UploadFile(entry.name, entry);
            this.addToQueue(toUpload);
          } else if (entry.isDirectory) {
            this.traverseFileTree(entry, entry.name);
          }
        }
      }

      // Check too see if all files are uploaded, then emit that the files have been uploaded
      const timerObservable = timer(200, 200);
      this.fileUploadCheckTimerSubscription = timerObservable.subscribe(() => {
        if (this.files.length > 0 && this.numOfActiveReadEntries === 0) {
          this.fileDrop.emit(new UploadEvent(this.files));
          this.files = [];
        }
      });
    }
  }

  pushToStack(str): void {
    this.stack.push(str);
  }

  popToStack(): void {
    this.stack.pop();
  }

  private traverseFileTree(item: FileSystemEntry, path: string): void {
    if (item.isFile) {
      const toUpload: UploadFile = new UploadFile(path, item);
      this.files.push(toUpload);
      this.popToStack();
    } else {
      this.pushToStack(path);
      path = path + '/';
      const dirReader = (item as FileSystemDirectoryEntry).createReader();
      let entries = [];

      const readEntries = () => {
        this.numOfActiveReadEntries++;

        dirReader.readEntries(function (res): void {
          if (!res.length) {
            // add empty folders
            if (entries.length === 0) {
              const entryToUpload: UploadFile = new UploadFile(path, item);
              this.addToQueue(entryToUpload);
            } else {
              for (let i = 0; i < entries.length; i++) {
                this.traverseFileTree(entries[i], path + entries[i].name);
              }
            }

            this.popToStack();
          } else {
            // continue with the reading
            entries = entries.concat(res);
            readEntries();
          }
          this.numOfActiveReadEntries--;
        });
      };

      readEntries();
    }
  }

  private addToQueue(item: UploadFile): void {
    this.files.push(item);
  }

  private clearQueue(): void {
    this.files = [];
  }

  private preventAndStop(event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  private clearFileUploadCheckTimerSubscription(): void {
    if (this.fileUploadCheckTimerSubscription) {
      this.fileUploadCheckTimerSubscription.unsubscribe();
      this.fileUploadCheckTimerSubscription = undefined;
    }
  }
}
