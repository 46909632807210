import { Component } from '@angular/core';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { filterFormats, Operators, OperatorText } from './../../../../../models/index';

@Component({
  template: ' ',
})
export class XpoConditionBooleanFalseComponent extends XpoConditionBase implements XpoFilterCondition {
  static text = OperatorText.False;
  static applyAlwaysEnabled: boolean = true;
  static operator = Operators.NotTrue;

  isNegative = false; // not intuitive but this is actually just metadata
  model: null = null;

  constructor() {
    super(filterFormats.default, false);
  }

  validate(cellValue): boolean {
    return cellValue === false;
  }

  getCriteria(): any {
    return {
      operator: Operators.NotTrue,
      value: false,
      display: ' ',
    };
  }
}
