import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';

import { XpoFormFieldInline } from './directives/xpo-form-field-inline.directive';
import { XpoLabelWrap } from './directives/xpo-label-wrap.directive';

@NgModule({
  declarations: [XpoLabelWrap, XpoFormFieldInline],
  exports: [XpoLabelWrap, XpoFormFieldInline, MatFormFieldModule],
})
export class XpoFormFieldModule {}
