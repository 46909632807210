import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'xpo-responsive-loading-spinner',
  templateUrl: './responsive-loading-spinner.component.html',
  styleUrls: ['./responsive-loading-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-ResponsiveLoadingSpinner' },
})
export class XpoResponsiveLoadingSpinner {
  // default spinner diameter from angular material.
  @Input()
  diameter: number = 100;
}
