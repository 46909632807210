import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

import { XpoErrorPage } from './error-page';

@NgModule({
  declarations: [XpoErrorPage],
  imports: [CommonModule, RouterModule, MatButtonModule],
  exports: [XpoErrorPage],
})
export class XpoErrorPageModule {}
