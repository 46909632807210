import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { BrowserGuardDialogComponent } from './browser-guard-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [BrowserGuardDialogComponent],
  imports: [CommonModule, MatIconModule, MatDialogModule],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
  ],
  entryComponents: [BrowserGuardDialogComponent],
})
export class XpoBrowserGuardModule {}
