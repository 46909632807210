import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	DeleteLocationFeatureSettingsQuery,
	DetermineNonWorkDayResp,
	DetermineNonWorkDayPath,
	DetermineOperationalServiceDateResp,
	DetermineOperationalServiceDateQuery,
	DetermineOperationalServiceDatePath,
	GetGeoAreasForLocationResp,
	GetGeoAreasForLocationQuery,
	GetGeoAreasForLocationPath,
	GetHostSicDetailsResp,
	GetHostSicDetailsPath,
	GetLocationReferenceDetailsResp,
	GetLocationReferenceDetailsQuery,
	GetLocHierarchyGroupResp,
	GetLocHierarchyGroupQuery,
	GetLocOperationsServiceCenterProfitabilityBySicResp,
	GetLocOperationsServiceCenterProfitabilityBySicPath,
	GetLocReferenceDetailsBySicResp,
	GetLocReferenceDetailsBySicQuery,
	GetLocReferenceDetailsBySicPath,
	GetOffshorePortBySicResp,
	GetOffshorePortBySicPath,
	GetOperationalServiceDaysCountResp,
	GetOperationalServiceDaysCountQuery,
	GetOperationalServiceDaysCountPath,
	GetNextBusinessDayResp,
	GetNextBusinessDayQuery,
	GetNextBusinessDayPath,
	GetRefSicAddressResp,
	GetRefSicAddressPath,
	GetRefSicHierarchyGroupNamesResp,
	GetRefSicHierarchyGroupNamesQuery,
	GetSectorByDoorNumberResp,
	GetSectorByDoorNumberPath,
	GetServiceCenterDetailsBySicResp,
	GetServiceCenterDetailsBySicPath,
	GetSicForPostalCodesResp,
	GetSicForPostalCodesQuery,
	GetZoneAndSatelliteBySicResp,
	GetZoneAndSatelliteBySicQuery,
	GetZoneAndSatelliteBySicPath,
	ListAllActiveDoorsBySicResp,
	ListAllActiveDoorsBySicQuery,
	ListAllActiveDoorsBySicPath,
	ListAllActiveSicsResp,
	ListAllSicCdsByRegionResp,
	ListAllSicCdsByRegionPath,
	ListAreaAssignmentsResp,
	ListAreaAssignmentsQuery,
	ListEligibleServicesByPostalSicRqst,
	ListEligibleServicesByPostalSicResp,
	ListGeoAreasResp,
	ListGeoAreasQuery,
	ListGeoAreasPath,
	ListLocationFeaturesResp,
	ListLocationFeaturesQuery,
	ListLocationFeatureValuesByFeatureResp,
	ListLocationFeatureValuesByFeatureQuery,
	ListLocationFeatureValuesByFeaturePath,
	ListLocationGroupCodesResp,
	ListLocationGroupCodesQuery,
	ListLocationsRqst,
	ListLocationsResp,
	ListLocationsQuery,
	ListOperationalCalendarDaysResp,
	ListOperationalCalendarDaysQuery,
	ListOperationalHierarchyResp,
	ListOperationalHierarchyQuery,
	ListOperationalRegionsResp,
	ListOperationalRegionsQuery,
	ListOperationalServiceCentersResp,
	ListOperationalServiceCentersQuery,
	UpsertLocationFeatureSettingRqst,
	UpsertLocationFeatureSettingResp,
	DeleteExpiredMessagesResp,
	DeleteExpiredMessagesQuery,
	DeleteExpiredMessagesPath,
	GetEnsembleStatusCountsResp,
	GetEnsembleStatusCountsPath,
	GetQueuePendingCountResp,
	GetQueuePendingCountPath,
	KillEnsembleRqst,
	KillEnsembleResp,
	ListExpiredMessagesResp,
	ListExpiredMessagesPath,
	ListQueuesResp,
	ReplayMessagePath,
	RestartEnsembleRqst,
	RestartEnsembleResp,
	RestartEnsemblePath,
	ResumeEnsembleRqst,
	ResumeEnsembleResp,
	ResumeEnsemblePath,
	StartQueuePath,
	StopQueuePath,
	StopQueueWebhookPath,
	StopQueueWebhookQuery,
	ExportEnsembleStateUnfilteredRqst,
	SearchEnsembleStateUnfilteredRqst,
	SearchEnsembleStateUnfilteredResp,
	ChangesFeedEnsembleStateUnfilteredRqst,
	RegisterFilterEnsembleStateUnfilteredRqst,
	RegisterFilterEnsembleStateUnfilteredResp,
	UnregisterFilterEnsembleStateUnfilteredRqst,
	BulkUpdateElasticDocumentEnsembleStateResp,
	DeleteElasticDocumentEnsembleStateResp,
	DeleteElasticDocumentEnsembleStatePath,
	GetElasticDocumentEnsembleStateResp,
	GetElasticDocumentEnsembleStateQuery,
	GetElasticDocumentEnsembleStatePath,
	GetElasticDocumentEnsembleStateCountResp,
	GetElasticDocumentEnsembleStateCountQuery,
	UpdateElasticDocumentEnsembleStateRqst,
	UpdateElasticDocumentEnsembleStateResp,
	UpdateElasticDocumentEnsembleStatePath,
	ListEnsembleConfigurationsResp
} from './api-location';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class LocationApiService extends BaseService {
	private static LocationApiEndpoint = 'locationV2ApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation deletes existing Location Feature Setting(s) that have either been deprecated or are not needed.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid feature code and sicCd OR a list of instIds for existing Location Feature Setting(s) must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the Location Feature Setting is deleted.
	* <br/>2. Otherwise an illegal argument exception is thrown, such as a 404 - Not Found if the feature code is not found.  NoResultException should be handled with a NotFoundException from the jaxrs.
	*/
	public deleteLocationFeatureSettings(
							   queryParams: DeleteLocationFeatureSettingsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/location-feature-settings'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation determines if the requested date is a non-work day, as well as its type, for the location provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid sicCd and date must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the date provided is a non-work day, then its type will be set in nonWorkDayTypeCd.  The description for the non-work day along with an indicator for whether Linehaul runs on that day will also be returned.
	* <br/>2. If the date provided isn't a non-work day, then nonWorkDayTypeCd will be null.
	* <br/>3. Otherwise an appropriate error message will be returned.
	*/
	public determineNonWorkDay(
							   pathParams: DetermineNonWorkDayPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DetermineNonWorkDayResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/calendar-dates/{date}/non-work-day'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation determines the operational service date for a lane (origin to destination) based on the calendar date and offset provided.
	* <br/>	When not provided, calendar date defaults to the current date and offset defaults to 0. Passing an offset of 0 allows for checking whether the calendar date is a service date.
	* <br/>	Passing findNextServiceDateInd allows for checking for the next service date from the offset of 0.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. A valid origin and destination SIC must be provided.
	* <br/>2. The calendar date defaults to the current date and offset defaults to 0 if they aren't provided.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. If successful, then the service date is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public determineOperationalServiceDate(
							   pathParams: DetermineOperationalServiceDatePath,
							   queryParams: DetermineOperationalServiceDateQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DetermineOperationalServiceDateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/origins/{originSicCd}/destinations/{destinationSicCd}/service-date'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns geo area information about a location. 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The SIC code and type code are required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If a geo area is found for the SIC and type provided, then information about it will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getGeoAreasForLocation(
							   pathParams: GetGeoAreasForLocationPath,
							   queryParams: GetGeoAreasForLocationQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetGeoAreasForLocationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/areas/{polygonTypeCd}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves the host SIC for the requested zone SIC. 
	* <br/>
	* <br/>Mandatory Criteria:
	* <br/>- Zone SIC
	* <br/>           
	*/
	public getHostSicDetails(
							   pathParams: GetHostSicDetailsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHostSicDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/host/{sicCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns location reference information for a SIC code or location instance ID.
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. A valid sicCd or instanceId is provided.
	* <br/>		
	* <br/>Post-conditions:
	* <br/>1. If successful, then the location reference details are returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getLocationReferenceDetails(
							   queryParams: GetLocationReferenceDetailsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLocationReferenceDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/loc-reference-details'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns location hierarchy group information for a SIC.
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. A valid sicCd and at least one hierarchyGroupCategoryCd must be provided.
	* <br/>		
	* <br/>Post-conditions:
	* <br/>1. If successful, then the location hierarchy group and location reference details are returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getLocHierarchyGroup(
							   queryParams: GetLocHierarchyGroupQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLocHierarchyGroupResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/hierarchy-group'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves the operations service center profitability for a SIC. 
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid zone SIC must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the location's operation service center profitability details are returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getLocOperationsServiceCenterProfitabilityBySic(
							   pathParams: GetLocOperationsServiceCenterProfitabilityBySicPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLocOperationsServiceCenterProfitabilityBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/operations-profitability'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* ************************************************************** This contract will be deprecated. **
	* <br/>** getLocationReferenceDetails should be used instead. **
	* <br/>************************************************************ 
	* <br/>Returns location reference information about a service center.
	*/
	public getLocReferenceDetailsBySic(
							   pathParams: GetLocReferenceDetailsBySicPath,
							   queryParams: GetLocReferenceDetailsBySicQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLocReferenceDetailsBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/loc-reference-sics/{sicCd}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves an offshore port by SIC.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid SIC must be provided.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. If successful, then the Offshore Port is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getOffshorePortBySic(
							   pathParams: GetOffshorePortBySicPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetOffshorePortBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{mainlandSicCd}/offshore-port'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation calculates the number of operational service days between the date the service started at the origin location to the date the service stopped at the destination location.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. A valid origin and destination SIC must be provided.
	* <br/>2. The start date defaults to the current date and the end date to the next day if they aren't provided.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. If successful, then the service days count is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getOperationalServiceDaysCount(
							   pathParams: GetOperationalServiceDaysCountPath,
							   queryParams: GetOperationalServiceDaysCountQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetOperationalServiceDaysCountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/origins/{originSicCd}/destinations/{destinationSicCd}/service-days-count'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation determines the next business day based on the start date and offset number of days. The number of offset days are added to the start date and the next business day is determined after adjusting for weekends or holidays.
	* <br/>
	* <br/>Pre-condition: 
	* <br/>1. The input data provided must be valid.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. If successful, the next business day is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getNextBusinessDay(
							   pathParams: GetNextBusinessDayPath,
							   queryParams: GetNextBusinessDayQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetNextBusinessDayResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/next-business-day'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves the Loc Address record for the requested SIC.
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. A valid reference SIC is provided.
	* <br/>		
	* <br/>Post-conditions:
	* <br/>1. If successful, then the corresponding Loc Address record is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getRefSicAddress(
							   pathParams: GetRefSicAddressPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetRefSicAddressResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{refSicCd}/address'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves hierarchy group names for the requested SICs.
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. A list of valid SICs is provided.
	* <br/>		
	* <br/>Post-conditions:
	* <br/>1. If successful, then a list of corresponding hierarchy group names is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getRefSicHierarchyGroupNames(
							   queryParams: GetRefSicHierarchyGroupNamesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetRefSicHierarchyGroupNamesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/hierarchy-group-names'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns the sector number that is associated with the door number provided.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The SIC and door number are required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the input provided is valid, then a sector number will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getSectorByDoorNumber(
							   pathParams: GetSectorByDoorNumberPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSectorByDoorNumberResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/doors/{doorNumber}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns location information about a service center. This information shall include the service center's physical address as well as geocoordinates for use in GIS applications.
	*/
	public getServiceCenterDetailsBySic(
							   pathParams: GetServiceCenterDetailsBySicPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetServiceCenterDetailsBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves the SIC associated with the postal code provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. One or more valid US or CA postal codes are provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the SIC, state, country, and other details for the postal code are returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getSicForPostalCodes(
							   queryParams: GetSicForPostalCodesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSicForPostalCodesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/postal-assignments'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns the zone and satellite details for a specific SIC location.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The SIC code is required.  The Boolean fields are optional.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the data provided is valid, info about the zone and satellite will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getZoneAndSatelliteBySic(
							   pathParams: GetZoneAndSatelliteBySicPath,
							   queryParams: GetZoneAndSatelliteBySicQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetZoneAndSatelliteBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/zone-satellite/{sicCd}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This oepration returns a list of all active doors for the service center provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The sicCd is provided and valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then a list of all active doors is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listAllActiveDoorsBySic(
							   pathParams: ListAllActiveDoorsBySicPath,
							   queryParams: ListAllActiveDoorsBySicQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListAllActiveDoorsBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/all-active-doors'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns a list of all active service service centers and their region, operational region, district, city, state, and whether they are a FAC or OB.
	* <br/>
	* <br/>Pre-condition:
	* <br/>None
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then a list of all active SICs will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listAllActiveSics(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListAllActiveSicsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/all-active-sics'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns a list of service centers
	*/
	public listAllSicCdsByRegion(
							   pathParams: ListAllSicCdsByRegionPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListAllSicCdsByRegionResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/sics-by-region/{locGroupCode}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists area assignments based on the input criteria provided.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. One of the following fields must be provided: areaInstId, sicCd. The input criteria can be combined.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, return all area assignments that match the input criteria.
	* <br/>	- If areaInstId is entered, all area assignments pertinent to that areaInstId will be returned.
	* <br/>	- If sicCd is entered, all area assignments pertinent to that sicCd will be returned.
	* <br/>	- If both areaInstId and sicCd are entered, a single area assignment will be returned.
	* <br/>2. If the input data is invalid, then throw an error.
	* <br/>3. If the input data is valid, but no area assignments are found, return a not found message.
	*/
	public listAreaAssignments(
							   queryParams: ListAreaAssignmentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListAreaAssignmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/area-assignments'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists the eligible services for postal code and SIC combination(s).
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. A list of valid postalCd and sicCd combinations must be provided.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. If successful, then the list of eligible services are returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listEligibleServicesByPostalSic(request: ListEligibleServicesByPostalSicRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListEligibleServicesByPostalSicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/postal-sics/services'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation lists geo area information for location(s). 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid polygonTypeCd must be provided.
	* <br/>2. At least one valid areaInstId or sicCd must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then a list of geo areas that matched the input criteria will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listGeoAreas(
							   pathParams: ListGeoAreasPath,
							   queryParams: ListGeoAreasQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListGeoAreasResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/areas/{polygonTypeCd}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists location features based on the input criteria provided.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. One of the following fields must be provided: employeeId, sicCd, or featureCd. The input criteria can be combined.
	* <br/>2. settingValue needs to be provided for a featureCd.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, return all location features that match the input criteria.
	* <br/>	- If sicCd is entered, all features pertinent to that SIC will be returned.
	* <br/>	- If employeeId is entered, all features pertinent to that employeeId will be returned.
	* <br/>	- If featureCd is entered, all features pertinent to that featureCd will be returned.
	* <br/>	- If settingValue is entered, all features pertinent to that settingValue will be returned.
	* <br/>2. If the input data is invalid, then throw an error.
	* <br/>3. If the input data is valid, but no features are found, return a not found message.
	*/
	public listLocationFeatures(
							   queryParams: ListLocationFeaturesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListLocationFeaturesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/location-features'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* ************************************************************** This contract will be deprecated. **
	* <br/>** listLocationFeatures should be used instead. **
	* <br/>************************************************************	
	* <br/>This operation retrieves data for a location feature. The list can be restricted to those of a specific feature value, e.g. 'Y' or 'true' or 'New'. A feature relates to an application. We use the feature codes to identify whether a feature or application has been rolled out to a SIC, handheld device, or employee. For example, if we wanted to track which SICs have been rolled out to use the ELD / Vnomics system, we would create an ELD flag.
	* <br/>								
	* <br/>Mandatory Criteria:
	* <br/>- Feature Cd
	* <br/>
	* <br/>Optional Criteria:
	* <br/>- Feature Setting Value	
	*/
	public listLocationFeatureValuesByFeature(
							   pathParams: ListLocationFeatureValuesByFeaturePath,
							   queryParams: ListLocationFeatureValuesByFeatureQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListLocationFeatureValuesByFeatureResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/features/{featureCd}/feature-values'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns a list of all location group codes.
	* <br/>â
	* <br/>Pre-conditions:
	* <br/>1. Group category code may be provided (LHO_LHAREA_ALL, LHO_MODEL_GRP) to return all location group codes for the category. 
	* <br/>2. A group category code and SIC may be provided to return a specific location group code.
	* <br/>â
	* <br/>Post-conditions:
	* <br/>1. If successful, then a list of all location group codes will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listLocationGroupCodes(
							   queryParams: ListLocationGroupCodesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListLocationGroupCodesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/location-group-codes'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns a list of locations and their zones and satellites, including their regions, cities, states, and if they are a FAC or OB. If no input SIC is provided, all locations satisfying the other criteria are returned. 
	* <br/>		
	* <br/>Pre-conditions:
	* <br/>1. The activeInd, zoneDesiredInd, and satelliteDesiredInd fields are optional.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the data provided is valid, then the list of matching locations is returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	* <br/>		
	* <br/>		
	*/
	public listLocations(request: ListLocationsRqst,
							   queryParams: ListLocationsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListLocationsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/locations'),
			{
				queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation lists the working or non-working day list on the basis of a given day.
	* <br/>
	* <br/>Pre-conditions: 
	* <br/>1. The input data provided must be valid.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. If successful, the operational calendar days are listed.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listOperationalCalendarDays(
							   queryParams: ListOperationalCalendarDaysQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListOperationalCalendarDaysResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/operational-calendar-days'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists the operational hierarchy which is composed of the following: operational regions with their districts, each district with all of its SICs, and for each SIC all of its zones and satellites. 
	* <br/>
	* <br/>Pre-condition:
	* <br/>None
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the operational hierarchy list is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listOperationalHierarchy(
							   queryParams: ListOperationalHierarchyQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListOperationalHierarchyResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/operational-hierarchy'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists all operational regions with their districts and for each district all of its SICs.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Both parameters are empty.
	* <br/>2. A valid locationHierarchyTypeCd is provided.
	* <br/>2.1 If locationHierarchyTypeCd is not empty, a list of valid location codes must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If both parameters are empty, then list all operational regions with their districts and for each district all of its SICs.
	* <br/>2. If both parameters are not empty, then list filtered operational regions with their districts and for each district all of its SICs.
	* <br/>3. If only one of the parameters is populated, then an error message identifying the missing parameter will be returned.
	* <br/>4. If there is no such data, then an empty list is returned.
	* <br/>5. Otherwise an appropriate error message is returned.
	*/
	public listOperationalRegions(
							   queryParams: ListOperationalRegionsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListOperationalRegionsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/operational-regions'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists operational service centers.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>None 
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. It should return a list of all existing operational servie centers.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listOperationalServiceCenters(
							   queryParams: ListOperationalServiceCentersQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListOperationalServiceCentersResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation either creates a new location feature setting or updates one if it already exists.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Valid values must be provided for the non-nullable columns of LOC_FEATURE_SETTING.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, a status code of 200 will be returned with the object inserted.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public upsertLocationFeatureSetting(request: UpsertLocationFeatureSettingRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertLocationFeatureSettingResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/location-feature-settings'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Delete expired messages in the queue
	*/
	public deleteExpiredMessages(
							   pathParams: DeleteExpiredMessagesPath,
							   queryParams: DeleteExpiredMessagesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteExpiredMessagesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/messages'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation returns counts for the number of ensembles in each of the status cd values.
	* <br/>Pre-conditions:
	* <br/>1. Input search criteria may be provided.
	* <br/>Post-condition:
	* <br/>1. Counts for each ensemble status are returned.
	*/
	public getEnsembleStatusCounts(
							   pathParams: GetEnsembleStatusCountsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetEnsembleStatusCountsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/counts/{fromTmst}/{toTmst}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns counts for the number of messages pending in the queue.
	* <br/>Pre-conditions:
	* <br/>1. Queue name is provided.
	* <br/>Post-condition:
	* <br/>1. Count of all messages in ready and wait statuses
	*/
	public getQueuePendingCount(
							   pathParams: GetQueuePendingCountPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetQueuePendingCountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/counts/pending'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation updates one or more SUSPENDED Ensemble execution row to a status of KILLED, in order to indicate it is has been forced to stop processing.
	* <br/>Pre-conditions:
	* <br/>1. One or more Ensemble Instance Ids that is in a status of SUSPENDED is supplied and valid.
	* <br/>Post-condition:
	* <br/>1. Ensemble record(s) updated to a Status of KILLED.
	*/
	public killEnsemble(request: KillEnsembleRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<KillEnsembleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/ensembles/kill'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* List all expired messages in the queue
	*/
	public listExpiredMessages(
							   pathParams: ListExpiredMessagesPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListExpiredMessagesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/messages/{enqueueFromTmst}/{enqueueToTmst}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List the queues.
	*/
	public listQueues(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListQueuesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Replay a message in expired status
	*/
	public replayMessage(request: ReplayMessagePath,
							   pathParams: ReplayMessagePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/messages/{messageId}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation Restarts a STARTED or NOT_STARTED Ensemble execution based on input Ensemble Instance Id.
	* <br/>Pre-conditions:
	* <br/>1. Input Ensemble Instance Id is supplied and valid.
	* <br/>Post-condition:
	* <br/>1. STARTED or NOT_STARTED Ensemble execution status is updated to STARTED and execution is initiated; if any Minuets exist for the Ensemble, those are initiated in sequence and status updates are performed.
	*/
	public restartEnsemble(request: RestartEnsembleRqst,
							   pathParams: RestartEnsemblePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RestartEnsembleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/ensembles/{ensembleInstId}/restart'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation Resumes a SUSPENDED Ensemble execution based on input Ensemble Instance Id.
	* <br/>Pre-conditions:
	* <br/>1. Input Ensemble Instance Id is supplied and valid.
	* <br/>Post-condition:
	* <br/>1. SUSPENDED Ensemble execution status is updated to STARTED and execution is initiated; if any Minuets exist for the Ensemble, those are initiated in sequence and status updates are performed.
	*/
	public resumeEnsemble(request: ResumeEnsembleRqst,
							   pathParams: ResumeEnsemblePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ResumeEnsembleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/ensembles/{ensembleInstId}/resume'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Start a queue.
	*/
	public startQueue(request: StartQueuePath,
							   pathParams: StartQueuePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/start'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Stop a queue. Stopping a queue will only stop the dequeuing process, it will continue to enqueue
	*/
	public stopQueue(request: StopQueuePath,
							   pathParams: StopQueuePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/stop'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Stop a queue. Stopping a queue will only stop the dequeuing process, it will continue to enqueue
	*/
	public stopQueueWebhook(request: StopQueueWebhookPath,
							   pathParams: StopQueueWebhookPath,
							   queryParams: StopQueueWebhookQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/webhooks/queues/{queueName}/stop'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Export documents from elastic index
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Filter criteria is supplied
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All documents matching the filter will be exported as CSV or Excel format
	* <br/>		
	*/
	public exportEnsembleStateUnfiltered(request: ExportEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/exports'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Search documents from elastic index
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Filter criteria is supplied
	* <br/>
	* <br/>Post-condition:
	* <br/>1. Documents matching the filter will be returned
	* <br/>		
	*/
	public searchEnsembleStateUnfiltered(request: SearchEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchEnsembleStateUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/searches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for ensemble-state-unfiltered
	*/
	public changesFeedEnsembleStateUnfiltered(request: ChangesFeedEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for EnsembleStateUnfiltered
	*/
	public registerFilterEnsembleStateUnfiltered(request: RegisterFilterEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterEnsembleStateUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for EnsembleStateUnfiltered
	*/
	public unregisterFilterEnsembleStateUnfiltered(request: UnregisterFilterEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Delete Elastic document for EnsembleState
	*/
	public deleteElasticDocumentEnsembleState(
							   pathParams: DeleteElasticDocumentEnsembleStatePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteElasticDocumentEnsembleStateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/ensemble-state-unfiltered/_doc/{instId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Get Elastic document from EnsembleState index for given primary key
	*/
	public getElasticDocumentEnsembleState(
							   pathParams: GetElasticDocumentEnsembleStatePath,
							   queryParams: GetElasticDocumentEnsembleStateQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentEnsembleStateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/ensemble-state-unfiltered/_doc/{instId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get document count from EnsembleState index
	*/
	public getElasticDocumentEnsembleStateCount(
							   queryParams: GetElasticDocumentEnsembleStateCountQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentEnsembleStateCountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/ensemble-state-unfiltered/_count'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Update Elastic document for EnsembleState
	*/
	public updateElasticDocumentEnsembleState(request: UpdateElasticDocumentEnsembleStateRqst,
							   pathParams: UpdateElasticDocumentEnsembleStatePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateElasticDocumentEnsembleStateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/ensemble-state-unfiltered/_doc/{instId}/_update'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* List all ensemble configurations
	*/
	public listEnsembleConfigurations(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListEnsembleConfigurationsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/configurations'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(LocationApiService.LocationApiEndpoint);
	}
}
