import { Pipe, PipeTransform } from '@angular/core';
import { ShipmentPnDStatusCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'pndStatus',
})
export class ShipmentPnDStatusPipe implements PipeTransform {
  transform(value: ShipmentPnDStatusCd): string {
    switch (value) {
      case ShipmentPnDStatusCd.ASSIGNED:
        return 'Assigned';
      case ShipmentPnDStatusCd.COMPLETE:
        return 'Complete';
      case ShipmentPnDStatusCd.DISPATCHED:
        return 'Dispatched';
      case ShipmentPnDStatusCd.EXCEPTION:
        return 'Exception';
      case ShipmentPnDStatusCd.LOADED:
        return 'Loaded';
      case ShipmentPnDStatusCd.RETURNING:
        return 'Returning';
      case ShipmentPnDStatusCd.NOT_APPLICABLE:
        return 'Not Applicable';
      case ShipmentPnDStatusCd.UNASSIGNED:
        return 'Unassigned';
      default:
        return value;
    }
  }
}
