import { DocumentData } from '@angular/fire/firestore';

export class RegionInfo {
  private readonly _apiUrl: string;
  private readonly _consumerKey: string;
  private readonly _consumerSecret: string;
  private readonly _svcAcct: string;
  private readonly _svcAcctKey: string;

  constructor(private doc: DocumentData) {
    this._apiUrl = doc.apiUrl;
    this._consumerKey = doc.consumerKey;
    this._consumerSecret = doc.consumerSecret;
    this._svcAcct = doc.svcAcct;
    this._svcAcctKey = doc.svcAcctKey;
  }

  get apiUrl(): string {
    return this._apiUrl;
  }

  get consumerKey(): string {
    return this._consumerKey;
  }

  get consumerSecret(): string {
    return this._consumerSecret;
  }

  get svcAcct(): string {
    return this._svcAcct;
  }

  get svcAcctKey(): string {
    return this._svcAcctKey;
  }
}
