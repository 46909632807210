import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'xpo-step-buttons',
  templateUrl: './step-buttons.component.html',
  styleUrls: ['./step-buttons.component.scss'],
  host: {
    class: 'xpo-StepButtons',
  },
  encapsulation: ViewEncapsulation.None,
})
export class XpoStepButtonsComponent {}
