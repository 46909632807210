import {ClaimEmailTemplateTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimEmailTemplateTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimEmailTemplateTypeCd ) {
      this._values.push(ClaimEmailTemplateTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimEmailTemplateTypeCd {

    for ( const obj in ClaimEmailTemplateTypeCd ) {
      if (ClaimEmailTemplateTypeCd[obj] === value){
        return ClaimEmailTemplateTypeCd[obj] as ClaimEmailTemplateTypeCd;
      }
    }
  }
}

const ClaimEmailTemplateTypeCdHelper = new ClaimEmailTemplateTypeCdHelperEnumHelperClass();
export default ClaimEmailTemplateTypeCdHelper;
