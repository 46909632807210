import { ApplicationCategoryIconModel } from './application-category-icon.model';

/**
 * SVGs source code for each Application Category icon
 * Note: make sure the latest versions of the SGVs version are up to date here
 */
export const APPLICATION_CATEGORY_ICONS: ApplicationCategoryIconModel[] = [
  // AUDIT & FINANCIAL
  {
    name: 'Audit&Financial',
    svg: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
 <style type="text/css">
   .st0{fill:#CC0000;}
 </style>
 <g>
   <path class="st0" d="M16,38.5c3.9,0,7.1-3.2,7.1-7.1s-3.2-7.1-7.1-7.1s-7.1,3.2-7.1,7.1S12.1,38.5,16,38.5z M10.2,32h2.6
     c0.3,1.3,1.3,2.3,2.5,2.5v2.7C12.7,37,10.5,34.8,10.2,32z M16,29.5c1.1,0,1.9,0.9,1.9,1.9c0,1-0.9,1.9-1.9,1.9s-1.9-0.9-1.9-1.9
     S14.9,29.5,16,29.5z M16.6,37.2v-2.6c1.3-0.3,2.3-1.3,2.5-2.5h2.6C21.5,34.8,19.3,37,16.6,37.2z M21.8,30.8h-2.6
     c-0.3-1.3-1.3-2.3-2.5-2.5v-2.6C19.3,26,21.5,28.1,21.8,30.8z M15.4,25.6v2.6c-1.3,0.3-2.3,1.3-2.5,2.5h-2.7
     C10.5,28.1,12.7,26,15.4,25.6z"/>
   <path d="M37.3,27.9c4.4-1.2,7.7-5.2,7.7-10c0-5.5-4.3-10-9.7-10.3V5h-27v18.7l-1.7,1.7L7.4,27c-0.2,0.5-0.4,0.9-0.6,1.4L5,29v4.8
     l1.8,0.6c0.2,0.5,0.4,1,0.6,1.4l-0.8,1.6l1.7,1.9V45h27.1v-5.2c1.5-0.3,2.6-1.6,2.6-3.2v-5.2c0-0.7-0.4-1.3-0.9-1.6
     c0.2-0.3,0.3-0.6,0.3-0.9L37.3,27.9L37.3,27.9z M35.4,29.5h-1.3c-0.4,0-0.6-0.3-0.6-0.6v-0.7c0.4,0.1,0.9,0.1,1.3,0.1
     s0.9,0,1.3-0.1v0.7C36,29.2,35.7,29.5,35.4,29.5z M43.7,17.9c0,5-4.1,9-9,9s-9-4.1-9-9s4.1-9,9-9S43.7,13,43.7,17.9z M8.7,35.5
     C8.4,35,8.1,34.3,8,33.8l-0.1-0.3l-1.6-0.6v-2.9l1.5-0.5l0.1-0.3c0.2-0.6,0.4-1.2,0.7-1.8l0.2-0.3l-0.7-1.4l2.1-2.1l1.4,0.7
     l0.3-0.2c0.6-0.3,1.2-0.6,1.8-0.7l0.3-0.1l0.5-1.5h2.9l0.5,1.5l0.3,0.1c0.6,0.2,1.2,0.4,1.8,0.7l0.3,0.2l1.4-0.7l2.1,2.1l-0.7,1.4
     l0.2,0.3c0.3,0.6,0.6,1.2,0.7,1.8l0.1,0.3l1.5,0.5v2.9l-1.5,0.5L24,33.6c-0.2,0.6-0.4,1.2-0.7,1.8l-0.2,0.3l0.7,1.4l-2.1,2.1
     l-1.4-0.7L20,38.7c-0.6,0.3-1.2,0.6-1.8,0.7l-0.3,0.1L17.4,41h-2.9L14,39.5l-0.3-0.1c-0.6-0.2-1.2-0.4-1.8-0.7l-0.3-0.2l-1.4,0.7
     l-2.1-2l0.7-1.4L8.7,35.5z M9.5,43.7v-3.2l0.4,0.4l1.6-0.8c0.5,0.2,0.9,0.4,1.4,0.6l0.6,1.8h4.8l0.6-1.8c0.5-0.2,1-0.4,1.4-0.6
     l1.6,0.8l3.4-3.4l-0.8-1.6c0.2-0.5,0.4-0.9,0.6-1.4l1.8-0.6v-4.8l-1.8-0.6c-0.2-0.5-0.4-1-0.6-1.4l0.8-1.6l-3.4-3.4l-1.6,0.8
     c-0.5-0.2-0.9-0.4-1.4-0.6l-0.6-1.8h-4.8L13,22.2c-0.5,0.2-1,0.4-1.4,0.6L10,22.1l-0.4,0.4V6.3h24.5v1.3c-5.4,0.3-9.7,4.8-9.7,10.3
     c0,4.8,3.3,8.8,7.7,10v1c0,0.3,0.1,0.7,0.3,0.9c-0.5,0.3-0.9,0.9-0.9,1.6v5.2c0,1.6,1.1,2.9,2.6,3.2v3.9H9.5z M36.5,36.5
     c0,1.1-0.8,1.9-1.8,1.9s-1.9-0.9-1.9-1.9v-5.2c0-0.4,0.3-0.6,0.6-0.6H34h1.3h0.6c0.4,0,0.6,0.3,0.6,0.6V36.5L36.5,36.5z"/>
   <path class="st0" d="M29.5,18.4l-1.3,1.3l3.5,3.5l9.2-9.2l-1.3-1.3l-7.9,7.9L29.5,18.4z"/>
   <path d="M10.8,14.1h12.9V7.6H10.8V14.1z M12.1,8.9h10.3v3.9H12.1V8.9z"/>
   <path class="st0" d="M10.8,15.4h1.3v1.3h-1.3V15.4z"/>
   <path class="st0" d="M13.4,15.4h9.7v1.3h-9.7C13.4,16.6,13.4,15.4,13.4,15.4z"/>
 </g>
 </svg>`,
  },
  // COST AND REVENUE
  {
    name: 'Cost&Revenue',
    svg: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
 <style type="text/css">
   .st0{fill:#CC0000;}
   .st1{fill:#040404;}
 </style>
 <g>
   <path class="st0" d="M36.3,21.4v-1.9c1.6,0,2.9-1.3,2.9-2.9s-1.3-2.9-2.9-2.9h-1.9c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1h2.9v1h1.9V9.9
     h-2.9V8h-1.9v1.9c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9h1.9c0.5,0,1,0.4,1,1c0,0.5-0.4,1-1,1h-2.9v-1h-1.9v2.9h2.9v1.9L36.3,21.4
     L36.3,21.4z"/>
   <path class="st1" d="M45,14.7C45,9.4,40.7,5,35.3,5s-9.7,4.3-9.7,9.7c0,4.1,2.6,7.6,6.2,9L30.5,25h-5.7l-4,5.2h-7.7l-7.9,8.6
     l0.9,0.9l7.6-8.2h7.8l4-5.2h5.6l2.2-2.2c0.6,0.1,1.2,0.2,1.8,0.2l-3.2,3.2H26l-3.9,5.2h-7.7L5,43.5V45h37.4V21.3
     C44,19.5,45,17.2,45,14.7L45,14.7z M27,14.7c0-4.6,3.8-8.4,8.4-8.4s8.4,3.8,8.4,8.4s-3.8,8.4-8.4,8.4S27,19.3,27,14.7z M33.4,43.7
     h-2.6V28.9h1.6l1-1l1.3-1.3l2.4-2.4c0.1,0,0.1,0,0.2,0v19.5h-2.6H33.4z M10.2,43.7H6.5l3.7-4.2l1.3-1.5l2.6-2.9v8.6h-2.6
     C11.5,43.7,10.2,43.7,10.2,43.7z M17.9,43.7h-2.6V34h2.6h1.3h2.6v9.7h-2.6H17.9z M25.7,43.7h-2.6V33.6l2.6-3.4l1-1.3h2.9v14.8H27
      M38.6,43.7V23.8c0.9-0.3,1.8-0.8,2.6-1.4v21.3C41.2,43.7,38.6,43.7,38.6,43.7z"/>
 </g>
 </svg>`,
  },
  // BILLING AND INVOICING
  {
    name: 'Billing&Invoicing',
    svg: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
 <style type="text/css">
   .st0{fill:#CC0000;}
 </style>
 <g>
   <path d="M15.6,29.4h2.9c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6h-2.9c-0.3,0-0.6,0.3-0.6,0.6S15.3,29.4,15.6,29.4z"/>
   <path d="M20.8,28.8c0,0.3,0.3,0.6,0.6,0.6h13.5c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6H21.4C21,28.2,20.8,28.5,20.8,28.8
     L20.8,28.8z"/>
   <path d="M15.6,33.3h2.9c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6h-2.9c-0.3,0-0.6,0.3-0.6,0.6S15.3,33.3,15.6,33.3z"/>
   <path d="M34.9,32.1H21.4c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h13.5c0.3,0,0.6-0.3,0.6-0.6C35.5,32.3,35.2,32.1,34.9,32.1z"/>
   <path d="M15.6,37.1h2.9c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6h-2.9c-0.3,0-0.6,0.3-0.6,0.6S15.3,37.1,15.6,37.1z"/>
   <path d="M34.9,35.9H21.4c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6l0,0h13.5c0.3,0,0.6-0.3,0.6-0.6C35.5,36.2,35.2,35.9,34.9,35.9z"
     />
   <path class="st0" d="M23.1,19.2l-0.7,0.9c0.7,0.6,1.5,1,2.4,1.1v0.7h0.9v-0.7c1.5-0.1,2.3-1,2.3-2.1c0-1.2-0.9-1.7-2.3-2v-1.9
     c0.5,0.1,1,0.4,1.5,0.8l0.7-0.9c-0.6-0.5-1.4-0.9-2.1-1v-0.8h-0.9v0.6c-1.3,0.2-2.1,1-2.1,2.1c0,1.2,0.8,1.7,2.1,2v2
     C24.2,19.9,23.6,19.6,23.1,19.2L23.1,19.2z M25.7,18.3c0.7,0.2,1.1,0.4,1.1,0.9s-0.4,0.8-1.1,0.9V18.3z M24,15.9
     c0-0.4,0.4-0.8,0.8-0.9v1.7C24.2,16.6,24,16.4,24,15.9L24,15.9z"/>
   <path d="M25.2,25.2c4.2,0,7.6-3.4,7.6-7.6S29.4,10,25.2,10s-7.6,3.4-7.6,7.6C17.7,21.8,21.1,25.2,25.2,25.2z M25.2,11.2
     c3.5,0,6.4,2.9,6.4,6.4S28.7,24,25.2,24s-6.4-2.9-6.4-6.4C18.8,14.1,21.7,11.2,25.2,11.2z"/>
   <path d="M39.5,5H10.9c-0.3,0-0.6,0.3-0.6,0.6v38.8c0,0.3,0.3,0.6,0.6,0.6c0.2,0,0.3-0.1,0.4-0.2l2.8-2.4l2.4,2.4
     c0.2,0.2,0.6,0.2,0.8,0l2.4-2.4l2.4,2.4c0.2,0.2,0.6,0.2,0.8,0l2.4-2.4l2.4,2.4c0.2,0.2,0.6,0.2,0.8,0l2.4-2.4l2.4,2.4
     c0.2,0.2,0.6,0.2,0.8,0l2.4-2.4l2.4,2.4c0.2,0.2,0.6,0.1,0.8,0c0.2-0.1,0.3-0.5,0.3-0.6l0.1-38.4C40.1,5.2,39.8,5,39.5,5L39.5,5z
      M38.9,43L37,41.1c-0.2-0.2-0.6-0.2-0.8,0l-2.4,2.4l-2.4-2.4c-0.2-0.2-0.6-0.2-0.8,0l-2.4,2.4l-2.4-2.4c-0.2-0.2-0.6-0.2-0.8,0
     l-2.4,2.4l-2.4-2.4c-0.2-0.2-0.6-0.2-0.8,0L17,43.5l-2.4-2.4c-0.2-0.2-0.6-0.2-0.8,0L11.5,43V6.2h27.4V43z"/>
 </g>
 </svg>`,
  },
  // OPS & DOCK
  {
    name: 'Ops&Dock',
    svg: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
 <style type="text/css">
   .st0{fill:#CC0000;}
 </style>
 <g>
   <path d="M44.9,21.6l-3.3-1.3c-0.3-1.1-0.8-2.3-1.4-3.3l1.4-3.3c0.1-0.2,0-0.5-0.1-0.6L37.4,9c-0.2-0.2-0.4-0.2-0.6-0.1l-3.3,1.4
     c-1.1-0.6-2.2-1-3.3-1.4l-1.3-3.3c-0.1-0.2-0.3-0.4-0.5-0.4h-5.9c-0.2,0-0.5,0.1-0.5,0.4l-1.3,3.3c-1.1,0.3-2.3,0.8-3.3,1.4
     l-3.3-1.4c-0.2-0.1-0.5,0-0.6,0.1l-4.1,4.1c-0.2,0.2-0.2,0.4-0.1,0.6l1.4,3.3c-0.6,1.1-1,2.2-1.4,3.3l-3.6,1.3
     c-0.2,0.1-0.4,0.3-0.4,0.5V28c0,0.2,0.1,0.5,0.4,0.5L9,29.8c0.3,1.1,0.8,2.3,1.4,3.3L9,36.4c-0.1,0.2,0,0.5,0.1,0.6l4.1,4.1
     c0.2,0.2,0.4,0.2,0.6,0.1l3.3-1.4c1.1,0.6,2.2,1,3.3,1.4l1.3,3.3c0.1,0.2,0.3,0.4,0.5,0.4h5.9c0.2,0,0.5-0.1,0.5-0.4l1.3-3.3
     c1.1-0.3,2.3-0.8,3.3-1.4l3.3,1.4c0.2,0.1,0.5,0,0.6-0.1l4.1-4.1c0.2-0.2,0.2-0.4,0.1-0.6l-1.4-3.3c0.6-1.1,1-2.2,1.4-3.3l3.3-1.3
     c0.2-0.1,0.4-0.3,0.4-0.5v-5.9C45,21.9,45,21.7,44.9,21.6z M44.1,27.6l-3.2,1.3c-0.2,0.1-0.3,0.2-0.3,0.4c-0.3,1.2-0.8,2.5-1.5,3.6
     c-0.1,0.2-0.1,0.4,0,0.5l1.4,3.2l-3.6,3.6l-3.2-1.4c-0.2-0.1-0.4-0.1-0.5,0c-1.1,0.6-2.3,1.1-3.6,1.5c-0.2,0-0.3,0.2-0.4,0.3
     l-1.3,3.2h-5.1l-1.3-3.2c-0.1-0.2-0.2-0.3-0.4-0.3c-1.2-0.3-2.5-0.8-3.6-1.5c-0.2-0.1-0.4-0.1-0.5,0l-3.2,1.4l-3.6-3.6l1.4-3.2
     c0.1-0.2,0.1-0.4,0-0.5c-0.6-1.1-1.1-2.3-1.5-3.6c0-0.2-0.2-0.3-0.3-0.4l-3.2-1.3v-5.1l3.2-1.3c0.2-0.1,0.3-0.2,0.3-0.4
     c0.3-1.2,0.8-2.5,1.5-3.6c0.1-0.2,0.1-0.4,0-0.5l-1.4-3.2l3.6-3.6l3.2,1.4c0.2,0.1,0.4,0.1,0.5,0c1.1-0.6,2.3-1.1,3.6-1.5
     c0.2,0,0.3-0.2,0.4-0.3l1.3-3.2h5.1l1.3,3.2c0.1,0.2,0.2,0.3,0.4,0.3c1.2,0.3,2.5,0.8,3.6,1.5c0.2,0.1,0.4,0.1,0.5,0l3.2-1.4
     l3.6,3.6l-1.4,3.2c-0.1,0.2-0.1,0.4,0,0.5c0.6,1.1,1.1,2.3,1.5,3.6c0,0.2,0.2,0.3,0.3,0.4l3.2,1.3V27.6z"/>
   <path d="M25.3,12.1c-7.1,0-12.9,5.8-12.9,12.9s5.8,12.9,12.9,12.9S38.2,32.1,38.2,25S32.4,12.1,25.3,12.1z M25.3,36.7
     c-6.5,0-11.7-5.3-11.7-11.7s5.2-11.7,11.7-11.7S37,18.6,37,25S31.8,36.7,25.3,36.7z"/>
   <path class="st0" d="M17.3,30l7.6,4.4c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l7.6-4.4c0.2-0.1,0.3-0.3,0.3-0.6v-8.8
     c0-0.2-0.1-0.5-0.3-0.6l-7.6-4.4c-0.2-0.1-0.5-0.1-0.7,0L17.2,20c-0.2,0.1-0.3,0.3-0.3,0.6v8.8C17,29.7,17.1,29.9,17.3,30z
      M18.3,21.8l6.3,3.6v7.3l-6.3-3.6V21.8z M26,32.7v-7.3l6.3-3.6v7.3L26,32.7z M25.3,17l6.3,3.6l-6.3,3.6L19,20.6L25.3,17z"/>
 </g>
 </svg>`,
  },
  // CUSTOMER SOLUTIONS
  {
    name: 'CustomerSolutions',
    svg: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
 <style type="text/css">
   .st0{fill:#CC0000;}
 </style>
 <g>
   <g>
     <g>
       <path d="M25.5,5.3c-0.2-0.3-0.7-0.3-1-0.1c0,0-0.1,0.1-0.1,0.1l-2,2.7l1.1,0.8l0.8-1.1V13h1.3V7.7l0.8,1.1l1.1-0.8L25.5,5.3z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M26.5,41.3l-0.8,1.1V37h-1.3v5.3l-0.8-1.1l-1.1,0.8l2,2.7c0.2,0.3,0.6,0.4,0.9,0.1c0.1,0,0.1-0.1,0.1-0.1l2-2.7
         L26.5,41.3z"/>
     </g>
   </g>
   <g>
     <g>
       <rect x="24.3" y="14.3" class="st0" width="1.3" height="1.3"/>
     </g>
   </g>
   <g>
     <g>
       <rect x="24.3" y="34.3" class="st0" width="1.3" height="1.3"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M13,24.3H7.7l1.1-0.8l-0.8-1.1l-2.7,2c-0.3,0.2-0.4,0.6-0.1,0.9c0,0.1,0.1,0.1,0.1,0.1l2.7,2l0.8-1.1l-1.1-0.8H13V24.3z"
         />
     </g>
   </g>
   <g>
     <g>
       <path d="M44.9,24.6c0-0.1-0.1-0.1-0.1-0.1l-2.7-2l-0.8,1.1l1.1,0.8H37v1.3h5.3l-1.1,0.8l0.8,1.1l2.7-2
         C45,25.3,45.1,24.9,44.9,24.6z"/>
     </g>
   </g>
   <g>
     <g>
       <rect x="14.3" y="24.3" class="st0" width="1.3" height="1.3"/>
     </g>
   </g>
   <g>
     <g>
       <rect x="34.3" y="24.3" class="st0" width="1.3" height="1.3"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M17,16l-3.8-3.8l1.3,0.2l0.2-1.3l-3.3-0.5c-0.4-0.1-0.7,0.2-0.8,0.6c0,0.1,0,0.1,0,0.2l0.5,3.3l1.3-0.2l-0.2-1.3L16,17
         L17,16z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M39.3,38.6l-0.5-3.3l-1.3,0.2l0.2,1.3L34,33L33,34l3.8,3.8l-1.3-0.2l-0.2,1.3l3.3,0.5c0,0,0.1,0,0.1,0
         C39,39.3,39.3,39,39.3,38.6C39.3,38.6,39.3,38.6,39.3,38.6z"/>
     </g>
   </g>
   <g>
     <g>

         <rect x="17.3" y="17.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -7.4214 17.927)" class="st0" width="1.3" height="1.3"/>
     </g>
   </g>
   <g>
     <g>

         <rect x="31.4" y="31.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -13.2786 32.069)" class="st0" width="1.3" height="1.3"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M13.2,37.7l3.8-3.8L16,33l-3.8,3.8l0.2-1.3l-1.3-0.2l-0.5,3.3c-0.1,0.4,0.2,0.7,0.6,0.8c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
         l3.3-0.5l-0.2-1.3L13.2,37.7z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M39.1,10.9c-0.1-0.1-0.4-0.2-0.6-0.2l-3.3,0.5l0.2,1.3l1.3-0.2L33,16L34,17l3.8-3.8l-0.2,1.3l1.3,0.2l0.5-3.3
         C39.4,11.2,39.3,11,39.1,10.9z"/>
     </g>
   </g>
   <g>
     <g>

         <rect x="17.3" y="31.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -17.4211 22.0694)" class="st0" width="1.3" height="1.3"/>
     </g>
   </g>
   <g>
     <g>

         <rect x="31.4" y="17.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.2775 27.9265)" class="st0" width="1.3" height="1.3"/>
     </g>
   </g>
 </g>
 <path class="st0" d="M18.4,29l6.3,3.7c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.3-3.7c0.2-0.1,0.3-0.3,0.3-0.5v-7.3
   c0-0.2-0.1-0.4-0.3-0.5l-6.3-3.7c-0.2-0.1-0.4-0.1-0.6,0l-6.3,3.7c-0.2,0.1-0.3,0.3-0.3,0.5v7.3C18.1,28.7,18.2,28.9,18.4,29z
    M19.2,22.2l5.2,3v6l-5.2-3V22.2z M25.6,31.2v-6l5.2-3v6L25.6,31.2z M25,18.2l5.2,3l-5.2,3l-5.2-3L25,18.2z"/>
 </svg>`,
  },
];
