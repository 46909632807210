import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoLtlDocViewPageComponent } from './doc-view-page/doc-view-page.component';

const routes: Routes = [
  {
    path: 'docview/:doc-class/:timestamp',
    component: XpoLtlDocViewPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class XpoLtlDmsUtilsRoutingModule {}
