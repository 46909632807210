import { Injectable } from '@angular/core';

/* XPO */
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';

/* Rxjs */
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/* Services */
import { ModuleGroupMgmtAssociatedModulesService } from '../../../services/module-group-mgmt-associated-modules.service';

@Injectable()
export class ModuleGroupMgmtAssociatedModulesDataSource extends XpoBoardDataSource {
  constructor(private moduleGroupMgmtAssociatedModulesService: ModuleGroupMgmtAssociatedModulesService) {
    super();
    this.pageSize = 100000;
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    return this.moduleGroupMgmtAssociatedModulesService
      .listModuleGroupMgmtAssociatedModules()
      .pipe(map((rows) => new XpoBoardData(state, rows, rows.length, this.pageSize)));
  }
}
