import {ClaimNoteVisibilityCd} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimNoteVisibilityCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimNoteVisibilityCd ) {
      this._values.push(ClaimNoteVisibilityCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimNoteVisibilityCd {

    for ( const obj in ClaimNoteVisibilityCd ) {
      if (ClaimNoteVisibilityCd[obj] === value){
        return ClaimNoteVisibilityCd[obj] as ClaimNoteVisibilityCd;
      }
    }
  }
}

const ClaimNoteVisibilityCdHelper = new ClaimNoteVisibilityCdHelperEnumHelperClass();
export default ClaimNoteVisibilityCdHelper;
