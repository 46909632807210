import {ServiceTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ServiceTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ServiceTypeCd ) {
      this._values.push(ServiceTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ServiceTypeCd {

    for ( const obj in ServiceTypeCd ) {
      if (ServiceTypeCd[obj] === value){
        return ServiceTypeCd[obj] as ServiceTypeCd;
      }
    }
  }
}

const ServiceTypeCdHelper = new ServiceTypeCdHelperEnumHelperClass();
export default ServiceTypeCdHelper;
