import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { XpoTrayDrawerDirective } from './drawer/tray-drawer-directive';
import { XpoTrayPanelFooterComponent } from './panel/buttons/tray-panel-footer.component';
import { XpoTrayPanelContentDirective } from './panel/content/tray-panel-content.directive';
import { XpoTrayPanelComponent } from './panel/tray-panel.component';

@NgModule({
  declarations: [
    XpoTrayDrawerDirective,
    XpoTrayPanelComponent,
    XpoTrayPanelFooterComponent,
    XpoTrayPanelContentDirective,
  ],
  imports: [CommonModule, MatSidenavModule, MatIconModule, MatButtonModule],
  exports: [
    MatSidenavModule,
    XpoTrayDrawerDirective,
    XpoTrayPanelComponent,
    XpoTrayPanelContentDirective,
    XpoTrayPanelFooterComponent,
  ],
})
export class XpoTrayModule {}
