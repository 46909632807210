import {LocationHierarchyTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class LocationHierarchyTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LocationHierarchyTypeCd ) {
      this._values.push(LocationHierarchyTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LocationHierarchyTypeCd {

    for ( const obj in LocationHierarchyTypeCd ) {
      if (LocationHierarchyTypeCd[obj] === value){
        return LocationHierarchyTypeCd[obj] as LocationHierarchyTypeCd;
      }
    }
  }
}

const LocationHierarchyTypeCdHelper = new LocationHierarchyTypeCdHelperEnumHelperClass();
export default LocationHierarchyTypeCdHelper;
