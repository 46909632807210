import { Pipe, PipeTransform } from '@angular/core';
import * as moment_ from 'moment-timezone';

const moment = moment_;
/**
 * A moment timezone pipe to support parsing based on time zone abbreviations
 * covers all cases of offset variation due to daylight saving.
 *
 * Same API as DatePipe with additional timezone abbreviation support
 * Official date pipe dropped support for abbreviations names from Angular V5
 */
@Pipe({
  name: 'momentDate',
})
export class MomentDatePipe implements PipeTransform {
  transform(value: string | Date, format: string = 'MM/DD/YYYY hh:mm z'): string {
    return moment(value)
      .tz(moment.tz.guess(true))
      .format(format);
  }
}
