import { Pipe, PipeTransform } from '@angular/core';
import { values as _values } from 'lodash';

@Pipe({
  name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {
  transform(data: Object): any {
    const keys = _values(data);
    return keys;
  }
}
