import {DeliveryInfoRequiredCd} from '../';
import {EnumHelper} from './enum-helper';

export class DeliveryInfoRequiredCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DeliveryInfoRequiredCd ) {
      this._values.push(DeliveryInfoRequiredCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DeliveryInfoRequiredCd {

    for ( const obj in DeliveryInfoRequiredCd ) {
      if (DeliveryInfoRequiredCd[obj] === value){
        return DeliveryInfoRequiredCd[obj] as DeliveryInfoRequiredCd;
      }
    }
  }
}

const DeliveryInfoRequiredCdHelper = new DeliveryInfoRequiredCdHelperEnumHelperClass();
export default DeliveryInfoRequiredCdHelper;
