import {ScoEventStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ScoEventStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ScoEventStatusCd ) {
      this._values.push(ScoEventStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ScoEventStatusCd {

    for ( const obj in ScoEventStatusCd ) {
      if (ScoEventStatusCd[obj] === value){
        return ScoEventStatusCd[obj] as ScoEventStatusCd;
      }
    }
  }
}

const ScoEventStatusCdHelper = new ScoEventStatusCdHelperEnumHelperClass();
export default ScoEventStatusCdHelper;
