import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { CustomFilterParams } from '../../models';
import { XpoConditionalFilter } from '../conditional-filter';
import { XpoFilterCondition } from './condition';
import { XpoConditionComponentDirective } from './condition.directive';

@Component({
  templateUrl: './conditions.component.html',
  selector: 'xpo-conditions',
  styleUrls: ['./conditions.component.scss'],
})
export class XpoConditionsComponent implements AfterViewInit, OnChanges {
  @ViewChild(XpoConditionComponentDirective, { static: true }) xpoConditionInputHost: XpoConditionComponentDirective;

  @Input('filter') filter: XpoConditionalFilter;
  @Input('customFilterParams') customFilterParams: CustomFilterParams;
  @Output() conditionChanged = new EventEmitter();
  @Output() inputChanged = new EventEmitter();

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnChanges(): void {
    this.loadComponent();
  }

  ngAfterViewInit(): void {
    this.loadComponent();
  }

  loadComponent(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.filter.selectedCondition);

    const viewContainerRef = this.xpoConditionInputHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);

    this.filter.saveComponentReference(componentRef);

    if ((<XpoFilterCondition>componentRef.instance).inputChanged) {
      (<XpoFilterCondition>componentRef.instance).inputChanged.subscribe(() => {
        this.inputChanged.emit();
      });
    }
    if (this.customFilterParams) {
      (<XpoFilterCondition>componentRef.instance).customFilterParams = this.customFilterParams;
    }
    this.conditionChanged.emit(componentRef);
  }

  conditionChange(): void {
    this.loadComponent();
  }
}
