import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { XPO_GRID_BOARD_DEFAULT_ROW_HEIGHT } from '../../../../models/grid/grid-board-default-row-height-token';
import {
  XpoGridDisplayDensityOptions,
  XpoGridRowHeightOptions,
} from '../../../board-grid-toolbar/board-grid-toolbar-settings/board-settings-default-buttons/grid-settings-default-buttons';

@Component({
  selector: 'xpo-grid-row-height-selection',
  templateUrl: './grid-row-height-selection.component.html',
  styleUrls: ['./grid-row-height-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-GridRowHeightSelection' },
})
export class XpoGridRowHeightSelectionComponent {
  rowHeightOptions: XpoGridRowHeightOptions[] = [];

  @Input()
  selectedRowHeight: number;

  @Output()
  selectedRowHeightChange = new EventEmitter<number>();

  constructor(@Inject(XPO_GRID_BOARD_DEFAULT_ROW_HEIGHT) @Optional() defaultRowHeight: number) {
    const rowHeight = defaultRowHeight || 40;

    this.rowHeightOptions = [
      { name: XpoGridDisplayDensityOptions.Default, value: rowHeight },
      { name: XpoGridDisplayDensityOptions.Compact, value: rowHeight - 8 },
      { name: XpoGridDisplayDensityOptions.Comfortable, value: rowHeight + 8 },
    ];
  }

  updateSelectedRowHeight(value: number): void {
    this.selectedRowHeight = value;
    this.selectedRowHeightChange.emit(this.selectedRowHeight);
  }
}
