import { Component, Input } from '@angular/core';

@Component({
  selector: 'xpo-busy-loader',
  templateUrl: './busy-loader.component.html',
  styleUrls: ['busy-loader.component.scss'],
  host: {
    class: 'xpo-BusyLoader',
  },
})
export class XpoBusyLoader {
  @Input()
  slow: boolean = false;
}
