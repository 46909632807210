import { Component, Inject, InjectionToken, Injector, Type } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { XpoSnackBarLinkAction } from './models/xpo-snack-bar-link-action.interface';
import { XpoSnackBarStatus } from './models/xpo-snack-bar-status.type';

/** The injection token to use for detailed message component data */
export const XPO_SNACKBAR_DETAILED_MESSAGE_DATA = new InjectionToken<any>('XPO_SNACKBAR_DETAILED_MESSAGE_DATA');

@Component({
  selector: 'xpo-notification-template',
  templateUrl: 'notification-template.html',
  styleUrls: ['notification-template.scss'],
  host: {
    '[class]': 'getStatusClasses()',
  },
})
export class XpoNotificationTemplate {

  /** Maps the icon name according to each status */
  static readonly iconMap: { [key: string]: string } = {
    success: 'checkmark-alt',
    warn: 'warning',
    error: 'error',
    info: 'info',
  };

  /** Data that was injected into the snack bar. */
  data: {
    message: string;
    detailedMessage: string;
    detailedMessageComponent: Type<any>;
    detailedMessageComponentData: any;
    linkRoute: string;
    linkMessage: string;
    isInternalRoute: boolean;
    status: XpoSnackBarStatus;
    linkAction: XpoSnackBarLinkAction;
    hideClose: boolean;
  };

  /** The icon name */
  iconName: any;

  /** The injector to be used to pass data to the detailed message component */
  detailedMessageComponentInjector: Injector;

  constructor(public snackBarRef: MatSnackBarRef<XpoNotificationTemplate>,
    @Inject(MAT_SNACK_BAR_DATA) data: any,
    injector: Injector) {

    // keep the injected data
    this.data = data;

    // define the icon name
    this.iconName = XpoNotificationTemplate.iconMap[data.status];

    // create the injector for the detailed message component
    this.detailedMessageComponentInjector = Injector.create({
      providers: [{provide: XPO_SNACKBAR_DETAILED_MESSAGE_DATA, useValue: this.data.detailedMessageComponentData}],
      parent: injector
    });
  }


  /** Performs the action on the snack bar. */
  close(): void {
    this.snackBarRef.dismissWithAction();
  }

  /** Returns the host class according to the snackbar status */
  getStatusClasses(): any {
    return `xpo-Notification xpo-Notification--${this.data.status}`;
  }
}
