import {TripEquipmentStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class TripEquipmentStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TripEquipmentStatusCd ) {
      this._values.push(TripEquipmentStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TripEquipmentStatusCd {

    for ( const obj in TripEquipmentStatusCd ) {
      if (TripEquipmentStatusCd[obj] === value){
        return TripEquipmentStatusCd[obj] as TripEquipmentStatusCd;
      }
    }
  }
}

const TripEquipmentStatusCdHelper = new TripEquipmentStatusCdHelperEnumHelperClass();
export default TripEquipmentStatusCdHelper;
