import {ApiFeatureStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ApiFeatureStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ApiFeatureStatusCd ) {
      this._values.push(ApiFeatureStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ApiFeatureStatusCd {

    for ( const obj in ApiFeatureStatusCd ) {
      if (ApiFeatureStatusCd[obj] === value){
        return ApiFeatureStatusCd[obj] as ApiFeatureStatusCd;
      }
    }
  }
}

const ApiFeatureStatusCdHelper = new ApiFeatureStatusCdHelperEnumHelperClass();
export default ApiFeatureStatusCdHelper;
