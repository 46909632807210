import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { XpoSnackBar } from '../snack-bar/index';
import { FileSystemDirectoryEntry, FileSystemFileEntry, UploadEvent } from './drag-and-drop/index';
import { XpoUploadEventType } from './upload-events/index';
import { XpoUploadListDeleteEvent, XpoUploadListItem } from './upload-list/index';
import { XpoUploadConfig, XpoUploadInternal, XpoUploadUploadedFiles } from './upload.config';
@Component({
  selector: 'xpo-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-Upload',
    '[class.xpo-Upload--disabled]': 'disabledInput',
  },
})
export class XpoUpload implements OnInit {
  files: XpoUploadListItem[] = [];
  uploadInternal: XpoUploadInternal;
  displayedColumns: string[] = ['id', 'customer', 'fileSize'];
  dataSource: MatTableDataSource<XpoUploadUploadedFiles>;
  uploadedFiles: XpoUploadUploadedFiles[] = [];
  uploadErrors: string[] = [];
  typesMessage: string = 'All file types allowed';
  disabledInput: boolean = false;
  disabledInternal: boolean = false;
  maxUploadSizeBytes: number;

  @ViewChild('selectFiles') selectFilesInput: ElementRef;

  @Input()
  get config(): XpoUploadConfig {
    return this.configValue;
  }
  set config(v: XpoUploadConfig) {
    if (!v) {
      throw new Error('XpoUpload: Upload Config Cannot be null');
    }

    this.configValue = v;

    if (this.config.showFileSize === undefined) {
      this.config.showFileSize = true;
    }
    if (this.config.showUploadedFiles === undefined) {
      this.config.showUploadedFiles = false;
    }
    if (this.config.accept === undefined) {
      this.config.accept = '';
    } else {
      this.typesMessage = '';
      this.getTypesMessage();
    }
    if (this.config.maxUploadSizeMB !== undefined) {
      this.maxUploadSizeBytes = this.config.maxUploadSizeMB * 1024000;
    } else {
      this.maxUploadSizeBytes = 256 * 1024000;
    }

    this.config.setFiles = (files: File[], eventType?: XpoUploadEventType) => {
      this.setFiles(files, eventType);
    };

    this.config.cleanFiles = () => {
      this.disabledInternal = false;
      this.uploadErrors.length = 0;
      this.uploadInternal.totalUploadSize = 0;
      this.removePendingFiles();
    };

    this.config.clearUploaded = () => {
      this.removeUploadedFiles();
    };

    this.config.uploadFileTrigger = () => {
      this.uploadFiles();
    };
  }
  configValue: XpoUploadConfig;

  @Input()
  get multiple(): boolean {
    return this.multipleValue;
  }
  set multiple(v: boolean) {
    this.multipleValue = coerceBooleanProperty(v);
  }
  private multipleValue: boolean = false;

  @Input() set disabled(value: boolean) {
    this.disabledInput = value;
    this.disabledInternal = value;
  }

  constructor(private cd: ChangeDetectorRef, private snackBar: XpoSnackBar) {
    this.uploadInternal = new XpoUploadInternal();
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    if (!this.config) {
      throw new Error('XpoUpload: Must provide config for xpo-upload component');
    }
    if (this.disabledInternal === true) {
      this.disabledInput = true;
    }
  }

  /**
   * Handles files entered using the user's OS
   *
   * @param filesInput
   */
  handleFileInput(filesInput: FileList): void {
    this.uploadErrors.length = 0;
    if (!this.selectFilesInput.nativeElement.value) {
      return;
    }

    const files: File[] = [];

    this.uploadInternal.generalProgress = 0;
    this.uploadSize();
    this.uploadInternal.sizeUploaded = 0;
    for (let i = 0; i < filesInput.length; i++) {
      this.exceedsSizeLimit(filesInput.item(i).size)
        ? this.showValidationError(`${filesInput.item(i).name} exceeds the size limit of ${this.config.maxUploadSizeMB}MB`)
        : files.push(filesInput.item(i));
    }
    if (files.length) {
      this.setFiles(files, XpoUploadEventType.Add);
    }
    // Clear input value so that the previous value triggers the change event
    this.selectFilesInput.nativeElement.value = null;
  }

  /**
   * Uploads Files
   */
  uploadFiles(): void {
    this.refreshFileUploader();
    this.uploadSize();
    this.files.forEach((file) => {
      if (file.progress < 100) {
        if (file.uploaded$.observers.length === 0) {
          file.uploaded$.subscribe((uploaded) => {
            this.updateGeneralProgressBar(uploaded);
          });
        }
        this.config.uploadFile(file.file).subscribe(
          (event: HttpEvent<any>) => {
            // If no event, default it to an empty object
            event = event || ({} as any);
            switch (event.type) {
              case HttpEventType.UploadProgress:
                file.setUpload(event.loaded);
                file.setProgress(Math.round((event.loaded / event.total) * 100));
                break;
              // Do nothing with these responses
              case HttpEventType.Sent:
              case HttpEventType.DownloadProgress:
              case HttpEventType.User:
                break;
              // Any other response, take it as a complete
              case HttpEventType.Response:
              default:
                file.setProgress(100);
                this.config.onComplete();
                file.setUpload(file.file.size);
                break;
            }
          },
          (error) => {
            if (this.config.onError) {
              this.showValidationError(`There is a problem uploading ${file.file.name}`);
              this.config.onError(file.file, error);
            } else {
              console.error(error);
            }
          }
        );
      }
    });
  }

  deleteFile(deleteEvent: XpoUploadListDeleteEvent): void {
    // Only delete pending files
    this.uploadErrors = this.uploadErrors.filter((val) => !val.includes(deleteEvent.file.name));
    if (deleteEvent.progress === 0) {
      this.files.splice(deleteEvent.fileIndex, 1);
      this.emitFileChange([deleteEvent.file], XpoUploadEventType.Remove);
    }

    // Empty progress bar when no element present
    if (this.files.length === 0) {
      this.uploadInternal.generalProgress = 0;
    }
  }

  /**
   * Handles Files that are dropped into the component's container
   *
   * @param event
   */
  handleFileDropped(event: UploadEvent): void {
    this.refreshFileUploader();
    if (event.files.length > 1 && !this.multipleValue) {
      this.showValidationError('Unable to upload multiple files at a time. Input only accepts one file.');

      return;
    }

    const filesToSet = [];
    event.files.forEach((droppedFile, index, array) => {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        const file = fileEntry.file;

        this.exceedsSizeLimit(file.size)
          ? this.showValidationError(`${file.name} exceeds the size limit of ${this.config.maxUploadSizeMB}MB`)
          : filesToSet.push(file);

        if (index === array.length - 1 && filesToSet.length) {
          this.setFiles(filesToSet, XpoUploadEventType.Add);
        }
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        // TODO: If not multiple, show snackbar saying you cannot drop a directory
        // eslint-disable-next-line unused-imports/no-unused-vars-ts
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    });
  }

  private exceedsSizeLimit(size: number): boolean {
    return size > this.maxUploadSizeBytes;
  }
  /**
   * Emits the onFileChange event and outputs the files that are
   */
  private emitFileChange(files: File[], eventType: XpoUploadEventType = XpoUploadEventType.Unknown): void {
    this.config.onFilesChange(
      this.files.map((file) => file.file),
      { files, eventType }
    );
    this.cd.markForCheck();
  }

  /**
   *
   * @param files file list to set into current file array
   */
  private setFiles(files: File[], eventType?: XpoUploadEventType): void {
    if (files && files.length) {
      if (this.multipleValue) {
        files = this.filterOutExistingFiles(files);
        if (!files.length) {
          this.showValidationError('File(s) already selected');
          return;
        }
      }
      this.files = this.convertToXpoUploadList(files);
    } else {
      this.files = [];
    }
    this.emitFileChange(files, eventType);

    // Check if we should upload the file automagically
    if (this.config.uploadFilesOnDrop) {
      this.uploadFiles();
    }
  }

  private convertToXpoUploadList(files: File[]): XpoUploadListItem[] {
    const filesToAdd: XpoUploadListItem[] = files.map((file) => new XpoUploadListItem(file));
    return this.multipleValue ? [...filesToAdd, ...this.files] : [...filesToAdd];
  }

  private filterOutExistingFiles(files: File[]): File[] {
    return files.filter((file) => !this.files.find((item) => item.file.name === file.name));
  }

  private showValidationError(message: string): void {
    this.uploadErrors.push(message);
    this.disabledInternal = false;
    this.cd.markForCheck();
  }

  private uploadSize(): void {
    this.uploadInternal.totalUploadSize = 0;
    this.files.forEach((file) => {
      if (file.progress < 100) {
        this.uploadInternal.totalUploadSize = this.uploadInternal.totalUploadSize + file.file.size;
      }
      if (file.progress === 100) {
        let foundRepeat = false;
        for (let i = 0; i < this.uploadedFiles.length; i++) {
          if (this.uploadedFiles[i].customer === file.file.name) {
            foundRepeat = true;
            break;
          }
        }
        if (!foundRepeat) {
          this.uploadedFiles.push({ customer: file.file.name, fileSize: file.file.size });
        }
      }
    });
    this.dataSource.data = this.uploadedFiles;
  }

  private refreshFileUploader(): void {
    this.uploadErrors.length = 0;
    this.uploadInternal.generalProgress = 0;
    this.uploadInternal.sizeUploaded = 0;
  }
  private updateGeneralProgressBar(uploaded): void {
    this.uploadInternal.sizeUploaded = this.uploadInternal.sizeUploaded + uploaded;
    if (this.uploadInternal.totalUploadSize !== 0) {
      this.uploadInternal.generalProgress = (this.uploadInternal.sizeUploaded * 100) / this.uploadInternal.totalUploadSize;
    }
    if (this.uploadInternal.generalProgress === 100) {
      this.disabledInternal = false;
      this.uploadSize();
    } else {
      this.disabledInternal = true;
    }
    this.cd.markForCheck();
  }

  private removePendingFiles(): void {
    let uploadedFiles = [];
    this.files.forEach((file) => {
      if (file.progress === 100) {
        uploadedFiles = [...uploadedFiles, file];
      }
    });
    const filesToAdd: XpoUploadListItem[] = uploadedFiles.map((file) => new XpoUploadListItem(file.file, file.progress));
    this.files = filesToAdd;
    this.emitFileChange(uploadedFiles, XpoUploadEventType.Remove);
    this.cd.markForCheck();
  }

  private removeUploadedFiles(): void {
    let uploadedFiles = [];
    this.files.forEach((file) => {
      if (file.progress === 0) {
        uploadedFiles = [...uploadedFiles, file];
      }
    });
    const filesToAdd: XpoUploadListItem[] = uploadedFiles.map((file) => new XpoUploadListItem(file.file, file.progress));
    this.files = filesToAdd;
    this.emitFileChange(uploadedFiles, XpoUploadEventType.Remove);
    this.filesToDataSource(this.files);
    this.uploadedFiles = [];
    this.cd.markForCheck();
  }

  private filesToDataSource(files: XpoUploadListItem[]): void {
    this.dataSource.data = [];
    files.map((file) => {
      this.dataSource.data.push({ customer: file.file.name, fileSize: file.file.size });
    });
  }
  private getTypesMessage(): void {
    const acceptArrLen = this.config.accept.split(',').length;
    const acceptArr = this.config.accept.split(',');
    for (let index = 0; index < acceptArr.length; index++) {
      let element = acceptArr[index];
      element = element.replace(/\/\*/g, '');
      if (index === 0) {
        this.typesMessage = '';
        if (index === acceptArrLen) {
          this.typesMessage = 'Only ' + element + ' is allowed';
        } else {
          this.typesMessage = 'Only ' + element;
        }
      } else {
        if (index === acceptArr.length - 1) {
          this.typesMessage = this.typesMessage + ' and ' + element + ' are allowed';
        } else {
          this.typesMessage = this.typesMessage + ', ' + element;
        }
      }
    }
  }
}
