import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { XpoIconModule, XpoPopoverModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoLtlDateSelectorComponent } from './date-selector.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [XpoLtlDateSelectorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    XpoPopoverModule,
    XpoIconModule,
  ],
  exports: [XpoLtlDateSelectorComponent],
})
export class XpoLtlDateSelectorModule {}
