import { XpoDateRangeDaily } from './date-range-daily';
import { XpoDateRangeHourly } from './date-range-hourly';
import { XpoDateRangeInterval } from './date-range-interval';
import { XpoDateRangeMinutes } from './date-range-minutes';
import { XpoDateRangeMonthly } from './date-range-monthly';
import { XpoDateRangeQuarterly } from './date-range-quarterly';
import { XpoDateRangeWeekly } from './date-range-weekly';
import { XpoDateRangeYearly } from './date-range-yearly';

export class XpoDateRangeIntervalFactory {
  private static allIntervals: XpoDateRangeInterval[] = [];
  private static activeIntervals: XpoDateRangeInterval[] = [];

  static getDateRangeInterval(index: number): XpoDateRangeInterval {
    return XpoDateRangeIntervalFactory.activeIntervals[index];
  }

  static updateActiveIntervals(intervals: XpoDateRangeInterval[]): void {
    this.activeIntervals = intervals;
  }

  static getAllIntervals(): XpoDateRangeInterval[] {
    if (this.allIntervals.length === 0) {
      this.allIntervals.push(new XpoDateRangeMinutes());
      this.allIntervals.push(new XpoDateRangeHourly());
      this.allIntervals.push(new XpoDateRangeDaily());
      this.allIntervals.push(new XpoDateRangeWeekly());
      this.allIntervals.push(new XpoDateRangeMonthly());
      this.allIntervals.push(new XpoDateRangeQuarterly());
      this.allIntervals.push(new XpoDateRangeYearly());
    }
    return this.allIntervals;
  }

  static getPos(intervalType: string): number {
    const interval: XpoDateRangeInterval[] = this.activeIntervals.filter(
      (i) => i.getTabModel().groupByInterval === intervalType
    );
    return interval.length > 0 ? interval[0].getTabModel().pos : 0;
  }
}
