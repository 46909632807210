import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'tooltip-cell',
  templateUrl: './tooltip-cell-renderer.component.html',
  styleUrls: ['./tooltip-cell-renderer.component.scss'],
})
export class ModuleExecutorTooltipCellRenderComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  getTooltip(value: String): String {
    return value
      ? value.length > 140
        ? value.substring(0, 137).concat('...')
        : value
      : 'There is no Description for this module';
  }
}
