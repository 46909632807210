import { Component } from '@angular/core';
import { XpoFilterCondition } from '../../../condition';
import { XpoConditionEmptyComponent } from '../is-empty/is-empty.component';
import { filterFormats, Operators, OperatorText, XpoFilterConditionCriteria } from './../../../../../models/index';

@Component({ template: ' ' })
export class XpoConditionRangeEmptyComponent extends XpoConditionEmptyComponent implements XpoFilterCondition {
  static text = OperatorText.Empty;
  static applyAlwaysEnabled: boolean = true;
  static operator = Operators.Empty;

  isNegative = false;
  model: null = null;

  constructor() {
    super();
  }

  validate(cellValue): boolean {
    if (!cellValue) {
      return false;
    }
    return !cellValue.from && !cellValue.to;
  }
}
