import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GetOdsShipmentResp } from '@xpo-ltl/sdk-shipmentods';
import { castArray as _castArray, get as _get } from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { XpoLtlShipmentDescriptor } from './shipment-descriptor';
import { XpoLtlShipmentDetailsDialogComponent } from './shipment-details-dialog/shipment-details-dialog.component';
import { XpoLtlShipmentDetailsConfig } from './shipment-details/shipment-details.component';

@Injectable({ providedIn: 'root' })
export class XpoLtlShipmentDetailsService {
  constructor(private dialog: MatDialog) {}

  private shipmentBehaviorSubject = new BehaviorSubject<GetOdsShipmentResp>(undefined);
  shipment$ = this.shipmentBehaviorSubject.asObservable();

  /**
   * Open a dialog to display the Shipment Details
   * @param shipment The GetOdsShipmentResp details to display in the dialog
   */
  showShipmentDetailsDialog(
    shipmentDesc: XpoLtlShipmentDescriptor | XpoLtlShipmentDescriptor[],
    config?: XpoLtlShipmentDetailsConfig,
    dialogConfig?: MatDialogConfig
  ): Observable<void> {
    const subject = new Subject<void>();

    const matDialogConfig = {
      width: '1232px',
      height: '98%',
      panelClass: 'xpo-LtlShipmentDetailsDialog__panel',
      disableClose: true,
      hasBackdrop: !_get(config, 'moveable'),
      ...(dialogConfig || {}),
      data: {
        shipmentDescriptors: _castArray(shipmentDesc),
        config,
      },
    };

    const dialogRef = this.dialog.open(XpoLtlShipmentDetailsDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      subject.next();
      subject.complete();
    });

    if (!_get(matDialogConfig, 'disableClose')) {
      dialogRef
        .keydownEvents()
        .pipe(take(1))
        .subscribe((e: KeyboardEvent) => {
          if (e.code === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            dialogRef.close();
          }
        });
    }

    return subject.asObservable();
  }
}
