import {ScoEventSubTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ScoEventSubTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ScoEventSubTypeCd ) {
      this._values.push(ScoEventSubTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ScoEventSubTypeCd {

    for ( const obj in ScoEventSubTypeCd ) {
      if (ScoEventSubTypeCd[obj] === value){
        return ScoEventSubTypeCd[obj] as ScoEventSubTypeCd;
      }
    }
  }
}

const ScoEventSubTypeCdHelper = new ScoEventSubTypeCdHelperEnumHelperClass();
export default ScoEventSubTypeCdHelper;
