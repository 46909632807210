import { Directive } from '@angular/core';

/**
 * Directive that allows supress the default placeholder for inputs
 */
@Directive({
  selector:
    'input[xpoDateRangeStartInput][xpoDateInputSuprressPlaceholder],input[xpoDateRangeEndInput][xpoDateInputSuprressPlaceholder]',
})
export class XpoDateRangeInputSupressPlaceholder {}
