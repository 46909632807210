import {MetricThresholdValueTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class MetricThresholdValueTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MetricThresholdValueTypeCd ) {
      this._values.push(MetricThresholdValueTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MetricThresholdValueTypeCd {

    for ( const obj in MetricThresholdValueTypeCd ) {
      if (MetricThresholdValueTypeCd[obj] === value){
        return MetricThresholdValueTypeCd[obj] as MetricThresholdValueTypeCd;
      }
    }
  }
}

const MetricThresholdValueTypeCdHelper = new MetricThresholdValueTypeCdHelperEnumHelperClass();
export default MetricThresholdValueTypeCdHelper;
