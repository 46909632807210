import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyCode'
})
export class CurrencyCodePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (!value || value.toLowerCase().startsWith('usdollar')) {
      return 'USD';
    } else if (value.toLowerCase().startsWith('canadiandollar')) {
      return 'CAD';
    }
    return value;
  }

}
