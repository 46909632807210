import {SortOrderCd} from '../';
import {EnumHelper} from './enum-helper';

export class SortOrderCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in SortOrderCd ) {
      this._values.push(SortOrderCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): SortOrderCd {

    for ( const obj in SortOrderCd ) {
      if (SortOrderCd[obj] === value){
        return SortOrderCd[obj] as SortOrderCd;
      }
    }
  }
}

const SortOrderCdHelper = new SortOrderCdHelperEnumHelperClass();
export default SortOrderCdHelper;
