
export enum PickupStatusCd {
	ASSIGNED = 'Assigned',
	CANCELLED = 'Cancelled',
	COMPLETED = 'Completed',
	CONFIRM_ON_BOARD = 'ConfirmOnBoard',
	DELETED = 'Deleted',
	DISPATCHED = 'Dispatched',
	MERGED_TO = 'MergedTo',
	NEW = 'New',
	PRE_ASSIGNED = 'Pre-assigned',
	SCHEDULED = 'Scheduled'}

