import {JobSelectionCd} from '../';
import {EnumHelper} from './enum-helper';

export class JobSelectionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in JobSelectionCd ) {
      this._values.push(JobSelectionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): JobSelectionCd {

    for ( const obj in JobSelectionCd ) {
      if (JobSelectionCd[obj] === value){
        return JobSelectionCd[obj] as JobSelectionCd;
      }
    }
  }
}

const JobSelectionCdHelper = new JobSelectionCdHelperEnumHelperClass();
export default JobSelectionCdHelper;
