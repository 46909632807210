import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { XpoChipsModule, XpoIconModule } from '@xpo-ltl/ngx-ltl-core';

import { XpoBoardViews } from './board-views.component';
import { XpoBoardViewsAlternateTabs, XpoNoBorderTabs } from './directives/index';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatMenuModule, MatTooltipModule, XpoIconModule, XpoChipsModule],
  declarations: [
    XpoBoardViews,
    // tslint:disable-next-line: deprecation
    XpoNoBorderTabs,
    XpoBoardViewsAlternateTabs,
  ],
  exports: [
    XpoBoardViews,
    // tslint:disable-next-line: deprecation
    XpoNoBorderTabs,
    XpoBoardViewsAlternateTabs,
  ],
})
export class XpoBoardViewsModule {}
