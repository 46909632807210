import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { XpoCriteriaStore, XpoFilter } from '@xpo-ltl/ngx-ltl-board';
import { AgFrameworkComponent } from 'ag-grid-angular';
import { FilterChangedEvent, IAfterGuiAttachedParams, IFloatingFilter } from 'ag-grid-community';

export interface XpoAgGridFilterChipComponentParams {
  filter: XpoFilter;
  criteriaStore: XpoCriteriaStore;
}

@Component({
  selector: 'xpo-ag-grid-filter-chip',
  templateUrl: './ag-grid-filter-chip.component.html',
  styleUrls: ['./ag-grid-filter-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-AgGridFilterChip',
  },
})
export class XpoAgGridFilterChipComponent
  implements IFloatingFilter, AgFrameworkComponent<XpoAgGridFilterChipComponentParams> {
  filter: XpoFilter;
  criteriaStore: XpoCriteriaStore;

  agInit(params: XpoAgGridFilterChipComponentParams): void {
    this.filter = params.filter;
    this.criteriaStore = params.criteriaStore;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent): void {}
}
