import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { cloneDeep} from 'lodash';
import { XpoFacetFilterTypeControls } from '../../share/enums/type-list-control';
import { XpoFacetFilterCriteria } from '../../share/model/facet-model-criteria.interface';
import { XpoFacetFilterStringOperators, XpoFacetFilterTypeOperator } from '../../share/model/filter-criteria.interface';
import { XpoFacetFilterListItemModel } from '../../share/model/list-items.interface';
import { XpoFilterControlBaseComponent } from '../filter-control-base.component';
import { sortItems } from '../../share/utils/general';
import { XpoFacetFilterSortOptions } from '../../share/enums/sort-options';

@Component({
  selector: 'xpo-option-facet-filter',
  templateUrl: './filter-control-option.component.html',
  styleUrls: ['./filter-control-option.component.scss'],
  providers: [{ provide: XpoFilterControlBaseComponent, useExisting: forwardRef(() => XpoFacetFilterOptionControlComponent) }],
})
export class XpoFacetFilterOptionControlComponent
  extends XpoFilterControlBaseComponent<XpoFacetFilterListItemModel[] | XpoFacetFilterListItemModel> implements OnInit {
  // To keep an original copy of the filterModel the user passed in.
  originalFilterModel: XpoFacetFilterListItemModel[] = [];

  typeControl: XpoFacetFilterTypeControls = XpoFacetFilterTypeControls.Option;
  selectedItem: XpoFacetFilterListItemModel;

  @Input()
  filterModel: XpoFacetFilterListItemModel[] = [];

  @Input()
  sort: XpoFacetFilterSortOptions | undefined = undefined;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.filterModel?.sort((current, next) => sortItems(this.sort, current.label, next.label));
    this.originalFilterModel = cloneDeep(this.filterModel);
    super.checkInit();
  }

  /**
   * Method which allows setting model from external source
   * @param newItems
   */
  setModel(model: XpoFacetFilterListItemModel[]): void {
    this.filterModel = model;
    this.filterModel?.sort((current, next) => sortItems(this.sort, current.label, next.label));
    this.originalFilterModel = cloneDeep(this.filterModel);
  }

  updateSelected(item: XpoFacetFilterListItemModel): void {
    this.clearSelections();
    item.selected = true;
    this.selectedItem = item;
    this.applyFilter();
  }

  applyFilter(): void {
    this.onValueChanged(this.selectedItem, this.mapFacetFilterControlToCriteria(this.selectedItem));
  }

  private clearSelections(): void {
    this.filterModel.map((el) => (el.selected = false));
    this.selectedItem = null;
  }

  onReset(): void {
    super.onReset();
    this.filterModel = cloneDeep(this.originalFilterModel);
    this.selectedItem = null;
    this.applyFilter();
  }

  mapFacetFilterControlToCriteria(item: XpoFacetFilterListItemModel): XpoFacetFilterCriteria {
    return {
      filterId: this.filterId,
      type: XpoFacetFilterTypeOperator.String,
      conditions: [{ operator: XpoFacetFilterStringOperators.EQUAL, value: item?.value || null }],
      invalid: !this.isValidCondition(),
    };
  }

  isValidCondition(): boolean {
    return !!this.selectedItem;
  }
}
