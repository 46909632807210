import { Injectable } from '@angular/core';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../shared/enums/config-manager-properties.enum';
import { ModuleGroupsService } from './module-groups.service';

@Injectable()
export class ModuleGroupsBoardDataSource extends XpoBoardDataSource {
  constructor(protected moduleGroupsService: ModuleGroupsService, private configManagerService: ConfigManagerService) {
    super();
    this.pageSize = 100000;
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    const dynamicDAGDisabled =
      this.configManagerService.getSetting(ConfigManagerProperties.dynamicDAGDisabled) || false;
    const query: any = {
      dynamicDagInd: dynamicDAGDisabled ? 'false' : null,
      listInfo: {
        levelOfDetail: 'RunStatus',
      },
    };
    return this.moduleGroupsService
      .listModuleGroups(query)
      .pipe(map((rows) => new XpoBoardData(state, rows, rows.length, this.pageSize)));
  }
}
