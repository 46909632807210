import {MatchedPartySourceCd} from '../';
import {EnumHelper} from './enum-helper';

export class MatchedPartySourceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MatchedPartySourceCd ) {
      this._values.push(MatchedPartySourceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MatchedPartySourceCd {

    for ( const obj in MatchedPartySourceCd ) {
      if (MatchedPartySourceCd[obj] === value){
        return MatchedPartySourceCd[obj] as MatchedPartySourceCd;
      }
    }
  }
}

const MatchedPartySourceCdHelper = new MatchedPartySourceCdHelperEnumHelperClass();
export default MatchedPartySourceCdHelper;
