import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { EMPTY, Observable, of as observableOf, ReplaySubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { XpoBoardViewDataStore } from '../board-views/index';
import { XpoDataDrawer } from '../data-drawer/index';
import { XpoApplyFilterStrategy, XpoFiltersService } from '../filters/index';
import { XpoBoardDataSourceResolver } from '../models/board-data-source-resolver.service';
import { XpoBoardDataSource } from '../models/board-data-source.model';
import { XpoBoardOptions } from '../models/board-options.model';
import { XpoBoardStateViewer } from '../models/board-state-viewer.model';
import { XpoBoardState } from '../models/board-state.model';
import { isExportableDataSource } from '../models/exportable-data-source';
import { XpoBoardView, XpoBoardViewTemplate, XPO_BOARD_DEFAULT_OPTIONS } from '../models/index';
import { XpoBoardActionsVisibilityService, XpoBoardActionType } from './board-actions/index';
import { XpoBoardApiDispatcherService } from './board-api/index';
import { XpoBoardApi, XpoBoardReadyEvent } from './board-api/models/index';
import { XpoBoardGridToolbar } from './board-grid-toolbar/board-grid-toolbar.component';
import { XpoBoardTotalRecords } from './board-total-records/board-total-records.component';
import { XpoBoardService } from './board.service';

let nextUniqueId = 0;

@Directive({
  selector: 'xpo-board-actions',
  host: { class: 'xpo-BoardActions' },
})
export class XpoBoardActions {}

@Component({
  selector: 'xpo-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss'],
  providers: [
    XpoBoardDataSourceResolver,
    XpoBoardService,
    XpoBoardActionsVisibilityService,
    XpoBoardApiDispatcherService,
    XpoFiltersService,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-Board', '[attr.id]': 'id' },
})
export class XpoBoard implements OnDestroy, XpoBoardStateViewer, AfterViewInit {
  dataDrawerOpen: boolean = false;
  stateChange$: ReplaySubject<XpoBoardState>;
  currentState: XpoBoardState;
  viewTemplates$: Observable<XpoBoardViewTemplate[]>;
  XpoBoardActionType = XpoBoardActionType;
  showExportButton = false;
  showFiltersButton: boolean = false;
  disableExportButton$: Observable<boolean> = EMPTY;
  boardApi: XpoBoardApi;

  private uid = `xpo-Board-${nextUniqueId++}`;
  private idValue: string = this.uid;
  private componentDestroyed$: Subject<void> = new Subject<void>();
  private dataDrawerValue: XpoDataDrawer;
  private dataDrawerVisibilitySubscription: Subscription;
  private boardOptionsValue: XpoBoardOptions = {};

  // Fetch board actions to hide/show grid bar
  @ContentChild(XpoBoardActions)
  boardActions: XpoBoardActions;

  @ContentChild(XpoBoardTotalRecords)
  boardTotalRecords: XpoBoardTotalRecords;

  @ContentChild(XpoBoardGridToolbar, { static: false })
  gridToolbar: XpoBoardGridToolbar;

  // User a getter/setter here to handle cases where data drawer has an *ngIf attached
  @ContentChild(XpoDataDrawer)
  get dataDrawer(): XpoDataDrawer {
    return this.dataDrawerValue;
  }
  set dataDrawer(value: XpoDataDrawer) {
    if (this.dataDrawerVisibilitySubscription) {
      this.dataDrawerVisibilitySubscription.unsubscribe();
    }

    this.dataDrawerValue = value;
    if (this.dataDrawerValue) {
      this.dataDrawerVisibilitySubscription = this.dataDrawerValue.visibilityChange
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((visibility: boolean) => {
          this.dataDrawerOpen = visibility;
          this.cd.markForCheck();
        });
    }
  }

  @Input()
  get id(): string {
    return this.idValue;
  }
  set id(value: string) {
    this.idValue = value || this.uid;
  }

  /** Data Source of the board */
  @Input()
  get dataSource(): XpoBoardDataSource {
    return this.dataSourceValue;
  }
  set dataSource(value: XpoBoardDataSource) {
    if (this.dataSourceValue) {
      this.dataSourceValue.disconnect(this);
    }

    this.dataSourceValue = value;
    this.dataSourceValue
      .connect(this)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((state) => this.handleStateChange(state));

    this.dataSourceValue.connect(this.boardApi);
    this.boardDataSourceResolver.dataSource = value;
    this.initializeExportButton();
  }
  private dataSourceValue: XpoBoardDataSource;

  /** Views to display, can be used instead of setting the viewDataStore */
  @Input()
  get views(): XpoBoardView[] {
    return this.boardService.views;
  }
  set views(v: XpoBoardView[]) {
    this.actionsVisibilityService.views = this.boardService.views = v;
  }

  /** Available View Templates */
  @Input()
  get viewTemplates(): XpoBoardViewTemplate[] {
    return this.boardService.viewTemplates;
  }
  set viewTemplates(value: XpoBoardViewTemplate[]) {
    this.actionsVisibilityService.viewTemplates = this.boardService.viewTemplates = value;
  }

  /** Data store that can provide the list of views */
  @Input()
  get viewDataStore(): XpoBoardViewDataStore {
    return this.boardService.viewDataStore;
  }
  set viewDataStore(value: XpoBoardViewDataStore) {
    this.actionsVisibilityService.viewDataStore = this.boardService.viewDataStore = value;
  }

  /** Configuration object to control child components options */
  @Input()
  get boardOptions(): XpoBoardOptions {
    return this.boardOptionsValue;
  }
  set boardOptions(boardOptions: XpoBoardOptions) {
    this.boardOptionsValue = Object.assign(
      boardOptions,
      // Keep global option properties only if they are not explicitly set on the input
      ...Object.keys(this.globalBoardOptions || {})
        .filter((globalKey) => !boardOptions.hasOwnProperty(globalKey))
        .map((key) => ({ [key]: this.globalBoardOptions[key] }))
    );
    this.boardService.boardOptions = this.boardOptionsValue;
  }

  /**
   * @deprecated  use the boardOptions input with "addNewViewButtonLabel: 'value'"
   * Text displayed next to the + icon for adding new views.
   * */
  @Input()
  get addBoardViewLabel(): string {
    return this.boardOptionsValue.addNewViewButtonLabel;
  }

  set addBoardViewLabel(value: string) {
    this.setDeprecatedPropertyOnOptions('addNewViewButtonLabel', coerceBooleanProperty(value));
  }

  /**
   * @deprecated  use the boardOptions input with "enableFilterReset: true"
   * Shows the Reset button in the filter bar
   * */
  @Input()
  get enableFilterReset(): boolean {
    return this.boardOptions.enableFilterReset;
  }
  set enableFilterReset(value: boolean) {
    this.setDeprecatedPropertyOnOptions('enableFilterReset', coerceBooleanProperty(value));
  }

  /**
   * @deprecated  use the boardOptions input with "enableFilterReset: true"
   * Adds the current view name to query params to it's persisted in the url
   * */
  @Input()
  get enableQueryParamStatePersistance(): boolean {
    return this.boardOptions.enableQueryParamStatePersistance;
  }
  set enableQueryParamStatePersistance(value: boolean) {
    this.setDeprecatedPropertyOnOptions('enableQueryParamStatePersistance', coerceBooleanProperty(value));
  }

  /**
   * @deprecated  use the boardOptions input with "hideViewSwitcher: true"
   * Hide board view tab switcher
   * */
  @Input()
  get hideViewSwitcher(): boolean {
    return this.boardOptions.suppressViewSwitcher;
  }
  set hideViewSwitcher(value: boolean) {
    this.setDeprecatedPropertyOnOptions('hideViewSwitcher', coerceBooleanProperty(value), 'suppressViewSwitcher');
  }

  /**
   * @deprecated  use the boardOptions input with "suppressGridSettingsPopover: true"
   * Hide board view settings menu
   * */
  @Input()
  get hideViewSettingsMenu(): boolean {
    return this.boardOptions.suppressGridSettingsPopover;
  }
  set hideViewSettingsMenu(value: boolean) {
    this.setDeprecatedPropertyOnOptions(
      'hideViewSettingsMenu',
      coerceBooleanProperty(value),
      'suppressGridSettingsPopover'
    );
  }

  /**
   * @deprecated  use the boardOptions input with "preloadViewData: true"
   * Whether or not the board preloads all view data
   * */
  @Input()
  get preloadViewData(): boolean {
    return this.boardOptions.preloadViewData;
  }
  set preloadViewData(value: boolean) {
    this.setDeprecatedPropertyOnOptions('preloadViewData', coerceBooleanProperty(value));
  }

  /**
   * @deprecated  use the boardOptions input with "applyFilterStrategy"
   * Options on when to apply the filters values
   * */
  @Input()
  get applyFilterStrategy(): XpoApplyFilterStrategy {
    return this.boardOptions.applyFilterStrategy;
  }
  set applyFilterStrategy(value: XpoApplyFilterStrategy) {
    this.setDeprecatedPropertyOnOptions('applyFilterStrategy', value);
  }

  /**
   * @deprecated  use the boardOptions input with "hideRecordCounts: true"
   * If true will hide the total number of records displayed in the view tabs
   * */
  @Input()
  get showRecordCounts(): boolean {
    return !this.boardOptions.suppressRecordCounts;
  }
  set showRecordCounts(value: boolean) {
    const hideValue = !coerceBooleanProperty(value);
    this.setDeprecatedPropertyOnOptions('showRecordCounts', hideValue, 'suppressRecordCounts');
  }

  /**
   * @deprecated  use the boardOptions input with "persistFiltersBetweenViews: true"
   * If true will persist the filter values when switching between views
   * */
  @Input()
  get persistFiltersBetweenViews(): boolean {
    return this.boardOptions.persistFiltersBetweenViews;
  }
  set persistFiltersBetweenViews(value: boolean) {
    this.setDeprecatedPropertyOnOptions('persistFiltersBetweenViews', coerceBooleanProperty(value));
  }

  @Output()
  boardReady = new EventEmitter<XpoBoardReadyEvent>();

  @Output()
  selectionChange = new EventEmitter<any[]>();

  constructor(
    private boardDataSourceResolver: XpoBoardDataSourceResolver,
    private boardService: XpoBoardService,
    private actionsVisibilityService: XpoBoardActionsVisibilityService,
    private boardApiService: XpoBoardApiDispatcherService,
    private cd: ChangeDetectorRef,
    private filterService: XpoFiltersService,
    @Optional() @Inject(XPO_BOARD_DEFAULT_OPTIONS) private globalBoardOptions: XpoBoardOptions
  ) {
    this.stateChange$ = this.boardService.stateChange$;
    this.viewTemplates$ = this.boardService.viewTemplates$;
    this.boardApi = new XpoBoardApi(this.boardApiService);

    if (this.globalBoardOptions) {
      this.boardOptions = { ...this.globalBoardOptions, ...this.boardOptions };
    }
  }

  ngAfterViewInit(): void {
    this.boardReady.emit({ boardApi: this.boardApi, filterService: this.filterService });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  isActionVisible(actionType: XpoBoardActionType): boolean {
    return this.actionsVisibilityService.isVisible(actionType);
  }

  private handleStateChange(state: XpoBoardState): void {
    this.currentState = state;
    this.checkAndEmitSelectionChanges(state);
    this.checkFiltersButtonVisibility(state);
  }

  private checkAndEmitSelectionChanges(state: XpoBoardState): void {
    if (state && state.changes && state.changes.includes('selection')) {
      this.selectionChange.emit(state.selection);
    }
  }

  private checkFiltersButtonVisibility(state: XpoBoardState): void {
    if (state && state.changes && state.changes.includes('filtersButtonVisible')) {
      this.showFiltersButton = state.filtersButtonVisible;
    }
  }

  private initializeExportButton(): void {
    // If the dataSource is an exportable data source, show export button
    this.showExportButton = isExportableDataSource(this.dataSource);

    // If the dataSource is an exportable data source, if the isExportDisabled function is
    // implemented in that class, call that function to get the disabled value, if not,
    // we will not disable the export button
    this.disableExportButton$ =
      isExportableDataSource(this.dataSource) && this.dataSource.isExportDisabled
        ? this.dataSource.isExportDisabled()
        : observableOf(false);
  }

  private setDeprecatedPropertyOnOptions(propertyName: string, value: any, newPropertyName?: string): void {
    const newPropName = newPropertyName || propertyName;

    console.warn(`[${propertyName}] is deprecated, use the boardOptions input with "${newPropName}: true"`);

    if (!this.boardOptions) {
      this.boardOptions = {};
    }

    this.boardOptions[newPropName] = value;
  }
}
