import {LhoEventTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class LhoEventTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LhoEventTypeCd ) {
      this._values.push(LhoEventTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LhoEventTypeCd {

    for ( const obj in LhoEventTypeCd ) {
      if (LhoEventTypeCd[obj] === value){
        return LhoEventTypeCd[obj] as LhoEventTypeCd;
      }
    }
  }
}

const LhoEventTypeCdHelper = new LhoEventTypeCdHelperEnumHelperClass();
export default LhoEventTypeCdHelper;
