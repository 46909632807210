import { GridOptions } from 'ag-grid-community';

/* Components */
import { ModuleExecutorDateTimeRenderComponent } from '../../shared/cell-renderers/date-time-cell-renderer/date-time-cell-renderer.component';
import {
  ModuleLibraryActionsCellRendererComponent,
  ModuleLibraryNameCellRenderComponent,
} from '../cell-renderers/index';

export const MODULE_LIBRARY_GRID_OPTIONS: GridOptions = {
  defaultColDef: {
    suppressMenu: false,
    sortable: true,
    resizable: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
  },
  autoGroupColumnDef: {
    headerName: 'Name',
    field: 'moduleName',
    filter: 'agTextColumnFilter',
    minWidth: 200,
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: 'xmeNameCellRenderer',
    },
  },
  frameworkComponents: {
    xmeDateTimeCellRenderer: ModuleExecutorDateTimeRenderComponent,
    xmeNameCellRenderer: ModuleLibraryNameCellRenderComponent,
    xmeActionsCellRenderer: ModuleLibraryActionsCellRendererComponent,
  },
  groupSelectsChildren: false,
  suppressRowClickSelection: true,
  context: { setSelectedModule: () => { } },
  suppressMovableColumns: false,
};
