import {ScoEventTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ScoEventTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ScoEventTypeCd ) {
      this._values.push(ScoEventTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ScoEventTypeCd {

    for ( const obj in ScoEventTypeCd ) {
      if (ScoEventTypeCd[obj] === value){
        return ScoEventTypeCd[obj] as ScoEventTypeCd;
      }
    }
  }
}

const ScoEventTypeCdHelper = new ScoEventTypeCdHelperEnumHelperClass();
export default ScoEventTypeCdHelper;
