import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[xpo-max-number-length]',
})
export class XpoMaxNumberLengthDirective {
  @Input('xpo-max-number-length') xpoMaxLength: number;

  constructor(public elementRef: ElementRef) {}

  @HostListener('paste', ['$event']) onPaste($event) {
    const data = $event.clipboardData.getData('text');
    if (
      (data && data.length > this.xpoMaxLength) ||
      data.length + this.elementRef.nativeElement.value.length > this.xpoMaxLength
    ) {
      return false;
    }
  }

  @HostListener('keypress', ['$event']) onKeyPress($event) {
    if ($event.target.value && $event.target.value.length >= this.xpoMaxLength) {
      return false;
    }
  }
}
