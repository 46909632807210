import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ConfigManagerModule } from '@xpo-ltl/config-manager';
import { XpoButtonModule, XpoDialogModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoLtlRoleSwitcherComponent } from './role-switcher.component';

@NgModule({
  declarations: [XpoLtlRoleSwitcherComponent],
  imports: [
    CommonModule,
    XpoDialogModule,
    ConfigManagerModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    XpoButtonModule,
  ],
  exports: [XpoLtlRoleSwitcherComponent],
  entryComponents: [XpoLtlRoleSwitcherComponent],
})
export class XpoLtlRoleSwitcherModule {}
