import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'xpo-grid-bar',
  templateUrl: 'grid-bar.component.html',
  styleUrls: ['grid-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-GridBar' },
})
export class XpoGridBar {}
