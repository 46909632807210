import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

/* XPO */
import { XpoBoardOptions } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardColumn } from '@xpo-ltl/ngx-ltl-board-grid';
import { DetailGridInfo, GridApi, GridOptions, RowNode } from 'ag-grid-community';

/* Rxjs */
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';

/* Services */
import { ModuleGroupManagementService } from '../../../services/module-group-management.service';

/* Models */
import { MODULE_GROUP_MGMT_EXECUTION_STEP_COLUMNS } from './models/module-group-mgmt-execution-step-columns';
import { MODULE_GROUP_MGMT_EXECUTION_STEP_GRID_OPTIONS } from './models/module-group-mgmt-execution-step-grid-options';

/* Components */
import { XpoConfirmDialog, XpoConfirmDialogConfig } from '@xpo-ltl/ngx-ltl-core';
import { ModuleExecutorParmsArgTypeCd } from '@xpo-ltl/sdk-common';
import { ModuleGroupModuleVersion } from '@xpo-ltl/sdk-moduleexecutor';

@Component({
  selector: 'module-group-mgmt-execution-step',
  templateUrl: './module-group-mgmt-execution-step.component.html',
  styleUrls: ['./module-group-mgmt-execution-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ModuleGroupMgmtExecutionStep',
  },
})
export class ModuleGroupMgmtExecutionStepComponent implements OnInit, OnDestroy {
  @Input() id: number;
  @Input() removable: boolean;

  private gridApi: GridApi;

  readonly gridOptions: GridOptions;
  readonly columnDefs: XpoAgGridBoardColumn[];
  readonly boardOptions: XpoBoardOptions = {
    suppressViewSwitcher: true,
    suppressGridSettingsPopover: true,
  };

  rowData$: Observable<ModuleGroupModuleVersion[]>;

  selectedRows: any;

  execOrderLoopInd: BehaviorSubject<boolean>;
  execOrderLoopInd$: Observable<boolean>;

  dagScriptText: string;

  /* Subscriptions */
  subscriptions: Subscription;

  isDagDisable: boolean;

  set isExecOrderLoopInd(value: boolean) {
    this.execOrderLoopInd.next(value);
  }

  get isExecOrderLoopInd(): boolean {
    return this.execOrderLoopInd.getValue();
  }

  constructor(
    private moduleGroupManagementService: ModuleGroupManagementService,
    private confirmDialog: XpoConfirmDialog
  ) {
    this.execOrderLoopInd = new BehaviorSubject<boolean>(false); // Initial value
    this.execOrderLoopInd$ = this.execOrderLoopInd.asObservable();

    this.columnDefs = MODULE_GROUP_MGMT_EXECUTION_STEP_COLUMNS;
    this.gridOptions = {
      ...MODULE_GROUP_MGMT_EXECUTION_STEP_GRID_OPTIONS,
      ...{
        context: {
          deleteModule: this.onDeleteModule.bind(this),
          execOrderLoopInd$: this.execOrderLoopInd$,
        },
      },
    };
    this.gridOptions.onSelectionChanged = () => this._selectionChanged();
    this.selectedRows = [];
    this.subscriptions = new Subscription();
    this.dagScriptText = null;
  }

  ngOnInit() {
    const step = this.moduleGroupManagementService.getStepById(this.id);
    const stepRows = this.moduleGroupManagementService.getStepRowsByStepId(this.id) || [];

    this.dagScriptText = this.moduleGroupManagementService.getDagScript();

    this.isExecOrderLoopInd = step ? step.execOrderLoopInd : false;

    this.rowData$ = of(stepRows);
    // Listen to Dag Script Change
    this.moduleGroupManagementService.onDagScriptChange().subscribe((dagScriptText) => {
      this.dagScriptText = dagScriptText;
      if (this.dagScriptText) {
        this.isExecOrderLoopInd = true;
      }
    });
    this.onSelectedPeriodCdChanges();
    this.isDagDisable = this.moduleGroupManagementService.getDagDisable();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.dagScriptText = null;
  }

  /**
   * @name onSelectedPeriodCdChanges
   * @description listener for periodCd changes. expands argument forms with dataset type arguments
   */
  onSelectedPeriodCdChanges(): void {
    const onSelectedPeriodCdChangeSubscription = this.moduleGroupManagementService
      .onSelectedPeriodCdChange()
      .subscribe(() => {
        if (this.gridApi) {
          this.gridApi.forEachNode((node) => {
            const expand =
              (node.data &&
                node.data.moduleGroupExecParm &&
                node.data.moduleGroupExecParm.some(
                  (obj) => obj.argumentTypeCd === ModuleExecutorParmsArgTypeCd.DATA_SET
                )) ||
              false;
            if (expand) {
              setTimeout(() => node.setExpanded(true), 0);
            }
          });
        }
      });
    this.subscriptions.add(onSelectedPeriodCdChangeSubscription);
  }

  /**
   * On Grid Ready
   * @param params <DetailGridInfo>
   */
  onGridReady(params: DetailGridInfo): void {
    this.gridApi = params.api;
    this.reOrderRows();
    // Listen to execOrderLoopInd changes
    this.execOrderLoopInd$.subscribe((res) => {
      this.setExecOrderLoopIndToAllRowsStep();
    });
  }

  setExecOrderLoopIndToAllRowsStep() {
    const step = this.moduleGroupManagementService.getStepById(this.id);
    if (step && step.rows) {
      const rowsToUpdate =
        (step.rows &&
          step.rows.map((row) => {
            row.execOrderLoopInd = this.isExecOrderLoopInd;
            return row;
          })) ||
        [];
      if (rowsToUpdate.length > 0) {
        this.moduleGroupManagementService.setStepRowsByStepId(step.id, rowsToUpdate);
      }
    }
  }

  deleteStep() {
    const openDialogConfirmDeleteStepSubscription = this.openDialogConfirmDeleteStep().subscribe((response) => {
      if (response) {
        this.moduleGroupManagementService.deleteStep(this.id);
      }
    });
    this.subscriptions.add(openDialogConfirmDeleteStepSubscription);
  }

  addRows(newRows: ModuleGroupModuleVersion[], uniqueRowsOnly: boolean = false): void {
    if (uniqueRowsOnly) {
      newRows = this.moduleGroupManagementService.getUniqueRowsByStepId(this.id, newRows);
    }
    const impactedRowNodes = this.gridApi.updateRowData({ add: newRows });
    const impactedRowsData = impactedRowNodes.add.map((impactedRowNode, index) => impactedRowNode.data);
    this.moduleGroupManagementService.addStepRows(this.id, impactedRowsData);
    this.reOrderRows();
  }

  deleteRows() {
    const impactedRowNodes = this.gridApi.updateRowData({ remove: this.selectedRows });
    const impactedRowsData = impactedRowNodes.remove.map((impactedRowNode, index) => impactedRowNode.data);
    this.moduleGroupManagementService.deleteStepRows(this.id, impactedRowsData);
    this.reOrderRows();
  }

  onExecOrderLoopIndChange(e) {
    // Prevent checkbox native check
    e.preventDefault();

    if (this.isExecOrderLoopInd) {
      // Check if exist any dynamic argument in the step
      const step = this.moduleGroupManagementService.getStepById(this.id);

      // Check for existing dynamics arguments in the step
      const existDynamicArg = step.rows.some((row) => {
        return (
          row.moduleGroupExecParm &&
          row.moduleGroupExecParm.some((obj) => obj.argumentTypeCd === ModuleExecutorParmsArgTypeCd.DYNAMIC_CONFIG)
        );
      });

      if (existDynamicArg) {
        const openDialogConfirmDeleteConfigArgsSubscription = this.openDialogConfirmDeleteConfigArgs().subscribe(
          (response) => {
            if (response) {
              // Get rows to be updated by deleting those DYNAMIC_CONFIG types
              const rowsToUpdate = step.rows.map((row) => {
                row.moduleGroupExecParm =
                  (row.moduleGroupExecParm &&
                    row.moduleGroupExecParm.filter(
                      (exeParam) => exeParam.argumentTypeCd !== ModuleExecutorParmsArgTypeCd.DYNAMIC_CONFIG
                    )) ||
                  [];
                return row;
              });
              if (rowsToUpdate.length > 0) {
                this.moduleGroupManagementService.setStepRowsByStepId(step.id, rowsToUpdate);
              }
              this.isExecOrderLoopInd = !this.isExecOrderLoopInd;
            }
          }
        );
        this.subscriptions.add(openDialogConfirmDeleteConfigArgsSubscription);
      } else {
        this.isExecOrderLoopInd = !this.isExecOrderLoopInd;
      }
    } else {
      this.isExecOrderLoopInd = !this.isExecOrderLoopInd;
    }
  }

  onRowDragMove(e) {
    this.reOrderRows();
  }

  private onDeleteModule(rowNode: RowNode): void {
    const clickedRows = rowNode.group ? rowNode.childrenAfterGroup.map((item) => item.data) : [rowNode.data];
    const impactedRowNodes = this.gridApi.updateRowData({ remove: clickedRows });
    const impactedRowsData = impactedRowNodes.remove.map((impactedRowNode, index) => impactedRowNode.data);
    const renderedNodes = this.gridApi.getRenderedNodes();
    this.moduleGroupManagementService.deleteStepRows(this.id, impactedRowsData);
    /* Delete Step if current step rendered Nodes is 0 and if the current Step is not the first one */
    const steps = this.moduleGroupManagementService.getSteps();
    if (steps.length > 1 && renderedNodes.length === 0) {
      this.moduleGroupManagementService.deleteStep(this.id);
    }
    this.reOrderRows();
  }

  private reOrderRows() {
    const rowsToUpdate = [];
    this.gridApi.forEachNode((node) => {
      node.data.execOrderSequenceNbr = node.childIndex + 1;
      node.setData(node.data);
      rowsToUpdate.push(node.data);
    });
    if (rowsToUpdate.length > 0) {
      this.moduleGroupManagementService.setStepRowsByStepId(this.id, rowsToUpdate);
    }
  }

  private _selectionChanged() {
    this.selectedRows = this.gridApi.getSelectedRows();
  }

  private openDialogConfirmDeleteStep(): Observable<boolean> {
    const confirmDialogConfig: XpoConfirmDialogConfig = {
      confirmButtonText: 'YES',
      confirmButtonColor: 'primary',
      rejectButtonText: 'NO',
      rejectButtonColor: 'accent',
      icon: 'warning',
      showCancelButton: false,
    };
    return this.confirmDialog.confirm(
      'Are you sure you want to delete step ' + this.id + '?',
      'Delete Step Confirmation',
      confirmDialogConfig
    );
  }

  private openDialogConfirmDeleteConfigArgs(): Observable<boolean> {
    const confirmDialogConfig: XpoConfirmDialogConfig = {
      confirmButtonText: 'YES',
      confirmButtonColor: 'primary',
      rejectButtonText: 'NO',
      rejectButtonColor: 'accent',
      icon: 'warning',
      showCancelButton: false,
    };
    return this.confirmDialog.confirm(
      'All Dynamic Configuration Arguments will be deleted from this step. Are you sure you want to continue?',
      'Delete Dynamic Configuration Arguments from step ' + this.id,
      confirmDialogConfig
    );
  }
}
