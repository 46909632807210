import { Directive, ElementRef, Inject, Injector, Optional } from '@angular/core';
import { FormGroupDirective, NgForm, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, ErrorStateMatcher, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateRange } from '@angular/material/datepicker';
import { XpoDateRangeBaseInput } from './date-range-base-input.directive';
import { XpoDateRangeInputSupressPlaceholder } from './date-range-input-supress-placeholder.directive';

/**
 * The end date input control
 */
@Directive({
  selector: 'input[xpoDateRangeEndInput]',
  host: {
    class: 'xpo-DateRangeInput-inner',
    type: 'text',
    autocomplete: 'off',
    '[attr.maxlength]': 'getMaxLength()',
    '(input)': 'onInput($event.target.value)',
    '(focus)': 'onFocus()',
    '(blur)': 'onBlur()',
  },
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: XpoDateRangeEndInput, multi: true },
    { provide: NG_VALIDATORS, useExisting: XpoDateRangeEndInput, multi: true },
  ],
})
export class XpoDateRangeEndInput<D> extends XpoDateRangeBaseInput<D> {
  constructor(
    protected elementRef: ElementRef<HTMLInputElement>,
    @Optional() protected parentForm: NgForm,
    @Optional() protected parentFormGroup: FormGroupDirective,
    protected dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) protected dateFormats: MatDateFormats,
    protected injector: Injector,
    protected errorStateMatcher: ErrorStateMatcher,
    @Optional() protected supressPlaceholder: XpoDateRangeInputSupressPlaceholder
  ) {
    // (from material): this constructor shouldn't be necessary, but ViewEngine doesn't seem to
    // handle DI correctly when it is inherited from `XpoDateRangeBaseInput`. We can drop this
    // constructor once ViewEngine is removed.
    super(
      elementRef,
      parentForm,
      parentFormGroup,
      dateAdapter,
      dateFormats,
      injector,
      errorStateMatcher,
      supressPlaceholder
    );
  }

  /**
   * Returns the corresponding value: the end selected date
   * @param range
   */
  protected getValueFromRange(range: DateRange<D>): D {
    return range.end;
  }

  /**
   * Returns a new range selection based on the given date as the end date of the range
   * @param date
   */
  protected getNewSelection(date: D): DateRange<D> {
    // create a new range based on the given date
    return new DateRange(this.selectionModel.selection.start, date);
  }
}
