import {ExpectationStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ExpectationStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ExpectationStatusCd ) {
      this._values.push(ExpectationStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ExpectationStatusCd {

    for ( const obj in ExpectationStatusCd ) {
      if (ExpectationStatusCd[obj] === value){
        return ExpectationStatusCd[obj] as ExpectationStatusCd;
      }
    }
  }
}

const ExpectationStatusCdHelper = new ExpectationStatusCdHelperEnumHelperClass();
export default ExpectationStatusCdHelper;
