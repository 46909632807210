import {EquipmentEventSubTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class EquipmentEventSubTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EquipmentEventSubTypeCd ) {
      this._values.push(EquipmentEventSubTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EquipmentEventSubTypeCd {

    for ( const obj in EquipmentEventSubTypeCd ) {
      if (EquipmentEventSubTypeCd[obj] === value){
        return EquipmentEventSubTypeCd[obj] as EquipmentEventSubTypeCd;
      }
    }
  }
}

const EquipmentEventSubTypeCdHelper = new EquipmentEventSubTypeCdHelperEnumHelperClass();
export default EquipmentEventSubTypeCdHelper;
