import { GridOptions } from 'ag-grid-community';

import {
  ModuleGroupMgmtActionsCellRendererComponent,
  ModuleGroupMgmtNameCellRendererComponent,
} from '../cell-renderers';

export const MODULE_GROUP_MGMT_ASSOCIATED_MODULES_GRID_OPTIONS: GridOptions = {
  defaultColDef: {
    suppressMenu: true,
    sortable: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
  },
  floatingFilter: false,
  autoGroupColumnDef: {
    headerName: 'Module Name',
    field: 'moduleName',
    resizable: true,
    filter: 'agTextColumnFilter',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      checkbox: false,
      suppressCount: true,
    },
  },
  frameworkComponents: {
    ActionsCellRenderer: ModuleGroupMgmtActionsCellRendererComponent,
    NameCellRenderer: ModuleGroupMgmtNameCellRendererComponent,
  },
  groupSelectsChildren: true,
  suppressRowClickSelection: true,
};
