
export enum EventLogTypeCd {
	ARCHIVED = 'Archived',
	BILL_ENTRY = 'BillEntry',
	CORRECTIONS = 'Corrections',
	CUSTOMER_MATCH = 'CustomerMatch',
	CUSTOMS = 'Customs',
	DLVY_RCPT_PRINT = 'DlvyRcptPrint',
	DOC_IMAGES = 'DocImages',
	EQUIPMENT_TRACKING = 'EquipmentTracking',
	PICKUP = 'Pickup',
	PURGED = 'Purged',
	RATING = 'Rating',
	SHIPMENT_DLVY = 'ShipmentDlvy',
	STRIP = 'Strip',
	TRLR_CONTENTS = 'TrlrContents'}

