/**
 * Location API
 * Version: 2.0
 * Build: 2.0.0.49
 */

import {
	DataValidationError,
	ListInfo,
	Address,
	LatLong,
	AuditInfo,
	PayloadType,
	SearchRecordMessageHeader,
	XrtAttributeFilter,
	XrtSortExpression,
	XrtSearchRespHeader,
	ActionCd,
	DimensionUomCd,
	EnsembleExecutionStatusCd,
	EnsembleListenerModeCd,
	HierarchyGroupCategoryCd,
	LocationDetailCd,
	LocationHierarchyTypeCd,
	MinuetExecutionStatusCd,
	NextBusinessDayOperationCd,
	NonWorkDayTypeCd,
	PolygonTypeCd,
	ServiceTypeCd,
	TransactionEventDetailTypeCd
} from '@xpo-ltl/sdk-common';

/**
 * Request to delete a location feature.
 */
export class DeleteLocationFeatureSettingsQuery {
	/**
	 * The list of unique identifiers which are associated with Location Feature Settings.
	 * Required: false
	 * Array: true
	 */
	instIds:number[] = undefined;
	/**
	 * The unique identifier for a feature.
	 * Required: false
	 * Array: false
	 */
	featureCd:string = undefined;
	/**
	 * The unique identifier for a SIC.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Determines whether the date provided is a non-work date for the location.
 */
export class DetermineNonWorkDayPath {
	/**
	 * The service center identification
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The date for which this operation will determine whether it is a non-work day for the location.
	 * Required: true
	 * Array: false
	 */
	date:string = undefined;
}
/**
 * Response to the request to determine whether the date provided is a non-work date for the location.
 */
export class DetermineNonWorkDayResp {
	/**
	 * The code that determines the type of non-work day.
	 * Required: true
	 * Array: false
	 */
	nonWorkDayTypeCd:NonWorkDayTypeCd = undefined;
	/**
	 * A description of the non-work day.
	 * Required: true
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Indicates whether Linehaul runs on a non-work day.
	 * Required: true
	 * Array: false
	 */
	linehaulInd:number = undefined;
}
/**
 * Path parameters which are used to determine the service date based on offset.
 */
export class DetermineOperationalServiceDatePath {
	/**
	 * The service center identification code for the origin location where the service will start.
	 * Required: true
	 * Array: false
	 */
	originSicCd:string = undefined;
	/**
	 * The service center identification code for the destination location where the service will end.
	 * Required: true
	 * Array: false
	 */
	destinationSicCd:string = undefined;
}
/**
 * Query parameters which are used to determine the service date based on offset.
 */
export class DetermineOperationalServiceDateQuery {
	/**
	 * The calendar date for which determination of service date based on offset will start.
	 * Required: false
	 * Array: false
	 */
	calendarDate:string = undefined;
	/**
	 * The code associated with the type of service.
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:ServiceTypeCd = undefined;
	/**
	 * The number of days to add to the calendar date.
	 * Required: false
	 * Array: false
	 */
	offsetDaysCount:number = undefined;
	/**
	 * Indicates the next service date should be returned if the offset of 0 returns a non-service date.
	 * Required: false
	 * Array: false
	 */
	findNextServiceDateInd:boolean = undefined;
}
/**
 * Response to the request to determine the service date based on offset.
 */
export class DetermineOperationalServiceDateResp {
	/**
	 * The service date based on the calendar date and offset provided.
	 * Required: false
	 * Array: false
	 */
	serviceDate:string = undefined;
}
/**
 * Request to return the geo area for a location.
 */
export class GetGeoAreasForLocationPath {
	/**
	 * Unique identifier for the service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * Defines what the polygon coordinates represent.  Possible values are: Area (A), Boundary (B), Fence (F), and Service Center (S).
	 * Required: true
	 * Array: false
	 */
	polygonTypeCd:string = undefined;
}
/**
 * Request to return the geo area for a location.
 */
export class GetGeoAreasForLocationQuery {
	/**
	 * The date used to validate active satellite sics.
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * Indicates whether satellite SICs for the parent should be included in the response.
	 * Required: false
	 * Array: false
	 */
	satelliteInd:boolean = undefined;
	/**
	 * Indicates whether geoArea shape data should be included in the response.
	 * Required: false
	 * Array: false
	 */
	geoAreaShapeInd:boolean = undefined;
}
/**
 * Response to the request to return the geo area for a location.
 */
export class GetGeoAreasForLocationResp {
	/**
	 * Details about a geo area.
	 * Required: false
	 * Array: true
	 */
	geoArea:GeoArea[] = undefined;
}
/**
 * Query parameters which are used to retrieve location reference details.
 */
export class GetLocationReferenceDetailsQuery {
	/**
	 * The service center identification code for a location.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The unique identifier for a location.
	 * Required: false
	 * Array: false
	 */
	instanceId:number = undefined;
	/**
	 * The fields that should be returned as part of the response body.
	 * Required: false
	 * Array: true
	 */
	locationDetailCds:LocationDetailCd[] = undefined;
}
/**
 * Response to the request to retrieve location reference details.
 */
export class GetLocationReferenceDetailsResp {
	/**
	 * The location reference details.
	 * Required: false
	 * Array: false
	 */
	locationReference:LocationReference = undefined;
	/**
	 * The Company Operations details.
	 * Required: false
	 * Array: false
	 */
	companyOperations:CompanyOperations = undefined;
	/**
	 * The ConWay Corporation details.
	 * Required: false
	 * Array: false
	 */
	conWayCorporation:ConWayCorporation = undefined;
	/**
	 * The charge to service center identification code.
	 * Required: false
	 * Array: false
	 */
	chargeToSicCd:string = undefined;
	/**
	 * The name of the charge to SIC.
	 * Required: false
	 * Array: false
	 */
	chargeToSicName:string = undefined;
	/**
	 * The area service center identification code.
	 * Required: false
	 * Array: false
	 */
	areaSicCd:string = undefined;
	/**
	 * The region service center identification code.
	 * Required: false
	 * Array: false
	 */
	regionSicCd:string = undefined;
	/**
	 * The satellite service center identification code.
	 * Required: false
	 * Array: false
	 */
	satelliteParentSicCd:string = undefined;
	/**
	 * The name of the satellitle parent SIC.
	 * Required: false
	 * Array: false
	 */
	satelliteParentSicName:string = undefined;
	/**
	 * >The zone parent service center identification code.
	 * Required: false
	 * Array: false
	 */
	zoneParentSicCd:string = undefined;
	/**
	 * The name of the zone parent SIC.
	 * Required: false
	 * Array: false
	 */
	zoneParentSicName:string = undefined;
	/**
	 * The linehaul service center identification code.
	 * Required: false
	 * Array: false
	 */
	linehaulHostSicCd:string = undefined;
	/**
	 * The name of the linehaul host SIC.
	 * Required: false
	 * Array: false
	 */
	linehaulHostSicName:string = undefined;
	/**
	 * The location Hierarchy Group details for the region.
	 * Required: false
	 * Array: false
	 */
	regionLocationHierarchyGroup:HierarchyGroup = undefined;
	/**
	 * The location Hierarchy Group details for the area.
	 * Required: false
	 * Array: false
	 */
	areaLocationHierarchyGroup:HierarchyGroup = undefined;
}
/**
 * Query parameters which are used to retrieve location hierarchy group details.
 */
export class GetLocHierarchyGroupQuery {
	/**
	 * The service center identification code.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The hierarchy category code of the group.
	 * Required: true
	 * Array: true
	 */
	hierarchyGroupCategoryCds:HierarchyGroupCategoryCd[] = undefined;
}
/**
 * Response to the request to retrieve location hierarchy group details.
 */
export class GetLocHierarchyGroupResp {
	/**
	 * The Location Reference details.
	 * Required: false
	 * Array: false
	 */
	locationReference:LocationReference = undefined;
	/**
	 * The location Hierarchy Group details for the SIC.
	 * Required: false
	 * Array: false
	 */
	locationHierarchyGroup:HierarchyGroup = undefined;
}
/**
 * Path parameter used to return operation service center profitability details for the sicCd provided.
 */
export class GetLocOperationsServiceCenterProfitabilityBySicPath {
	/**
	 * The unique identifier for the service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response to the request to return operation service center profitability details for the sicCd provided.
 */
export class GetLocOperationsServiceCenterProfitabilityBySicResp {
	/**
	 * The operation service center profitability details for a location.
	 * Required: false
	 * Array: false
	 */
	locOperationsServiceCentersProfitability:LocOperationsSvccProfitability = undefined;
}
/**
 * Path parameter which is used to retrieve an Offshore Port.
 */
export class GetOffshorePortBySicPath {
	/**
	 * The service center identification code for the Offshore Port.
	 * Required: true
	 * Array: false
	 */
	mainlandSicCd:string = undefined;
}
/**
 * Response to the request to retrieve an Offshore Port.
 */
export class GetOffshorePortBySicResp {
	/**
	 * The Offshore Port for the requested SIC.
	 * Required: false
	 * Array: false
	 */
	offshorePort:OffshorePort = undefined;
}
/**
 * Path parameters which are used to calculate the service days count.
 */
export class GetOperationalServiceDaysCountPath {
	/**
	 * The service center identification code for the origin location where the service started.
	 * Required: true
	 * Array: false
	 */
	originSicCd:string = undefined;
	/**
	 * The service center identification code for the destination location where the service ended.
	 * Required: true
	 * Array: false
	 */
	destinationSicCd:string = undefined;
}
/**
 * Query parameters which are used to calculate the service days count.
 */
export class GetOperationalServiceDaysCountQuery {
	/**
	 * The date for which the service started.
	 * Required: false
	 * Array: false
	 */
	startDate:string = undefined;
	/**
	 * The date for which the service ended.
	 * Required: false
	 * Array: false
	 */
	endDate:string = undefined;
	/**
	 * The code associated with the type of service.
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:ServiceTypeCd = undefined;
}
/**
 * Response to the request to calculate the service days.
 */
export class GetOperationalServiceDaysCountResp {
	/**
	 * The number of days it took to complete the service.
	 * Required: false
	 * Array: false
	 */
	serviceDaysCount:number = undefined;
}
/**
 * Path parameter which is used to retrieve the Loc Address record for a SIC.
 */
export class GetRefSicAddressPath {
	/**
	 * The reference service center identification code.
	 * Required: true
	 * Array: false
	 */
	refSicCd:string = undefined;
}
/**
 * Response to the request to retrieve the Loc Address record for a SIC.
 */
export class GetRefSicAddressResp {
	/**
	 * The Loc Address record which corresponds to the reference SIC.
	 * Required: false
	 * Array: false
	 */
	locAddress:LocationAddress = undefined;
}
/**
 * Query parameter which is used to retrieve hierarchy group names for SICs.
 */
export class GetRefSicHierarchyGroupNamesQuery {
	/**
	 * The list of reference service center identification codes.
	 * Required: true
	 * Array: true
	 */
	refSicCds:string[] = undefined;
}
/**
 * Response to the request to retrieve hierarchy group names for SICs.
 */
export class GetRefSicHierarchyGroupNamesResp {
	/**
	 * The hierarchy group names corresponding to the reference SICs.
	 * Required: false
	 * Array: true
	 */
	hierarchyGroupNames:string[] = undefined;
}
/**
 * Request to return the sector by the door number provided.
 */
export class GetSectorByDoorNumberPath {
	/**
	 * The location for which the sector is to be retrieved.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The door number for which the sector is to be retrieved.
	 * Required: true
	 * Array: false
	 */
	doorNumber:number = undefined;
}
/**
 * Response to the request to return the sector by the door number provided.
 */
export class GetSectorByDoorNumberResp {
	/**
	 * The sector number associated with the door number.
	 * Required: false
	 * Array: false
	 */
	sectorNumber:number = undefined;
	/**
	 * The location for which the sector is to be retrieved.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The door number for which the sector is to be retrieved.
	 * Required: false
	 * Array: false
	 */
	doorNumber:number = undefined;
}
/**
 * Query parameters used to retrieve the SIC assigned to the postal codes provided.
 */
export class GetSicForPostalCodesQuery {
	/**
	 * The list of US or CA postal codes for which the SIC will be retrieved.
	 * Required: true
	 * Array: true
	 */
	postalCds:string[] = undefined;
}
/**
 * Response to the request to retrieve the SIC assigned to the postal codes provided.
 */
export class GetSicForPostalCodesResp {
	/**
	 * The list of SICs that are assigned to the postal codes provided along with additional details.
	 * Required: false
	 * Array: true
	 */
	postalSicAssignments:PostalSicAssignment[] = undefined;
	/**
	 * The validation error details for the list of invalid postal codes.
	 * Required: false
	 * Array: true
	 */
	validationErrors:DataValidationError[] = undefined;
}
/**
 * Request to retrieve all active doors by SIC.
 */
export class ListAllActiveDoorsBySicPath {
	/**
	 * The code associated with the service center for which all active doors will be retrieved.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Query parameters used to return the doors for a location.
 */
export class ListAllActiveDoorsBySicQuery {
	/**
	 * The area inst id for which the door is to be retrieved.
	 * Required: true
	 * Array: false
	 */
	areaInstId:number = undefined;
}
/**
 * Response to the request to list all active doors by a service center.
 */
export class ListAllActiveDoorsBySicResp {
	/**
	 * The list of active doors that are associated with the SIC provided.
	 * Required: false
	 * Array: true
	 */
	activeDoors:Door[] = undefined;
}
/**
 * Query parameters used to filter the list of area assignments returned.
 */
export class ListAreaAssignmentsQuery {
	/**
	 * The unique identifier for the area assignment is to be retrieved.
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * The service center identification code for the location.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response to the request to list area assignments that match the input criteria.
 */
export class ListAreaAssignmentsResp {
	/**
	 * The list of area assignments that match the input criteria.
	 * Required: false
	 * Array: true
	 */
	areaAssignments:AreaAssignment[] = undefined;
}
/**
 * Request to list eligible services by postal SIC.
 */
export class ListEligibleServicesByPostalSicRqst {
	/**
	 * The list of postal SIC codes for which the eligible services will be retrieved.
	 * Required: false
	 * Array: true
	 */
	postalSics:PostalSic[] = undefined;
}
/**
 * Response to the request to list eligible services by postal SIC.
 */
export class ListEligibleServicesByPostalSicResp {
	/**
	 * The list of postal SIC eligible services that match the filter criteria.
	 * Required: false
	 * Array: true
	 */
	postalSicServices:PostalSicService[] = undefined;
}
/**
 * Path parameter used to list geo areas for locations.
 */
export class ListGeoAreasPath {
	/**
	 * Defines what the polygon coordinates represent.  Possible values are: Area (A), Boundary (B), Fence (F), and Service Center (S).
	 * Required: true
	 * Array: false
	 */
	polygonTypeCd:string = undefined;
}
/**
 * Query parameters used to list geo areas for locations.
 */
export class ListGeoAreasQuery {
	/**
	 * The list of geo area identifiers.
	 * Required: false
	 * Array: true
	 */
	areaInstIds:number[] = undefined;
	/**
	 * The list of service center identification codes.
	 * Required: false
	 * Array: true
	 */
	sicCds:string[] = undefined;
	/**
	 * The date used to validate active satellite SICs.
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * Indicates whether satellite SICs for the parent should be included in the response.
	 * Required: false
	 * Array: false
	 */
	satelliteInd:boolean = undefined;
	/**
	 * Indicates whether geo area shape data should be included in the response.
	 * Required: false
	 * Array: false
	 */
	geoAreaShapeInd:boolean = undefined;
}
/**
 * Response to the request to return the geo area for a location.
 */
export class ListGeoAreasResp {
	/**
	 * The list of geo areas associated with the locations provided.
	 * Required: false
	 * Array: true
	 */
	geoAreas:GeoArea[] = undefined;
}
/**
 * Request to list all active SICs.
 */
export class ListAllActiveSicsRqst {
}
/**
 * Response to the request to list all active service service centers and their region, operational region, district, city, state, and if they are a FAC or OB.
 */
export class ListAllActiveSicsResp {
	/**
	 * A list of active service centers and their details.
	 * Required: false
	 * Array: true
	 */
	activeSics:ActiveSic[] = undefined;
}
/**
 * Request to retrieve all sic codes by region.
 */
export class ListAllSicCdsByRegionPath {
	/**
	 * The code identifying the region.
	 * Required: true
	 * Array: false
	 */
	locGroupCode:string = undefined;
}
/**
 * Response to the request to list sic codes by region code.
 */
export class ListAllSicCdsByRegionResp {
	/**
	 * A list of the sic codes.
	 * Required: false
	 * Array: true
	 */
	sicCds:string[] = undefined;
}
/**
 * Query parameters used to filter the list location features returned.
 */
export class ListLocationFeaturesQuery {
	/**
	 * The unique identifier for the employee.
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * The unique identifier for the SIC.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The code that represents the feature for which details are to be retrieved.
	 * Required: false
	 * Array: false
	 */
	featureCd:string = undefined;
	/**
	 * The value attached to the specific feature. For example - 'Y'.
	 * Required: false
	 * Array: false
	 */
	settingValue:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list location features that match the input criteria.
 */
export class ListLocationFeaturesResp {
	/**
	 * The list of location features that matched the input criteria.
	 * Required: false
	 * Array: true
	 */
	locationFeatures:FeatureSetting[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Query to retrieve a list location group codes.
 */
export class ListLocationGroupCodesQuery {
	/**
	 * The category code groups the SICs by linehaul region.
	 * Required: false
	 * Array: false
	 */
	groupCategoryCd:string = undefined;
	/**
	 * Used to filter the list by a specific location.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response to the request to list operational service centers.
 */
export class ListLocationGroupCodesResp {
	/**
	 * A list of the regions along with the SICs that are part of that region.
	 * Required: false
	 * Array: true
	 */
	locationGroupCodes:LocationGroupCode[] = undefined;
}
/**
 * Query to list locations.
 */
export class ListLocationsQuery {
	/**
	 * Indicates if only active locations should be included.
	 * Required: false
	 * Array: false
	 */
	activeInd:boolean = undefined;
	/**
	 * Indicates if zone locations should be included.
	 * Required: false
	 * Array: false
	 */
	zoneDesiredInd:boolean = undefined;
	/**
	 * Indicates if satellite locations should be included.
	 * Required: false
	 * Array: false
	 */
	satelliteDesiredInd:boolean = undefined;
	/**
	 * Indicates if meet and turn SICs should be returned.
	 * Required: false
	 * Array: false
	 */
	meetAndTurnDesiredInd:boolean = undefined;
}
/**
 * Request to list locations
 */
export class ListLocationsRqst {
	/**
	 * List of SIC codes desired. If not supplied, list all locations based on the other criteria.
	 * Required: false
	 * Array: true
	 */
	refSicCd:string[] = undefined;
	/**
	 * A list of group categories to include.
	 * Required: false
	 * Array: true
	 */
	groupCategoryCd:string[] = undefined;
}
/**
 * Response to list locations and their zones and satellites, including its region, city, state, and if they are a FAC or OB.
 */
export class ListLocationsResp {
	/**
	 * A list of locations that match the input criteria.
	 * Required: false
	 * Array: true
	 */
	locationSic:LocationSic[] = undefined;
}
/**
 * Query parameter which is used to list the operational hierarchy.
 */
export class ListOperationalHierarchyQuery {
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list the operational hierarchy.
 */
export class ListOperationalHierarchyResp {
	/**
	 * The list of all operational regions, districts, host SICs, zones, and satellites.
	 * Required: false
	 * Array: true
	 */
	operationalHierarchy:RegionHierarchy[] = undefined;
}
/**
 * Request to list all operational regions with their districts and for each district all of its SICs.
 */
export class ListOperationalRegionsQuery {
	/**
	 * The type of location which will be used to filter the data. Possible values: REGION, DISTRICT and SIC.
	 * Required: false
	 * Array: false
	 */
	locationHierarchyTypeCd:LocationHierarchyTypeCd = undefined;
	/**
	 * The value used to filter the results based on the selected locationHierarchyTypeCd. The values should be the codes of the registry in the Database. Example: 
	 * 						LocationHierarchyTypeCd = 'REGION' and locationHierarchyFilters = ['CEA', 'CCA'], to filter by REGIONs Eastern and Mid-West
	 * 						LocationHierarchyTypeCd = 'DISTRICT' and locationHierarchyFilters = ['OAG', 'OCA'], to filter through the DISTRICTs 'ATLANTA DISTRICT' and 'CANADA DISTRICT'
	 * 						LocationHierarchyTypeCd = 'SIC' and locationHierarchyFilters = ['NAA', 'NAB'], to filter by the SICs 'NAA' and 'NAB'
	 * Required: false
	 * Array: true
	 */
	locationHierarchyFilters:string[] = undefined;
}
/**
 * Response wiht the of list all Operational Regions with their Districts, and for each District, all SICs..
 */
export class ListOperationalRegionsResp {
	/**
	 * A list of all operational regions which match the filter criteria.
	 * Required: false
	 * Array: true
	 */
	operationalRegions:OperationalRegion[] = undefined;
}
/**
 * Request to list operational service centers.
 */
export class ListOperationalServiceCentersQuery {
	/**
	 * Indicates whether satellite service centers should be excluded from the response.
	 * Required: false
	 * Array: false
	 */
	excludeSatellitesInd:boolean = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list operational service centers.
 */
export class ListOperationalServiceCentersResp {
	/**
	 * A list of operational service centers.
	 * Required: false
	 * Array: true
	 */
	serviceCenters:ServiceCenter[] = undefined;
}
/**
 * Request to insert or update a location feature setting.
 */
export class UpsertLocationFeatureSettingRqst {
	/**
	 * The feature setting which will be created or update the existing entry.
	 * Required: true
	 * Array: false
	 */
	featureSetting:FeatureSetting = undefined;
}
/**
 * Response to the request to insert or update a location feature setting.
 */
export class UpsertLocationFeatureSettingResp {
	/**
	 * The feature setting which was created or updated the existing entry.
	 * Required: false
	 * Array: false
	 */
	featureSetting:FeatureSetting = undefined;
}
/**
 * Request to return the host SIC for a zone.
 */
export class GetHostSicDetailsPath {
	/**
	 * Unique identifier for the zone service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response to the request to return the host SIC for a zone.
 */
export class GetHostSicDetailsResp {
	/**
	 * Unique identifier for the zone service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * Unique identifier for the host service center.
	 * Required: false
	 * Array: false
	 */
	hostSicCd:string = undefined;
	/**
	 * Identifies if the SIC is zone or satellite.
	 * Required: false
	 * Array: false
	 */
	sicType:string = undefined;
}
/**
 * Request to retrieve location reference details for a service center location.
 */
export class GetLocReferenceDetailsBySicPath {
	/**
	 * The code identifying a given service center
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * The query parameter for the request to get the loc reference for a SIC.
 */
export class GetLocReferenceDetailsBySicQuery {
	/**
	 * The fields that should be returned as part of the response body.
	 * Required: false
	 * Array: true
	 */
	responseFields:LocationDetailCd[] = undefined;
}
/**
 * Response that contains location reference detail information for a service center location.
 */
export class GetLocReferenceDetailsBySicResp {
	/**
	 * Required: false
	 * Array: false
	 */
	locReference:LocationReference = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	locCompanyOperations:CompanyOperations = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	locConWayCorporation:ConWayCorporation = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	chargeToSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	chargeToSicNm:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	areaSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	regionSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	satelliteParentSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	satelliteParentSicNm:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	zoneParentSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	zoneParentSicNm:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	linehaulHostSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	linehaulHostSicNm:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	regionLocHierarchyGroup:HierarchyGroup = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	areaLocHierarchyGroup:HierarchyGroup = undefined;
}
/**
 * Path parameter used to determine the next business day.
 */
export class GetNextBusinessDayPath {
	/**
	 * The service center identification code.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Query parameters used to determine the next business day.
 */
export class GetNextBusinessDayQuery {
	/**
	 * The date for which the next business day will be determined.
	 * Required: false
	 * Array: false
	 */
	startDate:string = undefined;
	/**
	 * The number of days to add to to the start date.
	 * Required: false
	 * Array: false
	 */
	offsetDaysCount:number = undefined;
	/**
	 * Identifies the operation to be executed. If no value is passed, NextBusinessDay is used as the default.
	 * Required: false
	 * Array: false
	 */
	nextBusinessDayOperationCd:NextBusinessDayOperationCd = undefined;
}
/**
 * Response to the request to determine the next business day.
 */
export class GetNextBusinessDayResp {
	/**
	 * The next business day after adding offset days to the start date.
	 * Required: false
	 * Array: false
	 */
	nextBusinessDay:string = undefined;
	/**
	 * The date for which the next business day was determined.
	 * Required: false
	 * Array: false
	 */
	startDate:string = undefined;
	/**
	 * The number of days to add to to the start date.
	 * Required: false
	 * Array: false
	 */
	offsetDaysCount:number = undefined;
	/**
	 * Identifies the type of non-work day.
	 * Required: false
	 * Array: false
	 */
	nonWorkDayTypeCd:NonWorkDayTypeCd = undefined;
	/**
	 * Indicates whether the given date is marked for linehaul. Possible values: Y or N.
	 * Required: false
	 * Array: false
	 */
	linehaulInd:string = undefined;
	/**
	 * Indicates whether the given date is marked for non-work. Possible values: Y or N.
	 * Required: false
	 * Array: false
	 */
	nonWorkDayInd:string = undefined;
	/**
	 * The revenue allocation for the given date.  This is use by the Sales team (including BI) to identify how a particular day is weighted as far as revenue is concerned.
	 * Required: false
	 * Array: false
	 */
	revenueAllocationNbr:number = undefined;
	/**
	 * The operation that was executed for the next business day calculation.
	 * Required: false
	 * Array: false
	 */
	nextBusinessDayOperationCd:NextBusinessDayOperationCd = undefined;
}
/**
 * Request to retrieve detailed information for a service center location.
 */
export class GetServiceCenterDetailsBySicPath {
	/**
	 * The code identifying a given service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response that contains detailed information for a service center location.
 */
export class GetServiceCenterDetailsBySicResp {
	/**
	 * The code identifying a given service center
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The code identifying the component for the sic
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * address of service center
	 * Required: false
	 * Array: false
	 */
	address:Address = undefined;
	/**
	 * GIS lat long
	 * Required: false
	 * Array: false
	 */
	coordinates:LatLong = undefined;
	/**
	 * The offset of the timezone.
	 * Required: false
	 * Array: false
	 */
	timezoneOffset:number = undefined;
}
/**
 * Path to return zone and satellite details for a specific SIC location.
 */
export class GetZoneAndSatelliteBySicPath {
	/**
	 * The SIC code for a service center.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Query to return zone and satellite details for a specific SIC location.
 */
export class GetZoneAndSatelliteBySicQuery {
	/**
	 * The true/false indicator denoting if the zone should be returned.
	 * Required: false
	 * Array: false
	 */
	zoneInd:boolean = undefined;
	/**
	 * The true/false indicator denoting if the satellite should be returned.
	 * Required: false
	 * Array: false
	 */
	satelliteInd:boolean = undefined;
}
/**
 * Response to the request to return zone and satellite details for a specific SIC location.
 */
export class GetZoneAndSatelliteBySicResp {
	/**
	 * The SIC used in the search (same as the input SIC).
	 * Required: false
	 * Array: false
	 */
	refSic:string = undefined;
	/**
	 * The region for the import SIC (host region for the sic).
	 * Required: false
	 * Array: false
	 */
	refRegionSic:string = undefined;
	/**
	 * The host of the import SIC if the import SIC was a zone.
	 * Required: false
	 * Array: false
	 */
	refZoneSic:string = undefined;
	/**
	 * The host of the import SIC if the import SIC was a satellite.
	 * Required: false
	 * Array: false
	 */
	refSatelliteSic:string = undefined;
	/**
	 * The SIC code for a service center.
	 * Required: false
	 * Array: true
	 */
	zoneSatelliteInfo:ZoneSatelliteInfo[] = undefined;
}
/**
 * Path parameter used to return a list of values for a location feature.
 */
export class ListLocationFeatureValuesByFeaturePath {
	/**
	 * The code that represents the feature for which details are to be retrieved.
	 * Required: true
	 * Array: false
	 */
	featureCd:string = undefined;
}
/**
 * Query parameter used to return a list of values for a location feature.
 */
export class ListLocationFeatureValuesByFeatureQuery {
	/**
	 * The value attached to the specific feature. For example - 'Y'.
	 * Required: false
	 * Array: false
	 */
	settingValue:string = undefined;
}
/**
 * Response to the request to return a list of values for a location feature.
 */
export class ListLocationFeatureValuesByFeatureResp {
	/**
	 * A list of location features associated with the feature code and optionally the feature value.
	 * Required: false
	 * Array: true
	 */
	locationFeatures:FeatureSetting[] = undefined;
}
/**
 * Query parameters used to list operational calendar days.
 */
export class ListOperationalCalendarDaysQuery {
	/**
	 * The beginning date to check for a work day or non-working day.
	 * Required: false
	 * Array: false
	 */
	startDate:string = undefined;
	/**
	 * The end date to check for a work day or non-working day.
	 * Required: false
	 * Array: false
	 */
	endDate:string = undefined;
	/**
	 * The code associated with the type of revenue day.  Valid values: R (Revenue day), W (Weekend day), and H (Holiday).
	 * Required: false
	 * Array: false
	 */
	revenueDayCd:string = undefined;
	/**
	 * For Revenue use 1.0 and 0 for Weekend and Holiday.
	 * Required: false
	 * Array: false
	 */
	revenueAllocationNbrStr:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list the operational calendard days that match the filter criteria.
 */
export class ListOperationalCalendarDaysResp {
	/**
	 * The list of operation caldendar days which match the filter criteria.
	 * Required: false
	 * Array: true
	 */
	operationalCalendarDays:LocationOperationalCalendar[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Details about an active service center.
 */
export class ActiveSic {
	/**
	 * The service center identification code.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The code associated with the group that belongs to the service center.
	 * Required: false
	 * Array: false
	 */
	groupCd:string = undefined;
	/**
	 * The region that belongs to the service center.
	 * Required: false
	 * Array: false
	 */
	region:string = undefined;
	/**
	 * The operational region of the service center.
	 * Required: false
	 * Array: false
	 */
	operationalRegion:string = undefined;
	/**
	 * The district for the service center.
	 * Required: false
	 * Array: false
	 */
	district:string = undefined;
	/**
	 * The city for service center.
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * The offset of the service center.
	 * Required: false
	 * Array: false
	 */
	serviceCenterOffset:string = undefined;
	/**
	 * The state for the service center.
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * Returns 1 if SIC is a FAC.
	 * Required: false
	 * Array: false
	 */
	isFac:number = undefined;
	/**
	 * Returns 1 if SIC is a OB.
	 * Required: false
	 * Array: false
	 */
	isOb:number = undefined;
	/**
	 * A description for the location.
	 * Required: false
	 * Array: false
	 */
	sicDescription:string = undefined;
}
/**
 * District represents a specific geographical location under a Region which is composed of a group of SIC codes. Examples: Chicago, Detroit, Milwaukee, etc.
 */
export class District {
	/**
	 * The code used as an identifier on the database.
	 * Required: false
	 * Array: false
	 */
	districtCd:string = undefined;
	/**
	 * The name of the district. E.g. Chicago, Detroit, Milwaukee, etc.
	 * Required: false
	 * Array: false
	 */
	districtName:string = undefined;
	/**
	 * A list of the service center identification codes that belong to that district.
	 * Required: false
	 * Array: true
	 */
	sicCds:string[] = undefined;
}
/**
 * District Hierarchy represents a collection of service center identification codes under a region in the hierarchy. E.g. OST-STOCKTON DISTRICT.
 */
export class DistrictHierarchy {
	/**
	 * The service center identification code.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The category of the district in the hierarchy. E.g. OPS-DISTRICT.
	 * Required: false
	 * Array: false
	 */
	sicCategory:string = undefined;
	/**
	 * Reference details about a district.
	 * Required: false
	 * Array: false
	 */
	reference:LocationReference = undefined;
	/**
	 * A list of host SICs which include zones/satellites that are linked to them.
	 * Required: false
	 * Array: true
	 */
	hostSics:HostSic[] = undefined;
}
/**
 * Details about a geo area.
 */
export class GeoArea {
	/**
	 * The unique identifier for the geo area.
	 * Required: false
	 * Array: false
	 */
	areaInstId:string = undefined;
	/**
	 * The service center identification code.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The name for the geo area.
	 * Required: false
	 * Array: false
	 */
	geoAreaName:string = undefined;
	/**
	 * The description for the geo area.
	 * Required: false
	 * Array: false
	 */
	geoAreaDesc:string = undefined;
	/**
	 * Defines what the polygon coordinates represent.  Possible values are: Area (A), Boundary (B), Fence (F), and Service Center (S).
	 * Required: false
	 * Array: false
	 */
	polygonTypeCd:PolygonTypeCd = undefined;
	/**
	 * The coordinates for the locations.
	 * Required: false
	 * Array: true
	 */
	polygon:LatLong[] = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * The host SIC, which has zones/satellites linked to it in the hierarchy. E.g. UPO-PORTLAND.
 */
export class HostSic {
	/**
	 * The service center identification code.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The category (host, zone, or satellite) of the service center in the operational hierarchy group.
	 * Required: false
	 * Array: false
	 */
	sicCategory:string = undefined;
	/**
	 * Address infomration for the host service center.
	 * Required: false
	 * Array: false
	 */
	locAddress:LocationAddress = undefined;
	/**
	 * Reference details about a host service center.
	 * Required: false
	 * Array: false
	 */
	reference:LocationReference = undefined;
	/**
	 * The list of zone service centers linked to the host SIC.
	 * Required: false
	 * Array: true
	 */
	zoneSics:ServiceCenter[] = undefined;
	/**
	 * The list of satellite service centers linked to the host SIC.
	 * Required: false
	 * Array: true
	 */
	satelliteSics:ServiceCenter[] = undefined;
}
/**
 * Details about a linehaul region, which is a location group code.
 */
export class LocationGroupCode {
	/**
	 * The code associated with the region.
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * The list of service center identification codes that belong to that region.
	 * Required: false
	 * Array: true
	 */
	sicCds:string[] = undefined;
}
/**
 * Details about an active service center.
 */
export class LocationSic {
	/**
	 * The service center identification code.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The code associated with the group that belongs to the service center.
	 * Required: false
	 * Array: false
	 */
	groupCd:string = undefined;
	/**
	 * The region that belongs to the service center.
	 * Required: false
	 * Array: false
	 */
	region:string = undefined;
	/**
	 * The ciity for service center.
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * The offset of the service center.
	 * Required: false
	 * Array: false
	 */
	serviceCenterOffset:string = undefined;
	/**
	 * The state for the service center.
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * Returns 1 if SIC is a FAC.
	 * Required: false
	 * Array: false
	 */
	isFac:number = undefined;
	/**
	 * Returns 1 if SIC is a OB.
	 * Required: false
	 * Array: false
	 */
	isOb:number = undefined;
	/**
	 * A description for the location.
	 * Required: false
	 * Array: false
	 */
	sicDescription:string = undefined;
	/**
	 * The SIC of the location hosting this satellite.
	 * Required: false
	 * Array: false
	 */
	parentSatelliteSic:string = undefined;
	/**
	 * The SIC of the service center that hosts the zone.
	 * Required: false
	 * Array: false
	 */
	zoneTerminalSic:string = undefined;
	/**
	 * Indicator to identify this SIC as a satellite.
	 * Required: false
	 * Array: false
	 */
	aSatelliteSic:boolean = undefined;
	/**
	 * Indicator to identify this SIC as a zone.
	 * Required: false
	 * Array: false
	 */
	aZoneSic:boolean = undefined;
}
/**
 * Operational Region represents a geographical area by which the Operations are divided in the organization and is composed of Districts.
 */
export class OperationalRegion {
	/**
	 * The code associated with the region.
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * The name of the operational region. E.g. Eastern, Mid-West, and Western.
	 * Required: false
	 * Array: false
	 */
	regionName:string = undefined;
	/**
	 * A list of the districts along with the SICs that are part of it.
	 * Required: false
	 * Array: true
	 */
	districts:District[] = undefined;
}
/**
 * Details about postal code and SIC.
 */
export class PostalSic {
	/**
	 * The service center identification code.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The postal code associated with the SIC.
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
}
/**
 * Details about postal code and SIC associations.
 */
export class PostalSicAssignment {
	/**
	 * Location Id code
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * Postal Code associated with the Sic Code
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
	/**
	 * US state code or CA province code
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * 2 character country code
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * If set to true then the Postal Code is a direct point
	 * Required: false
	 * Array: false
	 */
	directPointInd:boolean = undefined;
	/**
	 * If set to true then the Rapid Remote Service (RRS) is available
	 * Required: false
	 * Array: false
	 */
	rrsServiceInd:boolean = undefined;
	/**
	 * The service center identification code for the host location for the zone.
	 * Required: false
	 * Array: false
	 */
	hostSicCd:string = undefined;
	/**
	 * The distance in miles from the host SIC.
	 * Required: false
	 * Array: false
	 */
	distanceFromHostSicMiles:number = undefined;
}
/**
 * Details about a postal SIC and the services it offers.
 */
export class PostalSicService {
	/**
	 * Details about a postalSic.
	 * Required: false
	 * Array: false
	 */
	postalSic:PostalSic = undefined;
	/**
	 * The list of eligible services for a postal SIC code.
	 * Required: false
	 * Array: true
	 */
	eligibleServices:ServiceTypeCd[] = undefined;
}
/**
 * Operational Hierarchy Region is an area by which the Operations are segmented in the organization and it is furthur segmented into Districts.
 */
export class RegionHierarchy {
	/**
	 * The service center identification code.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The category of the region. Example: OPS-REGION.
	 * Required: false
	 * Array: false
	 */
	sicCategory:string = undefined;
	/**
	 * Reference details about a region.
	 * Required: false
	 * Array: false
	 */
	reference:LocationReference = undefined;
	/**
	 * A list of the districts along with the SICs that belong to them.
	 * Required: false
	 * Array: true
	 */
	districts:DistrictHierarchy[] = undefined;
}
/**
 * Details about a service center.
 */
export class ServiceCenter {
	/**
	 * Address infomration for the service center.
	 * Required: false
	 * Array: false
	 */
	locAddress:LocationAddress = undefined;
	/**
	 * The service center identification code for the location.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The category of the service center. E.g. Zone/Satellite.
	 * Required: false
	 * Array: false
	 */
	sicCategory:string = undefined;
	/**
	 * Reference details about a service center.
	 * Required: false
	 * Array: false
	 */
	reference:LocationReference = undefined;
}
/**
 * Details about zone and satellite.
 */
export class ZoneSatelliteInfo {
	/**
	 * The SIC code for the region.
	 * Required: false
	 * Array: false
	 */
	regionSic:string = undefined;
	/**
	 * The SIC code for the location.
	 * Required: false
	 * Array: false
	 */
	locationSic:string = undefined;
	/**
	 * The parent satellite.
	 * Required: false
	 * Array: false
	 */
	satelliteParent:string = undefined;
	/**
	 * The zone location of the terminal.
	 * Required: false
	 * Array: false
	 */
	zoneTerminal:string = undefined;
}
/**
 * Path parameters for get Ensemble status counts
 */
export class GetEnsembleStatusCountsPath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * From timestamp
	 * Required: true
	 * Array: false
	 */
	fromTmst:Date = undefined;
	/**
	 * To timestamp
	 * Required: true
	 * Array: false
	 */
	toTmst:Date = undefined;
}
/**
 * Response to the request to get Ensemble status counts
 */
export class GetEnsembleStatusCountsResp {
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleMetrics:EnsembleMetrics = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	queueMetrics:QueueMetrics = undefined;
}
/**
 * Path parameters for get Ensemble status counts
 */
export class GetQueuePendingCountPath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Response for getQueuePendingCount
 */
export class GetQueuePendingCountResp {
	/**
	 * Number of messages in either ready or wait status. This count indicates the queue depth.
	 * Required: false
	 * Array: false
	 */
	pendingCount:number = undefined;
}
/**
 * Request to Restart execution of a Started or Not Started  Ensemble
 */
export class RestartEnsemblePath {
	/**
	 * Name of the queue
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * Unique identifier for the application workflow.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
}
/**
 * Request to Restart execution of a Started or Not Started  Ensemble
 */
export class RestartEnsembleRqst {
}
/**
 * Response to the Request to Restart execution of a Started or Not Started  Ensemble
 */
export class RestartEnsembleResp {
	/**
	 * Ensemble that was Restarted.
	 * Required: false
	 * Array: false
	 */
	ensembleRestartedInd:boolean = undefined;
}
/**
 * Request to Resume execution of a Suspended ensemble
 */
export class ResumeEnsemblePath {
	/**
	 * Name of the queue
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * Unique identifier for the application workflow.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
}
/**
 * Request to Resume execution of a Suspended ensemble
 */
export class ResumeEnsembleRqst {
}
/**
 * Response to the Request to Resume execution of a Suspended ensemble
 */
export class ResumeEnsembleResp {
	/**
	 * Ensemble that was Resumed
	 * Required: false
	 * Array: false
	 */
	ensembleResumedInd:boolean = undefined;
}
/**
 * Request to start ensemble.
 */
export class StartMessageDrivenEnsembleRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	queue:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	messageId:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	message:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	eventDataType:string = undefined;
}
/**
 * Enter comments
 */
export class StartMessageDrivenEnsembleResp {
	/**
	 * Instance ID of the ensemble that was started.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
}
/**
 * Request to stop execution of an Ensemble
 */
export class KillEnsembleRqst {
	/**
	 * Unique identifier for the ensemble.
	 * Required: true
	 * Array: true
	 */
	ensembleInstIds:string[] = undefined;
}
/**
 * Response to the request to stop execution of an Ensemble
 */
export class KillEnsembleResp {
	/**
	 * Ensembles that were killed.
	 * Required: false
	 * Array: true
	 */
	status:KillEnsembleStatus[] = undefined;
}
/**
 * Path parameters for stopQueue
 */
export class StopQueuePath {
	/**
	 * Name of the queue to stop
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Path parameters for stopQueue
 */
export class StopQueueWebhookPath {
	/**
	 * Name of the queue to stop
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Path parameters for stopQueue
 */
export class StopQueueWebhookQuery {
	/**
	 * Authorization token
	 * Required: false
	 * Array: false
	 */
	auth_token:string = undefined;
}
/**
 * Path parameters for startQueue
 */
export class StartQueuePath {
	/**
	 * Name of the queue to start
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Response for listQueues
 */
export class ListQueuesResp {
	/**
	 * Name of the queue to start
	 * Required: false
	 * Array: true
	 */
	queues:SymphonyQueue[] = undefined;
}
/**
 * Path parameters
 */
export class ListExpiredMessagesPath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * From enqueue timestamp
	 * Required: true
	 * Array: false
	 */
	enqueueFromTmst:Date = undefined;
	/**
	 * Tom enqueue timestamp
	 * Required: true
	 * Array: false
	 */
	enqueueToTmst:Date = undefined;
}
/**
 * Response
 */
export class ListExpiredMessagesResp {
	/**
	 * Name of the queue
	 * Required: false
	 * Array: true
	 */
	message:SymphonyMessage[] = undefined;
}
/**
 * Path parameters
 */
export class DeleteExpiredMessagesPath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Path parameters
 */
export class DeleteExpiredMessagesQuery {
	/**
	 * Id of the message to be deleted
	 * Required: true
	 * Array: true
	 */
	messageId:string[] = undefined;
}
/**
 * Response to the request to stop execution of an Ensemble
 */
export class DeleteExpiredMessagesResp {
	/**
	 * Ensembles that were killed.
	 * Required: false
	 * Array: true
	 */
	status:DeleteExpiredMessageStatus[] = undefined;
}
/**
 * Path parameters
 */
export class ReplayMessagePath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * ID of the message to replay. It needs to be in expired status
	 * Required: true
	 * Array: false
	 */
	messageId:string = undefined;
}
export class MessageDrivenEnsemblePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The event occurrence timestamp
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:object = undefined;
}
export class DynamicMessageDrivenPayload {
	/**
	 * The type of the payload
	 * Required: true
	 * Array: false
	 */
	eventDataType:string = undefined;
	/**
	 * The message payload
	 * Required: true
	 * Array: false
	 */
	payload:string = undefined;
}
export class TransactionEventEnsemblePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The event occurrence timestamp
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:TransactionEvent = undefined;
}
/**
 * Status of a delete expired message request
 */
export class DeleteExpiredMessageStatus {
	/**
	 * Unique identifier for the ensemble record.
	 * Required: false
	 * Array: false
	 */
	messageId:string = undefined;
	/**
	 * Indicates whether the ensemble was killed.
	 * Required: false
	 * Array: false
	 */
	messageDeletedInd:boolean = undefined;
	/**
	 * A status describing why the message could not be deleted. Populated if messageDeletedInd is false
	 * Required: false
	 * Array: false
	 */
	failStatus:string = undefined;
}
/**
 * Status of a kill ensemble request
 */
export class KillEnsembleStatus {
	/**
	 * Unique identifier for the ensemble record.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
	/**
	 * Indicates whether the ensemble was killed.
	 * Required: false
	 * Array: false
	 */
	ensembleKilledInd:boolean = undefined;
	/**
	 * A status describing why the ensemble could not be killed. Populated if ensembleKilledInd is false
	 * Required: false
	 * Array: false
	 */
	failStatus:string = undefined;
}
/**
 * Stores execution information for application workflows.
 */
export class EnsembleStateSearchRecord {
	/**
	 * Unique identifier for the ensemble record.
	 * Required: false
	 * Array: false
	 */
	instId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState:EnsembleState = undefined;
}
export class Payload {
	/**
	 * Required: true
	 * Array: false
	 */
	type:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	value:object = undefined;
}
export class EnsembleState {
	/**
	 * Unique identifier for the ensemble record.
	 * Required: false
	 * Array: false
	 */
	instId:string = undefined;
	/**
	 * Name of the application workflow.
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * A short description of the ensemble
	 * Required: false
	 * Array: false
	 */
	desc:string = undefined;
	/**
	 * Name of the queue publishing this message.
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * Id of the message.
	 * Required: false
	 * Array: false
	 */
	messageId:string = undefined;
	/**
	 * The timestamp of when the trigger event was created.
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The timestamp of when the message was received by ensemble.
	 * Required: false
	 * Array: false
	 */
	recvTmst:Date = undefined;
	/**
	 * The timestamp of when this ensemble was dispatched to the executor thread.
	 * Required: false
	 * Array: false
	 */
	dispatchTmst:Date = undefined;
	/**
	 * The timestamp of when this ensemble processing started.
	 * Required: false
	 * Array: false
	 */
	strtTmst:Date = undefined;
	/**
	 * The timestamp of when this ensemble processing ended.
	 * Required: false
	 * Array: false
	 */
	endTmst:Date = undefined;
	/**
	 * Dequeue time in milliseconds. Populated for started ensembles, and is the difference between the received and event timestamps.
	 * Required: false
	 * Array: false
	 */
	dequeueTime:number = undefined;
	/**
	 * Dispatch time in milliseconds. Populated for started ensembles, and is the difference between the dispatch and received timestamps.
	 * Required: false
	 * Array: false
	 */
	dispatchTime:number = undefined;
	/**
	 * Wait time in milliseconds. Populated for started ensembles, and is the difference between the start and dispatch timestamps.
	 * Required: false
	 * Array: false
	 */
	waitTime:number = undefined;
	/**
	 * Time in milliseconds it took to process this ensemble from the time it was started. Populated for completed ensembles, and is the difference between the end and start timestamps.
	 * Required: false
	 * Array: false
	 */
	processTime:number = undefined;
	/**
	 * Total elapsed time in milliseconds to process this ensemble from the time the event was created. Populated for completed ensembles, and is the difference between the start and event timestamps.
	 * Required: false
	 * Array: false
	 */
	totalTime:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
	/**
	 * Status of workflow execution.  
	 * 
	 * Valid values: 
	 * NOT_STARTED 
	 * STARTED
	 * SUSPENDED 
	 * COMPLETED
	 * Required: false
	 * Array: false
	 */
	statCd:EnsembleExecutionStatusCd = undefined;
	/**
	 * Status message for the workflow execution.  This could be an error message, stacktrace, success message, etc.
	 * Required: false
	 * Array: false
	 */
	statMsg:string = undefined;
	/**
	 * Same value for CORRELATION 
	 * 
	 * id is assigned to various rows from different tables that form a single business 
	 * 
	 * transactions. 
	 * In other words, this groups multiple rows across various tables for a single business 
	 * 
	 * transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * Name of the host on which the ensemble was last processed
	 * Required: false
	 * Array: false
	 */
	host:string = undefined;
	/**
	 * Name of the thread on which the ensemble was last processed
	 * Required: false
	 * Array: false
	 */
	thread:string = undefined;
	/**
	 * Timestamp of when the ensemble started waiting for lock
	 * Required: false
	 * Array: false
	 */
	lockWaitStartTmst:Date = undefined;
	/**
	 * Time spent on waiting for lock
	 * Required: false
	 * Array: false
	 */
	elapsedLockWaitTime:number = undefined;
	/**
	 * Number of times this ensemble was put on lock wait
	 * Required: false
	 * Array: false
	 */
	lockWaitCount:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	minuetStates:MinuetState[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	gotoEnsembleStates:NestedEnsembleState[] = undefined;
	/**
	 * Payload that is input to the ensemble execution.
	 * Required: false
	 * Array: false
	 */
	payload:Payload = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	executionContext:EnsembleExecutionContext[] = undefined;
}
export class NestedEnsembleState {
	/**
	 * Name of the application workflow.
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	minuetStates:MinuetState[] = undefined;
}
export class EnsembleExecutionContext {
	/**
	 * Name of the application workflow.
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	minuetPtr:number = undefined;
}
/**
 * Stores execution information for the tasks that compose the application workflow.
 */
export class MinuetState {
	/**
	 * Name of the task.
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * Response payload that is output  from the task.
	 * Required: false
	 * Array: false
	 */
	respPayload:Payload = undefined;
	/**
	 * Datetime the task execution started
	 * Required: false
	 * Array: false
	 */
	strtTmst:Date = undefined;
	/**
	 * Datetime the task execution ended
	 * Required: false
	 * Array: false
	 */
	endTmst:Date = undefined;
	/**
	 * Time in milliseconds it took to process this minuet from the time it was started. Populated for completed minuets, and is the difference between the end and start timestamps.
	 * Required: false
	 * Array: false
	 */
	processTime:number = undefined;
	/**
	 * Number of times this minuet was retried
	 * Required: false
	 * Array: false
	 */
	executionCount:number = undefined;
	/**
	 * Status of task execution.  
	 * 
	 * Valid values:  
	 * STARTED
	 * FAILED 
	 * COMPLETED
	 * Required: false
	 * Array: false
	 */
	statCd:MinuetExecutionStatusCd = undefined;
	/**
	 * Status message for the task.  This could be an error message, stacktrace, success message, etc.
	 * Required: false
	 * Array: false
	 */
	statMsg:string = undefined;
}
export class EnsembleMetrics {
	/**
	 * Required: false
	 * Array: false
	 */
	notStartedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	startedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	completedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	suspendedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	failedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	killedCount:number = undefined;
}
export class QueueMetrics {
	/**
	 * Required: false
	 * Array: false
	 */
	readyCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	waitCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	processedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	expiredCount:number = undefined;
}
export class AuxMessagePublishers {
	/**
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	queueTypeCd:string = undefined;
}
export class EnsembleConfiguration {
	/**
	 * Required: false
	 * Array: false
	 */
	component:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	mode:EnsembleListenerModeCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shortName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sourceTable:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	payloadType:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cacheEnabled:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lockEnabled:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	retriggerEnabled:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	waitEnabled:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	rulesEngineEnabled:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	messagePublisherEnabled:boolean = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	auxMessagePublishers:AuxMessagePublishers[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fileAccessEnabled:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	synchronousResponseType:string = undefined;
}
export class LTLEventLocation {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * Contains a list of additional event types that are included in this event.
	 * Required: false
	 * Array: true
	 */
	auxEventTypes:string[] = undefined;
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The name of component from which this event originated.
	 * Required: false
	 * Array: false
	 */
	srcComponent:string = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: true
	 */
	eventData:LocationEventPayload[] = undefined;
}
/**
 * An Update response from a BULK update to elastic
 */
export class BulkUpdateResp {
	/**
	 * Required: false
	 * Array: false
	 */
	_index:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	_id:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	status:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	error:BulkUpdateItemError = undefined;
}
/**
 * Details of an error for an item
 */
export class BulkUpdateItemError {
	/**
	 * Required: false
	 * Array: false
	 */
	type:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	reason:string = undefined;
}
/**
 * An item in the bulk api response
 */
export class BulkApiRespItem {
	/**
	 * Required: false
	 * Array: false
	 */
	update:BulkUpdateResp = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	index:BulkUpdateResp = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	create:BulkUpdateResp = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	delete:BulkUpdateResp = undefined;
}
export class EnsembleStateSearchFilterNot {
	/**
	 * Required: false
	 * Array: true
	 */
	and:EnsembleStateSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:EnsembleStateSearchFilterOr[] = undefined;
}
export class EnsembleStateSearchFilterOr {
	/**
	 * Required: false
	 * Array: false
	 */
	instId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	and:EnsembleStateSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:EnsembleStateSearchFilterNot[] = undefined;
	public toJSON = function(this: EnsembleStateSearchFilterOr) {
		return {
			"instId" : this.instId,
			"instId.keyword" : this.instId_keyword,
			"ensembleState.messageId" : this.ensembleState_messageId,
			"ensembleState.messageId.keyword" : this.ensembleState_messageId_keyword,
			"ensembleState.queueName" : this.ensembleState_queueName,
			"ensembleState.queueName.keyword" : this.ensembleState_queueName_keyword,
			"ensembleState.name" : this.ensembleState_name,
			"ensembleState.name.keyword" : this.ensembleState_name_keyword,
			"ensembleState.desc" : this.ensembleState_desc,
			"ensembleState.desc.keyword" : this.ensembleState_desc_keyword,
			"ensembleState.eventTmst" : this.ensembleState_eventTmst,
			"ensembleState.eventTmst.keyword" : this.ensembleState_eventTmst_keyword,
			"ensembleState.strtTmst" : this.ensembleState_strtTmst,
			"ensembleState.strtTmst.keyword" : this.ensembleState_strtTmst_keyword,
			"ensembleState.endTmst" : this.ensembleState_endTmst,
			"ensembleState.endTmst.keyword" : this.ensembleState_endTmst_keyword,
			"ensembleState.statCd" : this.ensembleState_statCd,
			"ensembleState.statCd.keyword" : this.ensembleState_statCd_keyword,
			"ensembleState.correlationId" : this.ensembleState_correlationId,
			"ensembleState.correlationId.keyword" : this.ensembleState_correlationId_keyword,
			"ensembleState.businessKey1" : this.ensembleState_businessKey1,
			"ensembleState.businessKey1.keyword" : this.ensembleState_businessKey1_keyword,
			"ensembleState.businessKey2" : this.ensembleState_businessKey2,
			"ensembleState.businessKey2.keyword" : this.ensembleState_businessKey2_keyword,
			"ensembleState.businessKey3" : this.ensembleState_businessKey3,
			"ensembleState.businessKey3.keyword" : this.ensembleState_businessKey3_keyword,
			"ensembleState.businessKey4" : this.ensembleState_businessKey4,
			"ensembleState.businessKey4.keyword" : this.ensembleState_businessKey4_keyword,
			"ensembleState.businessKey5" : this.ensembleState_businessKey5,
			"ensembleState.businessKey5.keyword" : this.ensembleState_businessKey5_keyword,
			"ensembleState.host" : this.ensembleState_host,
			"ensembleState.host.keyword" : this.ensembleState_host_keyword,
			"and" : this.and,
			"not" : this.not		}
	}
}
export class EnsembleStateSearchFilterAnd {
	/**
	 * Required: false
	 * Array: false
	 */
	instId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:EnsembleStateSearchFilterOr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:EnsembleStateSearchFilterNot[] = undefined;
	public toJSON = function(this: EnsembleStateSearchFilterAnd) {
		return {
			"instId" : this.instId,
			"instId.keyword" : this.instId_keyword,
			"ensembleState.messageId" : this.ensembleState_messageId,
			"ensembleState.messageId.keyword" : this.ensembleState_messageId_keyword,
			"ensembleState.queueName" : this.ensembleState_queueName,
			"ensembleState.queueName.keyword" : this.ensembleState_queueName_keyword,
			"ensembleState.name" : this.ensembleState_name,
			"ensembleState.name.keyword" : this.ensembleState_name_keyword,
			"ensembleState.desc" : this.ensembleState_desc,
			"ensembleState.desc.keyword" : this.ensembleState_desc_keyword,
			"ensembleState.eventTmst" : this.ensembleState_eventTmst,
			"ensembleState.eventTmst.keyword" : this.ensembleState_eventTmst_keyword,
			"ensembleState.strtTmst" : this.ensembleState_strtTmst,
			"ensembleState.strtTmst.keyword" : this.ensembleState_strtTmst_keyword,
			"ensembleState.endTmst" : this.ensembleState_endTmst,
			"ensembleState.endTmst.keyword" : this.ensembleState_endTmst_keyword,
			"ensembleState.statCd" : this.ensembleState_statCd,
			"ensembleState.statCd.keyword" : this.ensembleState_statCd_keyword,
			"ensembleState.correlationId" : this.ensembleState_correlationId,
			"ensembleState.correlationId.keyword" : this.ensembleState_correlationId_keyword,
			"ensembleState.businessKey1" : this.ensembleState_businessKey1,
			"ensembleState.businessKey1.keyword" : this.ensembleState_businessKey1_keyword,
			"ensembleState.businessKey2" : this.ensembleState_businessKey2,
			"ensembleState.businessKey2.keyword" : this.ensembleState_businessKey2_keyword,
			"ensembleState.businessKey3" : this.ensembleState_businessKey3,
			"ensembleState.businessKey3.keyword" : this.ensembleState_businessKey3_keyword,
			"ensembleState.businessKey4" : this.ensembleState_businessKey4,
			"ensembleState.businessKey4.keyword" : this.ensembleState_businessKey4_keyword,
			"ensembleState.businessKey5" : this.ensembleState_businessKey5,
			"ensembleState.businessKey5.keyword" : this.ensembleState_businessKey5_keyword,
			"ensembleState.host" : this.ensembleState_host,
			"ensembleState.host.keyword" : this.ensembleState_host_keyword,
			"or" : this.or,
			"not" : this.not		}
	}
}
export class EnsembleStateSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	b:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	and:EnsembleStateSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:EnsembleStateSearchFilterOr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:EnsembleStateSearchFilterNot[] = undefined;
	public toJSON = function(this: EnsembleStateSearchFilter) {
		return {
			"q" : this.q,
			"b" : this.b,
			"instId" : this.instId,
			"instId.keyword" : this.instId_keyword,
			"ensembleState.messageId" : this.ensembleState_messageId,
			"ensembleState.messageId.keyword" : this.ensembleState_messageId_keyword,
			"ensembleState.queueName" : this.ensembleState_queueName,
			"ensembleState.queueName.keyword" : this.ensembleState_queueName_keyword,
			"ensembleState.name" : this.ensembleState_name,
			"ensembleState.name.keyword" : this.ensembleState_name_keyword,
			"ensembleState.desc" : this.ensembleState_desc,
			"ensembleState.desc.keyword" : this.ensembleState_desc_keyword,
			"ensembleState.eventTmst" : this.ensembleState_eventTmst,
			"ensembleState.eventTmst.keyword" : this.ensembleState_eventTmst_keyword,
			"ensembleState.strtTmst" : this.ensembleState_strtTmst,
			"ensembleState.strtTmst.keyword" : this.ensembleState_strtTmst_keyword,
			"ensembleState.endTmst" : this.ensembleState_endTmst,
			"ensembleState.endTmst.keyword" : this.ensembleState_endTmst_keyword,
			"ensembleState.statCd" : this.ensembleState_statCd,
			"ensembleState.statCd.keyword" : this.ensembleState_statCd_keyword,
			"ensembleState.correlationId" : this.ensembleState_correlationId,
			"ensembleState.correlationId.keyword" : this.ensembleState_correlationId_keyword,
			"ensembleState.businessKey1" : this.ensembleState_businessKey1,
			"ensembleState.businessKey1.keyword" : this.ensembleState_businessKey1_keyword,
			"ensembleState.businessKey2" : this.ensembleState_businessKey2,
			"ensembleState.businessKey2.keyword" : this.ensembleState_businessKey2_keyword,
			"ensembleState.businessKey3" : this.ensembleState_businessKey3,
			"ensembleState.businessKey3.keyword" : this.ensembleState_businessKey3_keyword,
			"ensembleState.businessKey4" : this.ensembleState_businessKey4,
			"ensembleState.businessKey4.keyword" : this.ensembleState_businessKey4_keyword,
			"ensembleState.businessKey5" : this.ensembleState_businessKey5,
			"ensembleState.businessKey5.keyword" : this.ensembleState_businessKey5_keyword,
			"ensembleState.host" : this.ensembleState_host,
			"ensembleState.host.keyword" : this.ensembleState_host_keyword,
			"and" : this.and,
			"or" : this.or,
			"not" : this.not		}
	}
}
/**
 * Path parameters
 */
export class GetElasticDocumentEnsembleStatePath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	instId:string = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentEnsembleStateQuery {
	/**
	 * Source filter
	 * Required: false
	 * Array: false
	 */
	_source:string = undefined;
	/**
	 * Source include filter
	 * Required: false
	 * Array: false
	 */
	_source_includes:string = undefined;
	/**
	 * Source exclude filter
	 * Required: false
	 * Array: false
	 */
	_source_excludes:string = undefined;
}
/**
 * Response from getElasticDocument.
 */
export class GetElasticDocumentEnsembleStateResp {
	/**
	 * The record if found
	 * Required: false
	 * Array: false
	 */
	record:EnsembleStateSearchRecord = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentEnsembleStateCountQuery {
	/**
	 * Query filter
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
}
/**
 * Response from getElasticDocumentCount.
 */
export class GetElasticDocumentEnsembleStateCountResp {
	/**
	 * The count of the documents
	 * Required: false
	 * Array: false
	 */
	count:number = undefined;
}
/**
 * Request message to update information for EnsembleState.
 */
export class UpdateElasticDocumentEnsembleStateRqst {
	/**
	 * The record to upsert in elastic
	 * Required: false
	 * Array: false
	 */
	doc:EnsembleStateSearchRecord = undefined;
	/**
	 * Indicate if an upsert operation should be executed if the document was not found
	 * Required: false
	 * Array: false
	 */
	doc_as_upsert:boolean = undefined;
}
/**
 * Path parameters
 */
export class UpdateElasticDocumentEnsembleStatePath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	instId:string = undefined;
}
/**
 * Response from updateElasticDocument.
 */
export class UpdateElasticDocumentEnsembleStateResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Response from bulkUpdateElasticDocument.
 */
export class BulkUpdateElasticDocumentEnsembleStateResp {
	/**
	 * The time it took to process this request
	 * Required: false
	 * Array: false
	 */
	took:number = undefined;
	/**
	 * Indicates if there are any errors
	 * Required: false
	 * Array: false
	 */
	errors:boolean = undefined;
	/**
	 * Results of the bulk operations
	 * Required: false
	 * Array: true
	 */
	items:BulkApiRespItem[] = undefined;
}
/**
 * Path parameters
 */
export class DeleteElasticDocumentEnsembleStatePath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	instId:string = undefined;
}
/**
 * Request message to delete information for EnsembleState.
 */
export class DeleteElasticDocumentEnsembleStateResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Request message to search information for ensemble-state-unfiltered.
 */
export class SearchEnsembleStateUnfilteredRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	fields:string[] = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:EnsembleStateSearchFilter = undefined;
}
/**
 * Response to the request to search information for ensemble-state-unfiltered.
 */
export class SearchEnsembleStateUnfilteredResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:EnsembleStateSearchRecord[] = undefined;
}
/**
 * Request message to export information for ensemble-state-unfiltered.
 */
export class ExportEnsembleStateUnfilteredRqst {
	/**
	 * Required: false
	 * Array: true
	 */
	fields:string[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fileType:string = undefined;
	/**
	 * Query for exporting
	 * Required: false
	 * Array: false
	 */
	filter:EnsembleStateSearchFilter = undefined;
}
/**
 * Request for register filter
 */
export class RegisterFilterEnsembleStateUnfilteredRqst {
	/**
	 * Id of the  user
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:EnsembleStateSearchFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterEnsembleStateUnfilteredResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterEnsembleStateUnfilteredRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedEnsembleStateUnfilteredRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:EnsembleStateSearchRecord[] = undefined;
}
/**
 * 
 */
export class AreaAssignment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rsnbleDisplayTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryRouteDepartTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pupOnlyInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CompanyOperations {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	companyNameCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	menloOperationsType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rsiOperationsType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trkldOperationsType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityOperationsFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	clasSzCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operations24HrFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dcmntImageFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	displayToFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dpndtFreightType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dpndtSicFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dir:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	facFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	facHrsOfOpnCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightOperationsFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightOperationsType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightServiceAreaFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightServiceAreaType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fuelFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hstedSicFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	intmdlType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedToFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	liftFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lnhDayMeetFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lnhNightMeetFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rrSpurFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	safetyClasSzCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	satlType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentOriginDestinationFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceSatlType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	termProdFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	termStmsLocationFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tieFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	workDoorCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	warmRoomFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	yardMeetAndTurnFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightLtlMovementFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightLtlMaintFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	maintTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	milemakerSplcNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ConWayCorporation {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cnwyCorpCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	altCorpCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cmptCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cmptName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	companyLogoCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	glCorpCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltCorp3CharName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sobCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltT60MajCorp:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	companyNameCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activeFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Door {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	displaySeqNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doorNbrTxt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hzntlCoordNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	vrtclCoordNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sectorId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pupOnlyInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dtlCapxTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replLstUpdtTmst:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlTmst:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentAreaInstId:number = undefined;
}
/**
 * 
 */
export class DpndtCityOperationsLnk {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parntSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	childSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dpndtFreightServiceDayCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class FeatureSetting {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	featureCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	settingValue:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	settingValueType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class GroupMember {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	groupCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	groupCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effectiveDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class HierarchyCategory {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyCategoryName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class HierarchyGroup {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyGroupRefSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyGroupName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltAreaCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltNameA:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltNameB:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltRgnCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentHrchyCategoryCd:string = undefined;
}
/**
 * 
 */
export class HrchyGroupMbr {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyGroupRefSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LoadPlan {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	firstFacSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mustClearSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	secondFacSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	thirdFacSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dayFreightInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originRailSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eslInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationPowerSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	roomSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	thruSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	meetSic:string = undefined;
}
/**
 * 
 */
export class LocFreightPostalArea {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countrySubdivisionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	geoPostalCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightPostalAreaEffDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activeFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dirFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hostSicCd:string = undefined;
}
/**
 * 
 */
export class LocOperationsSvccProfitability {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityOperationsInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoprtPdManifestCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundAvgLoadedWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundManPowerLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundPpldLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundPpldCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prideStudyInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupConfrmInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeVolumeThrshLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	laserPrtr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	linePrtr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoprtObManifestCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoprtDsrLastCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadPlanInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	smart4RolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sctrBalInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrFacInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrFacRolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrInboundInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrInboundRolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoptDsrTripCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mimsOperationsInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mimsRolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockOperationsInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockOperationsRolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inboundArrInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inboundArrRolloutDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	avgOutboundDensity:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	avgOutboundPceVolumeCubicFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LocationAddress {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addressId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	locationAddressEffDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	altAddressType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addr1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addr2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addr3:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	boxNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countrySubdivisionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countrySubdivisionName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	companyName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryInstructionDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nm1Name:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nm2Name:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prmyAddressFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	postalExtCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pyrlCntr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pyrlCorpCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pyrlEnvs:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	geoCodedFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	postalCertifiedFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LocationNonWeekDay {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nonWorkDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessUnitCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	linehaulInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	revenueAllocationNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pndStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LocationOperationalCalendar {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	calendarDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	revenueDayCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	revenueAllocationNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	caNonWeekInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	usNonWeekInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	financialReportDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LocationReference {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpOfcFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dstFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finSicFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finFuncCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finScndFuncCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyGroupFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationsBgnDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationsEndDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pdtTimeOffset:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	physLocationType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prvtFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicScpType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltPrimarySequence:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltTermAbbrvName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	kronFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lseExpiryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ownOrLseCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltCountryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltStateAbbrv:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pdtTimeOffsetInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	slsSicFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	timezoneName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mrcCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scanDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scanLocationCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scanLocationFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trkldCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trkldXrefCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mileageFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rgnSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	safetyClasSzCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	physSiteLatd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	physSiteLngt:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	erpCompanyCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	erpProfitCenterCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	erpDepartmentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of LocationReference. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class LocationReferenceSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:LocationReference = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:LocationReference = undefined;
}
/**
 * 
 */
export class OffshorePort {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mainlandSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effectiveDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expirationDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	portSic:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Sic {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scac:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	termName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	phoneNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
}
/**
 * 
 */
export class EventWait {
	/**
	 * Unique identifier of records in this table. Values populated from sequence
	 * Required: false
	 * Array: false
	 */
	waitInstanceId:number = undefined;
	/**
	 * Id of the ensemble that is being put on lock wait
	 * Required: false
	 * Array: false
	 */
	ensembleInstanceId:string = undefined;
	/**
	 * Name of the queue that was the source of this ensemble message
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * The timestamp of when this ensemble was first put on lock wait
	 * Required: false
	 * Array: false
	 */
	waitStartTmst:Date = undefined;
	/**
	 * The number of times this ensemble was put on lock wait
	 * Required: false
	 * Array: false
	 */
	waitCount:number = undefined;
	/**
	 * The duration of wait in milliseconds
	 * Required: false
	 * Array: false
	 */
	waitTime:number = undefined;
	/**
	 * The maximum wait time in milliseconds before the lock will be overridden
	 * Required: false
	 * Array: false
	 */
	maxWaitTime:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class RetriggerEvent {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventRetriggerId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceTableName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	transactionId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Details of a symphony message
 */
export class SymphonyMessage {
	/**
	 * ID of the message
	 * Required: false
	 * Array: false
	 */
	messageId:string = undefined;
	/**
	 * Timestamp of when the message was enqueued
	 * Required: false
	 * Array: false
	 */
	enqueueTmst:Date = undefined;
	/**
	 * Number of time the message was retried
	 * Required: false
	 * Array: false
	 */
	retryCount:number = undefined;
	/**
	 * Message payload
	 * Required: false
	 * Array: false
	 */
	payload:string = undefined;
}
/**
 * Details about a message queue. E.g. Oracle Advanced Queue
 */
export class SymphonyQueue {
	/**
	 * Name of the queue
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * Maximum number of retries
	 * Required: false
	 * Array: false
	 */
	maxRetries:number = undefined;
	/**
	 * Delay for retries
	 * Required: false
	 * Array: false
	 */
	retryDelay:number = undefined;
	/**
	 * Indicates if enqueue is enabled
	 * Required: false
	 * Array: false
	 */
	enqueueEnabled:string = undefined;
	/**
	 * Indicates if dequeue is enabled
	 * Required: false
	 * Array: false
	 */
	dequeueEnabled:string = undefined;
	/**
	 * The retention time
	 * Required: false
	 * Array: false
	 */
	retention:string = undefined;
}
/**
 * Captures transaction events. API writes one record per transaction.  Added to EVT seed model  06/26/2020 
 */
export class TransactionEvent {
	/**
	 * PK. Unique ID of the transaction. For Oracle, use the transaction id available in the context. Populated by trigger.
	 * Required: false
	 * Array: false
	 */
	transactionId:string = undefined;
	/**
	 * Identifies the type of transaction. Could be method name
	 * Required: false
	 * Array: false
	 */
	transactionType:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * Number of seconds to hold event before publishing.  Added 09/21/2020  LISG-7498
	 * Required: false
	 * Array: false
	 */
	eventReleaseDelaySeconds:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	transactionDetails:TransactionEventDetail[] = undefined;
}
/**
 * Stores the snapshot of all the tables that were updated as part of the transaction. The snapshot is a json representation of all columns that were changed. The snapshot will be created by insert/update/delete triggers on the source table.   Added to EVT seed model  06/26/2020
 */
export class TransactionEventDetail {
	/**
	 * PK. Unique id for the snapshot record. Generated by Sequence.
	 * Required: false
	 * Array: false
	 */
	detailId:number = undefined;
	/**
	 * Unique ID of the transaction. For Oracle, use the transaction id available in the context. Populated by trigger.
	 * Required: false
	 * Array: false
	 */
	transactionId:string = undefined;
	/**
	 * Name of the table for which the snapshot is stored
	 * Required: false
	 * Array: false
	 */
	tableName:string = undefined;
	/**
	 * Json string containing the before and after values for all the columns that were changed by the transaction.
	 * Required: false
	 * Array: false
	 */
	snapshot:object = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Identifies the type of transaction. Could be method name
	 * Required: false
	 * Array: false
	 */
	transactionType:TransactionEventDetailTypeCd = undefined;
}
/**
 * Enter comments
 */
export class ListEnsembleConfigurationsResp {
	/**
	 * List of message queues - AQ and JMS
	 * Required: false
	 * Array: true
	 */
	queues:EnsembleConfiguration[] = undefined;
	/**
	 * List of subscribers - Pub/Sub
	 * Required: false
	 * Array: true
	 */
	subscriptions:EnsembleConfiguration[] = undefined;
	/**
	 * List of API channels
	 * Required: false
	 * Array: true
	 */
	apiChannels:EnsembleConfiguration[] = undefined;
}
/**
 * Payload for processing carrier management transaction events.
 */
export class LocationEventPayload extends PayloadType {
	/**
	 * Snapshots of the records modified by the transaction event for which this payload was published.
	 * Required: true
	 * Array: true
	 */
	snapshots:LocationReferenceSnapshot[] = undefined;
}
/**
 * This type will be used to publish documents targeted for Elastic Search on a Kafka topic. The type includes KafkaMessageHeader.
 */
export class EnsembleStateSearchRecordMessage extends EnsembleStateSearchRecord {
	/**
	 * Additional details about the document
	 * Required: false
	 * Array: false
	 */
	messageHeader:SearchRecordMessageHeader = undefined;
}


