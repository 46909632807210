import {HierarchyGroupCategoryCd} from '../';
import {EnumHelper} from './enum-helper';

export class HierarchyGroupCategoryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in HierarchyGroupCategoryCd ) {
      this._values.push(HierarchyGroupCategoryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): HierarchyGroupCategoryCd {

    for ( const obj in HierarchyGroupCategoryCd ) {
      if (HierarchyGroupCategoryCd[obj] === value){
        return HierarchyGroupCategoryCd[obj] as HierarchyGroupCategoryCd;
      }
    }
  }
}

const HierarchyGroupCategoryCdHelper = new HierarchyGroupCategoryCdHelperEnumHelperClass();
export default HierarchyGroupCategoryCdHelper;
