import { Component, EventEmitter, Output } from '@angular/core';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { isValueFilterValid } from '../../common/common.util';
import { filterFormats, Operators, OperatorText, XpoFilterConditionCriteria } from './../../../../../models/index';

@Component({
  templateUrl: './greater-equal-than.component.html',
  styleUrls: ['../number.scss'],
})
export class XpoConditionNumberGreaterEqualThanComponent extends XpoConditionBase implements XpoFilterCondition {
  static text: string = OperatorText.GreaterEqual;
  static operator = Operators.GreaterEqual;

  isNegative = false;
  model: string = '';

  @Output() inputChanged = new EventEmitter();

  constructor() {
    super(filterFormats.number);
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    return parseFloat(cellValue) >= parseFloat(this.model);
  }
  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.GreaterEqual,
      value: this.model,
    };
  }
  onInputChange(): any {
    this.validateFormat();
    this.inputChanged.emit(this.model);
  }
}
