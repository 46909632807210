import { Component, Input } from '@angular/core';

@Component({
  selector: 'xpo-ssi',
  templateUrl: './special-service-indicator.component.html',
  host: { class: 'xpo-Ssi' },
})
export class XpoSsi {
  private nameValue: string;
  private statusValue: string;

  @Input()
  set name(value: string) {
    this.nameValue = value;
  }
  get name(): string {
    return this.statusValue ? `${this.nameValue}_${this.statusValue}` : this.nameValue;
  }

  @Input()
  set status(value: string) {
    this.statusValue = value;
  }

}
