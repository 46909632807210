import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { XpoIconModule } from '../icon/icon.module';
import { XpoDownloadButtonIcon } from './download-button-icon/download-button-icon.component';
import { XpoDownloadButton } from './download-button.component';
import { XpoDownloadButtonDirective } from './download-button.directive';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    XpoIconModule,
  ],
  exports: [XpoDownloadButton, XpoDownloadButtonDirective],
  declarations: [XpoDownloadButton, XpoDownloadButtonDirective, XpoDownloadButtonIcon],
  entryComponents: [XpoDownloadButtonIcon, MatSpinner],
})
export class XpoDownloadButtonModule {}
