import {BeaconItemStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class BeaconItemStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BeaconItemStatusCd ) {
      this._values.push(BeaconItemStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BeaconItemStatusCd {

    for ( const obj in BeaconItemStatusCd ) {
      if (BeaconItemStatusCd[obj] === value){
        return BeaconItemStatusCd[obj] as BeaconItemStatusCd;
      }
    }
  }
}

const BeaconItemStatusCdHelper = new BeaconItemStatusCdHelperEnumHelperClass();
export default BeaconItemStatusCdHelper;
