import { Component } from '@angular/core';
import { XpoConditionalFilterComponent } from '../conditional-filter.component';
import {
  XpoConditionRangeEmptyComponent,
  XpoConditionRangeNotEmptyComponent,
  XpoConditionTimeRangeAfterComponent,
  XpoConditionTimeRangeBeforeComponent,
  XpoConditionTimeRangeBetweenComponent,
  XpoConditionTimeRangeNotBetweenComponent,
} from '../conditions';

@Component({
  templateUrl: '../conditional-filter.component.html',
  styleUrls: ['../conditional-filter.component.scss'],
})
export class XpoTimeRangeFilterComponent extends XpoConditionalFilterComponent {
  defaultConditions = [
    XpoConditionTimeRangeBetweenComponent,
    XpoConditionTimeRangeNotBetweenComponent,
    XpoConditionTimeRangeBeforeComponent,
    XpoConditionTimeRangeAfterComponent,
    XpoConditionRangeEmptyComponent,
    XpoConditionRangeNotEmptyComponent,
  ];
}
