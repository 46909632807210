import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { XpoBoardConsumer } from '../../../models';

@Component({
  selector: 'xpo-board-toolbar-actions-group',
  templateUrl: 'board-grid-toolbar-actions.component.html',
  styleUrls: ['board-grid-toolbar-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-GridToolbar-container-actions-buttons-group',
    'data-cy': 'actions-buttons-group',
  },
})
export class XpoBoardGridToolbarActions extends XpoBoardConsumer {
  buttons: HTMLElement[] = [];
  onStateChange(): void {}
  updateButtonsVisibility(event: HTMLElement[]) {
    this.buttons = event;
  }
}
