import {TransactionEventDetailTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class TransactionEventDetailTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TransactionEventDetailTypeCd ) {
      this._values.push(TransactionEventDetailTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TransactionEventDetailTypeCd {

    for ( const obj in TransactionEventDetailTypeCd ) {
      if (TransactionEventDetailTypeCd[obj] === value){
        return TransactionEventDetailTypeCd[obj] as TransactionEventDetailTypeCd;
      }
    }
  }
}

const TransactionEventDetailTypeCdHelper = new TransactionEventDetailTypeCdHelperEnumHelperClass();
export default TransactionEventDetailTypeCdHelper;
