import {RschCnclReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class RschCnclReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RschCnclReasonCd ) {
      this._values.push(RschCnclReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RschCnclReasonCd {

    for ( const obj in RschCnclReasonCd ) {
      if (RschCnclReasonCd[obj] === value){
        return RschCnclReasonCd[obj] as RschCnclReasonCd;
      }
    }
  }
}

const RschCnclReasonCdHelper = new RschCnclReasonCdHelperEnumHelperClass();
export default RschCnclReasonCdHelper;
