import {RatingStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class RatingStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RatingStatusCd ) {
      this._values.push(RatingStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RatingStatusCd {

    for ( const obj in RatingStatusCd ) {
      if (RatingStatusCd[obj] === value){
        return RatingStatusCd[obj] as RatingStatusCd;
      }
    }
  }
}

const RatingStatusCdHelper = new RatingStatusCdHelperEnumHelperClass();
export default RatingStatusCdHelper;
