import { ChangeDetectorRef, Directive } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { XpoDateFilterBase } from './date-filter-base';
import { XpoFilterComponentBase } from './filter-component-base';
import { XpoFilterCriteria } from './filter-criteria-type';

@Directive()
export abstract class XpoDateFilterComponentBase extends XpoFilterComponentBase<XpoDateFilterBase> {
  minDate: Date;
  maxDate: Date;
  dateFilter: (date: Date) => boolean;

  constructor(protected cd: ChangeDetectorRef) {
    super();
  }

  protected initialize(): void {
    // TODO: Maybe disable the calender until this loads?
    this.configuration.dateFilterConfig$.pipe(takeUntil(this.componentDestroyed)).subscribe((config) => {
      this.minDate = config ? config.minDate : null;
      this.maxDate = config ? config.maxDate : null;
      this.dateFilter = config ? config.dateFilter : null;
      this.cd.markForCheck();
    });
  }

  protected abstract onCriteriaModified(fieldValue: any, criteria: XpoFilterCriteria): void;
}
