import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiRequest } from '@xpo-ltl/data-api';
import {
  AccountsReceivableApiService,
  ArPaymentDetail,
  ListArPaymentDetailsByProResp,
  ListArPaymentDetailsByProQuery,
} from '@xpo-ltl/sdk-accountsreceivable';
import { GetOdsShipmentResp } from '@xpo-ltl/sdk-shipmentods';
import { map as _map } from 'lodash';
import * as moment_ from 'moment-timezone';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '../../classes/unsubscriber';
import { XpoLtlConditioningService } from '../../services/conditioning-service/conditioning.service';
import { ShipmentReferenceService } from '../shipment-reference.service';

const moment = moment_;

@Component({
  selector: 'xpo-ltl-shipment-details-charges',
  templateUrl: './shipment-details-charges.component.html',
  styleUrls: ['./shipment-details-charges.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentDetailsChargesComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();
  columns = [
    { id: 'proNbr', label: 'PRO', renderer: (row) => this.conditioningService.conditionProNumber(row.proNbr, 10) },
    {
      id: 'totalChargeAmt',
      label: 'Charges',
      renderer: (row) => this.currencyPipe.transform(row.totalChargeAmt, row.currencyCd),
    },
    { id: 'totalPaymentAmt', label: 'Payments', renderer: (row) => this.currencyPipe.transform(row.totalPaymentAmt) },
    { id: 'creditedAmt', label: 'Credited', renderer: (row) => this.currencyPipe.transform(row.crenditedAmt) },
    {
      id: 'totalAdjustmentAmt',
      label: 'Adjustments',
      renderer: (row) => this.currencyPipe.transform(row.totalAdjustmentAmt),
    },
    { id: 'balanceDueAmt', label: 'Balance Due', renderer: (row) => this.currencyPipe.transform(row.balanceDueAmt) },
    { id: 'paymentDate', label: 'Date', renderer: (row) => moment(row.paymentDate).format('MM/DD/YYYY') },
    { id: 'currencyCd', label: 'Currency' },
    { id: 'chargeCd', label: 'Charge CD' },
    { id: 'receiptNbr', label: 'Receipt #' },
  ];
  columnsToDisplay = _map(this.columns, (c) => c.id);

  readonly dataSource = new MatTableDataSource<ArPaymentDetail>(undefined);

  constructor(
    private shipmentRefService: ShipmentReferenceService,
    private conditioningService: XpoLtlConditioningService,
    private accountsReceivableApiService: AccountsReceivableApiService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {
    this.shipmentRefService.shipment$
      .pipe(takeUntil(this.unsubscriber.done$), distinctUntilChanged())
      .subscribe((shipment) => {
        this.updateFormFromShipment(shipment);
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private updateFormFromShipment(shipment: GetOdsShipmentResp) {
    if (!shipment) {
      return;
    }

    const request = new ListArPaymentDetailsByProQuery();
    request.proNbr = shipment.shipment.proNbr;
    request.pickupDate = `${shipment.shipment.pickupDate.valueOf()}`;

    this.accountsReceivableApiService
      .listArPaymentDetailsByPro(request, ApiRequest.concealedCall)
      .subscribe((result: ListArPaymentDetailsByProResp) => {
        this.dataSource.data = result.arPaymentDetails;
      });
  }
}
