import { Injectable } from '@angular/core';

/* XPO */
import { ListModulesResp } from '@xpo-ltl/sdk-moduleexecutor';

/* Rxjs */
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class ListModulesStoreService {
  private listModulesSubject: BehaviorSubject<ListModulesResp>;
  private _listModules$: Observable<ListModulesResp>;

  constructor() {
    this.initialize();
  }

  get listModules$(): Observable<ListModulesResp> {
    return this._listModules$;
  }

  initialize() {
    this.listModulesSubject = new BehaviorSubject<ListModulesResp>(null);
    this._listModules$ = this.listModulesSubject.asObservable().pipe(filter((val) => !!val));
  }

  populateListModules(listModules: ListModulesResp): void {
    this.listModulesSubject.next(listModules);
  }
}
