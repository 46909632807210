import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthService } from 'ngx-auth';
import { LoginService } from './login.service';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginComponent } from '../login.component';

@Injectable()
export class AuthenticationService implements AuthService {

  private _dialogConfig: MatDialogConfig;

  constructor(
    private dialog: MatDialog,
    private loginService: LoginService) {
    this._dialogConfig = new MatDialogConfig();
    this._dialogConfig.disableClose = false;
    // this._dialogConfig.height = '300px';
    this._dialogConfig.width = '400px';
    // this._dialogConfig.position =  { top: '1px', left: '400px' };
  }

  /**
   * Used as part  of the ngxuath stuff and really does not handle issuing previously authorization's that will continue
   * via token rehydration.  To know when a user is authorized (either they are actively logging in or had a token) use
   * the userAuthenticated$ Observable.
   */
  isAuthorized(): Observable<boolean> {
    return of(this.loginService.isAuthorized());
  }


  getAccessToken(): Observable<string> {
    return this.loginService.getAccessToken();
  }

  /**
   * Checks for existing refresh token - if found formats request info and loads new tokens.  If not
   * found initiates login dialog
   */
  refreshToken(): Observable<any> {

    return Observable.create((observer) => {

      const refreshToken: string = this.loginService.getRefreshToken();

      if (!refreshToken) {
        const dialogRef = this.dialog.open(LoginComponent, this._dialogConfig);
        const sub = dialogRef.afterClosed().subscribe(result => {
          observer.next(result);
        }, null, () => { sub.unsubscribe(); });

      } else {

        const sub = this.loginService.loadTokensFromRefresh().subscribe(
          result => {
            observer.next(result);
          },
          err => {
            // If something wrong with using the refresh token, then clear all token information from local storage
            // Then call observer.next and it will re-trigger the login again.
            console.error(err);
            this.loginService.clear();
            observer.next(err);
          },
          () => { sub.unsubscribe(); }
        );
      }
    });
  }

  refreshShouldHappen(response: HttpErrorResponse): boolean {
    return response.status === 401;
  }

  verifyTokenRequest(url: string): boolean {
    return url.endsWith('/token') || url.includes('./');
  }
}
