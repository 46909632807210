import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule, Optional } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { XpoIconSmall } from './icon-small.directive';
import { XpoIcon } from './icon.component';
import { XpoIconLarge } from './icon-large.directive';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [XpoIcon, XpoIconSmall, XpoIconLarge],
  exports: [XpoIcon, XpoIconSmall, XpoIconLarge, MatIconModule],
})
export class XpoIconModule {
  constructor(@Optional() http: HttpClient) {
    if (!http) {
      console.error(
        'XpoIconModule requires an import for HttpClientModule\n' +
          'You need to import the HttpClientModule in your AppModule!'
      );
    }
  }
}
