import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { XpoAgGridBoardView } from '@xpo-ltl/ngx-ltl-board-grid';
import { ModuleExecutorBoardDataSource } from './services/module-executor-board-dataSource';
import { MODULE_EXECUTOR_BOARD_TEMPLATES } from './shared/module-executor-board-templates';

@Component({
  selector: 'ltl-xpo-module-executor-board',
  templateUrl: './module-executor-board.component.html',
  styleUrls: ['./module-executor-board.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ltl-xpo-moduleExecutorContainer',
  },
  providers: [ModuleExecutorBoardDataSource],
})
export class ModuleExecutorBoardComponent implements OnInit {
  @Input()
  title: string;

  views: XpoAgGridBoardView[];

  viewTemplates = MODULE_EXECUTOR_BOARD_TEMPLATES;

  constructor(public dataSource: ModuleExecutorBoardDataSource) {}

  ngOnInit(): void {
    this.views = this.getViews();
  }

  protected getViews(): XpoAgGridBoardView[] {
    return this.viewTemplates.map((template) => {
      return template.createView({
        id: template.id,
        name: template.name,
        visible: true,
      });
    });
  }
}
