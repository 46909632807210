import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MiscLineItemCd, MonetaryAmount } from '@xpo-ltl/sdk-common';
import { GetOdsShipmentResp, MiscLineItem } from '@xpo-ltl/sdk-shipmentods';
import { find as _find, reduce as _reduce } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '../../../classes/unsubscriber';
import { DataDisplayGroupType } from '../../data-display-group/data-display-group.component';
import { ShipmentReferenceService } from '../../shipment-reference.service';

interface GeneralInfoData {
  codAmount: MonetaryAmount;
  palletCount: number;
  pieceCount: number;
  linealFeet: string;
  totalWeight: number;
}

@Component({
  selector: 'xpo-ltl-shipment-details-general-info',
  templateUrl: './shipment-details-general-info.component.html',
  styleUrls: ['./shipment-details-general-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentDetailsGeneralInfoComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();

  private generalInfoSubject = new BehaviorSubject<GeneralInfoData>({
    codAmount: undefined,
    palletCount: undefined,
    pieceCount: undefined,
    linealFeet: undefined,
    totalWeight: undefined,
  });
  readonly generalInfo$ = this.generalInfoSubject.asObservable();

  readonly DataDisplayGroupType = DataDisplayGroupType;

  constructor(private shipmentRefService: ShipmentReferenceService) {}

  ngOnInit() {
    this.shipmentRefService.shipment$
      .pipe(takeUntil(this.unsubscriber.done$), distinctUntilChanged())
      .subscribe((shipment) => {
        this.updateFormFromShipment(shipment);
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private updateFormFromShipment(shipment: GetOdsShipmentResp) {
    if (!shipment) {
      return;
    }

    const totalPieces = _reduce(shipment.commodity, (total, commodity) => total + commodity.piecesCount, 0);
    const codItem: MiscLineItem = _find(shipment.miscLineItem, (li) => li.lineTypeCd === MiscLineItemCd.COD_AMT);
    const codAmount = codItem
      ? {
          amt: codItem.amount,
          currencyCd: 'USD', // always in US$
        }
      : undefined;

    this.generalInfoSubject.next({
      // codAmount: shipment.shipment.codInd ? shipment.chargeInfo.totalChargeAmt : undefined,
      codAmount: codAmount,
      palletCount: shipment.shipment.totalPalletsCount,
      pieceCount: totalPieces,
      linealFeet: shipment.shipment.linealFootTotalNbr,
      totalWeight: shipment.shipment.totalWeightLbs,
    });
  }
}
