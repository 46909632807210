import { Component, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import { xpoDateTimeFormatter } from '../../../../../../formatters';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { ordersMinMaxDateTime } from '../date-time.util';
import { filterFormats, Operators, OperatorText, XpoFilterConditionCriteria } from './../../../../../models/index';

interface DateTimeModel {
  dateFrom: Date | null;
  dateTo: Date | null;
  timeFrom: string;
  timeTo: string;
}

@Component({
  templateUrl: './not-between.component.html',
  styleUrls: ['./not-between.component.scss'],
})
export class XpoConditionDateTimeNotBetweenComponent extends XpoConditionBase implements XpoFilterCondition {
  static text: string = OperatorText.NotBetween;
  static operator = Operators.NotBetween;

  value: string = 'datetimeNotBetween';
  isNegative = false;
  model: DateTimeModel = {
    dateFrom: null,
    dateTo: null,
    timeFrom: '',
    timeTo: '',
  };

  @Output() inputChanged = new EventEmitter();

  constructor() {
    super(filterFormats.dateTimeRange);
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    if (!cellValue) {
      return false;
    }

    const ordered = ordersMinMaxDateTime(
      `${this.model.dateFrom} ${this.model.timeFrom}`,
      `${this.model.dateTo} ${this.model.timeTo}`
    );

    return !moment(cellValue).isBetween(
      ordered.from,
      ordered.to,
      null,
      '[]' // This signifies that the extent is exclusive
    );
  }

  getCriteria(): XpoFilterConditionCriteria {
    const ordered = ordersMinMaxDateTime(
      `${this.model.dateFrom} ${this.model.timeFrom}`,
      `${this.model.dateTo} ${this.model.timeTo}`
    );
    return {
      operator: Operators.Equals,
      value: ordered.from,
      valueTo: ordered.to,
      display: xpoDateTimeFormatter({ value: ordered.from }),
      displayTo: xpoDateTimeFormatter({ value: ordered.to }),
    };
  }

  onInputChange(): any {
    this.validateFormat();
    this.inputChanged.emit(this.model);
  }
}
