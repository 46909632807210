import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, share, takeUntil } from 'rxjs/operators';

import { DashbarService } from '../dashbar.service';
import { XpoDashbarMetricPopover } from './dashbar-metric-popover.directive';

@Component({
  selector: 'xpo-dashbar-metric',
  templateUrl: 'dashbar-metric.component.html',
  styleUrls: ['dashbar-metric.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class XpoDashbarMetric implements OnInit, OnDestroy, AfterContentInit {
  popoverOpened$: Observable<boolean>;
  private componentDestroyed$: Subject<any> = new Subject<any>();
  private hasPopoverSubject = new ReplaySubject<boolean>(1);

  @Input()
  description: string;

  @Input()
  value: string;

  @ContentChild(XpoDashbarMetricPopover)
  popover: XpoDashbarMetricPopover;

  get hasPopoverContent$(): Observable<boolean> {
    return this.hasPopoverSubject.asObservable().pipe(share());
  }

  constructor(private dashbarService: DashbarService) {}

  ngOnInit(): void {
    this.popoverOpened$ = this.dashbarService.openedPopover$.pipe(
      map((servicePopover) => this.popover === servicePopover),
      share(),
      takeUntil(this.componentDestroyed$)
    );
  }

  ngAfterContentInit(): void {
    this.hasPopoverSubject.next(this.popover != null && this.popover.template != null);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  togglePopover(): void {
    if (!this.popover) {
      return;
    }
    this.dashbarService.togglePopover(this.popover);
  }
}
