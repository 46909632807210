import {ClaimantSameAsCd} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimantSameAsCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimantSameAsCd ) {
      this._values.push(ClaimantSameAsCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimantSameAsCd {

    for ( const obj in ClaimantSameAsCd ) {
      if (ClaimantSameAsCd[obj] === value){
        return ClaimantSameAsCd[obj] as ClaimantSameAsCd;
      }
    }
  }
}

const ClaimantSameAsCdHelper = new ClaimantSameAsCdHelperEnumHelperClass();
export default ClaimantSameAsCdHelper;
