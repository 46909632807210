
export enum QueueNameCd {
	CREDIT = 'Credit',
	GOVERNMENT = 'Government',
	GROUPING = 'Grouping',
	LOCAL_REQUEST = 'LocalRequest',
	MEXICO = 'Mexico',
	NAQQ = 'NAQQ',
	NATIONAL_REQ = 'NationalReq',
	NMI = 'NMI',
	PRIORITY = 'Priority',
	SPECIAL_ACCOUNTS = 'SpecialAccounts'}

