import {EcmGoalTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class EcmGoalTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EcmGoalTypeCd ) {
      this._values.push(EcmGoalTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EcmGoalTypeCd {

    for ( const obj in EcmGoalTypeCd ) {
      if (EcmGoalTypeCd[obj] === value){
        return EcmGoalTypeCd[obj] as EcmGoalTypeCd;
      }
    }
  }
}

const EcmGoalTypeCdHelper = new EcmGoalTypeCdHelperEnumHelperClass();
export default EcmGoalTypeCdHelper;
