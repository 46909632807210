import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { XpoViewMgtModalActions } from '../view-modal-actions.interface';
import { XpoViewMgtModalData } from '../view-modal-data.interface';
import { XpoViewMgtModalTexts } from '../view-modal.texts';

@Component({
  selector: 'xpo-save-view-as-modal',
  templateUrl: './save-view-as.component.html',
  styleUrls: ['./save-view-as.component.scss'],
})
export class XpoViewMgtModalSaveViewAs {
  saveAsForm = new FormGroup({
    viewName: new FormControl(XpoViewMgtModalTexts.DefaultViewName, [Validators.required]),
  });

  saveViewAsData: XpoViewMgtModalData = {
    title: XpoViewMgtModalTexts.SaveViewAsTitle,
    submitButtonText: XpoViewMgtModalTexts.SaveViewAsSubmitBtn,
    cancelButtonText: XpoViewMgtModalTexts.SaveViewAsCancelBtn,
  };
  modalActions: XpoViewMgtModalActions;

  @ViewChild('xpoViewModal') xpoViewModal;

  constructor(public dialogRef: MatDialogRef<XpoViewMgtModalSaveViewAs>, @Inject(MAT_DIALOG_DATA) data) {
    this.modalActions = data.actions;
  }

  /**
   * Save button action in modal
   */
  saveViewAs(): void {
    if (!this.saveAsForm.valid) {
      this.saveAsForm.markAllAsTouched();
      return;
    }
    this.xpoViewModal.isSubmitting = true;
    // Callback for save view
    this.modalActions?.onSubmit({
      viewName: this.saveAsForm.get('viewName').value,
    });
  }

  /**
   * Cancel button action in modal
   */
  cancel(): void {
    // Callback for cancel
    this.modalActions?.onCancel();
  }
}
