import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { MatMenuModule } from '@angular/material/menu';
import { XpoUtilsModule } from '../utils/utils.module';
import { XpoIconModule } from './../icon/icon.module';
import { XpoHeaderSubMenuDirective } from './directives/header-sub-menu.directive';
import { XpoHeaderActionsSearch } from './header-action-search.directive';
import { XpoHeaderNavigationDynSubMenuComponent } from './header-navigation-sub-menu/header-navigation-sub-menu.component';
import { XpoHeaderNavigationComponent } from './header-navigation/header-navigation.component';
import {
  XpoHeader,
  XpoHeaderActions,
  XpoHeaderAppName,
  XpoHeaderHamburger,
  XpoHeaderItems,
  XpoHeaderSubAppName,
  XpoHeaderUtilitiesActions,
} from './header.component';
@NgModule({
  declarations: [
    XpoHeader,
    XpoHeaderActions,
    XpoHeaderItems,
    XpoHeaderAppName,
    XpoHeaderHamburger,
    XpoHeaderSubAppName,
    XpoHeaderUtilitiesActions,
    XpoHeaderNavigationComponent,
    XpoHeaderNavigationDynSubMenuComponent,
    XpoHeaderActionsSearch,
    XpoHeaderSubMenuDirective,
  ],
  imports: [CommonModule, MatButtonModule, XpoIconModule, MatMenuModule, XpoUtilsModule],
  exports: [
    XpoHeader,
    XpoHeaderActions,
    XpoHeaderItems,
    XpoHeaderAppName,
    XpoHeaderHamburger,
    XpoHeaderSubAppName,
    XpoHeaderUtilitiesActions,
    XpoHeaderNavigationComponent,
    XpoHeaderNavigationDynSubMenuComponent,
    XpoHeaderActionsSearch,
    XpoUtilsModule,
    XpoHeaderSubMenuDirective,
  ],
})
export class XpoHeaderModule {}
