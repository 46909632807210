import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'a[xpo-vertical-tab]',
  templateUrl: './vertical-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'xpo-VerticalTab',
    '[class.xpo-VerticalTab--is-active]': 'isActive',
  },
  encapsulation: ViewEncapsulation.None,
})
export class XpoVerticalTab {
  /** The Observable for tab state changes */
  private activeChangeSource = new BehaviorSubject<boolean>(false);

  constructor(private location: Location) {}

  /** Whether or not the link should open in a new tab */
  @Input()
  get external(): boolean {
    return this.externalValue;
  }
  set external(value: boolean) {
    this.externalValue = coerceBooleanProperty(value);
  }
  private externalValue: boolean;

  /** Whether or not the link should open in a new window */
  @Input()
  get openInNewWindow(): boolean {
    return this.openInNewWindowValue;
  }
  set openInNewWindow(value: boolean) {
    this.openInNewWindowValue = coerceBooleanProperty(value);
  }
  private openInNewWindowValue: boolean;

  /** Whether or not it is a group header */
  @Input()
  get isGroupHeader(): boolean {
    return this.isGroupHeaderValue;
  }
  set isGroupHeader(value: boolean) {
    this.isGroupHeaderValue = coerceBooleanProperty(value);
  }
  private isGroupHeaderValue: boolean;

  /** Whether or not the child links of a group are shown */
  @Input()
  get showChildLinks(): boolean {
    return this.showChildLinksValue;
  }
  set showChildLinks(value: boolean) {
    this.showChildLinksValue = coerceBooleanProperty(value);
  }
  private showChildLinksValue: boolean;

  /** Whether or not the tab is active */
  @Input()
  get isActive(): boolean {
    return this.isActiveValue;
  }
  set isActive(value: boolean) {
    if (value !== this.isActiveValue) {
      this.isActiveValue = coerceBooleanProperty(value);
      this.activeChangeSource.next(value);
    }
  }
  private isActiveValue: boolean;

  /** The title of the link
   *
   * @deprecated use content projection instead: `<a xpo-vertical-tab>{{ title }}</a>`
   */
  @Input()
  get title(): string {
    return this.titleValue;
  }
  set title(v: string) {
    this.titleValue = v;

    console.warn(
      'WARN: Using the [title] input for xpo-vertial-tab is depricated, ' +
        'use content projection instead: <a xpo-vertical-tab>{{ title }}</a>'
    );
  }
  private titleValue: string;

  /** The URL of the link */
  @Input() url?: string | null = null;

  /** The active change observable */
  get activeChange$(): Observable<boolean> {
    return this.activeChangeSource.asObservable();
  }

  /** Handles the link click to open up a new browser tab or window */
  handleClick(event: MouseEvent): void {
    if (this.external) {
      event.preventDefault(); // Stops native navigation
      event.stopPropagation(); // Stops Angular Router Navigation
      let features = null;
      if (this.openInNewWindow) {
        // Adding a feature to the "window.open()" function will force a new window instead of just a new tab.
        features = 'scrollbars=no';
      }

      window.open(this.location.prepareExternalUrl(this.url || '/'), '_blank', features);
    }
  }
}
