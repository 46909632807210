import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { Component, EventEmitter, Input, OnInit, Optional, Output, Renderer2, ViewChild } from '@angular/core';
import { XpoShell } from '../shell/shell.component';
import { XpoApplicationCategory } from './models/application-category.model';
import { XpoApplication } from './models/application.model';
import { XpoApplicationSwitcherService } from './services/application-switcher.service';

/** CSS class name to add to the Application Switcher dialog overlay pane */
const XPO_APP_SWITCHER_DIALOG_PANE_CLASS: string = 'xpo-ApplicationSwitcher-dialog';

/** CSS class name to add to the Application Switcher dialog overlay wrapper */
const XPO_APP_SWITCHER_DIALOG_WRAPPER_CLASS: string = 'xpo-ApplicationSwitcher-dialog-wrapper';

/** CSS class name to add to the Application Switcher dialog backdrop */
const XPO_APP_SWITCHER_DIALOG_BACKDROP_CLASS: string = 'cdk-overlay-transparent-backdrop';

/**
 * Main Application Switcher component
 */
@Component({
  selector: 'xpo-application-switcher',
  templateUrl: './application-switcher.component.html',
  host: {
    class: 'xpo-ApplicationSwitcher',
  },
  providers: [XpoApplicationSwitcherService],
})
export class XpoApplicationSwitcherComponent implements OnInit {
  /** Data for the application categories and its applications */
  @Input() applicationCategories: XpoApplicationCategory[];

  /** Whether to show only favorite applications when loads */
  @Input() showFavorite: boolean = false;

  /** Emits when an application favorite changes */
  @Output()
  favoriteApplicationChange: EventEmitter<XpoApplication> = new EventEmitter<XpoApplication>();

  /** Notify when user changes the show favorite applications value */
  @Output()
  showFavoriteChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** 
   * Whether the show favorite applications toggle is disabled
   * because the user hasn't any favorite application
   */
  disableFavorite: boolean = false;

  /**
   * The application name to be shown in the Application Switcher header
   */
  get appName(): string {
    // get the application sub-name from the parent shell component
    return this.shell ? this.shell.appName : '';
  }

  /**
   * The application sub-name to be shown in the Application Switcher header
   */
  get appSubName(): string {
    // get the application sub-name from the parent shell component
    return this.shell ? this.shell.appSubName : '';
  }

  /** Reference to the dialog when the calendar is opened */
  protected dialogRef: OverlayRef = null;

  /** the template to be attached and renderer inside the dialog */
  @ViewChild(CdkPortal) protected templatePortal: CdkPortal;

  /** Applications filtered by favorite value */
  filteredApplications: XpoApplicationCategory[] = [];

  constructor(
    protected overlay: Overlay,
    protected renderer: Renderer2,
    protected service: XpoApplicationSwitcherService,
    @Optional() protected shell: XpoShell
  ) {}

  ngOnInit(): void {

    // subscribe to favorite application changes
    this.service.favoriteApplicationChange$.subscribe((application) => {
      this.onFavoriteApplicationChange(application);
    });

    // filter applications depending on show favorite value
    this.showApplications();
  }

  /**
   * Opens the application switcher dialog
   */
  open(): void {
    // check if dialog is not open
    if (!this.dialogRef) {
      // create the dialog with the applications content
      // renderer inside it
      this.createDialog();
    }
  }

  /**
   * Closes the application switcher dialog
   */
  close(): void {
    // check if dialog is open
    if (this.dialogRef) {
      // destroy the dialog overlay reference
      this.destroyDialog();
    }
  }

  /**
   * Creates and shows the dialog with the applications content
   */
  protected createDialog(): void {
    // create the dialog configuration object
    // with a global position strategy
    const dialogConfig = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      hasBackdrop: true,
      backdropClass: XPO_APP_SWITCHER_DIALOG_BACKDROP_CLASS,
      panelClass: XPO_APP_SWITCHER_DIALOG_PANE_CLASS,
    });

    // create the dialog and keep its reference
    this.dialogRef = this.overlay.create(dialogConfig);

    // render the template inside the dialog
    this.dialogRef.attach(this.templatePortal);

    // add custom css class to the dialog HTML element
    this.renderer.addClass(this.dialogRef.hostElement, XPO_APP_SWITCHER_DIALOG_WRAPPER_CLASS);

    // close dialog when click on backdrop
    this.dialogRef.backdropClick().subscribe(() => this.close());
  }

  /**
   * Destroys the dialog
   */
  protected destroyDialog(): void {
    this.dialogRef.dispose();
    this.dialogRef = null;
  }

  /** 
   * Handles when user changes the show favorite applications value 
   */
  onShowFavoriteChange(favorite: boolean): void {
    // change show favorite value
    this.showFavorite = favorite;

    // set the applications to be displayed
    if (this.showFavorite) {
      this.filteredApplications = this.getFavoriteApplications();
    } else {
      this.filteredApplications = this.applicationCategories;
    }

    // notify show favorite value change
    this.showFavoriteChange.emit(this.showFavorite);
  }

  /**
   * Handles when the user changes an application favorite value
   */
  protected onFavoriteApplicationChange(application: XpoApplication): void {
    // filter applications
    this.showApplications();
    // notify the application change
    this.favoriteApplicationChange.emit(application);
  }

  /**
   * Show applications base on favorite values
   */
  protected showApplications(): void {

    // show all applications by default
    this.filteredApplications = this.applicationCategories;

    // get favorite applications
    const favoriteApplications = this.getFavoriteApplications();

    // disable toggle if there are no favorite apps
    this.disableFavorite = favoriteApplications.length === 0;

    // if we are showing favorite apps (toggle "on")
    // but there are no favorite apps to show
    if (this.disableFavorite && this.showFavorite) {

      // need to change the show favorite application toggle ("off")
      this.showFavorite = false;

      // notify the show favorite applications value change
      this.showFavoriteChange.emit(false);

    }

    // if we are still showing favorite applications
    if (this.showFavorite) {
      // replace applications with favorite ones
      this.filteredApplications = favoriteApplications;
    }
  }

  /**
   * Gets application categories with at least one favorite application
   */
  protected getFavoriteApplications(): XpoApplicationCategory[] {
    return this.applicationCategories
      .map<XpoApplicationCategory>((category: XpoApplicationCategory) => (
      {
        // filter the favorite applications for the current category
        id: category.id,
        name: category.name,
        icon: category.icon,
        applications: category.applications.filter((app) => app.favorite)
      }))
      // filter the categories with favorite apps
      .filter((category: XpoApplicationCategory) => category.applications.length > 0);
  }
}
