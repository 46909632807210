import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { XpoAccordionGroupCellRendererComponent } from './accordion-group/accordion-group-cell-renderer.component';
@NgModule({
  imports: [CommonModule],
  declarations: [XpoAccordionGroupCellRendererComponent],
  exports: [XpoAccordionGroupCellRendererComponent],
  entryComponents: [XpoAccordionGroupCellRendererComponent],
})
export class XpoCellRenderersModule {}
