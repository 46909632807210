import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar, MatDatepickerIntl, yearsPerPage } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatePickerViewTypeEnum } from '../../enum/datepicker-view-type.enum';

@Component({
  selector: 'xpo-datepicker-custom-header',
  styleUrls: ['datepicker-custom-header.component.scss'],
  templateUrl: 'datepicker-custom-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpoDatePickerCustomHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    private _intl: MatDatepickerIntl,
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    changeDetectorRef: ChangeDetectorRef
  ) {
    this._calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => changeDetectorRef.markForCheck());
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodButtonText(): string {
    if (this._calendar.startView === DatePickerViewTypeEnum.Month) {
      if (this._calendar.currentView === DatePickerViewTypeEnum.Month) {
        return this._dateAdapter
          .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
          .toLocaleUpperCase();
      }

      return this._dateAdapter.getYearName(this._calendar.activeDate);
    } else {
      if (this._calendar.currentView === DatePickerViewTypeEnum.Month) {
        return this._dateAdapter
          .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
          .toLocaleUpperCase();
      }
      if (this._calendar.currentView === DatePickerViewTypeEnum.Year) {
        return this._dateAdapter.getYearName(this._calendar.activeDate);
      }

      return this.yearsRangeLabel();
    }
  }

  yearsRangeLabel(): string {
    const activeYear = this._dateAdapter.getYear(this._calendar.activeDate);
    const minYearOfPage =
      activeYear -
      this._getActiveOffset(
        this._dateAdapter,
        this._calendar.activeDate,
        this._calendar.minDate,
        this._calendar.maxDate
      );
    const maxYearOfPage = minYearOfPage + yearsPerPage - 1;
    const minYearName = this._dateAdapter.getYearName(this._dateAdapter.createDate(minYearOfPage, 0, 1));
    const maxYearName = this._dateAdapter.getYearName(this._dateAdapter.createDate(maxYearOfPage, 0, 1));
    return this.formatYearRange(minYearName, maxYearName);
  }

  formatYearRange(start: string, end: string): string {
    return `${start} \u2013 ${end}`;
  }

  get periodButtonLabel(): string {
    return this._calendar.currentView === DatePickerViewTypeEnum.Month
      ? this._intl.switchToMultiYearViewLabel
      : this._intl.switchToMonthViewLabel;
  }

  get prevButtonLabel(): string {
    let label = '';

    switch (this._calendar.currentView) {
      case DatePickerViewTypeEnum.Month:
        label = this._intl.prevMonthLabel;
        break;
      case DatePickerViewTypeEnum.Year:
        label = this._intl.prevYearLabel;
        break;
      case DatePickerViewTypeEnum.MultiYear:
        label = this._intl.prevMultiYearLabel;
        break;
      default:
        break;
    }

    return label;
  }

  get nextButtonLabel(): string {
    let label = '';

    switch (this._calendar.currentView) {
      case DatePickerViewTypeEnum.Month:
        label = this._intl.nextMonthLabel;
        break;
      case DatePickerViewTypeEnum.Year:
        label = this._intl.nextYearLabel;
        break;
      case DatePickerViewTypeEnum.MultiYear:
        label = this._intl.nextMultiYearLabel;
        break;
      default:
        break;
    }

    return label;
  }

  currentPeriodClicked(): void {
    if (this._calendar.startView === DatePickerViewTypeEnum.Year) {
      this._calendar.currentView =
        this._calendar.currentView === DatePickerViewTypeEnum.Year
          ? DatePickerViewTypeEnum.MultiYear
          : DatePickerViewTypeEnum.Year;
    } else if (this._calendar.startView !== DatePickerViewTypeEnum.MultiYear) {
      if (this._calendar.currentView === DatePickerViewTypeEnum.Month) {
        this._calendar.currentView = DatePickerViewTypeEnum.Year;
      } else if (this._calendar.currentView === DatePickerViewTypeEnum.Year) {
        this._calendar.currentView = DatePickerViewTypeEnum.MultiYear;
      } else if (this._calendar.currentView === DatePickerViewTypeEnum.MultiYear) {
        this._calendar.currentView = DatePickerViewTypeEnum.Month;
      }
    }
  }

  previousClicked(): void {
    this._calendar.activeDate =
      this._calendar.currentView === DatePickerViewTypeEnum.Month
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(
          this._calendar.activeDate,
          this._calendar.currentView === DatePickerViewTypeEnum.Year ? -1 : -yearsPerPage
        );
  }

  nextClicked(): void {
    this._calendar.activeDate =
      this._calendar.currentView === DatePickerViewTypeEnum.Month
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(
          this._calendar.activeDate,
          this._calendar.currentView === DatePickerViewTypeEnum.Year ? 1 : yearsPerPage
        );
  }

  previousEnabled(): boolean {
    if (!this._calendar.minDate) {
      return true;
    }
    return !this._calendar.minDate || !this._isSameView(this._calendar.activeDate, this._calendar.minDate);
  }

  nextEnabled(): boolean {
    return !this._calendar.maxDate || !this._isSameView(this._calendar.activeDate, this._calendar.maxDate);
  }

  private _isSameView(date1: D, date2: D): boolean {
    if (this._calendar.currentView === DatePickerViewTypeEnum.Month) {
      return (
        this._dateAdapter.getYear(date1) === this._dateAdapter.getYear(date2) &&
        this._dateAdapter.getMonth(date1) === this._dateAdapter.getMonth(date2)
      );
    }
    if (this._calendar.currentView === DatePickerViewTypeEnum.Year) {
      return this._dateAdapter.getYear(date1) === this._dateAdapter.getYear(date2);
    }
    return this._isSameMultiYearView(this._dateAdapter, date1, date2, this._calendar.minDate, this._calendar.maxDate);
  }

  private _isSameMultiYearView(
    dateAdapter: DateAdapter<D>,
    date1: D,
    date2: D,
    minDate: D | null,
    maxDate: D | null
  ): boolean {
    const year1 = dateAdapter.getYear(date1);
    const year2 = dateAdapter.getYear(date2);
    const startingYear = this._getStartingYear(dateAdapter, minDate, maxDate);
    return Math.floor((year1 - startingYear) / yearsPerPage) === Math.floor((year2 - startingYear) / yearsPerPage);
  }

  private _getActiveOffset(dateAdapter: DateAdapter<D>, activeDate: D, minDate: D | null, maxDate: D | null): number {
    const activeYear = dateAdapter.getYear(activeDate);
    return this._euclideanModulo(activeYear - this._getStartingYear(dateAdapter, minDate, maxDate), yearsPerPage);
  }

  private _getStartingYear(dateAdapter: DateAdapter<D>, minDate: D | null, maxDate: D | null): number {
    let startingYear = 0;
    if (maxDate) {
      const maxYear = dateAdapter.getYear(maxDate);
      startingYear = maxYear - yearsPerPage + 1;
    } else if (minDate) {
      startingYear = dateAdapter.getYear(minDate);
    }
    return startingYear;
  }

  private _euclideanModulo(a: number, b: number): number {
    return ((a % b) + b) % b;
  }
}
