import { XpoFilterCriteria } from '../filters/models/filter-criteria-type';
import { XpoBoardState } from './board-state.model';
import { XpoBoardView, XpoBoardViewTemplate } from './board-view.model';

export class XpoBoardViewUtil {
  private static viewCounter = 1;

  /**
   * When a view change happens, we want to reset these values to their default values,
   * since we don't want to propigate a selection, pageNumber, or selection from view to view
   */
  private static viewStateUpdateResetValues: Partial<XpoBoardState> = {
    selection: [],
    focusedRecord: null,
    pageNumber: 1,
  };

  static activateView(
    view: XpoBoardView,
    currentState: XpoBoardState,
    source: string,
    persistCriteria: boolean = false
  ): XpoBoardState {
    const criteria = this.constructCriteria(view, currentState, persistCriteria);

    return {
      ...view.getState(criteria),
      ...this.viewStateUpdateResetValues,
      availableViews: XpoBoardViewUtil.applyStateChangesToView(currentState),
      source: source,
    };
  }

  static addView(
    view: XpoBoardView,
    state: XpoBoardState,
    source: string,
    persistCriteria: boolean = false
  ): XpoBoardState {
    const criteria = this.constructCriteria(view, state, persistCriteria);

    return {
      ...view.getState(criteria),
      ...this.viewStateUpdateResetValues,
      availableViews: [...XpoBoardViewUtil.applyStateChangesToView(state), view],
      visibleViewData: [...state.visibleViewData, { viewId: view.id }],
      source: source,
      isViewSystemDefined: false,
    };
  }

  static deleteView(viewId: string, state: XpoBoardState, source: string): XpoBoardState {
    const resultState = XpoBoardViewUtil.hideView(viewId, state, source);

    resultState.availableViews = XpoBoardViewUtil.removeFromAvailableViews(viewId, state.availableViews);

    return resultState;
  }

  /**
   * Retrieves the currently active view from the state object
   * @param state
   */
  static findActiveView(state: XpoBoardState): XpoBoardView {
    // separating this out from the return line since you will get a compile time error with using lambda functions on
    // a static function
    const result = state && state.viewId ? (state.availableViews || []).find((v) => v.id === state.viewId) : null;

    return result;
  }

  static getNextViewName(template: XpoBoardViewTemplate): string {
    const templateName = template.name ? template.name : 'New View';
    return templateName + ' ' + XpoBoardViewUtil.viewCounter++;
  }

  static hideView(viewId: string, state: XpoBoardState, source: string): XpoBoardState {
    const visibleViewData = [...(state.visibleViewData || [])];
    const viewIDX = visibleViewData.findIndex((vv) => vv.viewId === viewId);

    if (viewIDX !== -1) {
      visibleViewData.splice(viewIDX, 1); // remove the view
    }

    // display the next view, if you are hiding the current view
    const result: XpoBoardState =
      state.viewId === viewId
        ? { ...XpoBoardViewUtil.getNextView(viewId, state).getState(), visibleViewData, source, pageNumber: 1 }
        : { visibleViewData, source };

    // if closing a transient view, remove it from the list of available
    // else apply any transient changes to the view, so they are not lost
    const view = state.availableViews.find((v) => v.id === viewId);

    if (view && !view.lastSaved) {
      result.availableViews = XpoBoardViewUtil.removeFromAvailableViews(viewId, state.availableViews);
    } else {
      result.availableViews = XpoBoardViewUtil.applyStateChangesToView(state);
    }

    return result;
  }

  static showView(viewId: string, state: XpoBoardState, source: string): XpoBoardState {
    const view = state.availableViews.find((v) => v.id === viewId);

    const visibleViewData = [...(state.visibleViewData || [])];
    visibleViewData.push({ viewId });

    return { ...view.getState(), visibleViewData, source, pageNumber: 1 };
  }

  static updateView(view: XpoBoardView, state: XpoBoardState, source: string): XpoBoardState {
    // replace the old view, with the new copy
    const views = [...state.availableViews];
    const idx = views.findIndex((v) => v.id === view.id);
    views.splice(idx, 1, view);

    return {
      ...view.getState(),
      availableViews: views,
      pageNumber: 1,
      source: source,
    };
  }

  /**
   * Updates the availableView with any pending changes from the state object
   */
  private static applyStateChangesToView(state: XpoBoardState): XpoBoardView[] {
    const activeView = XpoBoardViewUtil.findActiveView(state);

    // if there is no active view, nothing to do
    if (!activeView) {
      return state.availableViews;
    }

    // replace the old view, with the new copy
    const views = [...state.availableViews];
    const idx = views.findIndex((v) => v.id === activeView.id);
    views.splice(idx, 1, activeView.template.createView(activeView.toViewConfig(state)));

    return views;
  }

  /**
   * Determines the next view to show
   */
  private static getNextView(viewId: string, state: XpoBoardState): XpoBoardView {
    // TODO: handle closing the last view
    if (state.visibleViewData.length === 1) {
      throw new Error('Closing last view is not currently supported');
    }

    const currentViewIdx = state.visibleViewData.findIndex((vv) => vv.viewId === viewId);

    // if last view, go current position - 1, else position + 1
    const nextViewIdx = currentViewIdx + 1 === state.visibleViewData.length ? currentViewIdx - 1 : currentViewIdx + 1;

    return state.availableViews.find((v) => v.id === state.visibleViewData[nextViewIdx].viewId);
  }

  private static removeFromAvailableViews(viewId: string, views: XpoBoardView[]): XpoBoardView[] {
    const result = [...views];

    const viewIdx = result.findIndex((v) => v.id === viewId);
    if (viewIdx !== -1) {
      result.splice(viewIdx, 1);
    }

    return result;
  }

  private static constructCriteria(
    view: XpoBoardView,
    currentState: XpoBoardState,
    persistCriteria: boolean
  ): XpoFilterCriteria {
    let criteria = null;

    if (persistCriteria) {
      criteria = { ...currentState.criteria, ...view.criteria };
    } else {
      criteria = currentState.viewId !== view.id ? view.criteria : currentState.criteria;
    }
    return criteria;
  }
}
