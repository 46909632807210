
export enum ClaimDecisionCategoryCd {
	AGREEMENT = 'Agreement',
	AUTO_APPROVED = 'AutoApproved',
	CANCELLED_BY_CUSTOMER = 'CancelledByCustomer',
	CLEAR_DELIVERY = 'ClearDelivery',
	CONCEALED_DAMAGE = 'ConcealedDamage',
	CONSIGNEE_DROP_TRAILER_PROCEDURE_VIOLATION = 'ConsigneeDropTrailerProcedureViolation',
	CROSS_OR_OVER_DELIVERY = 'CrossOrOverDelivery',
	DAMAGED_AT_PICKUP = 'DamagedAtPickup',
	DAMAGE_DUE_TO = 'DamageDueTo',
	DUPLICATE_CLAIM = 'DuplicateClaim',
	FAILED_TO_HOLD_CONTAINER_PACKAGING_OR_CONTENTS = 'FailedToHoldContainerPackagingOrContents',
	FILED_LATE = 'FiledLate',
	INADEQUATE_DOCUMENTS = 'InadequateDocuments',
	LIABILITY_NOT_MITIGATED_BY_CLAIMANT = 'LiabilityNotMitigatedByClaimant',
	LOADING = 'Loading',
	NOT_HANDLED_BY_XPO = 'NotHandledByXpo',
	PACKAGING = 'Packaging',
	POLICY_PAY_DAMAGE = 'PolicyPayDamage',
	POLICY_PAY_LOSS = 'PolicyPayLoss',
	RETURNED_TO_SHIPPER = 'ReturnedToShipper',
	SHIPMENT_FULL_DELIVERY = 'ShipmentFullDelivery',
	SHIPPER_AGREEMENT = 'ShipperAgreement',
	SHIPPER_LOAD_AND_COUNT = 'ShipperLoadAndCount',
	SHIPPER_LOAD_AND_COUNT_PROCEDURE_VIOLATION = 'ShipperLoadAndCountProcedureViolation',
	SHORTAGE_FROM_OPEN_OR_DAMAGED_CARTON = 'ShortageFromOpenOrDamagedCarton',
	SHORTAGE_PARTIALLY_CLEARED = 'ShortagePartiallyCleared',
	SHORT_AT_ARRIVAL = 'ShortAtArrival',
	SHORT_AT_DELIVERY = 'ShortAtDelivery',
	SHORT_AT_DESTINATION = 'ShortAtDestination',
	SHORT_AT_ORIGIN = 'ShortAtOrigin',
	SHORT_AT_PICKUP = 'ShortAtPickup',
	SHORT_DUE_TO_BILLING_ERROR = 'ShortDueToBillingError',
	SHORT_DUE_TO_MISLOAD = 'ShortDueToMisload',
	SHRINK_WRAP_BROKEN = 'ShrinkWrapBroken',
	SHRINK_WRAP_INTACT = 'ShrinkWrapIntact',
	SPECIAL_DAMAGES_XPO_NOT_LIABLE = 'SpecialDamagesXpoNotLiable',
	SUPERVISED_LOAD_OR_UNLOAD = 'SupervisedLoadOrUnload',
	THEFT = 'Theft',
	UNDETERMINED = 'Undetermined',
	VAGUE_DELIVERY_EXCEPTION = 'VagueDeliveryException',
	VEHICULAR_ACCIDENT = 'VehicularAccident',
	WRECK_AND_FIRE = 'WreckAndFire'}

