import {ExceptionTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ExceptionTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ExceptionTypeCd ) {
      this._values.push(ExceptionTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ExceptionTypeCd {

    for ( const obj in ExceptionTypeCd ) {
      if (ExceptionTypeCd[obj] === value){
        return ExceptionTypeCd[obj] as ExceptionTypeCd;
      }
    }
  }
}

const ExceptionTypeCdHelper = new ExceptionTypeCdHelperEnumHelperClass();
export default ExceptionTypeCdHelper;
