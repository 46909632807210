import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoLtlDialogTitleComponent } from './dialog-title.component';

const components = [XpoLtlDialogTitleComponent];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, MatDialogModule, XpoDialogModule, DragDropModule],
})
export class XpoLtlDialogTitleModule {}
