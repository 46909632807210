import {ExceptionStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ExceptionStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ExceptionStatusCd ) {
      this._values.push(ExceptionStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ExceptionStatusCd {

    for ( const obj in ExceptionStatusCd ) {
      if (ExceptionStatusCd[obj] === value){
        return ExceptionStatusCd[obj] as ExceptionStatusCd;
      }
    }
  }
}

const ExceptionStatusCdHelper = new ExceptionStatusCdHelperEnumHelperClass();
export default ExceptionStatusCdHelper;
