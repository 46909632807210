import {DisputeStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeStatusCd ) {
      this._values.push(DisputeStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeStatusCd {

    for ( const obj in DisputeStatusCd ) {
      if (DisputeStatusCd[obj] === value){
        return DisputeStatusCd[obj] as DisputeStatusCd;
      }
    }
  }
}

const DisputeStatusCdHelper = new DisputeStatusCdHelperEnumHelperClass();
export default DisputeStatusCdHelper;
