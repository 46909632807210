import {CollectMoneyAtDeliveryCd} from '../';
import {EnumHelper} from './enum-helper';

export class CollectMoneyAtDeliveryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CollectMoneyAtDeliveryCd ) {
      this._values.push(CollectMoneyAtDeliveryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CollectMoneyAtDeliveryCd {

    for ( const obj in CollectMoneyAtDeliveryCd ) {
      if (CollectMoneyAtDeliveryCd[obj] === value){
        return CollectMoneyAtDeliveryCd[obj] as CollectMoneyAtDeliveryCd;
      }
    }
  }
}

const CollectMoneyAtDeliveryCdHelper = new CollectMoneyAtDeliveryCdHelperEnumHelperClass();
export default CollectMoneyAtDeliveryCdHelper;
