import {ApiTypeAttributeDataTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ApiTypeAttributeDataTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ApiTypeAttributeDataTypeCd ) {
      this._values.push(ApiTypeAttributeDataTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ApiTypeAttributeDataTypeCd {

    for ( const obj in ApiTypeAttributeDataTypeCd ) {
      if (ApiTypeAttributeDataTypeCd[obj] === value){
        return ApiTypeAttributeDataTypeCd[obj] as ApiTypeAttributeDataTypeCd;
      }
    }
  }
}

const ApiTypeAttributeDataTypeCdHelper = new ApiTypeAttributeDataTypeCdHelperEnumHelperClass();
export default ApiTypeAttributeDataTypeCdHelper;
