import { Location } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextualHeaderBackArrow } from '../contextual-header-config';

@Component({
  selector: 'xpo-contextual-header-arrow-back',
  templateUrl: './contextual-header-arrow-back.component.html',
  styleUrls: ['./contextual-header-arrow-back.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-HeaderArrow',
  },
})
export class ContextualHeaderArrowBackComponent {
  @Input() config: ContextualHeaderBackArrow;

  constructor(private location: Location, private router: Router, private route: ActivatedRoute) {}

  goBack(): void {
    if (this.config) {
      const relativeTo = this.config.relativeTo ? { relativeTo: this.route } : null;
      this.router.navigate([this.config.path], relativeTo);
    } else {
      this.location.back();
    }
  }
}
