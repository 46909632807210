import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { LoggingApiService } from './logging-api.service';
import { IHttpLoggingConfig} from './logging-config';
import { detect } from 'detect-browser';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class LoggingUserContextHttpInterceptor implements HttpInterceptor {
  private userContextPath: string;
  private unsubscribe$ = new Subject<void>();

  constructor(private loggingApiService: LoggingApiService, private configManagerService: ConfigManagerService) {
    this.configManagerService.configured$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      config => {
        if (config) {
          this.userContextPath = config.userContextPath;
          this.unsubscribe$.next();
          this.unsubscribe$.complete();
          this.unsubscribe$ = null;
        }
      }
    )
  }
  private ValidURL(url) {
    return /^(http|https):\/\/[^ "]+$/.test(url);
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.ValidURL(req.url)) {
      return next.handle(req);
    }
    const url = new URL(req.url);
    return next.handle(req).pipe(
      distinctUntilChanged(),
      tap((response) => {
          if (response instanceof HttpResponse) {
            const status = Number.parseInt(`${response['status']}`);
            const is200 = (status & 200) === 200;
            const isContextPath = this.isUserContextPath(url.pathname);
            const isGet = req.method === 'GET';
            const hasUserInfo = response && response.body && response.body.data && response.body.data.userId;
            if (isContextPath && is200 && isGet && hasUserInfo) {
              const browserInfo = detect();
              this.loggingApiService.setUserContext(response.body.data.userId, `${browserInfo.name}-${browserInfo.version}`);
              this.loggingApiService.info(
                `Session Info - Browser Name: ${browserInfo.name} - Browser Version:${browserInfo.version} - OS: ${browserInfo.os}`,
                null,
                null,
                'Version Info'
              );
            }
          }
        },
        (err: HttpErrorResponse) => {
          throw err;
        }));
  }

  private isUserContextPath(pathname: string): boolean {
    return (!this.userContextPath) ? false : pathname.indexOf(this.userContextPath) >= 0;
  }
}
