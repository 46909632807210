import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export const DATE_FORMAT = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

export const TIME_FORMAT = {
  hour: '2-digit',
  minute: '2-digit',
  second: 'numeric',
  hour12: false,
};

@Component({
  selector: 'date-time-cell',
  templateUrl: './date-time-cell-renderer.component.html',
})
export class ModuleExecutorDateTimeRenderComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  getDateTime(value: Date): String {
    return (
      new Date(value).toLocaleDateString('en-US', DATE_FORMAT) +
      ' - ' +
      new Date(value).toLocaleTimeString('en-US', TIME_FORMAT)
    );
  }
}
