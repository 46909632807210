import {InvoiceCurrencyCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoiceCurrencyCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoiceCurrencyCd ) {
      this._values.push(InvoiceCurrencyCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoiceCurrencyCd {

    for ( const obj in InvoiceCurrencyCd ) {
      if (InvoiceCurrencyCd[obj] === value){
        return InvoiceCurrencyCd[obj] as InvoiceCurrencyCd;
      }
    }
  }
}

const InvoiceCurrencyCdHelper = new InvoiceCurrencyCdHelperEnumHelperClass();
export default InvoiceCurrencyCdHelper;
