import {ApiContractStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ApiContractStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ApiContractStatusCd ) {
      this._values.push(ApiContractStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ApiContractStatusCd {

    for ( const obj in ApiContractStatusCd ) {
      if (ApiContractStatusCd[obj] === value){
        return ApiContractStatusCd[obj] as ApiContractStatusCd;
      }
    }
  }
}

const ApiContractStatusCdHelper = new ApiContractStatusCdHelperEnumHelperClass();
export default ApiContractStatusCdHelper;
