export class LoggingConstants {
  public static readonly ActivityNames = {
    ApiGatewayCall: 'API Gateway Call',
  };
  public static readonly ActionNames = {
    Request: 'request',
    Response: 'response',
    Error: 'error',
    Change: 'ValueChanged'
  };
  public static readonly MESSAGE_TYPE = 'json';
}
