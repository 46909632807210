import { Component } from '@angular/core';
import { XpoConditionalFilterComponent } from '../conditional-filter.component';
import {
  XpoConditionEmptyComponent,
  XpoConditionIsNotEmptyComponent,
  XpoConditionIsOneOfComponent,
  XpoConditionStringContainsComponent,
  XpoConditionStringDoesNotContainComponent,
  XpoConditionStringEndsWithComponent,
  XpoConditionStringEqualsComponent,
  XpoConditionStringIsBetweenComponent,
  XpoConditionStringIsNotBetweenComponent,
  XpoConditionStringIsNotEqualComponent,
  XpoConditionStringStartsWithComponent,
} from '../conditions';

@Component({
  templateUrl: '../conditional-filter.component.html',
  styleUrls: ['../conditional-filter.component.scss'],
})
export class XpoStringFilterComponent extends XpoConditionalFilterComponent {
  defaultConditions = [
    XpoConditionStringContainsComponent,
    XpoConditionStringDoesNotContainComponent,
    XpoConditionStringEndsWithComponent,
    XpoConditionStringStartsWithComponent,
    XpoConditionStringEqualsComponent,
    XpoConditionStringIsNotEqualComponent,
    XpoConditionIsOneOfComponent,
    XpoConditionStringIsBetweenComponent,
    XpoConditionStringIsNotBetweenComponent,
    XpoConditionEmptyComponent,
    XpoConditionIsNotEmptyComponent,
  ];
}
