
export enum ClaimEmailTemplateFieldCd {
	APPROVE_LINK = 'APPROVE_LINK',
	CHK_NBR = 'CHK_NBR',
	CLAIMANT_NAME = 'CLAIMANT_NAME',
	CLAIMANT_REF_NBR = 'CLAIMANT_REF_NBR',
	CLM_AMOUNT = 'CLM_AMOUNT',
	CLM_ID = 'CLM_ID',
	CONSIGNEE = 'CONSIGNEE',
	DECLINE_LINK = 'DECLINE_LINK',
	EMAIL_NOTE = 'EMAIL_NOTE',
	EXAMINER_CITY = 'EXAMINER_CITY',
	EXAMINER_COUNTRY = 'EXAMINER_COUNTRY',
	EXAMINER_NAME = 'EXAMINER_NAME',
	EXAMINER_PHONE = 'EXAMINER_PHONE',
	EXAMINER_ROLE = 'EXAMINER_ROLE',
	EXAMINER_STATE = 'EXAMINER_STATE',
	EXAMINER_STREET = 'EXAMINER_STREET',
	EXAMINER_ZIP = 'EXAMINER_ZIP',
	FILING_DT = 'FILING_DT',
	MAX_LBLTY_AMT = 'MAX_LBLTY_AMT',
	PMT_AMT = 'PMT_AMT',
	PRO_NBR_AND_PKUP_DT = 'PRO_NBR_AND_PKUP_DT',
	PRO_NBR_TXT = 'PRO_NBR_TXT',
	SHIPPER = 'SHIPPER',
	SHPM_DLVRY_DT = 'SHPM_DLVRY_DT',
	SHP_INST_ID = 'SHP_INST_ID',
	TOTAL_PAYOUT_AMT = 'TOTAL_PAYOUT_AMT'}

