export enum ValidationRegexPatterns {
  tractorPattern = '^[0-9]{3}[-]{1}[0-9]{1,7}$',
  numberTrailerPattern = '^[0-9]{3}[-]{1}[0-9]{1,7}$',
  letterTrailerPattern = '^[a-zA-Z]{1,4}[-]{1}[0-9]{0,6}$',
  numberCheck = '^[0-9]+$',
  doorPattern = '^[0-9]{1,4}$',
  nineDigitPro = '^[0-9]{9}$',
  tenDigitPro = '^[0-9]{3}[-][0-9]{6}$',
  elevenDigitPro = '^[0][0-9]{3}[0][0-9]{6}$',
  emailValidationPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$',
  phoneNumberPattern = '^\\(?([0-9]{0,3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$',
  multiEmailsPattern = '^(\\s*,?\\s*[0-9a-zA-Z]([-.\\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\\w]*[0-9a-zA-Z]\\.)+[a-zA-Z]{2,9})+\\s*$',
  usZipCode = '^(\\d{5}-\\d{4}|\\d{5}|\\d{9})$',
  caPostalCode = '^([a-zA-Z]\\d[a-zA-Z]( )?\\d[a-zA-Z]\\d)$',
  mxPostalCode = '^\\d{5}$',
  madCodeValidationPattern = '^[A-Za-z]{3}[A-Za-z\\s]{2}([A-Za-z]{3}|[0-9]{3})[0-9]{3}$',
  billToMadCodeValidationPattern = '^[A-Za-z]{3}[A-Za-z\\s]{2}(991)[0-9]{3}$',
  sicValidationPattern = '^[A-Za-z]{3}$',
  nmfcValidationPattern = '^[0-9]{4,6}?(-[0-9]{1,2})?$',
  callNumber = '^([0]?[1-9]|[12]\\d|3[01])[-][0-9]{1,4}$',
  routeName = '^[0-9a-zA-Z]{1,5}[-][0-9a-zA-Z]{1,5}$',
  tractorInputValidatorPattern = '^[0-9-]$',
  trailerInputValidatorPattern = '^[0-9a-zA-Z-]$',
  dollyInputValidatorPattern = '^[0-9-]$',
  loadReleaseNumberInputValidatorPattern = '^[0-9]$',
  dateInputValidatorPattern = '^[0-9/]$',
  timeInputValidatorPattern = '^[0-9:]$',
}
