import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: './dynamic-dag-script-dialog',
  templateUrl: './dynamic-dag-script-dialog.component.html',
  styleUrls: ['./dynamic-dag-script-dialog.component.scss'],
})
export class DynamicDAGScriptDialogComponent {
  dagScript: string;
  originalText: string;

  constructor(
    public dialog: MatDialogRef<DynamicDAGScriptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this.originalText = data || '';
    this.dagScript = this.originalText;
  }

  save(value: string): void {
    this.dialog.close({ action: 'save', value: value });
  }

  close(): void {
    this.dialog.close();
  }
}
