import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { XpoUploadListDeleteEvent, XpoUploadListItem } from './upload-list.config';

@Component({
  selector: 'xpo-upload-list',
  templateUrl: './upload-list.component.html',
  styleUrls: ['./upload-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-UploadList' },
})
export class XpoUploadList {
  @Input()
  files: XpoUploadListItem[];

  @Input()
  showFileSize: boolean;

  @Output()
  deleteFile = new EventEmitter<XpoUploadListDeleteEvent>();

  onFileDelete(file: File, fileIndex: number, progress: number): void {
    this.deleteFile.emit({ file, fileIndex, progress });
  }
}
