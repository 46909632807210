/**
 * This whole condition needs a refactor, comparisonMap, valueMap and RelativeTime are too messy and confusing
 */
import { KeyValue } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { getTodayAt } from '../time.util';
import { filterFormats, Operators, OperatorText, XpoFilterConditionCriteria } from './../../../../../models/index';

enum RelativeTime {
  lastHour = 'Last hour',
  lastSix = 'Last 6 hours',
  lastTwelve = 'Last 12 hours',
  nextHour = 'Next hour',
  nextSix = 'Next 6 hours',
  nextTwelve = 'Next 12 hours',
}

@Component({
  templateUrl: './is.component.html',
  styleUrls: ['../../validator.scss'],
})
export class XpoConditionTimeIsComponent extends XpoConditionBase implements XpoFilterCondition, OnInit {
  static text: string = OperatorText.Is;
  static operator: Operators = Operators.Relative;
  isNegative = false;
  model: string = '-1h'; // This will be cleared when refactoring
  readonly RelativeTime = RelativeTime; // For Select

  // Don't think; Only do
  readonly comparisonMap = {
    [RelativeTime.lastHour]: (v): boolean => getTodayAt(v).isBetween(moment().subtract(1, 'hour'), moment()),
    [RelativeTime.lastSix]: (v): boolean => getTodayAt(v).isBetween(moment().subtract(6, 'hour'), moment()),
    [RelativeTime.lastTwelve]: (v): boolean => getTodayAt(v).isBetween(moment().subtract(12, 'hour'), moment()),
    [RelativeTime.nextHour]: (v): boolean => getTodayAt(v).isBetween(moment(), moment().add(1, 'hour')),
    [RelativeTime.nextSix]: (v): boolean => getTodayAt(v).isBetween(moment(), moment().add(6, 'hour')),
    [RelativeTime.nextTwelve]: (v): boolean => getTodayAt(v).isBetween(moment(), moment().add(12, 'hour')),
  };

  readonly valueMap = {
    '-1h': [RelativeTime.lastHour],
    '-6h': [RelativeTime.lastSix],
    '-12h': [RelativeTime.lastTwelve],
    '+1h': [RelativeTime.nextHour],
    '+6h': [RelativeTime.nextSix],
    '+12h': [RelativeTime.nextTwelve],
  };

  @Output() inputChanged = new EventEmitter();

  constructor() {
    super(filterFormats.default);
  }

  ngOnInit(): void {
    this.onInputChange();
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    if (!cellValue) {
      return false;
    }

    return this.comparisonMap[this.valueMap[this.model]](cellValue);
  }

  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.Relative,
      value: this.model,
      display: this.valueMap[this.model],
    };
  }

  onInputChange(): any {
    this.validateFormat();
    this.inputChanged.emit(this.model);
  }
  // preserve order when iterating object: https://github.com/angular/angular/issues/31420
  asIsOrder(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
    return 0;
  }
}
