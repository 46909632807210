import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'xpo-tray-panel',
  templateUrl: './tray-panel.component.html',
  styleUrls: ['./tray-panel.component.scss'],
  host: {
    class: 'xpo-TrayPanel',
  },
})
export class XpoTrayPanelComponent {
  @Output()
  close: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  title: string;

  onClose(): void {
    this.close.emit();
  }
}
