import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { GetOdsShipmentResp } from '@xpo-ltl/sdk-shipmentods';
import { find as _find, reduce as _reduce } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '../../../classes/unsubscriber';
import { DataDisplayGroupType } from '../../data-display-group/data-display-group.component';
import { ShipmentReferenceService } from '../../shipment-reference.service';
import { map as _map, findIndex as _findIndex, forEach as _forEach, get as _get } from 'lodash';
interface DimensionsData {
  Quantity: number;
  Length: number;
  Width: number;
  Height: number;
}
@Component({
  selector: 'xpo-ltl-shipment-details-dimensions',
  templateUrl: './shipment-details-dimensions.component.html',
  styleUrls: ['./shipment-details-dimensions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentDimensionsComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();
  packagesDimensionList: any[];
  dataSource: any[];
  noDimensions: boolean;
  showDimensions = false;
  columns = [
    { id: 'quantity', label: 'Quantity' },
    { id: 'lengthNbr', label: 'Length' },
    { id: 'widthNbr', label: 'Width' },
    { id: 'heightNbr', label: 'Height' },
  ];
  columnsToDisplay = _map(this.columns, (c) => c.id);
  private dimensionsSubject = new BehaviorSubject<DimensionsData>({
    Quantity: undefined,
    Length: undefined,
    Width: undefined,
    Height: undefined,
  });
  readonly dimensions$ = this.dimensionsSubject.asObservable();
  readonly DataDisplayGroupType = DataDisplayGroupType;
  constructor(private shipmentRefService: ShipmentReferenceService) {}
  ngOnInit() {
    this.shipmentRefService.shipment$
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscriber.done$))
      .subscribe((shipment) => {
        this.updateFormFromShipment(shipment);
      });
  }
  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private setPackageQuantity(dimensionsArray) {
    const strArray = [];
    const resultArray = [];
    _forEach(dimensionsArray, function(packageDimension) {
      packageDimension.quantity = 1;
      packageDimension = JSON.stringify(packageDimension);
      const indexPackageInStrArray = _findIndex(strArray, (o) => o === packageDimension);
      if (indexPackageInStrArray === -1) {
        strArray.push(packageDimension);
        resultArray.push(JSON.parse(packageDimension));
      } else {
        resultArray[indexPackageInStrArray].quantity += 1;
      }
    });
    return resultArray;
  }
  private updateFormFromShipment(shipment: GetOdsShipmentResp) {
    const dimensions = _get(shipment, 'linehaulDimensions');
    this.packagesDimensionList = this.setPackageQuantity(dimensions);
    this.dataSource = this.packagesDimensionList;
    if (!shipment) {
      return;
    }
    this.noDimensions = !dimensions || !dimensions.length;
  }
}
