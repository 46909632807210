import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import {
  XpoBoardConsumer,
  XpoBoardDataSourceResolver,
  XpoBoardStateSources,
  XpoGridBoardColumn,
  XpoGridBoardState,
  XpoGridBoardViewTemplate,
} from '../../../../../models/index';
import { XpoGridSettingsColumnOption } from './models/grid-settings-column-option.model';

@Component({
  selector: 'xpo-settings-button-grid-options',
  templateUrl: './grid-settings-default-buttons.component.html',
  styleUrls: ['./grid-settings-default-buttons.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-GridToolbar-settings-button' },
})
export class XpoSettingsGridOptionsComponent extends XpoBoardConsumer {
  /** Column Options that will be mutated from the grid column selection component via 2 way binding */
  columnOptions: XpoGridSettingsColumnOption[] = [];
  /** All columns from the current template */
  availableColumns: XpoGridBoardColumn[] = [];

  /** List of columns with no header names */
  private columnsWithNoHeaderName: XpoGridBoardColumn[] = [];
  private cachedState: XpoGridBoardState;

  constructor(boardDataSourceResolver: XpoBoardDataSourceResolver, cd: ChangeDetectorRef) {
    super(boardDataSourceResolver, cd);
  }

  handlePopoverClose(closeData: any): void {
    // If user doesn't hit apply, reset to previous state
    if (!closeData) {
      this.handleStateUpdate(this.cachedState);
    }
  }

  applySettingsUpdates(): void {
    const selectedColumnsToSubmit: XpoGridSettingsColumnOption[] = this.availableColumns.filter((column) => {
      const sel = this.columnOptions.find((col) => col.headerName === column.headerName);

      if (sel && sel.isVisible) {
        return column;
      }
    });

    const newState: XpoGridBoardState = {
      visibleColumns: [...this.columnsWithNoHeaderName, ...selectedColumnsToSubmit],
      source: XpoBoardStateSources.GridSettingsUpdate,
    };

    this.stateChange$.next(newState);
  }

  protected onStateChange(state: XpoGridBoardState): void {
    if (this.shouldUpdateState(state)) {
      this.cachedState = state;

      this.handleStateUpdate(state);
    }
  }

  private handleStateUpdate(state: XpoGridBoardState): void {
    this.updateColumnSelectionWithState(state);
  }

  private updateColumnSelectionWithState(state: XpoGridBoardState): void {
    const template = state.template as XpoGridBoardViewTemplate;
    const visibleColumns = state.visibleColumns || [];

    this.availableColumns = (template && template.availableColumns) || [];
    // Storing the column headers with no header names so they can be appended to the selected columns
    this.columnsWithNoHeaderName = this.availableColumns.filter((c) => !c.headerName);
    // mapping columns to view model columns
    this.columnOptions = this.availableColumns
      .filter((c) => !!c.headerName)
      .map((column) => {
        return {
          headerName: column.headerName,
          isVisible: !visibleColumns.length || visibleColumns.some((c) => c.headerName === column.headerName),
          lockVisible: column.lockVisible,
        };
      });
  }

  private shouldUpdateState(state: XpoGridBoardState): boolean {
    const changeKeys: Array<keyof XpoGridBoardState> = ['visibleColumns', 'template'];

    return changeKeys.some((v) => state.changes.includes(v));
  }
}
