import {ReportsCd} from '../';
import {EnumHelper} from './enum-helper';

export class ReportsCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ReportsCd ) {
      this._values.push(ReportsCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ReportsCd {

    for ( const obj in ReportsCd ) {
      if (ReportsCd[obj] === value){
        return ReportsCd[obj] as ReportsCd;
      }
    }
  }
}

const ReportsCdHelper = new ReportsCdHelperEnumHelperClass();
export default ReportsCdHelper;
