import { Pipe, PipeTransform } from '@angular/core';

export interface XpoFileSizePipeParams {
  precision: number;
  includeBytes: boolean;
}

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 0.
 * Takes in an includeBytes argument that if false and the
 * file size is < 1024 bytes, it rounds up to 1kb, defaults to true
 *
 * Usage:
 *   bytes | fileSize: { precision,  includeBytes }
 * Example:
 *   {{ 2048 |  fileSize }}
 *   formats to: 2 KB
 */
@Pipe({ name: 'xpoFileSize' })
export class XpoFileSizePipe implements PipeTransform {
  private units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  transform(bytes: number = 0, params: XpoFileSizePipeParams = { precision: 0, includeBytes: true }): string {
    if (isNaN(parseFloat(`${bytes}`)) || !isFinite(bytes)) {
      return '?';
    }

    let unit = 0;

    if (!params.includeBytes && bytes < 1024) {
      return '1 KB';
    }

    while (bytes >= 1024) {
      bytes /= 1024;
      unit++;
    }

    return bytes.toFixed(+params.precision) + ' ' + this.units[unit];
  }
}
