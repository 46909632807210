import { Component, EventEmitter, Output } from '@angular/core';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import {
  CustomFilterParams,
  filterFormats,
  Operators,
  OperatorText,
  XpoFilterConditionCriteria,
} from './../../../../../models/index';
import { IsBetweenModel } from './../../model.interface';

@Component({
  templateUrl: './is-not-between.component.html',
  styleUrls: ['./../../validator.scss'],
})
export class XpoConditionStringIsNotBetweenComponent extends XpoConditionBase implements XpoFilterCondition {
  static text = OperatorText.NotBetween;
  static operator: Operators = Operators.NotBetween;
  isNegative = true;
  model: IsBetweenModel = {
    from: '',
    to: '',
  };
  private isCaseSensitive = false;

  set customFilterParams(params: CustomFilterParams) {
    this.isCaseSensitive = params.isCaseSensitive || false;
  }

  @Output() inputChanged = new EventEmitter();

  constructor() {
    super(filterFormats.stringRange);
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model.from || !this.model.to) {
      return true;
    }

    if (!cellValue) {
      return false;
    }

    /**
     * If items are p0, p1, p2, p3, p4, p5, p6
     * range not between p1 and p4 = p0, p5, p6
     */
    let isGreater: number;
    let isLessThan: number;

    if (this.isCaseSensitive) {
      isGreater = cellValue.localeCompare(this.model.from);
      isLessThan = cellValue.localeCompare(this.model.to);
    } else {
      isGreater = cellValue
        .toString()
        .toLowerCase()
        .localeCompare(this.model.from.toLowerCase());
      isLessThan = cellValue
        .toString()
        .toLowerCase()
        .localeCompare(this.model.to.toLowerCase());
    }
    if (isGreater === 0 || isLessThan === 0) {
      return false;
    } else if (isGreater === 1 && isLessThan === -1) {
      return false;
    }
    return true;
  }

  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.NotBetween,
      value: this.model.from,
      valueTo: this.model.to,
    };
  }

  onInputChange(): any {
    this.validateFormat();
    this.inputChanged.emit(this.model);
  }
}
