import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

/* XPO */
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoConfirmDialog, XpoConfirmDialogConfig } from '@xpo-ltl/ngx-ltl-core';
import {
  DataExtract,
  Dataset,
  GetModuleGroupResp,
  ListDataExtractsQuery,
  ListDataExtractsResp,
  ListDatasetPeriodCdsResp,
  ModuleExecutorApiService,
  ModuleGroup,
  ModuleGroupModuleVersion,
  UnscheduleModuleGroupExecutionPath,
  UpsertModuleGroupResp,
  UpsertModuleGroupRqst,
} from '@xpo-ltl/sdk-moduleexecutor';

/* Rxjs */
import { Observable, of, Subscription, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/* Services */
import { ModuleGroupManagementService } from './services/module-group-management.service';

/* Models */
import { AssociatedModuleVersion } from './components/associated-modules/models/module-group-mgmt-associated-modules-grid.model';
import { AddModuleEvent } from './models/addModuleEvent.model';
import { StepGridItem } from './models/step-grid-item.model';

/* Components */
import { ModuleGroupMgmtInfoComponent } from './components/info/module-group-mgmt-info.component';
import { DynamicDAGScriptDialogComponent, DynamicDAGScriptDialogErrorComponent } from './dialogs';

/* Enums */
import { ModuleExecutorDataExtractTypeCd, ModuleExecutorParmsArgTypeCd } from '@xpo-ltl/sdk-common';
import { ConfigManagerProperties } from '../shared/enums/config-manager-properties.enum';

@Component({
  selector: 'ltl-xpo-module-group-management',
  templateUrl: './module-group-management.component.html',
  styleUrls: ['./module-group-management.component.scss'],
})
export class ModuleGroupManagementComponent implements OnInit, OnDestroy {
  @ViewChild('moduleGroupMgmtInfoForm')
  moduleGroupMgmtInfoFormComponent: ModuleGroupMgmtInfoComponent;
  @ViewChild('steps') stepsComponent;

  dynamicDAGDisabled: boolean;

  datasetLists: {
    periodCds: string[];
    dataExtracts: DataExtract[][];
    datasets: Dataset[][];
  };

  selectedPeriodCd: string;

  /* Form */
  moduleGroupMgmtForm: FormGroup;

  moduleGroupInstId: number;
  moduleGroup: ModuleGroup;

  defaultExecOrderLoopInd: boolean;

  partialUpdated: boolean;
  needToUnschedule: boolean;

  /* Subscriptions */
  subscriptions: Subscription;

  openDialogConfirmCancelSubscription: Subscription;

  get hasSomeStepEmptyRows(): boolean {
    return this.moduleGroupManagementService.hasSomeStepEmptyRows();
  }

  get dagScript(): string {
    return this.moduleGroupManagementService.getDagScript();
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private moduleGroupManagementService: ModuleGroupManagementService,
    private moduleExecutorApiService: ModuleExecutorApiService,
    private dialog: MatDialog,
    private confirmDialog: XpoConfirmDialog,
    private configManagerService: ConfigManagerService
  ) {
    this.dynamicDAGDisabled = this.configManagerService.getSetting(ConfigManagerProperties.dynamicDAGDisabled) || false;
    this.moduleGroupManagementService.setDagDisable(this.dynamicDAGDisabled);
    this.moduleGroupInstId = null;
    this.moduleGroup = null;

    this.defaultExecOrderLoopInd = true;

    this.moduleGroupMgmtForm = this.fb.group({
      information: [],
    });

    this.moduleGroupManagementService.resetSteps();

    this.partialUpdated = false;
    this.needToUnschedule = false;

    this.subscriptions = new Subscription();

    this.openDialogConfirmCancelSubscription = null;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.moduleGroupInstId = params['moduleGroupInstId'] || null;
      this.initialize();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * @name initialize
   * @description Initialize component
   */
  initialize(): void {
    this.moduleGroupManagementService.setSelectedPeriodCd(null);
    this.datasetLists = this.moduleGroupManagementService.datasetLists;
    this.listDatasetPeriodCds();
    if (this.moduleGroupInstId) {
      const getModuleGroupSubscription = this.moduleGroupManagementService
        .getModuleGroup(this.moduleGroupInstId)
        .subscribe(
          (getModuleGroupResp: GetModuleGroupResp) => {
            const mg = this.orderModuleGroupByArgumentSeqNbr(getModuleGroupResp.moduleGroup);
            const steps = this.moduleGroupManagementService.getStepsByModuleGroup(mg);

            this.moduleGroupManagementService.setDagScript(mg.dynamicDag);
            this.moduleGroupManagementService.setSteps(steps);
            this.moduleGroupManagementService.setStepsCopy(steps);

            this.moduleGroup = mg;
            const periodCd = this.moduleGroupManagementService.getSelectedPeriodCd();
            if (periodCd) {
              this.selectedPeriodCd = periodCd;
            } else {
              this.selectedPeriodCd = this.getPeriodCdFromNodeExecParm();
              this.moduleGroupManagementService.setSelectedPeriodCd(this.selectedPeriodCd);
            }
          },
          (err) => {
            throwError(err);
          }
        );
      this.subscriptions.add(getModuleGroupSubscription);
    }
  }

  orderModuleGroupByArgumentSeqNbr(moduleGroup: ModuleGroup): ModuleGroup {
    const moduleVersions = moduleGroup.moduleGroupModuleVersion;
    if (moduleVersions) {
      moduleVersions.forEach((mv) => {
        mv.moduleGroupExecParm = mv.moduleGroupExecParm
          ? mv.moduleGroupExecParm.sort((a, b) => (a.argumentSequenceNbr > b.argumentSequenceNbr ? 1 : -1))
          : [];
      });
    }
    return {
      ...moduleGroup,
      moduleGroupModuleVersion: moduleVersions,
    };
  }

  /**
   * @name listDatasetPeriodCds
   * @description Retrieve periodCds from Backend
   */
  private listDatasetPeriodCds(): void {
    this.moduleExecutorApiService.listDatasetPeriodCds().subscribe(
      (res: ListDatasetPeriodCdsResp) => {
        this.datasetLists.periodCds = res.periodCd;
        this.moduleGroupManagementService.datasetLists.periodCds = res.periodCd;
      },
      (err) => {
        this.moduleGroupManagementService.datasetLists.periodCds = [];
        this.datasetLists.periodCds = [];
      }
    );
  }

  /**
   * @name onPeriodCodeChanges
   * @description Listener which is triggered on periodCd control change. Set the new value into a global service
   */
  onPeriodCodeChanges(e, value): void {
    if (value && this.moduleGroupManagementService.getNumberOfDatasets() > 1) {
      const openDialogConfirmPeriodCdChangeSubscription = this.openDialogConfirmPeriodCdChange().subscribe((resp) => {
        if (resp) {
          this.selectedPeriodCd = value;
          this.moduleGroupManagementService.setSelectedPeriodCd(this.selectedPeriodCd);
        } else {
          e.source.writeValue(this.selectedPeriodCd);
        }
      });
      this.subscriptions.add(openDialogConfirmPeriodCdChangeSubscription);
    } else {
      this.selectedPeriodCd = value;
      this.moduleGroupManagementService.setSelectedPeriodCd(this.selectedPeriodCd);
    }
  }

  /**
   * @name getPeriodCdFromNodeExecParm
   * @description Retrieves a selected periodCd from a given ModuleGroup
   */
  private getPeriodCdFromNodeExecParm(): string {
    let periodCodeExecParmLst = [];
    if (!this.moduleGroup.moduleGroupModuleVersion) {
      return;
    }
    this.moduleGroup.moduleGroupModuleVersion.forEach((mv) => {
      const tmpLst = mv.moduleGroupExecParm
        ? mv.moduleGroupExecParm.filter((obj) => obj.argumentTypeCd === ModuleExecutorParmsArgTypeCd.PERIOD_CODE)
        : [];
      periodCodeExecParmLst = periodCodeExecParmLst.concat(tmpLst);
    });
    const periodCodeExecParm = periodCodeExecParmLst[0];
    return periodCodeExecParm && periodCodeExecParm.argument;
  }

  openDialogConfirmPeriodCdChange(): Observable<boolean> {
    const confirmDialogConfig: XpoConfirmDialogConfig = {
      confirmButtonText: 'YES',
      confirmButtonColor: 'primary',
      rejectButtonText: 'NO',
      rejectButtonColor: 'accent',
      icon: 'warning',
      showCancelButton: false,
    };
    return this.confirmDialog.confirm(
      'Are you sure you want to continue?',
      'Period Code for all steps will change.',
      confirmDialogConfig
    );
  }

  /**
   * @name upsertModuleGroup
   * @description Create or Update Module Group
   */
  upsertModuleGroup(): void {
    this.partialUpdated = false;

    const infoFormData = this.moduleGroupMgmtForm.get('information').value;
    const steps = this.moduleGroupManagementService.getSteps();
    const upsertModuleGroupRqst: UpsertModuleGroupRqst = {
      moduleGroup: {
        moduleGroupInstId: this.moduleGroupInstId,
        moduleGroupName: infoFormData.moduleName,
        moduleGroupDescription: infoFormData.moduleDescription,
        correlationId: null,
        schedule: infoFormData.scheduleCronExpression,
        dynamicDagInd: this.dagScript ? true : false,
        dynamicDag: this.dagScript,
        auditInfo: null,
        runStatus: null,
        executionTmst: null,
        moduleGroupModuleVersion: this.moduleGroupInstId
          ? this.moduleGroupManagementService.getModuleGroupModuleVersionCopyComparison(this.moduleGroupInstId)
          : this.moduleGroupManagementService.getModuleVersionsBySteps(this.moduleGroupInstId, steps),
      },
    };

    upsertModuleGroupRqst.moduleGroup = {
      ...upsertModuleGroupRqst.moduleGroup,
      moduleGroupModuleVersion: this.moduleGroupManagementService.setPeriodCodeOnModuleGroup(
        upsertModuleGroupRqst.moduleGroup.moduleGroupModuleVersion
      ),
    };

    this.moduleExecutorApiService.upsertModuleGroup(upsertModuleGroupRqst).subscribe(
      (upsertModuleGroupResp: UpsertModuleGroupResp) => {
        this.goBack();
      },
      (err) => {
        this.partialUpdated = true;
        if (err && err.error && err.error.moreInfo) {
          const exceptionOrigin = err.error.moreInfo.find((info) => info.location === 'exceptionOrigin');
          const exceptionOriginMessage = exceptionOrigin && exceptionOrigin.message;

          const unschedule = err.error.moreInfo.find((info) => info.location === 'unschedule');
          if (unschedule) {
            this.needToUnschedule =
              unschedule.length > 0 ? unschedule[0].message === 'true' : unschedule.message === 'true';
          }

          if (exceptionOriginMessage === 'DYNAMIC_VALIDATION') {
            const validateDynamicDagCodeArray = err.error.moreInfo.filter(
              (info) => info.location === 'validateDynamicDagCode'
            );
            if (validateDynamicDagCodeArray.length > 0) {
              this.openDAGUpsertErrorDialog(validateDynamicDagCodeArray);
            }
          }
        }
      }
    );
  }

  /**
   * @name addSelectedModulesToStep
   * @description Add all selected Modules from the associated list to a particular step.
   * @param newItem: AddModuleEvent
   */
  addSelectedModulesToStep(newItem: AddModuleEvent): void {
    const moduleGroupModuleVersions: ModuleGroupModuleVersion[] = newItem.rows.map((row: AssociatedModuleVersion) => {
      return {
        moduleVersionSequenceNbr: row.moduleVersionSequenceNbr,
        moduleGroupInstId: this.moduleGroupInstId,
        moduleInstId: row.moduleInstId,
        moduleGroupModuleVersionSequenceNbr: null,
        executionOrderNbr: newItem.stepId.toString(),
        execOrderSequenceNbr: null,
        jobConfiguration: null,
        correlationId: row.correlationId,
        execOrderLoopInd: this.dagScript ? this.defaultExecOrderLoopInd : false,
        listActionCd: row.listActionCd,
        auditInfo: row.auditInfo,
        parentModuleName: row.moduleName,
        parentVersion: row.version,
        moduleGroupExecParm: [],
      };
    });
    this.stepsComponent.addRowsByStepId(newItem.stepId, moduleGroupModuleVersions);
  }

  /**
   * @name addSelectedModulesToNewStep
   * @description Add all selected Modules from the associated list to a new step.
   */
  addSelectedModulesToNewStep(selectedRows: AssociatedModuleVersion[]): void {
    this.addStep(selectedRows);
  }

  /**
   * @name addStep
   * @description Add New step with/without rows(AssociatedModuleVersion) in.
   * @param rows?: AssociatedModuleVersion[]
   */
  addStep(rows?: AssociatedModuleVersion[]): void {
    const nextStepId = this.moduleGroupManagementService.getNextStepId();
    const moduleGroupModuleVerions: ModuleGroupModuleVersion[] =
      (rows &&
        rows.map((row: AssociatedModuleVersion) => {
          return {
            moduleVersionSequenceNbr: row.moduleVersionSequenceNbr,
            moduleGroupInstId: this.moduleGroupInstId,
            moduleInstId: row.moduleInstId,
            moduleGroupModuleVersionSequenceNbr: null,
            executionOrderNbr: nextStepId.toString(),
            execOrderSequenceNbr: null,
            jobConfiguration: null,
            correlationId: row.correlationId,
            execOrderLoopInd: this.dagScript ? this.defaultExecOrderLoopInd : false,
            listActionCd: row.listActionCd,
            auditInfo: row.auditInfo,
            parentModuleName: row.moduleName,
            parentVersion: row.version,
            moduleGroupExecParm: [],
          };
        })) ||
      [];
    const newStep = new StepGridItem(
      nextStepId, // id
      'Step ' + nextStepId, // name
      moduleGroupModuleVerions || [], // rows
      true, // removable
      this.dagScript ? this.defaultExecOrderLoopInd : false // execOrderLoopInd
    );
    this.moduleGroupManagementService.addStep(newStep);
  }

  /**
   * @name openDAGScriptDialog
   * @description Open DAG Script Dialog to edit or create a new python script
   */
  openDAGScriptDialog(): void {
    const dialogConfig: MatDialogConfig = {
      data: this.dagScript || '',
    };
    const DAGScriptDialogSubscription = this.dialog
      .open(DynamicDAGScriptDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((resp) => {
        if (resp && resp.action === 'save') {
          this.moduleGroupManagementService.updateDagScript(resp.value);
        }
      });
    this.subscriptions.add(DAGScriptDialogSubscription);
  }

  /**
   * @name openDAGUpsertErrorDialog
   * @description When exception error code 'XMEN021-028E' in the upsert service occurs
   * @param validateDynamicDagCodeList: any[] = []
   */
  openDAGUpsertErrorDialog(validateDynamicDagCodeList: any[] = []): void {
    const dialogConfig: MatDialogConfig = {
      data: validateDynamicDagCodeList,
    };
    const DAGUpsertErrorDialogSubscription = this.dialog
      .open(DynamicDAGScriptDialogErrorComponent, dialogConfig)
      .afterClosed()
      .subscribe();
    this.subscriptions.add(DAGUpsertErrorDialogSubscription);
  }

  openDialogConfirmCancel(): Observable<boolean> {
    const confirmDialogConfig: XpoConfirmDialogConfig = {
      confirmButtonText: 'YES',
      confirmButtonColor: 'primary',
      rejectButtonText: 'NO',
      rejectButtonColor: 'accent',
      icon: 'warning',
      showCancelButton: false,
    };
    return this.confirmDialog.confirm(
      'Are you sure you want to leave without rescheduling the Module Group?',
      'Module Group has been unscheduled',
      confirmDialogConfig
    );
  }

  /**
   * @name onModuleGroupMgmtInfoFormChange
   * @description Every time child component form control changes, we need to update our parent and father form.
   * @param form: FormGroup
   */
  onModuleGroupMgmtInfoFormChange(form: FormGroup): void {
    this.moduleGroupMgmtForm.setControl('information', form);
  }

  /**
   * @name goBack
   * @description Go back to module-groups-workbench list
   */
  goBack(): void {
    this.moduleGroupManagementService.setSelectedPeriodCd(null);
    const routePath =
      this.configManagerService.getSetting(ConfigManagerProperties.moduleGroupsWorkbenchRoute) ||
      './module-groups-workbench';
    this.router.navigate([routePath]);
  }

  /**
   * @name onCancel
   * @description Every time cancel action is executed, go back.
   */
  onCancel(): void {
    if (this.partialUpdated) {
      // Open modal dialog
      const openDialogConfirmCancelSubscription = this.openDialogConfirmCancel().subscribe((resp) => {
        if (resp) {
          if (this.needToUnschedule) {
            const unscheduleModuleGroupExecutionSubscriptions = this.unscheduleModuleGroupExecution().subscribe(
              (res) => {
                this.goBack();
              }
            );
            this.subscriptions.add(unscheduleModuleGroupExecutionSubscriptions);
          } else {
            this.goBack();
          }
        }
      });
      this.subscriptions.add(openDialogConfirmCancelSubscription);
    } else {
      this.goBack();
    }
  }

  /**
   * @name unscheduleModuleGroupExecution
   * @description Unschedule Module Group Execution
   * @returns Observable<void>
   */
  unscheduleModuleGroupExecution(): Observable<boolean> {
    const pathParams: UnscheduleModuleGroupExecutionPath = {
      moduleGroupInstId: this.moduleGroupInstId,
    };
    return this.moduleExecutorApiService.unscheduleModuleGroupExecution(pathParams).pipe(
      switchMap(
        (res) => of(true),
        (err) => false
      )
    );
  }
}
