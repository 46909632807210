import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { XpoIconModule } from '../icon/icon.module';
import { XpoSsi } from './special-service-indicator.component';


@NgModule({
  declarations: [XpoSsi],
  imports: [
    CommonModule,
    MatIconModule,
    XpoIconModule,
  ],
  exports: [XpoSsi]
})
export class XpoSsiModule { }
