import {ShipmentEventsCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentEventsCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentEventsCd ) {
      this._values.push(ShipmentEventsCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentEventsCd {

    for ( const obj in ShipmentEventsCd ) {
      if (ShipmentEventsCd[obj] === value){
        return ShipmentEventsCd[obj] as ShipmentEventsCd;
      }
    }
  }
}

const ShipmentEventsCdHelper = new ShipmentEventsCdHelperEnumHelperClass();
export default ShipmentEventsCdHelper;
