import {CustomerNoteTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerNoteTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerNoteTypeCd ) {
      this._values.push(CustomerNoteTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerNoteTypeCd {

    for ( const obj in CustomerNoteTypeCd ) {
      if (CustomerNoteTypeCd[obj] === value){
        return CustomerNoteTypeCd[obj] as CustomerNoteTypeCd;
      }
    }
  }
}

const CustomerNoteTypeCdHelper = new CustomerNoteTypeCdHelperEnumHelperClass();
export default CustomerNoteTypeCdHelper;
