import {ParentActivityTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ParentActivityTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ParentActivityTypeCd ) {
      this._values.push(ParentActivityTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ParentActivityTypeCd {

    for ( const obj in ParentActivityTypeCd ) {
      if (ParentActivityTypeCd[obj] === value){
        return ParentActivityTypeCd[obj] as ParentActivityTypeCd;
      }
    }
  }
}

const ParentActivityTypeCdHelper = new ParentActivityTypeCdHelperEnumHelperClass();
export default ParentActivityTypeCdHelper;
