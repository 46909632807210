import {ReturnReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class ReturnReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ReturnReasonCd ) {
      this._values.push(ReturnReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ReturnReasonCd {

    for ( const obj in ReturnReasonCd ) {
      if (ReturnReasonCd[obj] === value){
        return ReturnReasonCd[obj] as ReturnReasonCd;
      }
    }
  }
}

const ReturnReasonCdHelper = new ReturnReasonCdHelperEnumHelperClass();
export default ReturnReasonCdHelper;
