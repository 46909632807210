import {JSPWorkerTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class JSPWorkerTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in JSPWorkerTypeCd ) {
      this._values.push(JSPWorkerTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): JSPWorkerTypeCd {

    for ( const obj in JSPWorkerTypeCd ) {
      if (JSPWorkerTypeCd[obj] === value){
        return JSPWorkerTypeCd[obj] as JSPWorkerTypeCd;
      }
    }
  }
}

const JSPWorkerTypeCdHelper = new JSPWorkerTypeCdHelperEnumHelperClass();
export default JSPWorkerTypeCdHelper;
