import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoLtlReleaseNotesComponent } from './release-notes.component';

@NgModule({
  declarations: [XpoLtlReleaseNotesComponent],
  imports: [CommonModule, XpoDialogModule, ConfigManagerModule],
  exports: [XpoLtlReleaseNotesComponent],
  entryComponents: [XpoLtlReleaseNotesComponent],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
  ],
})
export class XpoLtlReleaseNotesModule {}
