import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { XpoHeaderModule } from '../header/header.module';
import { XpoIconModule } from '../icon/icon.module';
import { XpoSlideToggleModule } from '../toggle/toggle.module';
import { XpoApplicationSwitcherComponent } from './application-switcher.component';
import { XpoApplicationCategoryComponent } from './components/content/application-category/application-category.component';
import { XpoApplicationComponent } from './components/content/application-category/application/application.component';
import { XpoApplicationSwitcherContentComponent } from './components/content/application-switcher-content.component';
import { ApplicationSwitcherHeaderComponent } from './components/header/application-switcher-header.component';
import { ApplicationSwitcherToggleComponent } from './components/toggle/application-switcher-toggle.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OverlayModule,
    PortalModule,
    MatIconModule,
    MatTabsModule,
    MatSlideToggleModule,
    XpoIconModule,
    XpoHeaderModule,
    XpoSlideToggleModule
  ],
  declarations: [
    XpoApplicationSwitcherComponent,
    XpoApplicationSwitcherContentComponent,
    XpoApplicationCategoryComponent,
    XpoApplicationComponent,
    ApplicationSwitcherHeaderComponent,
    ApplicationSwitcherToggleComponent,
  ],
  exports: [ XpoApplicationSwitcherComponent ],

})
export class XpoApplicationSwitcherModule {}
