import { Injectable } from '@angular/core';

/**
 * Injectable access to browser Window object
 */
@Injectable({
  providedIn: 'root',
})
export class XpoLtlWindowService {
  constructor() {}

  get nativeWindow(): Window {
    return window;
  }

  get nativeDocument(): Document {
    return document;
  }

  // Utilities to make accessing native methods easier

  openWindow(url: string, target?: string): any {
    if (url) {
      return this.nativeWindow.open(url, target || '_blank');
    }
  }

  generateDownloadFile(mimeType: string, data: string | Uint8Array, fileName: string) {
    const blob = new Blob([data], { type: mimeType });

    if (this.nativeWindow.navigator && this.nativeWindow.navigator.msSaveOrOpenBlob) {
      this.nativeWindow.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const a = this.nativeDocument.createElement('a');
      this.nativeDocument.body.appendChild(a);
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      a.click();
      a.remove();
    }
  }
}
