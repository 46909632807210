import { Directive, Input } from '@angular/core';

@Directive({
  selector: 'xpo-ltl-popover-status-header',
  host: {
    class: 'xpo-ltl-Popover-status-header',
    '[style.background-color]': 'backgroundColor',
    '[style.color]': 'fontColor',
  },
})
export class XpoLtlPopoverStatusHeader {
  @Input()
  backgroundColor: string;

  @Input()
  fontColor: string;
}
