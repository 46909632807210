import {DensitySourceCd} from '../';
import {EnumHelper} from './enum-helper';

export class DensitySourceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DensitySourceCd ) {
      this._values.push(DensitySourceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DensitySourceCd {

    for ( const obj in DensitySourceCd ) {
      if (DensitySourceCd[obj] === value){
        return DensitySourceCd[obj] as DensitySourceCd;
      }
    }
  }
}

const DensitySourceCdHelper = new DensitySourceCdHelperEnumHelperClass();
export default DensitySourceCdHelper;
