import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AsWordsPipe } from './as-words/as-words.pipe';
import { BillClassPipe } from './bill-class/bill-class.pipe';
import { CamelCaseToHyphenPipe } from './camel-case-to-hyphen/camel-case-to-hyphen.pipe';
import { CommodityClassCdPipe } from './commodity-class-cd/commodity-class-cd.pipe';
import { CurrencyCodePipe } from './currency-code/currency-code.pipe';
import { EmptyCurrencyPipe } from './empty-currency/empty-currency.pipe';
import { EmptyValuePipe } from './empty-value/empty-value.pipe';
import { EnumToArrayPipe } from './enum-to-array/enum-to-array.pipe';
import { MillisToDaysPipe } from './millis-to-days/millis-to-days.pipe';
import { NegativeCurrencyParensPipe } from './negative-currency-parens/negative-currency-parens.pipe';
import { PastPresentFuturePipe } from './past-present-future/past-present-future.pipe';
import { PhoneNumberPipe } from './phone-number/phone-number.pipe';
import { PluralPipe } from './plural/plural.pipe';
import { ProFormatterPipe } from './pro-formatter/pro-formatter.pipe';
import { RoundPipe } from './round/round.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { SentenceCasePipe } from './sentence-case/sentence-case.pipe';
import { SicDatePipe } from './sic-date/sic-date.pipe';
import { ToRegexPipe } from './to-regex/to-regex.pipe';
import { MovementExceptionTypePipe } from './movement-exception-type/movement-exception-type.pipe';
import { MovementStatusPipe } from './movement-status/movement-status.pipe';
import { ShipmentPnDStatusPipe } from './shipment-pnd-status/shipment-pnd-status.pipe';
import { ShipmentSpecialServiceCdPipe } from './shipment-special-service-cd/shipment-special-service-cd.pipe';
import { DeliveryQualifierCdPipe } from './delivery-qualifier-cd/delivery-qualifier-cd.pipe';
import { ChargeToCdPipe } from './charge-to-cd/charge-to-cd.pipe';
import { MomentDatePipe } from './moment-date/moment-date.pipe';
import { ExemptReasonCdPipe } from './exempt-reason-cd/exempt-reason-cd.pipe';
import { EquipmentPipe } from './equipment-pipe/equipment.pipe';
import { EmployeeNamePipe } from './employee-name/employee-name.pipe';
import { InvoiceTypeCdPipe } from './invoice-type-cd/invoice-type-cd.pipe';
import { ClaimInternalStatusCdPipe } from './claim-internal-status-cd/claim-internal-status-cd.pipe';
import {
  AttemptReasonCdPipe,
  DeliveryQualifierReasonCdPipe,
  RefusedReasonCdPipe,
  ReturnReasonCdPipe,
  UndeliveredReasonCdPipe,
} from './delivery-qualifier-reason-cd.pipe.ts/delivery-qualifier-reason-cd.pipe';

const pipes = [
  AsWordsPipe,
  BillClassPipe,
  CamelCaseToHyphenPipe,
  ChargeToCdPipe,
  CommodityClassCdPipe,
  CurrencyCodePipe,
  DeliveryQualifierCdPipe,
  EmptyCurrencyPipe,
  EmptyValuePipe,
  EnumToArrayPipe,
  MillisToDaysPipe,
  MovementExceptionTypePipe,
  MovementStatusPipe,
  NegativeCurrencyParensPipe,
  PastPresentFuturePipe,
  PhoneNumberPipe,
  PluralPipe,
  ProFormatterPipe,
  RoundPipe,
  SafeHtmlPipe,
  SentenceCasePipe,
  ShipmentPnDStatusPipe,
  ShipmentSpecialServiceCdPipe,
  SicDatePipe,
  MomentDatePipe,
  ExemptReasonCdPipe,
  ClaimInternalStatusCdPipe,
  EquipmentPipe,
  EmployeeNamePipe,
  InvoiceTypeCdPipe,
  ToRegexPipe,
  DeliveryQualifierReasonCdPipe,
  AttemptReasonCdPipe,
  ReturnReasonCdPipe,
  RefusedReasonCdPipe,
  UndeliveredReasonCdPipe,
];

@NgModule({
  declarations: pipes,
  imports: [CommonModule],
  exports: pipes,
  providers: pipes,
})
export class XpoLtlPipeModule {}
