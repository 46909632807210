import {RatingWhatIfRequestStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class RatingWhatIfRequestStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RatingWhatIfRequestStatusCd ) {
      this._values.push(RatingWhatIfRequestStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RatingWhatIfRequestStatusCd {

    for ( const obj in RatingWhatIfRequestStatusCd ) {
      if (RatingWhatIfRequestStatusCd[obj] === value){
        return RatingWhatIfRequestStatusCd[obj] as RatingWhatIfRequestStatusCd;
      }
    }
  }
}

const RatingWhatIfRequestStatusCdHelper = new RatingWhatIfRequestStatusCdHelperEnumHelperClass();
export default RatingWhatIfRequestStatusCdHelper;
