import {PayformDetailCd} from '../';
import {EnumHelper} from './enum-helper';

export class PayformDetailCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PayformDetailCd ) {
      this._values.push(PayformDetailCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PayformDetailCd {

    for ( const obj in PayformDetailCd ) {
      if (PayformDetailCd[obj] === value){
        return PayformDetailCd[obj] as PayformDetailCd;
      }
    }
  }
}

const PayformDetailCdHelper = new PayformDetailCdHelperEnumHelperClass();
export default PayformDetailCdHelper;
