import {DynamicPriceQualifierTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DynamicPriceQualifierTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DynamicPriceQualifierTypeCd ) {
      this._values.push(DynamicPriceQualifierTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DynamicPriceQualifierTypeCd {

    for ( const obj in DynamicPriceQualifierTypeCd ) {
      if (DynamicPriceQualifierTypeCd[obj] === value){
        return DynamicPriceQualifierTypeCd[obj] as DynamicPriceQualifierTypeCd;
      }
    }
  }
}

const DynamicPriceQualifierTypeCdHelper = new DynamicPriceQualifierTypeCdHelperEnumHelperClass();
export default DynamicPriceQualifierTypeCdHelper;
