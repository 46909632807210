import {ShipmentHistoryDetailCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentHistoryDetailCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentHistoryDetailCd ) {
      this._values.push(ShipmentHistoryDetailCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentHistoryDetailCd {

    for ( const obj in ShipmentHistoryDetailCd ) {
      if (ShipmentHistoryDetailCd[obj] === value){
        return ShipmentHistoryDetailCd[obj] as ShipmentHistoryDetailCd;
      }
    }
  }
}

const ShipmentHistoryDetailCdHelper = new ShipmentHistoryDetailCdHelperEnumHelperClass();
export default ShipmentHistoryDetailCdHelper;
