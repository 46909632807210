import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { XpoUploadListItem } from '../upload-list.config';

@Component({
  selector: 'xpo-upload-item',
  templateUrl: './upload-item.component.html',
  styleUrls: ['./upload-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-UploadItem' },
})
export class XpoUploadItem {
  @Input()
  file: XpoUploadListItem;

  @Input()
  showFileSize: boolean;

  @Output()
  deleteFile = new EventEmitter<File>();
}
