import {DisputeProValidationStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeProValidationStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeProValidationStatusCd ) {
      this._values.push(DisputeProValidationStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeProValidationStatusCd {

    for ( const obj in DisputeProValidationStatusCd ) {
      if (DisputeProValidationStatusCd[obj] === value){
        return DisputeProValidationStatusCd[obj] as DisputeProValidationStatusCd;
      }
    }
  }
}

const DisputeProValidationStatusCdHelper = new DisputeProValidationStatusCdHelperEnumHelperClass();
export default DisputeProValidationStatusCdHelper;
