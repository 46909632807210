import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';

import { XpoDataDrawerDataSource } from './models/data-drawer-data-source.model';

/**
 * Default data drawer data source, it just echos back the selected record as an observable.
 */
@Injectable()
export class XpoDataDrawerDefaultDataSource<TInput = any> extends XpoDataDrawerDataSource<TInput> {
  fetchEntity(focusedRecord: TInput, selection?: TInput[]): Observable<TInput> {
    return observableOf(focusedRecord);
  }
}
