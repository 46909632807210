import { PipeTransform, Pipe } from '@angular/core';
import { GlobalSearchResultType } from './global-search-result-type.enum';

@Pipe({
  name: 'globalSearchResponseType',
})
export class GlobalSearchResponseTypePipe implements PipeTransform {
  transform(resultType: GlobalSearchResultType, count: number = 0, isProSearch?: boolean): any {
    if (!resultType) {
      return '';
    }

    let typeString = `${count} `;

    switch (resultType) {
      case GlobalSearchResultType.ClaimSearchResults: {
        typeString += 'Claim';
        break;
      }
      case GlobalSearchResultType.DisputeSearchResults: {
        typeString += 'Dispute';
        break;
      }
      case GlobalSearchResultType.InvoiceSearchResults: {
        typeString += 'Invoice';
        break;
      }
      case GlobalSearchResultType.ShipmentSearchResults: {
        typeString += 'Shipment';
        break;
      }
      default: {
        typeString += resultType;
        break;
      }
    }

    if (count > 1) {
      typeString += 's';
    }

    if (
      isProSearch &&
      (resultType === GlobalSearchResultType.ClaimSearchResults ||
        resultType === GlobalSearchResultType.DisputeSearchResults)
    ) {
      typeString = `PRO in ${typeString}`;
    }

    return typeString;
  }
}
