import {AttemptReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class AttemptReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in AttemptReasonCd ) {
      this._values.push(AttemptReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): AttemptReasonCd {

    for ( const obj in AttemptReasonCd ) {
      if (AttemptReasonCd[obj] === value){
        return AttemptReasonCd[obj] as AttemptReasonCd;
      }
    }
  }
}

const AttemptReasonCdHelper = new AttemptReasonCdHelperEnumHelperClass();
export default AttemptReasonCdHelper;
