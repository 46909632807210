import { ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { XpoFilterComponentBase, XpoFilterCriteria } from '../models/index';
import { XpoInlineMultiSelectFilter } from './inline-multi-select-filter';

@Component({
  selector: 'xpo-inline-multi-select-filter',
  templateUrl: 'inline-multi-select-filter.component.html',
  styleUrls: ['inline-multi-select-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-InlineMultiSelectFilter', '[attr.id]': 'id' },
})
export class XpoInlineMultiSelectFilterComponent extends XpoFilterComponentBase implements OnDestroy {
  options: any[] = [];
  optionsSource$ = new BehaviorSubject<any[]>([]);

  constructor() {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.optionsSource$.complete();
    this.optionsSource$ = undefined;
  }

  /** Updates the selected criterion of filter */
  onCheckboxClicked(el: any, selectedValue: string): void {
    const itemIndex = this.options.findIndex((i) => i.value === selectedValue);
    this.options[itemIndex].checked = el.checked;

    // publish the values for any selected options
    const value = this.options.filter((opt) => opt.checked).map((opt) => opt.value);

    this.storeCriteria(value);
  }

  protected initialize(): void {
    const config = <XpoInlineMultiSelectFilter>this.configuration;

    this.options = config.options.map((o: any) => {
      return {
        checked: false,
        label: o[config.labelField],
        value: o[config.valueField],
      };
    });
    this.optionsSource$.next(this.options);
  }

  protected onCriteriaModified(fieldValue: object, criteria: XpoFilterCriteria): void {
    const val = <string[]>fieldValue || new Array<string>();

    // check off the appropriate checkboxes
    this.options.forEach((opt) => {
      opt.checked = val.indexOf(opt.value) !== -1;
    });
    this.optionsSource$.next(this.options);
  }
}
