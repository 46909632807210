import { Type } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { XrtSearchResponse, XrtSearchRequest } from '../models/index';

export interface XrtConfig {
  apiUri: string;
}

export class XrtService {
  protected xrtBaseUri: any;
  protected defaultHeaders = new HttpHeaders();

  constructor(protected config: XrtConfig, private http: HttpClient) {
    this.xrtBaseUri = this.trimEnd(config.apiUri, '/');
  }

  search<T>(resultType: Type<T>, request: XrtSearchRequest, configUri?: string): Observable<XrtSearchResponse<T>> {
    let headers = this.defaultHeaders;
    const httpHeaderAccepts: string[] = ['application/json'];
    headers = headers.set('Accept', httpHeaderAccepts);

    const uri: string = this.getUri(request, configUri);
    return this.http.post<XrtSearchResponse<T>>(uri, request, {
      headers: headers,
    });
  }

  /*
   * configUri value comes from backend code. eg. options.AddExportModule(config => { config.Uri = "export"; });
   */
  export<T>(request: XrtSearchRequest, configUri: string = 'export'): Observable<Blob> {
    const uri: string = this.getUri(request, configUri);
    return this.http.post(uri, request, {
      responseType: 'blob',
    });
  }

  private getUri(request: XrtSearchRequest, configUri?: string): string {
    return configUri ? `${this.xrtBaseUri}/${configUri}/${request.type}` : `${this.xrtBaseUri}/${request.type}`;
  }

  private trimEnd(item: string, char: string): string {
    return item.endsWith(char) ? item.substring(0, item.lastIndexOf(char)) : item;
  }
}
