import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	DeleteDataExtractPath,
	DeleteDatasetPath,
	DeleteModulePath,
	DeleteModuleGroupPath,
	DeleteModuleVersionPath,
	DuplicateModuleGroupRqst,
	DuplicateModuleGroupResp,
	GetDataExtractResp,
	GetDataExtractPath,
	GetLastRunStatusByModuleGroupResp,
	GetLastRunStatusByModuleGroupPath,
	GetModuleGroupResp,
	GetModuleGroupPath,
	GetMostRecentDatasetResp,
	GetMostRecentDatasetPath,
	ListDataExtractsResp,
	ListDataExtractsQuery,
	ListDatasetPeriodCdsResp,
	ListDatasetsResp,
	ListDatasetsQuery,
	ListModuleGroupRunRequestsResp,
	ListModuleGroupRunRequestsQuery,
	ListModuleGroupRunStatusesResp,
	ListModuleGroupRunStatusesPath,
	ListModuleGroupsResp,
	ListModuleGroupsQuery,
	ListModuleGrpMVersionRunResultsResp,
	ListModuleGrpMVersionRunResultsQuery,
	ListModuleGrpMVersionRunResultsPath,
	ListModulesResp,
	ListModulesQuery,
	ListNotificationsResp,
	ListNotificationsQuery,
	UpsertModuleGroupRqst,
	UpsertModuleGroupResp,
	UpsertDataExtractRqst,
	UpsertDataExtractResp,
	UpsertDatasetRqst,
	UpsertDatasetResp,
	UpsertModuleAndModuleVersionByNameRqst,
	UpsertModuleAndModuleVersionByNameResp,
	UpsertModuleGroupRunRequestRqst,
	UpsertModuleGroupRunRequestResp,
	UpsertModuleGrpMVersionRunResultRqst,
	UpsertModuleGrpMVersionRunResultResp,
	LaunchModuleGroupRqst,
	LaunchModuleGroupResp,
	ScheduleModuleGroupExecutionRqst,
	UnscheduleModuleGroupExecutionPath
} from './api-moduleexecutor';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class ModuleExecutorApiService extends BaseService {
	private static ModuleExecutorApiEndpoint = 'moduleexecutorApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation is not implemented yet.
	* <br/>
	* <br/>This operation deletes a DataExtract based on the instance ID provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The DataExtract instance ID is required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If a DataExtract exists with the instance ID provided, then it is deleted.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public deleteDataExtract(
							   pathParams: DeleteDataExtractPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/data-extracts/{dataExtractInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Deletes a Dataset based on the instance ID provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The Dataset instance ID is required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If a Dataset exists with the instance ID provided, then it is deleted.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public deleteDataset(
							   pathParams: DeleteDatasetPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/datasets/{datasetInstId}/{dataExtractInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation deletes a Module with the instance ID provided only if the module is not being used by any Module group. All the module version of the Module are also deleted.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The module instance ID is required.
	* <br/>2. The module instance ID is valid for an existent Module.
	* <br/>3. The module is not being used by any Module group.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. The module is deleted and If any Module Versions exist for that module, then they are deleted too.
	* <br/>2. If the Module group does not exist for the module instance ID, a Non Found error is returned.
	* <br/>3. If the Module is being used by any Module group an appropriate message is returned informing the Module cannot be deleted for that reason.
	* <br/>4. If the deletion is not successful for any other unexpected reason then an appropriate error message is returned.
	*/
	public deleteModule(
							   pathParams: DeleteModulePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/modules/{moduleInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation deletes a Module Group based on the instance ID provided with all the data associated to that Module group: Module Versions, Execution Parameters, Run Requests and Run Results.
	* <br/>If the Module Group is already schedule then an unschedule is perform before the deletion in order to remove the DAG of the module group from Cloud Composer-Airflow.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The module group instance ID is required.
	* <br/>2. The module group instance ID is valid for an existent Module group.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the Module Group exists with the instance ID provided, then it is deleted with all the data associated to that Module group and the DAG file for that module group is deleted from Cloud Composer-Airflow.
	* <br/>2. If the Module Group does not exist with the instance ID provided then a Not Found error is returned.
	* <br/>3. If the deletion is not successful for any other unexpected reason then an appropriate error message is returned.
	*/
	public deleteModuleGroup(
							   pathParams: DeleteModuleGroupPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/module-groups/{moduleGroupInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation deletes a module version based on the module version sequence number and the module instance ID provided. The delete is perform only if the module version is not being used by any module group.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The moduleVersionSeqNbr and the moduleInstId are required.
	* <br/>2. The moduleVersionSeqNbr and the moduleInstId are valid for an existent module version.
	* <br/>3. The module version is not being used by any Module group.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the moduleVersionSeqNbr and the moduleInstId are valid for an existent module version then the module version is deleted. 
	* <br/>2. If the module version does not exist for the moduleVersionSeqNbr and the moduleInstId then a Not Found error is returned.
	* <br/>3. If the module version is being used by any Module group an appropriate message is returned informing the module version cannot be deleted for that reason.
	* <br/>4. If the deletion is not successful for any other unexpected reason then an appropriate error message is returned.
	*/
	public deleteModuleVersion(
							   pathParams: DeleteModuleVersionPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/module-versions/{moduleVersionSeqNbr}/modules/{moduleInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation duplicates and returns a Module Group based on the identifier provided. The module group configuration of Module Versions and its execution parameters is duplicated.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid moduleGroupInstId is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the module group is duplicated and a new module group with the same Module Versions and execution parameters is created and returned.
	* <br/>2. The new module group has the same module versions and execution parameters of the original module group.
	* <br/>3. If no module group is found for the identifier sent, a Not Found error is returned.	
	* <br/>4. If the duplicate operation is not successful for any other unexpected reason then an appropriate error message is returned.
	*/
	public duplicateModuleGroup(request: DuplicateModuleGroupRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DuplicateModuleGroupResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/module-groups/duplication'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation retrieves the Data Extract by the name supplied.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The dataExtractName is supplied and valid for an existent Data Extract.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the DataExtract that matches the name supplied is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getDataExtract(
							   pathParams: GetDataExtractPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetDataExtractResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/data-extracts/{dataExtractName}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation gets the module group's last run status with the execution DateTime and the URL to the Cloud Composer/Airflow graph view for the provided moduleGroupInstId. 
	* <br/>The last run status is got from the Cloud Composer/Airflow REST API.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The moduleGroupInstId must be provided and valid for an existent module group.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the moduleGroupInstId is valid and the module group was already scheduled, the Cloud Composer/Airflow REST API is called and the next fields are returned in the response:
	* <br/>a. executionDateTime: the date and time for the last run of the module group
	* <br/>b. graphViewHyperlink: the URL to the Cloud Composer/Airflow graph view for the last run of the module group
	* <br/>c. runStatus: the status of the last run of the module group in Cloud Composer/Airflow, can be one of the following:
	* <br/>	- Failed
	* <br/>	- Running
	* <br/>	- Success
	* <br/>	- No Status
	* <br/>	- Queued
	* <br/>	- Up for Retry
	* <br/>	- Up For Reschedule
	* <br/>	- Skipped
	* <br/>2. If the moduleGroupInstId is valid but the module group has not been scheduled, then a runStatus of Unscheduled is returned and no executionDateTime and graphViewHyperlink are returned.
	* <br/>3. If the moduleGroupInstId is valid and the module group was already scheduled, but Cloud Composer/Airflow REST API call fails or don't return any data then a runStatus of Scheduled is returned and no executionDateTime and graphViewHyperlink are returned.
	* <br/>4. If no module group is found for the identifier sent, a Not Found error is returned.	
	* <br/>5. If there is an error for any other unexpected reason then an appropriate error message is returned.
	*/
	public getLastRunStatusByModuleGroup(
							   pathParams: GetLastRunStatusByModuleGroupPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLastRunStatusByModuleGroupResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/module-groups/last-run-status/{moduleGroupInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns a ModuleGroup by the instance ID with its associated lists of ModuleGroupModuleVersion and ModuleGroupExecParm.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The moduleGroupInstId is supplied and it is valid for an existent Module Group.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the module group with the identifier sent is returned and contains the list of the associated ModuleGroupModuleVersion, each one of the ModuleGroupModuleVersion contains the associated list of ModuleGroupExecParm
	* <br/>2. Otherwise an appropriate error message is returned.
	* <br/>3. If no module group is found for the identifier sent, a Not Found error is returned.
	*/
	public getModuleGroup(
							   pathParams: GetModuleGroupPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetModuleGroupResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/module-groups/{moduleGroupInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns the data of the most recent dataset for the period code and the extract argument ID sent. 
	* <br/>	If the extract argument ID is for a Dataset type of Constant, then the period code sent is overridden with a period code of Constant.
	* <br/> If a period code of LASTMONTH is provided, then the last month is calculated using the current date and the format yyyyMM.
	* <br/> 
	* <br/>Pre-condition:
	* <br/>1. periodCd and argumentId are supplied and valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the data for the most recent dataset is returned for the supplied periodCd and argumentId.
	* <br/>2. If no data is found for the most recent dataset with the supplied periodCd and argumentId, then a Not Found Error is returned.
	* <br/>2. On failure for any other unexpected reason, then an appropriate error message is returned.
	*/
	public getMostRecentDataset(
							   pathParams: GetMostRecentDatasetPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetMostRecentDatasetResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/datasets/{periodCd}/{argumentId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists data extracts matching the sent parameters. If no parameters are sent then returns all the exiting data extracts.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. To filter the list of data extracts, optional query parameters for the period code and data extract type are sent.
	* <br/>2. To paginate the list of data extracts, the following query parameters for pagination are sent:
	* <br/>-numberOfRows: The number of results to retrieve in the current page.
	* <br/>-startAt: the position of the first result to retrieve, numbered from 0.
	* <br/>-sortByFieldName: Array with the names of the columns used to sort the results. Multiple columns names are allowed but a sortOrder needs to be defined for each column.
	* <br/>If the sortOrder is not defined for a column name, a default sorting of Ascending is used.
	* <br/>-sortOrder: Array with the sort orders for each column in the sortByFieldName array. The valid values are: Ascending, Descending. If any sortOrder is not defined, a default sorting of Ascending is used for each column in the sortByFieldName array.
	* <br/>Important Note: to get a correct pagination, all the parameters for pagination listed above need to be sent.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. A list with the data extracts matching the sent parameters is returned.
	* <br/>2. If the parameters for pagination are sent, a list with the data extracts matching the sent parameters and the pagination data  is returned.
	* <br/>3. If no optional query parameters are provided, all the exiting data extracts are returned.
	* <br/>4. If any error happens an appropriate error code and message are returned.
	*/
	public listDataExtracts(
							   queryParams: ListDataExtractsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListDataExtractsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/data-extracts'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists all period codes for which data sets are available.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Dataset records exist.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If datasets exist, then a distinct list of period codes are returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listDatasetPeriodCds(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListDatasetPeriodCdsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/dataset/period-code'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists datasets matching the sent parameters. If no parameters are sent then returns all the exiting data sets.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. To filter the list of data sets, optional query parameters for the period code and data extract instance Id are sent.
	* <br/>2. To paginate the list of data sets, the following query parameters for pagination are sent:
	* <br/>-numberOfRows: The number of results to retrieve in the current page.
	* <br/>-startAt: the position of the first result to retrieve, numbered from 0.
	* <br/>-sortByFieldName: Array with the names of the columns used to sort the results. Multiple columns names are allowed but a sortOrder needs to be defined for each column.
	* <br/>If the sortOrder is not defined for a column name, a default sorting of Ascending is used.
	* <br/>-sortOrder: Array with the sort orders for each column in the sortByFieldName array. The valid values are: Ascending, Descending. If any sortOrder is not defined, a default sorting of Ascending is used for each column in the sortByFieldName array.
	* <br/>Important Note: to get a correct pagination, all the parameters for pagination listed above need to be sent.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. A list with the data sets matching the sent parameters is returned.
	* <br/>2. If the parameters for pagination are sent, a list with the data sets matching the sent parameters and the pagination data is returned.
	* <br/>3. If no optional query parameters are provided, all the exiting data sets are returned.
	* <br/>4. If any errors happens an appropriate error code and message are returned.
	*/
	public listDatasets(
							   queryParams: ListDatasetsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListDatasetsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/datasets'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists the ModuleGroupRunRequests that are available for the provided moduleGroupInstId.
	* <br/>
	* <br/>Pre-conditiosn:
	* <br/>1. The moduleGroupInstId is provided.
	* <br/>2. To paginate the list of ModuleGroupRunRequests, the following query parameters for pagination are sent:
	* <br/>-numberOfRows: The number of results to retrieve in the current page.
	* <br/>-startAt: the position of the first result to retrieve, numbered from 0.
	* <br/>-sortByFieldName: Array with the names of the columns used to sort the results. Multiple columns names are allowed but a sortOrder needs to be defined for each column.
	* <br/>If the sortOrder is not defined for a column name, a default sorting of Ascending is used.
	* <br/>-sortOrder: Array with the sort orders for each column in the sortByFieldName array. The valid values are: Ascending, Descending. If any sortOrder is not defined, a default sorting of Ascending is used for each column in the sortByFieldName array.
	* <br/>Important Note: to get a correct pagination, all the parameters for pagination listed above need to be sent.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. A list with the ModuleGroupRunRequests for the provided moduleGroupInstId is returned.
	* <br/>2. If the parameters for pagination are sent, a list with the ModuleGroupRunRequests for the provided moduleGroupInstId and the pagination data is returned.
	* <br/>3. If any errors happens an appropriate error code and message are returned.
	*/
	public listModuleGroupRunRequests(
							   queryParams: ListModuleGroupRunRequestsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListModuleGroupRunRequestsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/module-group-run-requests'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists the module group's run statuses with the execution DateTime and the URL to the Cloud Composer/Airflow graph view for the provided moduleGroupInstId. 
	* <br/>	The run statuses are got from the Cloud Composer/Airflow REST API.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The moduleGroupInstId must be provided and valid for an existent module group.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the moduleGroupInstId is valid and the module group was already scheduled, the Cloud Composer/Airflow REST API is called and a list of objects with the next fields are returned in the response:
	* <br/>a. executionDateTime: the date and time for the run of the module group
	* <br/>b. graphViewHyperlink: the URL to the Cloud Composer-Airflow graph view for the run of the module group
	* <br/>c. runStatus: the status of the run of the module group in Cloud Composer/Airflow, can be one of the following:
	* <br/>	- Failed
	* <br/>	- Running
	* <br/>	- Success
	* <br/>	- No Status
	* <br/>	- Queued
	* <br/>	- Up for Retry
	* <br/>	- Up For Reschedule
	* <br/>	- Skipped
	* <br/>2. If the Composer/Airflow REST API call fails or don't return any data then and empty list is returned.
	* <br/>4. If no module group is found for the identifier sent, a Not Found error is returned.	
	* <br/>5. If there is an error for any other unexpected reason then an appropriate error message is returned.
	*/
	public listModuleGroupRunStatuses(
							   pathParams: ListModuleGroupRunStatusesPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListModuleGroupRunStatusesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/module-groups/run-statuses/{moduleGroupInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists the module groups based on the provided parameters. Optional parameters are the moduleVersionSeqNbr, moduleInstId and dynamicDagInd.
	* <br/>	For each module group in the list the data of the module group is returned including the detail of the module versions and execution parameters. 
	* <br/>	In addition, to include extra information about the last run of the module group, the optional query parameter: levelOfDetail can be sent with the value runStatus.
	* <br/>	This operation has pagination options implemented.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Module groups exist.
	* <br/>2. To return module groups associated to an specific module or module version, the optional query parameters moduleVersionSeqNbr and moduleInstId are sent.
	* <br/>3. To return the state of each module group, it is necessary to send the value "runStatus" in the request parameter "levelOfDetail". Example: levelOfDetail = runStatus.
	* <br/>When levelOfDetail is not provided, the modules groups returned in the list, will not contain the last run status.
	* <br/>4. To paginate the list of module groups, the following query parameters for pagination are sent:
	* <br/>-numberOfRows: The number of results to retrieve in the current page.
	* <br/>-startAt: the position of the first result to retrieve, numbered from 0.
	* <br/>-sortByFieldName: Array with the names of the columns used to sort the results. Multiple columns names are allowed but a sortOrder needs to be defined for each column.
	* <br/>If the sortOrder is not defined for a column name, a default sorting of Ascending is used.
	* <br/>-sortOrder: Array with the sort orders for each column in the sortByFieldName array. The valid values are: Ascending, Descending. If any sortOrder is not defined, a default sorting of Ascending is used for each column in the sortByFieldName array.
	* <br/>Important Note: to get a correct pagination, all the parameters for pagination listed above need to be sent.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then a list of the module groups that match the parameters are returned. If no parameters then all the available module groups are returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listModuleGroups(
							   queryParams: ListModuleGroupsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListModuleGroupsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/module-groups'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation is not implemented yet.
	* <br/>
	* <br/>This operation lists all moduleGrpMVersionRunResults that are available.
	*/
	public listModuleGrpMVersionRunResults(
							   pathParams: ListModuleGrpMVersionRunResultsPath,
							   queryParams: ListModuleGrpMVersionRunResultsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListModuleGrpMVersionRunResultsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/module-group-module-version-run-requests/{runInstId}/module-group/{moduleGroupInstId}/module-version/{moduleVersionSeqNbr}/module/{moduleInstId}/sequence-number/{runResultSequenceNbr}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists all the modules that are available with all the versions within each module.  It has pagination options implemented.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Modules exist.
	* <br/>2. To paginate the list of modules, the following query parameters for pagination are sent:
	* <br/>-numberOfRows: The number of results to retrieve in the current page.
	* <br/>-startAt: the position of the first result to retrieve, numbered from 0.
	* <br/>-sortByFieldName: Array with the names of the columns used to sort the results. Multiple columns names are allowed but a sortOrder needs to be defined for each column.
	* <br/>If the sortOrder is not defined for a column name, a default sorting of Ascending is used.
	* <br/>-sortOrder: Array with the sort orders for each column in the sortByFieldName array. The valid values are: Ascending, Descending. If any sortOrder is not defined, a default sorting of Ascending is used for each column in the sortByFieldName array.
	* <br/>Important Note: to get a correct pagination, all the parameters for pagination listed above need to be sent.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. A list with all the modules with all the versions within each module is returned.
	* <br/>2. If the parameters for pagination are sent, a list with the modules matching the pagination parameters is returned.
	* <br/>3. If any error happens, an appropriate error code and message are returned.
	*/
	public listModules(
							   queryParams: ListModulesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListModulesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/modules'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists all recent notifications that are available.  The notifications will include the following:
	* <br/>1. Newly added or updated Modules
	* <br/>2. Newly added or updated Module Versions
	* <br/>3. Newly added or updated Module Groups
	* <br/>4. Module Versions that failed execution by module group
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Supplied notificationWindowMinutes for the window of the notifications to list.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If any notifications are available, then they will be listed.
	* <br/>2. If no notifications are available, an empty response will be returned.
	*/
	public listNotifications(
							   queryParams: ListNotificationsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListNotificationsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/notifications'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation performs the following actions on the module group:
	* <br/>1. If the Module group already exists and it was previously schedule, then an unschedule is performed.
	* <br/>2. The module group is updated or created depending on the presence of the moduleGroupInstId. If the moduleGroupInstId is null then the ModuleGroup is created, otherwise is updated. When the ModuleGroup is created also its ModuleGroupModuleVersion are created with their associated ModuleGroupExecParm. 
	* <br/>When the ModuleGroup is updated, its ModuleGroupModuleVersion and their associated ModuleGroupExecParm are created, updated or deleted depending on the ActionCd contained in the element.
	* <br/>The field 'schedule' of the Module Group is never updated with this API. To update that field use the APIs: scheduleModuleGroupExecution and unscheduleModuleGroupExecution.
	* <br/>3. If the module group sent as a parameter, contains any value in the field schedule, then it is scheduled.
	* <br/>
	* <br/>Pre-conditions for the create:
	* <br/>1. The moduleGroupInstId has a null value.
	* <br/>2. The moduleGroupName is not being used by another Module Group.
	* <br/>3. The Module Versions exist for the moduleInstId and moduleVersionSequenceNbr sent.
	* <br/>4. If any execution parameter is associated to any Module Version, a valid argumentTypeCd needs to be set. For an argumentTypeCd of DataSet the parentDataExtractInstId is mandatory.
	* <br/>For an argumentTypeCd other than DataSet, the fields argumentId and argument are required.
	* <br/>
	* <br/>Post-conditions for the create:
	* <br/>1. If the creation is successful:
	* <br/>1.1 The Module Group is created with its associated ModuleGroupModuleVersions and ModuleGroupExecParms.
	* <br/>1.2 The Module Group is created with a null value in the schedule field.
	* <br/>1.3 If there is any value in the field schedule within the sent module group, then the Module Group is updated with the schedule cron expression and The DAG file(Python file with extension .py) for Cloud Composer/Airflow is stored in the cloud storage.
	* <br/>2. If the creation fails, an appropriate error message is returned.
	* <br/>
	* <br/>
	* <br/>Pre-conditions for the update:
	* <br/>1. The moduleGroupInstId has a not null value.
	* <br/>2. The moduleGroupName is not being used by another Module Group.
	* <br/>3. The Module Versions exist for the moduleInstId and moduleVersionSequenceNbr sent.
	* <br/>4. If any execution parameter is associated to any Module Version, a valid argumentTypeCd needs to be set. For an argumentTypeCd of DataSet the parentDataExtractInstId is mandatory.
	* <br/>For an argumentTypeCd other than DataSet, the fields argumentId and argument are required.
	* <br/>5. Each ModuleGroupModuleVersion and ModuleGroupExecParm associated to the ModuleGroup can have a value in the ActionCd field. 
	* <br/>For a ModuleGroupModuleVersion with:
	* <br/>- ActionCd of Add: All its execution parameters need to have the same ActionCd of Add and the ModuleGroupModuleVersion with its associated ModuleGroupExecParm will be created
	* <br/>- ActionCd of Delete: All its execution parameters need to have the same ActionCd of Delete and the ModuleGroupModuleVersion with its associated ModuleGroupExecParm will be deleted
	* <br/>- ActionCd of Update or null: its execution parameters will be created or deleted according to the ActionCd in each of them, and the ModuleGroupModuleVersion will be updated only for the ActionCd of Update.
	* <br/>
	* <br/>Post-conditions for the update:
	* <br/>1. If the update is successful:
	* <br/>1.1 The Module Group is updated and its associated ModuleGroupModuleVersions and ModuleGroupExecParms are created, updated or deleted according to the ActionCd indicated in each one of them.
	* <br/>1.2 If the module group was previously scheduled then the schedule column is set to null in the existing module group and the associated DAG file in the Cloud Composer/Airflow is removed.
	* <br/>1.3 If there is any value in the field schedule within the sent module group, then the Module Group is updated with the schedule cron expression and The DAG file(Python file with extension .py) for Cloud Composer/Airflow is stored in the cloud storage.
	* <br/>2. If the update fails, an appropriate error message is returned.
	* <br/>
	* <br/>Important Notes on failure case:
	* <br/>If the upsert of the module group fails performing the actions described above, the response will contain some MoreInfo objects giving more detail on the error:
	* <br/>- More Info object to describe the action that generates the error: this object will have these fields:
	* <br/>"location":"exceptionOrigin" and "message" with the action that generates the error, for example: "message":"UPSERT" indicates that the error was generated in the action of UPSERT. 
	* <br/>The "message" field can contain one of the following actions: DYNAMIC_VALIDATION, UPSERT, SCHEDULE, UNSCHEDULE.
	* <br/>-More info object if a partial unschedule was done and the unschedule API needs to be called in order to clean the schedule field in the module group: this object will have these fields:
	* <br/>"location":"unschedule" and "message":"true", this indicates that a call to the unschedule API needs to be done. This happens because on a failure, the transaction is rolled back and the unschedule involves the deletion of the DAG in Cloud Composer/Airflow and we cannot revert that deletion.
	* <br/>When an error happens and the DAG was already deleted, calling to the unschedule API will clean the field schedule in the module group.
	*/
	public upsertModuleGroup(request: UpsertModuleGroupRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertModuleGroupResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/module-groups'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation upserts a Data Extract.
	* <br/>If the Data Extract instance ID is null then the DataExtract is created, otherwise if instance Id is present then the Data Extract is updated.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. To create a Data Extract all the mandatory attributes needs to be sent.
	* <br/>2. To update a Data Extract all the mandatory attributes needs to be sent and the Data Extract needs to have an instance ID.
	* <br/>3. The dataExtractName and the argumentId of the Data Extract needs to be unique.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If all the mandatory data are sent and they are valid, the Data Extract is created or updated.
	* <br/>2. If any mandatory data is not present, any data is not valid or an errors happens, an appropriate error code and message are returned.
	*/
	public upsertDataExtract(request: UpsertDataExtractRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertDataExtractResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/data-extracts'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation upserts a Dataset. If the Dataset instance ID is null then the Dataset is created, otherwise if the instance ID is present then the Dataset is updated.
	* <br/>Pre-conditions:
	* <br/>1. To create a Dataset all the mandatory attributes needs to be sent.
	* <br/>2. To update a Dataset all the mandatory attributes needs to be sent and the Dataset needs to have an instance ID.
	* <br/>3. To create or update the Dataset, a valid data extract instance ID needs to be sent for an existent data extract.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If all the mandatory data are sent and they are valid, the Data Set is created or updated.
	* <br/>2. If any mandatory data is not present, any data is not valid or an errors happens, an appropriate error code and message are returned.
	*/
	public upsertDataset(request: UpsertDatasetRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertDatasetResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/datasets'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation upserts a Module and its ModuleVersion based on the provided Module Name and version.
	* <br/>	f a Module with the sent Module Name does not exist, then it is created, and also a version is created for the module with the sent Version.
	* <br/>	If a Module with the sent Module Name exists, then the Module is updated. If the sent Version already exist for that Module, then the version is also updated but if the sent version does not exist for that Module, then a new version is created.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. To create the module and its version, all the mandatory attributes needs to be sent and be valid.
	* <br/>2. To update the module and its version, all the mandatory attributes needs to be sent and be valid, in addition, the module with the same sent module name needs to exist and a version with the same sent version needs to exist.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If all the mandatory data are sent and they are valid, the Module and its Version are created or updated.
	* <br/>2. If any mandatory data is not present, any data is not valid or any error happens, an appropriate error code and message are returned.
	*/
	public upsertModuleAndModuleVersionByName(request: UpsertModuleAndModuleVersionByNameRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertModuleAndModuleVersionByNameResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/modules-module-versions'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation upsert the run request for the module group instance ID and the execution DateTime provided. If there is an existent ModuleGrpRunRequest for the module group instance ID and the execution DateTime provided then that ModuleGrpRunRequest is updated.
	* <br/>	If there is not an existent ModuleGrpRunRequest for the module group instance ID and the execution DateTime provided then that ModuleGrpRunRequest is created.
	* <br/>		
	* <br/>Pre-conditions:
	* <br/>1. To create a ModuleGrpRunRequest all the mandatory attributes needs to be sent and be valid.
	* <br/>2. To update a ModuleGrpRunRequest all the mandatory attributes needs to be sent and be valid and also the ModuleGrpRunRequest must exist for the module group instance ID and the execution DateTime provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If all the mandatory data are sent and they are valid, the ModuleGrpRunRequest is created or updated.
	* <br/>2. If any mandatory data is not present, any data is not valid or an errors happens, an appropriate error code and message are returned.
	*/
	public upsertModuleGroupRunRequest(request: UpsertModuleGroupRunRequestRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertModuleGroupRunRequestResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/module-group-run-requests'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation upserts the run result for the ModuleGroupModuleVersion instance Id and the execution DateTime provided. If there is an existent ModuleGrpMVersionRunResult for the ModuleGroupModuleVersion instance Id and the execution DateTime provided then that ModuleGrpRunRequest is updated.
	* <br/>	If there is not an existent ModuleGrpMVersionRunResult for the ModuleGroupModuleVersion instance ID and the execution DateTime provided, then that ModuleGrpMVersionRunResult is created.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. To create a ModuleGrpMVersionRunResult all the mandatory attributes needs to be sent and be valid.
	* <br/>2. To create a ModuleGrpMVersionRunResult, a ModuleGrpRunRequest must exist for the module group instance ID and the execution DateTime provided.
	* <br/>3. To update a ModuleGrpMVersionRunResult all the mandatory attributes needs to be sent and be valid and also the ModuleGrpMVersionRunResult must exist for the ModuleGroupModuleVersion instance Id and the execution DateTime provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If all the mandatory data are sent and they are valid, the ModuleGrpMVersionRunResult is created or updated.
	* <br/>2. If any mandatory data is not present, any data is not valid or an errors happens, an appropriate error code and message are returned.
	*/
	public upsertModuleGrpMVersionRunResult(request: UpsertModuleGrpMVersionRunResultRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpsertModuleGrpMVersionRunResultResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/module-group-module-version-run-result'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation launchs a module group execution in Cloud Composer/Airflow for the module group instance ID provided using the Cloud Composer/Airflow REST API. 
	* <br/>Only if the module group has a schedule vale of None or a cron string the module group is launched in Cloud Composer/Airflow.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid moduleGroupInstId is provided.
	* <br/>2. The module group was previously deployed to Cloud Composer/Airflow.
	* <br/>3. A valid token provider credentials are configured to access the Cloud Composer/Airflow REST API.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the module group execution will be launched in the Cloud Composer/Airflow.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public launchModuleGroup(request: LaunchModuleGroupRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<LaunchModuleGroupResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/module-groups/launch'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation sSchedules the execution of a Module Group, generating the DAG file and storing it in Cloud Composer/Airflow.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The ModuleGroupInstId is valid for an existent Module Group
	* <br/>2. The Module Group has associated at least one Module Version and the version has a valid Job Configuration in JSON format
	* <br/>3. If a scheduleCronExpression is sent, it needs to have the structure defined for a UNIX cron expression with length 5 or one of these cron preset: None,once,hourly,daily,weekly,monthly,yearly
	* <br/>The cron expression is a string comprising five or six fields separated by white space that represents a set of times(in order): Minutes Hours DayOfMonth Month DayOfWeek
	* <br/>The time represented by the cron expression should be sent in Coordinated Universal Time(UTC) 
	* <br/>More information: https://airflow.apache.org/docs/stable/scheduler.html https://en.wikipedia.org/wiki/Cron#CRON_expression
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the scheduling is successful:
	* <br/>1.1 The Module Group is updated with the schedule cron expression
	* <br/>1.2 The DAG file(Python file with extension .py) for Cloud Composer/Airflow is stored in the cloud storage
	* <br/>2. If the scheduling fails, an appropriate error message is returned.
	*/
	public scheduleModuleGroupExecution(request: ScheduleModuleGroupExecutionRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/module-groups/schedule-execution'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation removes the schedule of a Module Group execution. To remove the schedule, the schedule field of the Module Group is set to null and the DAG file that was stored in the Cloud Composer/Airflow when the Module Group was scheduled should be removed. 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid moduleGroupInstId is provided.
	* <br/>2. The moduleGroupInstId is valid for an existent Module Group.
	* <br/>3. The module group needs to has been previously scheduled.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If a module group exists with the instance ID provided and was previously scheduled, then the schedule field is set to null.
	* <br/>2. If a module group exists with the instance ID provided and was previously scheduled, then the associated DAG file in the Cloud Composer/Airflow is removed.
	* <br/>3. If a module group with the instance ID provided does not exist, then a Non Found Error is returned.
	* <br/>4. If a module group exists with the instance ID provided but It was not previously scheduled, then the unschedule fails and returns an error message informing that the module group is not scheduled.
	* <br/>5. If the unschedule fails for any other reason, an appropriate error code and message is returned.
	*/
	public unscheduleModuleGroupExecution(
							   pathParams: UnscheduleModuleGroupExecutionPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/moduleexecutor/module-groups/{moduleGroupInstId}/unscheduled'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(ModuleExecutorApiService.ModuleExecutorApiEndpoint);
	}
}
