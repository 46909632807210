/*
Tests for new view management logic will be handled in 	NGXLTL-967
*/
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { XpoViewListItem } from '../view-list';

@Component({
  selector: 'xpo-view-list',
  templateUrl: 'view-list.component.html',
  styleUrls: ['view-list.component.scss'],
})
export class XpoViewListComponent {
  // TODO: [NGXLTL-1018] Group and Owner handled in second iteration
  @Input('displayedColumns')
  displayedColumns?: string[] = ['name', /*'group', 'owner',*/ 'lastUpdated'];

  @Input('viewList')
  viewList: XpoViewListItem[];

  @Output('itemClick')
  itemClick = new EventEmitter<XpoViewListItem>();

  onRowClick(viewItem: XpoViewListItem) {
    this.itemClick.emit(viewItem);
  }
}
