import { Component, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { IsBetweenModel } from '../../model.interface';
import { XpoConditionTimeBetweenComponent } from '../../time/index';
import { ordersMinMaxTime } from '../../time/time.util';

import {
  CustomFilterParams,
  filterFormats,
  Operators,
  OperatorText,
  XpoFilterConditionCriteria,
} from './../../../../../models/index';

@Component({
  templateUrl: './between.component.html',
  styleUrls: ['../../validator.scss'],
})
export class XpoConditionTimeRangeBetweenComponent
  extends XpoConditionTimeBetweenComponent
  implements XpoFilterCondition {
  static text: string = OperatorText.Between;
  static operator: Operators = Operators.Between;

  set customFilterParams(params: CustomFilterParams) {
    this.is24Hour = params.is24HourFormat;
  }

  constructor() {
    super();
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    if (!cellValue || !cellValue.from || !cellValue.to) {
      return false;
    }

    const cellValueFrom = moment(cellValue.from, 'h:mma');
    const cellValueTo = moment(cellValue.to, 'h:mma');

    const modelFrom = moment(this.model.from, 'h:mma');
    const modelTo = moment(this.model.to, 'h:mma');

    // Common scenario when user set FROM lesser than TO
    if (modelFrom.isSameOrBefore(modelTo)) {
      return cellValueFrom.isSameOrAfter(modelFrom) && cellValueTo.isSameOrBefore(modelTo);
    }

    // Filter FROM is greater than TO, it means we only need to conside FROM, since TO will pass anyway
    return cellValueFrom.isSameOrAfter(modelFrom);
  }

  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.Between,
      value: this.model.from,
      valueTo: this.model.to,
    };
  }
}
