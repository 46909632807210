import { ColDef, ValueGetterParams } from 'ag-grid-community';

export class XpoGridColumnsUtil {
  static getColumnNestedValue(field: string, data: any): string | null {
    if (!field) {
      return null;
    }
    const columnFields = field.split('.');

    if (columnFields.length) {
      return columnFields.reduce((obj, property) => {
        return (obj && obj[property]) || '';
      }, data);
    }
    return null;
  }

  static getColumnRenderedValue(colDef: ColDef, params: ValueGetterParams): string | null {
    const colValue = XpoGridColumnsUtil.getColumnNestedValue(colDef.field, params.data);
    if (!colValue) {
      return null;
    }
    // As ag-grid specs, valueGetter can either be function or expression, so we need to normalize it
    if (colDef.valueGetter) {
      const getter = typeof colDef.valueGetter === 'function' ? colDef.valueGetter : () => colDef.valueGetter;
      return getter(params);
    }
    return colValue;
  }
}
