import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { XpoDateRangePicker } from '../../date-range-picker.component';
import { XpoDateRangeSelectionModel } from '../../models/date-range-selection.model';
import { XpoDateRangeEndInput } from './directives/date-range-end-input.directive';
import { XpoDateRangeStartInput } from './directives/date-range-start-input.directive';

/**
 * A form fiel that contains two input controls, one for the start date and other for the end date
 */
@Component({
  selector: 'xpo-date-range-input',
  exportAs: 'xpoDateRangeInput',
  templateUrl: 'date-range-input.component.html',
  styleUrls: ['date-range-input.component.scss'],
  host: {
    class: 'xpo-DateRangeInput',
    role: 'group',
    '[attr.id]': 'id',
    '[attr.aria-describedby]': 'ariaDescribedBy',
  },
  // need to override some styles of projected content (inputs controls)
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MatFormFieldControl, useExisting: XpoDateRangeInput }],
})
export class XpoDateRangeInput<D> implements MatFormFieldControl<DateRange<D>>, AfterContentInit, OnDestroy {
  /**
   * Unique ID for the control
   */
  protected static nextId: number = 0;

  /**
   * Current value of the range input.
   * Implemented as part of MatFormFieldControl
   */
  get value(): DateRange<D> {
    return this.selectionModel ? this.selectionModel.selection : null;
  }

  /**
   * Emits when the control's state has changed.
   * Implemented as part of MatFormFieldControl
   */
  stateChanges: Subject<void> = new Subject<void>();

  /**
   * Unique ID for the control, that MatFormField will associate all its labels and hints.
   * Implemented as part of MatFormFieldControl
   */
  id: string = `xpo-date-range-input-${XpoDateRangeInput.nextId++}`;

  /**
   * The placeholder for this control.
   * Set the placeholder attribute on `xpoDateRangeStart` and `xpoDateRangeEnd` inputs.
   * Implemented as part of MatFormFieldControl
   */
  get placeholder(): string {
    // TODO: ¿return based on inputs placeholders?
    return null;
  }

  /**
   * Gets the NgControl for this control.
   * Implemented as part of MatFormFieldControl
   */
  ngControl: NgControl = null;

  /**
   * Whether the control is focused.
   * Implemented as part of MatFormFieldControl
   */
  get focused(): boolean {
    // set the focused state for the form field control
    // based on the state of its children
    // or if the calendar is opened
    return this.startInput.focused || this.endInput.focused || this.dateRangePicker.isOpened();
  }

  /**
   * Whether the control is empty.
   * Implemented as part of MatFormFieldControl
   */
  get empty(): boolean {
    // TODO: return based on inputs values?
    return true;
  }

  /**
   * Whether the control's label should try to float.
   * Implemented as part of MatFormFieldControl
   */
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  /**
   * Whether the control is required.
   * Implemented as part of MatFormFieldControl
   */
  get required(): boolean {
    return this.startInput.required || this.endInput.required;
  }

  /**
   * Whether the control is disabled.
   * Implemented as part of MatFormFieldControl
   * TODO: what happens with inputs disabled property?
   */
  get disabled(): boolean {
    return this.isDisabled;
  }
  set disabled(disabled: boolean) {
    this.isDisabled = coerceBooleanProperty(disabled);
    // to make sure we run change detection if the disabled state changes
    this.stateChanges.next();
  }
  private isDisabled: boolean = false;

  /**
   * Whether the associated NgControl is in an error state.
   * Implemented as part of MatFormFieldControl
   */
  get errorState(): boolean {
    return this.startInput.errorState || this.endInput.errorState;
  }

  /**
   * This property allows us to specify a unique string for the type of control in form field.
   * The MatFormField will add an additional class based on this type that can be used to apply special styles.
   * Implemented as part of MatFormFieldControl
   */
  controlType = 'xpo-date-range-input';

  /** Separator text to be shown between the inputs. */
  @Input() separator = '–';

  /** The date range picker that this input is associated with. */
  @Input()
  get dateRangePicker(): XpoDateRangePicker<D> {
    return this.picker;
  }
  set dateRangePicker(dateRangePicker: XpoDateRangePicker<D>) {
    this.picker = dateRangePicker;

    // each time we set a new range picker we have to register the new selection model
    this.registerSelectionModel();

    // associate this component as the as the date picker input
    this.picker.registerInput(this);
  }
  private picker: XpoDateRangePicker<D>;

  /** Value for the `aria-describedby` attribute of the input */
  ariaDescribedBy: string | null = null;

  /** The selection model we get from the calendar picker */
  protected selectionModel: XpoDateRangeSelectionModel<D>;

  /** Input for the start date */
  @ContentChild(XpoDateRangeStartInput) startInput: XpoDateRangeStartInput<D>;

  /** Input for the end date */
  @ContentChild(XpoDateRangeEndInput) endInput: XpoDateRangeEndInput<D>;

  constructor(@Inject(MAT_FORM_FIELD) protected formField: MatFormField) {}

  ngAfterContentInit(): void {
    if (!this.startInput) {
      throw Error('xpo-date-range-input must contain a xpoDateRangeStartInput input');
    }

    if (!this.endInput) {
      throw Error('xpo-date-range-input must contain a xpoDateRangeEndInput input');
    }

    // register the date range picker selection model
    // for this component and its child inputs
    this.registerSelectionModel();
  }

  ngOnDestroy(): void {
    // make sure to complete stateChanges when the component is destroyed
    this.stateChanges.complete();
  }

  /**
   * Sets the list of element IDs that currently describe this control.
   * Implemented as part of MatFormFieldControl
   */
  setDescribedByIds(ids: string[]): void {
    this.ariaDescribedBy = ids.length ? ids.join(' ') : null;
  }

  /**
   * Handles a click on the control's container.
   * Implemented as part of MatFormFieldControl
   */
  onContainerClick(): void {
    if (!this.disabled && !this.dateRangePicker.isOpened()) {
      this.dateRangePicker.open();
    }
  }

  /**
   * Gets the element to which the calendar overlay should be attached.
   */
  getConnectedOverlayOrigin(): ElementRef {
    return this.formField.getConnectedOverlayOrigin();
  }

  /** Gets the value that is used to mirror the state of the start input. */
  getStartInputMirrorValue(): string {
    return this.startInput ? this.startInput.getMirrorValue() : '';
  }

  /**
   * Whether the separate text should be hidden.
   **/
  shouldHideSeparator(): boolean {
    // if start and end input have not placeholder or values
    // separator should be hidden
    return (
      this.startInput.getPlaceholder().length === 0 &&
      this.startInput.isEmpty() &&
      this.endInput.getPlaceholder().length === 0 &&
      this.endInput.isEmpty()
    );
  }

  /**
   * Whether to show the separate text with same
   * placeholder styles
   */
  showSeparatorAsPlaceholder(): boolean {
    // if start or end input have placeholder and both have empty values
    // separator should be hidden
    return (
      (this.startInput.getPlaceholder().length > 0 || this.endInput.getPlaceholder().length > 0) &&
      this.startInput.isEmpty() &&
      this.endInput.isEmpty()
    );
  }

  /**
   * Associate the date picker selection model to this component and its children inputs
   */
  protected registerSelectionModel(): void {
    this.selectionModel = this.dateRangePicker.selectionModel;

    // add a sanity check in case this is called in the date picker @Input set
    // before the children inputs are initialized
    if (this.startInput) {
      this.startInput.registerSelectionModel(this.selectionModel);
    }

    if (this.endInput) {
      this.endInput.registerSelectionModel(this.selectionModel);
    }
  }
}
