import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { cloneDeep} from 'lodash';
import { XPO_MONTH_DAY_PICKER_FORMATS } from '../../share/constants/date.constants';
import { XpoFacetFilterTypeControls } from '../../share/enums/type-list-control';
import { XpoFacetFilterCriteria } from '../../share/model/facet-model-criteria.interface';
import { XpoFacetFilterDateOperators, XpoFacetFilterTypeOperator } from '../../share/model/filter-criteria.interface';
import { XpoFacetFilterRangeDateModel } from '../../share/model/range-date.interface';
import { formatDate } from '../../share/utils/general';
import { XpoFilterControlBaseComponent } from '../filter-control-base.component';

const PREDEFINED_FORMAT_DATE = 'yyyy/MM/dd'; // universal format date  for filtering purposes
@Component({
  selector: 'xpo-date-range-facet-filter',
  templateUrl: './filter-control-date-range.component.html',
  styleUrls: ['./filter-control-date-range.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: XpoFilterControlBaseComponent, useExisting: forwardRef(() => XpoFacetFilterDateRangeControlComponent) },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: XPO_MONTH_DAY_PICKER_FORMATS},
  ],
})
export class XpoFacetFilterDateRangeControlComponent
  extends XpoFilterControlBaseComponent<XpoFacetFilterRangeDateModel> implements OnInit {

  originalFilterModel: XpoFacetFilterRangeDateModel;
  typeControl: XpoFacetFilterTypeControls = XpoFacetFilterTypeControls.DateRange;

  @Input()
  filterModel: XpoFacetFilterRangeDateModel = {} as XpoFacetFilterRangeDateModel;


  // TODO: allow user define input date format: https://jira.xpo.com/browse/NGXLTL-1369
  // @Input()
  // dateFormat: XpoFacetFilterFormatDateOptions = XpoFacetFilterFormatDateOptions.Long;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.checkInit();
    this.originalFilterModel = cloneDeep(this.filterModel);
  }

  setModel(model: XpoFacetFilterRangeDateModel): void {
    this.filterModel = model;
    this.originalFilterModel = cloneDeep(this.filterModel);
  }

  applyFilter(): void {
    this.onValueChanged(this.filterModel, this.mapFacetFilterControlToCriteria(cloneDeep(this.filterModel)));
  }

  onReset(): void {
    super.onReset();
    this.filterModel = cloneDeep(this.originalFilterModel);
    this.applyFilter();
  }

  mapFacetFilterControlToCriteria(val: XpoFacetFilterRangeDateModel): XpoFacetFilterCriteria {
    let operator: XpoFacetFilterDateOperators = XpoFacetFilterDateOperators.BETWEEN;
    if (val.minValue === undefined) {
      operator = XpoFacetFilterDateOperators.LESSERTHAN;
      val.minValue = val.maxValue;
      val.maxValue = undefined;
    } else if (val.maxValue === undefined) {
      operator = XpoFacetFilterDateOperators.GREATERTHAN;
    }
    return {
      filterId: this.filterId,
      type: XpoFacetFilterTypeOperator.Date,
      conditions: [{
        operator,
        value: formatDate(val.minValue, PREDEFINED_FORMAT_DATE),
        valueTo: formatDate(val.maxValue, PREDEFINED_FORMAT_DATE)
      }],
      invalid: !this.isValidCondition(),
    };
  }

  isValidCondition(): boolean {
    return (this.filterModel.maxValue !== undefined || this.filterModel.minValue !== undefined);
  }

}
