import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { XpoLtlServiceCentersService } from '../../services/service-centers/service-centers.service';
import { Observable, of } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

export function XpoLtlSicValidatorFunction(xpoLtlServiceCentersService: XpoLtlServiceCentersService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    if (!control || !control.value || control.value.length < 3) {
      return of(null);
    }

    return isSicValidFunction(control.value, xpoLtlServiceCentersService).pipe(
      first(),
      map((isValid) => (isValid ? null : { invalidLocation: true })),
      tap(() => control.markAsTouched())
    );
  };
}

export function isSicValidFunction(
  sicCd: string,
  xpoLtlServiceCentersService: XpoLtlServiceCentersService
): Observable<boolean> {
  return !sicCd || sicCd.length !== 3
    ? of(false)
    : xpoLtlServiceCentersService.getSicByCd$(sicCd).pipe(
        first(),
        map(
          (response) => {
            return !!response;
          },
          (errors) => {
            return false;
          }
        )
      );
}
