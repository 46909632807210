import { formatDate } from '@angular/common';

export class DateUtils {
  static readonly millisecondsPerDay: number = 24 * 60 * 60 * 1000;
  static readonly millisecondsPerWeek: number = 7 * DateUtils.millisecondsPerDay;

  constructor() {}

  static formatTimeString(time: string): string {
    return time ? time.replace('/^[0]+/g', '') : '';
  }

  static formatDate(date: Date): string {
    return date ? date.toLocaleDateString('en-US') : '';
  }

  static formatDateTime(date: Date, dateTimeFormatOptions?: Intl.DateTimeFormatOptions): string {
    const defaultDateTimeFormatOptions: any = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    if (dateTimeFormatOptions) {
      dateTimeFormatOptions = { ...defaultDateTimeFormatOptions, ...dateTimeFormatOptions };
      return date ? date.toLocaleString('en-US', dateTimeFormatOptions) : '';
    } else {
      return date ? date.toLocaleString('en-US', defaultDateTimeFormatOptions).replace(',', '') : '';
    }
  }

  static firstDayOfWeek(offset: number = 0): Date {
    const dayOfWeek = DateUtils.today().getDay();
    return DateUtils.fromToday(-1 * dayOfWeek + offset * 7);
  }

  static fromNow(offset: number): Date {
    const date = DateUtils.today();
    date.setHours(date.getHours() + offset);
    return date;
  }

  static fromToday(offset: number, interval: string = 'day'): Date {
    switch (interval) {
      case 'day': {
        return new Date(DateUtils.today().getTime() + this.millisecondsPerDay * offset);
      }
      case 'week': {
        return new Date(DateUtils.today().getTime() + this.millisecondsPerWeek * offset);
      }
      // case 'month': {
      //   let endDate = DateUtils.today().
      // }
      default: {
        return new Date(DateUtils.today().getTime() + this.millisecondsPerDay * offset);
      }
    }
  }

  static lastDayOfWeek(offset: number = 0): Date {
    const dayOfWeek = DateUtils.today().getDay();
    return DateUtils.fromToday(6 - dayOfWeek + offset * 7);
  }

  static today(): Date {
    return new Date();
  }

  static tomorrow(): Date {
    return DateUtils.fromToday(1);
  }

  static yesterday(): Date {
    return DateUtils.fromToday(-1);
  }

  static firstDayOfMonth(offset: number = 0): Date {
    const date = DateUtils.today();
    date.setMonth(date.getMonth() + offset);
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  static lastDayOfMonth(offset: number = 0): Date {
    const date = DateUtils.today();
    date.setMonth(date.getMonth() + offset);
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  static firstDayOfQuarter(offset: number = 0): Date {
    const quarter = Math.floor(DateUtils.today().getMonth() / 3) + 1;
    return new Date(DateUtils.today().getFullYear(), quarter * 3 - 3 + 3 * offset, 1);
  }

  static lastDayOfQuarter(offset: number = 0): Date {
    const date = DateUtils.firstDayOfQuarter(offset);
    return new Date(date.getFullYear(), date.getMonth() + 3, 0);
  }

  static getQuarter(date): number {
    date = date || new Date(); // If no date supplied, use today
    const q = [1, 2, 3, 4];
    return q[Math.floor(date.getMonth() / 3)];
  }

  static firstDayOfYear(offset: number = 0): Date {
    return new Date(new Date().getFullYear() + offset, 0, 1);
  }

  static lastDayOfYear(offset: number = 0): Date {
    return new Date(new Date().getFullYear() + offset, 11, 31);
  }

  static getTimeString(date: Date): string {
    return formatDate(date, 'HH:mm:ss', 'en-US');
  }

  static getDateFromOffset(offsetInSeconds: number): Date {
    const date = new Date();
    const utc = date.getTime() + date.getTimezoneOffset() * 60000;

    return new Date(utc + offsetInSeconds * 1000);
  }

  /**
   * Returns new date with the time set to the entered time value
   *
   */
  static combineDateAndTime(date: Date, time: string): Date {
    if (!date || !time) {
      return;
    }

    const [hours, min, sec] = time.split(':').map((v) => Number.parseInt(v, 10));

    return new Date(new Date(date).setHours(hours, min, sec || 0));
  }

  static crossBrowserToLocaleString(date: Date): string {
    return date ? formatDate(date, 'yyyy-MM-ddTHH:mm:ss', 'en-US') : '';
  }
}
