import { Injectable } from '@angular/core';

/* XPO */
import { ListModuleGroupsQuery, ListModuleGroupsResp, ModuleExecutorApiService } from '@xpo-ltl/sdk-moduleexecutor';

/* Rxjs */
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/* Services */
import { ListModuleGroupsStoreService } from './list-module-groups.store.service';

@Injectable()
export class ListModuleGroupsService {
  constructor(
    private apiService: ModuleExecutorApiService,
    private listModuleGroupsStoreService: ListModuleGroupsStoreService
  ) {}

  requestListModuleGroups(listModuleGroupsQuery: ListModuleGroupsQuery): Observable<ListModuleGroupsResp> {
    return this.apiService.listModuleGroups(listModuleGroupsQuery).pipe(
      switchMap((response: ListModuleGroupsResp) => {
        this.listModuleGroupsStoreService.populateListModuleGroups(response);

        return this.listModuleGroupsStoreService.listModuleGroups$;
      })
    );
  }
}
