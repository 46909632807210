import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Directive({
  selector: 'xpo-tab-drawer-content',
  host: { class: 'xpo-TabDrawer-content' },
})
export class XpoTabDrawerContent {}

@Component({
  selector: 'xpo-tab-drawer',
  templateUrl: './tab-drawer.html',
  styleUrls: ['./tab-drawer.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-TabDrawer' },
})
export class XpoTabsDrawer {
  private openedValue = true;
  private showCollapseButtonValue = true;
  private modeValue: XpoTabsDrawerMode = 'side';

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  /**
   * Whether or not the drawer should be open
   */
  @Input()
  get opened(): boolean {
    return this.openedValue;
  }
  set opened(value: boolean) {
    this.openedValue = coerceBooleanProperty(value);
    this.openedChange.emit(this.openedValue);
    // closing drawer doesn't trigger resize
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

  @Output()
  openedChange = new EventEmitter<boolean>();

  /**
   * Hide/show collapse button
   */
  @Input()
  get showCollapseButton(): boolean {
    return this.showCollapseButtonValue;
  }
  set showCollapseButton(value: boolean) {
    this.showCollapseButtonValue = coerceBooleanProperty(value);
  }

  @Input()
  get mode(): XpoTabsDrawerMode {
    return this.modeValue;
  }
  set mode(value: XpoTabsDrawerMode) {
    this.modeValue = value;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Toggle hiding/showing drawer
   */
  toggle(): void {
    this.opened = !this.opened;
    this.changeDetectorRef.markForCheck();
  }
}

/**
 * The modes that our drawers use.
 *
 * over: Slides the drawer over the UI, darkening the drawer content.
 *        Drawer must be closed to interact with content.
 *
 * push: The drawer pushes the content over off screen while darkening the content.
 *        The Drawer will close when interacting with the content.
 *
 * side: The drawer pushes the content over (not off-screen, though) not darkening
 *        the content. Drawer can be open while interacting with content.
 */
export type XpoTabsDrawerMode = 'over' | 'push' | 'side';
