import { GridOptions, ICellRendererParams } from 'ag-grid-community';

import { ModuleExecutorParmsArgTypeCd } from '@xpo-ltl/sdk-common';
import { ModuleGroupExecParm } from '@xpo-ltl/sdk-moduleexecutor';
import {
  ModuleGroupMgmtActionsCellRendererComponent,
  ModuleGroupMgmtNameCellRendererComponent,
} from '../cell-renderers';
import { ModuleGroupMgmtArgumentsDetailCellRendererComponent } from '../detail-cell-renderers';

export const MODULE_GROUP_MGMT_EXECUTION_STEP_GRID_OPTIONS: GridOptions = {
  defaultColDef: {
    suppressMenu: true,
    sortable: false,
  },
  masterDetail: true,
  rowDragManaged: true,
  detailRowAutoHeight: true,
  // Enable expanded row one at a time
  onRowGroupOpened: function(params) {
    if (params.node.expanded && params.api) {
      params.api.forEachNode(function(node) {
        if (node.expanded && node.id !== params.node.id && node.uiLevel === params.node.uiLevel) {
          node.setExpanded(false);
        }
      });
    }
  },
  detailCellRenderer: 'detailCellRenderer',
  frameworkComponents: {
    ActionsCellRenderer: ModuleGroupMgmtActionsCellRendererComponent,
    NameCellRenderer: ModuleGroupMgmtNameCellRendererComponent,
    detailCellRenderer: ModuleGroupMgmtArgumentsDetailCellRendererComponent,
  },
  groupSelectsChildren: true,
  // groupDefaultExpanded: 1,
  groupSuppressAutoColumn: true,
  suppressRowClickSelection: true,
  animateRows: true,
};
