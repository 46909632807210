import {CarrierTenderStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class CarrierTenderStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CarrierTenderStatusCd ) {
      this._values.push(CarrierTenderStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CarrierTenderStatusCd {

    for ( const obj in CarrierTenderStatusCd ) {
      if (CarrierTenderStatusCd[obj] === value){
        return CarrierTenderStatusCd[obj] as CarrierTenderStatusCd;
      }
    }
  }
}

const CarrierTenderStatusCdHelper = new CarrierTenderStatusCdHelperEnumHelperClass();
export default CarrierTenderStatusCdHelper;
