import {EventLogSubTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class EventLogSubTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EventLogSubTypeCd ) {
      this._values.push(EventLogSubTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EventLogSubTypeCd {

    for ( const obj in EventLogSubTypeCd ) {
      if (EventLogSubTypeCd[obj] === value){
        return EventLogSubTypeCd[obj] as EventLogSubTypeCd;
      }
    }
  }
}

const EventLogSubTypeCdHelper = new EventLogSubTypeCdHelperEnumHelperClass();
export default EventLogSubTypeCdHelper;
