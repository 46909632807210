import { XpoDateRangeFilterCriteria } from './date-range-filter-criteria';
import { XpoDateRangeFilterHelper } from './date-range-filter-helper';
import { XpoDateRangeFilterTab } from './date-range-filter-tab';
import { XpoDateRangeGroupByIntervalType } from './date-range-interval';
import { XpoDateRangeIntervalBase } from './date-range-interval-base';
import { XpoQuickDate } from './date-range-quick-date.enum';
import { XpoQuickFilter } from './date-range-quick-filter';

export class XpoDateRangeWeekly extends XpoDateRangeIntervalBase {
  get groupByInterval(): XpoDateRangeGroupByIntervalType {
    return 'week';
  }

  get label(): string {
    return 'Weekly';
  }

  getQuickFilterOptions(): XpoQuickFilter[] {
    return [
      { code: XpoQuickDate.LastMonth, display: 'Last Month' },
      { code: XpoQuickDate.ThisMonth, display: 'This Month' },
      { code: XpoQuickDate.NextMonth, display: 'Next Month' },
    ];
  }

  getTabModel(): XpoDateRangeFilterTab {
    return {
      pos: this.pos,
      quickFilterOptions: this.getQuickFilterOptions(),
      groupByInterval: this.groupByInterval,
    };
  }

  setNext(nextInput: number): XpoDateRangeFilterCriteria {
    return XpoDateRangeFilterHelper.constructFilter(XpoQuickDate.TodayPlusWeeks, nextInput * 1);
  }

  setPrev(prevInput: number): XpoDateRangeFilterCriteria {
    return XpoDateRangeFilterHelper.constructFilter(XpoQuickDate.TodayMinusWeeks, prevInput * 1);
  }
}
