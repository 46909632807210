import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { XpoViewMgtModalActions } from '../view-modal-actions.interface';
import { XpoViewMgtModalData } from '../view-modal-data.interface';
import { XpoViewMgtModalTexts } from '../view-modal.texts';

@Component({
  selector: 'xpo-rename-view-modal',
  templateUrl: './rename-view.component.html',
  styleUrls: ['./rename-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class XpoViewMgtModalRenameView {
  renameViewForm = new FormGroup({
    viewName: new FormControl('', [Validators.required]),
  });

  renameViewData: XpoViewMgtModalData = {
    title: XpoViewMgtModalTexts.RenameViewTitle,
    submitButtonText: XpoViewMgtModalTexts.RenameViewSubmitBtn,
    cancelButtonText: XpoViewMgtModalTexts.RenameViewCancelBtn,
  };
  modalActions: XpoViewMgtModalActions;

  @ViewChild('xpoViewModal') xpoViewModal;

  constructor(public dialogRef: MatDialogRef<XpoViewMgtModalRenameView>, @Inject(MAT_DIALOG_DATA) data) {
    this.modalActions = data.actions;
    this.renameViewForm.patchValue({
      viewName: data.formData.viewName,
    });
  }

  /**
   * On rename submit
   */
  renameView(): void {
    if (!this.renameViewForm.valid) {
      this.renameViewForm.markAllAsTouched();
      return;
    }
    this.xpoViewModal.isSubmitting = true;
    // Callback for rename view
    this.modalActions?.onSubmit({
      viewName: this.renameViewForm.get('viewName').value,
    });
  }

  /**
   * Cancel button action in modal
   */
  cancel(): void {
    // Callback for cancel
    this.modalActions?.onCancel();
  }
}
