import { Component } from '@angular/core';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { filterFormats, Operators, OperatorText, XpoFilterConditionCriteria } from './../../../../../models/index';

@Component({
  template: ' ',
})
export class XpoConditionBooleanTrueComponent extends XpoConditionBase implements XpoFilterCondition {
  static text = OperatorText.True;
  static applyAlwaysEnabled: boolean = true;
  static operator = Operators.True;

  isNegative = false;
  model: null = null;

  constructor() {
    super(filterFormats.default, false);
  }

  validate(cellValue): boolean {
    return cellValue === true;
  }
  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.True,
      value: true,
      display: ' ',
    };
  }
}
