import { Injectable } from '@angular/core';

import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ModuleGroupMgmtInfoService {
  convertTimeLocalToUTC(cronValue: string): string {
    const parseCron: string[] = cronValue !== 'INVALID' ? cronValue.split(' ') : null;
    let result: string = null;

    if (parseCron) {
      const timeParam = {
        minute: parseCron[0] !== '*' ? parseCron[0] : null,
        hour: parseCron[1] !== '*' ? parseCron[1] : null,
      };
      let time: moment.Moment;
      const { minute, hour } = timeParam;
      if (hour && minute) {
        time = moment(`${hour}:${minute}`, 'HH:mm').utc();
        parseCron[0] = `${time.minute()}`;
        parseCron[1] = `${time.hour()}`;
      } else if (hour) {
        time = moment(`${hour}`, 'HH').utc();
        parseCron[1] = `${time.hour()}`;
      } else if (minute) {
        time = moment(`${minute}`, 'mm').utc();
        parseCron[0] = `${time.minute()}`;
      }
      // TODO: Review edge cases when a timezone conversion change dates
      result = parseCron.join(' ');
    }
    return result;
  }

  convertTimeUTCToLocal(cronValue: string): string {
    const parseCron: string[] = cronValue !== 'INVALID' ? cronValue.split(' ') : null;
    let result: string = null;

    if (parseCron) {
      const timeParam = {
        minute: parseCron[0] !== '*' ? parseCron[0] : null,
        hour: parseCron[1] !== '*' ? parseCron[1] : null,
      };
      let time: moment.Moment;
      const { minute, hour } = timeParam;
      if (hour && minute) {
        time = moment.utc(`${hour}:${minute}`, 'HH:mm').local();
        parseCron[0] = `${time.minute()}`;
        parseCron[1] = `${time.hour()}`;
      } else if (hour) {
        time = moment.utc(`${hour}`, 'HH').local();
        parseCron[1] = `${time.hour()}`;
      } else if (minute) {
        time = moment.utc(`${minute}`, 'mm').local();
        parseCron[0] = `${time.minute()}`;
      }
      // TODO: Review edge cases when a timezone conversion change dates
      result = parseCron.join(' ');
    }
    return result;
  }

  getTimeFromCron(cronExpressionArr): any {
    const minutes =
      cronExpressionArr[0] !== '*'
        ? cronExpressionArr[0].length === 1
          ? `0${cronExpressionArr[0]}`
          : cronExpressionArr[0]
        : '';
    const hours =
      cronExpressionArr[1] !== '*'
        ? cronExpressionArr[1].length === 1
          ? `0${cronExpressionArr[1]}`
          : cronExpressionArr[1]
        : '';
    return {
      minutes,
      hours,
    };
  }

  getWeekDaysFromCron(cronExpressionArr): any {
    const cronWeekDays = cronExpressionArr[4] !== '*' ? cronExpressionArr[4] : [];
    let weekDays = {};
    ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'].forEach((i) => {
      weekDays = { ...weekDays, [i]: cronWeekDays.indexOf(i) !== -1 };
    });
    return {
      weekDays,
    };
  }
}
