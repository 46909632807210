import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { MODULE_GROUP_MGMT_ASSOCIATED_MODULES_COLUMNS } from './module-group-mgmt-associated-modules-columns';

export const MODULE_GROUP_MGMT_ASSOCIATED_MODULES_VIEW_TEMPLATES: XpoAgGridBoardViewTemplate[] = [
  new XpoAgGridBoardViewTemplate({
    id: 'module-group-mgmt-associated-modules',
    name: 'Module Group Management Associated Modules',
    allowAdditional: false,
    availableColumns: MODULE_GROUP_MGMT_ASSOCIATED_MODULES_COLUMNS,
    keyField: 'moduleVersionSequenceNbr',
  }),
];
