import {DsrLicenseCd} from '../';
import {EnumHelper} from './enum-helper';

export class DsrLicenseCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DsrLicenseCd ) {
      this._values.push(DsrLicenseCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DsrLicenseCd {

    for ( const obj in DsrLicenseCd ) {
      if (DsrLicenseCd[obj] === value){
        return DsrLicenseCd[obj] as DsrLicenseCd;
      }
    }
  }
}

const DsrLicenseCdHelper = new DsrLicenseCdHelperEnumHelperClass();
export default DsrLicenseCdHelper;
