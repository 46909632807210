import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { XpoChipsModule } from '../chips/chips.module';

import { XpoDragAndDrop } from './drag-and-drop/index';
import { XpoFileSizePipe } from './file-size/file-size.pipe';
import { XpoUploadButton, XpoUploadInput } from './upload-button/index';
import { XpoUploadItem } from './upload-list/upload-item/upload-item.component';
import { XpoUploadList } from './upload-list/upload-list.component';
import { XpoUpload } from './upload.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule, MatProgressBarModule, XpoChipsModule, MatTableModule],
  declarations: [
    XpoDragAndDrop,
    XpoUpload,
    XpoUploadButton,
    XpoUploadInput,
    XpoUploadList,
    XpoUploadItem,
    XpoFileSizePipe,
  ],
  exports: [XpoUpload],
})
export class XpoUploadModule {}
