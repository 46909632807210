import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { XpoCardModule, XpoIconModule, XpoPopoverModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoLtlSicSwitcherComponent } from './sic-switcher.component';

@NgModule({
  declarations: [XpoLtlSicSwitcherComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatInputModule,
    MatListModule,
    MatAutocompleteModule,

    ConfigManagerModule,

    XpoPopoverModule,
    XpoIconModule,
    XpoCardModule,
  ],
  exports: [XpoLtlSicSwitcherComponent],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
  ],
})
export class XpoLtlSicSwitcherModule {}
