import { XpoDateRangeFilterHelper } from './date-range-filter-helper';
import { XpoDateRangeFilterTab } from './date-range-filter-tab';
import { XpoDateRangeGroupByIntervalType } from './date-range-interval';
import { XpoDateRangeIntervalBase } from './date-range-interval-base';
import { XpoQuickDate } from './date-range-quick-date.enum';
import { XpoQuickFilter } from './date-range-quick-filter';

export class XpoDateRangeDaily extends XpoDateRangeIntervalBase {
  get groupByInterval(): XpoDateRangeGroupByIntervalType {
    return 'day';
  }

  get label(): string {
    return 'Daily';
  }

  getQuickFilterOptions(): XpoQuickFilter[] {
    return [
      { code: XpoQuickDate.LastWeek, display: 'Last Week' },
      { code: XpoQuickDate.ThisWeek, display: 'This Week' },
      { code: XpoQuickDate.NextWeek, display: 'Next Week' },
    ];
  }

  getTabModel(): XpoDateRangeFilterTab {
    return {
      pos: this.pos,
      quickFilterOptions: this.getQuickFilterOptions(),
      groupByInterval: this.groupByInterval,
    };
  }

  setNext(nextInput: number): any {
    return XpoDateRangeFilterHelper.constructFilter(XpoQuickDate.TodayPlusDays, nextInput * 1);
  }

  setPrev(prevInput: number): any {
    return XpoDateRangeFilterHelper.constructFilter(XpoQuickDate.TodayMinusDays, prevInput * 1);
  }
}
