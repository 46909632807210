import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

import { XpoIconModule } from '../icon/icon.module';
import { XpoBrowserGuard } from './browser-guard.service';
import { XpoBrowserGuardDialog } from './dialog/browser-guard-dialog.component';

@NgModule({
  imports: [CommonModule, RouterModule, MatButtonModule, MatDialogModule, XpoIconModule],
  exports: [RouterModule],
  providers: [XpoBrowserGuard],
  declarations: [XpoBrowserGuardDialog],
  entryComponents: [XpoBrowserGuardDialog],
})
export class XpoBrowserGuardModule {}
