import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

import { isValidCron } from 'cron-validator';

/* Enums */
import { Frequency } from '../../enums/frequency.enum';

/* XPO */
import { ModuleGroup } from '@xpo-ltl/sdk-moduleexecutor';

/* Rxjs */
import { Subscription } from 'rxjs';

/* Services */
import { ModuleGroupMgmtInfoService } from './services/module-group-mgmt-info.component.service';

/* Models */
import { StepGridItem } from '../../models/step-grid-item.model';
import { ModuleGroupManagementService } from '../../services/module-group-management.service';
import { StartTimeGroup, WeekDays } from './models/start-time-group.model';

/* Validators */
import { validateCron, validateMonthlyCron } from './validators/cron.validator';
import { validateSteps } from './validators/steps.validator';
import { validateWeekDays } from './validators/week.validator';

@Component({
  selector: 'module-group-mgmt-info',
  templateUrl: './module-group-mgmt-info.component.html',
  styleUrls: ['./module-group-mgmt-info.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ModuleGroupMgmtInfoComponent),
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ModuleGroupMgmtInfoComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input('moduleGroup')
  set moduleGroup(moduleGroup: ModuleGroup) {
    if (moduleGroup) {
      this.patchFormsValues(moduleGroup);
    }
  }

  @Output() childFormValueChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  form: FormGroup;

  frequencies: any;
  selectedFrequency: string;
  utcTimer;

  utcCronExpression: string;
  defaultTime: string;
  week: string[];

  steps: StepGridItem[];

  /* Subscriptions */
  subscriptions: Subscription;
  utcTime: string;

  changed(): void {}

  onTouched: () => void = () => {};

  writeValue(v: any): void {
    this.form.setValue(v, { emitEvent: false });
  }

  registerOnChange(fn: (v: any) => void): void {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    disabled ? this.form.disable() : this.form.enable();
  }

  get formControls(): any {
    return this.form ? this.form.controls : null;
  }

  get startGroup() {
    return this.form ? this.form.get('startTimeGroup') : null;
  }

  get weekDaysGroup() {
    return this.form ? this.form.get('startTimeGroup.weekDays') : null;
  }

  constructor(
    private fb: FormBuilder,
    private moduleGroupManagementService: ModuleGroupManagementService,
    private moduleGroupMgmtInfoService: ModuleGroupMgmtInfoService
  ) {
    this.initializeForm();

    this.utcCronExpression = '';
    this.defaultTime = '00:00';
    this.week = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

    this.steps = this.moduleGroupManagementService.getSteps();

    /* Subscriptions */
    this.subscriptions = new Subscription();
  }

  ngOnInit() {
    this.startUTCclock();
    this.setFrequencies();

    /* Important: Checks form changes and emit the form to parent */
    this.onFormValueChange();
    this.registerFormValueChanges();

    this.registerStepsChanges();
    this.registerFrequencyValueChanges();
    this.registerStartGroupValueChanges();

    this.form.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    clearInterval(this.utcTimer);
  }

  private startUTCclock(): void {
    this.utcTimer = setInterval(() => {
      this.utcTime = new Date().toISOString().slice(11, 19);
    }, 1000);
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      moduleName: [null, [Validators.required, Validators.pattern('[a-zA-Z0-9 ._-]*')]],
      frequency: ['Inactive', [validateSteps.bind(this)]],
      startTimeGroup: this.fb.group({
        manualLaunch: [null],
        startTime: [null, [Validators.required]],
        weekDays: this.fb.group(
          {
            MON: [false],
            TUE: [false],
            WED: [false],
            THU: [false],
            FRI: [false],
            SAT: [false],
            SUN: [false],
          },
          { validator: validateWeekDays }
        ),
        customCronExpression: [null, [Validators.required, validateCron]],
        monthlyDays: [null, [Validators.required, validateMonthlyCron]],
      }),
      moduleDescription: null,
      scheduleCronExpression: null,
    });
    this.setStartTimeGroupDisabled();
  }

  private setStartTimeGroupDisabled() {
    this.form.get('startTimeGroup').disable();
  }

  private patchFormsValues(moduleGroup: ModuleGroup): void {
    if (this.form && moduleGroup) {
      const { frequency, startTimeGroup } = this.patchSchedule(moduleGroup.schedule);

      this.selectedFrequency = frequency;

      this.form.patchValue({
        moduleName: moduleGroup.moduleGroupName,
        frequency,
        startTimeGroup: { ...startTimeGroup },
        moduleDescription: moduleGroup.moduleGroupDescription,
        scheduleCronExpression: this.getScheduleCronExpression(startTimeGroup, this.selectedFrequency),
      });
    }
  }

  private getScheduleCronExpression(startTimeGroup: StartTimeGroup, frequency: string): string {
    const cronExpression = this.getCronExpression(startTimeGroup);
    if (cronExpression === 'INVALID') {
      this.utcCronExpression = '';
      return frequency === 'ManualLaunch' ? 'None' : null;
    } else {
      this.utcCronExpression = cronExpression;
      return this.utcCronExpression;
    }
  }

  private isCustomCronExpression(cronExpressionArr: string[]): boolean {
    return (cronExpressionArr[2] !== '*' && cronExpressionArr[4] !== '*') || cronExpressionArr[3] !== '*' ||
      (cronExpressionArr[0] && cronExpressionArr[0].match(/\-|,|\//) && cronExpressionArr[0].match(/\-|,|\//).length > 0) ||
      (cronExpressionArr[1] && cronExpressionArr[1].match(/\-|,|\//) && cronExpressionArr[1].match(/\-|,|\//).length > 0) ||
      (cronExpressionArr[4] && cronExpressionArr[4].match(/\-|\//) && cronExpressionArr[4].match(/\-|\//).length > 0)
  }

  private patchSchedule(schedule: string): any {
    let frequency: string;
    let startTimeGroup: StartTimeGroup;

    // null/undefined schedule means Inactive
    if (schedule) {
      // None schedule is equal to ManualLaunch
      if (schedule === 'None') {
        frequency = 'ManualLaunch';
      } else {
        const localCronExpression = schedule;
        const cronExpressionArr = localCronExpression.split(' ');
        if (cronExpressionArr.length > 0) {
          switch (true) {
            case this.isCustomCronExpression(cronExpressionArr):
              {
                frequency = 'Custom';
                startTimeGroup = { customCronExpression: localCronExpression };
              }
              break;
            case cronExpressionArr[4] !== '*':
              {
                frequency = 'Weekly';
                const { minutes, hours } = this.moduleGroupMgmtInfoService.getTimeFromCron(cronExpressionArr);
                const { weekDays } = this.moduleGroupMgmtInfoService.getWeekDaysFromCron(cronExpressionArr);
                startTimeGroup = { startTime: `${hours}:${minutes}`, weekDays: { ...weekDays } };
              }
              break;
            case cronExpressionArr[2] !== '*':
              {
                frequency = 'Monthly';
                const { minutes, hours } = this.moduleGroupMgmtInfoService.getTimeFromCron(cronExpressionArr);
                startTimeGroup = { startTime: `${hours}:${minutes}`, monthlyDays: cronExpressionArr[2] };
              }
              break;
            default:
              {
                frequency = 'Daily';
                const { minutes, hours } = this.moduleGroupMgmtInfoService.getTimeFromCron(cronExpressionArr);
                startTimeGroup = { startTime: `${hours}:${minutes}` };
              }
          }
        } else {
          frequency = 'Inactive';
        }
      }
    } else {
      frequency = 'Inactive';
    }

    return {
      frequency,
      startTimeGroup,
    };
  }

  private onFormValueChange(): void {
    this.childFormValueChange.emit(this.form);
  }

  private registerStepsChanges(): void {
    const onStepsChangeSubscription = this.moduleGroupManagementService.onStepsChange().subscribe((steps) => {
      this.steps = steps;
      this.form.updateValueAndValidity();
      this.form.get('frequency').markAsTouched();
    });
    this.subscriptions.add(onStepsChangeSubscription);
  }

  private registerFormValueChanges(): void {
    const formValueChangeSubscription = this.form.valueChanges.subscribe(() => {
      this.onFormValueChange();
    });
    this.subscriptions.add(formValueChangeSubscription);
  }

  private registerStartGroupValueChanges(): void {
    const startGroupValueChangeSubscription = this.startGroup.valueChanges.subscribe(
      (startTimeGroup: StartTimeGroup) => {
        this.form.patchValue({
          scheduleCronExpression: this.getScheduleCronExpression(startTimeGroup, this.selectedFrequency),
        });
      }
    );
    this.subscriptions.add(startGroupValueChangeSubscription);
  }

  private registerFrequencyValueChanges(): void {
    const frequencyValueChangeSubscription = this.form.get('frequency').valueChanges.subscribe((frequency) => {
      this.selectedFrequency = frequency;

      switch (this.selectedFrequency) {
        case 'Inactive': {
          this.setStartTimeGroupDisabled();
          break;
        }
        case 'ManualLaunch': {
          this.startGroup.get('manualLaunch').setValue('Runs on demand');
          this.setStartTimeGroupDisabled();
          break;
        }
        case 'Daily': {
          this.startGroup.get('manualLaunch').disable();
          this.startGroup.get('startTime').enable();
          this.startGroup.get('customCronExpression').disable();
          this.startGroup.get('monthlyDays').disable();
          this.startGroup.get('weekDays').disable();
          break;
        }
        case 'Weekly': {
          this.startGroup.get('manualLaunch').disable();
          this.startGroup.get('startTime').enable();
          this.startGroup.get('customCronExpression').disable();
          this.startGroup.get('monthlyDays').disable();
          this.startGroup.get('weekDays').enable();
          break;
        }
        case 'Monthly': {
          this.startGroup.get('manualLaunch').disable();
          this.startGroup.get('startTime').enable();
          this.startGroup.get('customCronExpression').disable();
          this.startGroup.get('monthlyDays').enable();
          this.startGroup.get('weekDays').disable();
          break;
        }
        case 'Custom': {
          this.startGroup.get('manualLaunch').disable();
          this.startGroup.get('startTime').disable();
          this.startGroup.get('customCronExpression').enable();
          this.startGroup.get('monthlyDays').disable();
          this.startGroup.get('weekDays').disable();
          break;
        }
        default:
          this.setStartTimeGroupDisabled();
          break;
      }
      this.form.updateValueAndValidity();
    });
    this.subscriptions.add(frequencyValueChangeSubscription);
  }

  private getCronExpression(startTimeGroup: StartTimeGroup): string {
    let cronExpressionValue = 'INVALID';

    if (startTimeGroup) {
      const startTime = startTimeGroup.startTime || '';
      const weekDays = startTimeGroup.weekDays || new WeekDays();
      const monthlyDays = startTimeGroup.monthlyDays || '*';

      let cronValue = startTimeGroup.customCronExpression || '';

      switch (this.selectedFrequency) {
        case 'Daily': {
          if (startTime) {
            cronExpressionValue = `${+startTime.split(':')[1]} ${+startTime.split(':')[0]} * * *`;
          }
          break;
        }
        case 'Weekly': {
          // Array to track if a weekday was selected
          const weekArr = [];
          Object.keys(weekDays).forEach((wd) => (weekDays[wd] ? weekArr.push(wd) : false));

          const weekExpression = weekArr.length > 0 ? weekArr.join() : '*';
          const timeExpression = startTime ? `${+startTime.split(':')[1]} ${+startTime.split(':')[0]}` : `* *`;

          cronExpressionValue = `${timeExpression} * * ${weekExpression}`;
          break;
        }
        case 'Monthly': {
          const timeExpression = startTime ? `${+startTime.split(':')[1]} ${+startTime.split(':')[0]}` : `* *`;

          cronValue = `${timeExpression} ${monthlyDays.trim()} * *`;

          if (isValidCron(cronValue, { alias: true })) {
            cronExpressionValue = cronValue;
          }
          break;
        }
        case 'Custom': {
          if (cronValue.length > 0 && isValidCron(cronValue, { alias: true })) {
            cronExpressionValue = cronValue;
          }
          break;
        }
        default:
          break;
      }
    }
    return cronExpressionValue;
  }

  private setFrequencies(): void {
    this.frequencies = [];
    this.selectedFrequency = 'Inactive';
    Object.keys(Frequency).map((item) => {
      this.frequencies.push({ value: item, label: Frequency[item] });
    });
  }

  onSelectWeekDay(dayClicked): void {
    const dayControl = this.form.get(`startTimeGroup.weekDays.${dayClicked}`);
    dayControl.setValue(!dayControl.value);
  }
}
