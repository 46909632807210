import { Observable, of as observableOf } from 'rxjs';

import { XpoAuthUser } from '../models/user.model';

/**
 * The purpose of this class is to provide a way for a consumer of this library to hook on to the events
 * that occur in this library to do some processing before/after actions occur.
 */
export abstract class XpoAuthEventHandler {
  /**
   * Before Redirect to STS
   */
  abstract preSignIn(): Observable<any>;

  /**
   * Before redirect back to stored Route
   */
  abstract postSignIn(user: XpoAuthUser): Observable<any>;

  /**
   * Before STS redirect
   */
  abstract preSignOut(user: XpoAuthUser): Observable<any>;

  /**
   * After STS Redirect
   */
  abstract postSignOut(): Observable<any>;

  /**
   * Before silent refresh occurs
   */
  abstract preRefresh(user: XpoAuthUser): Observable<any>;

  /**
   * After silent refresh occurs
   */
  abstract postRefresh(user: XpoAuthUser): Observable<any>;
}

export class XpoAuthDefaultEventHandler extends XpoAuthEventHandler {
  preSignIn(): Observable<any> {
    return observableOf(null);
  }

  postSignIn(user: XpoAuthUser): Observable<any> {
    return observableOf(null);
  }

  preSignOut(user: XpoAuthUser): Observable<any> {
    return observableOf(null);
  }

  postSignOut(): Observable<any> {
    return observableOf(null);
  }

  preRefresh(user: XpoAuthUser): Observable<any> {
    return observableOf(null);
  }

  postRefresh(user: XpoAuthUser): Observable<any> {
    return observableOf(null);
  }
}

export const DefaultAuthEventHandlerProvider = {
  provide: XpoAuthEventHandler,
  useClass: XpoAuthDefaultEventHandler,
};
