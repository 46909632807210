import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { XpoLtlGlobalSearchComponent } from './global-search.component';
import { GlobalSearchResponseTypePipe } from './global-search-response-type.pipe';
import { XpoLtlPipeModule } from '../pipes/pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [XpoLtlGlobalSearchComponent, GlobalSearchResponseTypePipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatInputModule,
    MatIconModule,
    MatListModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatTooltipModule,

    ConfigManagerModule,

    XpoLtlPipeModule,
  ],
  exports: [XpoLtlGlobalSearchComponent, GlobalSearchResponseTypePipe],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
  ],
})
export class XpoLtlGlobalSearchModule {}
