import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { XpoAccountPopoverModule } from '../account-popover/account-popover.module';
import { XpoAppSwitcherPopoverModule } from '../app-switcher-popover/app-switcher-popover.module';
import { XpoHeaderModule } from '../header/index';
import { XpoNotificationPopoverModule } from '../notification-popover/notification-popover.module';
import { XpoTabDrawerModule } from '../tab-drawer/index';
import { XpoShell, XpoShellSidebarActions } from './shell.component';
@NgModule({
  declarations: [XpoShell, XpoShellSidebarActions],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    RouterModule.forChild([]),
    XpoHeaderModule,
    XpoTabDrawerModule,
    XpoAppSwitcherPopoverModule,
    XpoAccountPopoverModule,
    XpoNotificationPopoverModule,
  ],
  exports: [
    XpoShell,
    XpoHeaderModule,
    XpoShellSidebarActions,
    XpoAppSwitcherPopoverModule,
    XpoAccountPopoverModule,
    XpoNotificationPopoverModule,
  ],
  providers: [],
})
export class XpoShellModule {}
