import {InvoicePostDeliveryStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoicePostDeliveryStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoicePostDeliveryStatusCd ) {
      this._values.push(InvoicePostDeliveryStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoicePostDeliveryStatusCd {

    for ( const obj in InvoicePostDeliveryStatusCd ) {
      if (InvoicePostDeliveryStatusCd[obj] === value){
        return InvoicePostDeliveryStatusCd[obj] as InvoicePostDeliveryStatusCd;
      }
    }
  }
}

const InvoicePostDeliveryStatusCdHelper = new InvoicePostDeliveryStatusCdHelperEnumHelperClass();
export default InvoicePostDeliveryStatusCdHelper;
