import {
  XpoBoardViewTemplate,
  XpoBoardViewTemplateConfig,
  XpoGridBoardView,
  XpoGridBoardViewConfig,
} from '@xpo-ltl/ngx-ltl-board';

import { XpoAgGridBoardColumn } from './ag-grid-board-column';

// Could be converted to an interface when more configs are applied
export type XpoAgGridBoardViewConfig = XpoGridBoardViewConfig;

export class XpoAgGridBoardView extends XpoGridBoardView<XpoAgGridBoardViewConfig> {
  constructor(template: XpoAgGridBoardViewTemplate, config: XpoAgGridBoardViewConfig) {
    super(template, config);
  }
}

export interface XpoAgGridBoardViewTemplateConfig extends XpoBoardViewTemplateConfig {
  availableColumns?: XpoAgGridBoardColumn[];
}

export class XpoAgGridBoardViewTemplate extends XpoBoardViewTemplate {
  readonly availableColumns: XpoAgGridBoardColumn[];

  constructor(config: XpoAgGridBoardViewTemplateConfig) {
    super(config);

    this.availableColumns = config.availableColumns || [];
  }

  createView(config: XpoAgGridBoardViewConfig): XpoAgGridBoardView {
    this.assignUniqueId(config);
    return new XpoAgGridBoardView(this, { templateId: this.id, ...config });
  }
}
