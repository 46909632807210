import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';

import { XpoMultiSelectAutocompleteFilter } from '../multi-select-autocomplete-filter';

@Component({
  selector: 'xpo-multi-select-autocomplete-filter-selected-options',
  templateUrl: './multi-select-autocomplete-filter-selected-options.component.html',
  styleUrls: ['./multi-select-autocomplete-filter-selected-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-MultiSelectAutocompleteFilterSelectedOptions' },
})
export class XpoMultiSelectAutoCompleteFilterSelectedOptionsComponent implements OnInit {
  selectedOptions$: Observable<any[]>;

  @Input()
  config: XpoMultiSelectAutocompleteFilter;

  @Output()
  optionRemoved = new EventEmitter();

  removeOption(option: any): void {
    this.optionRemoved.emit(option.chip.value);
  }

  ngOnInit(): void {
    this.selectedOptions$ = this.config.selectedOptions$;
  }
}
