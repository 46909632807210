import {PnDRoleCd} from '../';
import {EnumHelper} from './enum-helper';

export class PnDRoleCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PnDRoleCd ) {
      this._values.push(PnDRoleCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PnDRoleCd {

    for ( const obj in PnDRoleCd ) {
      if (PnDRoleCd[obj] === value){
        return PnDRoleCd[obj] as PnDRoleCd;
      }
    }
  }
}

const PnDRoleCdHelper = new PnDRoleCdHelperEnumHelperClass();
export default PnDRoleCdHelper;
