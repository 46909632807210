import {PickupShipmetActionCd} from '../';
import {EnumHelper} from './enum-helper';

export class PickupShipmetActionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PickupShipmetActionCd ) {
      this._values.push(PickupShipmetActionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PickupShipmetActionCd {

    for ( const obj in PickupShipmetActionCd ) {
      if (PickupShipmetActionCd[obj] === value){
        return PickupShipmetActionCd[obj] as PickupShipmetActionCd;
      }
    }
  }
}

const PickupShipmetActionCdHelper = new PickupShipmetActionCdHelperEnumHelperClass();
export default PickupShipmetActionCdHelper;
