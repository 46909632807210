import {EquipmentTypeDetailCd} from '../';
import {EnumHelper} from './enum-helper';

export class EquipmentTypeDetailCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EquipmentTypeDetailCd ) {
      this._values.push(EquipmentTypeDetailCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EquipmentTypeDetailCd {

    for ( const obj in EquipmentTypeDetailCd ) {
      if (EquipmentTypeDetailCd[obj] === value){
        return EquipmentTypeDetailCd[obj] as EquipmentTypeDetailCd;
      }
    }
  }
}

const EquipmentTypeDetailCdHelper = new EquipmentTypeDetailCdHelperEnumHelperClass();
export default EquipmentTypeDetailCdHelper;
