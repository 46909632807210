import {ProcessStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ProcessStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ProcessStatusCd ) {
      this._values.push(ProcessStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ProcessStatusCd {

    for ( const obj in ProcessStatusCd ) {
      if (ProcessStatusCd[obj] === value){
        return ProcessStatusCd[obj] as ProcessStatusCd;
      }
    }
  }
}

const ProcessStatusCdHelper = new ProcessStatusCdHelperEnumHelperClass();
export default ProcessStatusCdHelper;
