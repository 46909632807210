import {MetricStageSubCd} from '../';
import {EnumHelper} from './enum-helper';

export class MetricStageSubCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MetricStageSubCd ) {
      this._values.push(MetricStageSubCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MetricStageSubCd {

    for ( const obj in MetricStageSubCd ) {
      if (MetricStageSubCd[obj] === value){
        return MetricStageSubCd[obj] as MetricStageSubCd;
      }
    }
  }
}

const MetricStageSubCdHelper = new MetricStageSubCdHelperEnumHelperClass();
export default MetricStageSubCdHelper;
