import { Provider } from '@angular/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';

export const rippleConfig: RippleGlobalOptions = {
  disabled: true,
};

export const rippleConfigFactory: Function = () => {
  console.warn('WARN: xpoRippleConfig is deprecated: Please import the XpoCommonModule instead');

  return rippleConfig;
};

/**
 * @deprecated import the XpoCommonModule instead.
 */
export const xpoRippleConfig: Provider = {
  provide: MAT_RIPPLE_GLOBAL_OPTIONS,
  useFactory: rippleConfigFactory,
};
