import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { XpoDatePickerCustomHeaderComponent } from './components/custom-header/datepicker-custom-header.component';
import { XpoDatePickerDirective } from './directives/datepicker-date.directive';
import { XpoMonthPickerViewDirective } from './directives/datepicker-month.directive';
import { XpoYearPickerDirective } from './directives/datepicker-year.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
  ],
  entryComponents: [XpoDatePickerCustomHeaderComponent],
  exports: [XpoDatePickerDirective, XpoMonthPickerViewDirective, XpoYearPickerDirective],
  declarations: [
    XpoDatePickerCustomHeaderComponent,
    XpoDatePickerDirective,
    XpoMonthPickerViewDirective,
    XpoYearPickerDirective,
  ],
})
export class XpoDatePickerModule {}
