import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConditioningService, FormatValidationService } from '@xpo-ltl/common-services';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ApiRequest } from '@xpo-ltl/data-api';
import {
  DocumentCdt,
  DocumentManagementApiService,
  GetDocumentPath,
  GetDocumentResp,
  RetrieveDmsAuthTokenResp,
  RetrieveDmsAuthTokenRqst,
  SearchDmsDocumentResp,
  SearchDmsDocumentRqst,
} from '@xpo-ltl/sdk-documentmanagement';
import { castArray as _castArray, findKey as _findKey, get as _get } from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ConfigManagerProperties } from '../config-manager-properties.enum';
import { XpoLtlWindowService } from '../services/window/window.service';
import { XpoLtlDocType } from './doc-type.enum';
import { XpoLtlDocumentViewerDialogConfig } from './document-viewer-dialog/document-viewer-dialog-config';
import { XpoLtlDocumentViewerDialogComponent } from './document-viewer-dialog/document-viewer-dialog.component';

@Injectable({ providedIn: 'root' })
export class XpoLtlDocumentService {
  private cachedToken: { tokenResponse: RetrieveDmsAuthTokenResp; expires: Date };

  constructor(
    private configManager: ConfigManagerService,
    private documentManagementService: DocumentManagementApiService,
    private conditioningService: ConditioningService,
    private formatValidationService: FormatValidationService,
    private windowRef: XpoLtlWindowService,
    private dialog: MatDialog
  ) {}

  /**
   * Return list of all document that match the proNbr or trailerNbr and search criteria
   */
  listAvailableDocuments(
    searchString: string,
    minDate?: Date,
    maxDate?: Date,
    searchTags?: string[],
    isTrailerNbr: boolean = false
  ): Observable<SearchDmsDocumentResp> {
    if (!isTrailerNbr && (!searchString || !this.formatValidationService.isValidProNumber(searchString))) {
      throw new Error(`A valid PRO is required: ${searchString}`);
    } else if (isTrailerNbr && (!searchString || !this.formatValidationService.isValidTrailerNumber(searchString))) {
      throw new Error(`A valid trailer number is required: ${searchString}`);
    }

    return this.getDmsAuthToken().pipe(
      switchMap((token: RetrieveDmsAuthTokenResp) => {
        const request = new SearchDmsDocumentRqst();
        request.corpCode = this.configManager.getSetting(ConfigManagerProperties.imageCorpCode);
        request.dmsAuth = _get(token, 'access_token');
        if (minDate) {
          request.minDateTime = minDate.toISOString();
        }
        if (maxDate) {
          request.maxDateTime = maxDate.toISOString();
        }
        if (searchTags) {
          request.searchTags = searchTags;
        }

        request.searchString = !isTrailerNbr
          ? this.conditioningService.conditionProNumber(searchString, 9)
          : searchString.trim();

        return this.documentManagementService
          .searchDmsDocument(request, ApiRequest.concealedCall, { headers: { DMSAuth: request.dmsAuth } })
          .pipe(
            map((value) => (value['searchDmsDocumentResp'] ? value['searchDmsDocumentResp'] : value)),
            map((value: SearchDmsDocumentResp) => {
              if (value) {
                value.documentInfo = value.documentInfo.filter((docInfo) => {
                  if (docInfo && docInfo.cdt && docInfo.cdt.docClass) {
                    const en = _findKey(XpoLtlDocType, (v) => v === docInfo.cdt.docClass);
                    return !!en;
                  }
                });
              }
              return value;
            })
          );
      })
    );
  }

  getDocument(
    docTimestamp: string,
    docClass: string,
    corpCode: string,
    docFormat: string = 'pdf'
  ): Observable<GetDocumentResp> {
    return this.getDmsAuthToken().pipe(
      switchMap((token: RetrieveDmsAuthTokenResp) => {
        const params = new GetDocumentPath();
        params.docArchiveTimestamp = docTimestamp;
        params.docClass = docClass;
        params.docFormat = docFormat;
        params.corpCode = corpCode;
        return this.documentManagementService.getDocument(
          params,
          undefined,
          { loadingOverlayEnabled: false },
          {
            headers: {
              dmsAuth: token.access_token,
            },
          }
        );
      })
    );
  }

  /**
   * Open Document(s) in a new DocView browser tab
   * @param pro
   * @param referenceNumber
   * @param docType
   * @param minDate
   * @param menuUpAllDocs
   * @param windowTarget
   */
  navigateToDocViewApp(
    pro: string,
    referenceNumber: string,
    docType: XpoLtlDocType,
    minDate: string,
    menuUpAllDocs: boolean,
    windowTarget?: string
  ) {
    const context = this.webContextRoot();
    const nativeWindow = this.windowRef.nativeWindow;
    const protocol = nativeWindow.location.protocol;
    const host = nativeWindow.location.hostname;
    const port = nativeWindow.location.port;

    const url = `${this.appendUrlParts(
      `${protocol}//${host}:${port}`,
      context,
      docType,
      pro,
      referenceNumber
    )}?minDate=${minDate}?menuUpAllDocs=${menuUpAllDocs}`;

    nativeWindow.open(url, windowTarget || '_blank');
  }

  showDocumentsDialog(
    documentDesc: DocumentCdt,
    config?: XpoLtlDocumentViewerDialogConfig,
    dialogConfig?: MatDialogConfig
  ): Observable<void> {
    const subject = new Subject<void>();

    const matDialogConfig = {
      width: '1232px',
      height: '98%',
      panelClass: 'xpo-LtlDocumentViewerDialog__panel',
      disableClose: true,
      hasBackdrop: !_get(config, 'moveable'),
      ...(dialogConfig || {}),
      data: {
        shipmentDescriptors: _castArray(documentDesc),
        config,
      },
    };

    const dialogRef = this.dialog.open(XpoLtlDocumentViewerDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      subject.next();
      subject.complete();
    });

    return subject.asObservable();
  }

  private _appendUrlParts(part1: string, part2: string): string {
    if (!part1) {
      return !!part2 ? part2 : '';
    }
    if (!part2) {
      return !!part1 ? part1 : '';
    }

    const conditionedPart1 = `${part1}${part1.endsWith('/') ? '' : '/'}`;
    const conditionedPart2 = `${part2.startsWith('/') ? part2.substring(1) : part2}`;
    return `${conditionedPart1}${conditionedPart2}`;
  }
  private appendUrlParts(...parts: string[]): string {
    let stitched = '';
    if (parts && parts.length > 0) {
      parts.forEach((part: string) => {
        stitched = this._appendUrlParts(stitched, part);
      });
    }
    return stitched;
  }

  private webContextRoot(): string {
    const context = this.configManager.getSetting<string>(ConfigManagerProperties.docViewWebContext);
    return context || '/appjs/docview/';
  }

  private getDmsAuthToken(): Observable<RetrieveDmsAuthTokenResp> {
    return this.cachedToken && this.cachedToken.expires.getTime() > Date.now()
      ? of(this.cachedToken.tokenResponse)
      : this.documentManagementService
          .retrieveDmsAuthToken({} as RetrieveDmsAuthTokenRqst, { loadingOverlayEnabled: false })
          .pipe(
            tap((resp) => {
              this.cachedToken = {
                tokenResponse: resp,
                expires: new Date(Date.now() + _get(resp, 'expires_in', 0) * 1000),
              };
            })
          );
  }
}
