
export enum ClaimDecisionSubCategoryCd {
	AT_DESTINATION_FROM_FAC = 'AtDestinationFromFac',
	AT_DESTINATION_SIC = 'AtDestinationSic',
	AT_FAC = 'AtFac',
	AT_FAC_FROM_ORIGIN_SIC = 'AtFacFromOriginSic',
	AT_FAC_FROM_PRIOR_FAC = 'AtFacFromPriorFac',
	AT_ORIGIN_SC = 'AtOriginSc',
	AUTO_APPROVED = 'AutoApproved',
	CANCELLED_BY_CUSTOMER = 'CancelledByCustomer',
	CARRIER_LIABILITY_NOT_ESTABLISHED = 'CarrierLiabilityNotEstablished',
	CLAIMANT_REFUSED_TO_AMEND_CLAIM = 'ClaimantRefusedToAmendClaim',
	COMPROMISE_SETTLEMENT = 'CompromiseSettlement',
	CONTAMINATION = 'Contamination',
	DIRECTIONAL_MARKINGS_NOT_FOLLOWED = 'DirectionalMarkingsNotFollowed',
	DOMESTIC_FORWARDER_CLAIM_NOT_FILED_WITHIN_90_DAYS = 'DomesticForwarderClaimNotFiledWithin90Days',
	DSR_UNDER_SHIPPEROR_CONSIGNEE_SUPERVISION = 'DsrUnderShipperorConsigneeSupervision',
	DUPLICATE_CLAIM = 'DuplicateClaim',
	EXCEPTION_NOTED_AT_FIRST_CHECKPOINT = 'ExceptionNotedAtFirstCheckpoint',
	EXCEPTION_NOTED_ON_BILL_OF_LADING = 'ExceptionNotedOnBillOfLading',
	FAC = 'Fac',
	FORKLIFT = 'Forklift',
	FREEZING = 'Freezing',
	FREIGHT_RECOVERED = 'FreightRecovered',
	FREIGHT_RETURNED_ON_NON_REVENUE_ORDER = 'FreightReturnedOnNonRevenueOrder',
	FREIGHT_UNPROTECTED = 'FreightUnprotected',
	HEAT = 'Heat',
	IMPROPER_BLOCKING = 'ImproperBlocking',
	IMPROPER_STACKING = 'ImproperStacking',
	INTL_FORWARDER_CLAIM_NOT_FILED_WITHIN_120_DAYS = 'IntlForwarderClaimNotFiledWithin120Days',
	INTL_FORWARDER_INTENT_NOT_WITHIN_7_DAYS_FROM_DLVY = 'IntlForwarderIntentNotWithin7DaysFromDlvy',
	LIABILITY_NOT_MITIGATED_BY_CLAIMANT = 'LiabilityNotMitigatedByClaimant',
	LOADED_HEAVY_FREIGHT_ON_LIGHT_FREIGHT = 'LoadedHeavyFreightOnLightFreight',
	LOST_PROFIT_INCOME_ATTY_FEE_CONSEQUENTIAL_DMG = 'LostProfitIncomeAttyFeeConsequentialDmg',
	MERCHANDISE_NOT_IN_SAME_CONDITION_AS_DELIVERED = 'MerchandiseNotInSameConditionAsDelivered',
	MISDELIVERY = 'Misdelivery',
	MISHANDLING = 'Mishandling',
	NOCLAIMS_UNDER_SPECIFIC_DOLLAR_THRESHOLD = 'NoclaimsUnderSpecificDollarThreshold',
	NOT_HELD_FOR_INSPECTION_OR_SALVAGE = 'NotHeldForInspectionOrSalvage',
	NO_3_PL_SUPPORT = 'No3PLSupport',
	NO_FORKLIFT_EXTENSION = 'NoForkliftExtension',
	NO_FORWARDER_SUPPORT = 'NoForwarderSupport',
	NO_PROOF_OF_LOSS = 'NoProofOfLoss',
	NO_RECORD_OF_RECEIPT_AND_OR_HANDLING = 'NoRecordOfReceiptAndOrHandling',
	NO_REPAIR_INVOICE = 'NoRepairInvoice',
	NO_STRAPS = 'NoStraps',
	NO_WEIGHT_PROVIDED = 'NoWeightProvided',
	OCEAN_AND_NOT_WITHIN_ONE_YR_OF_DLVY_DATE = 'OceanAndNotWithinOneYrOfDlvyDate',
	ONLY_THE_SHIPPER_WILL_CLAIM_VS_CUSTOMER = 'OnlyTheShipperWillClaimVsCustomer',
	ON_DELIVERY_AT_DESTINATION_SIC = 'OnDeliveryAtDestinationSic',
	OPEN_CARTON_AT_DELIVERY_OR_NO_INSPECTION_BY_DSR = 'OpenCartonAtDeliveryOrNoInspectionByDsr',
	ORIGINAL_INVOICE_NOT_PROVIDED = 'OriginalInvoiceNotProvided',
	ORIGINATED_IN_CANADA_NOT_WITHIN_60_DAYS_OF_DLVY = 'OriginatedInCanadaNotWithin60DaysOfDlvy',
	ORIGINATED_IN_US_NOT_WITHIN_9_MONTHS_OF_DLVY = 'OriginatedInUsNotWithin9MonthsOfDlvy',
	ORIGIN_PICKUP_FREIGHT_IMPROPERLY_PACKAGED = 'OriginPickupFreightImproperlyPackaged',
	ORIGIN_SC = 'OriginSc',
	OVER_DELIVERY = 'OverDelivery',
	PAPER_BAG_DAMAGE = 'PaperBagDamage',
	PART_SHORTAGE_LOCATED_OR_DELIVERED = 'PartShortageLocatedOrDelivered',
	PER_SHIPPER_DIRECTIONSCLAIMS_TO_BE_DECLINED = 'PerShipperDirectionsclaimsToBeDeclined',
	POLICY_PAY_DAMAGE = 'PolicyPayDamage',
	POLICY_PAY_LOSS = 'PolicyPayLoss',
	REFER_TO_SHIPPER_FOR_CREDIT_AND_OR_REPLACEMENT = 'ReferToShipperForCreditAndOrReplacement',
	REQUESTED_DOCUMENTATION_NOT_PROVIDED = 'RequestedDocumentationNotProvided',
	RETURNED_TO_SHIPPER = 'ReturnedToShipper',
	SAFE_STACK_IMPROPERLY_INSTALLED = 'SafeStackImproperlyInstalled',
	SHIPPER_LOAD_AND_COUNT_DESTINATION_VIOLATION = 'ShipperLoadAndCountDestinationViolation',
	SHORT_DUE_TO_BILLING_ERROR = 'ShortDueToBillingError',
	SHORT_OFF_DOCK = 'ShortOffDock',
	SHORT_OFF_DROP_OR_TRAP_TRAILER = 'ShortOffDropOrTrapTrailer',
	SHORT_OFF_PICKUP_AND_DELIVERY_UNIT = 'ShortOffPickupAndDeliveryUnit',
	SHORT_OPEN_OR_DAMAGED = 'ShortOpenOrDamaged',
	SHRINK_WRAP_INTACT = 'ShrinkWrapIntact',
	UNDETERMINED = 'Undetermined',
	VAGUE_DELIVERY_EXCEPTION = 'VagueDeliveryException',
	WATER = 'Water',
	WRECK_AND_FIRE = 'WreckAndFire'}

