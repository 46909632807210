import {HandlingUnitMovementTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class HandlingUnitMovementTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in HandlingUnitMovementTypeCd ) {
      this._values.push(HandlingUnitMovementTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): HandlingUnitMovementTypeCd {

    for ( const obj in HandlingUnitMovementTypeCd ) {
      if (HandlingUnitMovementTypeCd[obj] === value){
        return HandlingUnitMovementTypeCd[obj] as HandlingUnitMovementTypeCd;
      }
    }
  }
}

const HandlingUnitMovementTypeCdHelper = new HandlingUnitMovementTypeCdHelperEnumHelperClass();
export default HandlingUnitMovementTypeCdHelper;
