import { Pipe, PipeTransform } from '@angular/core';

import { XpoAvailableViewItem } from './available-views.component';

@Pipe({
  name: 'xpoAvailableViewsFilter',
})
export class XpoAvailableViewsFilter implements PipeTransform {
  transform(value: XpoAvailableViewItem[], term: string): XpoAvailableViewItem[] {
    if (value && value.length && term) {
      return value.filter((x) => x.name && x.name.toLowerCase().indexOf(term.toLowerCase()) !== -1);
    }

    return value;
  }
}
