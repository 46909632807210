import { Component, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import { xpoDateFormatter } from '../../../../../../formatters';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { filterFormats, Operators, OperatorText, XpoFilterConditionCriteria } from './../../../../../models';

@Component({
  templateUrl: './before-same.component.html',
  styleUrls: ['../../validator.scss'],
})
export class XpoConditionDateBeforeSameComponent extends XpoConditionBase implements XpoFilterCondition {
  static text: string = OperatorText.OnOrBefore;
  static operator = Operators.OnOrBefore;

  isNegative = false;
  model: string = '';

  @Output() inputChanged = new EventEmitter();

  constructor() {
    super(filterFormats.default);
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    if (!cellValue) {
      return false;
    }

    return moment(cellValue)
      .startOf('day')
      .isSameOrBefore(moment(this.model).startOf('day'));
  }
  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.OnOrBefore,
      value: this.model,
      display: xpoDateFormatter({ value: moment(this.model).startOf('day') }),
    };
  }
  onInputChange(): any {
    this.validateFormat();
    this.inputChanged.emit(this.model);
  }
}
