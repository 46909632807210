import {LineItemChargeToCd} from '../';
import {EnumHelper} from './enum-helper';

export class LineItemChargeToCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LineItemChargeToCd ) {
      this._values.push(LineItemChargeToCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LineItemChargeToCd {

    for ( const obj in LineItemChargeToCd ) {
      if (LineItemChargeToCd[obj] === value){
        return LineItemChargeToCd[obj] as LineItemChargeToCd;
      }
    }
  }
}

const LineItemChargeToCdHelper = new LineItemChargeToCdHelperEnumHelperClass();
export default LineItemChargeToCdHelper;
