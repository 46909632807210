import {PickupStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class PickupStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PickupStatusCd ) {
      this._values.push(PickupStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PickupStatusCd {

    for ( const obj in PickupStatusCd ) {
      if (PickupStatusCd[obj] === value){
        return PickupStatusCd[obj] as PickupStatusCd;
      }
    }
  }
}

const PickupStatusCdHelper = new PickupStatusCdHelperEnumHelperClass();
export default PickupStatusCdHelper;
