import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { XpoTileRibbonHeaderModel } from './models/tile-ribbon-header.model';

@Component({
  selector: 'xpo-tile-ribbon-header',
  templateUrl: './tile-ribbon-header.component.html',
  styleUrls: ['./tile-ribbon-header.component.scss'],
  host: { class: 'xpo-TileRibbon-header' },
})
export class XpoTileRibbonHeaderComponent implements OnInit {
  @Input() header: XpoTileRibbonHeaderModel;

  @Output() toggle: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {
    if (this.header.showToggle === undefined) {
      this.header.showToggle = true;
    }

    if (this.header.toggle === undefined) {
      this.header.toggle = {};
    }

    if (!this.header.toggle.showLabel) {
      this.header.toggle.showLabel = 'Show';
    }

    if (!this.header.toggle.hideLabel) {
      this.header.toggle.hideLabel = 'Hide';
    }

    if (this.header.toggle.showTiles === undefined) {
      this.header.toggle.showTiles = true;
    }
  }

  onClickToggle(): void {
    this.header.toggle.showTiles = !this.header.toggle.showTiles;
    this.toggle.emit();
  }
}
