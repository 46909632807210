import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { XpoStatusIndicator } from './status-indicator.component';

@NgModule({
  declarations: [XpoStatusIndicator],
  imports: [CommonModule, MatIconModule],
  exports: [XpoStatusIndicator],
})
export class XpoStatusIndicatorModule {}
