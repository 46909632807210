import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  HostListener,
  OnDestroy,
  AfterViewInit,
  Input,
  ElementRef,
} from '@angular/core';
import {
  first as _first,
  defaultTo as _defaultTo,
  get as _get,
  size as _size,
  filter as _filter,
  uniqWith as _uniqWith,
  isEqual as _isEqual,
  some as _some,
  has as _has,
  cloneDeep as _cloneDeep,
} from 'lodash';
import { BehaviorSubject, Observable, timer, Subject } from 'rxjs';
import { DisputeTypeCd, GlobalSearchDetailCd } from '@xpo-ltl/sdk-common';
import { FormControl } from '@angular/forms';
import { takeUntil, distinctUntilChanged, map, debounceTime, take, filter, tap, switchMap } from 'rxjs/operators';
import { Unsubscriber } from '../classes';
import {
  PerformAppGlobalSearchResp,
  ShipmentSearchResult,
  DisputeSearchResult,
  ClaimSearchResult,
  InvoiceSearchResult,
  AppMetadataApiService,
  PerformAppGlobalSearchPath,
  PerformAppGlobalSearchQuery,
} from '@xpo-ltl/sdk-appmetadata';
import { GlobalSearchResultType } from './global-search-result-type.enum';
import { Router } from '@angular/router';
import { GlobalSearchAppURI } from './global-search-app-uri.enum';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../config-manager-properties.enum';
import { ConditioningService } from '@xpo-ltl/common-services';
import { XpoLtlFormatValidationService } from '../services/format-validation/format-validation.service';
import { XpoLtlWindowService } from '../services/window/window.service';
import { GlobalSearchAppName } from './global-search-app-name.enum';
import { MatInput } from '@angular/material/input';
import { MatAutocomplete, MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

export interface LocalPerformAppGlobalSearchResp extends PerformAppGlobalSearchResp {
  primaryAppGlobalSearchResult?: PrimaryAppGlobalSearchResult;
}

export interface PrimaryAppGlobalSearchResult {
  searchResult: ShipmentSearchResult[] | DisputeSearchResult[] | ClaimSearchResult[] | InvoiceSearchResult[];
  key: string;
}

export interface LocalGlobalSearchResponseItem {
  globalSearchResultType: GlobalSearchResultType;
  searchResult: ShipmentSearchResult | DisputeSearchResult | ClaimSearchResult | InvoiceSearchResult;
}

export enum GlobalSearchLocalStorageDataType {
  RecentSearchList = 'LTLGlobalSearch.RecentSearchList',
}

@Component({
  selector: 'xpo-ltl-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class XpoLtlGlobalSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatInput) searchInput: MatInput;
  @ViewChild('searchInput') searchInputElement: ElementRef;
  @ViewChild(MatAutocomplete) searchAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) searchAutocompleteTrigger: MatAutocompleteTrigger;

  @Input() activateGlobalSearchKeyCode: string = 's';
  get activateGlobalSearchKeyCodeString(): string {
    return this.activateGlobalSearchKeyCode.toUpperCase();
  }

  private unsubscriber = new Unsubscriber();
  private OPEN_PANEL_OVERLAY_WIDTH = 600;
  private currentAppName: string;
  private previousSearchKey: string;
  private selectedGlobalSearchItem: LocalGlobalSearchResponseItem;
  private lastAutocompletePanleScrollPosition: number = 0;
  private resetInputTimer$ = new Subject();

  readonly GlobalSearchResultType = GlobalSearchResultType;
  readonly DisputeTypeCd = DisputeTypeCd;

  isProSearch: boolean = false;
  openExternalPageOnClick: boolean = false;
  searchControl: FormControl;

  private isFocusedSubject = new BehaviorSubject<boolean>(false);
  isFocused$ = this.isFocusedSubject.asObservable();
  get isFocused(): boolean {
    return this.isFocusedSubject.value;
  }
  set isFocused(focused: boolean) {
    this.isFocusedSubject.next(focused);
  }

  private globalSearchResponseSubject = new BehaviorSubject<LocalPerformAppGlobalSearchResp>(undefined);
  globalSearchResponse$ = this.globalSearchResponseSubject.asObservable();
  get globalSearchResponse(): LocalPerformAppGlobalSearchResp {
    return this.globalSearchResponseSubject.value;
  }
  set globalSearchResponse(searchResponse: LocalPerformAppGlobalSearchResp) {
    this.globalSearchResponseSubject.next(searchResponse);
  }

  private isPerformingGlobalSearchSubject = new BehaviorSubject<boolean>(false);
  isPerformingGlobalSearch$ = this.isPerformingGlobalSearchSubject.asObservable();
  get isPerformingGlobalSearch(): boolean {
    return this.isPerformingGlobalSearchSubject.value;
  }

  private showInvalidSearchSubject = new BehaviorSubject<boolean>(false);
  showInvalidSearch$ = this.showInvalidSearchSubject.asObservable();

  private recentGlobalSearchListSubject = new BehaviorSubject<Array<LocalGlobalSearchResponseItem>>([]);
  recentGlobalSearchList$ = this.recentGlobalSearchListSubject.asObservable();
  get recentGlobalSearchList(): Array<LocalGlobalSearchResponseItem> {
    return this.recentGlobalSearchListSubject.value;
  }

  constructor(
    private formatService: XpoLtlFormatValidationService,
    private conditioningService: ConditioningService,
    private windowService: XpoLtlWindowService,
    private configManagerService: ConfigManagerService,
    private appMetadataApiService: AppMetadataApiService,
    private elRef: ElementRef,
    private router: Router
  ) {}

  ngOnInit() {
    this.currentAppName = (
      this.configManagerService.getSetting<string>(ConfigManagerProperties.appName) || ''
    ).toLowerCase();

    this._initializeSearchControl();
    this._setRecentSearchList();

    console.warn(
      'The global search component has been deprecated. Please use component from xpo-ltl/ngx-ltl-global-search.'
    );
  }

  ngAfterViewInit() {
    this._setOverlayWidth();
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    if (event.altKey && event.code === `Key${this.activateGlobalSearchKeyCode.toUpperCase()}`) {
      event.preventDefault();
      this.focus();
    }

    if (event.ctrlKey && this.isFocused) {
      this.openExternalPageOnClick = true;
    }
  }

  @HostListener('document:keyup', ['$event'])
  onKeyupHandler(event: KeyboardEvent): void {
    if (event.key && event.key.toLowerCase() === 'control' && this.isFocused) {
      this.openExternalPageOnClick = false;
    }
  }

  @HostListener('document:click', ['$event'])
  onClickHandler($event: MouseEvent): void {
    if (
      this.isFocused &&
      !this.elRef.nativeElement.contains($event.target) &&
      !_get($event.target, 'className', '').includes('xpo-LtlGlobalSearch')
    ) {
      this.blur();
      this._resetLastScrollPosition();
    }
  }

  // #region PUBLIC API

  /**
   * Set the focus to the input control
   */
  focus(): void {
    this.searchInput.focus();
    this.searchInputElement.nativeElement.setSelectionRange(0, this.searchInput.value.length);
  }

  /**
   * Blurs the focus to the input control
   */
  blur(): void {
    if (this.isFocused) {
      this.isFocused = false;
      this.searchInputElement.nativeElement.blur();
    }
  }

  /**
   * Sets the search input value to the global search input control
   */

  setGlobalSearchInputValue(inputValue: string): void {
    this.searchControl.setValue(inputValue);
  }

  // #endregion PUBLIC AIP

  // #region PRIVATE API

  private _initializeSearchControl(): void {
    this.searchControl = new FormControl('');
    this._setControlWatcher();
    this._setInputDelayTimer();
  }

  private _setScrollWatcher(): void {
    this.searchAutocomplete.opened.pipe(debounceTime(25), takeUntil(this.searchAutocomplete.closed)).subscribe(() => {
      if (
        this.searchAutocomplete.panel &&
        this.searchAutocomplete.panel.nativeElement.scrollTop !== this.lastAutocompletePanleScrollPosition
      ) {
        this.searchAutocomplete.panel.nativeElement.scrollTop = this.lastAutocompletePanleScrollPosition;
      }
    });
  }

  private _setControlWatcher(): void {
    this.searchControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(() => this.showInvalidSearchSubject.next(false)),
        debounceTime(100),
        filter((val) => typeof val === 'string'),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((searchKey: string) => {
        const normalizedSearchKey = this._normalizeSearchKey(searchKey);

        if (this._matchesPreviousSearchKey(normalizedSearchKey)) {
          return;
        }

        if (this._canPerformGlobalSearch(normalizedSearchKey)) {
          this._performGlobalSearch(normalizedSearchKey)
            .pipe(take(1))
            .subscribe(() => {});
        } else {
          this.globalSearchResponse = undefined;
          this.previousSearchKey = undefined;
          this._resetLastScrollPosition();
          this._resetInputTimer();
        }
      });
  }

  private _setInputDelayTimer(): void {
    this.resetInputTimer$
      .pipe(
        filter(() => !!this.searchInput.value),
        switchMap(() => timer(1500))
      )
      .subscribe(() => {
        this.showInvalidSearchSubject.next(
          !this.isPerformingGlobalSearch && !this.globalSearchResponse && !!this.searchControl.value
        );
      });
  }

  private _resetInputTimer(): void {
    this.resetInputTimer$.next();
  }

  private _performGlobalSearch(searchTxt: string): Observable<void> {
    this.isPerformingGlobalSearchSubject.next(true);
    this.isProSearch = this.formatService.isValidProNumber(searchTxt);

    if (this.previousSearchKey !== searchTxt) {
      this.globalSearchResponse = undefined;
      this.previousSearchKey = searchTxt;
    }

    if (this.isProSearch) {
      searchTxt = this.conditioningService.conditionProNumber(searchTxt, 11);
    }

    return new Observable((observer) => {
      const pathParams = new PerformAppGlobalSearchPath();
      pathParams.searchTxt = searchTxt;

      const queryParams = new PerformAppGlobalSearchQuery();
      queryParams.globalSearchDetailCds = [
        GlobalSearchDetailCd.CLAIM_RESULT,
        GlobalSearchDetailCd.SHIPMENT_RESULT,
        GlobalSearchDetailCd.DISPUTE_RESULT,
        GlobalSearchDetailCd.INVOICE_RESULT,
      ];

      this.appMetadataApiService
        .performAppGlobalSearch(pathParams, queryParams, { loadingOverlayEnabled: false, toastOnError: true })
        .pipe(
          map(
            (globalSearchResp: PerformAppGlobalSearchResp): PerformAppGlobalSearchResp =>
              this._removeEmptyGlobalSearchResultItems(globalSearchResp)
          ),
          map(
            (globalSearchResp: PerformAppGlobalSearchResp): LocalPerformAppGlobalSearchResp =>
              this._transformToLocalPerformAppGlobalSearchResponse(globalSearchResp)
          )
        )
        .subscribe(
          (globalSearchResp: LocalPerformAppGlobalSearchResp) => {
            this.showInvalidSearchSubject.next(!_get(globalSearchResp, 'listInfo.totalRowCount', 0));
            this.globalSearchResponse = globalSearchResp as LocalPerformAppGlobalSearchResp;

            observer.next();
            observer.complete();
          },
          (error) => {
            this.globalSearchResponse = undefined;
            this.isPerformingGlobalSearchSubject.next(false);
            this.showInvalidSearchSubject.next(true);
            observer.next(error);
            observer.complete();
          },
          () => this.isPerformingGlobalSearchSubject.next(false)
        );
    });
  }

  private _removeEmptyGlobalSearchResultItems(
    globalSearchResp: PerformAppGlobalSearchResp
  ): PerformAppGlobalSearchResp {
    if (_size(_get(globalSearchResp, 'globalSearchResult', []))) {
      // remove empty return result object from globalSearchResult to prevent empty mat optgroup display
      Object.keys(globalSearchResp.globalSearchResult).forEach((currentKey) => {
        if (!_size(globalSearchResp.globalSearchResult[currentKey])) {
          delete globalSearchResp.globalSearchResult[currentKey];
        }
      });
    }

    return globalSearchResp;
  }

  private _transformToLocalPerformAppGlobalSearchResponse(
    globalSearchResp: PerformAppGlobalSearchResp
  ): LocalPerformAppGlobalSearchResp {
    const localGlobalSearchResp: LocalPerformAppGlobalSearchResp = _cloneDeep(globalSearchResp);
    switch (this.currentAppName) {
      case GlobalSearchAppName.CLAIMS_APP: {
        if (_size(_get(localGlobalSearchResp, 'globalSearchResult.claimSearchResults', []))) {
          localGlobalSearchResp.primaryAppGlobalSearchResult = {
            searchResult: localGlobalSearchResp.globalSearchResult.claimSearchResults,
            key: 'claimSearchResults',
          };
          delete localGlobalSearchResp.globalSearchResult.claimSearchResults;
        }
        break;
      }
      case GlobalSearchAppName.DISPUTES_APP: {
        if (_size(_get(localGlobalSearchResp, 'globalSearchResult.disputeSearchResults', []))) {
          localGlobalSearchResp.primaryAppGlobalSearchResult = {
            searchResult: localGlobalSearchResp.globalSearchResult.disputeSearchResults,
            key: 'disputeSearchResults',
          };
          delete localGlobalSearchResp.globalSearchResult.disputeSearchResults;
        }
        break;
      }
      case GlobalSearchAppName.INVOICE_APP: {
        if (_size(_get(localGlobalSearchResp, 'globalSearchResult.invoiceSearchResults', []))) {
          localGlobalSearchResp.primaryAppGlobalSearchResult = {
            searchResult: localGlobalSearchResp.globalSearchResult.invoiceSearchResults,
            key: 'invoiceSearchResults',
          };
          delete localGlobalSearchResp.globalSearchResult.invoiceSearchResults;
        }
        break;
      }
      case GlobalSearchAppName.SHIPMENTS_APP: {
        if (_size(_get(localGlobalSearchResp, 'globalSearchResult.invoiceSearchResults', []))) {
          localGlobalSearchResp.primaryAppGlobalSearchResult = {
            searchResult: localGlobalSearchResp.globalSearchResult.shipmentSearchResults,
            key: 'shipmentSearchResults',
          };
          delete localGlobalSearchResp.globalSearchResult.shipmentSearchResults;
        }
        break;
      }
    }

    return localGlobalSearchResp;
  }

  private _canPerformGlobalSearch(searchKey: string | LocalGlobalSearchResponseItem): boolean {
    if (typeof searchKey !== 'string') {
      return false;
    }

    return (
      this.formatService.isValidProNumber(searchKey) ||
      this.formatService.isValidClaimNumber(searchKey) ||
      this.formatService.isValidDisputeNumber(searchKey)
    );
  }

  private _setOverlayWidth(): void {
    this.searchAutocomplete.panelWidth = this.OPEN_PANEL_OVERLAY_WIDTH;
  }

  private _isLocalBuild(): boolean {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion) === 'local-version';
  }

  private _setRecentSearchList(): void {
    this.recentGlobalSearchListSubject.next(this._getRecentSearchesFromLocalStorage());
  }

  private _getRecentSearchesFromLocalStorage(): LocalGlobalSearchResponseItem[] {
    const recentSearchList = localStorage.getItem(GlobalSearchLocalStorageDataType.RecentSearchList);
    return recentSearchList ? JSON.parse(recentSearchList) : [];
  }

  private _setSelectedGlobalSearchItemToLocalStorage(): void {
    const localStorageList = _uniqWith(
      [this.selectedGlobalSearchItem, ...this._getRecentSearchesFromLocalStorage()],
      _isEqual
    ).slice(0, 5);
    localStorage.setItem(GlobalSearchLocalStorageDataType.RecentSearchList, JSON.stringify(localStorageList));
  }

  private _navigateToSelectedItem(): void {
    const navigationLink: string = this._generateGlobalSearchLink();

    if (!this.openExternalPageOnClick) {
      if (this._isNavigatingToLocalPath()) {
        this.router.navigateByUrl(navigationLink);
      } else {
        this.windowService.nativeWindow.location.href = navigationLink;
      }
    } else {
      this.openExternalPageOnClick = false;
      this.windowService.nativeWindow.open(navigationLink, '_blank', 'noopener');
    }

    this.selectedGlobalSearchItem = undefined;
  }

  // Need to manually mapping untiol app metadata api is developed to return application data
  private _isNavigatingToLocalPath(): boolean {
    switch (this.selectedGlobalSearchItem.globalSearchResultType) {
      case GlobalSearchResultType.ClaimSearchResults: {
        return this.currentAppName.includes(GlobalSearchAppName.CLAIMS_APP);
      }
      case GlobalSearchResultType.ShipmentSearchResults:
      case GlobalSearchResultType.DisputeSearchResults: {
        return this.currentAppName.includes(GlobalSearchAppName.DISPUTES_APP);
      }
      case GlobalSearchResultType.InvoiceSearchResults: {
        return this.currentAppName.includes(GlobalSearchAppName.INVOICE_APP);
      }
      default: {
        return false;
      }
    }
  }

  private _generateGlobalSearchLink(): string {
    const originURL = document.location.origin;
    const searchResult = this.selectedGlobalSearchItem.searchResult;
    let primaryKey: string | number;
    let secondaryKey: string | number;
    let appURI: GlobalSearchAppURI | string;
    let appPathURL = '';

    if (!this._isLocalBuild()) {
      appPathURL += 'appjs/';
    }

    switch (_get(this.selectedGlobalSearchItem, 'globalSearchResultType')) {
      case GlobalSearchResultType.ClaimSearchResults: {
        appURI = GlobalSearchAppURI.CLAIMS_APP;
        primaryKey = (searchResult as ClaimSearchResult).claimId;
        break;
      }
      case GlobalSearchResultType.DisputeSearchResults: {
        appURI = GlobalSearchAppURI.DISPUTES_APP;
        primaryKey = (searchResult as DisputeSearchResult).disputeId;
        secondaryKey = (searchResult as DisputeSearchResult).workflowItemInstId;
        break;
      }
      case GlobalSearchResultType.ShipmentSearchResults: {
        appURI = GlobalSearchAppURI.SHIPMENT_DETAILS_APP;
        primaryKey = (searchResult as ShipmentSearchResult).shipmentInstId;
        break;
      }
      case GlobalSearchResultType.InvoiceSearchResults: {
        appURI = GlobalSearchAppURI.INVOICE_APP;
        primaryKey = this.conditioningService.conditionProNumber((searchResult as InvoiceSearchResult).proNbr, 11);
        break;
      }
    }

    appURI = appURI.replace('{{0}}', `${primaryKey}`).replace('{{1}}', `${secondaryKey}`);

    if (this._isNavigatingToLocalPath()) {
      return appURI
        .split('/')
        .slice(1)
        .join('/');
    }

    appPathURL += appURI;

    return `${originURL}/${appPathURL}`;
  }

  private _matchesPreviousSearchKey(searchKey: string): boolean {
    let prevKey = _defaultTo(this.previousSearchKey, '').trim();

    if (this.formatService.isValidProNumber(searchKey)) {
      searchKey = this.conditioningService.conditionProNumber(searchKey, 11);
    }

    if (this.formatService.isValidProNumber(prevKey)) {
      prevKey = this.conditioningService.conditionProNumber(prevKey, 11);
    }

    return prevKey === searchKey;
  }

  private _normalizeSearchKey(searchKey: string): string {
    const trimmedSearchKey = _defaultTo(searchKey, '').trim();
    return trimmedSearchKey;
  }

  private _resetLastScrollPosition(): void {
    this.lastAutocompletePanleScrollPosition = 0;
  }

  private _setLastScrollPosition(panelElRef: ElementRef): void {
    this.lastAutocompletePanleScrollPosition = _get(panelElRef, 'nativeElement.scrollTop', 0);
  }

  selectItem($event: MatAutocompleteSelectedEvent): void {
    this.selectedGlobalSearchItem = $event.option.value as LocalGlobalSearchResponseItem;
    this._setSelectedGlobalSearchItemToLocalStorage();
    this._setLastScrollPosition($event.source.panel);

    if (!this.openExternalPageOnClick) {
      this.searchControl.setValue('', { emitEvent: false });
      this.globalSearchResponse = undefined;
      this.blur();
    } else {
      this.searchControl.setValue(this.previousSearchKey, { emitEvent: false });
      timer().subscribe(() => {
        this.focus();
      });
    }

    this._navigateToSelectedItem();
  }

  handleFilterFocus(): void {
    const normalizedSearchInputVal = this._normalizeSearchKey(this.searchInput.value);
    this._setScrollWatcher();
    this._setRecentSearchList();
    this.isFocused = true;

    if (
      this._canPerformGlobalSearch(normalizedSearchInputVal) &&
      (!this._matchesPreviousSearchKey(normalizedSearchInputVal) || !this.globalSearchResponse)
    ) {
      this._performGlobalSearch(normalizedSearchInputVal)
        .pipe(take(1))
        .subscribe(() => {});
    } else if (
      this._matchesPreviousSearchKey(normalizedSearchInputVal) &&
      (!!this.globalSearchResponse || !!_size(this.recentGlobalSearchList)) &&
      !this.searchAutocompleteTrigger.panelOpen
    ) {
      this.searchAutocompleteTrigger.openPanel();
    }
  }

  // #endregion
}
