import {RouteCategoryCd} from '../';
import {EnumHelper} from './enum-helper';

export class RouteCategoryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RouteCategoryCd ) {
      this._values.push(RouteCategoryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RouteCategoryCd {

    for ( const obj in RouteCategoryCd ) {
      if (RouteCategoryCd[obj] === value){
        return RouteCategoryCd[obj] as RouteCategoryCd;
      }
    }
  }
}

const RouteCategoryCdHelper = new RouteCategoryCdHelperEnumHelperClass();
export default RouteCategoryCdHelper;
