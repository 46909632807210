import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'xpo-inline-search',
  templateUrl: './xpo-inline-search.component.html',
  host: { class: 'xpo-InlineSearch' },
})
export class XpoInlineSearchComponent {
  searchInput: FormControl;

  /**
   * The searchFn passed in on Enter Event.
   */
  @Input()
  onEnter: Function;

  constructor() {
    this.searchInput = new FormControl();
  }
}
