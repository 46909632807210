import { AfterContentInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment } from 'moment';
import { XpoBaseDatePickerDirective } from './datepicker-base.directive';

const XPO_MONTH_PICKER_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Directive({
  selector: '[xpoMonthPickerView]',
  host: {
    class: 'xpo-DatePicker',
  },
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: XPO_MONTH_PICKER_FORMATS },
  ],
})
export class XpoMonthPickerViewDirective extends XpoBaseDatePickerDirective implements AfterContentInit {
  // TODO: Remove and only keep constructor in parent class using empty @Directive decorator (NGXLTL-732)V
  constructor(protected element: ElementRef, protected renderer: Renderer2) {
    super(element, renderer);
  }

  ngAfterContentInit(): void {
    super.ngAfterContentInit();

    this.datePickerMatInput.placeholder = XPO_MONTH_PICKER_FORMATS.display.dateInput;

    this.datePicker.startView = 'year';
    this.datePicker.monthSelected.subscribe((selectedMonth: Moment) => this.onMonthSelected(selectedMonth));
  }

  private onMonthSelected(selectedMonth: Moment): void {
    const monthValue = this.datePickerInput.value;
    monthValue.month(selectedMonth.month());
    monthValue.year(selectedMonth.year());
    this.datePicker.select(monthValue);
    this.datePicker.close();
  }
}
