import { CriteriaFunction } from './criteria-function.model';

export class ArrayCriteriaFunction implements CriteriaFunction {
  supportsValue(value: any): boolean {
    return Array.isArray(value);
  }

  getFilter<T>(key: string, value: any): (t: T) => boolean {
    // TODO: support searching an array
    return (t: T) => !value.length || value.indexOf(t[key]) !== -1;
  }
}
