import { Inject, Injectable, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { XpoBrowserDetectionUtils } from './browser-detection-util';
import { XPO_BROWSER_RESTRICTIONS, xpoDefaultBrowserRestrictions } from './default-browser-configuration';
import { XpoBrowserGuardDialog } from './dialog/browser-guard-dialog.component';
import { XpoBrowserClassification, XpoBrowserRestrictions } from './models/index';

@Injectable()
export class XpoBrowserGuard implements CanActivate {
  constructor(
    public dialog: MatDialog,
    @Optional() @Inject(XPO_BROWSER_RESTRICTIONS) private globalBrowserRestrictions: XpoBrowserRestrictions
  ) {}

  canActivate(snapshot: ActivatedRouteSnapshot): boolean {
    const restrictions: XpoBrowserRestrictions =
      (snapshot.data && snapshot.data.browserRestrictions) ||
      this.globalBrowserRestrictions ||
      xpoDefaultBrowserRestrictions;

    const currentBrowser = XpoBrowserDetectionUtils.loadBrowserInfo();
    const isBlocked = restrictions.blocked.some((x: XpoBrowserClassification) =>
      XpoBrowserClassification.areMatching(x, currentBrowser)
    );

    if (isBlocked) {
      this.dialog.open(XpoBrowserGuardDialog, {
        width: '550px',
        disableClose: true,
        data: { suggestions: restrictions.suggestions },
      });
    }

    return !isBlocked;
  }
}
