import { XpoFilter } from './filter';
import { XpoFilterFactory, XpoFilterFactoryParams } from './filter-factory';

export class XpoInMemoryFilterFactory implements XpoFilterFactory {
  constructor(private readonly builders: Map<string, (p: XpoFilterFactoryParams) => XpoFilter>) {}

  get(filterParam: XpoFilterFactoryParams): XpoFilter {
    const func = this.builders.get(filterParam.type);
    return func ? func(filterParam) : null;
  }

  getAll(filterParams: XpoFilterFactoryParams[]): XpoFilter[] {
    return filterParams.map((x) => this.get(x)).filter((x) => x !== null);
  }
}
