import { XpoInlineFilter } from '../models/inline-filter';
import { XpoCheckboxFilterComponent } from './checkbox-filter.component';

export class XpoCheckboxFilter extends XpoInlineFilter {
  constructor(public field: string, public label: string) {
    super(field, label, XpoCheckboxFilterComponent);
  }

  getDisplayValue(val: any): string {
    return '';
  }
}
