import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { XpoScrollableDirective } from './directives/scrollable.directive';

const XPO_SCROLLABLE_SCROLL_BEHAVIOR: ScrollBehavior = 'smooth';
const XPO_SCROLLABLE_HAS_SCROLL_CLASS: string = 'xpo-Scrollable--hasScroll';
const XPO_SCROLLABLE_COMPACT_CLASS: string = 'xpo-Scrollable--compact';
const XPO_SCROLLABLE_MINI_CLASS: string = 'xpo-Scrollable--mini';

@Component({
  selector: 'xpo-scrollable',
  templateUrl: 'scrollable.component.html',
  styleUrls: ['scrollable.component.scss'],
  host: { class: 'xpo-Scrollable' },
})
export class XpoScrollableComponent implements AfterViewInit, AfterViewChecked {
  @ViewChild(XpoScrollableDirective, { static: true }) scrollable: XpoScrollableDirective;

  canScroll: boolean = false;

  private elements: HTMLCollection;

  constructor(private cd: ChangeDetectorRef, private host: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    // get tile items
    this.elements = this.scrollable.getElementRef().nativeElement.children.item(0).children;

    this.scrollable.elementScrolled().subscribe(() => this.cd.detectChanges());
  }

  ngAfterViewChecked(): void {
    this.canScroll = this.canScrollLeft() || this.canScrollRight();

    if (this.canScroll) {
      this.renderer.addClass(this.host.nativeElement, XPO_SCROLLABLE_HAS_SCROLL_CLASS);
    } else {
      this.renderer.removeClass(this.host.nativeElement, XPO_SCROLLABLE_HAS_SCROLL_CLASS);
    }

    this.cd.detectChanges();
  }

  setCompact(compact: boolean): void {
    if (compact) {
      this.renderer.addClass(this.host.nativeElement, XPO_SCROLLABLE_COMPACT_CLASS);
    } else {
      this.renderer.removeClass(this.host.nativeElement, XPO_SCROLLABLE_COMPACT_CLASS);
    }
  }

  setMini(mini: boolean): void {
    if (mini) {
      this.renderer.addClass(this.host.nativeElement, XPO_SCROLLABLE_MINI_CLASS);
    } else {
      this.renderer.removeClass(this.host.nativeElement, XPO_SCROLLABLE_MINI_CLASS);
    }
  }

  canScrollLeft(): boolean {
    return this.isContinueScrollDistance(this.getMeasureScrollLeft());
  }

  canScrollRight(): boolean {
    return this.isContinueScrollDistance(this.getMeasureScrollRight());
  }

  onClickLeftScroll(): void {
    let totalMarginLeft: number = 0;
    let leftScroll: number = 0;
    let itemWidth: number = 0;
    let itemMarginLeft: number = 0;

    // from the first item to the current one
    let item: number = 0;
    while (leftScroll < this.getMeasureScrollLeft()) {
      // get the current item
      const element: Element = this.elements.item(item);

      // get current item width
      itemWidth = element.clientWidth;

      // get current item margin left
      itemMarginLeft = this.getMarginLeft(element);

      // accumulate the items margin left
      totalMarginLeft = totalMarginLeft + itemMarginLeft;

      // accumulate the item width and margin
      leftScroll = leftScroll + itemWidth + itemMarginLeft;

      // set the next item
      item++;
    }

    // compute if the left scroll is based as part of one item
    const leftOffset: number = Math.round((this.getMeasureScrollLeft() - totalMarginLeft) % itemWidth);

    // compute the position to scroll
    let scroll: number = 0;
    if (leftOffset > 0) {
      // scroll only the remaining part of the item
      scroll = this.getMeasureScrollLeft() - leftOffset - itemMarginLeft;
    } else {
      // define the position to scroll to with the entire item width
      scroll = this.getMeasureScrollLeft() - itemWidth - itemMarginLeft;
    }

    // scroll to the computed position
    this.scrollTo(scroll);
  }

  onClickRightScroll(): void {
    let totalMarginLeft: number = 0;
    let rightScroll: number = 0;
    let itemWidth: number = 0;
    let itemMarginLeft: number = 0;

    // from the last item scrolled to the current one
    let item: number = this.elements.length - 1;
    while (rightScroll < this.getMeasureScrollRight()) {
      // get the current item
      const element: Element = this.elements.item(item);

      // get current item width
      itemWidth = element.clientWidth;

      // get current item margin left
      itemMarginLeft = this.getMarginLeft(element);

      // accumulate the items margin left
      totalMarginLeft = totalMarginLeft + itemMarginLeft;

      // accumulate the item width and margin
      rightScroll = rightScroll + itemWidth + itemMarginLeft;

      // set the previous item
      item--;
    }

    // compute if the right scroll is based as part of one item
    const rightOffset: number = Math.round((this.getMeasureScrollRight() - totalMarginLeft) % itemWidth);

    // compute the position to scroll
    let scroll: number = 0;
    if (rightOffset > 0) {
      // scroll only the remaining part of the item
      scroll = this.getMeasureScrollLeft() + rightOffset + itemMarginLeft;
    } else {
      // define the position to scroll to with the entire item width
      scroll = this.getMeasureScrollLeft() + itemWidth + itemMarginLeft;
    }

    // scroll to the computed position
    this.scrollTo(scroll);
  }

  scrollToElement(elementIndex: number): void {
    if (!this.canScroll) {
      return;
    }

    // compute the start and end position of the item to scroll to
    let startPosition: number = 0;
    let endPosition: number = 0;
    for (let index = 0; index <= elementIndex; index++) {
      const element = this.elements.item(index);

      // compute the start position using the width of all elements except the desired one
      if (index !== elementIndex) {
        startPosition = startPosition + element.clientWidth + this.getMarginLeft(element);
      }

      // use the start position and add the with of the current element to get its end position
      endPosition = startPosition + element.clientWidth + this.getMarginLeft(element);
    }

    // get de viewport width
    const viewportWidth: number = this.scrollable.getElementRef().nativeElement.clientWidth;

    // get the left scrolled value
    const leftScrolled = this.getMeasureScrollLeft();

    // if the item end position is scrolled to the right
    if (endPosition > leftScrolled + viewportWidth) {
      // scroll to the right to show the entire element
      this.scrollTo(endPosition - viewportWidth);
    } else if (leftScrolled > startPosition) {
      // scroll to the left to show the entire element
      this.scrollTo(startPosition);
    }
  }

  private getMeasureScrollLeft(): number {
    return this.scrollable.measureScrollOffset('left');
  }

  private getMeasureScrollRight(): number {
    return this.scrollable.measureScrollOffset('right');
  }

  private isContinueScrollDistance(offset: number): boolean {
    return Math.abs(Math.round(offset)) > 0;
  }

  private scrollTo(scroll: number): void {
    this.scrollable.scrollTo({
      left: scroll,
      behavior: XPO_SCROLLABLE_SCROLL_BEHAVIOR,
    });
  }

  private getMarginLeft(element: Element): number {
    const margin: string = getComputedStyle(element).getPropertyValue('margin-left');
    return parseInt(margin, 10);
  }
}
