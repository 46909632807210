import {EquipmentEventTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class EquipmentEventTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EquipmentEventTypeCd ) {
      this._values.push(EquipmentEventTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EquipmentEventTypeCd {

    for ( const obj in EquipmentEventTypeCd ) {
      if (EquipmentEventTypeCd[obj] === value){
        return EquipmentEventTypeCd[obj] as EquipmentEventTypeCd;
      }
    }
  }
}

const EquipmentEventTypeCdHelper = new EquipmentEventTypeCdHelperEnumHelperClass();
export default EquipmentEventTypeCdHelper;
