import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { XpoBoardOptions } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardView } from '@xpo-ltl/ngx-ltl-board-grid';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

/* XPO */
import { ModuleGroupModuleVersion } from '@xpo-ltl/sdk-moduleexecutor';

/* Models */
import { AddModuleEvent } from '../../models/addModuleEvent.model';
import { StepGridItem } from '../../models/step-grid-item.model';
import { AssociatedModuleVersion } from './models/module-group-mgmt-associated-modules-grid.model';

import { MODULE_GROUP_MGMT_ASSOCIATED_MODULES_GRID_OPTIONS } from './models/module-group-mgmt-associated-modules-grid-options';
import { MODULE_GROUP_MGMT_ASSOCIATED_MODULES_VIEW_TEMPLATES } from './models/module-group-mgmt-associated-modules-view-templates';

/* Services */
import { ModuleGroupManagementService } from '../../services/module-group-management.service';
import { ModuleGroupMgmtAssociatedModulesDataSource } from './services/module-group-mgmt-associated-modules-data-source';

@Component({
  selector: 'module-group-mgmt-associated-modules',
  templateUrl: './module-group-mgmt-associated-modules.component.html',
  styleUrls: ['./module-group-mgmt-associated-modules.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ModuleGroupMgmtAssociatedModulesDataSource],
  host: {
    class: 'ModuleGroupMgmtAssociatedModules',
  },
})
export class ModuleGroupMgmtAssociatedModulesComponent implements OnInit {
  @Input() hasSomeStepEmptyRows: boolean;

  @Output() addSelectedModulesToStep: EventEmitter<AddModuleEvent>;
  @Output() addSelectedModulesToNewStep: EventEmitter<AssociatedModuleVersion[]>;

  readonly views: XpoAgGridBoardView[];
  readonly viewTemplates = MODULE_GROUP_MGMT_ASSOCIATED_MODULES_VIEW_TEMPLATES;
  readonly gridOptions = MODULE_GROUP_MGMT_ASSOCIATED_MODULES_GRID_OPTIONS;

  readonly boardOptions: XpoBoardOptions = {
    suppressViewSwitcher: true,
    suppressGridSettingsPopover: true,
  };

  private gridApi: GridApi;

  steps: StepGridItem[];
  selectedRows: AssociatedModuleVersion[];

  constructor(
    public dataSource: ModuleGroupMgmtAssociatedModulesDataSource,
    private moduleGroupManagementService: ModuleGroupManagementService
  ) {
    this.addSelectedModulesToStep = new EventEmitter<AddModuleEvent>();
    this.addSelectedModulesToNewStep = new EventEmitter<AssociatedModuleVersion[]>();

    this.selectedRows = [];
    this.views = this.getViews();
    this.gridOptions.onSelectionChanged = () => this._selectionChanged();
    this.steps = this.moduleGroupManagementService.getSteps();
    this.hasSomeStepEmptyRows = true;
  }

  ngOnInit() {
    this.moduleGroupManagementService.onStepsChange().subscribe((steps) => {
      this.steps = steps;
    });
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
  }

  publishEventAddSelectedModulesToStep(stepId: number): void {
    this.addSelectedModulesToStep.emit({ stepId: stepId, rows: this.selectedRows });
    this.gridApi.deselectAll();
  }

  publishEventAddSelectedModulesToNewStep(): void {
    this.addSelectedModulesToNewStep.emit(this.selectedRows);
    this.gridApi.deselectAll();
  }

  private getViews(): XpoAgGridBoardView[] {
    return this.viewTemplates.map((template) => {
      return template.createView({
        id: template.id,
        name: template.name,
        visible: true,
      });
    });
  }

  private _selectionChanged() {
    this.selectedRows = this.gridApi.getSelectedRows();
  }
}
