import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { ColDef, IDoesFilterPassParams, RowNode } from 'ag-grid-community';
import { each } from 'lodash';
import { XpoFiltersService } from '../filters.service';
import { XpoConditionalFilterParams } from '../models/conditional-filter-params';
import { ConditionalFilterCombiner } from './../models/combiner.enum';
import { XpoFilterColumnCriteria } from './../models/conditional-filter.type';
import { XpoConditionalFilter } from './conditional-filter';
import { XpoFilterCondition } from './conditions/condition';
import { isValueFilterValid } from './conditions/validators/common/common.util';
@Component({
  templateUrl: './conditional-filter.component.html',
  styleUrls: ['./conditional-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class XpoConditionalFilterComponent implements AfterViewChecked, IFilterAngularComp {
  readonly AG_MODAL_CLASS: string = 'ag-menu';
  readonly AG_BOARD_CLASS: string = 'xpo-Board';
  params: XpoConditionalFilterParams;
  valueGetter: (rowNode: RowNode) => any;
  filterValueGetter: (column: ColDef, rowNode: RowNode) => any;
  isfilterApplied: boolean = false;
  andOr: ConditionalFilterCombiner;
  hideFilter: Function;
  displayAddButton: boolean = true;
  allowMultipleFilterRows: boolean = true;
  filters: Array<XpoConditionalFilter> = [];
  column;
  agGridColumns: Array<object>;
  cdRef: ChangeDetectorRef;
  conditions = [];
  defaultConditions = [];
  applyButtonDisabled: boolean = true;
  ConditionalFilterCombiner = ConditionalFilterCombiner; // For use in template
  cachedModel: XpoFilterColumnCriteria = <XpoFilterColumnCriteria>{ conditions: [] };

  constructor(cdRef: ChangeDetectorRef, private filtersService: XpoFiltersService, private elementRef: ElementRef) {
    this.cdRef = cdRef;
    this.andOr = ConditionalFilterCombiner.Or;
  }

  // TODO: params can't be set an IFilterParams type because of custom params, improve anyways to not be any
  agInit(params: any): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
    this.filterValueGetter = params.filterValueGetter;
    this.column = params.column.colDef;
    this.conditions = params.operators ? this.setConditions(params.operators) : this.defaultConditions;
  }

  setConditions(operators: string[]): XpoFilterCondition[] {
    return this.defaultConditions.filter((condition) => {
      return operators.includes(condition.operator);
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  /**
   * Creates a new filter. This adapts to keep the previously selected filter.
   */
  createFilter(selectedCondition?: string): XpoConditionalFilter {
    /* TODO: do some clean up and check scenarios for selectedCondition being passed
    and totalFilters !== 0
    */
    const totalFilters = this.filters.length;
    let filter: XpoConditionalFilter;
    if (totalFilters !== 0) {
      filter = new XpoConditionalFilter(this.conditions, this.filters[totalFilters - 1].selectedCondition.value);
    }
    if (selectedCondition) {
      filter = new XpoConditionalFilter(this.conditions, selectedCondition);
    } else {
      filter = new XpoConditionalFilter(this.conditions);
    }
    return filter;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return this.doesNodeFilterPass(params.node);
  }

  doesNodeFilterPass(node): boolean {
    const getterValue = this.getGetterValue(this.column, node);
    const value = isValueFilterValid(getterValue) ? getterValue : this.getFieldValue(this.column.field, node.data);

    if (this.andOr === ConditionalFilterCombiner.And) {
      return this.doesANDFilterPass(value);
    } else {
      return this.doesORFilterPass(value);
    }
  }

  doesANDFilterPass(value): boolean {
    let filterPass = true;

    this.filters.forEach((filter) => {
      if (!filter.validate(value)) {
        filterPass = false;
      }
    });

    return filterPass;
  }

  doesORFilterPass(value): boolean {
    let filterPass = false;

    this.filters.forEach((filter) => {
      if (filter.validate(value)) {
        filterPass = true;
      }
    });
    return filterPass;
  }

  getModel(): any {
    if (!this.isFilterActive()) {
      return null;
    } else {
      return {
        filter: this.getFiltersCriteria(),
      };
    }
  }

  setModel(model: any): void {
    /*
    Reverse functinoality for filters logic. This sets the filters UI based on a
    criteria change in the board state.
    */
    this.clearFiltersInModal();

    if (!model || !model.filter) {
      this.isfilterApplied = false;
      this.cachedModel = <XpoFilterColumnCriteria>{ conditions: [] };
      return;
    }
    const criteria = model.filter as XpoFilterColumnCriteria;
    if (criteria.combiner) {
      this.andOr = criteria.combiner;
    }
    if (criteria.conditions && criteria.conditions.length > 0) {
      criteria.conditions.forEach((condition) => {
        // Create the new filter
        const conditionRef = this.createFilter(condition.operator);
        this.filters.push(conditionRef);
        this.cdRef.detectChanges();

        if (!!condition.valueTo) {
          conditionRef.componentReference.instance.model.from = condition.value;
          conditionRef.componentReference.instance.model.to = condition.valueTo;
        } else {
          conditionRef.componentReference.instance.model = condition.value;
        }
      });
    }
    this.isfilterApplied = this.filters.length ? true : false;
    this.cachedModel = this.getFiltersCriteria();
  }

  afterGuiAttached(params): void {
    this.adjustModalAlignment();
    // Attach ag-grid hide's method to our to execute us the behavior
    this.hideFilter = params.hidePopup;
    // Init config
    this.setModel({ filter: this.cachedModel });
    if (this.cachedModel.conditions && !this.cachedModel.conditions.length) {
      this.clearFiltersInModal();
      if (this.params.initializeFilterOnOpen) {
        this.addFilter();
      }
      this.cdRef.detectChanges();
    }
  }

  isFilterActive(): boolean {
    return this.isfilterApplied;
  }

  getFiltersCriteria(): XpoFilterColumnCriteria {
    const criteria: XpoFilterColumnCriteria = {
      conditions: [],
    };

    if (this.filters.length > 1) {
      criteria.combiner = this.andOr;
    }
    this.filters.forEach((filter) => {
      criteria.conditions.push(filter.componentReference.instance.getCriteria());
    });
    return criteria;
  }

  checkAddFilterAvailability(): void {
    this.filters.forEach((filter) => {
      if (filter.selectedCondition.applyAlwaysEnabled) {
        this.applyButtonDisabled = false;
      }
    });
  }

  /**
   * This will tell you whether all the conditions are logically negative.
   */
  get areAllConditionsNegative(): boolean {
    let positiveIndicator = false; // Update this if a positive is found
    this.filters.forEach((filter) => {
      if (!filter.selectedCondition.isNegative) {
        positiveIndicator = true;
      }
    });
    return !positiveIndicator;
  }

  onConditionChanged(): void {
    /**
     * Only if they are all negative change.
     * This is a UX touch requested by rohit.colaco@xpo.com
     *  If you think about it, though. If all your conditions
     *  are negative you'll exclude everything so you will
     *  want it to be an AND operation. - Good call Rohit
     */
    if (this.areAllConditionsNegative) {
      this.andOr = ConditionalFilterCombiner.And;
    }
    this.checkAddFilterAvailability();
  }

  isFormInvalid(): boolean {
    return this.isFormDirty();
  }

  isFormDirty(): boolean {
    // Ask for each field and show error in all of corresponding them also return true if just one has an error.
    let error = false;
    each(this.filters, (filter) => {
      if (!filter.componentReference.instance.isValid()) {
        error = true;
      }
    });
    return error;
  }

  addFilter(): void {
    this.filters.push(this.createFilter());
  }

  cancelFilter(): void {
    this.setModel({ filter: this.cachedModel });
    this.closeFilter();
  }

  closeFilter(): void {
    if (this.hideFilter) {
      this.hideFilter();
    }
  }

  removeFilter(filterIndex): void {
    this.filters.splice(filterIndex, 1);
    this.checkAddFilterAvailability();
  }

  applyFilter(): void {
    if (this.isFormInvalid()) {
      return;
    }
    this.isfilterApplied = this.filters.length ? true : false;
    this.cachedModel = this.getFiltersCriteria();
    this.params.filterChangedCallback();
    this.filtersService.setFieldCriteria(this.params.colDef.field, this.getFiltersCriteria());
    this.closeFilter();
  }

  getFiltersDiff(): null {
    // TODO: returns filter diff in comparison with applied filters, returns null if no diff
    return null;
  }

  /**
   * Clears the filters but does not activate the change.
   */
  clearFiltersInModal(): void {
    this.filters = [];
    this.applyButtonDisabled = false;
  }

  private getFieldValue(field: string, data: any): any {
    const columnFields = field.split('.');

    if (columnFields.length) {
      return columnFields.reduce((obj, property) => {
        // isValueFilterValid allows zero as valid value for numbers
        return isValueFilterValid(obj[property]) ? obj[property] : '';
      }, data);
    }

    return null;
  }
  /**
   * Checks either for filterValueGetter or valueGetter in that order.
   */
  private getGetterValue(column: ColDef, rowNode: RowNode): any {
    const getter: any = column.filterValueGetter || column.valueGetter;
    return getter ? getter(rowNode) : null;
  }
  /**
   * Prevent modal to be cut when it doesen't fit in the board due to columns position close to right
   * adjustModalAlignment
   */
  private adjustModalAlignment(): void {
    const modalRef = this.elementRef.nativeElement.closest(`.${this.AG_MODAL_CLASS}`);
    const boardRef = this.elementRef.nativeElement.closest(`.${this.AG_BOARD_CLASS}`);
    if (modalRef && boardRef) {
      const popoverRect = modalRef.getBoundingClientRect();
      const boardRect = boardRef.getBoundingClientRect();
      if (popoverRect.x + popoverRect.width > boardRect.x + boardRect.width) {
        modalRef.style.right = 0;
        modalRef.style.left = 'auto';
      }
    }
  }
}
