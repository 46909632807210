import {ServiceCalculationStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ServiceCalculationStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ServiceCalculationStatusCd ) {
      this._values.push(ServiceCalculationStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ServiceCalculationStatusCd {

    for ( const obj in ServiceCalculationStatusCd ) {
      if (ServiceCalculationStatusCd[obj] === value){
        return ServiceCalculationStatusCd[obj] as ServiceCalculationStatusCd;
      }
    }
  }
}

const ServiceCalculationStatusCdHelper = new ServiceCalculationStatusCdHelperEnumHelperClass();
export default ServiceCalculationStatusCdHelper;
