import { AbstractControl } from '@angular/forms';

import { isValidCron } from 'cron-validator';

export function validateCron(control: AbstractControl): { [key: string]: boolean } | null {
  return control && control.value && control.value.length > 0 && isValidCron(control.value, { alias: true })
    ? null
    : {
        validateCron: true,
      };
}

export function validateMonthlyCron(control: AbstractControl): { [key: string]: boolean } | null {
  const value = control && control.value && control.value.length > 0 ? `* * ${control.value} * *` : '';
  return isValidCron(value, { alias: true })
    ? null
    : {
        validateCron: true,
      };
}
