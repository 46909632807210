import {RateStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class RateStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RateStatusCd ) {
      this._values.push(RateStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RateStatusCd {

    for ( const obj in RateStatusCd ) {
      if (RateStatusCd[obj] === value){
        return RateStatusCd[obj] as RateStatusCd;
      }
    }
  }
}

const RateStatusCdHelper = new RateStatusCdHelperEnumHelperClass();
export default RateStatusCdHelper;
