import { HttpClient } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../config-manager-properties.enum';

@Component({
  selector: 'xpo-ltl-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-LtlReleaseNotes' }
})
export class XpoLtlReleaseNotesComponent {

  version = '';
  content = '';

  constructor(private config: ConfigManagerService, private http: HttpClient) {
    this.version = this.config.getSetting(ConfigManagerProperties.buildVersion);

    this.http.get('assets/release-notes.html', { responseType: 'text' }).subscribe((content) => {
      this.content = content;
    });
  }
}
