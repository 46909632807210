import {PricingTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class PricingTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PricingTypeCd ) {
      this._values.push(PricingTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PricingTypeCd {

    for ( const obj in PricingTypeCd ) {
      if (PricingTypeCd[obj] === value){
        return PricingTypeCd[obj] as PricingTypeCd;
      }
    }
  }
}

const PricingTypeCdHelper = new PricingTypeCdHelperEnumHelperClass();
export default PricingTypeCdHelper;
