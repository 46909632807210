import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { XpoBadgeChip, XpoBadgeChipNegative, XpoBadgeChipPositive } from './chip-badge.directive';
import { XpoChipColor } from './chip-color.directive';
import { XpoChipSmall } from './chip-small.directive';
import { XpoLargeTag } from './largeTag.directive';
import { XpoTag } from './tag.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    XpoChipColor,
    XpoChipSmall,
    XpoTag,
    XpoLargeTag,
    XpoBadgeChip,
    XpoBadgeChipNegative,
    XpoBadgeChipPositive,
  ],
  exports: [
    XpoChipColor,
    XpoChipSmall,
    XpoTag,
    XpoBadgeChip,
    XpoBadgeChipNegative,
    XpoBadgeChipPositive,
    XpoLargeTag,
    MatChipsModule,
  ],
})
export class XpoChipsModule {}
