import { Directive } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: '[xpo-select-two-lines], [xpoSelectTwoLines]',
})
export class XpoSelectTwoLinesDirective {
  readonly MAT_SELECT_DEFAULT_HEIGHT: number = 45;

  // We need to override _getItemHeight function so the navigation between the
  // options works correctly. Have in mind that in case the height of the
  // options is changed, this value will have to change as well.
  constructor(public matSelect: MatSelect) {
    (<any>this.matSelect)._getItemHeight = () => this.MAT_SELECT_DEFAULT_HEIGHT;
  }
}
