
export enum DisputeEmailTemplateTypeCd {
	ACKNOWLEDGE_EMAIL = 'AcknowledgeEmail',
	BLANK_TEMPLATE = 'BlankTemplate',
	CSV_FILE_UPLOAD = 'CsvFileUpload',
	DECLINE_PER_ACCESSORIAL_CHARGE = 'DeclinePerAccessorialCharge',
	DECLINE_PER_CLASS = 'DeclinePerClass',
	DECLINE_PER_DUPLICATE_CLAIM = 'DeclinePerDuplicateClaim',
	DECLINE_PER_INSPECTION = 'DeclinePerInspection',
	DECLINE_PER_INVALID_PRO = 'DeclinePerInvalidPro',
	DECLINE_PER_NO_PAYMENT_RECEIVED = 'DeclinePerNoPaymentReceived',
	DECLINE_PER_PREVIOUSLY_REFUNDED = 'DeclinePerPreviouslyRefunded',
	DECLINE_PER_PRICING_AGREEMENT = 'DeclinePerPricingAgreement',
	DECLINE_PER_REWEIGH = 'DeclinePerReweigh',
	DECLINE_PER_STATUTE_OF_LIMITATION = 'DeclinePerStatuteOfLimitation',
	DECLINE_PER_TARIFF_OR_CONTRACT = 'DeclinePerTariffOrContract',
	DECLINE_PER_UNABLE_TO_PROCESS_CLAIM = 'DeclinePerUnableToProcessClaim',
	DEFAULT_TEMPLATE = 'DefaultTemplate',
	DISPUTE_ACKNOWLEDGEMENT = 'DisputeAcknowledgement',
	DRP_APPROVAL = 'DrpApproval',
	DRP_BLANK_TEMPLATE = 'DRPBlankTemplate',
	DRP_CSV_FILE_UPLOAD = 'DrpCsvFileUpload',
	DRP_NOTIFICATION_EMAIL = 'DrpNotificationEmail',
	MRRA_APPROVAL = 'MrraApproval',
	MRRA_NOTIFICATION_EMAIL = 'MrraNotificationEmail',
	OCC_AMENDMENT = 'OccAmendment',
	OCC_APPROVAL = 'OccApproval',
	OCC_BLANK_TEMPLATE = 'OccBlankTemplate',
	OCC_NOTIFICATION_EMAIL = 'OccNotificationEmail',
	WNI_AMENDMENT = 'WniAmendment',
	WNI_APPROVAL = 'WniApproval',
	WNI_BLANK_TEMPLATE = 'WniBlankTemplate',
	WNI_NOTIFICATION_EMAIL = 'WniNotificationEmail'}

