import { NgModule } from '@angular/core';

import { FilterPipe } from './filter.pipe';
import { OrderByPipe } from './order-by.pipe';
import { SortPipe } from './sort.pipe';

const pipes = [FilterPipe, OrderByPipe, SortPipe];

@NgModule({
  declarations: pipes,
  exports: pipes,
  providers: pipes,
})
export class PipesModule {}
