import {DsrSelectionTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DsrSelectionTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DsrSelectionTypeCd ) {
      this._values.push(DsrSelectionTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DsrSelectionTypeCd {

    for ( const obj in DsrSelectionTypeCd ) {
      if (DsrSelectionTypeCd[obj] === value){
        return DsrSelectionTypeCd[obj] as DsrSelectionTypeCd;
      }
    }
  }
}

const DsrSelectionTypeCdHelper = new DsrSelectionTypeCdHelperEnumHelperClass();
export default DsrSelectionTypeCdHelper;
