import { Injectable } from '@angular/core';
import { ConditioningService } from '../conditioning-service/conditioning.service';
import * as _ from 'lodash';
import { ValidationRegexPatterns } from '../validation-regex-patterns.enum';
import { deprecate } from 'util';

@Injectable({ providedIn: 'root' })
export class FormatValidationService {

  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly numberTrailerPattern = ValidationRegexPatterns.numberTrailerPattern;
  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly letterTrailerPattern = ValidationRegexPatterns.letterTrailerPattern;
  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly numberCheck = ValidationRegexPatterns.numberCheck;
  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly doorPattern = ValidationRegexPatterns.doorPattern;
  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly nineDigitPro = ValidationRegexPatterns.nineDigitPro;
  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly tenDigitPro = ValidationRegexPatterns.tenDigitPro;
  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly elevenDigitPro = ValidationRegexPatterns.elevenDigitPro;
  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly emailValidationPattern = ValidationRegexPatterns.emailValidationPattern;
  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly phoneNumberPattern = ValidationRegexPatterns.phoneNumberPattern;
  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly multiEmailsPattern = ValidationRegexPatterns.multiEmailsPattern;

  /**
   * This expression matches three different formats of postal codes: 5 digit US ZIP code,
   * 5 digit US ZIP code + 4, and 6 digit alphanumeric Canadian Postal Code. The first one
   * must be 5 numeric digits. The ZIP+4 must be 5 numeric digits, a hyphen, and then 4
   * numeric digits. The Canadian postal code must be of the form ANA NAN where A is any
   * uppercase alphabetic character and N is a numeric digit from 0 to 9.
   */
  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly  usZipCode = ValidationRegexPatterns.usZipCode;
  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly  caPostalCode = ValidationRegexPatterns.caPostalCode;
  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly  mxPostalCode = ValidationRegexPatterns.mxPostalCode;

  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly madCodeValidationPattern = ValidationRegexPatterns.madCodeValidationPattern;
  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly billToMadCodeValidationPattern = ValidationRegexPatterns.billToMadCodeValidationPattern;
  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly sicValidationPattern = ValidationRegexPatterns.sicValidationPattern;

  /**
   * @deprecated Use ValidationRegexPatterns instead
   */
  public readonly nmfcValidationPattern = ValidationRegexPatterns.nmfcValidationPattern;

  private _emailSplitPattern = '[\\s,;]';

  constructor(private conditioningService: ConditioningService) { }

  isValidEmail(inputValue: string): boolean {
    if (!inputValue) {
      return false;
    }
    const regExp = new RegExp(ValidationRegexPatterns.emailValidationPattern, 'i');
    return regExp.test(inputValue);
  }

  isValidEmails(inputValue: string | Array<string>): boolean {
    if (Array.isArray(inputValue)) {
      return inputValue.every(value => this.isValidEmail(value));
    }
    return this.isValidEmails(this.splitEmailGroup(inputValue));
  }

  private splitEmailGroup(inputValue: string): Array<string> {
    if (!inputValue) {
      return undefined;
    }
    return inputValue.split(new RegExp(this._emailSplitPattern, 'g')).map(item => item.trim()).filter(item => !!item);
  }


  isValidProNumber(inputValue) {
    return this.isValidProNumberCheckDigit(inputValue);
  }
  isValidCallNumber(inputValue) {
    return new RegExp(ValidationRegexPatterns.callNumber).test(inputValue);
  }
  isValidRouteName(inputValue) {
    return new RegExp(ValidationRegexPatterns.routeName).test(inputValue);
  }

  isValidDoorNumber(inputValue) {
    const regExp = new RegExp(ValidationRegexPatterns.doorPattern);
    return regExp.test(inputValue);
  }
  isValidMexicoPostalCode(inputValue) {
    return new RegExp(ValidationRegexPatterns.mxPostalCode).test(inputValue);
  }
  isValidUsZipCode(inputValue) {
    return new RegExp(ValidationRegexPatterns.usZipCode).test(inputValue);
  }
  isValidCanadianPostalCode(inputValue) {
    return new RegExp(ValidationRegexPatterns.caPostalCode).test(inputValue);
  }

  isValidTrailerNumber(inputValue) {
    const numberTrailerRegExp = new RegExp(ValidationRegexPatterns.numberTrailerPattern).test(inputValue);
    const letterTrailerRegExp = new RegExp(ValidationRegexPatterns.letterTrailerPattern).test(inputValue);
    return numberTrailerRegExp || letterTrailerRegExp;
  }

  isValidTractorNumber(inputValue) {
    return new RegExp(ValidationRegexPatterns.tractorPattern).test(inputValue);
  }

  isValidProNumberCheckDigit(inputValue) {
    const proNumber = this.conditioningService.conditionProNumber(inputValue);
    if (proNumber === '' || proNumber.length < 9 || proNumber.length > 11) {
      return false;
    }
    // divide first 8 chars by 7 multiple remainder by 7
    const checkDigit = Number(proNumber.substring(8));
    const number = Number.parseInt(proNumber.substring(0, 8));
    const mod = (number % 7);
    return (mod === checkDigit);
  }

  // Added New function to verify entered Pro is of 9 or 11 digit
  proNumberCheckDigit(inputValue) {
    const proNumber = this.conditioningService.conditionProNumber(inputValue);
    if (proNumber === '' || proNumber.length < 9 || proNumber.length > 11) {
      return false;
    }
    return true;
  }

  isSicValid(value) {
    return value != null && value.length === 3 && new RegExp(ValidationRegexPatterns.sicValidationPattern).test(value);
  }
  isMadCodeValid(value): boolean {
    return value != null && value.length === 11 && new RegExp(ValidationRegexPatterns.madCodeValidationPattern).test(value);
  }
  isBillToMadCodeValid(value) {
    return value != null && value.length === 11 && new RegExp(ValidationRegexPatterns.billToMadCodeValidationPattern).test(value);
  }
  isValidNumeric(value) {
    return value != null && _.trim(value).length > 0 && !isNaN(value);
  }

  isValidAlphaNumeric(value): boolean {
    return value != null && /^[a-z0-9]+$/i.test(value);
  }

  containsLetters(value): boolean {
    return value != null && /[a-z]/i.test(value);
  }

  isNmfcValid(value) {
    return value != null && value.length >= 4 && new RegExp(ValidationRegexPatterns.nmfcValidationPattern).test(value);
  }
}
