import {TrailerStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class TrailerStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TrailerStatusCd ) {
      this._values.push(TrailerStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TrailerStatusCd {

    for ( const obj in TrailerStatusCd ) {
      if (TrailerStatusCd[obj] === value){
        return TrailerStatusCd[obj] as TrailerStatusCd;
      }
    }
  }
}

const TrailerStatusCdHelper = new TrailerStatusCdHelperEnumHelperClass();
export default TrailerStatusCdHelper;
