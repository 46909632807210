
export enum ShipmentMovementTypeCd {
	CLOSE = 'Close',
	DELIVERY = 'Delivery',
	FORCED_TO_LOCATION = 'ForcedToLocation',
	LOAD = 'Load',
	OUT_FOR_DELIVERY = 'OutForDelivery',
	PICKUP = 'Pickup',
	UNLOAD = 'Unload'}

