import { Directive, HostListener } from '@angular/core';

import { XpoPopover } from './popover.component';

/**
 * Element that when clicked, will close the parent popover component
 */
@Directive({
  selector: '[xpo-popover-close]',
  host: {
    class: 'xpo-Popover-close',
  },
})
export class XpoPopoverClose {
  constructor(private parentPopover: XpoPopover) {}

  @HostListener('click')
  handleElementClicked(): void {
    if (this.parentPopover) {
      this.parentPopover.closed.emit();
    }
  }
}
