import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { XpoAuthSwitchApiConfig } from '../config/switch-api-config.model';
import { XpoAuthApplication } from '../models/application.model';
import { XpoAuthUser } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class XpoAuthSwitchApiService {
  defaultHeaders: HttpHeaders = new HttpHeaders();
  baseUriUserApplications: string;

  constructor(private authSwitchApiConfig: XpoAuthSwitchApiConfig, private http: HttpClient) {
    this.defaultHeaders = new HttpHeaders();
    this.defaultHeaders.set('Accept', 'application/json');
    this.baseUriUserApplications = `${this.authSwitchApiConfig.apiUri}/userapplications`;
  }

  /**
   * @example
   *
   * getUserImageSrc(user)
   * // or
   * getUserImageSrc(user, 'http://picasaweb.google.com/data/entry/api/user/<hereYourUserIdOrYourEmail>?alt=json')
   *
   * @param user  The auth user object
   * @param uriOverride uri to override to get another profile picture
   * @returns The user's profile picture
   */
  getUserImageSrc(user: XpoAuthUser, uriOverride?: string): string {
    return uriOverride || `${this.authSwitchApiConfig.apiUri}/users/${user.profile.upn}/picture`;
  }

  /**
   * getUserDetails(user)
   *
   * @param user  The auth user object
   * @returns The user's details object
   */
  getUserDetails(user: XpoAuthUser): Observable<any> {
    const uri = `${this.authSwitchApiConfig.apiUri}/users/${user.profile.upn}/profile`;

    return this.http.get(uri, { headers: this.defaultHeaders });
  }

  /**
   * @example
   * getAllUserApplications(user)
   *
   * @param user  The auth user object
   * @param force  if true will force to rebuild the applications list
   * @returns The list of applications either forced get or cached
   */
  getAllUserApplications(user: XpoAuthUser, force: boolean = false): Observable<XpoAuthApplication[]> {
    let uri = `${this.baseUriUserApplications}/${user.profile.upn}`;

    if (force) {
      uri = `${uri}/true`;
    }

    return this.http.get<XpoAuthApplication[]>(uri, { headers: this.defaultHeaders });
  }

  /**
   * @example
   * getRecentUserApplications(user)
   *
   * @param user  The auth user object
   * @returns The list of recent applications
   */
  getRecentUserApplications(user: XpoAuthUser): Observable<any> {
    const uri = `${this.baseUriUserApplications}/${user.profile.upn}/recent`;

    return this.http.get(uri, { headers: this.defaultHeaders });
  }

  /**
   * @example
   * getBaseUriUserApplications()
   *
   * @returns The base Uri of the userapplications route
   */
  getBaseUriUserApplications(): string {
    return `${this.baseUriUserApplications}`;
  }
}
