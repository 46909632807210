export enum NotificationTime {
  SEVEN_DAYS_S = 10080,
  THREE_HOURS_S = 180,
  FIFTEEN_SECONDS_MS = 15000,
}

export enum NotificationStatus {
  READ = 'read',
  UNREAD = 'unread',
}
