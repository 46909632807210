import {DocumentFormTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DocumentFormTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DocumentFormTypeCd ) {
      this._values.push(DocumentFormTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DocumentFormTypeCd {

    for ( const obj in DocumentFormTypeCd ) {
      if (DocumentFormTypeCd[obj] === value){
        return DocumentFormTypeCd[obj] as DocumentFormTypeCd;
      }
    }
  }
}

const DocumentFormTypeCdHelper = new DocumentFormTypeCdHelperEnumHelperClass();
export default DocumentFormTypeCdHelper;
