import { Component, Input } from '@angular/core';
import { XpoLtlWindowService } from '../../services/window/window.service';
import { BehaviorSubject } from 'rxjs';
import { encode } from 'typescript-base64-arraybuffer';
import printJS from 'print-js';
import { DocumentDetails } from '../models/document-details.class';
import { DisplayState } from '../enums/display-state.enum';

@Component({
  selector: 'xpo-ltl-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  host: { class: 'xpo-LtlPdfViewer' },
})
export class XpoLtlPdfViewerComponent {
  readonly DisplayState = DisplayState;
  errorMessage: string = undefined;

  @Input() documentDetails: DocumentDetails;
  @Input() showPrint: boolean = true;

  state$ = new BehaviorSubject<DisplayState>(DisplayState.showPdf);

  constructor(private windowService: XpoLtlWindowService) {}

  afterPdfLoadComplete(event) {
    this.documentDetails.currentPage = 1;
    this.documentDetails.totalPages = event.numPages;
  }

  onPdfError(error) {
    this.errorMessage = error;
    this.state$.next(DisplayState.error);
  }

  zoom(direction: number) {
    this.documentDetails.zoomLevel += direction / 10;
  }

  rotate(direction: number) {
    this.documentDetails.pageRotation += direction * 90;
  }

  navigate(direction: number) {
    if (
      (this.documentDetails.currentPage > 1 && direction < 0) ||
      (this.documentDetails.currentPage < this.documentDetails.totalPages && direction > 0)
    ) {
      this.documentDetails.currentPage += direction;
    }
  }

  handleDownloadClicked() {
    this.windowService.generateDownloadFile(
      'application/pdf',
      this.documentDetails.document,
      this.documentDetails.filename
    );
  }

  handlePrintClicked() {
    printJS({ printable: encode(this.documentDetails.document), type: 'pdf', base64: true });
  }
}
