import {DriverTurnTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DriverTurnTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DriverTurnTypeCd ) {
      this._values.push(DriverTurnTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DriverTurnTypeCd {

    for ( const obj in DriverTurnTypeCd ) {
      if (DriverTurnTypeCd[obj] === value){
        return DriverTurnTypeCd[obj] as DriverTurnTypeCd;
      }
    }
  }
}

const DriverTurnTypeCdHelper = new DriverTurnTypeCdHelperEnumHelperClass();
export default DriverTurnTypeCdHelper;
