import {EmployeeIdTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class EmployeeIdTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EmployeeIdTypeCd ) {
      this._values.push(EmployeeIdTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EmployeeIdTypeCd {

    for ( const obj in EmployeeIdTypeCd ) {
      if (EmployeeIdTypeCd[obj] === value){
        return EmployeeIdTypeCd[obj] as EmployeeIdTypeCd;
      }
    }
  }
}

const EmployeeIdTypeCdHelper = new EmployeeIdTypeCdHelperEnumHelperClass();
export default EmployeeIdTypeCdHelper;
