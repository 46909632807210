import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { XpoLoginUser } from '../model/user';

const ACCESS_KEY = '.AccessToken';
const REFRESH_KEY = '.RefreshToken';
const USER_INFO_KEY = '.UserInfo';
const ACCESS_TOKEN_EXPIRATION_KEY = '.AccessTokenExpiration';
const REFRESH_TOKEN_EXPIRATION_KEY = '.RefreshTokenExpiration';

export interface IAccessData {
  expires_in: number;
  access_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
}

/**
 * Manages the logged-in user and access tokens
 */
@Injectable()
export class LoginService {

  private _refreshTokenExpirationMultiplier = 2;

  private config;
  private _cachedUser: XpoLoginUser;

  constructor(private http: HttpClient, private configManager: ConfigManagerService) { }

  private getConfigValue(setting: string): string {
    if (!this.config) {
      this.config = {
        appName: this.configManager.getSetting('appName'),
        apiUrl: this.configManager.getSetting('apiUrl'),
        secretToken: this.configManager.getSetting('secretToken'),
        scopeOptions: this.configManager.getSetting('scopeOptions'),
      };
    }

    return this.config[setting];
  }

  /**
   * Get the logged in user detail
   * @param loggedInUserEndpoint End point i.e. 'shipment/1.0/appusers/logged-in-user'
   */
  public getLoggedInUser(loggedInUserEndpoint: string): Observable<XpoLoginUser> {
    if (this._cachedUser) {
      return of(this._cachedUser);
    }
    return this.http.get(`${this.configManager.getSetting('apiUrl')}${loggedInUserEndpoint}`)
      .pipe(
        map(response => response['data'] as XpoLoginUser),
        tap(user => this._cachedUser = user)
      );
  }

  /**
   * Get access token
   */
  public getAccessToken(): Observable<string> {
    if (this.isAuthorized()) {
      const token: string = <string>localStorage.getItem(this.getConfigValue('appName') + ACCESS_KEY);
      return of(token);
    } else {
      return of('');
    }
  }

  /**
   * Handles refreshToken requests. Stores returned access token and refresh token in
   * local storage along with expiration time.
   *
   */
  public loadTokensFromRefresh(): Observable<any> {
    const body = 'grant_type=refresh_token&refresh_token=' + this.getRefreshToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Authorization': 'Basic ' + this.getConfigValue('secretToken')
    });

    const options = { headers: headers };

    return this.http.post<IAccessData>(this.getConfigValue('apiUrl') + 'token', body, options).pipe(
      map(accessData => {
        this.saveAccessData(accessData);
      })
    );

  }

  /**
   * Handles secret token requests. Stores returned access token and refresh token in
   * local storage along with expiration time.
   *
   */
  public loadTokensFromUser(username: string, password: string): Observable<any> {
    let body = 'grant_type=password&password=' + password + '&username=' + username;

    // check for scope entry
    if (this.getConfigValue('scopeOptions') && this.getConfigValue('scopeOptions').length > 0) {
      body += '&scope=' + this.getConfigValue('scopeOptions');
    }

    // loading username
    this.setAssociatedUserName(username);

    body = encodeURI(body);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Authorization': 'Basic ' + this.getConfigValue('secretToken')
    });

    const options = { headers: headers };

    return this.http.post<IAccessData>(this.getConfigValue('apiUrl') + 'token', body, options).pipe(
      map(accessData => {
        this.saveAccessData(accessData);
      })
    );
  }


  /**
   * Returns true if the user is currently authorized. If not, removees invalid access-key
   * and returns false.
   */
  public isAuthorized(): boolean {
    let authorize = false;
    if (Date.now() < this.getAccessTokenExpiration().getTime()) {
      authorize = true;
    } else {
      localStorage.removeItem(this.getConfigValue('appName') + ACCESS_KEY);
    }

    return authorize;
  }

  public getAssociatedUserName(): { user: string, isAuthenticated: boolean } {
    const userInfo = { user: undefined, isAuthenticated: false };
    if (this.isAuthorized()) {
      userInfo.user = localStorage.getItem(this.getConfigValue('appName') + USER_INFO_KEY);
      userInfo.isAuthenticated = true;
    }
    return userInfo;
  }

  public setAssociatedUserName(userName: string) {
    localStorage.setItem(this.getConfigValue('appName') + USER_INFO_KEY, userName);
  }

  /**
   * Get refresh token
   */
  public getRefreshToken(): string {
    let token: string;
    if (Date.now() < this.getRefreshTokenExpiration().getTime()) {
      token = <string>localStorage.getItem(this.getConfigValue('appName') + REFRESH_KEY);
    }
    return token;
  }

  /**
   * Set access token
   */
  public setAccessToken(token: string) {
    localStorage.setItem(this.getConfigValue('appName') + ACCESS_KEY, token);
  }

  /**
   * Set refresh token
   */
  public setRefreshToken(token: string) {
    localStorage.setItem(this.getConfigValue('appName') + REFRESH_KEY, token);
  }

  public setAccessTokenExpiration(dt: Date) {
    localStorage.setItem(this.getConfigValue('appName') + ACCESS_TOKEN_EXPIRATION_KEY, JSON.stringify(dt));
  }

  /**
   * If access token expiration date exist in local storage then return it, else return current date.
   */
  public getAccessTokenExpiration() {
    try {
      const s = <string>localStorage.getItem(this.getConfigValue('appName') + ACCESS_TOKEN_EXPIRATION_KEY);
      return new Date(Date.parse(JSON.parse(s)));
    } catch (err) {
      return new Date();
    }
  }

  public setRefreshTokenExpiration(dt: Date) {
    localStorage.setItem(this.getConfigValue('appName') + REFRESH_TOKEN_EXPIRATION_KEY, JSON.stringify(dt));
  }

  public getRefreshTokenExpiration() {
    try {
      const s = <string>localStorage.getItem(this.getConfigValue('appName') + REFRESH_TOKEN_EXPIRATION_KEY);
      return new Date(Date.parse(JSON.parse(s)));
    } catch (err) {
      return new Date();
    }
  }

  public saveAccessData(accessData: IAccessData) {

    const accessTokenExpiration = new Date();
    const refreshTokenExpiration = new Date();
    accessTokenExpiration.setTime(accessTokenExpiration.getTime() + ((accessData.expires_in - 240) * 1000));
    refreshTokenExpiration.setTime(refreshTokenExpiration.getTime() +
      (accessData.expires_in * 1000 * this._refreshTokenExpirationMultiplier));

    this.setAccessToken(accessData.access_token);
    this.setRefreshToken(accessData.refresh_token);
    this.setAccessTokenExpiration(accessTokenExpiration);
    this.setRefreshTokenExpiration(refreshTokenExpiration);
  }

  /**
   * Remove tokens from local storage
   */
  public clear() {
    localStorage.removeItem(this.getConfigValue('appName') + ACCESS_KEY);
    localStorage.removeItem(this.getConfigValue('appName') + REFRESH_KEY);
    localStorage.removeItem(this.getConfigValue('appName') + ACCESS_TOKEN_EXPIRATION_KEY);
    localStorage.removeItem(this.getConfigValue('appName') + REFRESH_TOKEN_EXPIRATION_KEY);
    localStorage.removeItem(this.getConfigValue('appName') + USER_INFO_KEY);
  }
}
