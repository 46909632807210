import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { XpoErrorPage } from './error-page';
import { XpoErrorPageModule } from './error-page.module';
import { XpoForbiddenInterceptor } from './forbidden-interceptor';

const routes: Routes = [{ path: '403', component: XpoErrorPage, data: { statusCode: 403 } }];

@NgModule({
  imports: [CommonModule, XpoErrorPageModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: XpoForbiddenInterceptor, multi: true }],
})
export class XpoForbiddenRoutingModule {}
