import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'xpoThousandsSeparator',
})
export class XpoThousandsSeparator implements PipeTransform {
  transform(value: number): string {
    return !!value ? value.toLocaleString('en-US') : '';
  }
}
