import {SnapshotDataTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class SnapshotDataTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in SnapshotDataTypeCd ) {
      this._values.push(SnapshotDataTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): SnapshotDataTypeCd {

    for ( const obj in SnapshotDataTypeCd ) {
      if (SnapshotDataTypeCd[obj] === value){
        return SnapshotDataTypeCd[obj] as SnapshotDataTypeCd;
      }
    }
  }
}

const SnapshotDataTypeCdHelper = new SnapshotDataTypeCdHelperEnumHelperClass();
export default SnapshotDataTypeCdHelper;
