import { formatDate as angularFormatDate } from '@angular/common';
import { XpoFacetFilterSortOptions } from '../enums/sort-options';

const DEFAULT_LOCALIZATION = 'en-US';

// TODO: See if we should replace this method with something else
export const getUniqueFilterId = (size?: number) => {
  const stringSize = size ? size : 5;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < stringSize; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const sortItems = (sort: XpoFacetFilterSortOptions, current: string, next: string): number =>
  sort === XpoFacetFilterSortOptions.ASC ? (current > next ? 1 : -1) :
    (sort === XpoFacetFilterSortOptions.DESC ? (current > next ? -1 : 1) : 0);

export const formatDate = (date: Date, format: string) => angularFormatDate(date, format, DEFAULT_LOCALIZATION);
