
export enum OptimizerResultReasonCd {
	EXTRATRLR = 'EXTRATRLR',
	LARGEPSE = 'LARGEPSE',
	UNRTD_1 = 'UNRTD1',
	UNRTD_2 = 'UNRTD2',
	UNRTD_3 = 'UNRTD3',
	UNRTD_4 = 'UNRTD4',
	UNRTD_5 = 'UNRTD5'}

