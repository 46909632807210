import {WorkerStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class WorkerStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in WorkerStatusCd ) {
      this._values.push(WorkerStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): WorkerStatusCd {

    for ( const obj in WorkerStatusCd ) {
      if (WorkerStatusCd[obj] === value){
        return WorkerStatusCd[obj] as WorkerStatusCd;
      }
    }
  }
}

const WorkerStatusCdHelper = new WorkerStatusCdHelperEnumHelperClass();
export default WorkerStatusCdHelper;
