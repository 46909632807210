import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Directive } from '@angular/core';

@Directive({
  selector: '[xpoIconButtonGroup]',
  host: { class: 'xpo-ButtonGroup--icon' },
})
export class XpoIconButtonGroup {}

@Component({
  selector: 'xpo-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-ButtonGroup',
  },
})
export class XpoButtonGroup {}
