import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { XpoAutofocusDirective } from './autofocus.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [XpoAutofocusDirective],
  exports: [XpoAutofocusDirective],
})
export class XpoAutofocusModule {}
