import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { XpoResponsiveLoadingSpinnerModule } from '@xpo-ltl/ngx-ltl-core';

import { XpoDataDrawer, XpoDataDrawerHeader } from './data-drawer.component';

@NgModule({
  declarations: [XpoDataDrawer, XpoDataDrawerHeader],
  imports: [CommonModule, MatButtonModule, XpoResponsiveLoadingSpinnerModule, MatIconModule],
  exports: [XpoDataDrawer, XpoDataDrawerHeader],
})
export class XpoDataDrawerModule {}
