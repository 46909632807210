import { XpoGridBoardColumnFactory } from './grid-board-column-factory.model';
import { XpoGridBoardColumn } from './grid-board-column.model';

export class XpoGridBoardInMemoryColumnFactory implements XpoGridBoardColumnFactory {
  private readonly columns: Map<string, XpoGridBoardColumn>;

  constructor(columns: Map<string, XpoGridBoardColumn> | XpoGridBoardColumn[]) {
    // support taking in array or a map
    if (Array.isArray(columns)) {
      this.columns = new Map<string, XpoGridBoardColumn>();
      columns.forEach((obj) => this.columns.set(obj['field'], obj));
    } else {
      this.columns = columns;
    }
  }

  get(columnId: string): XpoGridBoardColumn {
    return this.columns.get(columnId);
  }

  getAll(columnIds: string[]): XpoGridBoardColumn[] {
    return columnIds.map((id) => this.get(id)).filter((c) => !!c);
  }
}
