
export enum EquipmentTypeCd {
	DOLLY = 'Dolly',
	L_H_TRAILER = 'L/HTrailer',
	OTHER = 'Other',
	RENTAL = 'Rental',
	STRAIGHT_TRUCK = 'StraightTruck',
	TRACTOR = 'Tractor',
	VAN = 'Van'}

