import {NetExplosiveMassUomCd} from '../';
import {EnumHelper} from './enum-helper';

export class NetExplosiveMassUomCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in NetExplosiveMassUomCd ) {
      this._values.push(NetExplosiveMassUomCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): NetExplosiveMassUomCd {

    for ( const obj in NetExplosiveMassUomCd ) {
      if (NetExplosiveMassUomCd[obj] === value){
        return NetExplosiveMassUomCd[obj] as NetExplosiveMassUomCd;
      }
    }
  }
}

const NetExplosiveMassUomCdHelper = new NetExplosiveMassUomCdHelperEnumHelperClass();
export default NetExplosiveMassUomCdHelper;
