import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { XpoLtlRoleSwitcherData } from './role-switcher-data';

@Component({
  selector: 'xpo-ltl-role-switcher',
  templateUrl: './role-switcher.component.html',
  styleUrls: ['./role-switcher.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpoLtlRoleSwitcherComponent {
  constructor(
    private dialogRef: MatDialogRef<XpoLtlRoleSwitcherComponent>,
    @Inject(MAT_DIALOG_DATA) public data: XpoLtlRoleSwitcherData
  ) {}

  selectedRole: string;

  selectRoleClick() {
    this.dialogRef.close(this.selectedRole);
  }
}
