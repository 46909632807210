import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { cloneDeep, some } from 'lodash-es';
import {
  CustomFilterParams,
  filterFormats,
  Operators,
  OperatorText,
  OptionListValue,
  XpoFilterConditionCriteria,
} from '../../../../../models/index';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';

@Component({
  templateUrl: './equals.component.html',
  styleUrls: ['./equals.component.scss'],
})
export class XpoConditionEnumEqualsComponent extends XpoConditionBase implements XpoFilterCondition, AfterViewInit {
  static text: string = OperatorText.None; // value used in dropdown
  static operator: Operators = Operators.Equals;
  readonly ALL_DISPLAY_VALUE = 'all';
  defValuesChecked: boolean = false;
  isNegative: boolean = false;
  model: any = [];
  values: OptionListValue;
  allOptionsSelected: boolean = false;

  @Output() inputChanged = new EventEmitter();

  set customFilterParams(params: CustomFilterParams) {
    this.values = this.mapValues(params.enumValues);

    // adding the empty condition
    this.values = Object.assign(
      {
        [Operators.Empty]: {
          text: OperatorText.Empty,
          checked: this.defValuesChecked,
        },
      },
      this.values
    );
  }

  constructor() {
    super(filterFormats.default);
  }

  ngAfterViewInit(): void {
    this.model.forEach((item) => {
      this.values[item].checked = true;
    });
    this.checkAllSelection();
  }

  mapValues(enumValues: any): OptionListValue {
    const values: OptionListValue = {};
    const checked = this.defValuesChecked;

    Object.keys(enumValues).map((key) => {
      values[key] = {
        text: enumValues[key],
        checked: checked,
      };
    });
    return values;
  }

  validate(cellValue: string & string[]): boolean {
    return Array.isArray(cellValue) ? this.arrayMatchChecker(cellValue) : this.matchAnyChecked(cellValue);
  }

  private arrayMatchChecker(cellValue: string[]): boolean {
    return cellValue.length === 0 ? this.matchAnyChecked('') : some(cellValue, (value) => this.matchAnyChecked(value));
  }

  private matchAnyChecked(cellValue: string): boolean {
    return !this.model || some(this.model, (item) => this.hasCoincidence(item, cellValue));
  }

  private hasCoincidence(item: string, value: string): boolean {
    return (item === Operators.Empty && !value.toString().trim()) || item === value.toString().trim();
  }

  sortValues(): number {
    // preserve map order
    return 1;
  }

  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.Equals,
      value: this.model,
      display: this.getModelDisplayValues(),
    };
  }

  selectAll(e: MatCheckboxChange): void {
    this.model = [];
    Object.keys(this.values).forEach((val) => {
      this.values[val].checked = e.checked;
      const updatedModel = this.updateModel(e.checked, val);
      this.model = updatedModel;
    });
    this.allOptionsSelected = e.checked;
  }

  checkAllSelection(): void {
    this.allOptionsSelected = Object.keys(this.values).length === this.model.length && this.checkAllSelected();
  }

  onInputChange(e: MatCheckboxChange, value: string): void {
    const updatedModel = this.updateModel(e.checked, value);
    this.model = updatedModel;
    this.inputChanged.emit(updatedModel.join(','));
    this.checkAllSelection();
    this.validateFormat();
  }

  updateModel(checked, value): any {
    const updatedModel = cloneDeep(this.model);
    if (checked) {
      updatedModel.push(value);
    } else {
      // this.allOptionsSelected = false;
      const index = updatedModel.indexOf(value);
      if (index > -1) {
        updatedModel.splice(index, 1);
      }
    }
    return updatedModel;
  }
  private getModelDisplayValues(): string {
    if (this.allOptionsSelected) {
      return this.ALL_DISPLAY_VALUE;
    }
    const mappedValues: string[] = [];
    this.model.forEach((element) => {
      mappedValues.push(this.values[element].text);
    });
    // if empty was selected, switch it to last for displaying purposes
    if (mappedValues[0] === OperatorText.Empty) {
      mappedValues.push(mappedValues.shift());
    }
    return mappedValues.join(', ');
  }

  checkAllSelected(): boolean {
    let allChecked = true;
    Object.keys(this.values).map((val) => {
      if (!this.values[val].checked) {
        allChecked = false;
      }
    });
    return allChecked;
  }
}
