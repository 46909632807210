
export enum StopWindowCd {
	APPOINTMENT = 'Appointment',
	CONFLICT = 'Conflict',
	CUSTOMER_OPERATIONAL_HOURS = 'CustomerOperationalHours',
	CUSTOMER_PROFILE_TIME = 'CustomerProfileTime',
	GUARANTEED_BY_NOON = 'GuaranteedByNoon',
	MIXED_SOURCE = 'MixedSource',
	TIME_DATE_CRITICAL = 'TimeDateCritical'}

