import { XpoAgGridBoardColumn } from '@xpo-ltl/ngx-ltl-board-grid';

export const MODULE_GROUPS_COLUMNS: XpoAgGridBoardColumn[] = [
  {
    headerName: 'Actions',
    field: 'actions',
    sortable: false,
    cellRenderer: 'ActionsCellRenderer',
    minWidth: 230,
    pinned: 'left',
    floatingFilter: false,
  },
  {
    headerName: 'Name',
    field: 'name',
    cellRenderer: 'NameCellRenderer',
    minWidth: 300,
    pinned: 'left',
  },
  {
    headerName: 'Created By',
    field: 'createdBy',
    minWidth: 200,
  },
  {
    headerName: 'Created',
    field: 'created',
    cellRenderer: 'DateTimeCellRenderer',
    minWidth: 200,
  },
  {
    headerName: 'Last Run',
    field: 'lastRun',
    cellRenderer: 'DateTimeCellRenderer',
    minWidth: 200,
  },
  {
    headerName: 'Last Modified',
    field: 'lastModified',
    cellRenderer: 'DateTimeCellRenderer',
    minWidth: 200,
  },
  {
    headerName: 'Run Status',
    field: 'runStatus',
    minWidth: 200,
    cellRenderer: 'StatusCellRenderer',
  },
];
