import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'xpo-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-DownloadButton',
  },
})
export class XpoDownloadButton implements AfterViewInit {
  loading: boolean = false;
  hasContent: boolean = true;

  @Input()
  dataSource: Observable<any>;

  @Input()
  handleExport: () => Observable<any>;

  @Input()
  fileName: string;

  @Input()
  color: string = 'primary';

  @ViewChild('textContent')
  textContent: ElementRef;

  constructor(private changeDetection: ChangeDetectorRef) {
    console.warn('\'xpo-DownloadButton\' component is deprecated; use directive \'[xpoDownloadButton]\' instead.');
  }

  ngAfterViewInit(): void {
    this.hasContent = !!this.textContent.nativeElement.childNodes.length;
    this.changeDetection.detectChanges();
  }

  download(): void {
    const dataSource = this.buildDataSource();
    if (!dataSource) {
      return;
    }

    this.loading = true;

    dataSource
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetection.markForCheck();
        }),
        take(1)
      )
      .subscribe(
        (data: File) => {
          saveAs(data, this.fileName || data.name);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  private buildDataSource(): Observable<any> {
    if (this.dataSource && this.handleExport) {
      throw new Error('XpoDownloadButton: dataSource and handleExport should not be both provided');
    }

    let currentDataSource = this.dataSource;

    if (!currentDataSource && this.handleExport) {
      currentDataSource = this.handleExport();
    }

    return currentDataSource;
  }
}
