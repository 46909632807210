
export enum ChangeRsnCd {
	ACCIDENT = 'Accident',
	BREAKDOWN = 'Breakdown',
	CUSTOMS = 'Customs',
	DEPT_OF_TRANSP = 'DeptOfTransp',
	DRIVER = 'Driver',
	ENTRY_ERROR = 'EntryError',
	ETA_ADJUSTMENT = 'ETAAdjustment',
	HRS_OF_SERVICE = 'HrsOfService',
	ROAD_CLOSURE = 'RoadClosure',
	SCHD_TERMINATED = 'SchdTerminated',
	TRAFFIC = 'Traffic',
	WEATHER = 'Weather'}

