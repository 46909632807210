import {DeviceTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DeviceTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DeviceTypeCd ) {
      this._values.push(DeviceTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DeviceTypeCd {

    for ( const obj in DeviceTypeCd ) {
      if (DeviceTypeCd[obj] === value){
        return DeviceTypeCd[obj] as DeviceTypeCd;
      }
    }
  }
}

const DeviceTypeCdHelper = new DeviceTypeCdHelperEnumHelperClass();
export default DeviceTypeCdHelper;
