import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { shareReplay, takeUntil, tap } from 'rxjs/operators';

import { XpoPopover } from '@xpo-ltl/ngx-ltl-core';
import { XpoCriteriaStore } from '../filters.service';
import { XpoFilter, XpoFilterComponent } from '../models/index';

@Component({
  selector: 'xpo-filter-chip',
  templateUrl: 'filter-chip.component.html',
  styleUrls: ['filter-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-FilterChip', '[attr.id]': 'id' },
})
export class XpoFilterChip implements OnDestroy, OnInit {
  /** Id of filter chip */
  id: string;

  /** The display value for the filter */
  displayValue$: Observable<string>;

  disabled$: Observable<boolean>;
  private disabledValue: boolean;

  /** The filter component */
  private filterComponent: XpoFilterComponent;
  /** Whether the filter is open. */
  private opened = false;
  /** Emits a value when the filter is closed */
  private filterClosed$ = new Subject<void>();
  /** Used for component destroy */
  private unsubscribe$ = new Subject<void>();

  /** Template container for the filter component */
  @ViewChild('filterContainer', {
    read: ViewContainerRef,
  })
  filterContainerRef: ViewContainerRef;

  /** Popover container for the filter */
  @ViewChild('popover')
  popover: XpoPopover;

  /** input filter object */
  @Input()
  filter: XpoFilter;

  @Input()
  criteriaStore: XpoCriteriaStore;

  constructor(private changeDetectorRef: ChangeDetectorRef, private factoryResolver: ComponentFactoryResolver) {}

  ngOnDestroy(): void {
    this.close();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.criteriaStore.criteria$.pipe(takeUntil(this.unsubscribe$)).subscribe((c) => {
      const val = c != null ? c[this.filter.field] || null : null;
      const displayVal = this.filter.getDisplayValue(val);

      this.displayValue$ = displayVal instanceof Observable ? displayVal : of(displayVal);
      this.changeDetectorRef.markForCheck();
    });

    this.filterClosed$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      // When the filter popup is closed due to outside click, apply the filters if in container mode
      if (this.criteriaStore.applyFilterStrategy === 'container') {
        this.filterComponent.applyCriteria(true);
      }
    });

    if (this.filter.disabled$) {
      this.disabled$ = this.filter.disabled$.pipe(
        tap((x) => (this.disabledValue = x)),
        shareReplay()
      );
    }

    this.id = `${this.criteriaStore.id}-filterChip-${this.filter.field}`;
  }

  /** Open the filter drop-down */
  open(): void {
    if (this.disabledValue) {
      return;
    }

    if (!this.opened) {
      this.opened = true;

      // Create the filter component and add it to view child template
      const factory = this.factoryResolver.resolveComponentFactory(this.filter.filterComponentType);
      const component: ComponentRef<XpoFilterComponent> = this.filterContainerRef.createComponent(factory);
      this.filterComponent = component.instance;
      this.filterComponent.configuration = this.filter;
      this.filterComponent.field = this.filter.field;
      this.filterComponent.filtersService = this.criteriaStore;
      this.filterComponent.closePopup
        .pipe(takeUntil(this.filterClosed$), takeUntil(this.unsubscribe$))
        .subscribe(() => this.popover.closed.emit());
    }
  }

  /** Close the Filter drop-down. */
  close(): void {
    if (!this.opened) {
      return;
    }

    this.filterClosed$.next();
    this.filterContainerRef.clear();
    this.opened = false;
  }
}
