import { GridApi, ValueSetterParams } from 'ag-grid-community';
import { cloneDeep, reject } from 'lodash';
import { formatChecker } from '../models/ag-grid-inline-editing-col-def.interface';
import {
  DataChanges,
  DataToCheckValidity,
  InlineEditingErrors,
  NewDataChange,
} from '../models/ag-grid-inline-editing.interface';
import { XpoGridColumnsUtil } from '../util/column-util';

export abstract class XpoInlineEditingBase {
  static EMPTY_ERROR_DESCRIPTION = 'Field should be completed';
  private originalData: Array<any>;
  protected errors: InlineEditingErrors[];
  protected dataChanges: DataChanges[];
  abstract isEditing: boolean;
  abstract gridApi: GridApi;
  abstract gridData: any;
  abstract keyField: string;

  constructor() {
    this.dataChanges = [];
    this.errors = [];
  }

  onStartEditing(): void {
    this.toggleEditing();
    this.gridApi.refreshCells();
    this.clearErrors();
    this.originalData = cloneDeep(this.gridData);
  }

  onCancelEditing(): void {
    this.clearErrors();
    this.toggleEditing();
    this.gridApi.stopEditing(true);
    this.gridApi.refreshCells();
    this.dataChanges = [];
    this.gridApi.clearFocusedCell();
    this.gridData = this.originalData;
  }

  save(): DataChanges[] {
    const dataSaved = this.getDataChanges();
    this.dataChanges = [];
    this.toggleEditing();
    this.gridApi.stopEditing();
    this.clearErrors();
    this.gridApi.refreshCells();
    return dataSaved;
  }

  handleValueSetter(params: ValueSetterParams, keyField: string): void {
    const { parentObj, nestedPath } = this.getReferences(params);
    if (parentObj[nestedPath] !== params.newValue) {
      parentObj[nestedPath] = params.newValue;
      this.registerValueChanged(keyField, params);
    }
  }

  isRowInvalid(rowId: number): boolean {
    const errorCell = this.errors.find((item) => item.id === rowId);
    return errorCell !== undefined;
  }

  isCellInvalid(rowId: number, column: string): boolean {
    const errorCell = this.getError(rowId, column);
    return errorCell !== undefined;
  }

  isCellEdited(rowId, column): boolean {
    const editedRow = this.dataChanges.find((item) => item.id === rowId);
    return editedRow && this.existProperty(editedRow.columns[column]);
  }

  isInvalidForm(): boolean {
    this.gridApi.stopEditing();
    this.gridApi.refreshCells();
    return this.errors.length > 0;
  }

  getErrorDescription(params, column: string): string {
    let tooltipText: string;
    if (params && this.isCellInvalid(params.data[this.keyField], column)) {
      const rowId = params.data[this.keyField];
      const errorCell = this.getError(rowId, column);
      tooltipText = errorCell.errorDescription;
    }
    return tooltipText;
  }

  addError(dataToCheck: DataToCheckValidity, description: string): void {
    this.errors.push({
      keyField: dataToCheck.editedRow.keyField,
      id: dataToCheck.editedRow.id,
      errorDescription: description,
      column: dataToCheck.column,
    });
  }

  getDataChanges(): DataChanges[] {
    this.gridApi.stopEditing();
    return this.dataChanges;
  }

  registerValueChanged(keyField: string, params): void {
    const valueChanged: NewDataChange = {
      rowId: params.data[keyField],
      column: params.colDef.field,
      newValue: params.newValue,
    };
    let editedRow: DataChanges = this.dataChanges.find((item) => item.id === valueChanged.rowId);
    editedRow =
      editedRow !== undefined ? this.editChange(editedRow, valueChanged) : this.addNewChange(valueChanged, keyField);
    this.checkInvalid({ editedRow: editedRow, column: valueChanged.column }, params);
  }

  private addNewChange(valueChanged: NewDataChange, keyField: string): DataChanges {
    const newEditedRow = {
      keyField: keyField,
      id: valueChanged.rowId,
      columns: { [valueChanged.column]: valueChanged.newValue },
    };
    this.dataChanges.push(newEditedRow);
    return newEditedRow;
  }

  private editChange(alreadyEditedRow: DataChanges, valueChanged: NewDataChange): DataChanges {
    // If the column was already edited
    if (alreadyEditedRow.columns[valueChanged.column]) {
      const oldValue = this.getOldValue(valueChanged.rowId, valueChanged.column);
      // If the value is equal to the original one
      if (oldValue == valueChanged.newValue) {
        this.removeChange(valueChanged.column, valueChanged.rowId);
      } else {
        alreadyEditedRow.columns[valueChanged.column] = valueChanged.newValue;
      }
    } else {
      alreadyEditedRow.columns[valueChanged.column] = valueChanged.newValue;
    }
    // If exist, remove the error from the error list to be analyzed again
    this.removeError(valueChanged);
    return alreadyEditedRow;
  }

  private checkInvalid(editedData: DataToCheckValidity, params): void {
    const validations: formatChecker[] = [
      this.isEmptyField(editedData, params),
      ...this.getOwnValidators(editedData, params),
    ];
    validations.some((validation) => {
      if (validation && validation.fail) {
        this.addError(editedData, validation.errorDescription);
      }
      return validation && validation.fail;
    });
  }

  private getOwnValidators(editedData: DataToCheckValidity, params): formatChecker[] {
    // Letting this method be prepared for getting multiple validators in the future
    return params.colDef.cellConfig ? [params.colDef.cellConfig.formatValidator(editedData)] : [];
  }

  private toggleEditing(): void {
    this.isEditing = !this.isEditing;
  }

  private clearErrors(): void {
    this.errors = [];
  }

  private removeError(valueChanged: NewDataChange): void {
    this.errors = reject(this.errors, (error: InlineEditingErrors) => {
      return error.id === valueChanged.rowId && error.column === valueChanged.column;
    });
  }

  private isEmptyField(editedData: DataToCheckValidity, params): formatChecker {
    return {
      fail:
        params.colDef.cellConfig &&
        !params.colDef.cellConfig.allowEmpty &&
        !editedData.editedRow.columns[editedData.column],
      errorDescription: XpoInlineEditingBase.EMPTY_ERROR_DESCRIPTION,
    };
  }

  private existProperty(prop): boolean {
    return prop !== '' && prop !== undefined;
  }

  private getError(rowId: number, column: string): InlineEditingErrors {
    return this.errors.find((item) => item.id === rowId && item.column === column);
  }

  private removeChange(column: string, rowId): void {
    const index = this.dataChanges.findIndex((item) => item.id === rowId);
    // Check that the repeated value is not the only one for that row
    if (Object.keys(this.dataChanges[index].columns).length > 1) {
      delete this.dataChanges[index].columns[column];
    } else {
      this.dataChanges.splice(index, 1);
    }
  }

  private getOldValue(rowId, column): any {
    const originalRow = Array.from(this.originalData).find((item) => item[this.keyField] === rowId);
    return originalRow ? originalRow[column] : null;
  }

  private getReferences(params: ValueSetterParams): any {
    // Evaluate if it's a nested property and get the reference to edit
    let parentObj: any, nestedPath: string;
    // Split path by last dot
    const lastDotIndex = params.colDef.field.lastIndexOf('.');
    // If lastDotIndex > -1 it's a simple obj without nesting
    if (lastDotIndex > -1) {
      const pathInit = params.colDef.field.substring(0, lastDotIndex);
      nestedPath = params.colDef.field.substring(lastDotIndex + 1, params.colDef.field.length);
      parentObj = XpoGridColumnsUtil.getColumnNestedValue(pathInit, params.data);
    } else {
      nestedPath = params.colDef.field;
      parentObj = params.data;
    }

    // Return the object and the last path ref
    return { parentObj, nestedPath };
  }
}
