import { Pipe, PipeTransform } from '@angular/core';
import { XpoLtlDocType } from './doc-type.enum';

@Pipe({
  name: 'xpoLtlDocType',
})
export class XpoLtlDocTypePipe implements PipeTransform {
  transform(value: XpoLtlDocType, args?: any): any {
    let convertedValue = '';
    switch (value) {
      case XpoLtlDocType.ActivityLogHistory:
        convertedValue = 'Activity Log History';
        break;
      case XpoLtlDocType.BillOfLading:
        convertedValue = 'Bill of Lading';
        break;
      case XpoLtlDocType.BillOfLadingAttachment:
        convertedValue = 'Bill Of Lading Attachment';
        break;
      case XpoLtlDocType.CheckSheet:
        convertedValue = 'Check Sheet';
        break;
      case XpoLtlDocType.ClaimForm:
        convertedValue = 'Claim Form';
        break;
      case XpoLtlDocType.ClaimNotes:
        convertedValue = 'Claim Notes';
        break;
      case XpoLtlDocType.ClaimsAttachment:
        convertedValue = 'Claims Attachment';
        break;
      case XpoLtlDocType.ClaimsProductionUnknown:
        convertedValue = 'Claims Production Unknown';
        break;
      case XpoLtlDocType.ClosedClaimFolder:
        convertedValue = 'Closed Claim Folder';
        break;
      case XpoLtlDocType.CustomerUpload:
        convertedValue = 'Customer Upload';
        break;
      case XpoLtlDocType.CustomsDocuments:
        convertedValue = 'Customs Document';
        break;
      case XpoLtlDocType.DeliveryExceptionPhoto:
        convertedValue = 'Delivery Exception Photo';
        break;
      case XpoLtlDocType.DeliveryReceipt:
        convertedValue = 'Delivery Receipt';
        break;
      case XpoLtlDocType.DeliveryReceiptAttachment:
        convertedValue = 'Delivery Receipt Attachment';
        break;
      case XpoLtlDocType.DeliveryReturnReceipt:
        convertedValue = 'Delivery Return Receipt';
        break;
      case XpoLtlDocType.FlySheet:
        convertedValue = 'Fly Sheet';
        break;
      case XpoLtlDocType.InMotionDimensionerPhoto:
        convertedValue = 'In-Motion Dimensioner Photo';
        break;
      case XpoLtlDocType.InspectionReport:
        convertedValue = 'Inspection Report';
        break;
      case XpoLtlDocType.InspectionReportPhoto:
        convertedValue = 'Inspection Report Photo';
        break;
      case XpoLtlDocType.InvoicePdf:
        convertedValue = 'Invoice';
        break;
      case XpoLtlDocType.LetterOfAuthority:
        convertedValue = 'Letter of Authority';
        break;
      case XpoLtlDocType.MatchMailAcknowledgement:
        convertedValue = 'Match Mail Acknowledgment';
        break;
      case XpoLtlDocType.NmfcClassInspectionCertificate:
        convertedValue = 'NMFC Class Inspection Certificate';
        break;
      case XpoLtlDocType.OSDPhoto:
        convertedValue = 'OS&D Photo';
        break;
      case XpoLtlDocType.WeightInspectionCertificate:
        convertedValue = 'Weight Inspection Certificate';
        break;
      case XpoLtlDocType.WeightAndResearchPhotos:
        convertedValue = 'Weight and Research Photos';
        break;
      case XpoLtlDocType.TrailerPhotoOpen:
        convertedValue = 'Trailer Photo Open';
        break;
      case XpoLtlDocType.TrailerPhotoClose:
        convertedValue = 'Trailer Photo Close';
        break;
      case XpoLtlDocType.TrailerPhotoProgressive:
        convertedValue = 'Trailer Photo Progressive';
        break;
      case XpoLtlDocType.All:
        convertedValue = 'All Documents';
        break;
    }
    return convertedValue;
  }

  transformBack(value: string, args?: any): any {
    let convertedValue: XpoLtlDocType;

    switch (value) {
      case 'Bill of Lading':
        convertedValue = XpoLtlDocType.BillOfLading;
        break;
      case 'Delivery Receipt':
        convertedValue = XpoLtlDocType.DeliveryReceipt;
        break;
      case 'Delivery Return Receipt':
        convertedValue = XpoLtlDocType.DeliveryReturnReceipt;
        break;
      case 'Customs Document':
        convertedValue = XpoLtlDocType.CustomsDocuments;
        break;
      case 'Customer Upload':
        convertedValue = XpoLtlDocType.CustomerUpload;
        break;
      case 'Inspection Report':
        convertedValue = XpoLtlDocType.InspectionReport;
        break;
      case 'Letter of Authority':
        convertedValue = XpoLtlDocType.LetterOfAuthority;
        break;
      case 'NMFC Class Inspection Certificate':
        convertedValue = XpoLtlDocType.NmfcClassInspectionCertificate;
        break;
      case 'Weight Inspection Certificate':
        convertedValue = XpoLtlDocType.WeightInspectionCertificate;
        break;
      case 'Weight and Research Photos':
        convertedValue = XpoLtlDocType.WeightAndResearchPhotos;
        break;
      case 'In-Motion Dimensioner Photo':
        convertedValue = XpoLtlDocType.InMotionDimensionerPhoto;
        break;
      case 'Invoice':
        convertedValue = XpoLtlDocType.InvoicePdf;
        break;
      case 'Claims Attachment':
        convertedValue = XpoLtlDocType.ClaimsAttachment;
        break;
      case 'DR Attachment':
        convertedValue = XpoLtlDocType.DeliveryReceiptAttachment;
        break;
      case 'BL Attachment':
        convertedValue = XpoLtlDocType.BillOfLadingAttachment;
        break;
      case 'OS&D Photo':
        convertedValue = XpoLtlDocType.OSDPhoto;
        break;
      case 'All Documents':
        convertedValue = XpoLtlDocType.All;
        break;
    }
    return convertedValue;
  }
}
