import {CustomerRequestQueueNameCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerRequestQueueNameCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerRequestQueueNameCd ) {
      this._values.push(CustomerRequestQueueNameCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerRequestQueueNameCd {

    for ( const obj in CustomerRequestQueueNameCd ) {
      if (CustomerRequestQueueNameCd[obj] === value){
        return CustomerRequestQueueNameCd[obj] as CustomerRequestQueueNameCd;
      }
    }
  }
}

const CustomerRequestQueueNameCdHelper = new CustomerRequestQueueNameCdHelperEnumHelperClass();
export default CustomerRequestQueueNameCdHelper;
