import {CustOperationNoteTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustOperationNoteTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustOperationNoteTypeCd ) {
      this._values.push(CustOperationNoteTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustOperationNoteTypeCd {

    for ( const obj in CustOperationNoteTypeCd ) {
      if (CustOperationNoteTypeCd[obj] === value){
        return CustOperationNoteTypeCd[obj] as CustOperationNoteTypeCd;
      }
    }
  }
}

const CustOperationNoteTypeCdHelper = new CustOperationNoteTypeCdHelperEnumHelperClass();
export default CustOperationNoteTypeCdHelper;
