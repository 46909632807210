import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

import { XpoAuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'xpo-authentication-silent',
  template: '',
})
export class XpoAuthenticationSilentComponent implements OnInit {
  constructor(private authenticationService: XpoAuthenticationService) {}

  ngOnInit(): void {
    this.authenticationService
      .completeSilentRefresh$()
      .pipe(take(1))
      .subscribe();
  }
}
