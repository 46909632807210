import {PickupSourceCd} from '../';
import {EnumHelper} from './enum-helper';

export class PickupSourceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PickupSourceCd ) {
      this._values.push(PickupSourceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PickupSourceCd {

    for ( const obj in PickupSourceCd ) {
      if (PickupSourceCd[obj] === value){
        return PickupSourceCd[obj] as PickupSourceCd;
      }
    }
  }
}

const PickupSourceCdHelper = new PickupSourceCdHelperEnumHelperClass();
export default PickupSourceCdHelper;
