import {DisputeCsvFileStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeCsvFileStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeCsvFileStatusCd ) {
      this._values.push(DisputeCsvFileStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeCsvFileStatusCd {

    for ( const obj in DisputeCsvFileStatusCd ) {
      if (DisputeCsvFileStatusCd[obj] === value){
        return DisputeCsvFileStatusCd[obj] as DisputeCsvFileStatusCd;
      }
    }
  }
}

const DisputeCsvFileStatusCdHelper = new DisputeCsvFileStatusCdHelperEnumHelperClass();
export default DisputeCsvFileStatusCdHelper;
