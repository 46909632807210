import {ProInUseCd} from '../';
import {EnumHelper} from './enum-helper';

export class ProInUseCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ProInUseCd ) {
      this._values.push(ProInUseCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ProInUseCd {

    for ( const obj in ProInUseCd ) {
      if (ProInUseCd[obj] === value){
        return ProInUseCd[obj] as ProInUseCd;
      }
    }
  }
}

const ProInUseCdHelper = new ProInUseCdHelperEnumHelperClass();
export default ProInUseCdHelper;
