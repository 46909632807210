import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatCalendarView } from '@angular/material/datepicker';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { XpoFilterCriteria } from '../../models/index';
import { XpoDateRangeFilterComponent } from '../date-range-filter.component';
import {
  XpoDateRangeFilterCriteria,
  XpoDateRangeFilterHelper,
  XpoDateRangeFilterTab,
  XpoDateRangeGroupByIntervalType,
  XpoDateRangeInterval,
  XpoDateRangeIntervalFactory,
  XpoQuickDate,
} from '../date-range-model/index';
import { XpoDateRangeGroupByFilter } from './date-range-group-by-filter';

@Component({
  selector: 'xpo-date-range-filter-group-by',
  templateUrl: './date-range-filter-group-by.component.html',
  styleUrls: ['./date-range-filter-group-by.component.scss', '../date-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-DateRangeFilterGroupBy xpo-DateRangeFilter', '[attr.id]': 'id' },
})
export class XpoDateRangeFilterGroupByComponent extends XpoDateRangeFilterComponent {
  readonly viewLevel: { [key in XpoDateRangeGroupByIntervalType]: MatCalendarView } = {
    month: 'year',
    quarter: 'year',
    year: 'multi-year',
    day: 'month',
    hour: 'month',
    minute: 'month',
    week: 'month',
  };

  tabLinks = [];
  currentTabModel: XpoDateRangeFilterTab;
  selectedTabIndex = 0;

  /** number of today - days to track */
  prevInput: number;

  /** the selected button */
  selectedQuickFilterOptionCode: string;

  /** number of today + days to track */
  nextInput: number;

  @ViewChild('next')
  nextElement: ElementRef;

  @ViewChild('prev')
  prevElement: ElementRef;

  private dateRangeInterval: XpoDateRangeInterval;
  private shownIntervals: string[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  quickFilterSelected(filterKey: XpoQuickDate): void {
    this.selectedQuickFilterOptionCode = filterKey;
    this.storeCriteria(XpoDateRangeFilterHelper.constructFilter(filterKey));
    this.updateCalendar();
  }

  onNextPrevKeydown(e: any): void {
    // if enter key, trigger the change event on the button
    if (e.keyCode === 13) {
      e.target.parentNode.click();
    } else {
      const isNumericOrFuncKey = e.keyCode <= 57 || (e.keyCode >= 96 && e.keyCode <= 105);
      if (!isNumericOrFuncKey) {
        e.preventDefault();
      }
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.dateRangeInterval = XpoDateRangeIntervalFactory.getDateRangeInterval(tabChangeEvent.index);
    this.currentTabModel = this.dateRangeInterval.getTabModel();
    if (tabChangeEvent.tab) {
      this.nextInput = 0;
      this.prevInput = 0;
    }
  }

  setNext(value: number): void {
    if (value !== this.nextInput) {
      this.nextElement.nativeElement.focus();
      this.nextInput = value;

      if (this.nextInput) {
        this.storeCriteria(this.dateRangeInterval.setNext(this.nextInput));
        this.selectedQuickFilterOptionCode = this.currentCriteria.selected;
        this.updateCalendar();
      }
    }
  }

  setPrev(value: number): void {
    if (value !== this.prevInput) {
      this.prevElement.nativeElement.focus();
      this.prevInput = value;

      if (this.prevInput) {
        this.storeCriteria(this.dateRangeInterval.setPrev(this.prevInput));
        this.selectedQuickFilterOptionCode = this.currentCriteria.selected;
        this.updateCalendar();
      }
    }
  }

  protected initialize(): void {
    super.initialize();
    const config = <XpoDateRangeGroupByFilter>this.configuration;

    if (config.displayOptions && config.displayOptions.shownIntervals) {
      this.shownIntervals = config.displayOptions.shownIntervals.slice(0);
    }
    this.updateTabLinks();
    this.dateRangeInterval = XpoDateRangeIntervalFactory.getDateRangeInterval(0);
    this.currentTabModel = this.dateRangeInterval.getTabModel();
  }

  protected onCriteriaModified(fieldValue: object, criteria: XpoFilterCriteria): void {
    const val = <XpoDateRangeFilterCriteria>fieldValue || <XpoDateRangeFilterCriteria>{};

    this.toDateValue = val.max;
    this.fromDateValue = val.min;
    this.selectedQuickFilterOptionCode = val.selected || 'date';
    this.prevInput = val.selected && val.selected.indexOf('minus') > -1 ? val.offset : null;
    this.nextInput = val.selected && val.selected.indexOf('plus') > -1 ? val.offset : null;
    this.selectedTabIndex = XpoDateRangeIntervalFactory.getPos(val['intervalType']);
    this.tabChanged({ index: this.selectedTabIndex, tab: null });

    this.changeDetectorRef.markForCheck();
  }

  private updateTabLinks(): void {
    if (this.shownIntervals.length > 0) {
      this.showSelectedIntervals();
    } else {
      this.showDefaultIntervals();
    }
  }

  private showSelectedIntervals(): void {
    let pos = 0;
    const tabLinks = [];
    const activeIntervals: XpoDateRangeInterval[] = [];

    XpoDateRangeIntervalFactory.getAllIntervals().forEach((interval) => {
      if (this.shownIntervals.indexOf(interval.label) > -1) {
        tabLinks.push({ pos: pos, label: interval.label });
        interval.pos = pos;
        pos++;
        activeIntervals.push(interval);
      }
    });

    this.tabLinks = tabLinks;
    XpoDateRangeIntervalFactory.updateActiveIntervals(activeIntervals);
  }

  private showDefaultIntervals(): void {
    const tabLinks = [];
    const activeIntervals: XpoDateRangeInterval[] = [];

    XpoDateRangeIntervalFactory.getAllIntervals()
      .filter((i) => i.label !== 'Minutes') // Show everything but minutes since that is not typically used
      .forEach((interval, i) => {
        tabLinks.push({ pos: i, label: interval.label });
        interval.pos = i;
        activeIntervals.push(interval);
      });

    this.tabLinks = tabLinks;
    XpoDateRangeIntervalFactory.updateActiveIntervals(activeIntervals);
  }

  protected onCalendarChanged(min: Date, max: Date): void {
    this.storeCriteria({
      // make sure max date is less than min-date
      max: max,
      min: min,
      selected: 'date',
      intervalType: this.dateRangeInterval.getTabModel().groupByInterval,
    });
  }
}
