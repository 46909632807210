import { Inject, Injectable } from '@angular/core';
import {
    CONFIG_MANAGER_SERVICE_CONFIGURATION,
    IConfigManagerServiceConfiguration
} from './config-manager-configuration';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Injectable( { providedIn: 'root' })
export class ConfigManagerService {
    private _settings: any;
    private _mockArrayTest = /^\[.*]$/g;
    private configuredSubject: BehaviorSubject<any>;
    public  configured$: Observable<any>;

    constructor(
                @Inject(CONFIG_MANAGER_SERVICE_CONFIGURATION) private config: IConfigManagerServiceConfiguration) {
        this.configuredSubject = new BehaviorSubject<any>(undefined);
        this.configured$ = this.configuredSubject.asObservable();
    }

    getSettings<T>(): T {
        if (!this._settings) {
          return undefined;
        }
        return this._settings as T;
    }

    getSetting<T>(propertyName: string): T {
        if (!this._settings) {
          return undefined;
        }
        return this._settings[propertyName] as T;
    }

    setSetting<T>(propertyName: string, value: T) {
      if (this._settings) {
        this._settings[propertyName] = value;
      }
    }

    init(): Observable<any> {
      return Observable.create( observer => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', this.config.configFileUrl);
        xhr.addEventListener('readystatechange', () => {
          if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            this._settings = this.parseArray(JSON.parse(xhr.responseText));
            this.configuredSubject.next(this._settings);
            observer.next(this._settings);
          } else if (xhr.readyState === XMLHttpRequest.DONE) {
            observer.errback(new Error('Unknown error state'));
          }
        });
        xhr.send(null);
      });
    }

    parseArray(settings: any): any {
        if (settings) {
            Object.keys(settings).forEach(key => {
                    if (settings[key]['match'] && settings[key].match(this._mockArrayTest)) {
                        settings[key] = ( settings[key].length === 2)
                          ? []
                          : settings[key].substring(1, settings[key].length - 1).split('|');
                    }
                }
            );
        }
        return settings;
    }
}

export function configAppSettings(configManager: ConfigManagerService) {
  return () => {
    return new Promise( (resolve) => {
      configManager.init().subscribe( settings => {
        resolve(settings);
      });
    });
  };
}

export function getAppSettings<T>(configManager: ConfigManagerService) {
    return configManager.getSettings<T>();
}
