/**
 * (Ancel):
 *
 * Created this component by taking pieces of Material's Menu component(https://material.angular.io/components/menu) and
 * Tooltip component (https://material.angular.io/components/tooltip/)
 *
 * For the XpoPopover I utilized the functionality of MatMenu but removed the MenuItem and ParentMenu logic.
 *
 */
import { AnimationEvent } from '@angular/animations';
import { Direction } from '@angular/cdk/bidi';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HorizontalConnectionPos, VerticalConnectionPos } from '@angular/cdk/overlay';
import { debounceTime } from 'rxjs/operators';

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { matMenuAnimations, MatMenuContent } from '@angular/material/menu';
import { TooltipPosition } from '@angular/material/tooltip';
import { Subject } from 'rxjs';
import { ESCAPE } from '../keycodes/keycodes';
import { ClosedEmitterTypes } from './enums/closed-emitter-types.enum';
import { TriggerType } from './enums/trigger-type.enum';
import { XpoLtlPopoverFooter } from './xpo-ltl-popover-footer.directive';
import { XpoLtlPopoverHeader } from './xpo-ltl-popover-header.directive';
import { XpoLtlPopoverStatusHeader } from './xpo-ltl-popover-status-header.directive';

/** Default `mat-menu` options that can be overridden. */
export interface XpoLtlPopoverDefaultOptions {
  /** The x-axis position of the menu. */
  position: TooltipPosition;

  /** The x-axis or y-axis position of the caret. */
  caretPosition: HorizontalConnectionPos | VerticalConnectionPos;

  /** Class to be applied to the menu's backdrop. */
  backdropClass: string;

  /** Whether the menu has a backdrop. */
  hasBackdrop?: boolean;
}

export const XPO_LTL_POPOVER_OVERLAY_TRANSPARENT_BACKDROP_CLASS: string = 'cdk-overlay-transparent-backdrop';

/** Injection token to be used to override the default options for `mat-menu`. */
export const XPO_LTL_POPOVER_DEFAULT_OPTIONS = new InjectionToken<XpoLtlPopoverDefaultOptions>('xpo-popover-default-options', {
  providedIn: 'root',
  factory: XPO_LTL_POPOVER_DEFAULT_OPTIONS_FACTORY,
});

export function XPO_LTL_POPOVER_DEFAULT_OPTIONS_FACTORY(): XpoLtlPopoverDefaultOptions {
  return {
    position: 'below',
    caretPosition: 'center',
    hasBackdrop: true,
    backdropClass: XPO_LTL_POPOVER_OVERLAY_TRANSPARENT_BACKDROP_CLASS,
  };
}

interface CaretStyles {
  top: number | string;
  left: number | string;
  display: string;
  fill?: string;
}
/**
 * Start elevation for the menu panel.
 */
const XPO_POPOVER_BASE_ELEVATION = 2;

@Component({
  selector: 'xpo-ltl-popover',
  templateUrl: './xpo-ltl-popover.component.html',
  styleUrls: ['./xpo-ltl-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-ltl-Popover',
  },
  exportAs: 'xpoLtlPopover',
  animations: [matMenuAnimations.transformMenu, matMenuAnimations.fadeInItems],
})
export class XpoLtlPopover implements OnInit, AfterContentInit, OnDestroy {
  private positionValue: TooltipPosition = this.defaultOptions.position;
  private caretPositionValue: HorizontalConnectionPos | VerticalConnectionPos = this.defaultOptions.caretPosition;
  private previousElevationValue: string;
  private colorValue: ThemePalette = 'primary';
  triggerEvent: MouseEvent;
  caretVisible: false;

  caretStyles: CaretStyles = {
    top: 'auto',
    left: 'auto',
    display: 'flex',
  };

  triggerType: string = TriggerType.Click;
  /** Config object to be passed into the menu's ngClass */
  classList: { [key: string]: boolean } = {};

  /** Current state of the panel animation. */
  panelAnimationState: 'void' | 'enter' = 'void';

  /** Emits whenever an animation on the menu completes. */
  animationDone = new Subject<AnimationEvent>();

  /** Whether the menu is animating. */
  isAnimating: boolean;

  /** Layout direction of the menu. */
  direction: Direction;

  /** Tracks the original intended position  */
  private originalPosition: TooltipPosition;

  /** Closing disabled on popover */
  closeDisabled = false;

  readonly POPOVER_ANIMATION_TIME: number = 110;

  /** Class to be added to the backdrop element. */
  @Input() backdropClass: string = this.defaultOptions.backdropClass;

  /** Position of the menu in the X axis. */
  @Input()
  get position(): TooltipPosition {
    return this.positionValue;
  }
  set position(value: TooltipPosition) {
    if (this.positionValue !== value) {
      this.positionValue = value;
      this.setPopoverClasses();
      this.updateCaretFill();
    }
  }

  @Input() arrowBehaviourAuto: boolean = false;
  /** The x-axis or y-axis position of the caret. */
  @Input()
  get caretPosition(): HorizontalConnectionPos | VerticalConnectionPos {
    return this.caretPositionValue;
  }
  set caretPosition(value: HorizontalConnectionPos | VerticalConnectionPos) {
    if (this.caretPositionValue !== value) {
      this.caretPositionValue = value;
      this.setPopoverClasses();
    }
  }

  /** @docs-private */
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

  /**
   * Menu content that will be rendered lazily.
   * @docs-private
   */
  @ContentChild(MatMenuContent) lazyContent: MatMenuContent;

  @ContentChild(XpoLtlPopoverStatusHeader) status: XpoLtlPopoverStatusHeader;
  @ContentChild(XpoLtlPopoverHeader) header: XpoLtlPopoverHeader;
  @ContentChild(XpoLtlPopoverFooter) footer: XpoLtlPopoverFooter;

  /** Whether the menu has a backdrop. */
  @Input()
  get hasBackdrop(): boolean | undefined {
    return this.hasBackdropValue;
  }
  set hasBackdrop(value: boolean | undefined) {
    this.hasBackdropValue = coerceBooleanProperty(value);
  }
  private hasBackdropValue: boolean | undefined = this.defaultOptions.hasBackdrop;

  /**
   * This method takes classes set on the host ltl-popover element and applies them on the
   * menu template that displays in the overlay container.  Otherwise, it's difficult
   * to style the containing menu from outside the component.
   * @param classes list of class names
   */
  @Input('class')
  set panelClass(classes: string) {
    if (classes && classes.length) {
      this.classList = classes.split(' ').reduce((obj: any, className: string) => {
        obj[className] = true;
        return obj;
      }, {});
    }
  }

  /** Sets the trigger action type */
  @Input('triggerOn')
  set triggerMode(action: string) {
    this.triggerType = action;
  }

  /** Event emitted when the menu is closed. */
  @Output() readonly closed: EventEmitter<void | ClosedEmitterTypes.Click | ClosedEmitterTypes.Keydown | ClosedEmitterTypes.Tab> = new EventEmitter<
  void | ClosedEmitterTypes.Click | ClosedEmitterTypes.Keydown | ClosedEmitterTypes.Tab
  >();

  /** Event emitted when mouse leaves the popover component if triggertype is set to hover */
  @Output() mouseLeaveEmitter = new EventEmitter<void>();

  constructor(@Inject(XPO_LTL_POPOVER_DEFAULT_OPTIONS) private defaultOptions: XpoLtlPopoverDefaultOptions) {}

  ngOnInit(): void {
    this.originalPosition = this.position;
    this.setPopoverClasses();

    if (this.arrowBehaviourAuto) {
      this.caretStyles.display = 'none';
      this.animationDone.pipe(debounceTime(this.POPOVER_ANIMATION_TIME)).subscribe((event) => {
        this.updatePopoverArrowPosition(event.element);
      });
    }
  }

  ngAfterContentInit(): void {
    // after status header content init evaluate if the position is above
    // in order to set the caret fill the same as the status header color
    this.updateCaretFill();
  }

  ngOnDestroy(): void {
    this.closed.complete();
    this.mouseLeaveEmitter.complete();
  }

  /** Handle a keyboard event from the menu, delegating to the appropriate action. */
  handleKeydown(event: KeyboardEvent): void {
    const keyCode = event.code;
    switch (keyCode) {
      case ESCAPE:
        this.closed.emit(ClosedEmitterTypes.Keydown);
        event.stopPropagation();
        break;
      default:
    }
  }

  /**
   * Sets the menu panel elevation.
   * @param depth Number of parent menus that come before the menu.
   */
  setElevation(depth: number): void {
    // The elevation starts at the base and increases by one for each level.
    const newElevation = `mat-elevation-z${XPO_POPOVER_BASE_ELEVATION + depth}`;
    const customElevation = Object.keys(this.classList).find((c) => c.startsWith('mat-elevation-z'));

    if (!customElevation || customElevation === this.previousElevationValue) {
      if (this.previousElevationValue) {
        this.classList[this.previousElevationValue] = false;
      }

      this.classList[newElevation] = true;
      this.previousElevationValue = newElevation;
    }
  }

  /**
   * Adds classes to the popover panel based on its position. Can be used by
   * consumers to add specific styling based on the position.
   * @docs-private
   */
  setPopoverClasses(): void {
    const classes = this.classList;
    classes['xpo-ltl-Popover--before'] = this.positionValue === 'before';
    classes['xpo-ltl-Popover--after'] = this.positionValue === 'after';
    classes['xpo-ltl-Popover--above'] = this.positionValue === 'above';
    classes['xpo-ltl-Popover--below'] = this.positionValue === 'below';

    classes['xpo-ltl-Popover--primary'] = this.colorValue === 'primary';

    classes['xpo-ltl-Popover-caret--top'] =
      (this.caretPositionValue === 'top' || this.caretPositionValue === 'start') && (this.positionValue === 'before' || this.positionValue === 'after');
    classes['xpo-ltl-Popover-caret--bottom'] =
      (this.caretPositionValue === 'bottom' || this.caretPositionValue === 'end') && (this.positionValue === 'before' || this.positionValue === 'after');
    classes['xpo-ltl-Popover-caret--verticalCenter'] =
      this.caretPositionValue === 'center' && (this.positionValue === 'before' || this.positionValue === 'after');

    classes['xpo-ltl-Popover-caret--start'] =
      (this.caretPositionValue === 'top' || this.caretPositionValue === 'start') && (this.positionValue === 'above' || this.positionValue === 'below');
    classes['xpo-ltl-Popover-caret--end'] =
      (this.caretPositionValue === 'bottom' || this.caretPositionValue === 'end') && (this.positionValue === 'above' || this.positionValue === 'below');
    classes['xpo-ltl-Popover-caret--horizontalCenter'] =
      this.caretPositionValue === 'center' && (this.positionValue === 'above' || this.positionValue === 'below');
  }

  private updateCaretFill(): void {
    if (!this.status || !this.status.backgroundColor || this.position !== 'below') {
      // reset possible previous style
      this.caretStyles.fill = null;
    } else {
      // update caret fill color if it's needed
      this.caretStyles.fill = this.status.backgroundColor;
    }
  }

  /** Starts the enter animation. */
  startAnimation(): void {
    if (this.arrowBehaviourAuto) {
      this.caretStyles.display = 'none';
    }
    // @deletion-target 7.0.0 Combine with resetAnimation.
    this.panelAnimationState = 'enter';
  }

  /** Resets the panel animation to its initial state. */
  resetAnimation(): void {
    // @deletion-target 7.0.0 Combine with startAnimation.
    this.panelAnimationState = 'void';
  }

  /**
   * Callback that is invoked when the panel animation starts
   */
  onAnimationStart(): void {
    this.isAnimating = true;
  }

  /** Callback that is invoked when the panel animation completes. */
  onAnimationDone(event: AnimationEvent): void {
    this.animationDone.next(event);
    this.isAnimating = false;
  }

  resetPosition(): void {
    this.position = this.originalPosition;
  }

  /** Enables close of popover when mouse leaving popover element */
  onMouseLeave(): void {
    if (this.triggerType === TriggerType.Hover) {
      this.closeDisabled = false;
      this.mouseLeaveEmitter.emit();
    }
  }
  /** Disables close of popover when leaving trigger element and mouse over the popover */
  onMouseOver(): void {
    if (this.triggerType === TriggerType.Hover) {
      this.closeDisabled = true;
    }
  }
  /**
   * Arrow positioning based on triggerer position on screen
   * @param popoverElement
   */
  private updatePopoverArrowPosition(popoverElement: HTMLElement): void {
    if (!popoverElement || !popoverElement.getBoundingClientRect || !this.arrowBehaviourAuto) {
      return;
    }

    const popoverBcr = popoverElement.getBoundingClientRect();
    const target = <HTMLElement>this.triggerEvent.target;
    const triggererBcr = target.getBoundingClientRect();
    const vertically = this.position === 'after' || this.position === 'before';
    const dimension = vertically ? 'height' : 'width';
    const axis = vertically ? 'y' : 'x';
    const position = vertically ? 'top' : 'left';
    const tiggererSizeHalf = triggererBcr[dimension] / 2;

    this.caretStyles[position] = triggererBcr[axis] - popoverBcr[axis] + tiggererSizeHalf;
    this.caretStyles.display = 'flex';
  }
}
