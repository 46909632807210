import { XpoBrowserClassification } from './models/browser-classification.model';
import { XpoBrowserOs, XpoBrowserType } from './models/browser-type.enums';

export class XpoBrowserDetectionUtils {
  private static dataBrowser = [
    {
      agentName: window.navigator.userAgent,
      match: 'Trident',
      identity: XpoBrowserType.InternetExplorer,
    },
    {
      agentName: window.navigator.userAgent,
      match: 'MSIE',
      identity: XpoBrowserType.InternetExplorer,
    },
    {
      agentName: window.navigator.userAgent,
      match: 'Edge',
      identity: XpoBrowserType.Edge,
    },
    {
      agentName: window.navigator.vendor,
      match: 'Apple',
      identity: XpoBrowserType.Safari,
      versionSearch: 'Version',
    },
    {
      agentName: window.navigator.userAgent,
      match: 'OPR',
      identity: XpoBrowserType.Opera,
    },
    {
      agentName: window.navigator.userAgent,
      match: 'Opera',
      identity: XpoBrowserType.Opera,
    },
    {
      featureName: (<any>window).opera,
      identity: XpoBrowserType.Opera,
      versionSearch: 'Version',
    },
    {
      agentName: window.navigator.userAgent,
      match: 'Firefox',
      identity: XpoBrowserType.Firefox,
    },
    {
      agentName: window.navigator.userAgent,
      match: 'Chrome',
      identity: XpoBrowserType.Chrome,
    },
  ];
  private static dataOS = [
    {
      agentName: window.navigator.platform,
      match: 'Win',
      identity: XpoBrowserOs.Windows,
    },
    {
      agentName: window.navigator.platform,
      match: 'Mac',
      identity: XpoBrowserOs.Mac,
    },
    {
      agentName: window.navigator.userAgent,
      match: 'iPhone',
      identity: XpoBrowserOs.iPhone,
    },
    {
      agentName: window.navigator.platform,
      match: 'Linux',
      identity: XpoBrowserOs.Linux,
    },
  ];

  /**
   * Returns the current browser and os
   */
  static loadBrowserInfo(): XpoBrowserClassification {
    const browser = this.searchPossibleValues(this.dataBrowser) || XpoBrowserType.Unknown;
    const os = this.searchPossibleValues(this.dataOS) || XpoBrowserOs.Unknown;

    return { browser, os };
  }

  /**
   * Tries to match the current browser values (navigator.userAgent or navigator.platform)
   * to our predefined list, to figure out the current browser instance.
   * @param optionsArray list of Browsers of OSs to search for
   */
  private static searchPossibleValues(optionsArray): any {
    for (const option of optionsArray) {
      const agent = option.agentName;

      if (agent && agent.toLowerCase().indexOf(option.match.toLowerCase()) > -1) {
        return option.identity;
      } else if (option.feature) {
        return option.identity;
      }
    }
  }
}
