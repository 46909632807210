import { Pipe, PipeTransform } from '@angular/core';
import { ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'shipmentSpecialServiceCd',
})
export class ShipmentSpecialServiceCdPipe implements PipeTransform {
  transform(value: ShipmentSpecialServiceCd): string {
    switch (value) {
      case ShipmentSpecialServiceCd.APPOINTMENT:
        return 'Appointment';
      case ShipmentSpecialServiceCd.BULK_LIQUID:
        return 'Bulk Liquid';
      case ShipmentSpecialServiceCd.BYPASS:
        return 'Bypass';
      case ShipmentSpecialServiceCd.CASH_ON_DELIVERY:
        return 'Cash On Delivery';
      case ShipmentSpecialServiceCd.CUSTOMS_IN_BOND:
        return 'Customs In Bond';
      case ShipmentSpecialServiceCd.DRIVER_COLLECT:
        return 'Driver Collect';
      case ShipmentSpecialServiceCd.EXCESSIVE_LENGTH:
        return 'Excessive Length';
      case ShipmentSpecialServiceCd.EXPEDITE:
        return 'Expedite';
      case ShipmentSpecialServiceCd.FOOD:
        return 'Food';
      case ShipmentSpecialServiceCd.FORK_LIFT:
        return 'Fork Lift';
      case ShipmentSpecialServiceCd.FREEZABLE:
        return 'Freezable';
      case ShipmentSpecialServiceCd.GUARANTEED:
        return 'Guaranteed';
      case ShipmentSpecialServiceCd.GUARANTEED_BY_NOON:
        return 'Guaranteed By Noon';
      case ShipmentSpecialServiceCd.HAZMAT:
        return 'Hazmat';
      case ShipmentSpecialServiceCd.HEADLOAD:
        return 'Headload';
      case ShipmentSpecialServiceCd.HIGHWAY_SUB_SERVICE:
        return 'Highway Subservice';
      case ShipmentSpecialServiceCd.LIFT_GATE:
        return 'Lift Gate';
      case ShipmentSpecialServiceCd.LINEHAUL:
        return 'Linehaul';
      case ShipmentSpecialServiceCd.MISLOAD:
        return 'Misload';
      case ShipmentSpecialServiceCd.NOTIFY_ON_ARRIVAL:
        return 'Notify On Arrival';
      case ShipmentSpecialServiceCd.PALLET_JACK:
        return 'Pallet Jack';
      case ShipmentSpecialServiceCd.PARTIAL_BYPASS:
        return 'Partial Bypass';
      case ShipmentSpecialServiceCd.PUP_ONLY:
        return 'Pup Only';
      case ShipmentSpecialServiceCd.RAPID_REMOTE_SERVICE:
        return 'Rapid Remote Service';
      case ShipmentSpecialServiceCd.RESIDENTIAL_DELIVERY:
        return 'Residential Delivery';
      case ShipmentSpecialServiceCd.SUPPLEMENTAL:
        return 'Supplemental';
      case ShipmentSpecialServiceCd.TIME_DATE_CRITICAL:
        return 'Time Date Critical';
      case ShipmentSpecialServiceCd.TRAP_SERVICE:
        return 'Trap Service';
      case ShipmentSpecialServiceCd.UNMAPPED:
        return 'Unmapped';
      default:
        return value;
    }
  }
}
