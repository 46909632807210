import {DisputeDecisionReasonSubTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeDecisionReasonSubTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeDecisionReasonSubTypeCd ) {
      this._values.push(DisputeDecisionReasonSubTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeDecisionReasonSubTypeCd {

    for ( const obj in DisputeDecisionReasonSubTypeCd ) {
      if (DisputeDecisionReasonSubTypeCd[obj] === value){
        return DisputeDecisionReasonSubTypeCd[obj] as DisputeDecisionReasonSubTypeCd;
      }
    }
  }
}

const DisputeDecisionReasonSubTypeCdHelper = new DisputeDecisionReasonSubTypeCdHelperEnumHelperClass();
export default DisputeDecisionReasonSubTypeCdHelper;
