import { AgreementTypeCd } from './agreement-type-cd.enum';

class AgreementTypeCdClass {
  protected _values: string[] = [];
  protected _keys: string[] = [];

  values(): string[] {
    return this._values;
  }

  keys(): string[] {
    return this._keys;
  }
  constructor() {
    for (const obj in AgreementTypeCd) {
      if (!!obj) {
        this._values.push(AgreementTypeCd[obj]);
        this._keys.push(obj);
      }
    }
  }

  toEnum(value: string): AgreementTypeCd {
    for (const obj in AgreementTypeCd) {
      if (AgreementTypeCd[obj] === value) {
        return AgreementTypeCd[obj] as AgreementTypeCd;
      }
    }
  }
}

const AgreementTypeCdHelper = new AgreementTypeCdClass();
export default AgreementTypeCdHelper;
