export enum Operators {
  Empty = 'EMPTY',
  NotEmpty = 'NOT_EMPTY',
  True = 'TRUE',
  NotTrue = 'NOT_TRUE',
  Contains = 'CONTAINS',
  NotContains = 'NOT_CONTAINS',
  StartsWith = 'STARTS_WITH',
  EndsWith = 'ENDS_WITH',
  Relative = 'RELATIVE',
  Equals = 'EQUALS',
  NotEquals = 'NOT_EQUALS',
  OneOf = 'ONE_OF',
  Between = 'BETWEEN',
  NotBetween = 'NOT_BETWEEN',
  Less = 'LESS',
  Greater = 'GREATER_THAN',
  LessEqual = 'LESS_EQUAL',
  GreaterEqual = 'GREATER_EQUAL',
  Before = 'BEFORE',
  OnOrBefore = 'ON_OR_BEFORE',
  After = 'AFTER',
  OnOrAfter = 'ON_OR_AFTER',
}
