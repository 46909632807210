import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { XpoLtlReleaseNotesComponent } from './release-notes.component';

@Injectable({ providedIn: 'root' })
export class XpoLtlReleaseNotesService {
  constructor(private dialog: MatDialog) {}

  showReleaseNotes(): Observable<any> {
    const subject = new Subject<any>();

    const dialogRef = this.dialog.open(XpoLtlReleaseNotesComponent);
    dialogRef.afterClosed().subscribe(() => {
      subject.next(null);
      subject.complete();
    });

    return subject.asObservable();
  }
}
