import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { MODULE_LIBRARY_COLUMNS } from './module-library-columns';

export const MODULE_EXECUTOR_BOARD_TEMPLATES = [
  new XpoAgGridBoardViewTemplate({
    id: 'module-library',
    name: 'Module Library',
    allowAdditional: false,
    availableColumns: MODULE_LIBRARY_COLUMNS,
  }),
];
