import { Directive } from '@angular/core';

// IMPORTANT NOTIFICATION BADGE
@Directive({
  selector: '[xpo-badge-important-notification], [xpoBadgeImportantNotification] ',
  host: {
    class: 'xpo-Badge--important',
  },
})
export class XpoBadgeImportantNotification {}
// DOT BADGE
@Directive({
  selector: '[xpo-badge-dot-warning], [xpoBadgeDotWarning] ',
  host: {
    class: 'xpo-BadgeDot xpo-BadgeDot--warning',
  },
})
export class XpoBadgeDotWarning {}

@Directive({
  selector: '[xpo-badge-dot-success], [xpoBadgeDotSuccess] ',
  host: {
    class: 'xpo-BadgeDot xpo-BadgeDot--success',
  },
})
export class XpoBadgeDotSuccess {}

@Directive({
  selector: '[xpo-badge-dot-info], [xpoBadgeDotInfo] ',
  host: {
    class: 'xpo-BadgeDot xpo-BadgeDot--info',
  },
})
export class XpoBadgeDotInfo {}

@Directive({
  selector: '[xpo-badge-dot-important], [xpoBadgeDotImportant] ',
  host: {
    class: 'xpo-BadgeDot xpo-BadgeDot--important',
  },
})
export class XpoBadgeDotImportant {}
