
export enum CheckpointMasterActionCd {
	APPOINTMENT_SET = 'AppointmentSet',
	CITY_ARRIVE = 'CityArrive',
	CLOSE_FOR_DELIVERY = 'CloseForDelivery',
	FINAL_DELIVERY = 'FinalDelivery',
	LINEHAUL_ARRIVE = 'LinehaulArrive',
	LINEHAUL_CLOSE = 'LinehaulClose',
	LINEHAUL_DISPATCH = 'LinehaulDispatch',
	LINEHAUL_LOADING_AT_DOCK = 'LinehaulLoadingAtDock',
	LOAD = 'Load',
	UNLOAD = 'Unload'}

