import {CisRequestApprovalStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class CisRequestApprovalStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CisRequestApprovalStatusCd ) {
      this._values.push(CisRequestApprovalStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CisRequestApprovalStatusCd {

    for ( const obj in CisRequestApprovalStatusCd ) {
      if (CisRequestApprovalStatusCd[obj] === value){
        return CisRequestApprovalStatusCd[obj] as CisRequestApprovalStatusCd;
      }
    }
  }
}

const CisRequestApprovalStatusCdHelper = new CisRequestApprovalStatusCdHelperEnumHelperClass();
export default CisRequestApprovalStatusCdHelper;
