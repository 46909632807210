
export enum SeaNotifyEventCd {
	ALL_SHORT = 'AllShort',
	APPT_REQUIRED_AT_DEST = 'ApptRequiredAtDest',
	ARRIVED_AT_DEST = 'ArrivedAtDest',
	ARRIVED_AT_INTERIM = 'ArrivedAtInterim',
	ATTEMPTED_DLVRY = 'AttemptedDlvry',
	AT_DEST = 'AtDest',
	AT_INTERIM = 'AtInterim',
	AT_ORIGIN = 'AtOrigin',
	AWAITING_UNLOAD_AT_CONSIGNEE = 'AwaitingUnloadAtConsignee',
	BOL_IMAGE_AVAILABLE = 'BolImageAvailable',
	CONSOLIDATING_PER_CONSIGNEE = 'ConsolidatingPerConsignee',
	DELAYED_EN_ROUTE_TO_DEST = 'DelayedEnRouteToDest',
	DELAYED_EN_ROUTE_TO_INTERIM = 'DelayedEnRouteToInterim',
	DELIVERED = 'Delivered',
	DLRY_NOT_ATTEMPTED = 'DlryNotAttempted',
	DLVRD_PART_SHORT = 'DlvrdPartShort',
	DLVRY_ATTEMPTED = 'DlvryAttempted',
	DLVRY_RECEIPT_IMAGE_AVAILABLE = 'DlvryReceiptImageAvailable',
	ENROUTE_FROM_SEATTLE_TO_ALASKA = 'EnrouteFromSeattleToAlaska',
	ENROUTE_TO_HAWAII = 'EnrouteToHawaii',
	ENROUTE_TO_PUERTO_RICO = 'EnrouteToPuertoRico',
	EN_ROUTE_TO_DEST = 'EnRouteToDest',
	EN_ROUTE_TO_INTERIM = 'EnRouteToInterim',
	FINAL_DLVRY_PENDING_REVIEW = 'FinalDlvryPendingReview',
	FOLLOW_UP_DELAYED = 'FollowUpDelayed',
	FOLLOW_UP_ON_TIME = 'FollowUpOnTime',
	HOLD_FOR_CARTAGE_AT_DEST = 'HoldForCartageAtDest',
	HOLD_FOR_CUSTOMS_AT_DEST = 'HoldForCustomsAtDest',
	INTL_SHIPMEN_FROM_MX = 'IntlShipmenFromMX',
	INTL_SHIPMEN_TO_MX = 'IntlShipmenToMX',
	LATE = 'Late',
	LATE_NO_DELAY_NOTIFICATION_SENT = 'LateNoDelayNotificationSent',
	NON_MVMT_PRO = 'NonMvmtPro',
	OUT_FOR_DLVRY = 'OutForDlvry',
	OVERAGE = 'Overage',
	PICKUP_UP = 'PickupUp',
	POSSIBLE_DELAY_NOTIFICATION = 'PossibleDelayNotification',
	RECORDED_IN_SYSTEM = 'RecordedInSystem',
	REFUSED_DLVRY = 'RefusedDlvry',
	REFUSED_FOR_DAMAGE = 'RefusedForDamage',
	RETURNED_NO_ATTEMPT_TO_DLVRY = 'ReturnedNoAttemptToDlvry',
	SHIPMENT_CANCELLED = 'ShipmentCancelled',
	TRANSFER = 'Transfer',
	UNDELIVERABLE = 'Undeliverable'}

