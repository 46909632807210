import { Component,  Directive, ElementRef,  ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'xpo-header-hamburger',
  host: { class: 'xpo-Header-hamburger' },
  template: `
    <mat-icon>menu</mat-icon>
  `,
})
export class XpoHeaderHamburger {}

@Directive({
  selector: 'xpo-header-app-name',
  host: { class: 'xpo-Header-appName' },
})
export class XpoHeaderAppName {
  /**
   * The application name renderer by content projection
   */
  get appName(): string {
    // TODO: evaluate if the projected content it is not only text
    return this.host.nativeElement.textContent;
  }

  constructor(protected host: ElementRef<HTMLElement>) {}
}
@Directive({
  selector: 'xpo-header-sub-app-name',
  host: { class: 'xpo-Header-subAppName' },
})
export class XpoHeaderSubAppName {
  /**
   * The application sub-name renderer by content projection
   */
  get appSubName(): string {
    // TODO: evaluate if the projected content it is not only text
    return this.host.nativeElement.textContent;
  }

  constructor(protected host: ElementRef<HTMLElement>) {}
}

@Directive({
  selector: 'xpo-header-items',
  host: { class: 'xpo-Header-items' },
})
export class XpoHeaderItems {}

@Directive({
  selector: 'xpo-header-actions',
  host: { class: 'xpo-Header-actions' },
})
export class XpoHeaderActions {}

@Directive({
  selector: 'xpo-header-utilities-actions',
  host: { class: 'xpo-Header-utilities-actions' },
})
export class XpoHeaderUtilitiesActions {}

@Component({
  selector: '[xpo-header]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    class: 'xpo-Header',
  },
  encapsulation: ViewEncapsulation.None,
})
export class XpoHeader {}
