import { Directive } from '@angular/core';
import { Host, Input, OnInit, Optional, Self } from '@angular/core';
import { AUTOCOMPLETE_PANEL_HEIGHT, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { _countGroupLabelsBeforeOption, _getOptionScrollPosition } from '@angular/material/core';

@Directive({
  selector: '[xpoAutocomplete] , [xpo-autocomplete]',
})
export class XpoAutocompleteInternalActiveNavigation implements OnInit {
  readonly AUTOCOMPLETE_OPTION_HEIGHT: number = 24.5;
  @Input() xpoOptionHeight: number;
  @Input() xpoPanelHeight: number = AUTOCOMPLETE_PANEL_HEIGHT;

  constructor(@Host() @Self() @Optional() public xpoAutocompleteTrigger: MatAutocompleteTrigger) {
    this.xpoOptionHeight = this.AUTOCOMPLETE_OPTION_HEIGHT;
  }

  ngOnInit(): void {
    if (!this.xpoAutocompleteTrigger) {
      return;
    }
    this.xpoAutocompleteTrigger['_scrollToOption'] = this.setOptionScroll.bind(
      this.xpoAutocompleteTrigger,
      this.xpoOptionHeight,
      this.xpoPanelHeight
    );
  }

  // With the amounts of options that preceds the active option and the scrollTop we set the scroll position
  private setOptionScroll(this: MatAutocompleteTrigger, xpoOptionHeight: number, xpoPanelHeight: number): void {
    const index = this.autocomplete._keyManager.activeItemIndex || 0;
    // Counts the amount of option group labels that precede the specified option
    const xpoAutocompleteLabelCount = _countGroupLabelsBeforeOption(
      index,
      this.autocomplete.options,
      this.autocomplete.optionGroups
    );
    // If there is only one option set scroll top 0
    if (index === 0 && xpoAutocompleteLabelCount === 1) {
      this.autocomplete._setScrollTop(0);
    } else {
      // Set scroll position
      const xpoAutocompleteNewScrollPosition = _getOptionScrollPosition(
        index + xpoAutocompleteLabelCount,
        xpoOptionHeight,
        this.autocomplete._getScrollTop(),
        xpoPanelHeight
      );
      this.autocomplete._setScrollTop(xpoAutocompleteNewScrollPosition);
    }
  }
}
