import { Component } from '@angular/core';
import moment from 'moment';
import { XpoFilterCondition } from '../../../condition';
import { XpoConditionTimeBeforeComponent } from '../../time/before/before.component';
import { ordersMinMaxTime } from '../../time/time.util';
import { Operators, OperatorText } from './../../../../../models/index';

@Component({
  templateUrl: './before.component.html',
  styleUrls: ['../../validator.scss'],
})
export class XpoConditionTimeRangeBeforeComponent
  extends XpoConditionTimeBeforeComponent
  implements XpoFilterCondition {
  static text: string = OperatorText.Before;
  static operator: Operators = Operators.Before;

  constructor() {
    super();
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    if (!cellValue || !cellValue.from || !cellValue.to) {
      return false;
    }

    const cellValueFrom = moment(cellValue.from, 'h:mma');
    const cellValueTo = moment(cellValue.to, 'h:mma');
    const model = moment(this.model, 'h:mma');

    return cellValueFrom.isBefore(model) || cellValueTo.isBefore(model);
  }
}
