import {DynamicPriceApiNameCd} from '../';
import {EnumHelper} from './enum-helper';

export class DynamicPriceApiNameCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DynamicPriceApiNameCd ) {
      this._values.push(DynamicPriceApiNameCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DynamicPriceApiNameCd {

    for ( const obj in DynamicPriceApiNameCd ) {
      if (DynamicPriceApiNameCd[obj] === value){
        return DynamicPriceApiNameCd[obj] as DynamicPriceApiNameCd;
      }
    }
  }
}

const DynamicPriceApiNameCdHelper = new DynamicPriceApiNameCdHelperEnumHelperClass();
export default DynamicPriceApiNameCdHelper;
