import { Observable, of as observableOf } from 'rxjs';

import { XpoBoardView } from '../../models/board-view.model';
import { XpoBoardViewConfig } from './board-view-config.model';
import { XpoBoardViewDataStore } from './board-view-data-store.model';

export class XpoBoardViewInMemoryDataStore implements XpoBoardViewDataStore {
  private readonly boardViews: XpoBoardView[];

  constructor(views?: XpoBoardView[]) {
    this.boardViews = views || [];
  }

  getAll(): Observable<XpoBoardView[]> {
    return observableOf(this.boardViews);
  }

  loadAll(): Observable<XpoBoardView[]> {
    return this.getAll();
  }

  save(view: XpoBoardViewConfig): Observable<XpoBoardViewConfig> {
    throw new Error('InMemoryDataStore does not support saving views.');
  }

  delete(viewId: string): Observable<boolean> {
    throw new Error('InMemoryDataStore does not support deleting views.');
  }

  updateViewVisibility(viewId: string, visibility: boolean): Observable<XpoBoardViewConfig[]> {
    throw new Error('InMemoryDataStore does not support updating visibilities of views');
  }
}
