import { Component, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import { xpoDateTimeFormatter } from '../../../../../../formatters';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { ordersMinMaxDateTime } from '../date-time.util';
import { filterFormats, Operators, OperatorText, XpoFilterConditionCriteria } from './../../../../../models/index';

export interface DateTimeModel {
  dateFrom: Date | null;
  dateTo: Date | null;
  timeFrom: string;
  timeTo: string;
}

@Component({
  templateUrl: './between.component.html',
  styleUrls: ['./between.component.scss'],
})
export class XpoConditionDateTimeBetweenComponent extends XpoConditionBase implements XpoFilterCondition {
  static text: string = OperatorText.Between;
  static operator = Operators.Between;

  value: string = 'datetimeBetween';
  isNegative = false;
  model: DateTimeModel = {
    dateFrom: null,
    dateTo: null,
    timeFrom: '',
    timeTo: '',
  };

  @Output() inputChanged = new EventEmitter();

  constructor() {
    super(filterFormats.dateTimeRange);
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    if (!cellValue) {
      return false;
    }

    const ordered = ordersMinMaxDateTime(
      `${this.model.dateFrom} ${this.model.timeFrom}`,
      `${this.model.dateTo} ${this.model.timeTo}`
    );

    return moment(cellValue).isBetween(ordered.from, ordered.to);
  }
  getCriteria(): XpoFilterConditionCriteria {
    const ordered = ordersMinMaxDateTime(
      `${this.model.dateFrom} ${this.model.timeFrom}`,
      `${this.model.dateTo} ${this.model.timeTo}`
    );
    return {
      operator: Operators.Between,
      value: ordered.from,
      valueTo: ordered.to,
      display: xpoDateTimeFormatter({ value: ordered.from }),
      displayTo: xpoDateTimeFormatter({ value: ordered.to }),
    };
  }
  onInputChange(): any {
    this.validateFormat();
    this.inputChanged.emit(this.model);
  }
}
