import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatCalendar, MatCalendarView } from '@angular/material/datepicker';
import { take } from 'rxjs/operators';

@Component({
  selector: 'xpo-filter-date-selector',
  templateUrl: './filter-date-selector.component.html',
  styleUrls: ['./filter-date-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-FilterDateSelector' },
})
export class XpoFilterDateSelectorComponent {
  startDate = new Date();
  invalidDate: boolean = false;

  @Input()
  placeholder: string;

  @Input()
  get date(): Date {
    return this.dateValue;
  }
  set date(v: Date) {
    // If the value coming in is an invalid date or null, clear out the dateValue and set the startDate to today,
    if (!this.isDate(v)) {
      this.dateValue = null;
      this.startDate = new Date();
    } else {
      this.dateValue = new Date(v);
      this.startDate = this.dateValue;
    }

    if (this.calendarRef) {
      // We want to change the currentView after the mat-calendar logic finishes it's regular navigation between views.
      // Settings this normally has no effect because the currentView is changed after this is called inside
      // mat-calendar
      this.ngZone.onStable.pipe(take(1)).subscribe(() => {
        this.calendarRef._goToDateInView(this.dateValue, this.viewLevel);
        this.calendarRef.focusActiveCell();
      });
    }
  }
  private dateValue: Date;

  @Output()
  dateChange = new EventEmitter<Date>();

  @Input() minDate: Date;

  @Input() maxDate: Date;

  @Input() dateFilter: (date: Date) => boolean;

  @Input() viewLevel: 'month' | 'year' | 'multi-year' = 'month';

  @Input() intervalType: 'start' | 'end';

  @ViewChild(MatCalendar) calendarRef: MatCalendar<any>;

  constructor(private ngZone: NgZone) {}

  updateDateValue(value: Date): void {
    this.invalidDate = false;

    if (!this.isValidDate(value)) {
      this.invalidDate = true;
      return;
    }

    const dateValue = this.zeroOutSecondsFromDate(value);

    if (!this.date || this.date.toDateString() !== dateValue.toDateString()) {
      this.date = dateValue;
      this.dateChange.next(this.date);
    }
  }

  onMonthSelected(value: Date): void {
    // We want to stick to the year view only for month tab
    if (this.viewLevel !== 'year') {
      return;
    }

    this.preserveCalendarView(value, 'year');
  }

  onYearSelected(value: Date): void {
    // We want to stick to the multi-year view only for year tab
    if (this.viewLevel !== 'multi-year') {
      return;
    }

    this.preserveCalendarView(value, 'multi-year');
  }

  private isDate(date: any): boolean {
    return !!date && !!Date.parse(date);
  }

  private zeroOutSecondsFromDate(date: Date): Date {
    return new Date(new Date(date).setSeconds(0, 0));
  }

  private isValidDate(date: Date): boolean {
    return (
      this.isDate(date) &&
      this.validateMin(date) &&
      this.validateMax(date) &&
      (this.dateFilter ? this.dateFilter(date) : true)
    );
  }

  private validateMin(date: Date): boolean {
    return this.minDate ? new Date(date).getTime() >= this.minDate.getTime() : true;
  }

  private validateMax(date: Date): boolean {
    return this.maxDate ? new Date(date).getTime() <= this.maxDate.getTime() : true;
  }

  private preserveCalendarView(value: Date, nextView: MatCalendarView): void {
    let updatedValue = value;
    if (nextView === 'year') {
      updatedValue = this.getClosestMonthValidDate(value);
    } else if (nextView === 'multi-year') {
      updatedValue = this.getClosestYearValidDate(value);
    }
    this.updateDateValue(updatedValue);

    // We want to change the currentView after the mat-calendar logic finishes it's regular navigation between views.
    // Settings this normally has no effect because the currentView is changed after this is called inside mat-calendar
    setTimeout(() => {
      this.calendarRef.currentView = nextView;
      this.calendarRef.stateChanges.next();
    });
  }

  private getClosestMonthValidDate(value: Date): Date {
    switch (this.intervalType) {
      case 'start': {
        if (this.validateMin(value)) {
          return value;
        }

        const resultValue = this.minDate ? new Date(this.minDate) : new Date();
        resultValue.setHours(0, 0, 0, 0);

        return resultValue;
      }
      case 'end': {
        if (this.validateMax(value)) {
          return new Date(value.getFullYear(), value.getMonth() + 1, 0);
        }

        const refValue = this.maxDate ? new Date(this.minDate) : new Date();
        return new Date(refValue.getFullYear(), refValue.getMonth() + 1, 0);
      }

      default:
        throw new Error(
          'The intervalType input must be specified on the xpo-filter-date-selector when using viewLevel'
        );
    }
  }

  private getClosestYearValidDate(value: Date): Date {
    switch (this.intervalType) {
      case 'start': {
        if (this.validateMin(value)) {
          return value;
        }

        const refValue = this.minDate ? new Date(this.minDate) : new Date();
        return new Date(refValue.getFullYear());
      }
      case 'end': {
        if (this.validateMax(value)) {
          return new Date(value.getFullYear() + 1, 0);
        }

        const refValue = this.maxDate ? new Date(this.minDate) : new Date();
        return new Date(refValue.getFullYear() + 1, 0);
      }

      default:
        throw new Error(
          'The intervalType input must be specified on the xpo-filter-date-selector when using viewLevel'
        );
    }
  }
}
