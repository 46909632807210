import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  Operators,
  XpoBoardConsumer,
  XpoBoardData,
  XpoBoardDataFetchState,
  XpoBoardDataSourceResolver,
  XpoBoardPagination,
  XpoBoardState,
  XpoBoardStateSources,
  XpoColumnLevelFilteringToggleComponent,
  XpoFilter,
  XpoFilterCriteria,
  XpoFiltersService,
  XpoGridBoardState,
  XpoSortExpression,
} from '@xpo-ltl/ngx-ltl-board';
import {
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  Column,
  ColumnApi,
  ColumnMovedEvent,
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnState,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IDatasource,
  IGetRowsParams,
  ModelUpdatedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import * as merge from 'deepmerge';
import { cloneDeep, isEqual } from 'lodash-es';
import {
  XpoAgGridBoardApi,
  XpoAgGridBoardApiAction,
  XpoAgGridBoardApiDispatcherService,
} from '../ag-grid-board-api/index';
import { XpoAgGridFilterChipComponent, XpoAgGridFilterChipComponentParams } from '../ag-grid-filter-chip/index';
import { XpoAgGridSelectAllCheckbox } from '../ag-grid-select-all/ag-grid-select-all.component';
import { XPO_BOARD_AG_GRID_DEFAULT_GRID_OPTIONS } from '../default-grid-options-token';
import {
  XpoAgGridBoardColumn,
  XpoAgGridBoardData,
  XpoAgGridBoardReadyEvent,
  XpoAgGridBoardState,
  XpoAgGridBoardViewTemplate,
  XpoAgGridSelectedCell,
} from '../models/index';
import { XpoColumnsHelper } from './helpers/columns.helper';

export type RowSelection = 'single' | 'multiple';
export type XpoAgGridSelectionMode = 'row' | 'cell';
export type XpoAgGridHighlightMode = 'row' | 'cell';

enum XpoAgGridHighlightModeClasses {
  row = 'xpo-AgGrid--highlightModeRow',
  cell = 'xpo-AgGrid--highlightModeCell',
}

@Component({
  selector: 'xpo-ag-grid-board',
  templateUrl: './ag-grid-board.component.html',
  styleUrls: ['./ag-grid-board.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-AgGridBoard' },
  providers: [XpoAgGridBoardApiDispatcherService],
})
export class XpoAgGridBoard extends XpoBoardConsumer implements OnInit {
  readonly GRID_ROW_INDEX_CLASS = 'xpo-AgGrid-rowIndexColumn';

  gridOptions: GridOptions = {};
  rowHeight: number;
  /** Used to recreate the board component when updating row heights when grid is set to infinite */
  gridVisible = true;
  highlightModeClass: string = XpoAgGridHighlightModeClasses.cell;

  private pendingStateUpdate: XpoAgGridBoardState;
  private cachedState: XpoAgGridBoardState;
  private columns: XpoAgGridBoardColumn[] = [];
  private isGridReady: boolean;
  private columnsFromDataSource: XpoAgGridBoardColumn[] = [];
  private gridBoardApi: XpoAgGridBoardApi;
  private cachedAgGridFilterModel = {};
  private frameworkComponents = { xpoAgGridFilterChipComponent: XpoAgGridFilterChipComponent };
  private pendingSortApply: boolean;
  private showFloatingFilters: boolean = false;
  private dataAlreadyRendered: boolean = false;

  /** Whether or not the first grid row is automatically selected
   * TODO: re implement
   */
  @Input()
  get autoSelectFirstRow(): boolean {
    return this.autoSelectFirstRowValue;
  }
  set autoSelectFirstRow(value: boolean) {
    this.autoSelectFirstRowValue = coerceBooleanProperty(value);
  }
  private autoSelectFirstRowValue = false;

  /*
   * Whether or not the columns are automatically auto-sized every-time data changes
   * TODO: reimplement
   */
  @Input()
  get autoSizeColumns(): boolean {
    return this.autoSizeColumnsValue;
  }
  set autoSizeColumns(value: boolean) {
    this.autoSizeColumnsValue = coerceBooleanProperty(value);
  }
  private autoSizeColumnsValue = false;

  /** Whether or not you can move columns */
  @Input()
  get enableMovableColumns(): boolean {
    return this.enableMovableColumnsValue;
  }
  set enableMovableColumns(value: boolean) {
    this.enableMovableColumnsValue = coerceBooleanProperty(value);
  }
  private enableMovableColumnsValue = false;

  /** Whether or not you can select a cell */
  @Input()
  get enableCellSelection(): boolean {
    return this.enableCellSelectionValue;
  }
  set enableCellSelection(value: boolean) {
    this.enableCellSelectionValue = coerceBooleanProperty(value);
  }
  private enableCellSelectionValue = false;

  /** Whether or not you can select a row */
  @Input()
  get enableRowSelection(): boolean {
    return this.enableRowSelectionValue;
  }
  set enableRowSelection(value: boolean) {
    this.enableRowSelectionValue = coerceBooleanProperty(value);
  }
  private enableRowSelectionValue = true;

  /** Whether or not the grid has sorting */
  @Input()
  get enableSorting(): boolean {
    return this.enableSortingValue;
  }
  set enableSorting(value: boolean) {
    this.enableSortingValue = coerceBooleanProperty(value);
  }
  private enableSortingValue = false;

  /*
   * Whether or not grid uses the option groupUseEntireRow
   * TODO: re-implement
   */
  @Input()
  get groupUseEntireRow(): boolean {
    return this.groupUseEntireRowValue;
  }
  set groupUseEntireRow(value: boolean) {
    this.groupUseEntireRowValue = coerceBooleanProperty(value);
  }
  private groupUseEntireRowValue: boolean = false;

  /*
   * Whether or not the columns are automatically sizedToFit every-time data changes or the screen is resized
   * TODO: re-implement
   */
  @Input()
  get sizeColumnsToFit(): boolean {
    return this.sizeColumnsToFitValue;
  }
  set sizeColumnsToFit(value: boolean) {
    this.sizeColumnsToFitValue = coerceBooleanProperty(value);
  }
  private sizeColumnsToFitValue: boolean = false;

  private get gridApi(): GridApi {
    return this.gridOptions.api;
  }

  private get columnApi(): ColumnApi {
    return this.gridOptions.columnApi;
  }

  /** Update the grid options of the grid */
  @Input()
  customGridOptions: GridOptions;

  /** Whether to allow or single or multiple row select */
  @Input()
  rowSelection: RowSelection = 'single';

  /**
   * Sets the row model, options are 'client-side' client rendering and 'infinite' for server rendering.
   * Defaults to 'infinite'
   */
  @Input()
  rowModelType: 'infinite' | 'client-side' = 'infinite';

  /** Sets the selection mode, options are 'row' selection  and 'cell' selection. Defaults to 'row'  */
  @Input()
  get selectionMode(): XpoAgGridSelectionMode {
    return this.selectionModeValue;
  }
  set selectionMode(value: XpoAgGridSelectionMode) {
    this.selectionModeValue = value;

    if (this.selectionMode === 'row') {
      this.gridOptions.suppressRowClickSelection = false;
      this.gridOptions.suppressCellSelection = true;
    } else {
      this.gridOptions.suppressRowClickSelection = true;
      this.gridOptions.suppressCellSelection = false;
    }
  }
  private selectionModeValue: XpoAgGridSelectionMode = 'row';

  /**
   * If true and set to infinite scroll, instead of virtualizing the rows using the record count coming from the
   * data source, it will only render the page size amount. When the user scrolls down to the bottom, it will then
   * fetch the next page and render the rows.
   */
  @Input()
  get suppressUsingRecordCount(): boolean {
    return this.suppressUsingRecordCountValue;
  }
  set suppressUsingRecordCount(value: boolean) {
    this.suppressUsingRecordCountValue = coerceBooleanProperty(value);
  }
  private suppressUsingRecordCountValue: boolean = false;

  @Input()
  columnFilters: XpoFilter[];

  /** Emits event when cell is clicked */
  @Output()
  cellSelected = new EventEmitter<CellClickedEvent>();

  /** TODO: this should be removed at the next major release in favor of gridBoardReady*/
  @Output()
  gridReady = new EventEmitter<GridReadyEvent>();

  @Output()
  gridBoardReady = new EventEmitter<XpoAgGridBoardReadyEvent>();

  @Output()
  refreshPagination = new EventEmitter<XpoBoardDataFetchState>();

  @Input()
  pagination: XpoBoardPagination;

  @Input()
  paginationPageSizes: number[];

  @Input()
  paginationPageSizeStart: number;

  @Input()
  set highlightMode(value: XpoAgGridHighlightMode) {
    // Default to cell if the given mode doesn't exist
    if (!XpoAgGridHighlightModeClasses[value]) {
      console.warn(
        `[XPO-LTL-BOARD] Highlight mode "${value}" is not valid, defaulting to 'cell'. Available values: "row", "cell".`
      );
    }
    this.highlightModeClass = XpoAgGridHighlightModeClasses[value] || XpoAgGridHighlightModeClasses.cell;
  }

  constructor(
    boardDataSourceResolver: XpoBoardDataSourceResolver,
    cd: ChangeDetectorRef,
    private agGridBoardApiDispatcher: XpoAgGridBoardApiDispatcherService,
    private filtersService: XpoFiltersService,
    @Optional() @Inject(XPO_BOARD_AG_GRID_DEFAULT_GRID_OPTIONS) private globalAgGridOptions: GridOptions
  ) {
    super(boardDataSourceResolver, cd);
    this.gridBoardApi = new XpoAgGridBoardApi(this.agGridBoardApiDispatcher);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initializePagination();
    this.initializeGridOptions();
    this.registerBoardApiHandlers();

    // initialize if there is some column filter, if floating filters are active
    // and trigger an state update event needed to show or hide floating filters header row
    this.initializeColumnLevelFilteringVisibleState();
  }

  initializePagination() {
    if (!this.pagination) {
      return;
    }

    this.pagination.configurePageSettings(this.paginationPageSizes, this.paginationPageSizeStart);

    if (this.rowModelType !== 'client-side') {
      this.updatePaginationPageSize(this.pagination.currentPageSize);

      this.pagination.pageSizeChange.subscribe((pageSize) => {
        this.updatePaginationPageSize(pageSize);
        // ag-grid cache needs to be refreshed when pageSize changes on run time.
        this.gridOptions.api.refreshInfiniteCache();
      });
    }
  }

  onGridReady(params: GridReadyEvent): void {
    this.isGridReady = true;

    if (this.pendingStateUpdate) {
      this.applyStateUpdate(this.pendingStateUpdate);
      this.pendingStateUpdate = null;
    }

    this.gridReady.emit(params);
    this.gridBoardReady.emit({
      agGridApi: params.api,
      agGridColumnApi: params.columnApi,
      agGridBoardApi: this.gridBoardApi,
    });

    if (this.pagination) {
      this.pagination.initialize(params.api);
    }
  }

  /**
   * Update current pagination page size
   * @param pageSize
   */
  private updatePaginationPageSize(pageSize: number): void {
    // We must to update datasouce.page size which is used to calculate range and also need to persist it to
    // cacheBlockSize which is used by ag-grid to create rows to host new data.
    this.boardDataSourceResolver.dataSource.pageSize = pageSize;
    this.gridOptions.cacheBlockSize = pageSize;
  }

  /**
   * Check for checkboxSelection on the given columns and its children and return the column
   * @param columns
   */
  private findCheckboxSelectionColumn(columns: Array<ColDef & ColGroupDef>): ColDef | null {
    /**
     * Since ag-grid columns can either be ColDef or ColGroupDef and we need to use one attribute
     * from each independently we got to define this mixed type to avoid defining a new one.
     */
    return (
      columns.find((column) => {
        if (column.children) {
          return this.findCheckboxSelectionColumn(column.children as Array<ColDef & ColGroupDef>);
        }
        return column.checkboxSelection === true;
      }) || null
    );
  }

  protected onCellClicked(event: CellClickedEvent): void {
    const selectedCell: XpoAgGridSelectedCell = {
      columnName: event.colDef.field,
      columnValue: event.value,
      rowIndex: event.rowIndex,
      rowData: event.data,
    };

    const stateChangeEvent: XpoAgGridBoardState = {
      focusedRecord: this.selectionMode === 'cell' ? selectedCell : selectedCell.rowData,
      source: XpoBoardStateSources.CellClicked,
    };

    // Publish state selection also
    if (this.selectionMode === 'cell') {
      stateChangeEvent.selection = [selectedCell];
    } else {
      const selectedNodes = event.api.getSelectedNodes() || [];
      stateChangeEvent.selection = selectedNodes.map((node) => node.data);
    }

    this.stateChange$.next(stateChangeEvent);
  }

  protected onSortChanged(): void {
    // if gridApi is not initialized we can't read the sort so nothing to do
    if (!this.gridApi) {
      return;
    }

    // if the sort didn't change, don't re-broadcast, it might have just
    // ended up here because we set the sort on initial load
    const gridSortModel = this.getSortModel();
    if (this.cachedState) {
      const mapStateSortOrder = this.generateSortOrderFromState(this.cachedState).filter((col) => col.sort);

      if (gridSortModel && JSON.stringify(mapStateSortOrder) === JSON.stringify(gridSortModel)) {
        return;
      }
    }

    this.pendingSortApply = true;
    const columnSorted = gridSortModel.map((sort) => {
      return { column: sort.colId, direction: sort.sort };
    });
    this.stateChange$.next(<any>{
      sortOrder: columnSorted,
      source: 'SORT-CHANGE',
    });
  }

  protected onStateChange(state: XpoAgGridBoardState): void {
    if (state.changes.includes('data') && state.data) {
      state = { ...state, data: new XpoAgGridBoardData(state.data) };
    }
    this.applyStateUpdate(state);
  }

  private isRowHeightUpdateRequired(state: XpoAgGridBoardState, rowModelType: string): boolean {
    return (
      state.changes &&
      state.changes.includes('rowHeight') &&
      state.rowHeight &&
      state.rowHeight !== this.cachedState?.rowHeight &&
      this.rowModelType === rowModelType
    );
  }

  // TODO: clean this up
  private applyStateUpdate(state: XpoAgGridBoardState): void {
    if (!this.gridApi || !this.isGridReady) {
      // for the case multiple pending states updates, we want to save all the changes that happened.
      this.pendingStateUpdate = {
        ...state,
        changes: this.pendingStateUpdate ? [...this.pendingStateUpdate.changes, ...state.changes] : state.changes,
      };

      // Removing dupes
      this.pendingStateUpdate.changes = Array.from(new Set(this.pendingStateUpdate.changes));

      return;
    }

    /**
     * If row-height is required in inifinte row model we need to wait for the grid to be built again before
     * trying to apply any other state changes since it destroys the grid completely and re-creates it.
     */
    // TODO: Make enum for 'inifinite' and 'client-side' row models. NGXLTL-813
    if (this.isRowHeightUpdateRequired(state, 'infinite')) {
      // Persist state changes in pendingStateUpdate which be hanlded once grid is recreated  after applyRowHeightUpdate is done
      this.pendingStateUpdate = { ...this.pendingStateUpdate, ...state };
      this.applyRowHeightUpdate(state);
      return;
    }
    if (this.isRowHeightUpdateRequired(state, 'client-side')) {
      this.applyRowHeightUpdate(state);
    }

    // At this point, ensure that there is no pending state update since the gridApi is loaded.
    this.pendingStateUpdate = null;

    this.cachedState = state; // Storing state to be referred back to

    const viewTemplate = <XpoAgGridBoardViewTemplate>state.template;
    const viewTemplateKey = viewTemplate ? viewTemplate.keyField : null;

    let updateGridColumns = false; // Flag to call configureGridColumns()

    // Handling Template Changes
    if (state.changes.includes('template')) {
      // Update the getRowNodeId callback to fetch the ID from the template key
      if (viewTemplateKey) {
        this.gridOptions.getRowNodeId = (data) => data[viewTemplateKey];
      } else if (this.gridOptions.getRowNodeId) {
        this.gridOptions.getRowNodeId = null;
      }
    }

    // If there is a change in columns, update the grid columns.
    if (!isEqual(state.visibleColumns, this.columns) || state.source === 'ACTIVE-VIEW-CHANGE') {
      this.columns = state.visibleColumns || [];
      updateGridColumns = true;
    }

    // If there are some columns coming from the datasource, append them to the end of the list
    // TODO: maybe implement column ordering?
    if (
      state.changes.includes('data') &&
      state.data &&
      state.data.consumerData.columns &&
      state.data.consumerData.columns !== this.columnsFromDataSource
    ) {
      this.columnsFromDataSource = state.data.consumerData.columns || [];
      updateGridColumns = true;
    } else if (state.dataFetchState === XpoBoardDataFetchState.Loading) {
      // Make sure we don't append some old cached data source columns
      this.columnsFromDataSource = [];
    }

    if (updateGridColumns) {
      // after any change in columns definition
      // apply new configuration for gridApi and gridOptions
      this.addColumnLevelFilters();
      this.configureGridColumns();
    }

    // Handle data changes
    const isViewChangeFetch = state.changes.includes('dataFetchState') && state.changes.includes('viewId');
    if (state.changes.includes('data') || isViewChangeFetch) {
      this.applyStateUpdateToData(state);
    }

    // handle selection when there are actually records to select
    if (state.changes.includes('selection') && state.source !== 'SELECTION-CHANGE' && state.data) {
      // If selection comes in with an empty array or null, clear selection from grid
      if (!state.selection || !state.selection.length) {
        this.gridApi.deselectAll();
        this.gridApi.clearFocusedCell();
      } else if (state.data) {
        // In order to perform row selection we need to make sure we have a valid key set
        if (!viewTemplateKey) {
          // TODO: this should throw an error, but we will need to call the error callback for ag-grid
          // if you throw an error here, pagination is broken
          console.error(
            `View template ${viewTemplate.name} has a null keyField value.
            Please provide the key of the field that uniquely identifies each row for this viewTemplate
            in order for some functionality of the ag-grid-board to work`
          );

          return;
        }

        // Else, if there are selected rows, select them on the grid and make sure they are in focus
        let foundSelectCount: number = 0;

        // loop through each node and select it, if available
        this.gridApi.forEachNode((node) => {
          if (
            state.selection.some(
              (s) =>
                node.data && s && s[viewTemplateKey] === node.data[viewTemplateKey] && this.rowModelType !== 'infinite'
            )
          ) {
            node.setSelected(true, foundSelectCount === 0);

            if (state.source !== XpoBoardStateSources.CellClicked) {
              this.gridApi.ensureNodeVisible(node, null);
            }

            foundSelectCount++;
          }
        });
      }
    }

    // handle initial load of saved sort
    if (state.changes.includes('sortOrder')) {
      this.applySortStateUpdate(state);
    }

    // if floating filters header rows visibility has changed
    if (state.changes.includes('columnLevelFilteringVisible')) {
      // hide or show floating filters header row based on actual value and configuration ones
      this.applyColumnFiltersVisibility(state.columnLevelFilteringVisible);
    }

    // Assign state criteria values to ag-grid column filters if they exists
    if (state.changes.includes('criteria')) {
      this.loadCriteriaIntoColumnFilters(state.criteria);
      // [CustomSelection] clear selection if filter is applied
      if (this.rowModelType === 'infinite' && state.customSelection) {
        this.gridApi.deselectAll();
      }
    }

    // Enables/Disable editing
    if (state.changes.includes('isEditing')) {
      // TODO: Check if there are editable columns
      const editableColumn: ColDef = state.visibleColumns.find((x: ColDef) => !!x.editable);
      this.updateEditingState(state.isEditing, editableColumn.field);
    }
  }
  /**
   *
   * @param state
   */
  private applySortStateUpdate(state: XpoAgGridBoardState): void {
    if (!this.gridApi) {
      return;
    }
    const mapStateSortOrder = this.generateSortOrderFromState(state).filter((col) => col.sort);
    const gridSortModel = this.getSortModel();

    if (gridSortModel && JSON.stringify(mapStateSortOrder) !== JSON.stringify(gridSortModel)) {
      // Get the current column state
      const columnsState = this.columnApi.getColumnState();
      // Iterate columns that defines sorting
      mapStateSortOrder.forEach((sortDef) => {
        // Get the column ref from the colum state
        const col = columnsState.find((c) => c.colId === sortDef.colId);
        if (col) {
          // Set sort property in the reference
          col.sort = sortDef.sort ?? undefined;
        }
      });
      // Set the column state as received but with the sort updated
      this.columnApi.setColumnState(columnsState);
    }
  }

  private applyStateUpdateToData(state: XpoAgGridBoardState): void {
    if (!state.viewId) {
      return;
    }

    // If client side row model type, set row data to incoming row data
    if (this.rowModelType === 'client-side') {
      // if we are in error state, we will not get data
      const data = state.data || new XpoAgGridBoardData(XpoBoardData.empty<XpoAgGridBoardData>(<XpoBoardState>state));
      this.gridApi.setRowData(data.consumerData.rows);
      this.trySelectingFirstRow();
    }

    // If infinite scrolling, create dataSource
    if (this.rowModelType === 'infinite') {
      // If we are in loading state, make sure we clear-out any previous results
      // The next update will return the valid data
      if (state.dataFetchState === XpoBoardDataFetchState.Loading) {
        const emptyDataSet = new XpoAgGridBoardData(XpoBoardData.empty<XpoAgGridBoardData>(<XpoBoardState>state));
        this.gridApi.setDatasource(this.createDataAdapter(emptyDataSet));
      } else if (state.data) {
        // if startRecord === 0 assume a brand new data set and create new adapter
        if (state.data.startRecord === 0) {
          this.gridApi.setDatasource(this.createDataAdapter(state.data));
          this.gridApi.clearFocusedCell();
        } else if (<IGetRowsParams>state.context) {
          this.triggerSuccessCallback(<IGetRowsParams>state.context, state.data);
        }
      } else if (state.dataFetchState === XpoBoardDataFetchState.Error) {
        // make sure we properly tell ag-grid the request failed
        if (<IGetRowsParams>state.context) {
          (<IGetRowsParams>state.context).failCallback();
        }

        // there might be a better way to do this than simply clear out the data,
        // but while testing, even thought I called failCallback ag-grid still rendered
        // the rows as blank.  So for now if an error occurs do the failure callback
        // and then just completely reset the grid
        const emptyDataSet = new XpoAgGridBoardData(XpoBoardData.empty<XpoAgGridBoardData>(<XpoBoardState>state));
        this.gridApi.setDatasource(this.createDataAdapter(emptyDataSet));
      }
    }

    // If totals data, pin totals row to the bottom
    if (state.data && state.data.consumerData.totals) {
      if (
        state.data.consumerData.totals.length > 0 &&
        state.dataFetchState === XpoBoardDataFetchState.ResultsReturned
      ) {
        this.gridApi.setPinnedBottomRowData(state.data.consumerData.totals);
      } else {
        this.gridApi.setPinnedBottomRowData(null);
      }
    }
  }

  private generateSortOrderFromState(state: XpoAgGridBoardState): any[] {
    const agColumns = this.columnApi ? this.columnApi.getAllColumns() : [];

    return (state.sortOrder || []).map((orderEl) => {
      const agColumn = agColumns.find((col) => col.getColDef() != null && col.getColDef().field === orderEl.column);

      // The board sort model saves the column field, and this can be different from columnId sometimes
      // So if we can we will pass to ag grid the actual columnId
      return {
        colId: agColumn ? agColumn.getColId() : orderEl.column,
        sort: orderEl.direction,
      };
    });
  }

  /**
   * Returns the sorting from column state as of ag-grid v23+
   */
  private getSortModel(): ColumnState[] {
    return this.columnApi
      .getColumnState()
      .map((c: ColumnState) => ({ colId: c.colId, sort: c.sort }))
      .filter((c) => c.sort);
  }

  private generateSortOrderFromSortModel(agGridSortModel: any[]): XpoSortExpression[] {
    // Build the board data sort model by using colDef.field instead of colId
    // since these can be different sometimes
    return (agGridSortModel || []).map((sortModelCol) => {
      const agColumn = this.columnApi.getColumn(sortModelCol.colId);

      return new XpoSortExpression(agColumn ? agColumn.getColDef().field : sortModelCol.colId, sortModelCol.sort);
    });
  }

  private initializeGridOptions(): void {
    // TODO: update when data source changes
    this.gridOptions.cacheBlockSize = this.dataSource.pageSize;
    this.gridOptions.onModelUpdated = (event) => this.onModelUpdated(event);
    this.gridOptions.onSelectionChanged = (event) => this.onGridSelectionChanged(event);
    this.gridOptions.onCellClicked = (event) => this.onCellClicked(event);
    this.gridOptions.onSortChanged = () => this.onSortChanged();
    this.gridOptions.onColumnMoved = (event) => this.onColumnMovedEvent(event);
    this.gridOptions.onColumnPinned = (event) => this.onColumnPinnedEvent(event);
    this.gridOptions.onColumnResized = (event) => this.onColumnResizedEvent(event);
    this.gridOptions.paginationPageSize = this.dataSource.pageSize;
    this.gridOptions.rowSelection = this.rowSelection;
    this.gridOptions.groupUseEntireRow = this.groupUseEntireRow;
    this.gridOptions.suppressMovableColumns = !this.enableMovableColumns;
    this.gridOptions.suppressRowClickSelection = this.selectionMode === 'row' ? false : !this.enableRowSelection;
    this.gridOptions.suppressCellSelection = this.selectionMode === 'cell' ? false : !this.enableCellSelection;
    this.gridOptions.defaultColDef = { sortable: this.enableSorting };
    this.gridOptions.frameworkComponents = this.frameworkComponents;
    this.gridOptions.onGridReady = (event) => this.onGridReady(event);
    this.configureRowModelType();
    this.configurePostSorting();
    this.configureCustomGridStyles();

    if (this.customGridOptions && this.customGridOptions.onGridReady) {
      console.error(
        'XpoAgGridBoard: customGridOptions.onGridReady cannot be used, please listen to the (gridBoardReady) event'
      );
    }

    this.gridOptions = merge.all([this.gridOptions, this.globalAgGridOptions || {}, this.customGridOptions || {}]);
  }

  private onColumnResizedEvent(event: ColumnResizedEvent): void {
    // The action we want to perform with the column to be updated
    const updateWidthAction = (colDef: ColDef, column: Column) => {
      colDef.width = column.getActualWidth() || column.getMinWidth();
    };
    // The event.finished is true after they detect the user stops the movements
    // otherwise the onColumnResizedEvent is fired after each px change
    if (event.finished) {
      this.updateStateColumns(XpoColumnsHelper.onGeneralColumnEvent(event, updateWidthAction, this.columns));
    }
  }

  private onColumnPinnedEvent(event: ColumnPinnedEvent): void {
    // The action we want to perform with the column to be updated
    const updatePinnedAction = (colDef: ColDef) => {
      colDef.pinned = event.pinned;
    };
    this.updateStateColumns(XpoColumnsHelper.onGeneralColumnEvent(event, updatePinnedAction, this.columns));
  }

  private onColumnMovedEvent(event: ColumnMovedEvent): void {
    this.updateStateColumns(XpoColumnsHelper.onColumnMovedEvent(event, this.columns));
  }

  /**
   * Post sorting actions
   * initializePostSorting
   */
  private configurePostSorting(): void {
    /* Perform a cell refresh after sorting preserving postSort defined by developers in their app.
      This is needed to keep row index in sync when sorting or filtering is applied. */
    if (this.customGridOptions && this.customGridOptions.postSort) {
      const userDefinedPostSort = this.customGridOptions.postSort;
      this.customGridOptions.postSort = (e) => {
        this.gridOptions.api.refreshCells();
        if (userDefinedPostSort instanceof Function) {
          userDefinedPostSort(e);
        }
      };
    }
  }

  /**
   * Configures row model type and sorting configurations baed on the inputted row model type,
   * defaults to infinite
   */
  private configureRowModelType(): void {
    if (this.rowModelType !== 'client-side') {
      this.gridOptions.rowModelType = this.rowModelType;
    }
    this.stateChange$.next({
      rowModelType: this.rowModelType,
      source: XpoBoardStateSources.RowModelType,
    });
  }

  private configureGridColumns(): void {
    if (!this.columns.length && !this.columnsFromDataSource.length) {
      return;
    }

    // Confirm all the columns have a col id defined or assign one to each
    this.columns = XpoColumnsHelper.addIdsToColumns(this.columns);

    /**
     * Every time columns change evaluate if custom select is needed because this
     * component is not being restarted when you change between grids
     * And you need to keep it updated to prevent errors
     */
    this.checkAddingPaginatedSelectAll();

    /**
     * Reset columns since when switching from a previous view with same colIDs, ag-grid incorrectly stores the old
     * columns as they comment in: https://github.com/ag-grid/ag-grid/blob/0ab1a8ae3d313b3587ee6fb22344ce7f80a31c79/
     * community-modules/core/src/ts/columnController/columnController.ts#L198 and then try to assign unique ids to
     * cols causing wrong col names like status_1 (_1 as the unique part), in deed, causing filters to fail.
     * This is just to have a workaround from our side to something that ag-grid has not properly addressed yet.
     */
    this.gridApi.setColumnDefs([]);

    this.gridApi.setColumnDefs([...this.columns, ...this.columnsFromDataSource]);
    this.gridOptions.columnApi.resetColumnState();
  }

  /**
   * State change with the current selection on client-side
   * @param event
   */
  private onGridSelectionChanged(event: SelectionChangedEvent): void {
    // TODO: Move all hardcoded "client-side" strings into enums
    if (!this.columns) {
      return;
    }
    // Fire SelectionChange only if not using our custom select-all (that also fires selection change)
    if (!this.isUsingCustomSelectAllColumn()) {
      const selectedNodes = event.api.getSelectedNodes() || [];
      const selection = selectedNodes.map((node) => node.data);

      this.stateChange$.next({
        selection,
        source: XpoBoardStateSources.SelectionChange,
      });
    }
  }
  /**
   * Determine whether if user is using our custom select-all column or not
   */
  private isUsingCustomSelectAllColumn(): boolean {
    return this.columns.some((c: ColDef) => {
      return c.headerComponentFramework && c.headerComponentFramework.name === XpoAgGridSelectAllCheckbox.name;
    });
  }
  /**
   * If headerCheckboxSelection is declared on a column in an infinite scroll ag-grid,
   * add our custom select all component since ag-grid doesn't support this feature;
   * Else remove our custom select all to prevent duplication with the ag-grid one.
   */
  private checkAddingPaginatedSelectAll(columns = this.columns, isDetailGrid = false): void {
    const checkboxSelectionColumn: ColDef = columns.find((c: ColDef) => c.checkboxSelection);
    if (checkboxSelectionColumn && checkboxSelectionColumn.headerCheckboxSelection) {
      updateFrameworkAndParams(XpoAgGridSelectAllCheckbox, {
        keyField: isDetailGrid ? this.cachedState.template.detailGridKeyField : this.cachedState.template.keyField,
        isDetailGrid,
      });
    }

    function updateFrameworkAndParams(framework?, params?): void {
      checkboxSelectionColumn.headerComponentFramework = framework;
      checkboxSelectionColumn.headerComponentParams = params;
    }
  }

  /**
   * Configure classes and styles we need to use preserving project level defined ones
   */
  private configureCustomGridStyles(): void {
    const ACCORDION_GROUP_OFFSET = 0;
    this.customGridOptions = this.customGridOptions || {};

    // Merge project level rowClassRules with ours
    this.customGridOptions.rowClassRules = {
      ...this.customGridOptions.rowClassRules,
      ...{
        // This class is used for accordion and grouping related features
        'xpo-RowGroup': (params) => {
          return params.node && params.node.group;
        },
      },
    };
    // Define autoGroupColumnDef in case user never defined it
    this.customGridOptions.autoGroupColumnDef = this.customGridOptions.autoGroupColumnDef || {};

    // Preserve user cellStyle
    const userCellStyles = this.customGridOptions.autoGroupColumnDef.cellStyle;
    this.customGridOptions.autoGroupColumnDef.cellStyle = (params) => {
      let customStyles = {};

      /* Grouping node to left, that's in case rowIndex, SelectAll or Transpose is present
       */
      if (params.node.group) {
        customStyles = {
          left: `${ACCORDION_GROUP_OFFSET}px`,
        };
      }
      // cellStyle can either be a callable function or an object with the styles
      const userDefinedStyles = typeof userCellStyles === 'function' ? userCellStyles(params) : userCellStyles;
      // Take developer defined styles and merge them with ours
      customStyles = {
        ...customStyles,
        ...userDefinedStyles,
      };
      return customStyles;
    };
  }

  private createDataAdapter(initialBoardData: XpoAgGridBoardData): IDatasource {
    const self = this;

    return {
      getRows(params: IGetRowsParams): void {
        if (params.startRow > 0 || self.pendingSortApply) {
          // If self.pagination means user enabled our custom pagination so we ask for the page number to it,
          // otherwise fallback to current infinite scroll pagination logic.
          const pageNumber = self.pagination
            ? self.pagination.currentPage
            : Math.max(params.startRow / self.dataSource.pageSize + 1, 1);
          const sortOrder = self.generateSortOrderFromSortModel(params.sortModel);
          const source = self.pendingSortApply ? XpoBoardStateSources.SortChange : XpoBoardStateSources.PageChange;

          self.stateChange$.next({ pageNumber, context: params, source, sortOrder });
          self.pendingSortApply = false;
        } else {
          self.triggerSuccessCallback(params, initialBoardData);
          self.trySelectingFirstRow();
        }

        const inComingFilters = params.filterModel && Object.keys(params.filterModel).length;
        const filtersChanged = !isEqual(params.filterModel, self.cachedAgGridFilterModel);

        if (inComingFilters && filtersChanged) {
          let mergedCriteria = self.mergeCriteriaWithFilterModel(params.filterModel);
          mergedCriteria = self.checkEqualCriteriaAllSelected(mergedCriteria, self);
          self.filtersService.setCriteria(mergedCriteria);
        }
      },
    };
  }

  /**
   * Check if merged equal conditions are all selected and set correct diplay
   */
  private checkEqualCriteriaAllSelected(mergedCriteria: XpoFilterCriteria, self: any): XpoFilterCriteria {
    const mergedCriteriaClone = cloneDeep(mergedCriteria);
    Object.keys(mergedCriteriaClone).forEach((criteria) => {
      const filterFromModel = self.gridApi.getFilterModel();
      const filterCriteria = filterFromModel[criteria]?.filter;
      if (filterCriteria?.conditions) {
        filterCriteria.conditions.forEach((conditions) => {
          if (conditions.display === 'all' && conditions.operator === Operators.Equals) {
            mergedCriteriaClone[criteria].conditions.forEach((criteriaCondition) => {
              if (criteriaCondition.operator === Operators.Equals) {
                criteriaCondition.display = 'all';
              }
              return criteriaCondition;
            });
          }
        });
      }
    });
    return mergedCriteriaClone;
  }
  private triggerSuccessCallback(params: IGetRowsParams, data: XpoBoardData): void {
    const hideRecordCountFromAgGrid = this.suppressUsingRecordCount && params.endRow <= data.recordCount;
    const recordCount = hideRecordCountFromAgGrid ? null : data.recordCount;

    params.successCallback(data.consumerData.rows, recordCount);
  }

  private updateStateColumns(newColumns: XpoAgGridBoardColumn[]): void {
    if (newColumns) {
      this.columns = newColumns;
      this.stateChange$.next(<XpoAgGridBoardState>{
        visibleColumns: this.columns,
        source: 'SET-VISIBLE-COLUMNS',
      });
    }
  }

  private trySelectingFirstRow(): void {
    if (this.autoSelectFirstRow) {
      const firstRow = this.gridOptions.api.getDisplayedRowAtIndex(0);

      if (firstRow) {
        firstRow.setSelected(true);
      }
    }
  }

  private registerBoardApiHandlers(): void {
    this.agGridBoardApiDispatcher.registerHandler(XpoAgGridBoardApiAction.FocusRow, this.focusRow.bind(this));
    this.agGridBoardApiDispatcher.registerHandler(
      XpoAgGridBoardApiAction.UpdateEditingState,
      this.updateEditingState.bind(this)
    );
  }

  private focusRow(rowSelection: any): void {
    const viewTemplate = <XpoAgGridBoardViewTemplate>this.cachedState.template;
    const viewTemplateKey = viewTemplate ? viewTemplate.keyField : null;
    if (!viewTemplateKey) {
      // TODO: this should throw an error, but we will need to call the error callback for ag-grid
      // if you throw an error here, pagingation is broken
      console.error(
        `View template ${viewTemplate.name} has a null keyField value.
        Please provide the key of the field that uniquely identifies each row for this viewTemplate
        in order for some functionality of the ag-grid-board to work`
      );
      return;
    }

    this.gridApi.clearFocusedCell();

    const gridColumns = this.gridOptions.columnApi.getAllColumns().reverse();

    this.gridApi.forEachNode((node) => {
      if (node.data && rowSelection[viewTemplateKey] === node.data[viewTemplateKey]) {
        // focus all cells from row
        gridColumns.forEach((column) => {
          this.gridApi.setFocusedCell(node.rowIndex, column.getColId());
        });
        // scroll the grid to see the current node
        this.gridApi.ensureNodeVisible(node, null);
        return;
      }
    });
  }

  private applyRowHeightUpdate(state: XpoAgGridBoardState): void {
    if (!this.gridApi || !this.isGridReady) {
      return;
    }

    if (this.rowModelType === 'client-side') {
      this.rowHeight = state.rowHeight;
    } else {
      // Infinite scroll does not support changing the row heights
      // Ag-grid logic is calculating the virtual view size only once, in their AfterViewInit logic
      // So our only option is to re-create the entire grid all together with the new row height
      // Maybe a future ag-grid version will allow this, and we could delete all these expensive operations

      // Destroy the grid
      this.gridVisible = false;
      this.cd.detectChanges();
      // Reset cached values
      this.cachedState = null;
      this.isGridReady = false;
      this.columns = [];
      this.columnsFromDataSource = [];
      // Re-create the grid with the new height
      this.gridVisible = true;
      this.rowHeight = state.rowHeight;
      this.cd.detectChanges();
      // Reload the data into the new grid
      this.dataSource.refresh();
    }
  }

  private loadCriteriaIntoColumnFilters(criteria: XpoFilterCriteria): void {
    // columnApi.getAllGridColumns returns user defined columns and dynamic generated columns like group ones
    const allGridColumns = this.columnApi.getAllGridColumns();

    if (!allGridColumns) {
      return;
    }

    const inlineFilterColumnsFields = allGridColumns.filter((col) => {
      const colDef = col.getColDef();
      return (colDef.field || colDef.colId) && colDef.filter;
    });

    inlineFilterColumnsFields.forEach((col) => {
      const colDef = col.getColDef();
      const filterInstance = this.gridApi.getFilterInstance(colDef.colId || colDef.field);

      if (filterInstance) {
        // If we have a value for this column on criteria, set that one,
        // else just clear out whatever is there (by assigning null)
        // TODO: fix this to work with `not` `exclude` and such.
        const filterModel = criteria.hasOwnProperty(colDef.field)
          ? { filter: criteria[colDef.field], type: 'contains' }
          : null;

        filterInstance.setModel(filterModel);
      }
    });

    if (inlineFilterColumnsFields.length) {
      this.gridApi.onFilterChanged();
    }
  }

  private applyColumnFiltersVisibility(filtersVisible: boolean): void {
    // change floating filters header row visibility only if floating filters are configured by default
    // and if there is some column filter defined
    // otherwise hide floating filters header row
    this.gridOptions.defaultColDef.floatingFilter =
      this.showFloatingFilters && this.hasColumnLevelFilters() ? filtersVisible : false;
    this.gridApi.refreshHeader();
  }

  private mergeCriteriaWithFilterModel(agGridFilterModel: any): XpoFilterCriteria {
    // First remove the keys from criteria that were coming from the old filter model
    const strippedCriteria: XpoFilterCriteria = Object.keys(this.filtersService.criteria)
      .filter((k) => Object.keys(this.cachedAgGridFilterModel).indexOf(k) === -1)
      .reduce((res, k) => ({ ...res, [k]: this.filtersService.criteria[k] }), {});

    // Update the saved filter model
    this.cachedAgGridFilterModel = agGridFilterModel;

    // Map from ag-grid filter model to our own criteria
    // For now this is mapping to simple key-value primitives like {color: 'red'}
    const mappedFilterModel = Object.keys(agGridFilterModel).reduce(
      (res, field) => ({ ...res, [field]: agGridFilterModel[field].filter }),
      {}
    );

    return { ...strippedCriteria, ...mappedFilterModel };
  }

  private addColumnLevelFilters(): void {
    if (!this.columnFilters || this.columnFilters.length === 0) {
      return;
    }

    const filerColumnPair = this.columnFilters
      .map((filter) => {
        const foundColumn: ColDef = this.columns.find((c: ColDef) => c.field && c.filter && c.field === filter.field);

        return foundColumn ? { filter, foundColumn } : undefined;
      })
      .filter((v) => !!v);

    filerColumnPair.forEach((p) => {
      p.foundColumn.filter = true;
      p.foundColumn.floatingFilterComponent = 'xpoAgGridFilterChipComponent';
      p.foundColumn.floatingFilterComponentParams = <XpoAgGridFilterChipComponentParams>{
        criteriaStore: this.filtersService,
        filter: p.filter,
        suppressFilterButton: true,
      };
    });
  }

  /**
   * onModelUpdated event listener.
   * If the grid suffer change dispatch this event
   *
   * @param event The event being triggered off of
   */
  private onModelUpdated(event: ModelUpdatedEvent): void {
    if (!this.gridApi) {
      return;
    }
    const newVisibleRows = this.gridApi.getDisplayedRowCount();
    if (this.isDataAlreadyRendered() && this.cachedState.visibleRows !== newVisibleRows) {
      this.stateChange$.next({
        source: 'update-row-count',
        visibleRows: newVisibleRows,
      });
      // Notify change to pagination
      if (this.pagination) {
        this.pagination.notifyPagination();
      }
    }

    /**
     * Every time the model update is dispatched iterate the detail grids replacing, if exists, the default ag-grid select all component
     * with our custom one.
     */
    this.gridApi.forEachDetailGridInfo((detailGridApi) => {
      const detailGridColumns = detailGridApi.columnApi.getAllColumns().map((c) => c.getColDef());
      this.checkAddingPaginatedSelectAll(detailGridColumns, true);
      detailGridApi.api.refreshHeader();
    });
  }

  private isDataAlreadyRendered(): boolean {
    if (!this.dataAlreadyRendered) {
      this.dataAlreadyRendered = coerceBooleanProperty(this.cachedState && this.cachedState.data);
    }
    return this.dataAlreadyRendered;
  }

  /**
   * Prepare values to be displayed in applied filters, custom filters
   */
  private getCustomFilterValues(colDef: ColDef, values: string[]): string[] {
    const displayValues: string[] = [];

    // Conditional enum filter
    if (colDef.filterParams && colDef.filterParams.enumValues) {
      values.forEach((val) => {
        const customValue = colDef.filterParams.enumValues[val] || val;
        displayValues.push(customValue);
      });
    }
    return displayValues;
  }

  private updateEditingState(isEditing: boolean, field?: string): void {
    if (isEditing) {
      this.startEditing(field || '');
    } else {
      this.stopEditing();
    }
  }

  private startEditing(colKey: string): void {
    this.gridApi.startEditingCell({
      colKey: colKey,
      rowIndex: 0,
    });
  }

  private stopEditing(): void {
    this.gridApi.stopEditing();
  }

  private initializeColumnLevelFilteringVisibleState(): void {
    // preserve the initial value of floating filters available
    // this defaultColDef property could change later when showing or hiding the floating filters row
    // TODO: when update ag-grid package, evaluate this option at colDef level too (NGXLT-372)
    this.showFloatingFilters = this.gridOptions.defaultColDef.floatingFilter;

    // trigger a state update to let board know if the toggle filters button should be visible
    this.stateChange$.next(<XpoGridBoardState>{
      filtersButtonVisible: this.showFloatingFilters,
      source: XpoBoardStateSources.InitializeFloatingFilters,
    });

    // do not show filter button next to floating filter input
    this.gridOptions.defaultColDef.floatingFilterComponentParams = { suppressFilterButton: true };

    // by default, ag-grid shows floating filters header row depending on gridOptions.floatingFilters
    // if we are going to show floating filters
    // trigger a state update so that the XpoColumnLevelFilteringToggleComponent gets this default value
    if (this.showFloatingFilters) {
      const floatingFiltersVisible =
        this.gridOptions.context && this.gridOptions.context.filtersOptionsVisibleDefaultValue !== undefined
          ? this.gridOptions.context.filtersOptionsVisibleDefaultValue
          : XpoColumnLevelFilteringToggleComponent.IsFilterVisibleDefaultValue;

      this.stateChange$.next(<XpoGridBoardState>{
        columnLevelFilteringVisible: floatingFiltersVisible,
        source: XpoBoardStateSources.InitializeFloatingFilters,
      });
    }
  }

  private hasColumnLevelFilters(): boolean {
    // find at lease one column with a filter defined
    const allColumns = this.columnApi.getAllColumns();
    return allColumns ? allColumns.some((col) => col.isVisible && col.isFilterAllowed) : false;
  }
}
