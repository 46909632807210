import {PricingRulesetOwnerCd} from '../';
import {EnumHelper} from './enum-helper';

export class PricingRulesetOwnerCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PricingRulesetOwnerCd ) {
      this._values.push(PricingRulesetOwnerCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PricingRulesetOwnerCd {

    for ( const obj in PricingRulesetOwnerCd ) {
      if (PricingRulesetOwnerCd[obj] === value){
        return PricingRulesetOwnerCd[obj] as PricingRulesetOwnerCd;
      }
    }
  }
}

const PricingRulesetOwnerCdHelper = new PricingRulesetOwnerCdHelperEnumHelperClass();
export default PricingRulesetOwnerCdHelper;
