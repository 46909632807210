import {HssRegionCd} from '../';
import {EnumHelper} from './enum-helper';

export class HssRegionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in HssRegionCd ) {
      this._values.push(HssRegionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): HssRegionCd {

    for ( const obj in HssRegionCd ) {
      if (HssRegionCd[obj] === value){
        return HssRegionCd[obj] as HssRegionCd;
      }
    }
  }
}

const HssRegionCdHelper = new HssRegionCdHelperEnumHelperClass();
export default HssRegionCdHelper;
