import { FocusMonitor } from '@angular/cdk/a11y';
import { ChangeDetectorRef, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { MatStepHeader, MatStepperIntl } from '@angular/material/stepper';

@Component({
  selector: 'xpo-step-header',
  templateUrl: 'step-header.component.html',
  styleUrls: ['step-header.component.scss'],
  host: {
    class: 'xpo-StepHeader',
    role: 'tab',
  },
  encapsulation: ViewEncapsulation.None,
})
export class XpoStepHeaderComponent extends MatStepHeader {
  @Input() title: string;

  constructor(
    intl: MatStepperIntl,
    focusMonitor: FocusMonitor,
    _elementRef: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(intl, focusMonitor, _elementRef, changeDetectorRef);
  }
}
