export enum XpoLtlDocType {
  ActivityLogHistory = 'ACTL',
  All = 'ALL',
  BillOfLading = 'BL',
  BillOfLadingAttachment = 'BLAT',
  CheckSheet = 'CHKS',
  ClaimForm = 'CLM',
  ClaimNotes = 'CLMN',
  ClaimsAttachment = 'CLAT',
  ClaimsProductionUnknown = 'PXFC',
  ClosedClaimFolder = 'CLMH',
  CustomerUpload = 'CUPD',
  CustomsDocuments = 'CUST',
  DeliveryExceptionPhoto = 'DXFO',
  DeliveryReceipt = 'DR',
  DeliveryReceiptAttachment = 'DRAT',
  DeliveryReturnReceipt = 'DRRR',
  FlySheet = 'FLYS',
  InMotionDimensionerPhoto = 'DMNR',
  InspectionReport = 'IR',
  InspectionReportPhoto = 'IRFO',
  InvoicePdf = 'ARIN',
  LetterOfAuthority = 'LOA',
  MatchMailAcknowledgement = 'MMAK',
  NmfcClassInspectionCertificate = 'NCIC',
  OSDPhoto = 'OSDF',
  WeightAndResearchPhotos = 'WRFO',
  WeightInspectionCertificate = 'WI',
  TrailerPhotoOpen = 'TOFO',
  TrailerPhotoClose = 'TLFO',
  TrailerPhotoProgressive = 'TPFO',
}
