
export enum UserRoleCd {
	CDS = 'CDS',
	DNM = 'DNM',
	FAC = 'FAC',
	FOM = 'FOM',
	FOS = 'FOS',
	MANAGER_SUPERVISOR_LINEHAUL = 'ManagerSupervisorLinehaul',
	RM = 'RM',
	SCM = 'SCM'}

