import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { XpoBoardDataSource } from './board-data-source.model';

@Injectable()
export class XpoBoardDataSourceResolver {
  private dataSourceSource$ = new ReplaySubject<XpoBoardDataSource>(1);
  private dataSourceValue: XpoBoardDataSource;

  get dataSource(): XpoBoardDataSource {
    return this.dataSourceValue;
  }
  set dataSource(value: XpoBoardDataSource) {
    this.dataSourceValue = value;
    this.dataSourceSource$.next(this.dataSourceValue);
  }

  get dataSource$(): Observable<XpoBoardDataSource> {
    return this.dataSourceSource$.asObservable();
  }
}
