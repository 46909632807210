import moment from 'moment';

/**
 * Converts either what the users passes in or ISO into XPO LTL's preferred format
 * @param params ag-Grid params
 */
export function xpoDateFormatter(params: any, inputFormat: string = 'YYYY-MM-DD'): string {
  if (params.value) {
    return moment(params.value, inputFormat).format('MM/DD/YYYY');
  }
}

/**
 * Converts 2012-04-23T18:25:43.511Z into XPO LTL's preferred format
 * @param params ag-Grid params
 */
export function xpoShortDateFormatter(params: any, inputFormat: string = 'YYYY-MM-DD'): string {
  if (params.value) {
    return moment(params.value, inputFormat).format('MM/DD');
  }
}

/**
 * Converts either what the users passes in or ISO into XPO LTL's preferred format
 * @param params ag-Grid params
 */
export function xpoDateTimeFormatter(params: any, inputFormat: string = 'YYYY-MM-DD HH:mm:ssZ'): string {
  if (params.value) {
    return moment(params.value, inputFormat).format('MM/DD/YYYY HH:mm');
  }
}

/**
 * Converts either what the users passes in or ISO into XPO LTL's preferred format
 * @param params ag-Grid params
 */
export function xpoTimeFormatter(params: any, inputFormat: string = 'HH:mm:ss'): string {
  if (params.value) {
    return moment(params.value, inputFormat).format('HH:mm');
  }
}
