import {DimensionSourceCd} from '../';
import {EnumHelper} from './enum-helper';

export class DimensionSourceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DimensionSourceCd ) {
      this._values.push(DimensionSourceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DimensionSourceCd {

    for ( const obj in DimensionSourceCd ) {
      if (DimensionSourceCd[obj] === value){
        return DimensionSourceCd[obj] as DimensionSourceCd;
      }
    }
  }
}

const DimensionSourceCdHelper = new DimensionSourceCdHelperEnumHelperClass();
export default DimensionSourceCdHelper;
