import {RouteActionCd} from '../';
import {EnumHelper} from './enum-helper';

export class RouteActionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RouteActionCd ) {
      this._values.push(RouteActionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RouteActionCd {

    for ( const obj in RouteActionCd ) {
      if (RouteActionCd[obj] === value){
        return RouteActionCd[obj] as RouteActionCd;
      }
    }
  }
}

const RouteActionCdHelper = new RouteActionCdHelperEnumHelperClass();
export default RouteActionCdHelper;
