import { Component, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import { xpoDateTimeFormatter } from '../../../../../../formatters';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { filterFormats, Operators, OperatorText, XpoFilterConditionCriteria } from './../../../../../models/index';

interface DateTimeModel {
  date: Date | null;
  time: string;
}

@Component({
  templateUrl: './before.component.html',
  styleUrls: ['../../validator.scss'],
})
export class XpoConditionDateTimeBeforeComponent extends XpoConditionBase implements XpoFilterCondition {
  static text: string = OperatorText.Before;
  static operator = Operators.Before;

  isNegative = false;
  model: DateTimeModel = {
    date: null,
    time: '',
  };

  @Output() inputChanged = new EventEmitter();

  constructor() {
    super(filterFormats.default, true, true);
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    if (!cellValue) {
      return false;
    }

    return moment(cellValue)
      .startOf('day')
      .isBefore(moment(`${this.model.date} ${this.model.time}`));
  }
  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.Before,
      value: this.model,
      display: xpoDateTimeFormatter({ value: moment(`${this.model.date} ${this.model.time}`).subtract(1, 'minute') }),
    };
  }
  onInputChange(): any {
    this.validateFormat();
    this.inputChanged.emit(this.model);
  }
}
