import {CustomerExpiryReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerExpiryReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerExpiryReasonCd ) {
      this._values.push(CustomerExpiryReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerExpiryReasonCd {

    for ( const obj in CustomerExpiryReasonCd ) {
      if (CustomerExpiryReasonCd[obj] === value){
        return CustomerExpiryReasonCd[obj] as CustomerExpiryReasonCd;
      }
    }
  }
}

const CustomerExpiryReasonCdHelper = new CustomerExpiryReasonCdHelperEnumHelperClass();
export default CustomerExpiryReasonCdHelper;
