import {PickupRequestReasonCategoryCd} from '../';
import {EnumHelper} from './enum-helper';

export class PickupRequestReasonCategoryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PickupRequestReasonCategoryCd ) {
      this._values.push(PickupRequestReasonCategoryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PickupRequestReasonCategoryCd {

    for ( const obj in PickupRequestReasonCategoryCd ) {
      if (PickupRequestReasonCategoryCd[obj] === value){
        return PickupRequestReasonCategoryCd[obj] as PickupRequestReasonCategoryCd;
      }
    }
  }
}

const PickupRequestReasonCategoryCdHelper = new PickupRequestReasonCategoryCdHelperEnumHelperClass();
export default PickupRequestReasonCategoryCdHelper;
