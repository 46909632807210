import {CubicFeetProfileMthdCd} from '../';
import {EnumHelper} from './enum-helper';

export class CubicFeetProfileMthdCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CubicFeetProfileMthdCd ) {
      this._values.push(CubicFeetProfileMthdCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CubicFeetProfileMthdCd {

    for ( const obj in CubicFeetProfileMthdCd ) {
      if (CubicFeetProfileMthdCd[obj] === value){
        return CubicFeetProfileMthdCd[obj] as CubicFeetProfileMthdCd;
      }
    }
  }
}

const CubicFeetProfileMthdCdHelper = new CubicFeetProfileMthdCdHelperEnumHelperClass();
export default CubicFeetProfileMthdCdHelper;
