import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { MODULE_GROUPS_COLUMNS } from './module-groups-columns';

export const MODULE_EXECUTOR_BOARD_TEMPLATES: XpoAgGridBoardViewTemplate[] = [
  new XpoAgGridBoardViewTemplate({
    id: 'module-groups',
    name: 'Module Groups',
    allowAdditional: false,
    availableColumns: MODULE_GROUPS_COLUMNS,
    keyField: 'name',
  }),
];
