import {DeviceShiftCd} from '../';
import {EnumHelper} from './enum-helper';

export class DeviceShiftCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DeviceShiftCd ) {
      this._values.push(DeviceShiftCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DeviceShiftCd {

    for ( const obj in DeviceShiftCd ) {
      if (DeviceShiftCd[obj] === value){
        return DeviceShiftCd[obj] as DeviceShiftCd;
      }
    }
  }
}

const DeviceShiftCdHelper = new DeviceShiftCdHelperEnumHelperClass();
export default DeviceShiftCdHelper;
