import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_TABS_CONFIG, MatTabsModule } from '@angular/material/tabs';
import { tabsConfig } from '../common/tabs/index';
import { XpoAlternateTabs } from './alternative-tabs.directive';
import { XpoPrimaryTabs } from './tabs-primary.directive';
import { XpoTabsWideNavDirective } from './tabs-wide-nav.directive';
import { XpoVerticalTab, XpoVerticalTabGroup, XpoVerticalTabs } from './vertical-tabs/index';

@NgModule({
  declarations: [
    XpoAlternateTabs,
    // tslint:disable-next-line: deprecation
    XpoPrimaryTabs,
    XpoVerticalTab,
    XpoVerticalTabGroup,
    XpoVerticalTabs,
    XpoTabsWideNavDirective,
  ],
  imports: [CommonModule, MatIconModule, MatTabsModule],
  exports: [
    MatTabsModule,
    XpoAlternateTabs,
    // tslint:disable-next-line: deprecation
    XpoPrimaryTabs,
    XpoVerticalTab,
    XpoVerticalTabGroup,
    XpoVerticalTabs,
    XpoTabsWideNavDirective,
  ],
  providers: [{ provide: MAT_TABS_CONFIG, useValue: tabsConfig }],
})
export class XpoTabsModule {}
