import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { XpoButtonModule } from '../button/button.module';
import { XpoIconModule } from '../icon/icon.module';
import { XpoEmptyStateActions } from './empty-state-actions.directive';
import { XpoEmptyStateDescription } from './empty-state-description.directive';
import { XpoEmptyStateHeader } from './empty-state-header.directive';
import { XpoEmptyStateIcon } from './empty-state-icon.directive';
import { XpoEmptyState } from './empty-state.component';

@NgModule({
  imports: [CommonModule, MatIconModule, XpoButtonModule, XpoIconModule],
  declarations: [XpoEmptyState, XpoEmptyStateActions, XpoEmptyStateDescription, XpoEmptyStateHeader, XpoEmptyStateIcon],
  exports: [XpoEmptyState, XpoEmptyStateActions, XpoEmptyStateDescription, XpoEmptyStateHeader, XpoEmptyStateIcon],
})
export class XpoEmptyStateModule {}
