import {LookerResultFormatCd} from '../';
import {EnumHelper} from './enum-helper';

export class LookerResultFormatCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LookerResultFormatCd ) {
      this._values.push(LookerResultFormatCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LookerResultFormatCd {

    for ( const obj in LookerResultFormatCd ) {
      if (LookerResultFormatCd[obj] === value){
        return LookerResultFormatCd[obj] as LookerResultFormatCd;
      }
    }
  }
}

const LookerResultFormatCdHelper = new LookerResultFormatCdHelperEnumHelperClass();
export default LookerResultFormatCdHelper;
