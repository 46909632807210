import { Component, EventEmitter, Output } from '@angular/core';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';
import { filterFormats, Operators, OperatorText, XpoFilterConditionCriteria } from './../../../../../models/index';

@Component({
  templateUrl: './not-equals.component.html',
  styleUrls: ['../number.scss'],
})
export class XpoConditionNumberNotEqualsComponent extends XpoConditionBase implements XpoFilterCondition {
  static text: string = OperatorText.IsNot; // value used in dropdown
  static operator = Operators.NotEquals;

  isNegative = true;
  model: string = '';

  @Output() inputChanged = new EventEmitter();

  constructor() {
    super(filterFormats.number);
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    if (!cellValue) {
      return false;
    }

    return parseFloat(cellValue) !== parseFloat(this.model);
  }
  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.NotEquals,
      value: this.model,
    };
  }

  onInputChange(): any {
    this.validateFormat();
    this.inputChanged.emit(this.model);
  }
}
