import {NodeTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class NodeTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in NodeTypeCd ) {
      this._values.push(NodeTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): NodeTypeCd {

    for ( const obj in NodeTypeCd ) {
      if (NodeTypeCd[obj] === value){
        return NodeTypeCd[obj] as NodeTypeCd;
      }
    }
  }
}

const NodeTypeCdHelper = new NodeTypeCdHelperEnumHelperClass();
export default NodeTypeCdHelper;
