import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { XpoButtonModule } from '../button/button.module';
import { XpoIconModule } from '../icon/icon.module';
import { ContextualHeaderActionsComponent } from './contextual-header-actions/contextual-header-actions.component';
import { ContextualHeaderArrowBackComponent } from './contextual-header-arrow-back/contextual-header-arrow-back.component';
import { ContextualHeaderItemsComponent } from './contextual-header-items/contextual-header-items.component';
import { ContextualHeaderComponent } from './contextual-header.component';

@NgModule({
  imports: [CommonModule, MatIconModule, XpoIconModule, XpoButtonModule, MatSlideToggleModule, MatTooltipModule],
  declarations: [
    ContextualHeaderComponent,
    ContextualHeaderActionsComponent,
    ContextualHeaderArrowBackComponent,
    ContextualHeaderItemsComponent,
  ],
  exports: [
    ContextualHeaderComponent,
    ContextualHeaderActionsComponent,
    ContextualHeaderArrowBackComponent,
    ContextualHeaderItemsComponent,
  ],
})
export class XpoContextualHeaderModule {}
