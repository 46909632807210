import {ApiTypeStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ApiTypeStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ApiTypeStatusCd ) {
      this._values.push(ApiTypeStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ApiTypeStatusCd {

    for ( const obj in ApiTypeStatusCd ) {
      if (ApiTypeStatusCd[obj] === value){
        return ApiTypeStatusCd[obj] as ApiTypeStatusCd;
      }
    }
  }
}

const ApiTypeStatusCdHelper = new ApiTypeStatusCdHelperEnumHelperClass();
export default ApiTypeStatusCdHelper;
