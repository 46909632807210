import { Component, Input } from '@angular/core';
import { XpoApplication } from '../../../../models/application.model';
import { XpoApplicationSwitcherService } from '../../../../services/application-switcher.service';

/**
 * The component that shows the application data
 */
@Component({
  selector: 'xpo-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
  host: {
    class: 'xpo-Application',
  },
})
export class XpoApplicationComponent {
  /** The application data */
  @Input() application: XpoApplication;

  constructor(protected service: XpoApplicationSwitcherService) {}

  /** Handles the favorite icon click */
  onFavoriteClick(event: MouseEvent): void {
    // prevent the navigation to the application url
    event.preventDefault();

    // change favorite application
    this.application.favorite = !this.application.favorite;

    // notifies the application change
    this.service.favoriteApplicationChange$.next(this.application);
  }
}
