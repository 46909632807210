import {InvoiceDetailDataTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoiceDetailDataTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoiceDetailDataTypeCd ) {
      this._values.push(InvoiceDetailDataTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoiceDetailDataTypeCd {

    for ( const obj in InvoiceDetailDataTypeCd ) {
      if (InvoiceDetailDataTypeCd[obj] === value){
        return InvoiceDetailDataTypeCd[obj] as InvoiceDetailDataTypeCd;
      }
    }
  }
}

const InvoiceDetailDataTypeCdHelper = new InvoiceDetailDataTypeCdHelperEnumHelperClass();
export default InvoiceDetailDataTypeCdHelper;
