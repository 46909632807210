import {CustomerAccountTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerAccountTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerAccountTypeCd ) {
      this._values.push(CustomerAccountTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerAccountTypeCd {

    for ( const obj in CustomerAccountTypeCd ) {
      if (CustomerAccountTypeCd[obj] === value){
        return CustomerAccountTypeCd[obj] as CustomerAccountTypeCd;
      }
    }
  }
}

const CustomerAccountTypeCdHelper = new CustomerAccountTypeCdHelperEnumHelperClass();
export default CustomerAccountTypeCdHelper;
