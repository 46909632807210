import { NgModule } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, MatTooltipModule } from '@angular/material/tooltip';

export const xpoTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 300,
  hideDelay: 0,
  touchendHideDelay: 1500,
  position: 'above',
};

@NgModule({
  imports: [MatTooltipModule],
  exports: [MatTooltipModule],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: xpoTooltipDefaults }],
})
export class XpoTooltipModule {}
