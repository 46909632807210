import {DoorMatchCd} from '../';
import {EnumHelper} from './enum-helper';

export class DoorMatchCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DoorMatchCd ) {
      this._values.push(DoorMatchCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DoorMatchCd {

    for ( const obj in DoorMatchCd ) {
      if (DoorMatchCd[obj] === value){
        return DoorMatchCd[obj] as DoorMatchCd;
      }
    }
  }
}

const DoorMatchCdHelper = new DoorMatchCdHelperEnumHelperClass();
export default DoorMatchCdHelper;
