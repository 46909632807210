import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'xpo-board-toolbar-settings-group',
  templateUrl: 'board-grid-toolbar-settings.component.html',
  styleUrls: ['board-grid-toolbar-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-GridToolbar-container-settings-buttons-group',
    'data-cy': 'settings-buttons-group',
  },
})
export class XpoBoardGridToolbarSettings {}
