import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { XpoButtonModule } from '../button/button.module';
import { XpoLtlPopoverModule } from '../xpo-ltl-popover/index';
import { XpoAccountPopover } from './account-popover.component';

@NgModule({
  imports: [CommonModule, XpoLtlPopoverModule, MatButtonModule, MatIconModule, XpoButtonModule],
  declarations: [XpoAccountPopover],
  exports: [XpoAccountPopover],
})
export class XpoAccountPopoverModule {}
