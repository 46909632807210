import { XpoDateRangeFilterTab } from './date-range-filter-tab';
import { XpoDateRangeGroupByIntervalType, XpoDateRangeInterval } from './date-range-interval';
import { XpoQuickFilter } from './date-range-quick-filter';

export abstract class XpoDateRangeIntervalBase implements XpoDateRangeInterval {
  protected posValue = 0;

  get pos(): number {
    return this.posValue;
  }
  set pos(value: number) {
    this.posValue = value;
  }

  abstract get groupByInterval(): XpoDateRangeGroupByIntervalType;

  abstract label: string;
  abstract getQuickFilterOptions(): XpoQuickFilter[];
  abstract getTabModel(): XpoDateRangeFilterTab;
  abstract setNext(nextInput: number): any;
  abstract setPrev(prevInput: number): any;
}
