import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

/* Material */
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';

/* XPO */
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import {
  XpoButtonModule,
  XpoCardModule,
  XpoChipsModule,
  XpoDialogModule,
  XpoIconModule,
  XpoSnackBarModule,
  XpoStatusIndicatorModule,
  XpoTooltipModule,
} from '@xpo-ltl/ngx-ltl-core';
import { SdkModuleExecutorModule } from '@xpo-ltl/sdk-moduleexecutor';

/* Ag-grid */
import { AgGridModule } from 'ag-grid-angular';

/* Modules */
import { DateTimeCellRendererModule } from '../shared/cell-renderers/date-time-cell-renderer/date-time-cell-renderer.module';

/* Components */
import {
  ModuleLibraryActionsCellRendererComponent,
  ModuleLibraryNameCellRenderComponent,
} from './cell-renderers/index';
import { ModuleLibraryTrailPanelComponent } from './components/trail-panel/trail-panel.component';
import { ModuleLibraryDeleteModuleVersionUnableDialogComponent } from './dialogs/index';
import { ModuleLibraryBoardComponent } from './module-library-board.component';

/* Services */
import { ListModuleGroupsService } from '../module-groups-board/services/list-module-groups.service';
import { ListModuleGroupsStoreService } from '../module-groups-board/services/list-module-groups.store.service';
import { ModuleGroupsService } from '../module-groups-board/services/module-groups.service';
import { ModuleLibraryBoardDataSource } from './services/module-library-board-dataSource';
import { ModuleLibraryDataDrawerDataSource } from './services/module-library-dataDrawer-dataSource';
import { ModuleLibraryService } from './services/module-library.service';

@NgModule({
  declarations: [
    ModuleLibraryBoardComponent,
    ModuleLibraryNameCellRenderComponent,
    ModuleLibraryActionsCellRendererComponent,
    ModuleLibraryDeleteModuleVersionUnableDialogComponent,
    ModuleLibraryTrailPanelComponent,
  ],
  imports: [
    CommonModule,
    XpoTooltipModule,
    XpoIconModule,
    XpoButtonModule,
    MatSidenavModule,
    XpoCardModule,
    XpoDialogModule,
    XpoAgGridBoardModule,
    XpoSnackBarModule,
    XpoStatusIndicatorModule,
    XpoChipsModule,
    SdkModuleExecutorModule,
    AgGridModule.withComponents([ModuleLibraryNameCellRenderComponent, ModuleLibraryActionsCellRendererComponent]),
    DateTimeCellRendererModule,
    RouterModule,
  ],
  entryComponents: [ModuleLibraryDeleteModuleVersionUnableDialogComponent],
  exports: [ModuleLibraryBoardComponent],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    ModuleLibraryBoardDataSource,
    ModuleLibraryService,
    ModuleLibraryDataDrawerDataSource,
    ListModuleGroupsService,
    ListModuleGroupsStoreService,
    ModuleGroupsService,
  ],
})
export class ModuleLibraryBoardModule {}
