import {ShipmentAcquiredTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentAcquiredTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentAcquiredTypeCd ) {
      this._values.push(ShipmentAcquiredTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentAcquiredTypeCd {

    for ( const obj in ShipmentAcquiredTypeCd ) {
      if (ShipmentAcquiredTypeCd[obj] === value){
        return ShipmentAcquiredTypeCd[obj] as ShipmentAcquiredTypeCd;
      }
    }
  }
}

const ShipmentAcquiredTypeCdHelper = new ShipmentAcquiredTypeCdHelperEnumHelperClass();
export default ShipmentAcquiredTypeCdHelper;
