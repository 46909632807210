import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  XpoAuthenticationComponent,
  XpoAuthenticationSilentComponent,
  XpoPostSignOutComponent,
} from './components/index';
import { XpoAuthRoutePaths } from './enums/auth-route-paths.enum';

const routes: Routes = [
  { path: XpoAuthRoutePaths.Authentication, component: XpoAuthenticationComponent },
  { path: XpoAuthRoutePaths.AuthSilent, component: XpoAuthenticationSilentComponent },
  { path: XpoAuthRoutePaths.PostSignOut, component: XpoPostSignOutComponent },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class XpoAuthRoutingModule {}
