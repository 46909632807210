import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { XpoBoardDataSource } from './board-data-source.model';
import { XpoBoardStreamData } from './board-stream-data.model';

/**
 * Use this when your grid is getting sent events from an external source (socket.io, firebase, etc)
 * You can pass in your stream source and the grid will handle those updates appropriately
 */
@Injectable()
export abstract class XpoBoardStreamingDataSource extends XpoBoardDataSource implements OnDestroy {
  private streamSourceSubscription: Subscription;

  constructor(public streamSource$: Observable<XpoBoardStreamData[]>) {
    super();

    // Subscribing to the updates stream
    this.streamSourceSubscription = this.streamSource$.subscribe((updates) => {
      this.handleUpdate(updates);
    });
  }

  ngOnDestroy(): void {
    this.streamSourceSubscription.unsubscribe();
  }

  private handleUpdate(updates: XpoBoardStreamData[]): void {
    if (!updates || !updates.length) {
      return;
    }

    // Update the visible view data with the amount of records that have been changes in the view.
    const visibleViewData = (this.stateCache.visibleViewData || []).map((vv) => {
      const recordChangeCount = (vv.recordChangeCount || 0) + updates.filter((v) => v.viewId === vv.viewId).length;

      return { viewId: vv.viewId, recordCount: vv.recordCount, recordChangeCount };
    });

    this.setState({ visibleViewData, source: 'BOARD-STREAM-VISIBLE-VIEW-DATA-UPDATE' });
  }
}
