
export enum TrailerStatusCd {
	APPOINTMENT = 'Appointment',
	ARRIVE = 'Arrive',
	CITY_ARRIVE = 'CityArrive',
	CITY_LOADING_AT_DOCK = 'CityLoadingAtDock',
	CLOSE_FOR_DELIVERY = 'CloseForDelivery',
	CLOSE_FOR_LINEHAUL = 'CloseForLinehaul',
	DROP = 'Drop',
	EMPTY = 'Empty',
	LOADING_AT_CUSTOMER = 'LoadingAtCustomer',
	LOADING_AT_DOCK = 'LoadingAtDock',
	OVERHEAD = 'Overhead',
	PICKUP_AND_DELIVERY = 'PickupAndDelivery',
	TRAP = 'Trap',
	UNLOAD_AT_CUSTOMER = 'UnloadAtCustomer',
	UNLOAD_AT_DOCK = 'UnloadAtDock'}

