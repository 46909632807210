import moment from 'moment';
import { DataToCheckValidity, formatChecker } from '../models';

export class InlineEditingValidators {
  static isValidNumber(editedData: DataToCheckValidity): formatChecker {
    return {
      fail: !matchNumber(editedData.editedRow.columns[editedData.column]),
      errorDescription: 'Number is invalid',
    };

    function matchNumber(number: string | number): boolean | RegExpMatchArray {
      return (
        number === '' ||
        (number && number.toString().match(/^-{0,1}\d+$/)) ||
        (number && number.toString().match(/^-{0,1}\d+(\.\d{0,5})?$/))
      );
    }
  }

  static isValidString(editedData: DataToCheckValidity): formatChecker {
    return {
      fail: false,
      errorDescription: 'Text is invalid',
    };
  }

  static isValidDate(editedData: DataToCheckValidity): formatChecker {
    return {
      fail: !moment(editedData.editedRow.columns[editedData.column], 'YYYY-MM-DD').isValid(),
      errorDescription: 'Date format is invalid',
    };
  }
}
