import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { XpoFilterComponentBase, XpoFilterCriteria } from '../models/index';
import { XpoQuickSearchFilter } from './quick-search-filter';

@Component({
  selector: 'xpo-quick-search-filter',
  templateUrl: 'quick-search-filter.component.html',
  styleUrls: ['quick-search-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-QuickSearchFilter', '[attr.id]': 'id' },
})
export class XpoQuickSearchFilterComponent extends XpoFilterComponentBase {
  static readonly InputValueChangeDebounceTime = 500;
  collapsible: boolean = false;
  collapsed: boolean = false;
  disableAutofocus: boolean = false;
  placeholderText: string = 'Search';
  searchFormControl: FormControl = new FormControl();

  constructor() {
    super();
    this.inline = true;

    this.searchFormControl.valueChanges
      .pipe(
        debounceTime(XpoQuickSearchFilterComponent.InputValueChangeDebounceTime),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((v) => {
        this.emitValue(v);
      });
  }

  /**
   * Clears inputs and sets focus back to input
   */
  clearInput(): void {
    this.searchFormControl.setValue(null);
  }

  /**
   * Emits quicksearch value change
   */
  emitValue(value: string): void {
    this.storeCriteria(value ? value.toString() : null);
  }

  /**
   * expands search field
   */
  expandSearchField(): void {
    this.collapsed = false;
  }

  /**
   * collapses search field on blur if input field is blank
   */
  collapseSearchField(val: string): void {
    this.collapsed = this.collapsible && !val && true;
  }

  protected initialize(): void {
    const config = <XpoQuickSearchFilter>this.configuration;
    this.collapsible = config.collapsible;
    this.collapsed = this.collapsible;
    this.disableAutofocus = config.disableAutofocus;
    this.placeholderText = config.placeholderText;
  }

  protected onCriteriaModified(fieldValue: object, criteria: XpoFilterCriteria): void {
    this.searchFormControl.setValue(fieldValue ? fieldValue.toString() : null, { emitEvent: false });
  }
}
