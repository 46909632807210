import {DriverSourceCd} from '../';
import {EnumHelper} from './enum-helper';

export class DriverSourceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DriverSourceCd ) {
      this._values.push(DriverSourceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DriverSourceCd {

    for ( const obj in DriverSourceCd ) {
      if (DriverSourceCd[obj] === value){
        return DriverSourceCd[obj] as DriverSourceCd;
      }
    }
  }
}

const DriverSourceCdHelper = new DriverSourceCdHelperEnumHelperClass();
export default DriverSourceCdHelper;
