import {ClaimDecisionSubCategoryCd} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimDecisionSubCategoryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimDecisionSubCategoryCd ) {
      this._values.push(ClaimDecisionSubCategoryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimDecisionSubCategoryCd {

    for ( const obj in ClaimDecisionSubCategoryCd ) {
      if (ClaimDecisionSubCategoryCd[obj] === value){
        return ClaimDecisionSubCategoryCd[obj] as ClaimDecisionSubCategoryCd;
      }
    }
  }
}

const ClaimDecisionSubCategoryCdHelper = new ClaimDecisionSubCategoryCdHelperEnumHelperClass();
export default ClaimDecisionSubCategoryCdHelper;
