import {DeltaVarianceTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DeltaVarianceTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DeltaVarianceTypeCd ) {
      this._values.push(DeltaVarianceTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DeltaVarianceTypeCd {

    for ( const obj in DeltaVarianceTypeCd ) {
      if (DeltaVarianceTypeCd[obj] === value){
        return DeltaVarianceTypeCd[obj] as DeltaVarianceTypeCd;
      }
    }
  }
}

const DeltaVarianceTypeCdHelper = new DeltaVarianceTypeCdHelperEnumHelperClass();
export default DeltaVarianceTypeCdHelper;
