import { ComponentType } from '@angular/cdk/portal';
import { AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Component, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MatCalendar,
  MatCalendarCell,
  MatCalendarUserEvent,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';
import { XpoDateRangePicker } from '../../date-range-picker.component';
import { XpoDateRangeSelectionModel } from '../../models/date-range-selection.model';
import { XpoDateRangeCalendarHeader } from './header/calendar-header.component';
import { XpoCalendarHeaderService } from './header/calendar-header.service';

/**
 * Two calendars component that allows to select a date range
 */
@Component({
  selector: 'xpo-date-range-calendar',
  exportAs: 'xpoDateRangeCalendar',
  templateUrl: 'date-range-calendar.component.html',
  styleUrls: ['date-range-calendar.component.scss'],
  host: {
    class: 'xpo-DateRangePickerCalendar',
  },
  // Needed to override mat-calendar styles
  encapsulation: ViewEncapsulation.None,
  providers: [
    XpoCalendarHeaderService,
    { provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: DefaultMatCalendarRangeStrategy },
  ],
})
export class XpoDateRangeCalendar<D> implements AfterViewInit {
  /** Each of the mat-calendar instances */
  @ViewChildren(MatCalendar) protected calendars: QueryList<MatCalendar<D>>;

  /** Instance of the start calendar */
  protected startCalendar: MatCalendar<D>;

  /** Instance for the end calendar */
  protected endCalendar: MatCalendar<D>;

  /** Reference to the date range picker that contains the calendar */
  dateRangePicker: XpoDateRangePicker<D>;

  /** Model for the selected range */
  protected selectionModel: XpoDateRangeSelectionModel<D>;

  constructor(
    protected headerService: XpoCalendarHeaderService<D>,
    protected dateAdapter: DateAdapter<D>,
    protected changeDetector: ChangeDetectorRef
  ) {
    // listen to period changes
    this.headerService.onNext.subscribe(() => this.setNextPeriod());
    this.headerService.onPrevious.subscribe(() => this.setPreviousPeriod());
  }

  ngAfterViewInit(): void {
    // keep the start calendar instance for future uses
    this.startCalendar = this.calendars.toArray()[0];

    // keep the end calendar instance for future uses
    this.endCalendar = this.calendars.toArray()[1];

    // set the period to show for both calendars
    // taking into account if there is a date range already selected
    this.showCurrentPeriod();

    // set focus on start calendar
    this.startCalendar.focusActiveCell();

    // need to handle preview changes for both start and end calendar
    this.handlePreviewChange();
  }

  /**
   * Returns the ComponentType for the calendar header
   */
  getHeaderComponentType(): ComponentType<XpoDateRangeCalendarHeader<D>> {
    // the custom component header type
    return XpoDateRangeCalendarHeader;
  }

  /**
   * Handles when selection changes inside the calendar
   * @param event
   */
  handleUserSelection(event: MatCalendarUserEvent<D | null>): void {
    // updates the current selection with the new date
    this.selectionModel.changeSelection(event.value);

    // if the selection range is completed
    if (this.selectionModel.isComplete()) {
      // close the date range picker
      this.dateRangePicker.close();
    }
  }

  /**
   * Returns the current selected range
   */
  getSelected(): DateRange<D> {
    return this.selectionModel.selection;
  }

  /**
   * Sets the reference to the date range picker that contains the calendar and its selection model
   * @param dateRangePicker
   */
  registerDateRangePicker(dateRangePicker: XpoDateRangePicker<D>): void {
    this.dateRangePicker = dateRangePicker;

    // keep the selection model
    this.selectionModel = dateRangePicker.selectionModel;
  }

  /**
   * Initialize the period to be shown in both start and end calendars
   * If there is a selected range we'll see the first calendar based on the start date
   */
  protected showCurrentPeriod(): void {
    if (this.selectionModel.selection.start) {
      // set the active date for the start calendar
      // if not it keeps being the current date (as default)
      this.startCalendar.activeDate = this.selectionModel.selection.start;
    }

    // move end calendar one month after the start calendar
    this.endCalendar.activeDate = this.getNextMonth(this.startCalendar.activeDate);

    // force to detect changes inside the mat-calendar
    this.changeDetector.detectChanges();
  }

  /**
   * Sets the period to be shown when user clicks on next
   */
  protected setNextPeriod(): void {
    // move the start calendar
    this.startCalendar.activeDate = this.getNextMonth(this.startCalendar.activeDate);

    // move the end calendar
    this.endCalendar.activeDate = this.getNextMonth(this.endCalendar.activeDate);
  }

  /**
   * Sets the period to be shown when user clicks on previous
   */
  protected setPreviousPeriod(): void {
    // move the start calendar
    this.startCalendar.activeDate = this.getPreviousMonth(this.startCalendar.activeDate);

    // move the end calendar
    this.endCalendar.activeDate = this.getPreviousMonth(this.endCalendar.activeDate);
  }

  /**
   * Handle when the selected range preview changes inside the calendar
   */
  protected handlePreviewChange(): void {
    // if preview change is made in the end calendar,
    // need to reflect this in the start calendar too
    this.endCalendar.monthView._matCalendarBody.previewChange.subscribe(
      (event: MatCalendarUserEvent<MatCalendarCell<D>>) => {
        this.startCalendar.monthView._previewChanged(event);
      }
    );
  }

  /**
   * Based on the given date, returns the next month
   * @param date
   */
  protected getNextMonth(date: D): D {
    // to the given date, add one month
    return this.dateAdapter.addCalendarMonths(date, 1);
  }

  /**
   * Based on the given date, returns the previous month
   * @param date
   */
  protected getPreviousMonth(date: D): D {
    // to the given date, subtract one month
    return this.dateAdapter.addCalendarMonths(date, -1);
  }
}
