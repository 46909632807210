import { Component } from '@angular/core';
import { XpoFilterCondition } from '../../../condition';
import { XpoConditionIsNotEmptyComponent } from '../is-not-empty/is-not-empty.component';
import { filterFormats, Operators, OperatorText, XpoFilterConditionCriteria } from './../../../../../models/index';

@Component({ template: ' ' })
export class XpoConditionRangeNotEmptyComponent extends XpoConditionIsNotEmptyComponent implements XpoFilterCondition {
  static text = OperatorText.NotEmpty;
  static applyAlwaysEnabled: boolean = true;
  static operator = Operators.NotEmpty;

  isNegative = false;
  model: null = null;

  constructor() {
    super();
  }

  validate(cellValue): boolean {
    if (!cellValue) {
      return false;
    }
    if (cellValue.from || cellValue.to) {
      return (cellValue.from && cellValue.from.length) || (cellValue.to && cellValue.to.length);
    }
  }
}
