import {EventSourceCd} from '../';
import {EnumHelper} from './enum-helper';

export class EventSourceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EventSourceCd ) {
      this._values.push(EventSourceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EventSourceCd {

    for ( const obj in EventSourceCd ) {
      if (EventSourceCd[obj] === value){
        return EventSourceCd[obj] as EventSourceCd;
      }
    }
  }
}

const EventSourceCdHelper = new EventSourceCdHelperEnumHelperClass();
export default EventSourceCdHelper;
