import { CriteriaFunction } from './criteria-function.model';

export class DefaultCriteriaFunction implements CriteriaFunction {
  supportsValue(value: any): boolean {
    return true;
  }

  getFilter<T>(key: string, value: any): (t: T) => boolean {
    return (t: T) => true;
  }
}
