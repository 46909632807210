import {DisputeTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeTypeCd ) {
      this._values.push(DisputeTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeTypeCd {

    for ( const obj in DisputeTypeCd ) {
      if (DisputeTypeCd[obj] === value){
        return DisputeTypeCd[obj] as DisputeTypeCd;
      }
    }
  }
}

const DisputeTypeCdHelper = new DisputeTypeCdHelperEnumHelperClass();
export default DisputeTypeCdHelper;
