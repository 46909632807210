import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { XpoCardModule, XpoDialogModule, XpoIconModule, XpoPopoverModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoLtlDialogTitleModule } from '../dialog-title/dialog-title.module';
import { XpoLtlPipeModule } from '../pipes/pipe.module';
import { DataDisplayGroupComponent } from './data-display-group/data-display-group.component';
import { ShipmentDetailsChargesComponent } from './shipment-details-charges/shipment-details-charges.component';
import { ShipmentDetailsDetailsComponent } from './shipment-details-details/shipment-details-details.component';
import { ShipmentDimensionsComponent } from './shipment-details-details/shipment-details-dimensions/shipment-details-dimensions.component';
import { ShipmentDetailsGeneralInfoComponent } from './shipment-details-details/shipment-details-general-info/shipment-details-general-info.component';
import { ShipmentDetailsLineItemsComponent } from './shipment-details-details/shipment-details-line-items/shipment-details-line-items.component';
import { ShipmentDetailsOverviewComponent } from './shipment-details-details/shipment-details-overview/shipment-details-overview.component';
import { ShipmentDetailsPaymentSummaryComponent } from './shipment-details-details/shipment-details-payment-summary/shipment-details-payment-summary.component';
import { ShipmentDetailsRemarksComponent } from './shipment-details-details/shipment-details-remarks/shipment-details-remarks.component';
import { ShipmentDetailsSrnsComponent } from './shipment-details-details/shipment-details-srns/shipment-details-srns.component';
import { XpoLtlShipmentDetailsDialogComponent } from './shipment-details-dialog/shipment-details-dialog.component';
import { ShipmentDetailsEventsComponent } from './shipment-details-events/shipment-details-events.component';
import { XpoLtlShipmentDetailsComponent } from './shipment-details/shipment-details.component';
import { XpoLtlShipmentSelectorComponent } from './shipment-selector/shipment-selector.component';

const components = [
  DataDisplayGroupComponent,
  ShipmentDetailsChargesComponent,
  ShipmentDetailsDetailsComponent,
  ShipmentDetailsEventsComponent,
  ShipmentDetailsGeneralInfoComponent,
  ShipmentDetailsLineItemsComponent,
  ShipmentDetailsOverviewComponent,
  ShipmentDetailsPaymentSummaryComponent,
  ShipmentDetailsRemarksComponent,
  ShipmentDetailsSrnsComponent,
  ShipmentDimensionsComponent,
];

const materialModules = [
  MatAutocompleteModule,
  MatButtonModule,
  MatDialogModule,
  MatExpansionModule,
  MatInputModule,
  MatListModule,
  MatSelectModule,
  MatTableModule,
  MatTabsModule,
  MatCardModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatRadioModule,
];

const exportComponents = [
  XpoLtlShipmentDetailsComponent,
  XpoLtlShipmentDetailsDialogComponent,
  XpoLtlShipmentSelectorComponent,
];

@NgModule({
  declarations: [...components, ...exportComponents],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    DragDropModule,

    ...materialModules,

    ConfigManagerModule,

    XpoCardModule,
    XpoDialogModule,
    XpoIconModule,
    XpoLtlPipeModule,
    XpoPopoverModule,
    XpoLtlDialogTitleModule,
  ],
  exports: [...exportComponents],
  entryComponents: [XpoLtlShipmentDetailsDialogComponent],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    CurrencyPipe,
  ],
})
export class XpoLtlShipmentDetailsModule {}
