import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

import { XpoSnackBarConfig } from './models/xpo-snack-bar-config.interface';
import { XpoNotificationTemplate } from './notification-template';
import { XpoSnackBarModule } from './snack-bar.module';

@Injectable({ providedIn: XpoSnackBarModule })
export class XpoSnackBar {
  constructor(
    private snackBar: MatSnackBar
  ) {}

  success(message: string, action: string = ''): MatSnackBarRef<XpoNotificationTemplate> {
    return this.open({ message, status: 'success', action });
  }

  warn(message: string, action: string = ''): MatSnackBarRef<XpoNotificationTemplate> {
    return this.open({ message, status: 'warn', action });
  }

  error(message: string, action: string = ''): MatSnackBarRef<XpoNotificationTemplate> {
    return this.open({ message, status: 'error', action });
  }

  info(message: string, action: string = ''): MatSnackBarRef<XpoNotificationTemplate> {
    return this.open({ message, status: 'info', action });
  }

  open(params: XpoSnackBarConfig): MatSnackBarRef<XpoNotificationTemplate> {
    const {
      message,
      status,
      linkRoute,
      linkMessage,
      isInternalRoute,
      detailedMessage,
      detailedMessageComponent,
      detailedMessageComponentData,
      matConfig,
      linkAction,
      hideClose } = params;

    const localConfig: MatSnackBarConfig = {
      // keep values from the custom config used when opening snackbar
      ...matConfig,
    };

    localConfig.data = {
      message,
      status,
      linkRoute,
      linkMessage,
      isInternalRoute,
      detailedMessage,
      detailedMessageComponent,
      detailedMessageComponentData,
      linkAction,
      hideClose
    };

    return this.snackBar.openFromComponent(XpoNotificationTemplate, localConfig);
  }
}
