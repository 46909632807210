import {ArchiveControlCd} from '../';
import {EnumHelper} from './enum-helper';

export class ArchiveControlCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ArchiveControlCd ) {
      this._values.push(ArchiveControlCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ArchiveControlCd {

    for ( const obj in ArchiveControlCd ) {
      if (ArchiveControlCd[obj] === value){
        return ArchiveControlCd[obj] as ArchiveControlCd;
      }
    }
  }
}

const ArchiveControlCdHelper = new ArchiveControlCdHelperEnumHelperClass();
export default ArchiveControlCdHelper;
