import {CarrierTenderGroupStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class CarrierTenderGroupStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CarrierTenderGroupStatusCd ) {
      this._values.push(CarrierTenderGroupStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CarrierTenderGroupStatusCd {

    for ( const obj in CarrierTenderGroupStatusCd ) {
      if (CarrierTenderGroupStatusCd[obj] === value){
        return CarrierTenderGroupStatusCd[obj] as CarrierTenderGroupStatusCd;
      }
    }
  }
}

const CarrierTenderGroupStatusCdHelper = new CarrierTenderGroupStatusCdHelperEnumHelperClass();
export default CarrierTenderGroupStatusCdHelper;
