import {SpecialEquipmentCd} from '../';
import {EnumHelper} from './enum-helper';

export class SpecialEquipmentCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in SpecialEquipmentCd ) {
      this._values.push(SpecialEquipmentCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): SpecialEquipmentCd {

    for ( const obj in SpecialEquipmentCd ) {
      if (SpecialEquipmentCd[obj] === value){
        return SpecialEquipmentCd[obj] as SpecialEquipmentCd;
      }
    }
  }
}

const SpecialEquipmentCdHelper = new SpecialEquipmentCdHelperEnumHelperClass();
export default SpecialEquipmentCdHelper;
