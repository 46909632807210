
export enum ShipmentSpecialServiceCd {
	AFTER_HOURS_PICKUP = 'AfterHoursPickup',
	APPOINTMENT = 'Appointment',
	BULK_LIQUID = 'BulkLiquid',
	BYPASS = 'Bypass',
	CASH_ON_DELIVERY = 'CashOnDelivery',
	CONSTRUCTION_AND_UTILITY_SITES_DELIVERY = 'ConstructionAndUtilitySitesDelivery',
	CUSTOMS_IN_BOND = 'CustomsInBond',
	DRIVER_COLLECT = 'DriverCollect',
	EXCESSIVE_LENGTH = 'ExcessiveLength',
	EXPEDITE = 'Expedite',
	FOOD = 'Food',
	FORK_LIFT = 'ForkLift',
	FREEZABLE = 'Freezable',
	GUARANTEED = 'Guaranteed',
	GUARANTEED_BY_NOON = 'GuaranteedByNoon',
	HAZMAT = 'Hazmat',
	HEADLOAD = 'Headload',
	HIGHWAY_SUB_SERVICE = 'HighwaySubService',
	INSIDE_PICKUP = 'InsidePickup',
	LIFT_GATE = 'LiftGate',
	LINEHAUL = 'Linehaul',
	MISLOAD = 'Misload',
	NOTIFY_ON_ARRIVAL = 'NotifyOnArrival',
	PALLET_JACK = 'PalletJack',
	PARTIAL_BYPASS = 'PartialBypass',
	PREMIUM_SUPPORT_SERVICE = 'PremiumSupportService',
	PUP_ONLY = 'PupOnly',
	RAPID_REMOTE_SERVICE = 'RapidRemoteService',
	RESIDENTIAL_DELIVERY = 'ResidentialDelivery',
	STRAND = 'Strand',
	SUPPLEMENTAL = 'Supplemental',
	TIME_DATE_CRITICAL = 'TimeDateCritical',
	TRAP_SERVICE = 'TrapService',
	UNMAPPED = 'Unmapped',
	VOLUME_PICKUP = 'VolumePickup'}

