import { Pipe, PipeTransform } from '@angular/core';
import { XpoLtlTimeService } from '../../services/time/time.service';

/**
 * Display Date based on the requested SIC
 */
@Pipe({
  name: 'sicDate',
})
export class SicDatePipe implements PipeTransform {
  constructor(private timeService: XpoLtlTimeService) {}

  transform(value: any, format?: string, sicCd?: string): any {
    return this.timeService.formatDate(value, format, sicCd);
  }
}
