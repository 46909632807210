import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Observable, ReplaySubject } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class XrtFireMessagingTokenService {
  private tokenValue: string = null;
  private tokenSource$ = new ReplaySubject<string>(1);
  private tokenFetched = false;

  constructor(private angularFireMessaging: AngularFireMessaging) {}

  getToken$(): Observable<string> {
    // If there is no token value and token value is not already being fetched,
    // fetch token and store it.
    if (!this.tokenValue && !this.tokenFetched) {
      this.tokenFetched = true;

      this.angularFireMessaging.requestToken.pipe(take(1)).subscribe((token) => this.setToken(token));
    }

    return this.tokenSource$.asObservable();
  }

  deleteToken(): void {
    this.tokenSource$.complete();

    this.tokenSource$ = new ReplaySubject<string>(1);
    this.tokenValue = null;
    this.tokenFetched = false;
  }

  private setToken(v: string): void {
    this.tokenValue = v;
    this.tokenSource$.next(v);
  }
}
