import { ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validates if there was some error parsing dates typed by the user or assigned by value setter
 * */
export function xpoDateRangeParseValidator(hasParseError: boolean): ValidatorFn {
  return (): ValidationErrors | null =>
    // evaluates if there was some error
    hasParseError ? { xpoDateRangeParseError: true } : null;
}
