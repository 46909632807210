import { AfterContentInit, ContentChild, Directive, ElementRef, Renderer2 } from '@angular/core';
import { MatDatepicker, MatDatepickerInput } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { Moment } from 'moment';
import { XpoDatePickerCustomHeaderComponent } from '../components/custom-header/datepicker-custom-header.component';

const XPO_DATEPICKER_CALENDAR_CLASS: string = 'xpo-DatePicker-calendar';
const XPO_DATEPICKER_ACTIVE_CLASS: string = 'xpo-DatePicker--active';

@Directive()
export abstract class XpoBaseDatePickerDirective implements AfterContentInit {
  // date picker popup component
  @ContentChild(MatDatepicker, { static: true }) datePicker: MatDatepicker<Moment>;

  // date picker input HTML element to set attributes
  @ContentChild(MatInput, { static: true, read: ElementRef }) datePickerInputElement: ElementRef<HTMLInputElement>;

  // date picker input for matInput directive only to manage placeholders and click event
  @ContentChild(MatInput, { static: true }) datePickerMatInput: MatInput;

  // date picker input directive for date handling
  @ContentChild(MatDatepickerInput, { static: true }) datePickerInput: MatDatepickerInput<Moment>;

  constructor(protected element: ElementRef, protected renderer: Renderer2) {}

  ngAfterContentInit(): void {
    this.datePicker.panelClass = XPO_DATEPICKER_CALENDAR_CLASS;
    this.datePicker.calendarHeaderComponent = XpoDatePickerCustomHeaderComponent;

    this.datePicker.openedStream.subscribe(() => this.onCalendarOpened());
    this.datePicker.closedStream.subscribe(() => this.onCalendarClosed());

    this.renderer.setAttribute(this.datePickerInputElement.nativeElement, 'autocomplete', 'off');

    this.datePickerMatInput.onContainerClick = () => {
      this.onClicked();
    };
  }

  protected onCalendarOpened(): void {
    this.renderer.addClass(this.element.nativeElement, XPO_DATEPICKER_ACTIVE_CLASS);
  }

  protected onCalendarClosed(): void {
    this.renderer.removeClass(this.element.nativeElement, XPO_DATEPICKER_ACTIVE_CLASS);
  }

  protected onClicked(): void {
    if (this.datePicker.opened) {
      return;
    }

    this.datePicker.open();
  }
}
