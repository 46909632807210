import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { OptionsResolver, XpoInlineFilter } from '../models/index';
import { XpoInlineMultiSelectFilterComponent } from './inline-multi-select-filter.component';

export class XpoInlineMultiSelectFilter extends XpoInlineFilter {
  /**
   * Controls what field on the options array is used for the display. Defaults to title
   */
  labelField = 'title';

  /**
   * Controls the max number of number of characters to have in the displayValue. Defaults to 30
   */
  maxDisplayValueCharLength = 30;

  /**
   * Readonly array of objects containing the field values
   */
  options: any[];

  /**
   * Controls what field on the options array is used for filter value. Defaults to code
   */
  valueField = 'code';

  constructor(field: string, label: string, options: any[] | OptionsResolver | Observable<any[]> | Promise<any[]>) {
    super(field, label, XpoInlineMultiSelectFilterComponent);

    if (options instanceof Array) {
      this.options = options || [];
    } else if (options instanceof Observable) {
      this.options = [];
      options.pipe(take(1)).subscribe((opt) => (this.options = opt || []));
    } else if (options instanceof Promise) {
      this.options = [];
      options.then((opt) => (this.options = opt || []));
    } else {
      this.options = options();
    }
  }

  getDisplayValue(val: any): any {
    return val;
  }
}
