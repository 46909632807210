import {ApiTypeCategoryCd} from '../';
import {EnumHelper} from './enum-helper';

export class ApiTypeCategoryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ApiTypeCategoryCd ) {
      this._values.push(ApiTypeCategoryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ApiTypeCategoryCd {

    for ( const obj in ApiTypeCategoryCd ) {
      if (ApiTypeCategoryCd[obj] === value){
        return ApiTypeCategoryCd[obj] as ApiTypeCategoryCd;
      }
    }
  }
}

const ApiTypeCategoryCdHelper = new ApiTypeCategoryCdHelperEnumHelperClass();
export default ApiTypeCategoryCdHelper;
