import { DateAdapter } from '@angular/material/core';
import { DateRange } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { XpoDateRangeSelectionChangeEvent } from './date-range-selection-change.event';

export class XpoDateRangeSelectionModel<D> {
  /** Notifier for selection changes */
  selectionChanged: Subject<XpoDateRangeSelectionChangeEvent<D>> = new Subject<XpoDateRangeSelectionChangeEvent<D>>();

  /** Current selected range */
  selection: DateRange<D> = new DateRange(null, null);

  constructor(private dateAdapter: DateAdapter<D>) {}

  /**
   * Changes the current selection based on a new date.
   * The given date fills in the next `null` value in the range. If both the start and the end already have a date,
   * the selection is reset so that the given date is the new `start` and the `end` is null.
   * @param date
   * @param source
   */
  changeSelection(date: D, source?: 'input' | 'calendar'): void {
    // keep the curren selection start and end
    let { start, end } = this.selection;

    if (start && !end && date && this.dateAdapter.compareDate(date, start) >= 0) {
      // when only the start is selected
      // and the selected date is later than the start
      // we finished the range
      end = date;
    } else {
      // if there is no current selection started or selection is already completed
      // or the new date is earlier than the current start
      // start a new range with the selected date
      start = date;
      end = null;
    }

    // update the current selection with new computed values
    this.updateSelection(new DateRange<D>(start, end), source);
  }

  /**
   * Update the selected range
   * @param selection
   */
  updateSelection(selection: DateRange<D>, source?: 'input' | 'calendar'): void {
    // replace the selection
    this.selection = selection;

    // notify the change
    this.selectionChanged.next({ value: this.selection, source });
  }

  /**
   * Checks whether the current selection is complete.
   * This is true if the current selection has a non-null `start` and `end`.
   */
  isComplete(): boolean {
    return this.selection.start != null && this.selection.end != null;
  }
}
