import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { XpoFilterChip } from '../filter-chip/filter-chip.component';
import { XpoFiltersService } from '../filters.service';
import { XpoFilter, XpoFilterComponent, XpoFilterCriteria, XpoInlineFilter } from '../models/index';

@Directive({
  selector: '[xpo-filter-form-host]',
})
export class XpoFilterFormHost {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

@Component({
  selector: 'xpo-filter-form',
  templateUrl: 'filter-form.component.html',
  styleUrls: ['filter-form.component.scss'],
  providers: [XpoFiltersService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-FilterForm' },
})
export class XpoFilterForm implements OnDestroy, OnInit {
  private componentDestroyed: Subject<any> = new Subject<any>();
  private criteriaValue: XpoFilterCriteria;

  /** Input filter object that will be dynamically generated */
  private filtersValue: XpoFilter[];

  /** Child filter components container */
  @ViewChild(XpoFilterFormHost, { static: true })
  host: XpoFilterFormHost;

  @Input()
  get criteria(): XpoFilterCriteria {
    return this.criteriaValue;
  }
  set criteria(value: XpoFilterCriteria) {
    if (this.criteriaValue !== value) {
      this.filtersService.setCriteria(value);
    }
  }

  @Output()
  criteriaChange = new EventEmitter<XpoFilterCriteria>();

  /** Filters that will be generated */
  @Input()
  get filters(): XpoFilter[] {
    return this.filtersValue;
  }
  set filters(v: XpoFilter[]) {
    this.filtersValue = v;
    this.createFilterChips();
  }

  /**
   * If set to false will disable the default display layout
   * with 3 columns of filter chips and align start everything
   */
  @HostBinding('class.xpo-FilterForm--inline')
  @Input()
  get inline(): boolean {
    return this.inlineValue;
  }
  set inline(v: boolean) {
    this.inlineValue = coerceBooleanProperty(v);
  }
  private inlineValue: boolean = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private filtersService: XpoFiltersService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  ngOnInit(): void {
    this.filtersService.criteria$.pipe(takeUntil(this.componentDestroyed)).subscribe((c) => {
      this.criteriaValue = c;
      this.criteriaChange.emit(this.criteriaValue);
    });
  }

  /** Generates the child filter components. */
  private createFilterChips(): void {
    // Remove anything thats in the filter container
    this.host.viewContainerRef.clear();

    if (!this.filters) {
      return;
    }

    this.filters.filter(Boolean).forEach((filter) => {
      // check whether or no the filter renders inline
      if (filter instanceof XpoInlineFilter) {
        this.createInlineFilter(filter);
      } else {
        this.createFilter(filter);
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  /**
   * Create Inline filter
   */
  private createInlineFilter(filter: XpoInlineFilter): void {
    // create the filter component
    const componentFactory: ComponentFactory<
      XpoFilterComponent
    > = this.componentFactoryResolver.resolveComponentFactory(filter.filterComponentType);
    // Attach it to the container
    const componentRef = this.host.viewContainerRef.createComponent(componentFactory);
    // Get the component instance
    const component = componentRef.instance;
    // Assign data to the component
    component.configuration = filter;
    component.field = filter.field;
    component.filtersService = this.filtersService;
  }

  /**
   * Creates filter with filter chip
   */
  private createFilter(filter: XpoFilter): void {
    // Create Filter Chip
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(XpoFilterChip);
    // Attach it to the container
    const componentRef = this.host.viewContainerRef.createComponent(componentFactory);
    // Get the component instance of the newc
    const component = componentRef.instance;
    // Assign filter to filter chip
    component.filter = filter;
    component.criteriaStore = this.filtersService;
  }
}
