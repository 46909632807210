import { Component, OnInit } from '@angular/core';
import { XpoAuthEventHandler } from '../event-handler/event-handler.model';

@Component({
  selector: 'xpo-post-sign-out',
  template: `
    <h3>You have been signed out. <a [routerLink]="'/'">Return To Application</a></h3>
  `,
})
export class XpoPostSignOutComponent implements OnInit {
  constructor(private authEventListener: XpoAuthEventHandler) {}

  ngOnInit(): void {
    this.authEventListener.postSignOut();
  }
}
