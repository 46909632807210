import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import {
  castArray as _castArray,
  forEach as _forEach,
  forOwn as _forOwn,
  get as _get,
  hasIn as _hasIn,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
  map as _map,
  reduce as _reduce,
  set as _set,
  unset as _unset,
  forIn as _forIn,
  size as _size,
} from 'lodash';
import { OperatorFunction, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Default types that can be used as FormControl values
 */
export type DefaultFormControlValueTypes = undefined | string | number | boolean | object | Date | Array<FormControl>;

/**
 * Possible values a FormControl can have
 */
export interface RawFormControlValues {
  [key: string]: RawFormControlValues | DefaultFormControlValueTypes;
}

/**
 * Associates a set of errors with a FormControl
 */
export interface FormControlValidationErrors {
  errors: ValidationErrors;
  controlName: string;
  control: AbstractControl;
  children: FormControlValidationErrors[];
}

/**
 * Enum mapping to list of form statuses on statusChange emit
 */
export enum FormStatus {
  Valid = 'VALID',
  Invalid = 'INVALID',
  Pending = 'PENDING',
  Disabled = 'DISABLED',
}

/**
 * State of a FormControl as returned by getControlState
 */
export interface FormUtilsControlState {
  name: string;
  status: FormStatus;
  valid: boolean;
  pending: boolean;
  enabled: boolean;
  dirty: boolean;
  touched: boolean;
  errors: ValidationErrors;
  warnings: any;
  value?: any;
  children?: FormUtilsControlState[];
}

/**
 * Utilities for interacting with Angular Reactive Forms
 */
export class FormUtils {
  /**
   * Returns object representing the control's state
   * Note: This is mostly useful for debugging
   */
  static getControlState(
    control: AbstractControl,
    controlName: string = null,
    recursive = false
  ): FormUtilsControlState {
    const result: FormUtilsControlState = {
      name: controlName,
      status: control.status as FormStatus,
      value: undefined,
      valid: control.valid,
      pending: control.pending,
      enabled: control.enabled,
      errors: control.errors,
      warnings: control['warnings'],
      dirty: control.dirty,
      touched: control.touched,
    };
    if (control instanceof FormControl) {
      _set(result, 'value', control.value);
    }

    if (recursive) {
      _set(
        result,
        'children',
        _map(_get(control, 'controls', []), (c: AbstractControl, name: string) =>
          this.getControlState(c, name, recursive)
        )
      );
    }
    return result;
  }

  /**
   * Attempt to get the name of the control as it is registered in its parent.
   * For a FormArray, this will be the index in the array. If the control does not
   * have a parent, then the name is 'root'.
   */
  static getControlName(control: AbstractControl | FormGroup | FormArray): string {
    const parent = control.parent;
    if (parent instanceof FormArray) {
      const index = parent.controls.findIndex((c) => c === control);
      return `${index}`;
    } else if (parent instanceof FormGroup) {
      for (const [key, value] of Object.entries(parent.controls)) {
        if (value === control) {
          return key;
        }
      }
    }
  }

  /**
   * Sets the specified control in the group to enabled/disabled without emitting events
   */
  static setEnabled(group: FormGroup, field: string, isEnabled: boolean = true) {
    if (group) {
      const control = group.get(field);
      if (control) {
        if (isEnabled) {
          FormUtils.enable(control);
        } else {
          FormUtils.disable(control);
        }
      }
    }
  }

  static setControlEnabled(control: AbstractControl, isEnabled: boolean = true) {
    if (isEnabled) {
      FormUtils.enable(control);
    } else {
      FormUtils.disable(control);
    }
  }

  static enable(control: AbstractControl) {
    if (control && control.disabled) {
      control.enable({ onlySelf: true, emitEvent: false });
    }
  }

  static disable(control: AbstractControl) {
    if (control && control.enabled) {
      control.disable({ onlySelf: true, emitEvent: false });
    }
  }

  /**
   * rxjs pipe which will return a boolean result comparing the passed in status value
   * to the current form status value
   */
  static isFormStatus$(status: FormStatus): OperatorFunction<FormStatus, boolean> {
    const result$: OperatorFunction<FormStatus, boolean> = pipe(
      map(function(formStatus: FormStatus) {
        return formStatus === status;
      })
    );

    return result$;
  }

  /**
   * Set the values for the named controls if it is different, without emitting statusChanges
   * and valueChanges events
   */
  static setValues(form: AbstractControl, values: RawFormControlValues, options: Object = {}) {
    if (form) {
      _forOwn(values, (value, controlKey) => {
        const control = form.get(controlKey);
        FormUtils.setValue(control, value, options);
      });
    }
  }

  /**
   * Set the value for the control if it is different, without emitting statusChanges
   * and valueChanges events
   */
  static setValue(control: AbstractControl, value: any, options: Object = {}) {
    if (control) {
      if (!_isEqual(control.value, value)) {
        control.patchValue(value, { emitEvent: false, ...options }); // don't emit change events
        control.markAsDirty();
      }
      control.markAsTouched();
    }
  }

  static getNestedControl(rootControl: AbstractControl, ...nestedControlNames: string[]) {
    if (rootControl) {
      return _reduce(
        nestedControlNames,
        (target, name) => {
          return target ? target.get(name) : undefined;
        },
        rootControl
      );
    } else {
      return undefined;
    }
  }

  /**
   * Set the value of the nested control specified.
   */
  static setNestedValue(value: any, rootControl: AbstractControl, ...nestedControlNames: string[]) {
    const control = FormUtils.getNestedControl(rootControl, ...nestedControlNames);
    FormUtils.setValue(control, value);
  }

  /**
   * Get the value of the nested control specified.
   */
  static getNestedValue<T>(rootControl: AbstractControl, ...nestedControlNames: string[]): T {
    const control = FormUtils.getNestedControl(rootControl, ...nestedControlNames);
    return control ? (control.value as T) : undefined;
  }

  /**
   * Mark the passed control as touched. If recursive, mark all of its children as touched
   */
  static markAsTouched(control: AbstractControl, recursive: boolean = true) {
    if (control) {
      control.markAsTouched();
      if (recursive) {
        _forEach(_get(control, 'controls', []), (c) => FormUtils.markAsTouched(c, recursive));
      }
    }
  }

  /**
   * Mark the passed control as dirty. If recursive, mark all of its children as touched
   */
  static markAsDirty(control: AbstractControl, recursive: boolean = true) {
    if (control) {
      control.markAsDirty();
      if (recursive) {
        _forEach(_get(control, 'controls', []), (c) => FormUtils.markAsDirty(c, recursive));
      }
    }
  }

  /**
   * Mark the passed control as dirty. If recursive, mark all of its children as touched
   */
  static markAsTouchedAndDirty(control: AbstractControl, recursive: boolean = true) {
    if (control) {
      control.markAsDirty();
      control.markAsTouched();
      if (recursive) {
        _forEach(_get(control, 'controls', []), (c) => FormUtils.markAsTouchedAndDirty(c, recursive));
      }
    }
  }

  /**
   * Touch all controls and update value and validity
   * */
  static touchAllControls(control: AbstractControl, onlyPristine: boolean = false) {
    if (control) {
      if (onlyPristine) {
        if (!control.touched) {
          control.markAsTouched();
          control.updateValueAndValidity();
        }
      } else {
        control.markAsTouched();
        control.updateValueAndValidity();
      }
      _forEach(_get(control, 'controls', []), (c) => FormUtils.touchAllControls(c, onlyPristine));
    }
  }

  /**
   * Untouch all conttrols, clear warnings and errors, and set all as Pristine
   */
  static untouchAllControls(control: AbstractControl) {
    if (control) {
      control.markAsUntouched();
      control.markAsPristine();
      _unset(control, 'warnings');
      _forEach(_get(control, 'controls', []), (c) => FormUtils.untouchAllControls(c));
    }
  }

  /** touch all controls and update value and validity*/
  static updateDescendantControlsValueAndValidity(control: AbstractControl, options: Object = {}) {
    if (control) {
      control.updateValueAndValidity({ onlySelf: true, ...options });
      _forEach(_get(control, 'controls', []), (c) => FormUtils.updateDescendantControlsValueAndValidity(c, options));
    }
  }

  /**
   * Return true if the passed control or named control in the passed group has warnings
   * @param group control or group to test
   * @param field name of control in group to test. If not provided, returns if group has warnings
   */
  static hasWarnings(group: AbstractControl, field?: string): boolean {
    if (group && group.enabled) {
      const control = field ? group.get(field) : group;
      return !!control && control.enabled && !_isEmpty(_get(control, 'warnings'));
    }
    return false;
  }

  static hasWarning(warning: string, control: AbstractControl, field?: string): boolean {
    if (control && control.enabled) {
      control = field ? control.get(field) : control;
      return control && control.enabled && !!_get(control, `warnings[${warning}]`);
    }
    return false;
  }

  static getWarning(group: FormGroup, field: string, warning: string): any {
    if (group) {
      const control = field ? group.get(field) : group;
      return _get(control, `warnings[${warning}]`);
    }
  }

  static setWarning(control: AbstractControl, warning: string): void {
    _set(control, `warnings`, { ...control['warnings'], [warning]: true });
  }

  static removeWarning(control: AbstractControl, warning: string): void {
    _unset(control, `warnings['${warning}']`);
  }

  static clearWarnings(control: AbstractControl, warnings: string[] = null) {
    if (control && _hasIn(control, 'warnings')) {
      if (warnings) {
        _forEach(_castArray(warnings), (warning) => {
          delete control['warnings'][warning];
        });
      } else {
        delete control['warnings'];
      }
    }
  }

  static resetControl(control: AbstractControl, defaultValue?: any): void {
    control.reset(defaultValue);
    this.clearWarnings(control);
  }

  static hasErrors(group: FormGroup, field: string): boolean {
    if (group && group.enabled) {
      const control = group.get(field);
      return control && control.enabled && control.invalid;
    }
    return false;
  }

  static hasError(error: string, group: FormGroup | FormControl | FormArray, field?: string): boolean {
    if (group && group.enabled) {
      const control = field ? group.get(field) : group;
      return control && control.enabled && control.hasError(error);
    }
    return false;
  }

  static getError(error: string, group: FormGroup | FormControl, field?: string): any {
    if (group) {
      const control = field ? group.get(field) : group;
      return _get(control, `errors[${error}]`);
    } else {
      return undefined;
    }
  }

  /**
   * Returns list of all controls that have errors starting with the passed control and
   * traversing all its children.
   */
  static getControlsWithErrors(control: AbstractControl | FormGroup | FormArray): AbstractControl[] {
    let errors: AbstractControl[] = [];

    if (control.errors) {
      errors.push(control);
    }

    // add child controls with errors
    if ('controls' in control) {
      const children = Object.values(control.controls);
      children.forEach((child) => {
        const childErrors = FormUtils.getControlsWithErrors(child);
        errors = errors.concat(childErrors);
      });
    }

    return errors.filter((e) => !!e);
  }

  /**
   * Returns reactive form error state matcher
   * traversing all its children.
   */
  static getErrorStateMatcher(
    validatorFn: (formControl: AbstractControl, group: FormGroupDirective | NgForm) => boolean
  ): ErrorStateMatcher {
    const errorStateMatcher = new ErrorStateMatcher();
    errorStateMatcher.isErrorState = validatorFn;
    return errorStateMatcher;
  }
}
