import {DisputeFilingMethodCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeFilingMethodCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeFilingMethodCd ) {
      this._values.push(DisputeFilingMethodCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeFilingMethodCd {

    for ( const obj in DisputeFilingMethodCd ) {
      if (DisputeFilingMethodCd[obj] === value){
        return DisputeFilingMethodCd[obj] as DisputeFilingMethodCd;
      }
    }
  }
}

const DisputeFilingMethodCdHelper = new DisputeFilingMethodCdHelperEnumHelperClass();
export default DisputeFilingMethodCdHelper;
