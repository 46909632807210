import {RatingShipmentFileTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class RatingShipmentFileTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RatingShipmentFileTypeCd ) {
      this._values.push(RatingShipmentFileTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RatingShipmentFileTypeCd {

    for ( const obj in RatingShipmentFileTypeCd ) {
      if (RatingShipmentFileTypeCd[obj] === value){
        return RatingShipmentFileTypeCd[obj] as RatingShipmentFileTypeCd;
      }
    }
  }
}

const RatingShipmentFileTypeCdHelper = new RatingShipmentFileTypeCdHelperEnumHelperClass();
export default RatingShipmentFileTypeCdHelper;
