export interface XpoErrorPageData {
  status: number;
  summary: string;
  detail: string;
  link?: { route: string; label: string };
}

export const xpoErrorPageStatusCodeMap = new Map<number, XpoErrorPageData>([
  [
    403,
    {
      status: 403,
      summary: 'Forbidden',
      detail: 'You do not have permission to access this application',
    },
  ],
  [
    404,
    {
      status: 404,
      summary: 'Page Not Found',
      detail: 'We\'re sorry, but we could not find the page you are looking for.',
      link: { route: '/', label: 'Go To Homepage' },
    },
  ],
]);
