import { GridOptions } from 'ag-grid-community';

import { ModuleExecutorDateTimeRenderComponent } from '../../shared/cell-renderers/date-time-cell-renderer/date-time-cell-renderer.component';
import {
  ModuleGroupsActionsCellRendererComponent,
  ModuleGroupsNameCellRendererComponent,
  ModuleGroupsStatusCellRendererComponent,
} from '../cell-renderers';

export const MODULE_GROUP_GRID_OPTIONS: GridOptions = {
  defaultColDef: {
    suppressMenu: false,
    sortable: true,
    resizable: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
  },
  // floatingFilter: false,
  frameworkComponents: {
    ActionsCellRenderer: ModuleGroupsActionsCellRendererComponent,
    NameCellRenderer: ModuleGroupsNameCellRendererComponent,
    StatusCellRenderer: ModuleGroupsStatusCellRendererComponent,
    DateTimeCellRenderer: ModuleExecutorDateTimeRenderComponent,
  },
  suppressMovableColumns: false,
};
