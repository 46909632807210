import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core';
import { SdkModuleExecutorModule } from '@xpo-ltl/sdk-moduleexecutor';
import { ModuleExecutorBoardComponent } from './module-executor-board.component';
import { ModuleExecutorService } from './services/module-executor.service';
import { ModuleLibraryInMemoryService } from './services/module-library-inMemory.service';
import { ModuleExecutorDateTimeRenderComponent } from './shared/cell-renderers/date-time-cell-renderer/date-time-cell-renderer.component';
import { ModuleExecutorTooltipCellRenderComponent } from './shared/cell-renderers/tooltip-cell-renderer/tooltip-cell-renderer.component';

@NgModule({
  declarations: [
    ModuleExecutorBoardComponent,
    ModuleExecutorTooltipCellRenderComponent,
    ModuleExecutorDateTimeRenderComponent,
  ],
  imports: [CommonModule, MatTooltipModule, XpoCardModule, XpoAgGridBoardModule, SdkModuleExecutorModule],
  exports: [ModuleExecutorBoardComponent],
  entryComponents: [ModuleExecutorTooltipCellRenderComponent, ModuleExecutorDateTimeRenderComponent],
  providers: [ModuleExecutorService, ModuleLibraryInMemoryService],
})
export class ModuleExecutorBoardModule {}
