import { Observable, of as observableOf } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';

import { XpoBoardViewConfig } from './board-view-config.model';
import { XpoBoardViewDataStore } from './board-view-data-store.model';

export abstract class XpoBoardViewDataStoreBase<T extends XpoBoardViewConfig = XpoBoardViewConfig>
  implements XpoBoardViewDataStore {
  protected availableViews: T[];

  abstract getAll(): Observable<T[]>;
  abstract updateDataStore(viewConfigs: XpoBoardViewConfig[]): Observable<T[]>;

  loadAll(): Observable<T[]> {
    return this.getAll().pipe(tap((views) => (this.availableViews = views)));
  }

  delete(viewId: string): Observable<boolean> {
    const viewIdx = this.availableViews.findIndex((v) => v.id === viewId);

    if (viewIdx !== -1) {
      this.availableViews.splice(viewIdx, 1);
    }

    return viewIdx !== -1 ? this.updateDataStore(this.availableViews).pipe(mapTo(true)) : observableOf(false);
  }

  save(view: T): Observable<T> {
    // append or replace current and store them all
    const viewIdx = this.availableViews.findIndex((v) => v.id === view.id);

    if (viewIdx !== -1) {
      this.availableViews.splice(viewIdx, 1, view);
    } else {
      this.availableViews.push(view);
    }

    return this.updateDataStore(this.availableViews).pipe(mapTo(view));
  }

  updateViewVisibility(viewId: string, visibility: boolean): Observable<T[]> {
    const viewIdx = this.availableViews.findIndex((v) => v.id === viewId);

    if (viewIdx !== -1) {
      this.availableViews[viewIdx].visible = visibility;
    }

    return this.updateDataStore(this.availableViews);
  }
}
