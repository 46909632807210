import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { XpoDateRangeCalendar } from './components/calendar/date-range-calendar.component';
import { XpoDateRangeCalendarHeader } from './components/calendar/header/calendar-header.component';
import { XpoDateRangeInput } from './components/inputs/date-range-input.component';
import { XpoDateRangeEndInput } from './components/inputs/directives/date-range-end-input.directive';
import { XpoDateRangeInputSupressPlaceholder } from './components/inputs/directives/date-range-input-supress-placeholder.directive';
import { XpoDateRangeStartInput } from './components/inputs/directives/date-range-start-input.directive';
import { XpoDateRangePickerToggle } from './components/toggle/date-range-picker-toggle.component';
import { XpoDateRangePicker } from './date-range-picker.component';
import { XPO_DATE_RANGE_PICKER_FORMATS } from './models/date-range-picker-formats';

@NgModule({
  imports: [CommonModule, MatDatepickerModule, MatButtonModule],
  declarations: [
    XpoDateRangeCalendarHeader,
    XpoDateRangeCalendar,
    XpoDateRangeInput,
    XpoDateRangeInputSupressPlaceholder,
    XpoDateRangeStartInput,
    XpoDateRangeEndInput,
    XpoDateRangePickerToggle,
    XpoDateRangePicker,
  ],
  exports: [
    XpoDateRangeInput,
    XpoDateRangeInputSupressPlaceholder,
    XpoDateRangeStartInput,
    XpoDateRangeEndInput,
    XpoDateRangePickerToggle,
    XpoDateRangePicker,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: XPO_DATE_RANGE_PICKER_FORMATS },
  ],
})
export class XpoDateRangePickerModule {}
