import { STEPPER_GLOBAL_OPTIONS, StepperOptions } from '@angular/cdk/stepper';
import { Component, forwardRef, Inject, Input, Optional, SkipSelf } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatStep, MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'xpo-step',
  templateUrl: 'step.component.html',
  styleUrls: ['step.component.scss'],
  providers: [{ provide: MatStep, useExisting: XpoStepComponent }],
})
export class XpoStepComponent extends MatStep {
  @Input() title: string;

  constructor(
  @Inject(forwardRef(() => MatStepper)) stepper: MatStepper,
    @SkipSelf() _errorStateMatcher: ErrorStateMatcher,
    @Optional() @Inject(STEPPER_GLOBAL_OPTIONS) stepperOptions?: StepperOptions
  ) {
    super(stepper, _errorStateMatcher, stepperOptions);
  }

  isValid(): boolean {
    return this.optional || !this.stepControl || !this.stepControl.invalid;
  }
}
