import { Injectable } from '@angular/core';
import { XpoFacetFilterCriteria } from './share/model/facet-model-criteria.interface';
import { XpoFacetFilterModelCriteria } from './share/model/filter-criteria.interface';

@Injectable()
export class XpoFacetFilterService {
  private filterCriteriaState: XpoFacetFilterModelCriteria[] = [];

  updateFilterCriteria(objCustomFilter: XpoFacetFilterCriteria): void {
    const filterCriteria = {
      [objCustomFilter.filterId]: {
        filter: {
          typeFilter: objCustomFilter.type,
          conditions: objCustomFilter.conditions,
          combiner: objCustomFilter.combiner,
        },
      },
    };
    const index = this.filterCriteriaState.findIndex((element) => Object.keys(element)[0] === objCustomFilter.filterId);
    if (index >= 0) {
      this.filterCriteriaState.splice(index, 1);
    }
    if (!objCustomFilter.invalid) {
      this.filterCriteriaState.push(filterCriteria);
    }
  }

  getFilterCriteria(): XpoFacetFilterModelCriteria[] {
    return this.filterCriteriaState;
  }
}
