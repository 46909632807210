import { ContentChild, Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: 'xpo-dashbar-metric-popover, [xpoDashbarMetricPopover]',
})
export class XpoDashbarMetricPopover {
  constructor() {}

  @ContentChild(TemplateRef) template: TemplateRef<any>;
}
