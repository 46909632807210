import {DisputeCsvFileUploadStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeCsvFileUploadStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeCsvFileUploadStatusCd ) {
      this._values.push(DisputeCsvFileUploadStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeCsvFileUploadStatusCd {

    for ( const obj in DisputeCsvFileUploadStatusCd ) {
      if (DisputeCsvFileUploadStatusCd[obj] === value){
        return DisputeCsvFileUploadStatusCd[obj] as DisputeCsvFileUploadStatusCd;
      }
    }
  }
}

const DisputeCsvFileUploadStatusCdHelper = new DisputeCsvFileUploadStatusCdHelperEnumHelperClass();
export default DisputeCsvFileUploadStatusCdHelper;
