import { ModuleExecutorDateTimeRenderComponent } from './cell-renderers/date-time-cell-renderer/date-time-cell-renderer.component';
import { ModuleExecutorTooltipCellRenderComponent } from './cell-renderers/tooltip-cell-renderer/tooltip-cell-renderer.component';

export const MODULE_LIBRARY_COLUMNS = [
  {
    headerName: 'Name',
    field: 'name',
    sortable: true,
    cellRendererFramework: ModuleExecutorTooltipCellRenderComponent,
  },
  {
    headerName: 'Version',
    field: 'version',
    sortable: false,
  },
  {
    headerName: 'Created (PST)',
    field: 'created',
    sortable: true,
    cellRendererFramework: ModuleExecutorDateTimeRenderComponent,
  },
  {
    headerName: 'Last Modified (PST)',
    field: 'updated',
    sortable: true,
    cellRendererFramework: ModuleExecutorDateTimeRenderComponent,
  },
];
