import {CustomerLineStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerLineStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerLineStatusCd ) {
      this._values.push(CustomerLineStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerLineStatusCd {

    for ( const obj in CustomerLineStatusCd ) {
      if (CustomerLineStatusCd[obj] === value){
        return CustomerLineStatusCd[obj] as CustomerLineStatusCd;
      }
    }
  }
}

const CustomerLineStatusCdHelper = new CustomerLineStatusCdHelperEnumHelperClass();
export default CustomerLineStatusCdHelper;
