
export enum ExceptionStatusCd {
	ACKNOWLEDGED = 'Acknowledged',
	APPROVED = 'Approved',
	CANCELLED = 'Cancelled',
	CLOSED = 'Closed',
	COMPLETED = 'Completed',
	DECLINED = 'Declined',
	EXPIRED = 'Expired',
	NEW = 'New',
	NEW_SIC = 'NewSIC',
	PENDING = 'Pending',
	RESOLVED = 'Resolved',
	RESPONDED = 'Responded',
	UNDER_REVIEW_LH = 'UnderReviewLH'}

