import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { XpoHeaderNavigationDynSubMenuComponent } from '../header-navigation-sub-menu/header-navigation-sub-menu.component';

@Component({
  selector: 'xpo-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'xpo-header-navigation',
  },
})
export class XpoHeaderNavigationComponent implements OnInit {
  @Input() menuTitle: string = 'Sub Menu';
  // Get the sub menu reference
  @ViewChild(XpoHeaderNavigationDynSubMenuComponent) subMenuWrapper: XpoHeaderNavigationDynSubMenuComponent;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Update sub menu every time the navigation change
        this.subMenuWrapper.updateButton$.next();
      }
    });
  }

  // Called when the navbar is resized. Receives the hidden buttons if correspond
  updateButtonsVisibility(hiddenButtons: ElementRef[]): void {
    // Close the dropdown menu due the navbar is being resized
    this.subMenuWrapper.tryToCloseMenu();
    // Send the hidden buttons to the submenu. If no one is hidden it sends and empty array
    this.subMenuWrapper.updateButton$.next(hiddenButtons);
  }
}
