/**
 * (Ancel):
 *
 * Created this component by taking pieces of Material's Menu component(https://material.angular.io/components/menu) and
 * Tooltip component (https://material.angular.io/components/tooltip/)
 *
 * For the XpoPopoverTrigger i utilized the functionality
 * of MenuTrigger component with the positioning logic of the Tooltip component.
 *
 */
import { FocusMonitor, FocusOrigin, isFakeMousedownFromScreenReader } from '@angular/cdk/a11y';
import { Direction, Directionality } from '@angular/cdk/bidi';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  OriginConnectionPosition,
  Overlay,
  OverlayConfig,
  OverlayConnectionPosition,
  OverlayRef,
  ScrollStrategy,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  Optional,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { delay, filter, take } from 'rxjs/operators';
import { LEFT_ARROW, RIGHT_ARROW } from '../keycodes/keycodes';
import { MouseEventType } from './enums/mouse-events.enum';
import { HorizontalPosition, VerticalPosition } from './enums/popover-position-enum';
import { TriggerType } from './enums/trigger-type.enum';
import { OVERLAY_TRANSPARENT_BACKDROP_CLASS, XpoPopover } from './popover.component';

/** Injection token that determines the scroll handling while the menu is open. */
export const XPO_MENU_SCROLL_STRATEGY = new InjectionToken<() => ScrollStrategy>('xpo-panel-scroll-strategy');

/** @docs-private */
export function XPO_POPOVER_SCROLL_STRATEGY_FACTORY(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition();
}

/** @docs-private */
export const XPO_POPOVER_SCROLL_STRATEGY_FACTORY_PROVIDER = {
  provide: XPO_MENU_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: XPO_POPOVER_SCROLL_STRATEGY_FACTORY,
};

/** Default top padding of the menu panel. */
export const MENU_PANEL_TOP_PADDING = 8;

/**
 * This directive is intended to be used in conjunction with an xpo-popover tag.  It is
 * responsible for toggling the display of the provided menu instance.
 */
@Directive({
  selector: '[xpoPopoverTriggerFor]',
  host: {
    'aria-haspopup': 'true',
    '[attr.aria-expanded]': 'menuOpen || !menuOpen',
    '(mousedown)': 'handleMousedown($event)',
    '(keydown)': 'handleKeydown($event)',
    '(click)': 'handleClick()',
    '(mouseenter)': 'handleMouseEnter()',
    '(mouseleave)': 'handleMouseLeave()',
  },
  exportAs: 'xpoPopoverTrigger',
})
export class XpoPopoverTrigger implements AfterContentInit, OnDestroy {
  private portal: TemplatePortal;
  private overlayRef: OverlayRef | null = null;
  private menuOpenValue: boolean = false;
  private closeSubscription = Subscription.EMPTY;
  private hoverSubscription = Subscription.EMPTY;
  private cachedMenuPosition: TooltipPosition;
  private cachedCaretPosition: HorizontalConnectionPos | VerticalConnectionPos;
  private cachedOverlayXPosition: TooltipPosition;
  private cachedOverlayYPosition: TooltipPosition;
  private positionSubscription: Subscription;

  private mouseEventSource = new Subject<string>();

  // Tracking input type is necessary so it's possible to only auto-focus
  // the first item of the list when the menu is opened via the keyboard
  private openedByMouse: boolean = false;

  /** References the menu instance that the trigger is associated with. */
  @Input('xpoPopoverTriggerFor')
  menu: XpoPopover;

  /** Data to be passed along to any lazily-rendered content. */
  @Input('xpoMenuTriggerData')
  menuData: any;

  @Input('xpoPopoverDisabled')
  get menuDisabled(): boolean {
    return this.menuDisabledValue;
  }
  set menuDisabled(value: boolean) {
    this.menuDisabledValue = coerceBooleanProperty(value);
  }
  private menuDisabledValue: boolean;

  /** Event emitted when the associated menu is opened. */
  @Output()
  readonly menuOpened: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Event emitted when the associated menu is opened.
   * @deprecated Switch to `menuOpened` instead
   * @deletion-target 7.0.0
   */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output()
  readonly onMenuOpen: EventEmitter<void> = this.menuOpened;

  /** Event emitted when the associated menu is closed. */
  @Output()
  readonly menuClosed: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Event emitted when the associated menu is closed.
   * @deprecated Switch to `menuClosed` instead
   * @deletion-target 7.0.0
   */

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output()
  readonly onMenuClose: EventEmitter<void> = this.menuClosed;

  constructor(
    private overlay: Overlay,
    private element: ElementRef,
    private viewContainerRef: ViewContainerRef,
    @Inject(XPO_MENU_SCROLL_STRATEGY) private scrollStrategy,
    @Optional() private dirValue: Directionality,
    private focusMonitor?: FocusMonitor
  ) {}

  ngAfterContentInit(): void {
    this.checkMenu();

    this.menu.closed.subscribe(() => {
      this.destroyMenu();
    });

    if (this.positionIsVertical(this.menu.position)) {
      this.cachedOverlayXPosition = HorizontalPosition.Before;
      this.cachedOverlayYPosition = this.menu.position;
    } else {
      this.cachedOverlayXPosition = this.menu.position;
      this.cachedOverlayYPosition = VerticalPosition.Below;
    }

    this.menu.mouseLeaveEmitter.subscribe(() => this.closeMenu());

    this.initMouseEventWatcher();
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }

    this.cleanUpSubscriptions();
  }

  /** Whether the menu is open. */
  get menuOpen(): boolean {
    return this.menuOpenValue;
  }

  /** The text direction of the containing app. */
  get dir(): Direction {
    return this.dirValue && this.dirValue.value === 'rtl' ? 'rtl' : 'ltr';
  }

  /** Toggles the menu between the open and closed states. */
  toggleMenu(): void {
    return this.menuOpenValue ? this.closeMenu() : this.openMenu();
  }

  /** Opens the menu. */
  openMenu(): void {
    if (this.menuOpenValue || this.menuDisabled) {
      return;
    }

    const overlayRef = this.createOverlay();
    overlayRef.attach(this.portal);

    if (this.menu.lazyContent) {
      this.menu.lazyContent.attach(this.menuData);
    }

    this.closeSubscription = this.menuClosingActions().subscribe(() => {
      this.closeMenu();
    });
    this.initMenu();

    if (this.menu instanceof XpoPopover) {
      this.menu.startAnimation();
    }
  }

  /** Closes the menu. */
  closeMenu(): void {
    this.menu.resetPosition();
    this.menu.closed.emit();
  }

  /**
   * Focuses the menu trigger.
   * @param origin Source of the menu trigger's focus.
   */
  focus(origin: FocusOrigin = 'program'): void {
    if (this.focusMonitor) {
      this.focusMonitor.focusVia(this.element.nativeElement, origin);
    } else {
      this.element.nativeElement.focus();
    }
  }

  /** Closes the menu and does the necessary cleanup. */
  private destroyMenu(): void {
    if (!this.overlayRef || !this.menuOpen) {
      return;
    }

    const menu = this.menu;

    this.closeSubscription.unsubscribe();
    this.overlayRef.detach();

    menu.resetAnimation();

    if (menu.lazyContent) {
      // Wait for the exit animation to finish before detaching the content.
      menu.animationDone
        .pipe(
          filter((event) => event.toState === 'void'),
          take(1)
        )
        .subscribe(() => {
          menu.lazyContent!.detach();
          this.resetMenu();
        });
    } else {
      this.resetMenu();
    }
  }

  /**
   * This method sets the menu state to open and focuses the first item if
   * the menu was opened via the keyboard.
   */
  private initMenu(): void {
    this.menu.direction = this.dir;
    this.setMenuElevation();
    this.setIsMenuOpen(true);
  }

  /** Updates the menu elevation based on the amount of parent menus that it has. */
  private setMenuElevation(): void {
    if (this.menu.setElevation) {
      this.menu.setElevation(0);
    }
  }

  /**
   * This method resets the menu when it's closed, most importantly restoring
   * focus to the menu trigger if the menu was opened via the keyboard.
   */
  private resetMenu(): void {
    this.setIsMenuOpen(false);

    // We should reset focus if the user is navigating using a keyboard or
    // if we have a top-level trigger which might cause focus to be lost
    // when clicking on the backdrop.
    if (!this.openedByMouse) {
      // Note that the focus style will show up both for `program` and
      // `keyboard` so we don't have to specify which one it is.
      this.focus();
    }

    this.openedByMouse = false;
  }

  // set state rather than toggle to support triggers sharing a menu
  private setIsMenuOpen(isOpen: boolean): void {
    this.menuOpenValue = isOpen;
    this.menuOpenValue ? this.menuOpened.emit() : this.menuClosed.emit();
  }

  /**
   * This method checks that a valid instance of MatMenu has been passed into
   * matMenuTriggerFor. If not, an exception is thrown.
   */
  private checkMenu(): void {
    if (!this.menu) {
      // throwMatMenuMissingError();
    }
  }

  /**
   * This method creates the overlay from the provided menu's template and saves its
   * OverlayRef so that it can be attached to the DOM when openMenu is called.
   */
  private createOverlay(): OverlayRef {
    if (!this.overlayRef) {
      this.portal = new TemplatePortal(this.menu.templateRef, this.viewContainerRef);
      const config = this.getOverlayConfig();
      this.overlayRef = this.overlay.create(config);
      this.subscribeToPositions(config.positionStrategy as FlexibleConnectedPositionStrategy);
    } else if (this.overlayRef && (this.menu.position !== this.cachedMenuPosition || this.menu.caretPosition !== this.cachedCaretPosition)) {
      // Updating the overlay of the menu when the menu position value changes.
      this.updatePosition();
      this.cachedMenuPosition = this.menu.position;
      this.cachedCaretPosition = this.menu.caretPosition;
    }

    return this.overlayRef;
  }

  /**
   * Listens to changes in the position of the overlay and sets the correct classes
   * on the popover based on the new position. This ensures the animation origin is always
   * correct, even if a fallback position is used for the overlay.
   */
  private subscribeToPositions(position: FlexibleConnectedPositionStrategy): void {
    this.positionSubscription = position.positionChanges.subscribe((change) => {
      const positionX: TooltipPosition = change.connectionPair.overlayX === 'start' ? HorizontalPosition.After : HorizontalPosition.Before;
      const positionY: TooltipPosition = change.connectionPair.overlayY === 'top' ? VerticalPosition.Below : VerticalPosition.Above;

      if (!this.positionIsVertical(this.menu.position) && this.cachedOverlayXPosition !== positionX) {
        this.menu.position = positionX;
        this.cachedOverlayXPosition = positionX;
      } else if (this.positionIsVertical(this.menu.position) && this.cachedOverlayYPosition !== positionY) {
        this.menu.position = positionY;
        this.cachedOverlayYPosition = positionY;
      }
    });
  }

  /** Updates the position of the current tooltip. */
  private updatePosition(): void {
    const position = this.overlayRef!.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
    const origin = this.getOrigin();
    const overlay = this.getOverlayPosition();

    position.withPositions([
      { ...origin.main, ...overlay.main },
      { ...origin.fallback, ...overlay.fallback },
    ]);
  }

  /**
   * This method builds the configuration object needed to create the overlay, the OverlayState.
   * @returns OverlayConfig
   */
  private getOverlayConfig(): OverlayConfig {
    const overlayState: OverlayConfig = { positionStrategy: this.getPosition() };
    if (this.menu.triggerType === TriggerType.Click) {
      overlayState.hasBackdrop = true;
      overlayState.backdropClass = OVERLAY_TRANSPARENT_BACKDROP_CLASS;
    }
    overlayState.scrollStrategy = this.scrollStrategy();
    overlayState.direction = this.dirValue;
    return overlayState;
  }

  /**
   * This method builds the position strategy for the overlay, so the menu is properly connected
   * to the trigger.
   * @returns ConnectedPositionStrategy
   */
  private getPosition(): FlexibleConnectedPositionStrategy {
    const origin = this.getOrigin();
    const overlay = this.getOverlayPosition();

    return this.overlay
      .position()
      .flexibleConnectedTo(this.element)
      .withTransformOriginOn('.xpo-Popover-panel')
      .withPositions([
        { ...origin.main, ...overlay.main },
        { ...origin.fallback, ...overlay.fallback },
      ]);
  }

  /**
   * Returns the origin position and a fallback position based on the user's position preference.
   * The fallback position is the inverse of the origin (e.g. `'below' -> 'above'`).
   */
  getOrigin(): { main: OriginConnectionPosition; fallback: OriginConnectionPosition } {
    const isLtr = !this.dirValue || this.dirValue.value === 'ltr';
    const position = this.menu.position;
    let originPosition: OriginConnectionPosition;

    if (position === VerticalPosition.Above || position === VerticalPosition.Below) {
      originPosition = {
        originX: this.getHorizontalPositionFromCaretOptions(this.menu.caretPosition),
        originY: position === VerticalPosition.Above ? 'top' : 'bottom',
      };
    } else if (position === HorizontalPosition.Before || (position === 'left' && isLtr) || (position === 'right' && !isLtr)) {
      originPosition = {
        originX: 'start',
        originY: this.getVerticalPositionFromCaretOptions(this.menu.caretPosition),
      };
    } else if (position === HorizontalPosition.After || (position === 'right' && isLtr) || (position === 'left' && !isLtr)) {
      originPosition = { originX: 'end', originY: this.getVerticalPositionFromCaretOptions(this.menu.caretPosition) };
    } else {
      // throw getMatTooltipInvalidPositionError(position);
    }

    const { x, y } = this.invertPosition(originPosition.originX, originPosition.originY);

    return {
      main: originPosition,
      fallback: { originX: x, originY: y },
    };
  }

  /** Returns the overlay position and a fallback position based on the user's preference */
  getOverlayPosition(): { main: OverlayConnectionPosition; fallback: OverlayConnectionPosition } {
    const isLtr = !this.dirValue || this.dirValue.value === 'ltr';
    const position = this.menu.position;
    let overlayPosition: OverlayConnectionPosition;

    if (position === VerticalPosition.Above) {
      overlayPosition = {
        overlayX: this.getHorizontalPositionFromCaretOptions(this.menu.caretPosition),
        overlayY: 'bottom',
      };
    } else if (position === VerticalPosition.Below) {
      overlayPosition = {
        overlayX: this.getHorizontalPositionFromCaretOptions(this.menu.caretPosition),
        overlayY: 'top',
      };
    } else if (position === HorizontalPosition.Before || (position === 'left' && isLtr) || (position === 'right' && !isLtr)) {
      overlayPosition = {
        overlayX: 'end',
        overlayY: this.getVerticalPositionFromCaretOptions(this.menu.caretPosition),
      };
    } else if (position === HorizontalPosition.After || (position === 'right' && isLtr) || (position === 'left' && !isLtr)) {
      overlayPosition = {
        overlayX: 'start',
        overlayY: this.getVerticalPositionFromCaretOptions(this.menu.caretPosition),
      };
    } else {
      // throw getMatTooltipInvalidPositionError(position);
    }

    const { x, y } = this.invertPosition(overlayPosition.overlayX, overlayPosition.overlayY);

    return {
      main: overlayPosition,
      fallback: { overlayX: x, overlayY: y },
    };
  }
  // tslint:enable:triple-equals

  private getHorizontalPositionFromCaretOptions(caretPosition: HorizontalConnectionPos | VerticalConnectionPos): HorizontalConnectionPos {
    if (caretPosition === 'top') {
      caretPosition = 'start';
    } else if (caretPosition === 'bottom') {
      caretPosition = 'end';
    }
    return caretPosition as HorizontalConnectionPos;
  }

  private getVerticalPositionFromCaretOptions(caretPosition: HorizontalConnectionPos | VerticalConnectionPos): VerticalConnectionPos {
    if (caretPosition === 'start') {
      caretPosition = 'top';
    } else if (caretPosition === 'end') {
      caretPosition = 'bottom';
    }
    return caretPosition as VerticalConnectionPos;
  }

  /** Inverts an overlay position. */
  private invertPosition(x: HorizontalConnectionPos, y: VerticalConnectionPos): any {
    if (this.menu.position === VerticalPosition.Above || this.menu.position === VerticalPosition.Below) {
      if (y === 'top') {
        y = 'bottom';
      } else if (y === 'bottom') {
        y = 'top';
      }
    } else {
      if (x === 'end') {
        x = 'start';
      } else if (x === 'start') {
        x = 'end';
      }
    }

    return { x, y };
  }

  /** Cleans up the active subscriptions. */
  private cleanUpSubscriptions(): void {
    this.closeSubscription.unsubscribe();
    this.hoverSubscription.unsubscribe();
    if (this.positionSubscription) {
      this.positionSubscription.unsubscribe();
    }
  }

  /** Returns a stream that emits whenever an action that should close the menu occurs. */
  private menuClosingActions(): Observable<void | MouseEvent> {
    const backdrop = this.overlayRef.backdropClick();
    const detachments = this.overlayRef.detachments();

    return merge(backdrop, detachments);
  }

  /** Handles mouse presses on the trigger. */
  handleMousedown(event: MouseEvent): void {
    if (!isFakeMousedownFromScreenReader(event)) {
      this.openedByMouse = true;
    }
  }

  /** Handles key presses on the trigger. */
  handleKeydown(event: KeyboardEvent): void {
    const keyCode = event.code;

    if ((keyCode === RIGHT_ARROW && this.dir === 'ltr') || (keyCode === LEFT_ARROW && this.dir === 'rtl')) {
      this.openMenu();
    }
  }

  /** Handles click events on the trigger. */
  handleClick(): void {
    this.toggleMenu();
  }

  /** Handles mouse over event */
  handleMouseEnter(): void {
    if (this.menu.triggerType === TriggerType.Hover) {
      this.mouseEventSource.next(MouseEventType.Enter);
    }
  }

  /** Handles mouse leave event */
  handleMouseLeave(): void {
    if (this.menu.triggerType === TriggerType.Hover) {
      this.mouseEventSource.next(MouseEventType.Leave);
    }
  }

  initMouseEventWatcher(): void {
    /** Delay added to allow pointer to travel from trigger to popover without triggering closeMenu() */
    this.hoverSubscription = this.mouseEventSource.pipe(delay(500)).subscribe((action: string) => {
      if (action === MouseEventType.Leave && this.menuOpenValue && !this.menu.closeDisabled) {
        this.closeMenu();
      }
      if (action === MouseEventType.Enter && !this.menuOpenValue) {
        this.openMenu();
      }
    });
  }

  private positionIsVertical(position: string): boolean {
    return position === VerticalPosition.Above || position === VerticalPosition.Below;
  }
}
