import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import {
  XpoBoardConsumer,
  XpoBoardDataFetchState,
  XpoBoardDataSourceResolver,
  XpoBoardState,
  XpoBoardStateSources,
} from '../../models/index';
@Directive({
  selector: 'xpo-board-no-results-display',
})
export class XpoBoardNoResultsDisplay {}

@Component({
  selector: 'xpo-board-data-fetch-display',
  templateUrl: './board-data-fetch-display.component.html',
  styleUrls: ['./board-data-fetch-display.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-BoardDataFetchDisplay' },
})
export class XpoBoardDataFetchDisplay extends XpoBoardConsumer implements OnInit {
  dataFetchState: XpoBoardDataFetchState;
  XpoBoardDataFetchState = XpoBoardDataFetchState; // allow enum in template

  constructor(boardDataSourceResolver: XpoBoardDataSourceResolver, cd: ChangeDetectorRef) {
    super(boardDataSourceResolver, cd);
  }

  retry(): void {
    this.dataSource.refresh();
  }

  protected onStateChange(state: XpoBoardState): void {
    this.dataFetchState = state.dataFetchState; // Updating the data fetch state so handle what to show in the template
  }
}
