export enum ConfigManagerProperties {
  appName = 'appName',
  production = 'production',
  buildVersion = 'buildVersion',
  apiUrl = 'apiUrl',
  loggedInUserRoot = 'loggedInUserRoot',
  feedbackToAddress = 'feedbackToAddress',
  feedbackSubject = 'feedbackSubject',
  region = 'region',
  imageCorpCode = 'imageCorpCode',
  docViewWebContext = 'docViewWebContext',
}
