import {BolUsageTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class BolUsageTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BolUsageTypeCd ) {
      this._values.push(BolUsageTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BolUsageTypeCd {

    for ( const obj in BolUsageTypeCd ) {
      if (BolUsageTypeCd[obj] === value){
        return BolUsageTypeCd[obj] as BolUsageTypeCd;
      }
    }
  }
}

const BolUsageTypeCdHelper = new BolUsageTypeCdHelperEnumHelperClass();
export default BolUsageTypeCdHelper;
