import { Component, Input } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { cloneDeep } from 'lodash-es';
import _isEqual from 'lodash-es/isEqual';
import { Operators, OperatorsToOperatorText, OperatorText, XpoFilterCriteria } from '../filters';
import { XpoGridBoardState } from '../models';
import { XpoAppliedFilter } from '../models/applied-filters.interface';
import { XpoBoardConsumer } from '../models/board-consumer';

@Component({
  selector: 'xpo-applied-filters',
  templateUrl: './applied-filters.component.html',
  styleUrls: ['./applied-filters.component.scss'],
})
export class XpoAppliedFilters extends XpoBoardConsumer {
  readonly FILTER_CHAGE_SOURCE_NAME = 'FILTER-CHANGE';
  operatorsTexts: { [key: string]: string } = OperatorsToOperatorText;
  currentFilters: Array<any> = [];

  private currentState: XpoGridBoardState;

  @Input('conditionalFiltersOnly') conditionalFiltersOnly: boolean = false;

  /**
   * Handle filter remove action
   * @param filter
   */
  onFilterRemove(filter): void {
    this.currentState.criteria = this.removeFilterFromCriteria(filter, cloneDeep(this.currentState.criteria));
    this.stateChange$.next(<XpoGridBoardState>{
      criteria: this.currentState.criteria,
      source: this.FILTER_CHAGE_SOURCE_NAME,
    });
  }

  /**
   * Handle filter remove all action
   */
  onFilterRemoveAll(): void {
    if (this.conditionalFiltersOnly) {
      this.removeConditionalFilters();
    } else {
      this.removeAllFilters();
    }
  }

  private removeAllFilters(): void {
    this.stateChange$.next(<XpoGridBoardState>{
      criteria: {},
      source: this.FILTER_CHAGE_SOURCE_NAME,
    });
  }
  private removeConditionalFilters(): void {
    const criteria = cloneDeep(this.currentState.criteria);
    Object.keys(criteria).forEach((key) => {
      if (criteria[key] && criteria[key].conditions) {
        delete criteria[key];
      }
    });
    this.stateChange$.next(<XpoGridBoardState>{
      criteria,
      source: this.FILTER_CHAGE_SOURCE_NAME,
    });
  }

  /**
   * Remove filter from criteria depending on the filter type.
   * @param filter
   * @param criteria
   */
  private removeFilterFromCriteria(filter: any, criteria: any): XpoFilterCriteria {
    // if criteria contains conditions then it's a complex filter
    if (criteria[filter.columnField] && criteria[filter.columnField].conditions) {
      const columFilter = criteria[filter.columnField];
      const newConditions = columFilter.conditions.filter((condition) => {
        const conditionValue = this.getConditionalFilterValue(condition);
        return !(condition.operator === filter.operator && _isEqual(conditionValue, filter.value));
      });
      criteria[filter.columnField].conditions = newConditions;
      return criteria;
    }
    // othewise threat it as regular column filter
    if (criteria[filter.columnField]) {
      delete criteria[filter.columnField];
    }
    return criteria;
  }
  /**
   * Every time criteria changes on the state we display them on the tags.
   * @param state // board state
   */
  protected onStateChange(state: XpoGridBoardState): void {
    if (!state.changes.includes('criteria')) {
      return;
    }
    this.currentState = state;
    this.currentFilters = [];
    Object.keys(state.criteria).forEach((colField) => {
      // merge current filters with incoming filters
      this.currentFilters = [...this.currentFilters, ...this.parseFilterValue(colField, state.criteria[colField])];
    });
  }

  /**
   * Parse filter values to applied filters representation.
   * @param colField
   * @param filterValue
   */
  private parseFilterValue(colField, filterValue): XpoAppliedFilter[] {
    let filters: XpoAppliedFilter[] = [];

    // avoid empty filters
    if (!filterValue || !Object.keys(filterValue).length) {
      return filters;
    }

    // conditional filters
    if (filterValue.conditions) {
      filters = this.parseConditionalFilterValues(colField, filterValue);
    }
    // regular column filters
    if (!filterValue.conditions && !this.conditionalFiltersOnly) {
      const column = this.findColumn(colField, this.currentState.visibleColumns);

      if (column) {
        const appliedFilter: XpoAppliedFilter = {
          combiner: '',
          columnName: column.headerName,
          columnField: colField,
          value: filterValue,
          displayValue: this.getSimpleFilterDisplayValue(filterValue, column),
          operator: Operators.Equals,
          displayOperator: this.operatorsTexts[Operators.Equals],
        };
        filters.push(appliedFilter);
      }
    }
    return filters;
  }

  /**
   * Find and return column based on colField. It walks over all children columns in case there
   * are nested columns.
   * @param colField
   * @param columns
   */
  private findColumn(colField: ColDef, columns: any[]): ColDef | undefined {
    return columns.find((column) => {
      if (column.children) {
        return this.findColumn(colField, column.children);
      }
      return column.field === colField;
    });
  }

  /**
   * Parse conditional filter values to applied filters standard format
   * @param columnName
   * @param filterValue
   */
  private parseConditionalFilterValues(colField, filterValue): XpoAppliedFilter[] {
    const filters: XpoAppliedFilter[] = [];
    const column = this.findColumn(colField, this.currentState.visibleColumns);

    if (column) {
      filterValue.conditions.forEach((condition, index) => {
        const conditionOperator = condition.operator || Operators.Equals;
        const appliedFilter: XpoAppliedFilter = {
          // do not add -combiner- (AND, OR) on first tag element.
          combiner: index && filterValue.combiner ? filterValue.combiner : '',
          columnName: column.headerName,
          columnField: colField,
          value: this.getConditionalFilterValue(condition),
          // value can be represented in different ways based on the filter type.
          displayValue: this.getConditionalFilterDisplayValue(condition),
          // if no operator is present, use "is" as fallback (e.g.: in ag-grid default floating filters).
          operator: condition.operator || Operators.Equals,
          displayOperator: this.operatorsTexts[conditionOperator] || this.operatorsTexts[Operators.Equals],
        };
        filters.push(appliedFilter);
      });
    }

    return filters;
  }

  /**
   * Return appropriate value to be displayed when using complex filters which can have multiple representation values.
   * @param condition
   */
  private getConditionalFilterDisplayValue(condition: any): string {
    // 'and' is just the connector text for range filters, nothing to do with the -combiner- AND, OR.
    const RANGE_FILTER_CONNECTOR_TEXT = 'and';

    // display and displayTo used on range filters with custom display value.
    if (condition.display && condition.displayTo) {
      return `${condition.display} ${RANGE_FILTER_CONNECTOR_TEXT} ${condition.displayTo}`;
    }

    // value and valueTo used in range filters with no custom display value.
    if (condition.value && condition.valueTo) {
      return `${this.parseValueToString(condition.value)} ${RANGE_FILTER_CONNECTOR_TEXT} ${this.parseValueToString(
        condition.valueTo
      )}`;
    }

    // display value or value as is for non range filters. Empty string as fallback.
    return condition.display || this.parseValueToString(condition.value) || '';
  }

  /**
   * returns unified value if condition is a range or fallback to regular value instead
   * @param condition
   */
  private getConditionalFilterValue(condition: any): any {
    return condition.valueTo ? { from: condition.value, to: condition.valueTo } : condition.value;
  }

  /**
   * Return formatted value using valueFormatter from column when possible.
   * Supports simple filters using range values.
   * @param value
   * @param column
   */
  private getSimpleFilterDisplayValue(value: any, column: any): string {
    let formattedValue: string;
    const valueFormatter = column.valueFormatter || null;
    if (value.min) {
      formattedValue = valueFormatter ? valueFormatter({ value: value.min }) : this.parseValueToString(value.min);
    }
    if (value.max) {
      const maxValue = valueFormatter ? valueFormatter({ value: value.max }) : this.parseValueToString(value.max);
      formattedValue = `${formattedValue} - ${maxValue}`;
    }
    if (!value.min && !value.max) {
      formattedValue = this.parseValueToString(value);
    }

    return formattedValue;
  }
  /**
   * Try to parse the given value to string when possible.
   * @param value
   */
  private parseValueToString(value: any): string {
    // In most cases value will be already a string, so return same thing.
    if (typeof value === 'string') {
      return value;
    }
    // if array, return array elements separated by ', ' for readability.
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    // try to parse to string when possible or fallback to JSON.stringify in the worst case.
    return value.toString ? value.toString() : JSON.stringify(value);
  }
}
