import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { XrtChangedDocuments, XrtFirebaseMessage } from '@xpo/ngx-xrt';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable()
export class XrtFireMessagingService {
  constructor(private angularFireMessaging: AngularFireMessaging) {}

  changedDocument$(): Observable<XrtChangedDocuments> {
    return this.angularFireMessaging.messages.pipe(
      filter((message: XrtFirebaseMessage) => !!(message && message.data)),
      map(
        (message: XrtFirebaseMessage) =>
          <XrtChangedDocuments>{
            documents: message.data.changedDocumentsJson,
            filterHash: message.data.filterHash,
          }
      )
    );
  }
}
