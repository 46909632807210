export enum XpoViewMgtModalTexts {
  // Save view as
  DefaultViewName = 'Untitled',
  SaveViewAsTitle = 'Save as',
  SaveViewAsSubmitBtn = 'Save view',
  SaveViewAsCancelBtn = 'Cancel',
  // Rename view
  RenameViewTitle = 'Rename Selected View',
  RenameViewSubmitBtn = 'Save',
  RenameViewCancelBtn = 'Cancel',
  // Delete view
  DeleteViewTitle = 'Delete view',
  DeleteViewSubmitBtn = 'Delete',
  DeleteViewCancelBtn = 'Cancel',
}
