import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { XpoLtlRoleSwitcherData } from './role-switcher-data';
import { XpoLtlRoleSwitcherComponent } from './role-switcher.component';

@Injectable({ providedIn: 'root' })
export class XpoLtlRoleSwitcherService {
  constructor(private dialog: MatDialog) {}

  showRoleSwitcherDialog(roleSwitcherData: XpoLtlRoleSwitcherData): Observable<string> {
    return this.dialog.open(XpoLtlRoleSwitcherComponent, { data: roleSwitcherData, disableClose: true }).afterClosed();
  }
}
