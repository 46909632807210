import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { XpoPopoverClose } from './popover-close.directive';
import { XpoPopoverItem } from './popover-item.directive';
import { XpoPopoverTitle } from './popover-title.directive';
import { XPO_POPOVER_SCROLL_STRATEGY_FACTORY_PROVIDER, XpoPopoverTrigger } from './popover-trigger.directive';
import { XpoPopover, XpoPopoverContent } from './popover.component';

@NgModule({
  declarations: [XpoPopover, XpoPopoverTrigger, XpoPopoverTitle, XpoPopoverClose, XpoPopoverContent, XpoPopoverItem],
  imports: [CommonModule, MatMenuModule, MatIconModule, MatButtonModule],
  exports: [XpoPopover, XpoPopoverTrigger, XpoPopoverTitle, XpoPopoverClose, XpoPopoverContent, XpoPopoverItem],
  providers: [XPO_POPOVER_SCROLL_STRATEGY_FACTORY_PROVIDER],
})
export class XpoPopoverModule {
  constructor() {}
}
