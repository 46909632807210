import { Pipe, PipeTransform } from '@angular/core';

/**
 * Convert the passed string into a RegEx expression
 */
@Pipe({
  name: 'toRegex',
})
export class ToRegexPipe implements PipeTransform {
  transform(regExString: string): RegExp {
    return new RegExp(regExString);
  }
}
