import { Directive, ElementRef, Inject, Injector, Optional } from '@angular/core';
import { FormGroupDirective, NgForm, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, ErrorStateMatcher, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateRange } from '@angular/material/datepicker';
import { XpoDateRangeBaseInput } from './date-range-base-input.directive';
import { XpoDateRangeInputSupressPlaceholder } from './date-range-input-supress-placeholder.directive';

/**
 * The start date input control
 */
@Directive({
  selector: 'input[xpoDateRangeStartInput]',
  host: {
    class: 'xpo-DateRangeInput-inner',
    type: 'text',
    autocomplete: 'off',
    '[attr.maxlength]': 'getMaxLength()',
    '(input)': 'onInput($event.target.value)',
    '(focus)': 'onFocus()',
    '(blur)': 'onBlur()',
  },
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: XpoDateRangeStartInput, multi: true },
    { provide: NG_VALIDATORS, useExisting: XpoDateRangeStartInput, multi: true },
  ],
})
export class XpoDateRangeStartInput<D> extends XpoDateRangeBaseInput<D> {
  constructor(
    protected elementRef: ElementRef<HTMLInputElement>,
    @Optional() protected parentForm: NgForm,
    @Optional() protected parentFormGroup: FormGroupDirective,
    protected dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) protected dateFormats: MatDateFormats,
    protected injector: Injector,
    protected errorStateMatcher: ErrorStateMatcher,
    @Optional() protected supressPlaceholder: XpoDateRangeInputSupressPlaceholder
  ) {
    // (from material): this constructor shouldn't be necessary, but ViewEngine doesn't seem to
    // handle DI correctly when it is inherited from `XpoDateRangeBaseInput`. We can drop this
    // constructor once ViewEngine is removed.
    super(
      elementRef,
      parentForm,
      parentFormGroup,
      dateAdapter,
      dateFormats,
      injector,
      errorStateMatcher,
      supressPlaceholder
    );
  }

  /**
   * Gets the value that should be used when mirroring the input's size.
   * */
  getMirrorValue(): string {
    const element = this.elementRef.nativeElement;
    const value = element.value;

    // if the input has not a placeholder defined, use the value defined for dates format
    // just to keep a min widht for the input when no placeholder o values are defined
    const placeholder = element.placeholder.length > 0 ? element.placeholder : this.dateFormats.display.dateInput;

    // define the input width based on the typed value or on its placeholder
    return value.length > 0 ? value : placeholder;
  }

  /**
   * Returns the corresponding value: the start selected date
   * @param range
   */
  protected getValueFromRange(range: DateRange<D>): D {
    return range.start;
  }

  /**
   * Returns a new range selection based on the given date as the start date of the range
   * @param date
   */
  protected getNewSelection(date: D): DateRange<D> {
    // create a new range based on the given date
    return new DateRange(date, this.selectionModel.selection.end);
  }
}
