import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import _ from 'lodash';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

/* XPO */
import { XpoConfirmDialog, XpoConfirmDialogConfig } from '@xpo-ltl/ngx-ltl-core';
import { ActionCd, ModuleExecutorDataExtractTypeCd, ModuleExecutorParmsArgTypeCd } from '@xpo-ltl/sdk-common';
import {
  DataExtract,
  Dataset,
  ListDataExtractsQuery,
  ListDataExtractsResp,
  ListDatasetPeriodCdsResp,
  ListDatasetsQuery,
  ListDatasetsResp,
  ModuleExecutorApiService,
  ModuleGroupExecParm,
  ModuleGroupModuleVersion,
} from '@xpo-ltl/sdk-moduleexecutor';

/* Rxjs */
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

/* Services */
import { ModuleGroupManagementService } from '../../../../../services/module-group-management.service';

/* Enums */
import { ModuleExecutorParmsArgTypeCdText } from '../../../../../enums/module-executor-parms-arg-type-cd-text';

@Component({
  selector: 'module-group-mgmt-arguments-detail-cell-renderer',
  templateUrl: './arguments-detail-cell-renderer.component.html',
  styleUrls: ['./arguments-detail-cell-renderer.component.scss'],
})
export class ModuleGroupMgmtArgumentsDetailCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  argumentsForm: FormGroup;

  argumentTypes: any[];

  /* Lists for 'Dataset' type */
  datasetLists: {
    periodCds: string[];
    dataExtracts: DataExtract[][];
    datasets: Dataset[][];
  };

  /* Lists for 'Constant Dataset' type */
  constDatasetLists: {
    dataExtracts: DataExtract[][];
    datasets: Dataset[][];
  };

  nodeData: ModuleGroupModuleVersion;

  selectedPeriodCd: string;

  stepId: number;

  execOrderLoopInd: boolean;

  tooltipDataExtract: string;

  /* Subscriptions */
  subscriptions: Subscription;
  controlsValueChangesSubscription: Subscription;

  get meParmsArgTypeCd(): any {
    return ModuleExecutorParmsArgTypeCd;
  }

  get argumentsFormGroupControls() {
    return (this.argumentsForm.get('arguments') as FormArray).controls;
  }

  constructor(
    private fb: FormBuilder,
    private moduleExecutorApiService: ModuleExecutorApiService,
    private moduleGroupManagementService: ModuleGroupManagementService,
    private confirmDialog: XpoConfirmDialog
  ) {
    this.argumentTypes = [
      {
        key: ModuleExecutorParmsArgTypeCd.DATA_SET,
        value: ModuleExecutorParmsArgTypeCdText.DATA_SET,
      },
      {
        key: ModuleExecutorParmsArgTypeCd.CONSTANT_DATASET,
        value: ModuleExecutorParmsArgTypeCdText.CONSTANT_DATASET,
      },
      {
        key: ModuleExecutorParmsArgTypeCd.CONFIGURATION,
        value: ModuleExecutorParmsArgTypeCdText.CONFIGURATION,
      },
      {
        key: ModuleExecutorParmsArgTypeCd.DYNAMIC_CONFIG,
        value: ModuleExecutorParmsArgTypeCdText.DYNAMIC_CONFIG,
      },
    ];

    this.datasetLists = {
      periodCds: [],
      dataExtracts: [],
      datasets: [],
    };
    this.constDatasetLists = {
      dataExtracts: [],
      datasets: [],
    };

    this.nodeData = null;
    this.selectedPeriodCd = null;

    this.subscriptions = new Subscription();
    this.controlsValueChangesSubscription = new Subscription();
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.initialize();
    const execOrderLoopIndSubscription = this.params.context.execOrderLoopInd$.subscribe((execOrderLoopInd) => {
      this.execOrderLoopInd = execOrderLoopInd;
      this.onExecOrderLoopIndChanges();
    });
    this.subscriptions.add(execOrderLoopIndSubscription);
    this.onSelectedPeriodCdChanges();
  }

  refresh(): boolean {
    return true;
  }

  destroy() {
    this.subscriptions.unsubscribe();
    this.controlsValueChangesSubscription.unsubscribe();

    this.controlsValueChangesSubscription = new Subscription();
    this.subscriptions = new Subscription();
  }

  initialize() {
    this.argumentsForm = this.fb.group({
      arguments: this.fb.array([]),
    });

    // this datasetLists has a global periodCd[] and step level dataExtract[][] and datasets[][].
    this.datasetLists = {
      ...this.datasetLists,
      ...this.moduleGroupManagementService.datasetLists.periodCds,
    };
    this.constDatasetLists = {
      dataExtracts: [],
      datasets: [],
    };

    this.nodeData = null;
    this.selectedPeriodCd = null;

    this.subscriptions = new Subscription();
    this.controlsValueChangesSubscription = new Subscription();

    // Initialize data
    this.nodeData = { ...{ moduleGroupExecParm: [] }, ...this.params.node.data };

    this.nodeData.moduleGroupExecParm = this.fixArgumentSequenceNbrOrder(this.nodeData.moduleGroupExecParm);

    this.stepId = parseInt(this.nodeData.executionOrderNbr, 10);

    const globalPeriodCd = this.moduleGroupManagementService.getSelectedPeriodCd();
    const stepPeriodCd = this.getPeriodCdFromNodeExecParm();
    this.tooltipDataExtract =
      !globalPeriodCd && !stepPeriodCd ? 'Please select a Period Code before continuing...' : '';

    if (!globalPeriodCd) {
      this.selectedPeriodCd = stepPeriodCd;
      this.moduleGroupManagementService.setSelectedPeriodCd(this.selectedPeriodCd);
    } else {
      this.setPeriodCodeChange(globalPeriodCd);
    }

    this.setNodeData(this.nodeData);

    this.createArgumentsFormArray(this.nodeData.moduleGroupExecParm);
    this.setPeriodCdValToAllDataSetArguments(this.selectedPeriodCd);
    this.listDataExtracts(this.selectedPeriodCd);

    // Register form changes
    this.onFormChanges();
  }

  private resetFormChanges(): void {
    this.controlsValueChangesSubscription.unsubscribe();
    this.controlsValueChangesSubscription = new Subscription();
    this.onFormChanges();
  }

  private checkAndSetControlChanges(control: AbstractControl): void {
    const controlValueChangesSubscription = control.valueChanges.subscribe((ctrl) => {
      const periodCode = this.getDataSetPeriodCode();
      const moduleGroupExecParmEdited = this.getArgumentsFormArray()
        .controls.filter((c) => c.valid)
        .map((c) => {
          // Case DYNAMIC_CONFIG Update argument value
          if (c.get('argumentTypeCd').value === ModuleExecutorParmsArgTypeCd.DYNAMIC_CONFIG) {
            c.value.argument = this.getDynamicConfigArgument(
              c.get('argumentId').value,
              c.get('argumentSequenceNbr').value,
              c.get('dateTimestamp').value
            );
          }
          return c.value;
        });
      if (periodCode) {
        moduleGroupExecParmEdited.push(periodCode);
      }
      // Update Node moduleGroupExecParm
      this.nodeData.moduleGroupExecParm = moduleGroupExecParmEdited;
      // Set Node Data
      this.setNodeData(this.nodeData);
    });
    this.controlsValueChangesSubscription.add(controlValueChangesSubscription);
  }

  private validateDuplicatedConfigArgs(input: FormControl) {
    const args = this.getArgumentsFormArray();

    const exist = args.value.some((obj) => {
      return (
        obj.argumentId === input.value.argumentId &&
        obj.argument === input.value.argument &&
        obj.argumentTypeCd === input.value.argumentTypeCd
      );
    });

    input.markAsTouched();

    return !input.dirty ? null : !exist ? null : { typeConfigValidation: false };
  }

  private validateDuplicatedDynArgs(input: FormControl) {
    const args = this.getArgumentsFormArray();

    const exist = args.value.some((obj) => {
      return obj.argumentId === input.value.argumentId && obj.argumentTypeCd === input.value.argumentTypeCd;
    });

    input.markAsTouched();

    return !input.dirty ? null : !exist ? null : { typeConfigValidation: false };
  }

  private createArgumentsFormArray(moduleGroupExecParmList: ModuleGroupExecParm[]): void {
    const args = this.getArgumentsFormArray();
    if (moduleGroupExecParmList.length > 0) {
      moduleGroupExecParmList
        .filter((obj) => obj.argumentTypeCd !== ModuleExecutorParmsArgTypeCd.PERIOD_CODE)
        .forEach((moduleGroupExecParm, i) => {
          args.push(this.createRowFormGroup(i, moduleGroupExecParm));
        });
    } else {
      args.push(this.createRowFormGroup());
    }
  }

  private createRowFormGroup(index: number = null, moduleGroupExecParm: ModuleGroupExecParm = null): FormGroup {
    let argsFormGroup = null;
    switch (moduleGroupExecParm && moduleGroupExecParm.argumentTypeCd) {
      case ModuleExecutorParmsArgTypeCd.CONFIGURATION:
        argsFormGroup = this.createArgsConfiguration(moduleGroupExecParm);
        break;
      case ModuleExecutorParmsArgTypeCd.DATA_SET:
        this.listDataExtracts(this.selectedPeriodCd);
        if (moduleGroupExecParm.parentDataExtractInstId !== null) {
          this.listDatasets(index, moduleGroupExecParm.parentDataExtractInstId, this.selectedPeriodCd);
        }
        argsFormGroup = this.createArgsDataset(moduleGroupExecParm);
        break;
      case ModuleExecutorParmsArgTypeCd.CONSTANT_DATASET:
        this.listDataExtracts();
        if (moduleGroupExecParm.parentDataExtractInstId !== null) {
          this.listDatasets(index, moduleGroupExecParm.parentDataExtractInstId);
        }
        argsFormGroup = this.createArgsConstantDataset(moduleGroupExecParm);
        break;
      case ModuleExecutorParmsArgTypeCd.DYNAMIC_CONFIG:
        argsFormGroup = this.createArgsDynamicConfig(moduleGroupExecParm);
        break;
      default:
        argsFormGroup = this.createArgsConfiguration(moduleGroupExecParm);
        break;
    }
    return argsFormGroup;
  }

  private createArgsConfiguration(
    moduleGroupExecParm: ModuleGroupExecParm = null,
    argumentSequenceNbr: number = this.nodeData.moduleGroupExecParm.length + 1
  ): FormGroup {
    return this.fb.group(
      {
        argumentTypeCd: [
          (moduleGroupExecParm && moduleGroupExecParm.argumentTypeCd) || ModuleExecutorParmsArgTypeCd.CONFIGURATION,
          [Validators.required],
        ],
        argumentId: [moduleGroupExecParm && moduleGroupExecParm.argumentId, [Validators.required]],
        argument: moduleGroupExecParm && moduleGroupExecParm.argument,
        argumentSequenceNbr: (moduleGroupExecParm && moduleGroupExecParm.argumentSequenceNbr) || argumentSequenceNbr,
        moduleInstId: this.nodeData.moduleInstId,
        moduleGroupInstId: this.nodeData.moduleGroupInstId,
        moduleVersionSequenceNbr: this.nodeData.moduleVersionSequenceNbr,
        moduleGroupModuleVersionSequenceNbr: this.nodeData.moduleGroupModuleVersionSequenceNbr,
      },
      { validators: this.validateDuplicatedConfigArgs.bind(this) }
    );
  }

  private createArgsDynamicConfig(
    moduleGroupExecParm: ModuleGroupExecParm = null,
    argumentSequenceNbr: number = this.nodeData.moduleGroupExecParm.length + 1
  ): FormGroup {
    const nowString = Date.now().toString();
    const dateTimestamp = nowString.substring(nowString.length - 5, nowString.length);
    return this.fb.group(
      {
        argumentTypeCd: [
          (moduleGroupExecParm && moduleGroupExecParm.argumentTypeCd) || ModuleExecutorParmsArgTypeCd.DYNAMIC_CONFIG,
          [Validators.required],
        ],
        argumentId: [moduleGroupExecParm && moduleGroupExecParm.argumentId, [Validators.required]],
        argument: { value: moduleGroupExecParm && moduleGroupExecParm.argument, disabled: true },
        argumentSequenceNbr: (moduleGroupExecParm && moduleGroupExecParm.argumentSequenceNbr) || argumentSequenceNbr,
        moduleInstId: this.nodeData.moduleInstId,
        moduleGroupInstId: this.nodeData.moduleGroupInstId,
        moduleVersionSequenceNbr: this.nodeData.moduleVersionSequenceNbr,
        moduleGroupModuleVersionSequenceNbr: this.nodeData.moduleGroupModuleVersionSequenceNbr,
        dateTimestamp: {
          value: dateTimestamp,
          disabled: true,
        },
      },
      { validators: this.validateDuplicatedDynArgs.bind(this) }
    );
  }

  private createArgsDataset(
    moduleGroupExecParm: ModuleGroupExecParm = null,
    argumentSequenceNbr: number = this.nodeData.moduleGroupExecParm.length + 1
  ): FormGroup {
    const periodCd = this.getDataSetPeriodCodeValue();
    return this.fb.group({
      argumentTypeCd: [
        (moduleGroupExecParm && moduleGroupExecParm.argumentTypeCd) || ModuleExecutorParmsArgTypeCd.DATA_SET,
        [Validators.required],
      ],
      periodCd: [periodCd, [Validators.required]],
      parentDataExtractInstId: [
        moduleGroupExecParm && moduleGroupExecParm.parentDataExtractInstId,
        [Validators.required],
      ],
      parentDatasetInstId: [(moduleGroupExecParm && moduleGroupExecParm.parentDatasetInstId) || ''],
      argumentId: moduleGroupExecParm && moduleGroupExecParm.argumentId,
      argument: moduleGroupExecParm && moduleGroupExecParm.argument,
      argumentSequenceNbr: (moduleGroupExecParm && moduleGroupExecParm.argumentSequenceNbr) || argumentSequenceNbr,
      moduleGroupExecParmId: moduleGroupExecParm && moduleGroupExecParm.moduleGroupExecParmId,
      moduleInstId: this.nodeData.moduleInstId,
      moduleGroupInstId: this.nodeData.moduleGroupInstId,
      moduleVersionSequenceNbr: this.nodeData.moduleVersionSequenceNbr,
      moduleGroupModuleVersionSequenceNbr: this.nodeData.moduleGroupModuleVersionSequenceNbr,
    });
  }

  private createArgsConstantDataset(
    moduleGroupExecParm: ModuleGroupExecParm = null,
    argumentSequenceNbr: number = this.nodeData.moduleGroupExecParm.length + 1
  ): FormGroup {
    return this.fb.group({
      argumentTypeCd: [
        (moduleGroupExecParm && moduleGroupExecParm.argumentTypeCd) || ModuleExecutorParmsArgTypeCd.CONSTANT_DATASET,
        [Validators.required],
      ],
      parentDataExtractInstId: [
        moduleGroupExecParm && moduleGroupExecParm.parentDataExtractInstId,
        [Validators.required],
      ],
      parentDatasetInstId: [(moduleGroupExecParm && moduleGroupExecParm.parentDatasetInstId) || ''],
      argumentId: moduleGroupExecParm && moduleGroupExecParm.argumentId,
      argument: moduleGroupExecParm && moduleGroupExecParm.argument,
      argumentSequenceNbr: (moduleGroupExecParm && moduleGroupExecParm.argumentSequenceNbr) || argumentSequenceNbr,
      moduleInstId: this.nodeData.moduleInstId,
      moduleGroupInstId: this.nodeData.moduleGroupInstId,
      moduleVersionSequenceNbr: this.nodeData.moduleVersionSequenceNbr,
      moduleGroupModuleVersionSequenceNbr: this.nodeData.moduleGroupModuleVersionSequenceNbr,
    });
  }

  getArgumentFormControl(i): AbstractControl {
    return this.argumentsForm.get('arguments')['controls'][i];
  }

  /**
   * Fix ArgumentSequenceNbr Order
   * @description This is used to correct the Arguments SequenceNbr Order When comes intercalated like 2,5,9 --> 1,2,3
   * @param moduleGroupExecParm <ModuleGroupExecParm[]>
   * @return ModuleGroupExecParm[]
   */
  fixArgumentSequenceNbrOrder(moduleGroupExecParm = []): ModuleGroupExecParm[] {
    return moduleGroupExecParm
      .filter(
        (obj) => obj.argumentTypeCd !== ModuleExecutorParmsArgTypeCd.PERIOD_CODE || obj.listActionCd !== ActionCd.DELETE
      )
      .map((execParm, i) => {
        execParm.argumentSequenceNbr = i + 1;
        return execParm;
      });
  }

  private getGridDatasets(pos: number): Dataset[] {
    return this.datasetLists.datasets[pos];
  }

  private getGridConstDatasets(pos: number): Dataset[] {
    return this.constDatasetLists.datasets[pos];
  }

  private getPeriodCdValue(optionValue: string): string {
    return optionValue === 'LASTMONTH' ? null : optionValue;
  }

  private getPeriodCdFromNodeExecParm(): string {
    const periodCodeExecParm = this.nodeData.moduleGroupExecParm.find(
      (obj) => obj.argumentTypeCd === ModuleExecutorParmsArgTypeCd.PERIOD_CODE
    );
    return periodCodeExecParm && periodCodeExecParm.argument;
  }

  private getNodePeriodCodeExecParm(): ModuleGroupExecParm {
    return this.nodeData.moduleGroupExecParm.find(
      (obj) => obj.argumentTypeCd === ModuleExecutorParmsArgTypeCd.PERIOD_CODE
    );
  }

  private getNodeWithoutPeriodCodeExecParm(): ModuleGroupExecParm[] {
    return this.nodeData.moduleGroupExecParm.filter(
      (obj) => obj.argumentTypeCd !== ModuleExecutorParmsArgTypeCd.PERIOD_CODE
    );
  }

  getDataSetPeriodCode(): ModuleGroupExecParm {
    if (this.selectedPeriodCd) {
      let newExecParam = new ModuleGroupExecParm();
      newExecParam = {
        moduleGroupExecParmId: null,
        moduleVersionSequenceNbr: this.nodeData.moduleVersionSequenceNbr,
        moduleGroupInstId: this.nodeData.moduleGroupInstId,
        moduleInstId: this.nodeData.moduleInstId,
        moduleGroupModuleVersionSequenceNbr: this.nodeData.moduleGroupModuleVersionSequenceNbr,
        argumentSequenceNbr: '0',
        argumentTypeCd: ModuleExecutorParmsArgTypeCd.PERIOD_CODE,
        argumentId: 'pc',
        argument: this.selectedPeriodCd,
        correlationId: null,
        listActionCd: null,
        auditInfo: null,
        parentDatasetInstId: null,
        parentDataExtractInstId: null,
      };
      return newExecParam;
    } else {
      return this.getNodePeriodCodeExecParm();
    }
  }

  private getDataSetPeriodCodeValue(): string {
    const args = this.getArgumentsFormArray();
    const periodCodeControl = args.controls.filter(
      (control) => control.value.argumentTypeCd === ModuleExecutorParmsArgTypeCd.DATA_SET
    );
    if (periodCodeControl.length > 0) {
      return periodCodeControl[0].value.periodCd;
    } else {
      return this.getPeriodCdFromNodeExecParm();
    }
  }

  private getArgumentsFormArray(): FormArray {
    return this.argumentsForm.get('arguments') as FormArray;
  }

  private getGridDataExtracts(dataExtract: DataExtract[]): DataExtract[][] {
    const args = this.getArgumentsFormArray();
    return args.controls.map((argCtrl) =>
      dataExtract.filter(
        (el) =>
          !args.value.some(
            (argValue) => argValue.argumentId === el.argumentId && argCtrl.value.argumentId !== el.argumentId
          )
      )
    );
  }

  private getDataSetExecParmCount(moduleGroupExecParm: ModuleGroupExecParm[]): number {
    let count = 0;
    moduleGroupExecParm.forEach((obj) => {
      if (obj.argumentTypeCd === ModuleExecutorParmsArgTypeCd.DATA_SET) {
        count++;
      }
    });
    return count;
  }

  private getModuleGroupModuleVersionNormalized(
    moduleGroupModuleVersion: ModuleGroupModuleVersion
  ): ModuleGroupModuleVersion {
    const moduleGroupExecParm = _.cloneDeep(moduleGroupModuleVersion.moduleGroupExecParm).map(
      (obj: ModuleGroupExecParm) => {
        if (obj.argumentTypeCd === ModuleExecutorParmsArgTypeCd.DATA_SET) {
          delete obj['periodCd'];
        }
        return obj;
      }
    );
    moduleGroupModuleVersion.moduleGroupExecParm = moduleGroupExecParm.filter(
      (obj) => obj.argumentTypeCd !== ModuleExecutorParmsArgTypeCd.PERIOD_CODE
    );
    return moduleGroupModuleVersion;
  }

  private setNodeData(moduleGroupModuleVersion: ModuleGroupModuleVersion): void {
    moduleGroupModuleVersion.moduleGroupExecParm = this.fixArgumentSequenceNbrOrder(
      moduleGroupModuleVersion.moduleGroupExecParm
    );
    this.params.node.setData(moduleGroupModuleVersion);
    this.moduleGroupManagementService.setStepModuleGroupModuleVersion(
      this.getModuleGroupModuleVersionNormalized(moduleGroupModuleVersion)
    );
  }

  private setNodePeriodCodeExecParmValue(periodCd: string): void {
    const index = this.nodeData.moduleGroupExecParm.findIndex(
      (obj) => obj.argumentTypeCd === ModuleExecutorParmsArgTypeCd.PERIOD_CODE
    );
    if (index !== -1) {
      this.nodeData.moduleGroupExecParm[index].argument = this.getPeriodCdValue(periodCd);
    } else {
      const newExecParam = new ModuleGroupExecParm();
      newExecParam.argumentTypeCd = ModuleExecutorParmsArgTypeCd.PERIOD_CODE;
      newExecParam.argument = this.getPeriodCdValue(periodCd);
      newExecParam.argumentId = 'pc';
      this.nodeData.moduleGroupExecParm.push(newExecParam);
      this.setNodeData(this.nodeData);
    }
  }

  private setPeriodCdValToAllDataSetArguments(periodCd: string): void {
    const args = this.getArgumentsFormArray();
    let dataSetCount = 0;
    args.controls.forEach((control) => {
      if (control.value.argumentTypeCd === ModuleExecutorParmsArgTypeCd.DATA_SET) {
        const periodCdCtrl = control.get('periodCd');
        if (periodCdCtrl) {
          periodCdCtrl.patchValue(periodCd);
          if (dataSetCount > 0) {
            periodCdCtrl.disable();
          } else {
            periodCdCtrl.enable();
          }
          dataSetCount++;
        }
      }
    });
  }

  private setDataSetValidation(pos: number): void {
    const argFormControl = this.getArgumentFormControl(pos);

    const parentDataExtractInstIdCtrl = argFormControl.get('parentDataExtractInstId');
    const parentDatasetInstIdCtrl = argFormControl.get('parentDatasetInstId');

    const periodCdCtrlVal = this.selectedPeriodCd;
    const parentDataExtractInstIdCtrlVal = parentDataExtractInstIdCtrl && parentDataExtractInstIdCtrl.value;

    if (parentDataExtractInstIdCtrl) {
      if (periodCdCtrlVal !== null) {
        parentDataExtractInstIdCtrl.enable();
      } else {
        parentDataExtractInstIdCtrl.disable();
      }
    }

    if (parentDatasetInstIdCtrl) {
      if (parentDataExtractInstIdCtrlVal !== null) {
        parentDatasetInstIdCtrl.enable();
      } else {
        parentDatasetInstIdCtrl.disable();
      }
    }
  }

  private setConstDataSetValidation(pos: number): void {
    const argFormControl = this.getArgumentFormControl(pos);

    const parentDataExtractInstIdCtrl = argFormControl.get('parentDataExtractInstId');
    const parentDatasetInstIdCtrl = argFormControl.get('parentDatasetInstId');

    const parentDataExtractInstIdCtrlVal = parentDataExtractInstIdCtrl && parentDataExtractInstIdCtrl.value;

    if (parentDatasetInstIdCtrl) {
      if (parentDataExtractInstIdCtrlVal !== null) {
        parentDatasetInstIdCtrl.enable();
      } else {
        parentDatasetInstIdCtrl.disable();
      }
    }
  }

  setPeriodCodeChange(periodCdValue: string): void {
    const argsFormArray = this.getArgumentsFormArray();
    const datasetArgsFormArrayControls = argsFormArray.controls;
    this.tooltipDataExtract = '';
    /* Set Period Code */
    this.selectedPeriodCd = periodCdValue;
    if (datasetArgsFormArrayControls.length > 0) {
      this.setPeriodCdValToAllDataSetArguments(this.selectedPeriodCd);
    }
  }

  private addArgument(
    argPos: number = this.getArgumentsFormArray().value.length,
    argumentSequenceNbr: number = this.nodeData.moduleGroupExecParm.length + 1,
    argumentTypeCd: ModuleExecutorParmsArgTypeCd = null
  ): number {
    const args = this.getArgumentsFormArray();
    switch (argumentTypeCd) {
      case ModuleExecutorParmsArgTypeCd.CONFIGURATION:
        args.insert(argPos, this.createArgsConfiguration(null, argumentSequenceNbr));
        break;
      case ModuleExecutorParmsArgTypeCd.DYNAMIC_CONFIG:
        args.insert(argPos, this.createArgsDynamicConfig(null, argumentSequenceNbr));
        break;
      case ModuleExecutorParmsArgTypeCd.DATA_SET:
        args.insert(argPos, this.createArgsDataset(null, argumentSequenceNbr));
        this.setPeriodCdValToAllDataSetArguments(this.selectedPeriodCd);
        this.listDataExtracts(this.selectedPeriodCd);
        break;
      case ModuleExecutorParmsArgTypeCd.CONSTANT_DATASET:
        args.insert(argPos, this.createArgsConstantDataset(null, argumentSequenceNbr));
        this.listDataExtracts();
        break;
      default:
        args.insert(argPos, this.createArgsDataset(null, argumentSequenceNbr));
        this.setPeriodCdValToAllDataSetArguments(this.selectedPeriodCd);
        this.listDataExtracts(this.selectedPeriodCd);
        break;
    }
    this.resetFormChanges();
    return argPos;
  }

  private deleteArgument(i: number): FormArray {
    const args = this.getArgumentsFormArray();
    const nodePeriodCd = this.getNodePeriodCodeExecParm();
    const moduleGroupExecParmEdited = this.getNodeWithoutPeriodCodeExecParm();

    this.deleteGridDataset(i);

    moduleGroupExecParmEdited.splice(i, 1);

    const dataSetExecParmCount = this.getDataSetExecParmCount(moduleGroupExecParmEdited);

    if (nodePeriodCd && dataSetExecParmCount > 0) {
      moduleGroupExecParmEdited.push(nodePeriodCd);
    } else {
      this.selectedPeriodCd = this.moduleGroupManagementService.getSelectedPeriodCd();
      this.deleteNodePeriodCodeExecParm();
    }

    this.nodeData.moduleGroupExecParm = moduleGroupExecParmEdited;
    this.setNodeData(this.nodeData);

    // Delete from args
    args.removeAt(i);
    this.resetFormChanges();

    return args;
  }

  private deleteNodePeriodCodeExecParm(): void {
    const periodCdExecParamIndex = this.nodeData.moduleGroupExecParm.findIndex(
      (obj) => obj.argumentTypeCd === ModuleExecutorParmsArgTypeCd.PERIOD_CODE
    );
    if (periodCdExecParamIndex !== -1) {
      this.nodeData.moduleGroupExecParm.splice(periodCdExecParamIndex, 1);
    }
  }

  private deleteGridDataset(pos: number): void {
    if (this.constDatasetLists && this.constDatasetLists.datasets && this.constDatasetLists.dataExtracts) {
      this.datasetLists.datasets.splice(pos, 1);
      this.constDatasetLists.datasets.splice(pos, 1);
      this.constDatasetLists.dataExtracts.splice(pos, 1);
    }
  }

  private listDataExtracts$(periodCd): Observable<any> {
    if (!periodCd) {
      return of([]);
    } else {
      const query: ListDataExtractsQuery = {
        periodCd: this.getPeriodCdValue(periodCd),
        dataExtractTypeCd: ModuleExecutorDataExtractTypeCd.PERIOD_CODE,
        listInfo: null,
      };
      return this.moduleExecutorApiService.listDataExtracts(query).pipe(
        map((res) => {
          this.datasetLists.dataExtracts = this.getGridDataExtracts(res.dataExtract);
        })
      );
    }
  }

  private listDataExtracts(periodCd: string = null): void {
    const query: ListDataExtractsQuery = {
      periodCd: this.getPeriodCdValue(periodCd),
      dataExtractTypeCd: periodCd
        ? ModuleExecutorDataExtractTypeCd.PERIOD_CODE
        : ModuleExecutorDataExtractTypeCd.CONSTANT,
      listInfo: null,
    };

    const listDataExtractSubscription = this.moduleExecutorApiService.listDataExtracts(query).subscribe(
      (res: ListDataExtractsResp) => {
        /* Reorder Grid Data Extracts to avoid duplicated selections */
        if (periodCd) {
          /* If it has Period Code it's a DATA_SET Type */
          this.datasetLists.dataExtracts = this.getGridDataExtracts(res.dataExtract);
        } else {
          /* If Not, it's a CONSTANT_DATASET Type */
          this.constDatasetLists.dataExtracts = this.getGridDataExtracts(res.dataExtract);
        }
      },
      (err) => {
        if (periodCd) {
          this.datasetLists.dataExtracts = [];
        } else {
          this.constDatasetLists.dataExtracts = [];
        }
      }
    );
    this.subscriptions.add(listDataExtractSubscription);
  }

  private listDatasets(pos, parentDataExtractInstId, periodCd = null): void {
    const queryParams: ListDatasetsQuery = {
      dataExtractInstId: parentDataExtractInstId,
      periodCd: this.getPeriodCdValue(periodCd), // It will not be necessary for a dataset constant
      listInfo: null,
    };
    const listDatasetsSubscription = this.moduleExecutorApiService.listDatasets(queryParams).subscribe(
      (res: ListDatasetsResp) => {
        if (periodCd) {
          /* If it has Period Code it's a DATA_SET Type */
          this.datasetLists.datasets[pos] = res.datasets;
        } else {
          /* If Not, it's a CONSTANT_DATASET Type */
          this.constDatasetLists.datasets[pos] = res.datasets;
        }
      },
      (err) => {
        if (periodCd) {
          this.datasetLists.datasets[pos] = [];
        } else {
          this.constDatasetLists.datasets[pos] = [];
        }
      }
    );
    this.subscriptions.add(listDatasetsSubscription);
  }

  private onExecOrderLoopIndChanges() {
    const args = this.getArgumentsFormArray();

    this.nodeData.execOrderLoopInd = this.execOrderLoopInd;
    this.setNodeData(this.nodeData);

    const dynamicConfig = {
      key: ModuleExecutorParmsArgTypeCd.DYNAMIC_CONFIG,
      value: ModuleExecutorParmsArgTypeCdText.DYNAMIC_CONFIG,
    };
    const dynamicConfigIndex = this.argumentTypes.findIndex(
      (obj) => obj.key === ModuleExecutorParmsArgTypeCd.DYNAMIC_CONFIG
    );

    if (this.execOrderLoopInd) {
      if (dynamicConfigIndex === -1) {
        this.argumentTypes.push(dynamicConfig);
      }
    } else {
      if (dynamicConfigIndex !== -1) {
        this.argumentTypes.splice(dynamicConfigIndex, 1);
      }
      if (args.length > 0) {
        const argFormControl = this.getArgumentFormControl(args.length - 1);
        const argTypeCdValue = argFormControl.get('argumentTypeCd').value;
        if (argTypeCdValue === ModuleExecutorParmsArgTypeCd.DYNAMIC_CONFIG) {
          this.onTypeChange(args.length - 1, ModuleExecutorParmsArgTypeCd.CONFIGURATION);
        }
        this.initialize();
      }
    }
  }

  onTypeChange(i: number, value: any): void {
    const argFormControl = this.getArgumentFormControl(i);
    const argTypeCdValue = argFormControl.get('argumentTypeCd').value;

    this.deleteArgument(i);
    this.addArgument(i, i + 1, value);

    argFormControl.patchValue({
      parentDataExtractInstId: null,
      parentDatasetInstId: '',
      argumentId: null,
      argument: null,
    });

    /* Case: DATA_SET */
    if (argTypeCdValue === ModuleExecutorParmsArgTypeCd.DATA_SET) {
      this.deleteGridDataset(i);
      this.setDataSetValidation(i);
    }
    /* Case: CONSTANT_DATASET */
    if (argTypeCdValue === ModuleExecutorParmsArgTypeCd.CONSTANT_DATASET) {
      this.deleteGridDataset(i);
      this.setConstDataSetValidation(i);
    }
  }

  onPeriodCodeChanges(e, value): void {
    if (this.selectedPeriodCd && this.moduleGroupManagementService.getNumberOfDatasets() > 1) {
      const openDialogConfirmPeriodCdChangeSubscription = this.openDialogConfirmPeriodCdChange().subscribe((resp) => {
        if (resp) {
          this.setPeriodCodeChange(value);
          this.moduleGroupManagementService.setSelectedPeriodCd(this.selectedPeriodCd);
        } else {
          e.source.writeValue(this.selectedPeriodCd);
        }
      });
      this.subscriptions.add(openDialogConfirmPeriodCdChangeSubscription);
    } else {
      this.setPeriodCodeChange(value);
      this.moduleGroupManagementService.setSelectedPeriodCd(this.selectedPeriodCd);
    }
  }

  onExtractNameChanges(index, parentDataExtractInstIdControl: AbstractControl): void {
    const argFormControl = this.getArgumentFormControl(index);
    const argTypeCdValue = argFormControl.get('argumentTypeCd').value;

    /* Case: DATA_SET */
    if (argTypeCdValue === ModuleExecutorParmsArgTypeCd.DATA_SET) {
      const dataExtract = this.datasetLists.dataExtracts[index].find(
        (obj) => obj.dataExtractInstId === parentDataExtractInstIdControl.value
      );
      argFormControl.patchValue({
        parentDatasetInstId: '',
        argumentId: dataExtract.argumentId,
      });
      this.setDataSetValidation(index);
      this.listDatasets(index, parentDataExtractInstIdControl.value, this.selectedPeriodCd);
      this.listDataExtracts(this.selectedPeriodCd);
    }

    /* Case: CONSTANT_DATASET */
    if (argTypeCdValue === ModuleExecutorParmsArgTypeCd.CONSTANT_DATASET) {
      const dataExtract = this.constDatasetLists.dataExtracts[index].find(
        (obj) => obj.dataExtractInstId === parentDataExtractInstIdControl.value
      );
      argFormControl.patchValue({
        parentDatasetInstId: '',
        argumentId: dataExtract.argumentId,
      });
      this.setConstDataSetValidation(index);
      this.listDatasets(index, parentDataExtractInstIdControl.value);
      this.listDataExtracts();
    }
  }

  onDataExtractChanges(index, parentDatasetInstIdControl: AbstractControl): void {
    const argFormControl = this.getArgumentFormControl(index);
    const argTypeCdValue = argFormControl.get('argumentTypeCd').value;

    if (parentDatasetInstIdControl.value !== '') {
      const gridDatasets =
        argTypeCdValue === ModuleExecutorParmsArgTypeCd.DATA_SET
          ? this.getGridDatasets(index)
          : this.getGridConstDatasets(index);
      const dataset = gridDatasets.find((obj) => obj.datasetInstId === parentDatasetInstIdControl.value);
      argFormControl.patchValue({
        argument: dataset.datasetLocation,
      });
    } else {
      argFormControl.patchValue({
        argument: null,
      });
    }
  }

  onAddArgument(): void {
    const newPos = this.addArgument();
    const argFormControl = this.getArgumentFormControl(newPos);
    if (argFormControl.get('argumentTypeCd').value === ModuleExecutorParmsArgTypeCd.DATA_SET) {
      this.setDataSetValidation(newPos);
    }
  }

  onDeleteArgument(index: number): void {
    const args = this.deleteArgument(index);
    if (args.length === 0) {
      this.addArgument(undefined, undefined, ModuleExecutorParmsArgTypeCd.CONFIGURATION);
    }
    this.setPeriodCdValToAllDataSetArguments(this.selectedPeriodCd);
    this.listDataExtracts(this.selectedPeriodCd);
  }

  getDynamicConfigArgumentByIndex(index: number) {
    const argFormControl = this.getArgumentFormControl(index);

    const argumentId = argFormControl.get('argumentId').value;
    const argumentSequenceNbr = argFormControl.get('argumentSequenceNbr').value;
    const dateTimestamp = argFormControl.get('dateTimestamp').value;

    const dynamicConfigArg = this.getDynamicConfigArgument(argumentId, argumentSequenceNbr, dateTimestamp);

    return dynamicConfigArg;
  }

  getDynamicConfigArgument(argumentId, argumentSequenceNbr, dateTimestamp): string {
    const newVal =
      argumentId + '_argSeqNbr' + argumentSequenceNbr + '_step' + this.nodeData.executionOrderNbr + '_' + dateTimestamp;

    return argumentId ? newVal : null;
  }

  onFormChanges(): void {
    this.getArgumentsFormArray().controls.forEach((control, index) => {
      this.checkAndSetControlChanges(control);
    });
  }

  onSelectedPeriodCdChanges() {
    const onSelectedPeriodCdChangeSubscription = this.moduleGroupManagementService
      .onSelectedPeriodCdChange()
      .subscribe((selectedPeriodCd: string) => {
        const listDataExtractsSubscription = this.listDataExtracts$(selectedPeriodCd).subscribe(() => {
          this.setPeriodCodeChange(selectedPeriodCd);
        });
        this.subscriptions.add(listDataExtractsSubscription);
      });
    this.subscriptions.add(onSelectedPeriodCdChangeSubscription);
  }

  openDialogConfirmPeriodCdChange(): Observable<boolean> {
    const confirmDialogConfig: XpoConfirmDialogConfig = {
      confirmButtonText: 'YES',
      confirmButtonColor: 'primary',
      rejectButtonText: 'NO',
      rejectButtonColor: 'accent',
      icon: 'warning',
      showCancelButton: false,
    };
    return this.confirmDialog.confirm(
      'Are you sure you want to continue?',
      'Period Code for all steps will change.',
      confirmDialogConfig
    );
  }
}
