import { cloneDeep } from 'lodash';
import { XpoFilterCriteria } from '../../filters/models/filter-criteria-type';
import { XpoBoardView } from '../board-view.model';
import { XpoGridBoardColumn } from './grid-board-column.model';
import { XpoGridBoardState } from './grid-board-state.model';
import { XpoGridBoardViewConfig, XpoGridBoardVisibleColumn } from './grid-board-view-config.model';
import { XpoGridBoardViewTemplate } from './grid-board-view-template.model';
import { XpoGridBoardViewUtils } from './grid-board-view.utils';

export class XpoGridBoardView<T extends XpoGridBoardViewConfig = XpoGridBoardViewConfig> extends XpoBoardView<T> {
  visibleColumns: XpoGridBoardColumn[];

  constructor(public template: XpoGridBoardViewTemplate, config: T) {
    super(template, config);

    this.visibleColumns =
      config.visibleColumns && config.visibleColumns.length
        ? XpoGridBoardViewUtils.generateVisibleColumns(this.template, config)
        : [];
  }

  getState(criteria?: XpoFilterCriteria): XpoGridBoardState {
    const state = <XpoGridBoardState>super.getState(criteria);
    // Using cloneDeep below because availableColumn could contains functions which breaks JSON.parse(JSON.stringify())
    // If we don't copy the availableColumns deep the children refs are being shared among views
    // As lodash is already installed and used in the app is better to use cloneDeep than create a custom method
    state.visibleColumns = this.visibleColumns.length
      ? XpoGridBoardViewUtils.oldViewsAdapter(this.template, this.visibleColumns)
      : cloneDeep(this.template.availableColumns);
    return state;
  }

  toViewConfig(state?: XpoGridBoardState): XpoGridBoardViewConfig {
    // if visible columns are the exact same length as available columns
    // don't persist the data because, it means columns were not customized
    const columns = XpoGridBoardViewUtils.wereColumnsUpdated(state, this.template) ? state.visibleColumns || [] : [];

    return {
      ...super.toViewConfig(state),
      visibleColumns: columns.map(
        ({ colId, headerName, width, children, pinned, groupId }): XpoGridBoardVisibleColumn => ({
          colId,
          headerName,
          width,
          pinned,
          groupId,
          children:
            children && children.length
              ? children.map((child) => ({
                  colId: child.colId,
                  headerName: child.headerName,
                  width: child.width,
                  pinned: child.pinned,
                }))
              : undefined,
        })
      ),
    } as XpoGridBoardViewConfig;
  }
}
