import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { MatBadgeModule } from '@angular/material/badge';
import { XpoBadgeModule } from '../badge/badge.module';
import { XpoLtlPopoverModule } from '../xpo-ltl-popover/index';
import { XpoNotificationPopover } from './notification-popover.component';

@NgModule({
  imports: [CommonModule, XpoLtlPopoverModule, MatButtonModule, XpoBadgeModule, MatBadgeModule, MatIconModule],
  declarations: [XpoNotificationPopover],
  exports: [XpoNotificationPopover, XpoBadgeModule, MatBadgeModule, MatIconModule],
})
export class XpoNotificationPopoverModule {}
