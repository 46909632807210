import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanActivate } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { User } from '@xpo-ltl/sdk-common';
import { get as _get, includes as _includes } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, delay, retryWhen, take } from 'rxjs/operators';
import { XpoLtlLoggedInUserService } from '../../services/logged-in-user/logged-in-user.service';
import { XpoLtlConfirmEnvironmentComponent } from './confirm-environment/confirm-environment.component';

/**
 * Config properties used by this service
 */
enum ConfigManagerProperties {
  production = 'production',
  loggedInUserRoot = 'loggedInUserRoot',
}

const ENVIRONMENT_PROD = 'PROD';
const ENVIRONMENT_TEST = 'TEST';

const DEFAULT_ACCESS = 'LTL_DEFAULT_ACCESS_PROD';

/**
 * Block user from continuing if they do not have access rights for the specified environment.
 * User can continue if they specifically type in the environment name, forcing them to understand
 * which environment they are operating in.
 */
@Injectable({
  providedIn: 'root',
})
export class XpoLtlEnvironmentAndRoleGuard implements CanActivate {
  private environment: string;
  private isProduction = false;

  constructor(
    private loggedInUserService: XpoLtlLoggedInUserService,
    private configManagerService: ConfigManagerService,
    private dialog: MatDialog
  ) {
    this.isProduction = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
    this.environment = this.isProduction ? ENVIRONMENT_PROD : ENVIRONMENT_TEST;
  }

  canActivate(): Observable<boolean> {
    return new Observable((observer) => {
      this.loggedInUserService
        .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
        .pipe(
          retryWhen((errors) => errors.pipe(delay(1000), take(5))),
          catchError((error) => {
            console.error('XpoLtlEnvironmentAndRoleGuard error retrieving logged in user: ', error);
            return of({ ...new User(), roles: [] });
          })
        )
        .subscribe((user: User) => {
          const roles = _get(user, 'roles', []);
          const hasProdAccess = roles.findIndex((r) => _includes(r.toUpperCase(), DEFAULT_ACCESS)) >= 0;

          if ((!this.isProduction && hasProdAccess) || (this.isProduction && !hasProdAccess)) {
            // user either is in PROD and does not have PROD access, or in TEST but has PROD access.
            this.displayWarning(this.environment).subscribe((confirm: boolean) => {
              observer.next(confirm);
              observer.complete();
            });
          } else {
            // valid environment for the user
            observer.next(true);
            observer.complete();
          }
        });
    });
  }

  private displayWarning(environment: string): Observable<boolean> {
    return new Observable((observer) => {
      const dialogRef = this.dialog.open(XpoLtlConfirmEnvironmentComponent, {
        data: { environment },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((confirm: boolean) => {
        observer.next(true);
        observer.complete();
      });
    });
  }
}
