import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';

import { XpoTabsModule } from '../tabs/index';
import { XpoTabDrawerContent, XpoTabsDrawer } from './tab-drawer';

@NgModule({
  declarations: [XpoTabDrawerContent, XpoTabsDrawer],
  imports: [CommonModule, MatSidenavModule, MatIconModule],
  exports: [XpoTabDrawerContent, XpoTabsDrawer, XpoTabsModule],
})
export class XpoTabDrawerModule {}
