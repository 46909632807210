import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ContextualHeaderItems } from '../contextual-header-config';

@Component({
  selector: 'xpo-contextual-header-items',
  templateUrl: './contextual-header-items.component.html',
  styleUrls: ['./contextual-header-items.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-HeaderItems',
  },
})
export class ContextualHeaderItemsComponent {
  @Input() itemList: ContextualHeaderItems;
}
