import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { XpoFilterComponentBase, XpoFilterCriteria } from '../models/index';
import { XpoSelectFilter } from './select-filter';

@Component({
  selector: 'xpo-select-filter',
  templateUrl: 'select-filter.component.html',
  styleUrls: ['select-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-SelectFilter', '[attr.id]': 'id' },
})
export class XpoSelectFilterComponent extends XpoFilterComponentBase<XpoSelectFilter> {
  selection: string[] | string | any;

  constructor() {
    super();
  }

  optionsSelected(fieldValues: string[]): void {
    this.selection = fieldValues;
    this.storeCriteria(fieldValues);
  }

  protected initialize(): void {}

  protected onCriteriaModified(fieldValue: string[] | string | any, criteria: XpoFilterCriteria): void {
    this.selection = fieldValue;
  }
}
