
export enum MatchedPartyStatusCd {
	DERIVED_BILL_TO = 'DerivedBillTo',
	INVALID_ADDRESS = 'InvalidAddress',
	MCH = 'Mch',
	NO_DERIVED_BILL_TO = 'NoDerivedBillTo',
	NO_DERIVED_BILL_TO_SELF_INV = 'NoDerivedBillToSelfInv',
	POSSIBLES_FOUND = 'PossiblesFound',
	UN_MCH = 'UnMch'}

