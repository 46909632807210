import {CustomerTypeOfSvcIndicatorCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerTypeOfSvcIndicatorCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerTypeOfSvcIndicatorCd ) {
      this._values.push(CustomerTypeOfSvcIndicatorCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerTypeOfSvcIndicatorCd {

    for ( const obj in CustomerTypeOfSvcIndicatorCd ) {
      if (CustomerTypeOfSvcIndicatorCd[obj] === value){
        return CustomerTypeOfSvcIndicatorCd[obj] as CustomerTypeOfSvcIndicatorCd;
      }
    }
  }
}

const CustomerTypeOfSvcIndicatorCdHelper = new CustomerTypeOfSvcIndicatorCdHelperEnumHelperClass();
export default CustomerTypeOfSvcIndicatorCdHelper;
