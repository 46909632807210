import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { XpoHeaderAppName, XpoHeaderSubAppName } from '../header/header.component';

export interface XpoShellRoute {
  label: string;
  path: string;
  children?: XpoShellRoute[];
  queryParamsHandling?: string;
  openInNewTab?: boolean;
}

@Directive({
  selector: 'xpo-shell-sidebar-actions',
  host: { class: 'xpo-Shell-sidebar-actions' },
})
export class XpoShellSidebarActions {}

@Component({
  selector: 'xpo-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-Shell' }
})
export class XpoShell implements OnInit, OnDestroy {
  /** Whether or not we're in tablet mode or not */
  isTablet = false;

  /** Observable Cleanup */
  private destroyed$ = new Subject<void>();

  /** The routes for the application */
  @Input()
  get routes(): XpoShellRoute[] {
    return this.routesValue;
  }
  set routes(value: XpoShellRoute[]) {
    this.routesValue = value;
    // When routes are received, open drawer if the its not set to drawerClosedByDefault
    if (!this.drawerClosedByDefault) {
      this.isDrawerOpen = !this.isTablet && this.hasRoutes;
    }
  }
  private routesValue: XpoShellRoute[] = [];

  /** Whether or not the sidebar should be closed by default */
  @Input()
  get drawerClosedByDefault(): boolean {
    return this.drawerClosedByDefaultValue;
  }
  set drawerClosedByDefault(value: boolean) {
    this.drawerClosedByDefaultValue = coerceBooleanProperty(value);
    if (this.drawerClosedByDefaultValue) {
      this.isDrawerOpen = false;
    }
  }
  private drawerClosedByDefaultValue = false;

  /** The version number of the application */
  @Input()
  get versionNumber(): string {
    return this.versionNumberValue;
  }
  set versionNumber(value: string) {
    this.versionNumberValue = value;
  }
  private versionNumberValue: string;

  /** Whether or not the drawer is open */
  @Input()
  get isDrawerOpen(): boolean {
    return this.isDrawerOpenValue;
  }
  set isDrawerOpen(value: boolean) {
    const v = coerceBooleanProperty(value);
    if (v !== this.isDrawerOpenValue) {
      this.isDrawerOpenValue = v;
      this.isDrawerOpenChange.emit(v);
    }
  }
  private isDrawerOpenValue = true;

  @Output()
  isDrawerOpenChange = new EventEmitter<boolean>();

  /** The path to the logo (assets/foo/logo.png) */
  @Input()
  logoPath: string;

  /** The width of the logo. Defaults to 126px */
  @Input()
  logoWidth: string = '126px';


  get hasRoutes(): boolean {
    return !!this.routes && !!this.routes.length;
  }

  /**
   * The application name component rendered inside the header by content projection
   */
  @ContentChild (XpoHeaderAppName) protected appNameComponent: XpoHeaderAppName;

  /**
   * Returns the application name
   */
  get appName(): string {
    // get application name from application name child component
    return this.appNameComponent ? this.appNameComponent.appName : '';
  }

  /**
   * The application sub-name component rendered inside the header by content projection
   */
  @ContentChild (XpoHeaderSubAppName) protected appSubNameComponent: XpoHeaderSubAppName;

  /**
   * Returns the application sub-name
   */
  get appSubName(): string {
    // get application sub-name from application name child component
    return this.appSubNameComponent ? this.appSubNameComponent.appSubName : '';
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private changeDetectionRef: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscribeToBreakpointChanges();
    this.subscribeToNavigationEndChanges();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /** When a link is clicked when in tablet mode, close the drawer */
  handleLinkClick(): void {
    if (this.isTablet) {
      this.isDrawerOpen = false;
    }
  }

  /** Open the drawer if the drawer is closed and if there is routes available */
  onMenuClick(): void {
    this.isDrawerOpen = this.hasRoutes && !this.isDrawerOpen;
  }

  private subscribeToBreakpointChanges(): void {
    // If viewport is less than a tablet, hide the drawer on default
    this.breakpointObserver
      .observe('(max-width: 1024px)') // FIXME: width width width
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.isTablet = result.matches;
        /**
         * Open drawer when the view is not the size of a tablet, there is
         * routes available, and if the shell is not set to have the
         * menu closed on default
         */
        this.isDrawerOpen = !this.isTablet && this.hasRoutes && !this.drawerClosedByDefault;
        this.changeDetectionRef.markForCheck();
      });
  }

  /**
   * Since some routes are lazy loaded, `routerLinkActive` does
   * not get updated to make the link look active, Need to manually
   * trigger change detection when a navigation end event triggers.
   * https://github.com/angular/angular/issues/19934
   */
  private subscribeToNavigationEndChanges(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.changeDetectionRef.markForCheck());
  }
}
