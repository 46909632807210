import {CustomerCreditStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerCreditStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerCreditStatusCd ) {
      this._values.push(CustomerCreditStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerCreditStatusCd {

    for ( const obj in CustomerCreditStatusCd ) {
      if (CustomerCreditStatusCd[obj] === value){
        return CustomerCreditStatusCd[obj] as CustomerCreditStatusCd;
      }
    }
  }
}

const CustomerCreditStatusCdHelper = new CustomerCreditStatusCdHelperEnumHelperClass();
export default CustomerCreditStatusCdHelper;
