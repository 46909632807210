import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MiscLineItemCd } from '@xpo-ltl/sdk-common';
import { GetOdsShipmentResp } from '@xpo-ltl/sdk-shipmentods';
import { defaultTo as _defaultTo, get as _get, map as _map, reduce as _reduce } from 'lodash';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '../../../classes/unsubscriber';
import { CommodityClassCdPipe } from '../../../pipes/commodity-class-cd/commodity-class-cd.pipe';
import { CurrencyCodePipe } from '../../../pipes/currency-code/currency-code.pipe';
import { ShipmentReferenceService } from '../../shipment-reference.service';

interface LineItemData {
  pieces: number;
  description: string;
  classType: string;
  asRatedClassCd: string;
  nmfc: string;
  weight: number;
  rate: number;
  charges: number;
}

@Component({
  selector: 'xpo-ltl-shipment-details-line-items',
  templateUrl: './shipment-details-line-items.component.html',
  styleUrls: ['./shipment-details-line-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentDetailsLineItemsComponent implements OnInit, OnDestroy {
  @Input() hideCharges: boolean = false;
  @Output() toggleDimensions = new EventEmitter<boolean>();

  private unsubscriber = new Unsubscriber();

  columns = [
    { id: 'pieces', label: 'Pieces' },
    { id: 'description', label: 'Description' },
    { id: 'classType', label: 'Class', renderer: (row) => this.commodityClassCdPipe.transform(row.classType) },
    {
      id: 'asRatedClassCd',
      label: 'Rate Class',
      renderer: (row) => this.commodityClassCdPipe.transform(row.asRatedClassCd),
    },
    { id: 'nmfc', label: 'NMFC' },
    { id: 'weight', label: 'Weight' },
  ];
  columnsToDisplay: string[] = [];

  dataSource: MatTableDataSource<LineItemData> = new MatTableDataSource(undefined);
  showDimensions = false;

  constructor(
    private shipmentRefService: ShipmentReferenceService,
    private currencyPipe: CurrencyPipe,
    private commodityClassCdPipe: CommodityClassCdPipe,
    private currencyCdPipe: CurrencyCodePipe
  ) {}

  ngOnInit() {
    // add charges to display if we aren't hiding them
    if (!this.hideCharges) {
      this.columns.push({ id: 'rate', label: 'Rate', renderer: (row) => this.currencyPipe.transform(row.rate) });
      this.columns.push({
        id: 'charges',
        label: 'Charges',
        renderer: (row) => `${this.currencyPipe.transform(row.charges)} ${row.currency || ''}`,
      });
    }
    this.columnsToDisplay = _map(this.columns, (c) => c.id);

    this.shipmentRefService.shipment$
      .pipe(takeUntil(this.unsubscriber.done$), distinctUntilChanged())
      .subscribe((shipment) => {
        this.updateFormFromShipment(shipment);
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  handleToggleDimensions() {
    this.showDimensions = !this.showDimensions;
    this.toggleDimensions.emit(this.showDimensions);
  }

  private updateFormFromShipment(shipment: GetOdsShipmentResp) {
    if (!shipment) {
      return;
    }

    let data;
    data = _map(shipment.commodity, (commodity) => {
      return {
        pieces: commodity.piecesCount,
        description: commodity.description,
        classType: commodity.classType,
        asRatedClassCd: commodity.asRatedClassCd,
        nmfc: commodity.nmfcItemCd,
        weight: commodity.weightLbs,
        rate: commodity.tariffsRate,
        charges: commodity.amount,
        currency: null,
      };
    });

    const accessorials = _map(shipment.accessorialService, (accessorial) => {
      return {
        description: accessorial.description,
        rate: accessorial.tariffsRate,
        charges: accessorial.amount,
        currency: null,
      };
    });

    const miscLineItems = _map(shipment.miscLineItem, (miscLineItem) => {
      return {
        description: miscLineItem.description,
        rate: miscLineItem.tariffsRate,
        charges: miscLineItem.amount,
        currency: null,
        lineTypeCd: miscLineItem.lineTypeCd,
      };
    });

    data.push(...accessorials, ...miscLineItems);

    // calculate total and add to end of list
    const totals = _reduce(
      data,
      (total, value) => {
        const lineTypeCd = _get(value, 'lineTypeCd', undefined);
        const hasDiscount = !!lineTypeCd && lineTypeCd === MiscLineItemCd.DISC_LN;

        return {
          pieces: total.pieces + _defaultTo(value.pieces, 0),
          charges: total.charges + _defaultTo(hasDiscount ? -value.charges : value.charges, 0),
        };
      },
      { pieces: 0, charges: 0 }
    );

    data.push({
      pieces: totals.pieces,
      description: 'TOTAL',
      classType: undefined,
      asRatedClassCd: undefined,
      nmfc: undefined,
      weight: undefined,
      rate: undefined,
      charges: totals.charges,
      currency: this.currencyCdPipe.transform(shipment.shipment.invoicingCurrencyCd),
    });

    // update the datasource with the data
    this.dataSource.data = data;
  }
}
