export class WeekDays {
  constructor(
    public MON?: boolean,
    public TUE?: boolean,
    public WED?: boolean,
    public THU?: boolean,
    public FRI?: boolean,
    public SAT?: boolean,
    public SUN?: boolean
  ) {
    this.MON = false;
    this.TUE = false;
    this.WED = false;
    this.THU = false;
    this.FRI = false;
    this.SAT = false;
    this.SUN = false;
  }
}

export class StartTimeGroup {
  constructor(
    public manualLaunch?: string,
    public startTime?: string,
    public weekDays?: WeekDays,
    public customCronExpression?: string,
    public monthlyDays?: string
  ) {
    this.manualLaunch = '';
    this.startTime = '';
    this.weekDays = new WeekDays();
    this.customCronExpression = '';
    this.monthlyDays = '';
  }
}
