import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryQualifierReasonCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'deliveryQualifierReasonCd',
})
export class DeliveryQualifierReasonCdPipe implements PipeTransform {
  transform(value: DeliveryQualifierReasonCd): string {
    switch (value) {
      case DeliveryQualifierReasonCd.ATTEMPT_CONSIGNEE_RELATED:
        return 'Consignee related';
      case DeliveryQualifierReasonCd.ATTEMPT_SHIPPER_RELATED:
        return 'Shipper related';
      case DeliveryQualifierReasonCd.BROKER_CLOSED:
        return 'Broker closed';
      case DeliveryQualifierReasonCd.CASH_NOT_AVAILABLE:
        return 'Cash not available';
      case DeliveryQualifierReasonCd.CLIENT_CLEAR:
        return 'Client will clear shipment';
      case DeliveryQualifierReasonCd.CLIENT_CREDIT:
        return 'Client has credit issues';
      case DeliveryQualifierReasonCd.CLOSED_HOLIDAY:
        return 'Closed for holiday';
      case DeliveryQualifierReasonCd.CLOSED_INVENTORY:
        return 'Closed for inventory';
      case DeliveryQualifierReasonCd.COD_REQUIRED:
        return 'COD required';
      case DeliveryQualifierReasonCd.CREDIT_HOLD:
        return 'Credit hold';
      case DeliveryQualifierReasonCd.CUSTOMER_REQUESTED_CONSOLIDATION:
        return 'Customer requested consolidation';
      case DeliveryQualifierReasonCd.CUSTOMER_REQUEST_FUTURE_DELIVERY:
        return 'Customer requested future delivery';
      case DeliveryQualifierReasonCd.CUSTOMER_SHUTDOWN:
        return 'Customer shutdown';
      case DeliveryQualifierReasonCd.CUSTOMER_STRIKE:
        return 'Customer strike';
      case DeliveryQualifierReasonCd.CUSTOMS_IMPORT_EXPORT:
        return 'Customs import/export';
      case DeliveryQualifierReasonCd.DRIVER_RELATED:
        return 'Driver related';
      case DeliveryQualifierReasonCd.FAILED_PARS:
        return 'Failed PARS';
      case DeliveryQualifierReasonCd.FINAL_CONSIGNEE_RELATED:
        return 'Consignee related';
      case DeliveryQualifierReasonCd.FINAL_SHIPPER_RELATED:
        return 'Shipper related';
      case DeliveryQualifierReasonCd.GOVERNMENT_DOCS_REQUIRED:
        return 'Government docs required';
      case DeliveryQualifierReasonCd.HELD_FOR_PROTECTIVE_SERVICE:
        return 'Held for protective service';
      case DeliveryQualifierReasonCd.IMPROPER_UNLOAD_EQUIPMENT:
        return 'Improper unload equipment';
      case DeliveryQualifierReasonCd.INCORRECT_BROKER:
        return 'Incorrect broker';
      case DeliveryQualifierReasonCd.INFO_MISSING:
        return 'Info missing';
      case DeliveryQualifierReasonCd.INSUFFICIENT_DELIVERY_TIME:
        return 'Insufficient delivery time';
      case DeliveryQualifierReasonCd.INSUFFICIENT_TIME_TO_COMPLETE_DELIVERY:
        return 'Insufficient time to complete delivery';
      case DeliveryQualifierReasonCd.INT_NON_CARRIER_DELIVERY:
        return 'International non carrier delivery';
      case DeliveryQualifierReasonCd.MECHANICAL_BREAKDOWN:
        return 'Mechanical breakdown';
      case DeliveryQualifierReasonCd.MISSED_DELIVERY:
        return 'Missed delivery';
      case DeliveryQualifierReasonCd.NO_BROKER:
        return 'No Broker';
      case DeliveryQualifierReasonCd.OTHER:
        return 'Other';
      case DeliveryQualifierReasonCd.OTHER_CARRIER_RELATED_REASON:
        return 'Other carrier related reason';
      case DeliveryQualifierReasonCd.PACKAGE_MIS_SORTED:
        return 'Package mis-sorted';
      case DeliveryQualifierReasonCd.PAPERWORK_ISSUE:
        return 'Paperwork issue';
      case DeliveryQualifierReasonCd.PREVIOUS_STOP_CAUSED_DELAY:
        return 'Previous stop caused delay';
      case DeliveryQualifierReasonCd.RECEIVING_TIMES_RESTRICTED:
        return 'Receiving times restricted';
      case DeliveryQualifierReasonCd.RECIPIENT_UNAVAILABLE:
        return 'Recipient unavailable';
      case DeliveryQualifierReasonCd.RESIDENTIAL_NOT_HOME:
        return 'Residential consignee not at home';
      case DeliveryQualifierReasonCd.RETURN_SHIPPER_RELATED:
        return 'Return shipper related';
      case DeliveryQualifierReasonCd.WAITING_INSPECTION:
        return 'Waiting inspection';
      case DeliveryQualifierReasonCd.WRONG_PRO:
        return 'Wrong PRO';
      case DeliveryQualifierReasonCd.XPO_RELATED:
        return 'XPO related';
    }
  }
}

@Pipe({
  name: 'attemptReasonCd',
})
export class AttemptReasonCdPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case '1':
        return 'Residential consignee not at home';
      case '2':
        return 'Closed for holiday';
      case '3':
        return 'Closed for inventory';
      case '4':
        return 'Customer requested future delivery';
      case '5':
        return 'Consignee related';
      case '6':
        return 'Shipper related';
      case '7':
        return 'Recipient unavailable — delivery delayed';
      case '8':
        return 'Improper unloading facility or equipment';
      case '9':
        return 'Credit hold';
      case 'A':
        return 'Customer on shutdown';
      case 'B':
        return 'Customer strike';
      case 'C':
        return 'COD required';
      case 'D':
        return 'Cash not available from consignee';
    }
  }
}

@Pipe({
  name: 'returnReasonCd',
})
export class ReturnReasonCdPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case '1':
        return 'Driver related';
      case '2':
        return 'Insufficient time to complete delivery';
      case '3':
        return 'Mechanical breakdown';
      case '4':
        return 'Missed delivery';
      case '5':
        return 'Other carrier-related reason';
      case '6':
        return 'Package mis-sorted';
      case '7':
        return 'Previous stop caused delay';
      case '8':
        return 'Shipper related';
    }
  }
}

@Pipe({
  name: 'refusedReasonCd',
})
export class RefusedReasonCdPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case '1':
        return 'Unauthorized return';
      case '2':
        return `Consignee won't accept freight collect`;
      case '3':
        return 'Arrived too late';
      case 'A':
        return 'Arrived too early';
      case '4':
        return 'Order cancelled';
      case '5':
        return 'Did not order';
      case '6':
        return 'Wrong order';
      case 'B':
        return 'Duplicate order';
      case '7':
        return 'Customer wanted earlier';
      case '8':
        return 'Insufficient delivery time';
      case '9':
        return `Manufacturer's defect`;
    }
  }
}

@Pipe({
  name: 'undeliveredReasonCd',
})
export class UndeliveredReasonCdPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case '1':
        return 'Construction site, not deliverable within 2 days';
      case '2':
        return 'No response to arrival notification after 2 working days';
      case '3':
        return 'Consignee involved in labor dispute';
      case '4':
        return 'Consignee cannot be located at address or phone number shown';
      case '5':
        return 'Consignee has moved';
      case '6':
        return 'Credit Hold';
      case '7':
        return 'Suspended at Customer Request';
      case '8':
        return 'Insufficient Delivery Time';
      case 'Z':
        return 'Other';
    }
  }
}
