import {EventLogTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class EventLogTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EventLogTypeCd ) {
      this._values.push(EventLogTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EventLogTypeCd {

    for ( const obj in EventLogTypeCd ) {
      if (EventLogTypeCd[obj] === value){
        return EventLogTypeCd[obj] as EventLogTypeCd;
      }
    }
  }
}

const EventLogTypeCdHelper = new EventLogTypeCdHelperEnumHelperClass();
export default EventLogTypeCdHelper;
