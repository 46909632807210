import {DynamicPriceLaneTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DynamicPriceLaneTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DynamicPriceLaneTypeCd ) {
      this._values.push(DynamicPriceLaneTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DynamicPriceLaneTypeCd {

    for ( const obj in DynamicPriceLaneTypeCd ) {
      if (DynamicPriceLaneTypeCd[obj] === value){
        return DynamicPriceLaneTypeCd[obj] as DynamicPriceLaneTypeCd;
      }
    }
  }
}

const DynamicPriceLaneTypeCdHelper = new DynamicPriceLaneTypeCdHelperEnumHelperClass();
export default DynamicPriceLaneTypeCdHelper;
