import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';

/* XPO */
import {
  XpoButtonModule,
  XpoCommonModule,
  XpoContextualHeaderModule,
  XpoDialogModule,
  XpoIconModule,
  XpoSelectModule,
  XpoStatusIndicatorModule,
  XpoTooltipModule,
} from '@xpo-ltl/ngx-ltl-core';

/* Components */
import { ModuleGroupManagementComponent } from './module-group-management.component';

/* Modules */
import { ModuleGroupMgmtAssociatedModulesModule } from './components/associated-modules/module-group-mgmt-associated-modules.module';
import { ModuleGroupMgmtExecutionStepsModule } from './components/execution-steps/module-group-mgmt-execution-steps.module';
import { ModuleGroupMgmtInfoModule } from './components/info/module-group-mgmt-info.module';
import { ListModulesService } from './services/list-modules.service';
import { ListModulesStoreService } from './services/list-modules.store.service';

/* Services */
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DynamicDAGScriptDialogComponent, DynamicDAGScriptDialogErrorComponent } from './dialogs/index';
import { ModuleGroupManagementService } from './services/module-group-management.service';
import { ModuleGroupMgmtAssociatedModulesService } from './services/module-group-mgmt-associated-modules.service';

/* Guards */
import { ConfirmDeactivateGuard } from './guards/confirm-deactivate.guard';

@NgModule({
  declarations: [ModuleGroupManagementComponent, DynamicDAGScriptDialogComponent, DynamicDAGScriptDialogErrorComponent],
  imports: [
    CommonModule,
    XpoCommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    XpoContextualHeaderModule,
    XpoContextualHeaderModule,
    XpoDialogModule,
    XpoIconModule,
    XpoSelectModule,
    XpoButtonModule,
    XpoStatusIndicatorModule,
    XpoTooltipModule,
    ModuleGroupMgmtInfoModule,
    ModuleGroupMgmtAssociatedModulesModule,
    ModuleGroupMgmtExecutionStepsModule,
  ],
  entryComponents: [DynamicDAGScriptDialogComponent, DynamicDAGScriptDialogErrorComponent],
  exports: [ModuleGroupManagementComponent],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    ListModulesService,
    ListModulesStoreService,
    ModuleGroupManagementService,
    ModuleGroupMgmtAssociatedModulesService,
    ConfirmDeactivateGuard,
  ],
})
export class ModuleGroupManagementModule {}
