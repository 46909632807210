import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MAT_CHECKBOX_CLICK_ACTION } from '@angular/material/checkbox';
import { XpoBoardConsumer, XpoBoardDataSourceResolver } from '@xpo-ltl/ngx-ltl-board';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { AgEvent, GridApi, IHeaderParams, RowNode, SelectionChangedEvent } from 'ag-grid-community';
import { filter, isEqual } from 'lodash-es';
import { SelectAllState } from './select-all-state.class';

@Component({
  selector: 'xpo-ag-grid-paginated-select-all-checkbox',
  templateUrl: 'ag-grid-select-all.component.html',
  styleUrls: ['ag-grid-select-all.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'xpo-AgGridPaginatedSelectAllCheckbox', 'data-cy': 'select-all-checkbox' },
  providers: [{ provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'noop' }],
})
export class XpoAgGridSelectAllCheckbox extends XpoBoardConsumer implements IHeaderAngularComp, OnDestroy {
  readonly SELECTION_CHANGE_SELECT_NAME = 'SELECTION-CHANGE';
  readonly EVENT_SELECTION_CHANGED_NAME = 'selectionChanged';
  readonly EVENT_PAGINATION_CHANGED_NAME = 'paginationChanged';
  readonly EVENT_FILTER_CHANGED_NAME = 'filterChanged';
  readonly CLIENT_SIDE_ID = 'clientSide';
  checked: boolean = false;
  indeterminate: boolean = false;
  private cachedSelection: RowNode[] = [];
  private selectAllState: SelectAllState;
  private gridApi: GridApi;
  private selectionChangedDispatchedByUs: boolean;
  private isDetailGrid: boolean;
  private cachedFilters: any;
  // TODO: Improve this type
  private columnController: any;
  private nodeIteratorName: string;

  constructor(boardDataSourceResolver: XpoBoardDataSourceResolver, cd: ChangeDetectorRef) {
    super(boardDataSourceResolver, cd);
  }

  protected onStateChange(state): void {}

  agInit(params: any): void {
    this.gridApi = params.api;
    this.columnController = params.api.columnController;
    this.isDetailGrid = params.isDetailGrid;
    this.setKeyField(params.keyField);
    this.gridApi.addEventListener(this.EVENT_SELECTION_CHANGED_NAME, this.selectionChangeListener);
    this.gridApi.addEventListener(this.EVENT_PAGINATION_CHANGED_NAME, this.paginationChangedListener);
    this.gridApi.addEventListener(this.EVENT_FILTER_CHANGED_NAME, this.filterChangedListener);

    // We have to do this because ag-grid has different node iterators for client-side and non client-side row model types
    this.nodeIteratorName =
      this.gridApi.getModel().getType() === this.CLIENT_SIDE_ID ? 'forEachNodeAfterFilter' : 'forEachNode';

    this.selectionChangeListener();
  }

  handleCheckboxChange(): void {
    this.selectionChangedDispatchedByUs = true;
    this.checked || this.indeterminate ? this.unselectAll() : this.selectAll();
    this.dispatchCustomEvent();
  }

  refresh(params: IHeaderParams): boolean {
    return !!params;
  }

  private selectionChangeListener = () => {
    if (!this.selectionChangedDispatchedByUs) {
      this.updateState();
      this.updateSelection();
    }

    this.selectionChangedDispatchedByUs = false;
  }
  private updateSelection(): void {
    // If all visible nodes are selected but not all data-set is selected, mark selected all checkbox
    if (this.areAllVisibleSelected()) {
      this.selectAllVisible();
      return;
    }

    // If all data-set is selected then go to select-all state
    if (this.isAllDataSetSelected()) {
      this.selectAll();
      return;
    }

    // If all visible nodes are blank then reset the select all checkbox
    if (this.areAllVisibleBlank()) {
      this.unselectAll();
      return;
    }

    // If not all nodes are blank, nor all dataset is selected, nor all visible nodes are selected,
    // then fallback to intermediate
    this.setToIndeterminate();
  }

  /**
   * Return true only if all data-set is selected
   */
  private isAllDataSetSelected(): boolean {
    const totalRowsCount = this.getSelectableRowCount();
    const totalRowsSelected = this.cachedSelection.length;
    return totalRowsCount && totalRowsCount === totalRowsSelected;
  }

  /**
   * Return true if all visible nodes are selected
   */
  private areAllVisibleSelected(): boolean {
    const totalRowsCount = this.getSelectableRowCount();
    if (!totalRowsCount) {
      return false;
    }
    let allVisibleSelected = true;
    // TODO: Unfortunately ag-grid does not provide a better use of the filtered nodes, need to do this old school stuff.
    let filteredNodesCount = 0;
    if (this.gridApi) {
      this.gridApi[this.nodeIteratorName]((node: RowNode) => {
        filteredNodesCount++;
        if (!node.isSelected()) {
          allVisibleSelected = false;
        }
      });
    }
    return allVisibleSelected && filteredNodesCount > 0;
  }
  /**
   * Return true if all visible nodes are not selected
   */
  private areAllVisibleBlank(): boolean {
    let someIsSelected = false;

    if (this.gridApi) {
      this.gridApi[this.nodeIteratorName]((node: RowNode) => {
        if (node.isSelected()) {
          someIsSelected = true;
        }
      });
    }
    return !someIsSelected;
  }

  // It's not a performing method but is safer than keep a qty on init and update it when filters are applied
  private getSelectableRowCount(): number {
    let count = 0;
    // Can't reduce this logic with a filter because is an ag-grid custom one
    this.gridApi.forEachNode((node: RowNode) => {
      // Group and detail rows haven't data. Can't check for selectable boolean because can be true even with no checkbox
      // Can't check for row type either because some row groups can have checkboxes
      if (node.data) {
        count++;
      }
    });

    return count;
  }

  private paginationChangedListener = () => {
    // if select all is active, then if the user goes on to the next page, select those nodes as well
    if (this.selectAllState.all) {
      this.setNodesSelection(true, false);
    }
  }

  private filterChangedListener = (param) => {
    // TODO: Need to test this with server side and probably do some small refactor NGXLTL-912
    /**
     * This listener is dispatched with filters coming from the master grid or any of his child so the
     * following if statement compare the stored filters to detect if the changes comes from this grid or other.
     * If came from this, clear selection
     */
    const newFilters = param.api.getFilterModel();
    if (!isEqual(this.cachedFilters, newFilters)) {
      this.dispatchCustomEvent();
    }
    this.cachedFilters = newFilters;
    this.updateSelection();
  }

  private dispatchCustomEvent(): void {
    const event: AgEvent | SelectionChangedEvent = {
      type: this.EVENT_SELECTION_CHANGED_NAME,
      api: this.gridApi,
    };
    this.selectionChangedDispatchedByUs = true;
    this.gridApi.dispatchEvent(event);
  }

  private setToIndeterminate(): void {
    this.checked = false;
    this.indeterminate = true;
    this.cd.markForCheck();
    this.emitSelectionChange();
  }

  private setToChecked(): void {
    this.checked = true;
    this.indeterminate = false;
  }

  private setToNoneSelected(): void {
    this.checked = false;
    this.indeterminate = false;
  }

  private selectAll(): void {
    // We can use setNodesSelectionFiltered only with client-side row model type by a limitations of ag-grid itself
    this.gridApi.getModel().getType() === this.CLIENT_SIDE_ID
      ? this.setNodesSelectionFiltered(true, false)
      : this.setNodesSelection(true, false);

    if (!this.areFiltersApplied()) {
      this.selectAllState.selectAll();
    }
    this.setToChecked();
    this.cd.markForCheck();
    this.emitSelectionChange();
  }

  private selectAllVisible(): void {
    this.setToChecked();
    this.cd.markForCheck();
    this.emitSelectionChange();
  }

  private unselectAll(): void {
    this.setToNoneSelected();
    if (this.areFiltersApplied()) {
      this.unselectAllCurrentNodes();
    } else {
      this.selectAllState.reset();
      this.setNodesSelection(false, false);
    }
    this.cd.markForCheck();
    this.emitSelectionChange();
  }

  private unselectAllCurrentNodes(): void {
    if (this.gridApi) {
      this.gridApi[this.nodeIteratorName]((node: RowNode) => {
        node.setSelected(false);
      });
    }
  }

  private areFiltersApplied(): boolean {
    return (
      (this.cachedFilters && Object.keys(this.cachedFilters).length !== 0) ||
      (this.columnController.gridApi && this.getQuickFilterText().length > 0)
    );
  }

  private getQuickFilterText(): string {
    return (
      (this.columnController.gridApi &&
        this.columnController.gridApi.filterManager &&
        this.columnController.gridApi.filterManager.quickFilter) ||
      ''
    );
  }
  /**
   * Assign new state to cached after check excluded ids for selection
   */
  private updateState(): void {
    const agGridSelecteds: RowNode[] = this.gridApi.getSelectedNodes();
    const newSelection: RowNode[] = filter(agGridSelecteds, (node: RowNode) => {
      return node.data !== undefined;
    });
    // Check isn't a massive un-selection. In that case remove the select all state and just left select the selected
    if (this.selectAllState.all && this.cachedSelection.length - newSelection.length <= 1) {
      this.updateExcluded(this.mapSelection(newSelection));
      this.selectAllState.selection.clear();
    } else {
      this.selectAllState.reset();
      // Add the new selection mapping only ID's with given keyField
      this.selectAllState.selection = new Set(newSelection.map((node) => node.data[this.selectAllState.keyField]));
    }
    this.cachedSelection = this.mapSelection(newSelection);
  }

  private mapSelection(selection: RowNode[]): any[] {
    return selection.map((select: RowNode) => select.data);
  }

  /**
   * Only in client-side: Perfoms the action of set each row filtered as selected if correspond. The second parameter allows to
   * cancel the ag-grid event emition for new selected.
   * @param setSelected
   * @param emitEvent
   */
  private setNodesSelectionFiltered(setSelected: boolean, emitEvent: boolean = true): void {
    if (!this.gridApi) {
      return;
    }
    this.gridApi[this.nodeIteratorName]((node: RowNode) => {
      // Avoiding updating the state `x` amount of times and only sending out
      // the selection change event on the last row.
      const emitSelectEvent = node.rowIndex === this.gridApi.getLastDisplayedRow() && emitEvent;
      // Not select if is in the excluded set of rows
      if (node.data && !this.selectAllState.excluded.has(node.data[this.selectAllState.keyField])) {
        node.setSelected(setSelected, false, !emitSelectEvent);
      }
    });
    this.updateState();
  }
  /**
   * Perfoms the action of set each row as selected if correspond. The second parameter allows to
   * cancel the ag-grid event emition for new selected.
   * @param setSelected
   * @param emitEvent
   */
  private setNodesSelection(setSelected: boolean, emitEvent: boolean = true): void {
    this.gridApi.forEachNode((node: RowNode) => {
      // Avoiding updating the state `x` amount of times and only sending out
      // the selection change event on the last row.
      const emitSelectEvent = node.rowIndex === this.gridApi.getLastDisplayedRow() && emitEvent;
      // Not select if is in the excluded set of rows
      if (node.data && !this.selectAllState.excluded.has(node.data[this.selectAllState.keyField])) {
        node.setSelected(setSelected, false, !emitSelectEvent);
      }
    });
    this.updateState();
  }

  /**
   * Toggles new excluded / added rows to excluded set in the state
   * @param selection
   */
  private updateExcluded(selection: RowNode[]): void {
    if (selection.length < this.cachedSelection.length) {
      this.getDiffBetweenSelections(this.cachedSelection, selection).forEach((row) => {
        this.selectAllState.excluded.add(row[this.selectAllState.keyField]);
      });
    } else if (selection.length > this.cachedSelection.length) {
      this.getDiffBetweenSelections(selection, this.cachedSelection).forEach((row) => {
        this.selectAllState.excluded.delete(row[this.selectAllState.keyField]);
      });
    }
  }

  private getDiffBetweenSelections(sel1: RowNode[], sel2: RowNode[]): RowNode[] {
    return sel1.filter(
      (node) =>
        !sel2.some((otherNode) => node[this.selectAllState.keyField] === otherNode[this.selectAllState.keyField])
    );
  }

  private updateCountOfSelect(): void {
    const countRowsSelected = this.cachedSelection.length;
    const countTotalRows = this.gridApi.getDisplayedRowCount();
    this.selectAllState.countOfSelect = this.selectAllState.all
      ? countTotalRows - this.selectAllState.excluded.size
      : countRowsSelected;
  }

  /**
   * Emits the advice of selection change for all the app. This method is called only from
   * selectionChangeListener or handleCheckboxChange, the two ways where user can change selection
   */
  private emitSelectionChange(): void {
    this.updateCountOfSelect();
    // The board state isn't prepared yet to receive a selection change from a grid detail. It'll be developed soon
    if (!this.isDetailGrid) {
      this.stateChange$.next({
        selection: this.cachedSelection,
        source: this.SELECTION_CHANGE_SELECT_NAME,
        customSelection: this.selectAllState,
      });
    }
  }

  setKeyField(keyField: string) {
    if (!keyField) {
      const keyFieldProperty = this.isDetailGrid ? 'detailGridKeyField' : 'keyField';
      console.error(
        `[XPO-LTL-BOARD] ${keyFieldProperty} property should be defined in your XpoAgGridBoardViewTemplate options if you want to use the select all checkbox`
      );
    }
    this.selectAllState = new SelectAllState(keyField);
  }

  ngOnDestroy(): void {
    this.gridApi.removeEventListener(this.EVENT_PAGINATION_CHANGED_NAME, this.paginationChangedListener);
    this.gridApi.removeEventListener(this.EVENT_SELECTION_CHANGED_NAME, this.selectionChangeListener);
    this.gridApi.removeEventListener(this.EVENT_FILTER_CHANGED_NAME, this.filterChangedListener);
  }
}
