import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetOdsShipmentQuery, GetOdsShipmentResp, ShipmentOdsApiService } from '@xpo-ltl/sdk-shipmentods';
import { defaultTo as _defaultTo, first as _first, get as _get, size as _size, uniqBy as _uniqBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '../../classes';
import { ProFormatterPipe } from '../../pipes/pro-formatter/pro-formatter.pipe';
import { XpoLtlShipmentDescriptor } from '../shipment-descriptor';
import {
  XpoLtlShipmentDetailsComponent,
  XpoLtlShipmentDetailsConfig,
  XpoLtlShipmentDetailsTabConfig,
} from '../shipment-details/shipment-details.component';

interface DialogData {
  shipmentDescriptors: XpoLtlShipmentDescriptor[];
  config?: XpoLtlShipmentDetailsConfig;
}

@Component({
  selector: 'xpo-ltl-shipment-details-dialog',
  templateUrl: './shipment-details-dialog.component.html',
  styleUrls: ['./shipment-details-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpoLtlShipmentDetailsDialogComponent implements AfterViewInit, OnDestroy {
  private unsubscriber = new Unsubscriber();
  private dialogRef: MatDialogRef<XpoLtlShipmentDetailsComponent>;

  opened = false;

  private dialogData: DialogData;
  private shipmentDescs: XpoLtlShipmentDescriptor[];

  get shipmentDescriptors(): XpoLtlShipmentDescriptor[] {
    return this.shipmentDescs;
  }

  get moveable(): boolean {
    return !!_get(this.dialogData, 'config.moveable');
  }

  get searchable(): boolean {
    return !!_get(this.dialogData, 'config.searchable');
  }

  get showShipmentListPanel(): boolean {
    return _size(this.shipmentDescriptors) > 1 || this.searchable;
  }

  private selectedShipmentSubject = new BehaviorSubject<GetOdsShipmentResp>(undefined);
  readonly selectedShipment$ = this.selectedShipmentSubject.asObservable();
  get selectedShipment(): GetOdsShipmentResp {
    return this.selectedShipmentSubject.value;
  }

  private dialogTitleSubject = new BehaviorSubject<string>('Shipment');
  readonly dialogTitle$ = this.dialogTitleSubject.asObservable();

  private selectedTabLabelSubject = new BehaviorSubject<string>(undefined);
  readonly selectedTabLabel$ = this.selectedTabLabelSubject.asObservable();

  set selectedTabLabel(value: XpoLtlShipmentDetailsTabConfig) {
    this.selectedTabLabelSubject.next(value.title);
  }

  constructor(
    private shipmentOdsApiService: ShipmentOdsApiService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private proFormatterPipe: ProFormatterPipe
  ) {
    this.dialogData = data;
    this.shipmentDescs = _uniqBy(this.dialogData.shipmentDescriptors, 'proNbr');

    this.opened = _get(this.dialogData, 'config.showShipmentSelector', false);

    combineLatest([this.selectedTabLabel$, this.selectedShipment$])
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((values) => {
        let title = `${_defaultTo(values[0], '')}`;
        const proNbr = _get(values[1], 'shipment.proNbr');
        if (proNbr) {
          title = `${title} - PRO ${this.proFormatterPipe.transform(proNbr, 10)}`;
        }
        this.dialogTitleSubject.next(title);
      });
  }

  ngAfterViewInit() {
    if (_size(this.shipmentDescriptors) === 1) {
      this.selectShipment(_first(this.shipmentDescriptors));
    }
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.closeClicked();
  }

  closeClicked() {
    if (!!this.dialogRef) {
      this.dialogRef.close();
    }
  }

  private shipmentMatchDescriptor(shipment: GetOdsShipmentResp, desc: XpoLtlShipmentDescriptor): boolean {
    if (!shipment && !desc) {
      return true;
    }

    if (desc.shipmentInstId && desc.shipmentInstId === _get(shipment, 'shipment.shipmentInstId')) {
      return true;
    }

    if (
      desc.proNbr &&
      desc.pickupDate &&
      desc.proNbr === _get(shipment, 'shipment.proNbr') &&
      new Date(desc.pickupDate) === _get(shipment, 'shipment.pickupDate')
    ) {
      return true;
    }

    // does not match
    return false;
  }

  selectShipment(shipmentDesc: XpoLtlShipmentDescriptor) {
    if (shipmentDesc) {
      // Load and display the newly selected shipment
      if (!this.shipmentMatchDescriptor(this.selectedShipment, shipmentDesc)) {
        this.loadShipment(shipmentDesc).subscribe((shipment: GetOdsShipmentResp) => {
          this.selectedShipmentSubject.next(shipment);
        });
      }
    } else {
      this.selectedShipmentSubject.next(undefined);
    }
  }

  private loadShipment(shipmentDesc: XpoLtlShipmentDescriptor): Observable<GetOdsShipmentResp> {
    // Load and display the newly selected shipment
    const queryParams = new GetOdsShipmentQuery();
    queryParams.shipmentInstId = shipmentDesc.shipmentInstId ? shipmentDesc.shipmentInstId : undefined;
    queryParams.proNbr = !shipmentDesc.shipmentInstId ? shipmentDesc.proNbr : undefined;
    queryParams.pickupDate = !shipmentDesc.shipmentInstId ? shipmentDesc.pickupDate : undefined;
    queryParams.shipmentDetailCd = shipmentDesc.shipmentDetailCd ? [shipmentDesc.shipmentDetailCd] : undefined;
    return this.shipmentOdsApiService.getOdsShipment(queryParams).pipe(switchMap((resp) => of(resp)));
  }
}
