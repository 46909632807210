import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ApiRequest } from '@xpo-ltl/data-api';
import {
  AccountsReceivableApiService,
  ListArPaymentSummaryByProResp,
  ListArPaymentSummaryByProQuery,
} from '@xpo-ltl/sdk-accountsreceivable';
import { PaymentStatusInternalCd } from '@xpo-ltl/sdk-common';
import { GetOdsShipmentResp } from '@xpo-ltl/sdk-shipmentods';
import { first as _first, get as _get } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '../../../classes/unsubscriber';
import { ShipmentReferenceService } from '../../shipment-reference.service';

interface SummaryData {
  status: string;
  payments: number;
  charges: number;
  adjustments: number;
  currentBalance: number;
}
@Component({
  selector: 'xpo-ltl-shipment-details-payment-summary',
  templateUrl: './shipment-details-payment-summary.component.html',
  styleUrls: ['./shipment-details-payment-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentDetailsPaymentSummaryComponent implements OnInit, OnDestroy {
  constructor(
    private shipmentRefService: ShipmentReferenceService,
    private accountsReceivableApiService: AccountsReceivableApiService
  ) {}

  @Input() hasFreightChargesTab: boolean = false;

  @Output()
  openFreightCharges = new EventEmitter<void>();

  private unsubscriber = new Unsubscriber();

  private summarySubject = new BehaviorSubject<SummaryData>({
    status: 'OPEN',
    payments: 0,
    charges: 0,
    adjustments: 0,
    currentBalance: 0,
  });
  readonly summary$ = this.summarySubject.asObservable();

  readonly PaymentStatusInternalCd = PaymentStatusInternalCd;

  ngOnInit() {
    this.shipmentRefService.shipment$
      .pipe(takeUntil(this.unsubscriber.done$), distinctUntilChanged())
      .subscribe((shipment) => {
        this.updateFormFromShipment(shipment);
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  openFreightChargesTab() {
    this.openFreightCharges.emit();
  }

  private updateFormFromShipment(shipment: GetOdsShipmentResp) {
    if (!shipment) {
      return;
    }

    const request = new ListArPaymentSummaryByProQuery();
    request.proNbr = shipment.shipment.proNbr;
    request.pickupDate = `${shipment.shipment.pickupDate.valueOf()}`;

    this.accountsReceivableApiService
      .listArPaymentSummaryByPro(request, ApiRequest.concealedCall)
      .subscribe((result: ListArPaymentSummaryByProResp) => {
        const paymentSummary = _first(result.arPaymentSummaries);
        this.summarySubject.next({
          status: _get(paymentSummary, 'paymentStatusTxt', 'OPEN'),
          payments: paymentSummary.totalPaymentAmt,
          charges: paymentSummary.totalChargeAmt,
          adjustments: paymentSummary.totalAdjustmentAmt,
          currentBalance: paymentSummary.balanceDueAmt,
        });
      });
  }
}
