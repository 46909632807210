import { NgModule } from '@angular/core';
import { DataApiService } from './data-api.service';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import { NotificationService } from './notification/notification.service';
import { CommonModule } from '@angular/common';


@NgModule({
  imports: [
    CommonModule,
    ConfigManagerModule
  ],
  providers: [
    { provide: CONFIG_MANAGER_SERVICE_CONFIGURATION, useValue: { configFileUrl: './assets/config.json'}},
  ]
})
export class DataApiModule { }
