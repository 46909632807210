import {SeaNotifyEventCd} from '../';
import {EnumHelper} from './enum-helper';

export class SeaNotifyEventCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in SeaNotifyEventCd ) {
      this._values.push(SeaNotifyEventCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): SeaNotifyEventCd {

    for ( const obj in SeaNotifyEventCd ) {
      if (SeaNotifyEventCd[obj] === value){
        return SeaNotifyEventCd[obj] as SeaNotifyEventCd;
      }
    }
  }
}

const SeaNotifyEventCdHelper = new SeaNotifyEventCdHelperEnumHelperClass();
export default SeaNotifyEventCdHelper;
