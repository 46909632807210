import {StopWindowNoteTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class StopWindowNoteTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in StopWindowNoteTypeCd ) {
      this._values.push(StopWindowNoteTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): StopWindowNoteTypeCd {

    for ( const obj in StopWindowNoteTypeCd ) {
      if (StopWindowNoteTypeCd[obj] === value){
        return StopWindowNoteTypeCd[obj] as StopWindowNoteTypeCd;
      }
    }
  }
}

const StopWindowNoteTypeCdHelper = new StopWindowNoteTypeCdHelperEnumHelperClass();
export default StopWindowNoteTypeCdHelper;
