
export enum InvoiceTransmitStatusCd {
	DO_NOT_TRANSMIT = 'Do Not Transmit',
	EXPIRED = 'Expired',
	NOT_READY = 'NotReady',
	PAID = 'Paid',
	PAID_BELOW_THRESHOLD = 'Paid_BelowThreshold',
	PAID_CASH_COLLECTED = 'Paid_CashCollected',
	PAID_CASH_PPD = 'Paid_CashPpd',
	PAID_SELF_PAID = 'Paid_SelfPaid',
	TRANSMIT = 'Transmit'}

