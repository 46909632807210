import {GlobalSearchDetailCd} from '../';
import {EnumHelper} from './enum-helper';

export class GlobalSearchDetailCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in GlobalSearchDetailCd ) {
      this._values.push(GlobalSearchDetailCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): GlobalSearchDetailCd {

    for ( const obj in GlobalSearchDetailCd ) {
      if (GlobalSearchDetailCd[obj] === value){
        return GlobalSearchDetailCd[obj] as GlobalSearchDetailCd;
      }
    }
  }
}

const GlobalSearchDetailCdHelper = new GlobalSearchDetailCdHelperEnumHelperClass();
export default GlobalSearchDetailCdHelper;
