import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	ListPrinterGroupsRqst,
	ListPrinterGroupsResp,
	ListPrintersRqst,
	ListPrintersResp,
	ListAttachedPrintersRqst,
	ListAttachedPrintersResp,
	AddPrinterRqst,
	AddPrinterResp,
	AddPrinterGroupRqst,
	AddPrinterGroupResp,
	DeletePrinterRqst,
	UpdatePrinterRqst,
	AttachPrinterToGroupRqst,
	RemovePrinterFromGroupRqst,
	DeletePrinterGroupRqst,
	UpdatePrinterGroupRqst,
	ValidatePrinterGroupOrPrinterCodesRqst,
	ValidatePrinterGroupOrPrinterCodesResp,
	SendEmailRqst,
	CreateAsyncReportRqst,
	CreateAsyncReportResp,
	ListRefStandardCodesRqst,
	ListRefStandardCodesResp,
	MaintainRefStandardCodesRqst,
	MaintainRefStandardCodesResp
} from './api-infrastructure';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class InfrastructureApiService extends BaseService {
	private static InfrastructureApiEndpoint = 'infrastructureApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get a list of printer groups. If a group code is provided then return a list of printer groups starting from that group code. If no group code provided then return all of the printer group. There are only a few hundred printer groups out there.
	*/
	public listPrinterGroups(request: ListPrinterGroupsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPrinterGroupsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/printers/groups'),
			{
				queryParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns a list of printers. If a printer code is provided then a list of printers will be returned starting from that printer code.  If a SIC code is provided, this will be used to filter the results by that location.  If no printer code is provided, then all of the printers will be returned.  There aren't a lot of them, so we will return all of them.
	*/
	public listPrinters(request: ListPrintersRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPrintersResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/printers'),
			{
				queryParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get a list of printers attached to a printer group.
	*/
	public listAttachedPrinters(request: ListAttachedPrintersRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListAttachedPrintersResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/printers/groups/{printerGroupInstId}/printers'),
			{
		    	pathParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Add a new Printer reference.
	* <br/>
	*/
	public addPrinter(request: AddPrinterRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<AddPrinterResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/printers'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Create a new Printer Group.
	*/
	public addPrinterGroup(request: AddPrinterGroupRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<AddPrinterGroupResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/printers/groups'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Delete a printer reference.
	*/
	public deletePrinter(request: DeletePrinterRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/printers/{printerInstId}'),
			{
		    	pathParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Update Printer information
	*/
	public updatePrinter(request: UpdatePrinterRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/printers'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Attach a printer to a printer group.
	*/
	public attachPrinterToGroup(request: AttachPrinterToGroupRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/printers/groups/{printerGroupInstId}/printers/{printerInstId}'),
			{
		    	pathParams: request
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Remove a printer from a printer group.
	*/
	public removePrinterFromGroup(request: RemovePrinterFromGroupRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/printers/groups/{printerGroupInstId}/printers/{printerInstId}'),
			{
		    	pathParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Delete a printer group
	*/
	public deletePrinterGroup(request: DeletePrinterGroupRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/printers/groups/{printerGroupInstId}'),
			{
		    	pathParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Update printer group information.
	*/
	public updatePrinterGroup(request: UpdatePrinterGroupRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/printers/groups'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation will return any submitted printer group or printer codes if they are invalid and do not exist.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Either a printer group code or one or more printer codes are submitted.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If any of the submitted codes do not exist in the printer table, they are returned as invalid
	* <br/>2. Return standard error message.
	*/
	public validatePrinterGroupOrPrinterCodes(request: ValidatePrinterGroupOrPrinterCodesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ValidatePrinterGroupOrPrinterCodesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/printers/invalid-printercodes'),
			{
				queryParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service accepts a document and one or more printer names and prints the documents on the specified printers.
	* <br/>#### Pre Condition
	* <br/>Document and printer names are supplied.
	* <br/>#### Post Condition:
	* <br/>Documents are printed on the specified printers
	* <br/>		
	*/
	public printDocument(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/printers/print-document'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will send an email message based on the request message.
	* <br/>			The request message must contain at least one email address in 'to' list and 'from' email address  and 'from name'. 
	* <br/>
	* <br/>Pre Condition: A valid email address is passed in the 'to' list and 'from' email address and 'from name' is provided along with message subject and text.
	* <br/>
	* <br/>Post Condition: The service will send an email message to the email address mentioned in the 'to' list.
	* <br/>
	*/
	public sendEmail(request: SendEmailRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/messages/email'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This service will create an asynchronous report request to be processed in the background. The service will store the request in a database table as well as write the request to a JMS queue. It returns a unique ID for each successfully created request.
	* <br/>
	* <br/>Pre Condition: Valid Report Name, Report parameter string and teh requester ID is paaed.
	* <br/>
	* <br/>Post Condition: The service creates a report request and returns the unique identifier for the request. 
	* <br/>
	*/
	public createAsyncReportRequest(request: CreateAsyncReportRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateAsyncReportResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/reports/request'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Get a list of reference standard codes for a code type.
	* <br/>pre-conditions: a 4 characters code type
	* <br/>post-conditions: return a list of standard codes that match the code type.
	*/
	public listRefStandardCodes(request: ListRefStandardCodesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListRefStandardCodesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/reference/codes/{codeType}'),
			{
		    	pathParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Maintain a list of reference standard codes for a code type.
	* <br/>pre-conditions: a 4 characters code type, a list of reference standard codes to create and/or update
	* <br/>post-conditions: return a list of standard codes that match the code type.
	*/
	public maintainRefStandardCodes(request: MaintainRefStandardCodesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<MaintainRefStandardCodesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/reference/codes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(InfrastructureApiService.InfrastructureApiEndpoint);
	}
}
