/**
 * AccountsReceivable API
 * Version: 1.0
 * Build: 1.0-1.0.0.1
 */

import {
	ShipmentId
} from '@xpo-ltl/sdk-common';

export class ListArPaymentDetailsByProQuery {
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
}
export class ListArPaymentDetailsByProResp {
	/**
	 * Required: false
	 * Array: true
	 */
	arPaymentDetails:ArPaymentDetail[] = undefined;
}
export class ListArPaymentSummaryByProQuery {
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
}
export class ListArPaymentSummaryByProResp {
	/**
	 * Required: false
	 * Array: true
	 */
	arPaymentSummaries:ArPaymentSummary[] = undefined;
}
export class ListArPaymentSummaryByMultiProsRqst {
	/**
	 * Contains identifying information for shipments.
	 * Required: true
	 * Array: true
	 */
	shipments:ShipmentId[] = undefined;
}
export class ListArPaymentSummaryByMultiProsResp {
	/**
	 * Required: false
	 * Array: true
	 */
	arPaymentSummaries:ArPaymentSummary[] = undefined;
}
export class ArPaymentDetail {
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currencyCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	custNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	receiptNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	chargeCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	paymentDate:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalChargeAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalPaymentAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalAdjustmentAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	balanceDueAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	creditedAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	inDisputeAmt:number = undefined;
}
export class ArPaymentSummary {
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalChargeAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalPaymentAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	totalAdjustmentAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	balanceDueAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	creditedAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	inDisputeAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	chargeCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proStatusCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	paymentStatusTxt:string = undefined;
}



