import { CdkScrollable } from '@angular/cdk/overlay';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { XpoStepHeaderComponent } from '../step-header/step-header.component';
import { XpoStepComponent } from '../step/step.component';

const XPO_STEPPER_HEADER_SCROLL_BEHAVIOR: ScrollBehavior = 'smooth';

// WARNING: this value is use to position the header without take into account its negative margin.
// It is equals to the property `margin-left` set by CSS for each not first header.
// If any of these values change, the scrolling behavior will be affected.
const XPO_STEPPER_HEADER_LEFT_MARGIN: number = 16;

@Component({
  selector: 'xpo-horizontal-steps-header',
  templateUrl: 'horizontal-steps-header.component.html',
  styleUrls: ['horizontal-steps-header.component.scss'],
  host: {
    class: 'xpo-HorizontalStepsHeader',
  },
  entryComponents: [XpoStepHeaderComponent],
  encapsulation: ViewEncapsulation.None,
})
export class XpoHorizontalStepsHeaderComponent implements AfterViewInit, AfterViewChecked {
  @Input() steps: XpoStepComponent[];

  @Input() selectedIndex: number;

  @Input() selectionChange: EventEmitter<StepperSelectionEvent>;

  @Input() linear: boolean;

  @ViewChild(CdkScrollable, { static: true }) scrollable: CdkScrollable;

  @ViewChildren(XpoStepHeaderComponent) stepHeaders: QueryList<XpoStepHeaderComponent>;

  canScroll: boolean = false;

  private scrollValue: number = 0;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.scrollable.elementScrolled().subscribe(() => this.cd.detectChanges());

    this.selectionChange.subscribe((event: StepperSelectionEvent) => this.onSelectionChange(event.selectedIndex));
  }

  ngAfterViewChecked(): void {
    this.canScroll = this.canScrollLeft() || this.canScrollRight();

    // if it needs scrolling, set the scroll value based on the width of each elements
    // it is supposed that if scroll is needed, the size of all header elements is the same,
    // and all them are adjusted to its min-width CSS property
    if (this.canScroll) {
      this.scrollValue = this.stepHeaders.first._getHostElement().offsetWidth;
    } else {
      this.scrollValue = 0;
    }
  }

  isStepSelected(stepNumber: number): boolean {
    return stepNumber === this.selectedIndex;
  }

  isStepActive(step: XpoStepComponent, stepNumber: number): boolean {
    const previousStepCompleted =
      this.steps.indexOf(step) > 0 && this.steps.find((s) => this.steps.indexOf(s) === stepNumber - 1).completed;

    return !this.linear || step.completed || this.isStepSelected(stepNumber) || previousStepCompleted;
  }

  canScrollLeft(): boolean {
    return this.isContinueScrollDistance(this.getMeasureScrollLeft());
  }

  canScrollRight(): boolean {
    return this.isContinueScrollDistance(this.getMeasureScrollRight());
  }

  onClickLeftScroll(): void {
    // compute the scrolling value taken into account the negative left margin of each header element
    const scrollValueWithMargin = this.scrollValue - XPO_STEPPER_HEADER_LEFT_MARGIN;

    // compute the count of headers that were scrolled to the left
    const headersScrolled = Math.round(this.getMeasureScrollLeft() / scrollValueWithMargin);

    // compute if the left scrolling is based of a part of the header
    const leftOffset = Math.round(this.getMeasureScrollLeft() % scrollValueWithMargin);

    // based of values above, define the position to scroll to
    let scroll: number;

    // if there is part of an element scrolled
    if (leftOffset > XPO_STEPPER_HEADER_LEFT_MARGIN) {
      // compute value to scroll to the start of the element,
      // taking into account the left negative margin
      scroll = this.getMeasureScrollLeft() - leftOffset + XPO_STEPPER_HEADER_LEFT_MARGIN;
    } else if (headersScrolled > 1) {
      scroll = this.getMeasureScrollLeft() - scrollValueWithMargin;
    } else {
      // scroll to position 0
      scroll = 0;
    }

    // scroll to the computed position
    this.scrollTo(scroll);
  }

  onClickRightScroll(): void {
    // compute the scrolling value taken into account the negative left margin of each header element
    const scrollValueWithMargin = this.scrollValue - XPO_STEPPER_HEADER_LEFT_MARGIN;

    // compute the count of headers that were scrolled to the left
    const headersScrolled = Math.round(this.getMeasureScrollLeft() / scrollValueWithMargin);

    // based of values above, define the position to scroll to
    let scroll;

    // if the element to scroll the first one
    if (headersScrolled === 0) {
      // scroll the exactly element width
      scroll = this.getMeasureScrollLeft() + this.scrollValue;
    } else {
      // scroll the element without the lef negative margin
      scroll = this.getMeasureScrollLeft() + this.scrollValue - XPO_STEPPER_HEADER_LEFT_MARGIN;
    }

    // scroll to the computed position
    this.scrollTo(scroll);
  }

  private onSelectionChange(selectedIndex: number): void {
    // if there is no scroll
    if (!this.canScroll) {
      return;
    }

    // compute the scrolling value taken into account the negative left margin of each header element
    const scrollValueWithMargin = this.scrollValue - XPO_STEPPER_HEADER_LEFT_MARGIN;

    let scroll: number;

    // if selected header is the first one
    if (selectedIndex === 0) {
      // scroll to the start position
      scroll = 0;
    } else if (selectedIndex === 1) {
      // scroll to position without taking into account the left negative margin of the first header
      scroll = this.scrollValue * selectedIndex;
    } else {
      // scroll to position taking into account the left negative margin of the first header
      scroll = scrollValueWithMargin * selectedIndex + XPO_STEPPER_HEADER_LEFT_MARGIN;
    }

    this.scrollTo(scroll);
  }

  private getMeasureScrollLeft(): number {
    return this.scrollable.measureScrollOffset('left');
  }

  private getMeasureScrollRight(): number {
    return this.scrollable.measureScrollOffset('right');
  }

  private isContinueScrollDistance(offset: number): boolean {
    return Math.abs(Math.round(offset)) > 0;
  }

  private scrollTo(scroll: number): void {
    this.scrollable.scrollTo({
      left: scroll,
      behavior: XPO_STEPPER_HEADER_SCROLL_BEHAVIOR,
    });
  }
}
