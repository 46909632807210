import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { filterFormats, Operators, OperatorText, XpoFilterConditionCriteria } from '../../../../../models/index';
import { XpoConditionBase } from '../../../../validations/validations.class';
import { XpoFilterCondition } from '../../../condition';

@Component({
  templateUrl: './is-one-of.component.html',
  styleUrls: ['../../validator.scss'],
})
export class XpoConditionIsOneOfComponent extends XpoConditionBase implements XpoFilterCondition, AfterViewInit {
  static text = OperatorText.OneOf;
  static operator: Operators = Operators.OneOf;
  isNegative = false;
  model = [];
  userVal = '';

  @Output() inputChanged = new EventEmitter();

  constructor() {
    super(filterFormats.default);
  }

  ngAfterViewInit(): void {
    // Transform array of values into string separated by new lines to be showed in textarea
    this.userVal = this.model.join('\n');
  }

  validate(cellValue): boolean {
    // Do not validate if input is empty
    if (!this.model) {
      return true;
    }

    if (!cellValue) {
      return false;
    }

    const values = this.getArrayOfValues(this.model);
    let filterPass = false;

    values.forEach((val) => {
      if (val.toString().toLowerCase() === cellValue.toString().toLowerCase()) {
        filterPass = true;
      }
    });
    return filterPass;
  }

  getCriteria(): XpoFilterConditionCriteria {
    return {
      operator: Operators.OneOf,
      value: this.getArrayOfValues(this.model),
    };
  }

  onInputChange(): any {
    this.model = this.getArrayOfValues(this.userVal);
    this.validateFormat();
    this.inputChanged.emit(this.model);
  }

  private getArrayOfValues(receivedModel: string | string[]): string[] {
    let properArray: string[];
    // If not an array create one with the received string value
    Array.isArray(receivedModel) ? (properArray = receivedModel) : (properArray = receivedModel.match(/[^\r\n]+/g));
    // If content is null assign a new array
    properArray = properArray || [];
    // Remove white spaces before and after each value
    properArray.forEach((value, index) => {
      properArray[index] = this.removeSpaces(value);
    });
    // Return expected array of values
    return properArray;
  }

  private removeSpaces(value: string): string {
    return value.trim();
  }
}
