import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ModuleGroupGridModel } from '../../../module-groups-board/models/module-group-grid.model';
import { ModuleLibraryVersion } from '../../models/module-library-version.model';

@Component({
  selector: 'module-library-trail-panel',
  templateUrl: './trail-panel.component.html',
  styleUrls: ['./trail-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ModuleLibraryTrailPanelComponent {
  @Output()
  close: EventEmitter<any>;

  @Input()
  moduleLibraryVersion: ModuleLibraryVersion;

  @Input()
  moduleGroups: ModuleGroupGridModel[];

  constructor() {
    this.close = new EventEmitter<any>();
  }

  onClose(): void {
    this.close.emit();
  }
}
