import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { XpoBoardConsumer, XpoBoardData, XpoBoardState } from '../../models/index';

@Component({
  selector: 'xpo-board-total-records',
  templateUrl: 'board-total-records.component.html',
  styleUrls: ['board-total-records.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-BoardTotalRecords' },
})
export class XpoBoardTotalRecords extends XpoBoardConsumer {
  totalRecords = 0;
  selectedRecords = 0;

  protected onStateChange(state: XpoBoardState): void {
    if (state.source === 'update-row-count') {
      this.totalRecords = state.visibleRows;
    }

    if (state.changes.includes('selection')) {
      if (state.customSelection && state.customSelection.all) {
        this.selectedRecords = state.customSelection.countOfSelect;
      } else {
        this.selectedRecords = (state.selection || []).length;
      }
    }
  }
}
