import { Injectable } from '@angular/core';
import {
  ListOperationalServiceCentersQuery,
  ListOperationalServiceCentersResp,
  LocationApiService,
  ServiceCenter,
} from '@xpo-ltl-2.0/sdk-location';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import {
  filter as _filter,
  find as _find,
  sortBy as _sortBy,
  sortedUniqBy as _sortedUniqBy,
  toUpper as _toUpper,
} from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';
import { delay, filter, map, retryWhen, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class XpoLtlServiceCentersService {
  private cachedServiceCenters: ServiceCenter[] = undefined;
  private serviceCentersSubject = new ReplaySubject<ServiceCenter[]>(1);
  readonly serviceCenters$ = this.serviceCentersSubject.asObservable();

  constructor(private locationApiService: LocationApiService, private configService: ConfigManagerService) {
    this.configService.configured$
      .pipe(
        filter((config) => config),
        take(1),
        switchMap(() => this.getServiceCenters())
      )
      .subscribe((centers) => {
        this.cachedServiceCenters = centers; // save a cache to use with getSicByCd
        this.serviceCentersSubject.next(centers);
      });
  }

  // get the list of ServiceCenters from the server
  private getServiceCenters(): Observable<ServiceCenter[]> {
    const query = new ListOperationalServiceCentersQuery();
    return this.locationApiService.listOperationalServiceCenters(query).pipe(
      retryWhen((errors) =>
        // retry getting list if we get an error. Might be waiting for authentication to complete
        errors.pipe(delay(1000), take(5))
      ),
      map((resp: ListOperationalServiceCentersResp) => {
        const centers = _sortBy(resp.serviceCenters, (center) => center.sicCd);
        const uniqCenters = _sortedUniqBy(centers, (center) => center.sicCd);
        return uniqCenters;
      }),
      take(1)
    );
  }

  /**
   * Filters the passed list of SICs to those that match the passed filter term
   * Filtering is based on the sicCd and the city name
   *
   * @param filterTerm string used to filter the list of SICs
   */
  filterSicsBy(sicList: ServiceCenter[], filterTerm: string): ServiceCenter[] {
    if (!filterTerm || filterTerm === '') {
      // nothing to filter by, so return full list
      return sicList;
    } else {
      const term = _toUpper(filterTerm);

      return _filter(sicList, (sic) =>
        _toUpper(sic?.sicCd ?? '').includes(term) ||
        _toUpper(sic?.locAddress?.cityName ?? '').includes(term) ||
        _toUpper(sic?.reference.sicName ?? '').includes(term)
          ? true
          : false
      ).sort((prev, curr) => (curr.sicCd.includes(term) ? 1 : -1));
    }
  }

  /**
   * Finds and returns the first SIC with the passed SicCd. Returns undefined if not found
   * Assumes that serviceCenters has already been populated!  Otherwise, use getSicByCd$!
   * @param sicCd SIC code to search for
   * @deprecated Use getSicByCd$ instead.
   */
  getSicByCd(sicCd: string): ServiceCenter {
    const term = _toUpper(sicCd);
    return _find(this.cachedServiceCenters, (sic) => _toUpper(sic.sicCd) === term);
  }

  /**
   * Async Finds and returns the first SIC with the passed SicCd. Returns undefined if not found
   * @param sicCd SIC code to search for
   */
  getSicByCd$(sicCd: string): Observable<ServiceCenter> {
    return this.serviceCenters$.pipe(
      take(1),
      map((centers: ServiceCenter[]) => {
        const term = _toUpper(sicCd);
        return _find(centers, (sic) => _toUpper(sic.sicCd) === term);
      })
    );
  }
}
