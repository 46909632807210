import {CustomsReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomsReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomsReasonCd ) {
      this._values.push(CustomsReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomsReasonCd {

    for ( const obj in CustomsReasonCd ) {
      if (CustomsReasonCd[obj] === value){
        return CustomsReasonCd[obj] as CustomsReasonCd;
      }
    }
  }
}

const CustomsReasonCdHelper = new CustomsReasonCdHelperEnumHelperClass();
export default CustomsReasonCdHelper;
