import { XpoCustomSelectionState } from '@xpo-ltl/ngx-ltl-board';

/**
 * This class assign behavior to the state of the custom selection created for ag-grid in server side
 */
export class SelectAllState implements XpoCustomSelectionState {
  all = false;
  countOfSelect: number = 0;
  excluded: Set<string> = new Set<string>();
  selection: Set<string> = new Set<string>();

  constructor(readonly keyField: string) {}

  /**
   * Perform select all action cleaning selected and excluded sets
   */
  selectAll(): void {
    this.all = true;
    this.excluded.clear();
    this.selection.clear();
  }

  /**
   * Restore select all state to initial
   */
  reset(): void {
    this.all = false;
    this.selection.clear();
    this.excluded.clear();
    this.countOfSelect = 0;
  }
}
