
export enum LhoEventTypeCd {
	ADDLANE = 'ADDLANE',
	DVRTBYP = 'DVRTBYP',
	DVRTFRT = 'DVRTFRT',
	DVRTTRLR = 'DVRTTRLR',
	EDITDSRCNT = 'EDITDSRCNT',
	EDITPUPCNT = 'EDITPUPCNT',
	EDITVIA = 'EDITVIA',
	RLSESTAT = 'RLSESTAT'}
