import { Injectable } from '@angular/core';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { XrtInMemoryService } from '@xpo/ngx-xrt';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModuleLibraryVersion } from '../models/module-library-version.model';
import { ModuleExecutorBoardTemplates } from '../shared/enums/module-executor-board-templates.enum';
import { ModuleLibraryInMemoryService } from './module-library-inMemory.service';

export interface Service {
  id: string;
  source: XrtInMemoryService;
}

@Injectable()
export class ModuleExecutorBoardDataSource extends XpoBoardDataSource {
  protected services: Array<Service>;

  constructor(protected moduleLibraryService: ModuleLibraryInMemoryService) {
    super();
    this.pageSize = 100000;
    this.setServices();
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    return this.services
      .find((service) => service.id === state.viewId)
      .source.search(ModuleLibraryVersion, {
        filter: state.criteria,
        pageNumber: state.pageNumber,
        pageSize: this.pageSize,
        sortExpressions: state.sortOrder,
      })
      .pipe(map((data) => new XpoBoardData(state, data.items, data.resultCount, this.pageSize)));
  }

  setServices(): void {
    this.services = [{ id: ModuleExecutorBoardTemplates.MODULE_LIBRARY, source: this.moduleLibraryService }];
  }
}
