import {AppointmentStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class AppointmentStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in AppointmentStatusCd ) {
      this._values.push(AppointmentStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): AppointmentStatusCd {

    for ( const obj in AppointmentStatusCd ) {
      if (AppointmentStatusCd[obj] === value){
        return AppointmentStatusCd[obj] as AppointmentStatusCd;
      }
    }
  }
}

const AppointmentStatusCdHelper = new AppointmentStatusCdHelperEnumHelperClass();
export default AppointmentStatusCdHelper;
