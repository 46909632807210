import {PickupShipmentSourceCd} from '../';
import {EnumHelper} from './enum-helper';

export class PickupShipmentSourceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PickupShipmentSourceCd ) {
      this._values.push(PickupShipmentSourceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PickupShipmentSourceCd {

    for ( const obj in PickupShipmentSourceCd ) {
      if (PickupShipmentSourceCd[obj] === value){
        return PickupShipmentSourceCd[obj] as PickupShipmentSourceCd;
      }
    }
  }
}

const PickupShipmentSourceCdHelper = new PickupShipmentSourceCdHelperEnumHelperClass();
export default PickupShipmentSourceCdHelper;
