import {MatchedPartyStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class MatchedPartyStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MatchedPartyStatusCd ) {
      this._values.push(MatchedPartyStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MatchedPartyStatusCd {

    for ( const obj in MatchedPartyStatusCd ) {
      if (MatchedPartyStatusCd[obj] === value){
        return MatchedPartyStatusCd[obj] as MatchedPartyStatusCd;
      }
    }
  }
}

const MatchedPartyStatusCdHelper = new MatchedPartyStatusCdHelperEnumHelperClass();
export default MatchedPartyStatusCdHelper;
