import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { XpoBoardConsumer, XpoBoardStateSources, XpoGridBoardState } from '../../../../../models';
import { XpoColumnLevelFilteringToggleComponent } from '../../../../board-actions/column-level-filtering-toggle/column-level-filtering-toggle.component';

@Component({
  selector: 'xpo-settings-button-floating-filters',
  templateUrl: './floating-settings-default-buttons.component.html',
  styleUrls: ['./floating-settings-default-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-column-level-filtering-toggle',
    'data-cy': 'settings-buttons-floating',
  },
})
export class XpoSettingsFloatingFiltersComponent extends XpoBoardConsumer {
  static readonly IsFilterVisibleDefaultValue = true;

  isFiltersVisible: boolean = XpoColumnLevelFilteringToggleComponent.IsFilterVisibleDefaultValue;

  toggleColumnLevelFiltering(): void {
    this.stateChange$.next(<XpoGridBoardState>{
      columnLevelFilteringVisible: !this.isFiltersVisible,
      source: XpoBoardStateSources.ColumnLevelFilteringToggle,
    });
  }

  protected onStateChange(state: XpoGridBoardState): void {
    if (state.changes.includes('columnLevelFilteringVisible')) {
      this.isFiltersVisible = state.columnLevelFilteringVisible;
    }
  }
}
