import {
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  Optional,
  QueryList,
  Self,
  ViewChild,
} from '@angular/core';
import { XpoTileRibbonClickableDirective } from './directives/tile-ribbon-clickable.directive';
import { XpoTileRibbonCompactDirective } from './directives/tile-ribbon-compact.directive';
import { XpoTileRibbonMiniDirective } from './directives/tile-ribbon-mini.directive';

import { XpoTileRibbonHeaderModel } from './header/models/tile-ribbon-header.model';
import { XpoScrollableComponent } from './scrollable/scrollable.component';
import { XpoTileComponent } from './tile/tile.component';

@Component({
  selector: 'xpo-tile-ribbon',
  templateUrl: './tile-ribbon.component.html',
  styleUrls: ['./tile-ribbon.component.scss'],
  host: { class: 'xpo-TileRibbon' },
})
export class XpoTileRibbonComponent implements AfterContentInit, AfterViewChecked {
  @Input() defaultSelectedIndex: number;

  @Input() header?: XpoTileRibbonHeaderModel;

  @ContentChildren(XpoTileComponent) private tiles: QueryList<XpoTileComponent>;

  @ViewChild(XpoScrollableComponent, { static: false }) private scrollable: XpoScrollableComponent;

  private selectedTile: XpoTileComponent;

  constructor(
    private cd: ChangeDetectorRef,
    @Self() @Optional() private compact: XpoTileRibbonCompactDirective,
    @Self() @Optional() private mini: XpoTileRibbonMiniDirective,
    @Self() @Optional() private clickable: XpoTileRibbonClickableDirective
  ) {}

  ngAfterContentInit(): void {
    if (this.clickable) {
      this.setClickableTiles();
    }
  }

  ngAfterViewChecked(): void {
    if (this.scrollable) {
      this.scrollable.setCompact(this.compact !== null);
      this.scrollable.setMini(this.mini !== null);
    }
  }

  showTiles(): boolean {
    return (
      !this.header ||
      !this.header.showToggle ||
      !this.header.toggle ||
      (this.header.toggle && this.header.toggle.showTiles)
    );
  }

  showCollapsedTiles(): boolean {
    return this.header && this.header.toggle && !this.header.toggle.showTiles;
  }

  onClickToggle(): void {
    this.cd.detectChanges();

    if (this.header.toggle.showTiles && this.selectedTile) {
      this.scrollToSelectedTile();
    }
  }

  private setClickableTiles(): void {
    this.tiles.forEach((tile) => {
      // set tile clickable behavior
      tile.setClickable();

      // change selected tile
      tile.tileClick.subscribe(() => this.onTileClick(tile));
    });

    if (this.defaultSelectedIndex !== undefined) {
      this.selectDefaultTile();
    }
  }

  private selectDefaultTile(): void {
    const tile = this.tiles.toArray()[this.defaultSelectedIndex];

    tile.tileClick.emit(tile.tile);
  }

  private onTileClick(tile: XpoTileComponent): void {
    // if select a different tile
    if (this.selectedTile !== tile) {
      // unselect old tile
      if (this.selectedTile !== undefined) {
        this.selectedTile.setSelected(false);
      }
      // select new tile
      tile.setSelected(true);

      // save recently selected tile
      this.selectedTile = tile;
    }

    // scroll to selected tile
    this.scrollToSelectedTile();
  }

  private scrollToSelectedTile(): void {
    const tileIndex = this.tiles.toArray().indexOf(this.selectedTile);
    this.scrollable.scrollToElement(tileIndex);
  }
}
