import {CustomerInvoiceCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerInvoiceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerInvoiceCd ) {
      this._values.push(CustomerInvoiceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerInvoiceCd {

    for ( const obj in CustomerInvoiceCd ) {
      if (CustomerInvoiceCd[obj] === value){
        return CustomerInvoiceCd[obj] as CustomerInvoiceCd;
      }
    }
  }
}

const CustomerInvoiceCdHelper = new CustomerInvoiceCdHelperEnumHelperClass();
export default CustomerInvoiceCdHelper;
