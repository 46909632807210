import {ExceptionLocationTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ExceptionLocationTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ExceptionLocationTypeCd ) {
      this._values.push(ExceptionLocationTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ExceptionLocationTypeCd {

    for ( const obj in ExceptionLocationTypeCd ) {
      if (ExceptionLocationTypeCd[obj] === value){
        return ExceptionLocationTypeCd[obj] as ExceptionLocationTypeCd;
      }
    }
  }
}

const ExceptionLocationTypeCdHelper = new ExceptionLocationTypeCdHelperEnumHelperClass();
export default ExceptionLocationTypeCdHelper;
