export type XrtSortDirection = 'asc' | 'desc';

export class XrtSortExpression {
  readonly column: any;
  readonly direction: string;

  constructor(column: string, direction?: XrtSortDirection) {
    this.column = column;
    this.direction = direction || 'asc';
  }
}
