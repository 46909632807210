import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { DocumentSearch } from '@xpo-ltl/sdk-documentmanagement';
import { get as _get } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfigManagerProperties } from '../../config-manager-properties.enum';
import { XpoLtlDocType } from '../doc-type.enum';
import { XpoLtlDocumentService } from '../document.service';

@Component({
  selector: 'xpo-ltl-document-selector',
  templateUrl: './document-selector.component.html',
  styleUrls: ['./document-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpoLtlDocumentSelectorComponent implements AfterViewInit {
  @ViewChild('documentSelect') documentSelect: MatSelect;

  @Input() set proNbr(value: string) {
    this._proNbr = value;
    this.fetchDocuments(this._proNbr);
  }
  private _proNbr: string;

  @Input() tooltip: string = 'Select Documents to Display';
  @Input() matIcon: string = 'link';

  @Output() selectionChange = new EventEmitter<DocumentSearch[]>();

  private documentListSubject = new BehaviorSubject<DocumentSearch[]>([]);
  readonly documentList$ = this.documentListSubject.asObservable();

  readonly XpoLtlDocType = XpoLtlDocType;

  readonly defaultAll: DocumentSearch = {
    cdt: {
      corpCode: this.configManager.getSetting(ConfigManagerProperties.imageCorpCode),
      docClass: XpoLtlDocType.All,
      timestamp: `${new Date().valueOf()}`,
    },
    timestampISO: new Date().toISOString(),
    relevanceValue: 0,
  };

  constructor(private doccumentService: XpoLtlDocumentService, private configManager: ConfigManagerService) {}

  ngAfterViewInit() {
    // this.documentSelect.trigger = this.documentSelectTrigger;
  }

  private fetchDocuments(proNbr: string, minDate?: Date, maxDate?: Date, searchTags?: string[]) {
    this.doccumentService
      .listAvailableDocuments(proNbr, minDate, maxDate, searchTags)
      .pipe(take(1))
      .subscribe((response) => {
        this.documentListSubject.next(_get(response, 'documentInfo', []));
      });
  }

  selectionChanged(selection: MatSelectChange) {
    if (selection.value.cdt.docClass === XpoLtlDocType.All) {
      // return all documents
      this.selectionChange.emit(this.documentListSubject.value);
    } else {
      // return only selected document
      this.selectionChange.emit([selection.value]);
    }

    // if we don't clear value here, we can't select the same document twice in a row
    this.documentSelect.value = undefined;
  }
}
