
export enum ShipmentCreditStatusCd {
	BAD_DEBT = 'BadDebt',
	CASH_ONLY = 'CashOnly',
	CREDIT = 'Credit',
	NON_CREDIT = 'NonCredit',
	PCO_CNTRL = 'PcoCntrl',
	POST_PETITION = 'PostPetition',
	VOLUNTARY = 'Voluntary'}

