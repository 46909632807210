import {BillTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class BillTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BillTypeCd ) {
      this._values.push(BillTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BillTypeCd {

    for ( const obj in BillTypeCd ) {
      if (BillTypeCd[obj] === value){
        return BillTypeCd[obj] as BillTypeCd;
      }
    }
  }
}

const BillTypeCdHelper = new BillTypeCdHelperEnumHelperClass();
export default BillTypeCdHelper;
