import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { UserManager } from 'oidc-client';

import { XpoAuthRoutingModule } from './auth-routing.module';
import { XpoAuthenticationGuard, XpoAuthenticationInterceptor } from './authentication/index';
import { XpoAuthorizationGuard } from './authorization/authorization.guard';

import {
  XpoAuthenticationComponent,
  XpoAuthenticationSilentComponent,
  XpoPostSignOutComponent,
} from './components/index';
import { XPO_AUTH_CONFIG, XpoAuthConfig, XpoAuthSwitchApiConfig } from './config/index';
import { XpoAuthRoutePaths } from './enums/auth-route-paths.enum';
import { DefaultAuthEventHandlerProvider } from './event-handler/event-handler.model';

export function userManagerFactory(authConfig: XpoAuthConfig): UserManager {
  return new UserManager({
    authority: authConfig.authorityUri,
    client_id: authConfig.appCode,
    post_logout_redirect_uri: `${authConfig.applicationRootUri}${XpoAuthRoutePaths.PostSignOut}`,
    redirect_uri: `${authConfig.applicationRootUri}${XpoAuthRoutePaths.Authentication}`,
    response_type: XpoAuthConfig.ResponseTypes.join(' '),
    scope: authConfig.scopes.join(' '),
    silent_redirect_uri: `${authConfig.applicationRootUri}${XpoAuthRoutePaths.AuthSilent}`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
  });
}

export function switchApiConfigFactory(authConfig: XpoAuthConfig): XpoAuthSwitchApiConfig {
  return {
    apiUri: authConfig.switchApiUri,
  };
}

@NgModule({
  imports: [
    CommonModule,
    XpoAuthRoutingModule, // Keep last
  ],
  declarations: [XpoAuthenticationComponent, XpoAuthenticationSilentComponent, XpoPostSignOutComponent],
  providers: [
    { provide: UserManager, useFactory: userManagerFactory, deps: [XPO_AUTH_CONFIG] },
    { provide: XpoAuthSwitchApiConfig, useFactory: switchApiConfigFactory, deps: [XPO_AUTH_CONFIG] }, // TODO
    XpoAuthenticationGuard,
    XpoAuthorizationGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XpoAuthenticationInterceptor,
      multi: true,
    },
  ],
})
export class XpoAuthModule {
  static forRoot(
    authConfigProvider: Provider,
    authEventListener: Provider = DefaultAuthEventHandlerProvider
  ): ModuleWithProviders {
    return {
      ngModule: XpoAuthModule,
      providers: [authConfigProvider, authEventListener],
    };
  }
}
