import { assign, every, isEmpty, isEqual, isObject, transform } from 'lodash-es';
import { asyncScheduler, EMPTY, Observable, of as observableOf, Subject, timer } from 'rxjs';
import { catchError, concatMap, debounce, filter, map, observeOn, take } from 'rxjs/operators';
import { XpoBoardDataFetchState } from './board-data-fetch-state.model';
import { XpoBoardData } from './board-data.model';
import { XpoRowModelType } from './board-row-model-type.enum';
import { XpoBoardStateSources } from './board-state-sources.enum';
import { XpoBoardStateViewer } from './board-state-viewer.model';
import { XpoBoardState } from './board-state.model';
import { XpoVisibleBoardViewDatum } from './visible-board-view-datum.model';
/**
 * Helper class internally used by the board-data source to handle fetching of data when a state change occurs.
 */
export class XpoBoardDataFetcher<T extends XpoBoardData = XpoBoardData> implements XpoBoardStateViewer {
  // Only cause a fetch when paging, or when criteria change
  private static CauseFetchChangeKeys: Array<keyof XpoBoardState> = ['pageNumber', 'criteria'];
  private static FetcherSource: string = XpoBoardStateSources.BoardDataFetcher;
  private cachedState: XpoBoardState;

  stateChange$: Subject<XpoBoardState> = new Subject<XpoBoardState>();

  constructor(
    private stateSource$: Observable<XpoBoardState>,
    private fetcher: (state: XpoBoardState) => Observable<T>
  ) {
    this.stateSource$
      .pipe(
        filter((state) => this.isFetchRequiredBeforeCache(state)),
        /**
         * The following debounce prevents multiple fetch dispatches when the same criteria changes
         * is fired from multiple components. We evaluated use a distinctUntilChange operator to dispatch
         * the fetcher only when the criteria change compared with the last state but that can be a limit
         * from some projects that emit the fetch from custom reasons even when the criteria didn't change.
         *
         * isUserScrollingInfinite added to resolve the error with the infinite scroll. We are skipping the debounceTime
         * in that case allowing immediately fetch data regarding quickly scrolling. Bug related: NGXLTL-1054
         */
        debounce((state) => (this.isUserScrollingInfinite(state) ? EMPTY : timer(100))),
        concatMap((state) => {
          return this.fetcher(state).pipe(
            // For the scenario that we get an observableOf(value), this will ensure that we processes this async
            // regardless
            observeOn(asyncScheduler),
            map((result) => {
              return {
                context: state.context, // echo back the context, in case the requested needs to display the data
                data: result,
                dataFetchState:
                  result.recordCount !== 0 ? XpoBoardDataFetchState.ResultsReturned : XpoBoardDataFetchState.NoResults,
                source: XpoBoardDataFetcher.FetcherSource,
                visibleViewData: XpoBoardDataFetcher.constructVisibleViewData(state, result),
              };
            }),
            catchError((_) =>
              observableOf({
                context: state.context, // echo back the context, in case the requested needs to display the data
                data: null,
                dataFetchState: XpoBoardDataFetchState.Error,
                source: XpoBoardDataFetcher.FetcherSource,
              })
            ),
            take(1)
          );
        })
      )
      .subscribe(
        (state: XpoBoardState) => {
          this.stateChange$.next(<XpoBoardState>state);
        },
        (err) => {
          this.stateChange$.error({
            error: 'Unhandled error ' + err,
            dataFetchState: XpoBoardDataFetchState.Error,
            source: XpoBoardDataFetcher.FetcherSource,
          });
        }
      );
  }

  static isFetchRequired(state: XpoBoardState, cachedState: XpoBoardState): boolean {
    return state.rowModelType === XpoRowModelType.ClientSide ? isFetchRequiredClientSide() : isFetchRequiredCommon();

    // --- The following are some functions declared inside this static method scope to be used here ---

    function isFetchRequiredClientSide(): boolean {
      return (
        isFetchRequiredCommon() &&
        ([
          ...'',
          XpoBoardStateSources.BoardActivatingView,
          XpoBoardStateSources.ActiveViewChange,
          XpoBoardStateSources.AddNewView,
        ].includes(state.source) ||
          (state.source === XpoBoardStateSources.FilterChange && !isConditionalFilter()))
      );
      /**
       * the initial ...'' in the XpoBoardStateSources array is a momentary fix until the state.source be
       * completed migrated to be XpoBoardStateSources type. Contrary case it will not work because state.source
       *  only matchs string type now. It's related with the NGXLTL-505 ticket
       */
    }

    function isFetchRequiredCommon(): boolean {
      return (
        state.source !== XpoBoardDataFetcher.FetcherSource &&
        XpoBoardDataFetcher.CauseFetchChangeKeys.some((f) => state.changes.includes(f))
      );
    }

    function isConditionalFilter(): boolean {
      if (cachedState) {
        return isAllConditional(getNewCriteria());
      }
    }

    function isAllConditional(newCriteria): boolean {
      /**
       * Evaluate if filter has 'conditions' property to know if it's a conditional filter
       * If newCriteria is empty, user has cleaned filters so evaluate if last state had
       * non-conditional to emit a fetch to restore dataset
       */
      return isEmpty(newCriteria)
        ? every(
            cachedState.criteria,
            (value, key: string) => cachedState.criteria[key] && cachedState.criteria[key].conditions
          )
        : every(newCriteria, (value, key: string) => newCriteria[key] && newCriteria[key].conditions);
    }

    function getNewCriteria(): any {
      // Get the new assign/remove criteria compared with last state
      return assign(diff(state.criteria, cachedState.criteria), diff(cachedState.criteria, state.criteria));
    }

    function diff(compared, comparator): any {
      function changes(compared_, comparator_): any {
        return transform(compared_, function (result, value, key): void {
          if (!isEqual(value, comparator_[key])) {
            result[key] = isObject(value) && isObject(comparator_[key]) ? changes(value, comparator_[key]) : value;
          }
        });
      }
      return changes(compared, comparator);
    }
  }

  // construct the visible view result, so the data count matches correctly
  private static constructVisibleViewData(state: XpoBoardState, result: XpoBoardData): XpoVisibleBoardViewDatum[] {
    const visibleViews = [...(state.visibleViewData || [])];
    const visibleIndex = visibleViews.findIndex((v) => v.viewId === result.state.viewId);

    if (visibleIndex !== -1) {
      visibleViews.splice(visibleIndex, 1, { viewId: result.state.viewId, recordCount: result.recordCount });
    }

    return visibleViews;
  }

  private isFetchRequiredBeforeCache(state: XpoBoardState): boolean {
    // First evaluate fetch then cache state
    const evaluation =
      state.source === XpoBoardStateSources.ExternalDataRefresh ||
      state.source === XpoBoardStateSources.DataSourceRefresh ||
      XpoBoardDataFetcher.isFetchRequired(state, this.cachedState);
    this.cacheState(state);
    return evaluation;
  }

  private cacheState(state: XpoBoardState): void {
    this.cachedState = state;
  }

  private isUserScrollingInfinite(state: XpoBoardState): boolean {
    return state.rowModelType === XpoRowModelType.Infinite && state.source === XpoBoardStateSources.PageChange;
  }
}
