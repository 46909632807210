import {ClaimFilingMethodCd} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimFilingMethodCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimFilingMethodCd ) {
      this._values.push(ClaimFilingMethodCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimFilingMethodCd {

    for ( const obj in ClaimFilingMethodCd ) {
      if (ClaimFilingMethodCd[obj] === value){
        return ClaimFilingMethodCd[obj] as ClaimFilingMethodCd;
      }
    }
  }
}

const ClaimFilingMethodCdHelper = new ClaimFilingMethodCdHelperEnumHelperClass();
export default ClaimFilingMethodCdHelper;
