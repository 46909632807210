import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

export interface XpoMessagingPopoverMessage {
  app: string;
  body: string;
  link: string;
  received: Date;
  title: string;
  type: string;
}

@Component({
  selector: 'xpo-messaging-popover',
  templateUrl: './messaging-popover.html',
  styleUrls: ['./messaging-popover.scss'],
  host: {
    class: 'xpo-MessagingPopover',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class XpoMessagingPopover {
  @Input()
  messages: XpoMessagingPopoverMessage[];

  alertColor(type: string): string {
    let retColor: string = '$xpo-grey';
    switch (type) {
      case 'alert': {
        retColor = '#D50000';
        break;
      }
      case 'success': {
        retColor = '#00C853';
        break;
      }
      case 'warning': {
        retColor = '#FFBA00';
        break;
      }
    }
    return retColor;
  }
}
