// TODO: We could probably implement this as CSS classes instead. NGXLTL-931
export class StatusColors {
  static colors = {
    blue: '#0091EA',
    yellow: '#FFD600',
    green: '#00C853',
    red: '#D32F2F',
    grey: '#D8D8D8',
    purple: '#6200EA',
    pink: '#C51162',
    darkgrey: '#333333',
  };
}
